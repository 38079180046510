import { FormattedMessage } from 'react-intl';

import { Button } from '@trustyou/ui';

import { useManageGroups } from '../../../../hooks';
import styles from './styles';

export interface AddGroupButtonProps {
  onAddGroup?: () => void;
}

const AddGroupButton = ({ onAddGroup }: AddGroupButtonProps) => {
  const isManageGroupsAllowed = useManageGroups();

  if (!isManageGroupsAllowed) return null;

  return (
    <Button
      data-testid="add-group"
      sx={styles.button}
      variant="outlined"
      size="medium"
      onClick={onAddGroup}
    >
      <FormattedMessage id="organization.action.addGroup" defaultMessage="Add group" />
    </Button>
  );
};

export default AddGroupButton;
