import { useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import type { GridCellParams, GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import { faGaugeMax } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  type Benchmark,
  FETCH_BENCHMARKS,
  getPaginationRequest,
  getSortRequest,
  useBenchmarks,
  useBenchmarksGridStore,
  useChangelingStore,
  useGridParams,
} from '@trustyou/shared';
import {
  Box,
  CircularProgress,
  ContentPane,
  DataGrid,
  DataGridCell,
  EmptyBenchmarks,
  SecondaryDrawer,
  SomethingWrongRoute,
} from '@trustyou/ui';

import { BenchmarkDetailView } from './BenchmarkDetailView';
import { IconButtonWithTooltip } from './IconButtonWithTooltip';

import { styles } from './styles';

const tableHeaders = defineMessages({
  segment: {
    id: 'benchmark.table.segment',
    defaultMessage: 'Benchmark segment',
  },
  group: {
    id: 'benchmark.table.group',
    defaultMessage: 'Benchmark group',
  },
  ownEntities: {
    id: 'benchmark.table.own-entities',
    defaultMessage: 'Number of own entities',
  },
  competitorEntities: {
    id: 'benchmark.table.competitor-entities',
    defaultMessage: 'Number of competitor entities',
  },
});

export const BenchmarksTable = () => {
  const intl = useIntl();
  const { isChangeling } = useChangelingStore();

  const [benchmark, setBenchmark] = useState<Benchmark | undefined>(undefined);

  const gridState = useBenchmarksGridStore();

  const navigate = useNavigate();

  const { paginationModel, sortModel } = gridState;

  const { onPaginationModelChange, onSortModelChange } = useGridParams(FETCH_BENCHMARKS, gridState);

  const {
    data,
    isPending: isLoading,
    isError,
  } = useBenchmarks(getPaginationRequest(paginationModel), getSortRequest(sortModel));

  const navigateToDashboard = (link: string) => () => navigate(link);

  const handleRowClick = (params: GridRowParams) => {
    setBenchmark(params.row);
  };

  const columns: GridColDef[] = [
    {
      field: 'set_name',
      headerName: intl.formatMessage(tableHeaders.segment),
      minWidth: 200,
      flex: 1,
      renderCell: ({ row }: GridCellParams) => <DataGridCell primaryValue={row.set_name} />,
    },
    {
      field: 'name',
      headerName: intl.formatMessage(tableHeaders.group),
      minWidth: 200,
      flex: 1,
      renderCell: ({ row }: GridCellParams) => <DataGridCell primaryValue={row.name} />,
    },
    {
      field: 'num_owned_entities',
      headerName: intl.formatMessage(tableHeaders.ownEntities),
      minWidth: 200,
      flex: 1,
      sortable: false,
      renderCell: ({ row }: GridCellParams) => (
        <DataGridCell primaryValue={row.num_owned_entities} />
      ),
    },
    {
      field: 'num_competitor_entities',
      headerName: intl.formatMessage(tableHeaders.competitorEntities),
      minWidth: 200,
      flex: 1,
      sortable: false,
      renderCell: ({ row }: GridCellParams) => (
        <DataGridCell primaryValue={row.num_competitor_entities} />
      ),
    },
    {
      field: 'dashboard_link',
      headerName: '',
      minWidth: 150,
      sortable: false,
      renderCell: ({ row }: GridCellParams) => (
        <IconButtonWithTooltip
          icon={faGaugeMax}
          hide={!row.dashboard_link}
          tooltip={
            <FormattedMessage id="benchmark.tooltip.dashboard" defaultMessage="View dashboard" />
          }
          onClick={navigateToDashboard(row.dashboard_link)}
          sx={{ height: 32, width: 32 }}
        />
      ),
    },
  ];

  if (isLoading)
    return (
      <ContentPane sx={styles.paneWrapper}>
        <Box sx={styles.loader}>
          <CircularProgress />
        </Box>
      </ContentPane>
    );

  if (data?.pagination?.total === 0)
    return (
      <ContentPane sx={styles.paneWrapper}>
        <EmptyBenchmarks />
      </ContentPane>
    );

  if (isError)
    return (
      <ContentPane sx={styles.paneWrapper}>
        <SomethingWrongRoute />
      </ContentPane>
    );

  const rows = (data?.data || []).map((row, rowId) => ({ ...row, rowId }));

  return (
    <ContentPane sx={styles.paneWrapper}>
      <DataGrid
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        rowHeight={64}
        columns={columns.filter((col) => col.field !== 'dashboard_link')}
        rows={rows}
        getRowId={(row) => row.rowId}
        rowCount={data?.pagination.total}
        onRowClick={handleRowClick}
      />
      <SecondaryDrawer
        header={benchmark?.name}
        open={!!benchmark}
        onClose={() => setBenchmark(undefined)}
        changelingMode={isChangeling}
      >
        {benchmark && <BenchmarkDetailView benchmark={benchmark} />}
      </SecondaryDrawer>
    </ContentPane>
  );
};
