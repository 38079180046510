import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { useAuthorizationStore, useMembershipStore, usePermissionStore } from '@trustyou/shared';
import { Box, CodeBlock, ContentPane, Typography } from '@trustyou/ui';

export const DevTools = () => {
  const { tokenStore } = useAuthorizationStore();
  const { membership } = useMembershipStore();
  const { permissions } = usePermissionStore();
  const { accessToken: keycloakAccessToken } = useOidcAccessToken();
  const allPermissions = permissions || {};

  const accessToken =
    tokenStore && membership?.id && tokenStore[membership.id].org_center?.access_token;

  const claims = tokenStore && membership?.id && tokenStore[membership.id].org_center?.claims;

  return (
    <div>
      <ContentPane>
        <Typography color="text.primary">Org Access Token:</Typography>
        {accessToken && (
          <CodeBlock sx={{ mt: 2 }} isFullWidth>
            {accessToken}
          </CodeBlock>
        )}
      </ContentPane>
      <ContentPane>
        <Typography color="text.primary">Keycloak Access Token:</Typography>
        {keycloakAccessToken && (
          <CodeBlock sx={{ mt: 2 }} isFullWidth>
            {keycloakAccessToken}
          </CodeBlock>
        )}
      </ContentPane>
      <ContentPane>
        <Typography color="text.primary">Membership</Typography>
        {claims && (
          <Box component="pre">
            <code data-testid="code">{JSON.stringify(claims, null, 2)}</code>
          </Box>
        )}
      </ContentPane>
      {Object.entries(allPermissions).map(([k, perms]) => {
        return (
          <ContentPane>
            <Typography color="text.primary">Permissions &quot;{k}&quot;</Typography>
            {perms && (
              <Box component="pre">
                <code data-testid="code">{JSON.stringify(perms, null, 2)}</code>
              </Box>
            )}
          </ContentPane>
        );
      })}
    </div>
  );
};
