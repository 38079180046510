import { type Control, Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { TimePicker, renderTimeViewClock } from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import { FormControl, FormHelperText, LocalizationProvider, Stack } from '@trustyou/ui';
import { DateTime } from 'luxon';

import { frequencySelectorMessages } from '../../constants/messages';
import { FormControlLabel } from '../form-control-label/form-control-label';
import styles from './styles';

type Props = {
  control: Control;
};

export const TimeOfDayFormControl = ({ control }: Props) => (
  <FormControl size="small" sx={styles.formControl}>
    <FormControlLabel>
      <FormattedMessage {...frequencySelectorMessages.timeOfDay} />
    </FormControlLabel>
    <Controller
      control={control}
      name="timeOfDay"
      render={({ field: { value, onChange } }) => (
        <Stack>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <TimePicker
              views={['hours']}
              viewRenderers={{
                hours: renderTimeViewClock,
              }}
              format="HH:00"
              value={DateTime.fromISO(value)}
              defaultValue={DateTime.fromISO(control._defaultValues.timeOfDay)}
              onChange={(newValue) => {
                onChange(newValue);
              }}
              sx={styles.timePicker}
            />
          </LocalizationProvider>
          <FormHelperText>
            <FormattedMessage {...frequencySelectorMessages.timeOfDayHelper} />
          </FormHelperText>
        </Stack>
      )}
    />
  </FormControl>
);
