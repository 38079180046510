import type { IStyles } from '../../types/common';

const styles: IStyles = {
  list: {
    ml: 1,
  },
  point: {
    display: 'list-item',
    listStyleType: 'none',
    px: 1,
    py: 0,
  },
  pointBullet: {
    fontSize: '1em',
    lineHeight: '24px',
    color: 'black',
  },
  pointWrapper: {
    display: 'inline',
    position: 'relative',
    left: '-24px',
  },
  pointTitle: {
    display: 'inline',
    fontWeight: 700,
  },
  pointDescription: {
    display: 'inline',
    ml: 1,
  },

  button: {
    backgrountColor: 'primary.main',
    color: 'primary.contrastText',
  },
};

export default styles;
