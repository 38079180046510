import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useResponsive } from '@trustyou/shared';
import { Button } from '@trustyou/ui';

export const AddAccessGroupButton = () => {
  const { isMobileRange } = useResponsive();
  const navigate = useNavigate();

  if (isMobileRange) return null;

  return (
    <Button
      data-testid="add-access-group"
      data-gtm-id="add-access-group-nav-button"
      variant="outlined"
      onClick={() => navigate('access-groups/create')}
    >
      <FormattedMessage
        id="organization.access-groups.add-access-group"
        defaultMessage="Add Access Group"
      />
    </Button>
  );
};
