import { FormattedMessage } from 'react-intl';

import { DeleteModal } from '@trustyou/ui';

import { commonMessages } from './messages';

export const DeleteReportModal = ({
  open,
  onClose,
  onDelete,
}: {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}) => {
  return (
    <DeleteModal
      contentStyles={{ width: 444 }}
      cancelText={<FormattedMessage {...commonMessages.cancel} />}
      content={<FormattedMessage {...commonMessages.deleteReportContent} />}
      dataName="report-delete"
      deleteText={<FormattedMessage {...commonMessages.delete} />}
      onClose={onClose}
      onDelete={onDelete}
      title={<FormattedMessage {...commonMessages.deleteReportTitle} />}
      open={open}
    />
  );
};
