import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import {
  type CustomAttributeDefinition,
  CustomAttributeType,
  useAlertStore,
  useCanUpdateCustomAttributeDefinition,
  useCreateCustomAttributeDefinition,
  useUpdateCustomAttributeDefinition,
} from '@trustyou/shared';
import { Button } from '@trustyou/ui';

import type { CustomAttributeFormData } from './Form';
import { type UpdateConfirmationData, UpdateConfirmationDialog } from './UpdateConfirmationDialog';
import { UpdateProhibitionDialog } from './UpdateProhibitionDialog';

import { manageDrawer } from '../../constants/messages/customAttributes';

type Props = {
  onSave: () => void;
  data?: CustomAttributeDefinition;
};

export const SaveAction = ({ data, onSave }: Props) => {
  const { alert } = useAlertStore();

  const [impactedSegments, setImpactedSegments] = useState<Record<string, string[]>>();
  const [updateConfirmationData, setUpdateConfirmationData] = useState<UpdateConfirmationData>();

  const { mutate: createCustomAttribute } = useCreateCustomAttributeDefinition((response) => {
    alert.success(
      <FormattedMessage {...manageDrawer.addSuccess} values={{ name: response.name }} />
    );
    onSave();
  }, alert.genericError);

  const { mutate: updateCustomAttribute } = useUpdateCustomAttributeDefinition((response) => {
    alert.success(
      <FormattedMessage {...manageDrawer.updateSuccess} values={{ name: response.name }} />
    );
    onSave();
  }, alert.genericError);

  const { mutate: validateUpdate } = useCanUpdateCustomAttributeDefinition((response, payload) => {
    if (!response.valid && response.segment_names_by_option) {
      setImpactedSegments(response.segment_names_by_option);
    } else {
      if (payload.type === CustomAttributeType.TEXT) {
        setUpdateConfirmationData({
          entitiesCount: response.number_of_affected_owned_entities,
        });
      } else {
        const oldOptions = data?.options || [];
        const newOptions = payload.options.filter((row) => !row.id).map((row) => row.name);
        const oldIds = payload.options.map((row) => row.id);
        const deleted = oldOptions.filter((row) => !oldIds.includes(row.id)).map((row) => row.name);
        const renamed = payload.options
          .filter(
            (row) => row.id && oldOptions.find((option) => option.id === row.id)?.name !== row.name
          )
          .map((row) => row.name);
        setUpdateConfirmationData({
          entitiesCount: response.number_of_affected_owned_entities,
          renamed,
          new: newOptions,
          deleted: deleted,
        });
      }
    }
  }, alert.genericError);

  const { formState, watch, getValues } = useFormContext<CustomAttributeFormData>();

  const { type, options = [] } = watch();

  const hasOptions =
    type === CustomAttributeType.SELECT || type === CustomAttributeType.MULTI_SELECT;

  const saveDisabled =
    !formState.isDirty ||
    !formState.isValid ||
    !!Object.keys(formState.errors).length ||
    (hasOptions && options.length === 0);

  const getSavingValues = () => {
    const values = getValues();
    const options = [...(values.options || [])];
    options.forEach((option) => {
      delete option._id;
      if (option.id?.startsWith('NEW_')) {
        delete option.id;
      }
    });
    const savingValues = {
      ...values,
      type: values.type as CustomAttributeType,
      default: options.filter((option) => option.isDefault).map(({ isDefault, ...rest }) => rest),
      unique: hasOptions ? false : values.unique,
      options: options.map(({ isDefault, ...rest }) => rest),
    };
    return savingValues;
  };

  const onSaveConfirmed = () => {
    if (data?.id) {
      updateCustomAttribute({
        ...getSavingValues(),
        id: data.id,
      });
    } else {
      createCustomAttribute(getSavingValues());
    }
    setUpdateConfirmationData(undefined);
  };

  const onSubmit = () => {
    if (data?.id) {
      validateUpdate({
        ...getSavingValues(),
        id: data.id,
      });
    } else {
      onSaveConfirmed();
    }
  };

  return (
    <>
      <Button onClick={onSubmit} variant="contained" disabled={saveDisabled}>
        <FormattedMessage {...manageDrawer.save} />
      </Button>
      <UpdateProhibitionDialog
        segmentsByAttribute={impactedSegments}
        onClose={() => setImpactedSegments(undefined)}
      />
      <UpdateConfirmationDialog
        data={updateConfirmationData}
        onCancel={() => setUpdateConfirmationData(undefined)}
        onSave={onSaveConfirmed}
      />
    </>
  );
};
