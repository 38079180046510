import { FormattedMessage } from 'react-intl';

import { ExplanationModal } from '@trustyou/ui';

export interface WhatIsSectorProps {
  open: boolean;
  onClose: () => void;
}

export const WhatIsSector = ({ open, onClose }: WhatIsSectorProps) => (
  <ExplanationModal
    open={open}
    onClose={onClose}
    dataName="what-is-sector"
    title={<FormattedMessage id="tyDesign.whatIsSectorTitle" defaultMessage="What is sector?" />}
    content={
      <FormattedMessage
        id="tyDesign.whatIsSectorContent"
        defaultMessage="A sector is a category of products or services, and you will be able to see your KPIs per sector. Select one of these values for a sector:"
      />
    }
    buttonText={<FormattedMessage id="tyDesign.whatIsSectorButton" defaultMessage="Got it" />}
    points={[
      {
        title: <FormattedMessage id="tyDesign.accommodation" defaultMessage="Accommodation" />,
        description: (
          <FormattedMessage
            id="tyDesign.accommodationDescription"
            defaultMessage="lodging services to travelers, e.g. hotel"
          />
        ),
      },
      {
        title: (
          <FormattedMessage id="tyDesign.foodAndBeverage" defaultMessage="Food and Beverage" />
        ),
        description: (
          <FormattedMessage
            id="tyDesign.foodAndBeverageDescription"
            defaultMessage="food and drink services to customers, e.g. restaurant"
          />
        ),
      },
      {
        title: (
          <FormattedMessage id="tyDesign.pointOfInterest" defaultMessage="Point of Interest" />
        ),
        description: (
          <FormattedMessage
            id="tyDesign.pointOfInterestDescription"
            defaultMessage="other services to travelers, e.g. travel agency, theme park, conference center"
          />
        ),
      },
    ]}
  />
);
