import { capitalizeFirstLetter } from '@trustyou/shared';

import type { InboxStatusEnum, ViewScoreEnum } from '../client';
import type { DropdownMultipleOption } from '../components/dropdowns/dropdown-multiple';
import { flipObject } from '../utils/misc';

// The following `status`-related constants are only used in tests and stories.
export const ALL_STATUSES_LABEL = 'All Statuses';
export const STATUSES: InboxStatusEnum[] = ['unread', 'read', 'responded', 'confirmed'];
export const STATUS_OPTIONS: DropdownMultipleOption[] = [
  // {
  //   label: 'All Statuses',
  //   value: 'all',
  // },
  {
    label: 'Unresponded',
    children: [
      {
        label: 'Unread',
        value: 'unread',
      },
      {
        label: 'Read',
        value: 'read',
      },
    ],
  },
  {
    label: 'Marked as responded',
    value: 'responded',
  },
  {
    label: 'Response confirmed',
    value: 'confirmed',
  },
  {
    label: 'Marked as deleted',
    value: 'marked_as_deleted',
  },
  {
    label: 'Deleted',
    value: 'deleted',
  },
];

// semantic time units
const MINUTE_IN_SECONDS = 60;
const HOUR_IN_SECONDS = 60 * MINUTE_IN_SECONDS;
const DAY_IN_SECONDS = 24 * HOUR_IN_SECONDS;
const MONTH_IN_SECONDS = 30 * DAY_IN_SECONDS;
const YEAR_IN_SECONDS = 12 * MONTH_IN_SECONDS;

// time range
export const TIME_RANGE_IN_SECONDS_MAP: Record<string, number> = {
  'all-time': 0,
  '24h': 24 * HOUR_IN_SECONDS,
  '7d': 7 * DAY_IN_SECONDS,
  '30d': 30 * DAY_IN_SECONDS,
  '2y': 2 * YEAR_IN_SECONDS,
  '6M': 6 * MONTH_IN_SECONDS,
  '24M': 2 * YEAR_IN_SECONDS,
};
export const TIME_RANGE_SEMANTIC_MAP: Record<string, string> = {
  'all-time': 'All time',
  '7d': 'Last 7 days',
  '24M': 'Last 24 months',
  '{"start":"now-1d/d","end":"now/d"}': 'Yesterday',
  '{"start":"now-1w/w","end":"now/w"}': 'Last week',
  '{"start":"now/M","end":"now"}': 'Month to date',
  '{"start":"now-1M/M","end":"now/M"}': 'Last full month',
  '{"start":"now/Q","end":"now"}': 'Quarter to Date',
  '{"start":"now-1Q/Q","end":"now/Q"}': 'Last full quarter',
  '{"start":"now/y","end":"now"}': 'Year to date',
  '{"start":"now/y","end":"now/M"}': 'Year to last month',
  '{"start":"now-1y/y","end":"now/y"}': 'Last full year',
};
export const TIME_RANGE_SHORT_NAME_OR_STRINGIFIED_JSON_MAP: Record<string, string> =
  flipObject(TIME_RANGE_SEMANTIC_MAP);

// score (or rating)
export const ALL_SCORES_LABEL = 'All Scores';
export const SCORE_RANGE_MAP: Record<ViewScoreEnum, number[] | boolean> = {
  no_score: true,
  low: [0, 5.9],
  medium: [6, 7.9],
  high: [8, 10],
};
export const SCORE_NAMES = Object.keys(SCORE_RANGE_MAP) as ViewScoreEnum[];
export const SCORE_OPTIONS = Object.entries(SCORE_RANGE_MAP).map(([key, value]) => ({
  label:
    typeof value === 'boolean'
      ? `${capitalizeFirstLetter(key)}`
      : `${capitalizeFirstLetter(key)} (${value.at(0)}-${value.at(1)})`,
  value: key,
}));

// source
export const ALL_SOURCES_LABEL = 'All Sources';

// survey type
export const ALL_SURVEY_TYPES_LABEL = 'All types';
export const SURVEY_TYPE_NAMES_MAP: Record<string, string> = {
  all: 'all',
  hotel_post_stay: 'Hotel Post-stay',
  hotel_on_site: 'Hotel On-site',
  restaurant: 'Food & Beverages',
  ess: 'MICE',
  attraction: 'Attraction',
  wellness_and_spa: 'Wellness & Spa',
  hotel_pre_stay: 'Hotel Pre-stay',
};
export const SURVEY_TYPE_KEYS_MAP = flipObject(SURVEY_TYPE_NAMES_MAP);

// sorting
export const SORTING_API_NAMES_MAP: Record<string, string> = {
  'Date: New to Old': 'review.date:desc',
  'Date: Old to New': 'review.date:asc',
  'Score: High to Low': 'review.score:desc',
  'Score: Low to High': 'review.score:asc',
};
export const SORTING_SEMANTIC_NAMES_MAP: Record<string, string> = flipObject(SORTING_API_NAMES_MAP);

// Entities limit for filtering to avoid performance issues on big scopes (clients with a lot of entities, like Accor).
// It must be in sync with the environment variable `MAX_ENTITIES` that backend defines:
// <https://stash.trustyou.com/projects/INFRA/repos/gitops-cxpi/browse/inbox-api/values.yaml?at=refs/heads/staging#26>
export const MAX_ENTITIES = Number(process.env.REACT_APP_INBOX_MAX_ENTITIES ?? '250');
