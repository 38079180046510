import { ResponseAISettingsForm, SignatureSettingsForm } from '@trustyou/inbox';
import { INBOX_RESPONSE_AI_PERMISSION, usePermissions } from '@trustyou/shared';
import { Box } from '@trustyou/ui';

import { useAuthorization } from '../../../service/hooks/authorization/useAuthorization';
import { styles } from '../styles';

export function InboxProfileSettings() {
  const { accessToken } = useAuthorization();
  const { hasPermissions } = usePermissions(accessToken);

  const hasResponseAiPermissions = hasPermissions(INBOX_RESPONSE_AI_PERMISSION);

  return (
    <Box sx={styles.generalPageContainer}>
      <SignatureSettingsForm />
      {hasResponseAiPermissions && <ResponseAISettingsForm />}
    </Box>
  );
}
