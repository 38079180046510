import { grey } from '@mui/material/colors';
import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  container: {
    padding: 3,
    background: grey[100],
    borderRadius: '4px',
    gap: 3,
    maxWidth: '600px',
  },
  infoCaption: {
    color: 'text.hint',
  },
  addressRowIcon: {
    color: 'action.active',
  },
};

export default styles;
