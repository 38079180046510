import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { commonFields, useAlertStore } from '@trustyou/shared';
import { Box, Button, ContentPane, Link, Stack, Typography } from '@trustyou/ui';

import { AIEntityGuides } from './ai-entity-guides';

import type { LanguageEnum } from '../../../../client';
import {
  useFetchResponseAIGetProfile,
  useUpdateResponseAIManageProfile,
} from '../../../../hooks/api-query';
import { useLeaveDialog } from '../../../../hooks/leave-dialog/use-leave-dialog';
import { useLanguage } from '../../../../hooks/use-language';
import { type Language, ReplyLengthLabels, type UserFormData } from '../../../../types';
import { getFormattedProfileData } from '../../../../utils/settings';
import { ToneComparison } from '../../../cards/tone-comparison';
import { DropdownFormField } from '../../../dropdowns/dropdown-form-field';
import { SettingsSection } from '../../../settings-section/settings-section';

export function ResponseAISettingsForm() {
  const intl = useIntl();
  const { languagesWithAuto } = useLanguage();
  const { alert } = useAlertStore();

  const [isComparisonOpen, setComparisonOpen] = useState<boolean>(false);

  const { data: userProfileData, isPending: isLoading } = useFetchResponseAIGetProfile();

  const manageProfile = useUpdateResponseAIManageProfile({
    onSuccess: () => {
      alert.success(intl.formatMessage(commonFields.changesSaved));
    },
    onError: () => {
      alert.genericError();
    },
  });

  const getDefaultValues = useCallback((): UserFormData => {
    let language = userProfileData?.translation_language as unknown as LanguageEnum;
    if (!language || language === 'UND') {
      language = 'AUTO';
    }

    const res = {
      default_tone_of_voice: userProfileData?.tone_of_voice ?? 'formal',
      default_reply_length: userProfileData?.reply_length ?? ReplyLengthLabels.Auto,
      default_translation_language: language,
      guides: userProfileData?.guides ?? [],
      default_reply_language: 'AUTO' as Language | 'AUTO',
      signature: '',
    };
    return res;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const methods = useForm<UserFormData>({
    defaultValues: getDefaultValues(),
  });

  const {
    reset,
    handleSubmit,
    formState: { isDirty },
  } = methods;
  const { renderLeaveDialog, canContinue } = useLeaveDialog({ showDialog: isDirty });

  const onSubmit = handleSubmit((data) => {
    const formattedProfileData = getFormattedProfileData(data);
    manageProfile.mutate(formattedProfileData);
    reset({
      default_reply_length: data.default_reply_length,
      default_tone_of_voice: data.default_tone_of_voice,
      default_translation_language: data.default_translation_language,
      guides: data.guides,
    });
  });

  const toneOfVoiceOptions = [
    {
      value: 'formal',
      label: intl.formatMessage({
        id: 'inbox.settings.voice.labelFormal',
        defaultMessage: 'Formal',
      }),
    },
    {
      value: 'casual',
      label: intl.formatMessage({
        id: 'inbox.settings.voice.labelCasual',
        defaultMessage: 'Casual',
      }),
    },
  ];

  const replyLengthOptions = [
    {
      value: 'auto',
      label: intl.formatMessage({
        id: 'inbox.settings.length.labelAuto',
        defaultMessage: 'Auto',
      }),
    },
    {
      value: 'detailed',
      label: intl.formatMessage({
        id: 'inbox.settings.length.labelDetailed',
        defaultMessage: 'Detailed',
      }),
    },
    {
      value: 'brief',
      label: intl.formatMessage({
        id: 'inbox.settings.length.labelBrief',
        defaultMessage: 'Brief',
      }),
    },
  ];

  useEffect(() => {
    if (isLoading) return;
    if (userProfileData) {
      reset(getDefaultValues());
    }
  }, [userProfileData, isLoading, reset, getDefaultValues]);

  const onCancel = async () => {
    if (await canContinue()) {
      reset();
    }
  };

  return (
    <ContentPane bodyStyles={{ justifyContent: 'space-between' }}>
      <FormProvider {...methods}>
        <Typography variant="h6">
          <FormattedMessage id="inbox.responseAI" defaultMessage="ResponseAI" />
        </Typography>
        <Stack gap={5} mt={3}>
          <SettingsSection
            fieldId="default_tone_of_voice"
            title={
              <FormattedMessage id="inbox.settings.voice.title" defaultMessage="Tone of voice" />
            }
            description={
              <Box component="span" sx={{ display: 'flex', gap: 0.5 }}>
                <FormattedMessage
                  id="inbox.settings.voice.description"
                  defaultMessage="Select the style responseAI should use."
                />
                <Link variant="body1" component="button" onClick={() => setComparisonOpen(true)}>
                  <FormattedMessage
                    id="inbox.settings.voice.details"
                    defaultMessage=" Click to see a comparison"
                  />
                </Link>
              </Box>
            }
          >
            <DropdownFormField
              name="default_tone_of_voice"
              label={
                <FormattedMessage id="inbox.settings.voice.default" defaultMessage="Default tone" />
              }
              options={toneOfVoiceOptions}
            />
          </SettingsSection>
          <SettingsSection
            fieldId="default_reply_length"
            title={
              <FormattedMessage id="inbox.settings.length.title" defaultMessage="Response length" />
            }
            description={
              <FormattedMessage
                id="inbox.settings.length.description"
                defaultMessage="When left on “Auto”, responseAI will try to match the length of the response to that of the guest review."
              />
            }
          >
            <DropdownFormField
              name="default_reply_length"
              options={replyLengthOptions}
              label={
                <FormattedMessage
                  id="inbox.settings.length.default"
                  defaultMessage="Default response length"
                />
              }
            />
          </SettingsSection>
          <SettingsSection
            fieldId="default_translation_language"
            title={
              <FormattedMessage
                id="inbox.settings.translation.title"
                defaultMessage="Translation"
              />
            }
            description={
              <FormattedMessage
                id="inbox.settings.translation.description"
                defaultMessage={`The language which will be used In case you decide to translate the generated response. If set to "Auto" the default app language will be used`}
              />
            }
          >
            <DropdownFormField
              name="default_translation_language"
              options={languagesWithAuto}
              label={
                <FormattedMessage
                  id="inbox.settings.translation.default"
                  defaultMessage="Default translation language"
                />
              }
            />
          </SettingsSection>
          <Box paddingTop={5}>
            <AIEntityGuides />
          </Box>
          <Stack direction="row" gap={2} justifyContent="flex-end">
            <Button
              disabled={!isDirty}
              onClick={onCancel}
              data-testid="inbox_settings_cancel"
              sx={{ alignSelf: 'end' }}
            >
              <FormattedMessage id="inbox.action.cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              onClick={onSubmit}
              variant="contained"
              data-testid="inbox_settings_save"
              sx={{ alignSelf: 'end' }}
            >
              <FormattedMessage id="inbox.action.save-changes" defaultMessage="Save changes" />
            </Button>
          </Stack>
        </Stack>
      </FormProvider>

      {renderLeaveDialog({
        title: intl.formatMessage({
          id: 'inbox.settings.dialog.header',
          defaultMessage: 'Discard changes?',
        }),
        content: intl.formatMessage({
          id: 'inbox.settings.dialog.message',
          defaultMessage: 'You have unsaved changes on this page. If you leave, they will be lost.',
        }),
      })}

      <ToneComparison isModalOpen={isComparisonOpen} setModalOpen={setComparisonOpen} />
    </ContentPane>
  );
}
