import { FormattedMessage } from 'react-intl';

import { Stack, Typography } from '@trustyou/ui';

import type { SubmitButtonProps } from '../submit-region';
import { SubmitButton } from './submit-button';

export function DirectResponseButton({ onSubmit, isDisabled }: SubmitButtonProps) {
  return (
    <Stack spacing={1.5} sx={{ alignItems: 'start' }}>
      <Typography variant="body2" color="text.secondary">
        <FormattedMessage
          defaultMessage="This response will be posted directly on the source"
          id="inbox.response.info.directResponse"
        />
      </Typography>
      <SubmitButton onClick={onSubmit} data-gtm-id="direct_response" disabled={isDisabled}>
        <FormattedMessage
          id="inbox.response.action.directResponse"
          defaultMessage="Submit response"
        />
      </SubmitButton>
    </Stack>
  );
}
