import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  body: {
    paddingX: '25%',
  },
  modalBody: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 5,
    paddingBottom: 2,
  },
  groupNameContainer: {
    height: '100%',
    '& form': {
      height: '100%',
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
    },
  },
  groupNameForm: { marginTop: 4 },
  groupFilterHeader: { marginTop: 5 },
  rulesStack: { alignItems: 'center', marginTop: 2 },
  conditionBox: { minWidth: '54px' },
  attributeBox: { minWidth: '220px' },
  addFilterButton: { marginTop: 2 },
  addFilterButtonIcon: { marginRight: 1 },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingX: 5,
    marginTop: 'auto',
  },
  footerNextBtn: {
    marginLeft: 'auto',
  },
};

export default styles;
