import type { IStyles } from '@trustyou/shared';

export const styles: IStyles = {
  nodesContainer: {
    display: 'flex',
    width: '100%',
    maxWidth: '1200px',
    justifyContent: 'space-between',
    gap: 3,
    margin: 'auto',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
  },
  topNodes: {
    display: 'flex',
    gap: { md: 0.5 },
    width: { xs: '100%', md: '200px' },
    paddingTop: 0,
    flexDirection: 'column',
    alignItems: 'center',
    '& > span:first-of-type': {
      marginTop: 0,
    },
    paddingBottom: 0,
    margin: { xs: 2, md: 0 },
    height: 'fit-content',
    borderRadius: '4px',
    overflow: 'hidden',
  },
  pageContainer: {
    flex: 1,
    overflow: 'auto',
  },
  reviewProviderGrid: {
    marginTop: 3,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
  },
  reviewProviderGridGapFill: {
    width: '292px',
    visibility: 'hidden',
    height: 0,
    paddingTop: '0 !important',
  },
  introWrapper: {
    mt: 3,
  },
};
