import { FormattedMessage } from 'react-intl';

import { useManageUploadEntities, useRouteUtils } from '@trustyou/shared';
import { EmptyStatePlaceholder } from '@trustyou/ui';

// eslint-disable-next-line @nx/enforce-module-boundaries
import personSelectingData from '../../../../../ui/src/lib/assets/illustrations/person-selecting-data.svg';

export function InboxEmptyState() {
  const { navigateWithBase } = useRouteUtils();

  const isUploadEntitiesAllowed = useManageUploadEntities();

  const navigateToEntities = () => {
    navigateWithBase('organization/entities');
  };

  return (
    <EmptyStatePlaceholder
      dataName="inbox"
      img={personSelectingData}
      title={<FormattedMessage id="inbox.empty-state.title" defaultMessage="No entities yet" />}
      description={
        isUploadEntitiesAllowed ? (
          <FormattedMessage
            id="inbox.empty-state.description.for-admin"
            defaultMessage="No entities added yet. When you import them, their reviews will show up here."
          />
        ) : (
          <FormattedMessage
            id="inbox.empty-state.description.for-read-only"
            defaultMessage="Once your admin imports entities, their reviews will show up here."
          />
        )
      }
      emptyStateHeader={{ marginTop: 3 }}
      emptyStateContainer={{ minHeight: '500px' }}
      action={
        isUploadEntitiesAllowed
          ? {
              testId: 'inbox-empty-state',
              message: (
                <FormattedMessage id="inbox.empty-state.button" defaultMessage="Go to Entities" />
              ),
              handler: navigateToEntities,
              styles: { marginTop: 3 },
            }
          : undefined
      }
    />
  );
}
