import type { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';

import { Box, Stack } from '@trustyou/ui';

import useAlertChangeling from '../../../service/hooks/changeling/useAlertChangeling';
import styles from './styles';

export type TopBarLayoutProps = {
  hideTopBar?: boolean;
  children?: ReactElement;
};

export const FullScreenLayout = ({ children }: TopBarLayoutProps) => {
  const { JSX: changelingAlert } = useAlertChangeling();

  return (
    <Stack sx={styles.container}>
      {changelingAlert}
      <Box component="main" sx={styles.main}>
        {children || <Outlet />}
      </Box>
    </Stack>
  );
};
