import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type InvitationState = {
  isInvited: boolean;
  setIsInvited: (isInvited: boolean) => void;
};

const useInvitationStore = create<InvitationState>()(
  persist<InvitationState>(
    (set) => ({
      isInvited: false,
      setIsInvited: (isInvited) => set({ isInvited }),
    }),
    {
      name: 'invitation-store',
    }
  )
);

export default useInvitationStore;
