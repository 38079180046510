/* eslint-disable import/prefer-default-export */
import type {
  CreateOrganization,
  CreateOrganizationResponse,
  EditOrganization,
  EditOrganizationResponse,
  Organization,
  OrganizationLogo,
  OrganizationsResponse,
  UpdateUserOrganization,
} from '../../../../types/model/organization';
import type { PaginationRequest } from '../../../../types/model/shared';
import { $assApi } from '../../../api';
import { ADMIN_ORGANIZATION_BASE_PATH, ORGANIZATION_BASE_PATH } from '../../path';

export async function isValidOrganizationName(name: string): Promise<boolean> {
  const { data } = await $assApi
    .get(`${ADMIN_ORGANIZATION_BASE_PATH}/is_valid_organization_name`, {
      params: {
        name,
      },
    })
    .then((response) => response.data)
    .catch(() => ({ data: { valid: false } }));
  return data.valid;
}

export async function createOrganization(
  organization: CreateOrganization
): Promise<CreateOrganizationResponse> {
  const { data } = await $assApi.post(
    `${ADMIN_ORGANIZATION_BASE_PATH}/create_organization`,
    organization
  );
  return data.data;
}

export async function editOrganization(
  organization: EditOrganization
): Promise<EditOrganizationResponse> {
  const { data } = await $assApi.post(
    `${ADMIN_ORGANIZATION_BASE_PATH}/update_organization`,
    organization
  );
  return data.data;
}

export async function editUserOrganization(
  organization: UpdateUserOrganization
): Promise<Organization> {
  const { data } = await $assApi.post(
    `${ORGANIZATION_BASE_PATH}/update_organization`,
    organization
  );
  return data.data;
}

export async function deleteOrganization(organizationId: string): Promise<string> {
  const { data } = await $assApi.delete(`${ADMIN_ORGANIZATION_BASE_PATH}/delete_organization`, {
    params: {
      organization_id: organizationId,
    },
  });
  return data;
}

export async function fetchOrganizations(
  { limit, offset }: PaginationRequest,
  sortKey: string,
  searchKey?: string
): Promise<OrganizationsResponse> {
  const { data } = await $assApi.get(`${ADMIN_ORGANIZATION_BASE_PATH}/get_organizations`, {
    params: {
      limit,
      offset,
      order: sortKey,
      ...(searchKey && { q: searchKey }),
    },
  });
  return data;
}

export async function fetchUserOrganization(withSummary?: boolean): Promise<Organization> {
  const { data } = await $assApi.get(`${ORGANIZATION_BASE_PATH}/get_organization`, {
    params: {
      ...(withSummary && { with_summary: withSummary }),
    },
  });
  return data.data;
}

export async function fetchOrganizationLogo(): Promise<OrganizationLogo> {
  const { data } = await $assApi.get(`${ORGANIZATION_BASE_PATH}/get_organization_logo`);
  return data.data;
}

export async function fetchOrganizationAdmin(id: string): Promise<Organization> {
  const { data } = await $assApi.get(`${ADMIN_ORGANIZATION_BASE_PATH}/get_organization`, {
    params: {
      id,
    },
  });
  return data;
}
