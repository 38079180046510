import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { yupResolver } from '@hookform/resolvers/yup';
import { useChangelingStore } from '@trustyou/shared';
import { Button, ComposableDrawerWithStickyFooter, Tab, Tabs } from '@trustyou/ui';
import * as yup from 'yup';

import { FILTERS_DRAWER_WIDTH } from '../../constants';
import { DrawerTabs, type FiltersForm } from '../../types';

const schema = yup.object().shape({
  status: yup.array().of(yup.string()),
  rangename: yup.string(),
  score: yup.array().of(yup.string()),
  source: yup.array().of(yup.string()),
  survey_type: yup.array().of(yup.string()),
});

export type FilterDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  currentTab: DrawerTabs;
  onChange: (event: React.SyntheticEvent<Element, Event>, value: DrawerTabs) => void;
  onApplyFilters: (form: FiltersForm) => void;
  ManageTabContent: React.ReactNode;
  FiltersTabContent: React.ReactNode;
};

export function ViewsDrawer({
  isOpen,
  onClose,
  currentTab,
  onChange,
  onApplyFilters,
  ManageTabContent,
  FiltersTabContent,
}: FilterDrawerProps) {
  const methods = useForm<FiltersForm>({ resolver: yupResolver(schema) });
  const { handleSubmit } = methods;
  const { isChangeling } = useChangelingStore();

  return (
    <FormProvider {...methods}>
      <ComposableDrawerWithStickyFooter
        anchor="right"
        open={isOpen}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: FILTERS_DRAWER_WIDTH,
            paddingTop: isChangeling ? 8 : 0,
          },
        }}
      >
        <ComposableDrawerWithStickyFooter.Header
          otherContent={
            <Tabs value={currentTab} onChange={onChange}>
              <Tab
                label={<FormattedMessage id="inbox.manage" defaultMessage="Manage" />}
                value={DrawerTabs.MANAGE}
              />
              <Tab
                label={<FormattedMessage id="inbox.filters" defaultMessage="Filters" />}
                value={DrawerTabs.FILTERS}
              />
            </Tabs>
          }
          sx={{ paddingBlock: 0 }}
        />
        <ComposableDrawerWithStickyFooter.Content>
          {currentTab === DrawerTabs.MANAGE && ManageTabContent}
          {currentTab === DrawerTabs.FILTERS && FiltersTabContent}
        </ComposableDrawerWithStickyFooter.Content>
        <ComposableDrawerWithStickyFooter.Footer
          primaryButton={
            currentTab === DrawerTabs.FILTERS && (
              <Button onClick={handleSubmit(onApplyFilters)} type="button" variant="contained">
                <FormattedMessage id="inbox.action.apply" defaultMessage="Apply" />
              </Button>
            )
          }
          secondaryButton={
            <Button onClick={onClose} type="button" color="inherit">
              {currentTab === DrawerTabs.MANAGE && (
                <FormattedMessage id="inbox.action.close" defaultMessage="Close" />
              )}
              {currentTab === DrawerTabs.FILTERS && (
                <FormattedMessage id="inbox.action.cancel" defaultMessage="Cancel" />
              )}
            </Button>
          }
          sx={{ boxShadow: 5 }}
        />
      </ComposableDrawerWithStickyFooter>
    </FormProvider>
  );
}
