import { FormattedMessage } from 'react-intl';

import {
  faOctagonCheck,
  faOctagonMinus,
  faTrash,
} from '@trustyou/fortawesome/pro-regular-svg-icons';
import { ActionCell } from '@trustyou/ui';

import { manageDrawer } from '../../../constants/messages/customAttributes';

type Props = {
  onRemove: () => void;
  onToggleDefault: () => void;
  isDefault?: boolean;
};

export const ActionButton = ({ isDefault, onRemove, onToggleDefault }: Props) => (
  <ActionCell
    testId="option-action-menu"
    actions={[
      {
        testId: 'option-action-delete',
        icon: faTrash,
        handler: onRemove,
        message: <FormattedMessage {...manageDrawer.optionSelectorDeleteOption} />,
      },
      {
        testId: 'option-action-set-default',
        icon: isDefault ? faOctagonMinus : faOctagonCheck,
        handler: onToggleDefault,
        message: (
          <FormattedMessage
            {...(isDefault
              ? manageDrawer.optionSelectorRemoveDefaultOption
              : manageDrawer.optionSelectorSetDefaultOption)}
          />
        ),
      },
    ]}
  />
);
