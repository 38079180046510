import { type ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  type FileProp,
  FormControl,
  ImageUploadDialog,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@trustyou/ui';

type ImageUploadInputFieldProps = {
  label: ReactNode;
  imageTypeList: { label: string }[];
  file: FileProp;
  setFile: (files: FileProp) => void;
  imageType: string | null;
  setImageType: (imageType: string | null) => void;
};

const imageTypes = [{ label: 'Banner' }, { label: 'Logo' }, { label: 'No image' }];

const ImageUploadInputField = ({
  label,
  imageTypeList,
  file,
  setFile,
  imageType,
  setImageType,
}: ImageUploadInputFieldProps) => (
  <Stack spacing={2}>
    <Typography variant="subtitle1" sx={{ fontWeight: '500' }}>
      {label}
    </Typography>
    <FormControl variant="standard" fullWidth sx={{ flexDirection: 'row', gap: 5 }}>
      <TextField
        select
        fullWidth
        sx={{ maxWidth: 240 }}
        value={imageType}
        onChange={(event) => setImageType(event.target.value)}
      >
        {imageTypeList.map((type) => (
          <MenuItem key={type.label} value={type.label}>
            {type.label}
          </MenuItem>
        ))}
      </TextField>
      {imageType !== 'No image' && (
        <ImageUploadDialog file={file} setFile={setFile} open={false} setOpen={() => {}} />
      )}
    </FormControl>
  </Stack>
);

export function ThemeEditorImageUploadContent() {
  //Image type
  const [surveyHeaderImageType, setSurveyHeaderImageType] = useState<string | null>('No image');
  const [emailInviteHeaderImageType, setEmailInviteHeaderImageType] = useState<string | null>(
    'No image'
  );
  const [emailInviteFooterImageType, setEmailInviteFooterImageType] = useState<string | null>(
    'No image'
  );

  //Image file
  const [surveyHeaderImage, setSurveyHeaderImage] = useState<FileProp>(null);
  const [emailInviteHeaderImage, setEmailInviteHeaderImage] = useState<FileProp>(null);
  const [emailInviteFooterImage, setEmailInviteFooterImage] = useState<FileProp>(null);
  return (
    <>
      <Typography variant="h6" sx={{ marginBottom: 3 }}>
        <FormattedMessage id="survey.common.images" defaultMessage="Images" />
      </Typography>
      <Stack spacing={7} sx={{ width: '100%' }}>
        <ImageUploadInputField
          label={
            <FormattedMessage
              id="survey.theme-editor.image-upload.survey-header"
              defaultMessage="Survey header"
            />
          }
          imageTypeList={imageTypes}
          file={surveyHeaderImage}
          setFile={setSurveyHeaderImage}
          imageType={surveyHeaderImageType}
          setImageType={setSurveyHeaderImageType}
        />
        <ImageUploadInputField
          label={
            <FormattedMessage
              id="survey.theme-editor.image-upload.email-invite.header"
              defaultMessage="Email invitation header"
            />
          }
          imageTypeList={imageTypes.filter((value) => value.label !== 'Logo')}
          file={emailInviteHeaderImage}
          setFile={setEmailInviteHeaderImage}
          imageType={emailInviteHeaderImageType}
          setImageType={setEmailInviteHeaderImageType}
        />
        <ImageUploadInputField
          label={
            <FormattedMessage
              id="survey.theme-editor.image-upload.email-invite.footer"
              defaultMessage="Email invitation footer"
            />
          }
          imageTypeList={imageTypes.filter((value) => value.label !== 'Logo')}
          file={emailInviteFooterImage}
          setFile={setEmailInviteFooterImage}
          imageType={emailInviteFooterImageType}
          setImageType={setEmailInviteFooterImageType}
        />
      </Stack>
    </>
  );
}
