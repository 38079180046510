import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import type { ButtonProps, DialogProps } from '@mui/material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@trustyou/ui';

type DiscardAlertDialogProps = DialogProps & {
  open: boolean;
  title: ReactNode;
  content: ReactNode;
  maxWidth?: DialogProps['maxWidth'];
  fullWidth?: DialogProps['fullWidth'];
  onClose?: DialogProps['onClose'];
  onCancel: ButtonProps['onClick'];
  onDiscard: ButtonProps['onClick'];
};

export function DiscardDialog({
  open,
  title,
  content,
  maxWidth = 'sm',
  fullWidth = true,
  onClose,
  onCancel,
  onDiscard,
}: DiscardAlertDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth={maxWidth} fullWidth={fullWidth}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="inherit">
          <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
        </Button>
        <Button onClick={onDiscard} autoFocus variant="contained" color="error">
          <FormattedMessage id="common.discard" defaultMessage="Discard" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
