import { Outlet } from 'react-router-dom';

import { ContentPane } from '@trustyou/ui';

import { NewReportButton } from './new-report-button';
import { ReportViewSelector } from './report-view-selector';

import styles from './styles';

export const Reports = () => (
  <ContentPane
    headerStyles={styles.contentPaneHeaderStyles}
    headers={[
      <>
        <ReportViewSelector />
        <NewReportButton sx={styles.newReportButton} />
      </>,
    ]}
  >
    <Outlet />
  </ContentPane>
);
