import type { ReactNode } from 'react';

import { faXmark } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Box, Drawer, StyledFontAwesomeIcon as Icon, IconButton, Typography } from '@trustyou/ui';

import styles, { getDrawerStyles } from './styles';

export type SecondaryDrawerProps = {
  header: ReactNode;
  children: ReactNode;
  open: boolean;
  onClose: () => void;
  width?: string;
  changelingMode?: boolean;
};

export const SecondaryDrawerHeader = ({
  header,
  width,
  onClose,
}: Pick<SecondaryDrawerProps, 'header' | 'onClose' | 'width'>) => {
  return (
    <Box sx={styles.header} width={width}>
      <Typography data-testid="secondary-drawer-header" variant="h6">
        {header}
      </Typography>
      <IconButton data-testid="secondary-drawer-close-button" sx={styles.close} onClick={onClose}>
        <Icon icon={faXmark} />
      </IconButton>
    </Box>
  );
};

export const SecondaryDrawer = ({
  header,
  children,
  open,
  onClose,
  width = '600px',
  changelingMode,
}: SecondaryDrawerProps) => (
  <Drawer anchor="right" open={open} onClose={onClose} sx={getDrawerStyles(width, changelingMode)}>
    <SecondaryDrawerHeader header={header} onClose={onClose} />
    <Box sx={styles.body}>{children}</Box>
  </Drawer>
);
