import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { faArrowUpRightFromSquare } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Stack,
  StyledFontAwesomeIcon,
  Typography,
} from '@trustyou/ui';

import type { FlagFormValues } from './form-drawer';
import { guidelinesMessages } from './messages';

const SURVEY_MODERATION_GUIDELINES_URL =
  'https://support.cxp.trustyou.com/hc/en-us/articles/20679281287325-Survey-moderation';

export function GuidelinesStep() {
  const intl = useIntl();
  const {
    control,
    formState: { errors },
  } = useFormContext<FlagFormValues>();

  const openGuidelinesPage = () => {
    window.open(SURVEY_MODERATION_GUIDELINES_URL, '_blank');
  };

  return (
    <Stack spacing={3} sx={{ alignItems: 'start' }}>
      <Typography>{intl.formatMessage(guidelinesMessages.disclaimer)}</Typography>
      <Button
        type="button"
        variant="outlined"
        endIcon={
          <StyledFontAwesomeIcon icon={faArrowUpRightFromSquare} style={{ fontSize: 'inherit' }} />
        }
        onClick={openGuidelinesPage}
      >
        <FormattedMessage
          id="inbox.review.flag-inappropriate.guidelines.button"
          defaultMessage="Guidelines"
        />
      </Button>
      <Controller
        name="agreeToGuidelines"
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <FormControl error={!!errors.agreeToGuidelines}>
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label={intl.formatMessage(guidelinesMessages.checkboxLabel)}
            />
            <FormHelperText sx={{ marginLeft: 4 }}>
              {errors.agreeToGuidelines?.message}
            </FormHelperText>
          </FormControl>
        )}
      />
    </Stack>
  );
}
