import { useAuthorizationStore, useMembershipStore } from '@trustyou/shared';

export const useOrgCenter = () => {
  const { tokenStore } = useAuthorizationStore();
  const { membership } = useMembershipStore();

  if (tokenStore && membership?.id && tokenStore[membership.id]) {
    return tokenStore[membership.id].org_center;
  }

  return undefined;
};
