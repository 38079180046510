import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  container: {
    paddingX: 3,
    marginBottom: 3,
    alignItems: 'center',
  },
  headerText: {
    marginTop: 8,
  },
  bodyPrimaryText: {
    maxWidth: '534px',
    textAlign: 'center',
  },
  stepsContainer: {
    justifyContent: 'center',
    marginTop: 10,
  },
  stepsStack: {
    marginTop: 2,
  },
  stepsText: {
    marginLeft: 1,
  },
  getStartedButton: {
    marginTop: 5,
  },
};

export default styles;
