import { useAlertStore } from '@trustyou/shared';
import { Alert, AlertTitle, Snackbar } from '@trustyou/ui';

const DEFAULT_AUTO_HIDE_DURATION = 5000; // milliseconds
const ALERT_MIN_WIDTH = '320px';
const ALERT_MAX_WIDTH = '480px';

export const AlertSnackbar = () => {
  const { closeSnackbar, severity, title, content } = useAlertStore();
  const isSnackbarOpen = Boolean(title || content);
  const isError = severity === 'error';

  return (
    <Snackbar
      open={isSnackbarOpen}
      onClose={closeSnackbar}
      autoHideDuration={isError ? undefined : DEFAULT_AUTO_HIDE_DURATION}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      sx={{ alignItems: 'flex-end', minHeight: 'auto !important' }}
    >
      {isSnackbarOpen ? (
        <Alert
          onClose={isError ? closeSnackbar : undefined}
          severity={severity}
          elevation={6}
          sx={{ minWidth: ALERT_MIN_WIDTH, maxWidth: ALERT_MAX_WIDTH }}
        >
          {title && <AlertTitle sx={{ margin: 0 }}>{title}</AlertTitle>}
          {content}
        </Alert>
      ) : undefined}
    </Snackbar>
  );
};
