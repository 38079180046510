import { FormattedMessage } from 'react-intl';

import { Box, Typography } from '@trustyou/ui';

import magnifyingGlassSearchingFile from '../../assets/illustrations/magnifying-glass-searching-file.svg';
import styles from './styles';

export const NoRowsOverlay = () => (
  <Box sx={styles.noRowsWrapper}>
    <img src={magnifyingGlassSearchingFile} alt="No rows" />
    <Box sx={styles.noRowsTextWrapper}>
      <Typography variant="h6">
        <FormattedMessage id="tyDesign.noResultsFound" defaultMessage="No results found" />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage
          id="tyDesign.noRowsDescription"
          defaultMessage="There are no results for your search. Please try again with a different keyword."
        />
      </Typography>
    </Box>
  </Box>
);
