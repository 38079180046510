import type { IStyles } from '@trustyou/shared';

export const styles: IStyles = {
  card: {
    width: '100%',
    minHeight: '124px',
  },
  cardTitle: { fontWeight: 500 },
  cardBody: { paddingTop: 0 },
  textBlock: { width: '100%' },
  nameField: {
    minWidth: '396px',
  },
};
