import {
  type AccessGroup,
  type Entity,
  type EntityShort,
  type SegmentGroup,
  USER_SCOPES,
} from '@trustyou/shared';
import { create } from 'zustand';

type ScopeState = {
  selectedScope: USER_SCOPES;
  setSelectedScope: (scope: USER_SCOPES) => void;

  selectedEntities: Entity[] | EntityShort[];
  setSelectedEntities: (entities: Entity[] | EntityShort[]) => void;

  selectedGroups: SegmentGroup[];
  setSelectedGroups: (groups: SegmentGroup[]) => void;

  selectedAccessGroup?: AccessGroup;
  setSelectedAccessGroup: (accessGroup?: AccessGroup) => void;

  isValid: () => boolean;
};

export const useScopeStore = create<ScopeState>((set, get) => ({
  selectedScope: 'none' as USER_SCOPES.NONE,
  setSelectedScope: (selectedScope) => set(() => ({ selectedScope })),

  selectedEntities: [],
  setSelectedEntities: (selectedEntities) => set(() => ({ selectedEntities })),

  selectedGroups: [],
  setSelectedGroups: (selectedGroups) => set(() => ({ selectedGroups })),

  setSelectedAccessGroup: (selectedAccessGroup) => set(() => ({ selectedAccessGroup })),

  isValid: () =>
    get().selectedScope === USER_SCOPES.ALL_ENTITIES ||
    (get().selectedScope === USER_SCOPES.BY_ENTITIES && get().selectedEntities.length >= 0) ||
    (get().selectedScope === USER_SCOPES.ACCESS_GROUP && !!get().selectedAccessGroup) ||
    (get().selectedScope === USER_SCOPES.BY_GROUPS && get().selectedGroups.length > 0) ||
    get().selectedScope === USER_SCOPES.NONE,
}));
