import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Stack, Typography } from '@trustyou/ui';

import type { ResponseFormSchema } from '../../../../types';
import type { SubmitButtonProps } from '../submit-region';
import { SubmitButton } from './submit-button';

export function PublicSurveyRespondButton({ onSubmit, isDisabled }: SubmitButtonProps) {
  const { getValues } = useFormContext<ResponseFormSchema>();

  const alsoSendViaEmail = getValues('alsoSendViaEmail');

  return (
    <Stack spacing={1.5} sx={{ alignItems: 'start' }}>
      <Typography variant="body2" color="text.secondary">
        <FormattedMessage
          id="inbox.response.info.visibleInWidget"
          defaultMessage="This response will be shown in your hotel's widget"
        />
      </Typography>
      <SubmitButton onClick={onSubmit} data-gtm-id="survey_response" disabled={isDisabled}>
        {alsoSendViaEmail ? (
          <FormattedMessage
            id="inbox.response.action.respond-and-send-email"
            defaultMessage="Respond & send email"
          />
        ) : (
          <FormattedMessage id="inbox.response.action.respond" defaultMessage="Respond" />
        )}
      </SubmitButton>
    </Stack>
  );
}
