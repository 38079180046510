const BASE_KEY = 'ASS_FRONTEND';

export const setSessionState = <T>(key: string, value: T): void => {
  try {
    sessionStorage.setItem(`${BASE_KEY}_${key}`, JSON.stringify(value));
  } catch (error) {
    console.error('Error setting item in session storage:', error);
  }
};

export const getSessionState = <T>(key: string): T | null => {
  try {
    const item = sessionStorage.getItem(`${BASE_KEY}_${key}`);
    return item ? JSON.parse(item) : null;
  } catch (error) {
    console.error('Error getting item from session storage:', error);
    return null;
  }
};
