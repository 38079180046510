import { grey } from '@mui/material/colors';
import type { IStyles } from '@trustyou/shared';

const listItemButton = {
  paddingY: 0.5,
  '.MuiListItemIcon-root': {
    minWidth: 'unset',
  },
};

const styles: IStyles = {
  list: {
    marginTop: 3,
    border: `1px solid ${grey[300]}`,
    borderRadius: '4px',
  },
  listItemButton,
  listTopItemButton: {
    ...listItemButton,
    borderBottom: `1px solid ${grey[300]}`,
  },
  listSubItemButton: {
    ...listItemButton,
    paddingLeft: 5,
  },
};

export default styles;
