import { DASHBOARD_WIDTH, type IStyles } from '@trustyou/shared';

const styles: IStyles = {
  dashboardContainer: {
    gap: 3,
    width: `${DASHBOARD_WIDTH}px !important`,
    marginX: 'auto',
    height: '100%',
    minHeight: '650px',
    '> iframe': {
      border: 'none',
      width: '100%',
      height: 'calc(100% - 72px)',
    },
  },
  loaderContainer: { marginX: 'auto', marginBottom: 'auto' },
  loaderHeader: { marginTop: 3 },
  loaderIcon: { marginTop: 5 },
};

export default styles;
