import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { LoadingPlaceholder } from '@trustyou/ui';

import personUploadingFile from '../../assets/illustrations/person-uploading-file.svg';

const SpreadsheetUploading = () => (
  <LoadingPlaceholder
    img={personUploadingFile}
    title={
      <FormattedMessage
        id="uploadingSpreadsheet.uploadingFile"
        defaultMessage="Uploading file..."
      />
    }
    description={
      <FormattedMessage
        id="uploadingSpreadsheet.loaderMessage"
        defaultMessage="We're uploading and processing your data. This may take a few minutes"
      />
    }
    loaderContainer={{ marginTop: 6 }}
    loaderHeader={{ marginTop: 3 }}
    loaderIcon={{ marginTop: 5 }}
  />
);

export default SpreadsheetUploading;
