import { DragDropContext, type DropResult, Droppable } from 'react-beautiful-dnd';

import { List, Stack } from '@trustyou/ui';

import { ViewsEmptyState } from './views-empty-state';

import type { View } from '../../types';
import { SortableViewItem } from './sortable-view-item';

export type ManageTabContentProps = {
  views: View[];
  onReorder: (result: DropResult) => void;
  onDelete: (viewToDelete: View) => void;
};

export const ManageTabContent = ({ views, onReorder, onDelete }: ManageTabContentProps) => {
  if (!views.length) {
    return <ViewsEmptyState />;
  }

  return (
    <Stack sx={{ margin: -2 }}>
      <DragDropContext onDragEnd={onReorder}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <List ref={provided.innerRef} {...provided.droppableProps}>
              {views.map((view, index) => (
                <SortableViewItem view={view} index={index} onDelete={onDelete} />
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </Stack>
  );
};
