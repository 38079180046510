import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  dialog: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        width: '600px',
      },
    },
  },
  dialogSubTitle: { marginTop: 2 },
  dialogContent: { height: '440px' },
  selectionCard: {
    minHeight: '56px',
    flexDirection: 'row',
    gap: 2,
  },
};

export default styles;
