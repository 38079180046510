import type { SxProps, Theme } from '@mui/material';
import { Box, Tooltip } from '@trustyou/ui';

export const CustomTooltip = ({
  title,
  children,
  sx,
}: {
  title: string;
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}) => {
  return (
    <Tooltip arrow placement="top" title={title}>
      <Box sx={sx}>{children}</Box>
    </Tooltip>
  );
};
