import { FormattedMessage } from 'react-intl';

import type { GridColDef } from '@mui/x-data-grid-pro';
import { DataGrid, Typography } from '@trustyou/ui';

import TableInfoHeader from '../TableInfoHeader';
import styles from './styles';

const tableColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    sortable: false,
  },
  {
    field: 'sector',
    headerName: 'Sector',
    renderHeader: TableInfoHeader,
    flex: 1,
    sortable: false,
  },
  {
    field: 'brand',
    headerName: 'Brand',
    flex: 1,
    sortable: false,
  },
  {
    field: 'countryCode',
    headerName: 'Country code',
    renderHeader: TableInfoHeader,
    flex: 1,
    sortable: false,
  },
  {
    field: 'city',
    headerName: 'City',
    flex: 1,
    sortable: false,
  },
  {
    field: 'zip',
    headerName: 'Zip',
    flex: 1,
    sortable: false,
  },
  {
    field: 'address',
    headerName: 'Address',
    flex: 1,
    sortable: false,
  },
  {
    field: 'stars',
    headerName: 'Stars (custom attribute)',
    renderHeader: TableInfoHeader,
    width: 220,
    sortable: false,
  },
];

const tableRows = [
  {
    id: 1,
    name: 'Sapphire Hotel',
    sector: 'Accommodation',
    brand: 'Sapphire',
    countryCode: 'DE',
    city: 'Munich',
    zip: '81369',
    address: 'Steinerstr. 15',
    stars: '5 stars',
  },
];

const FieldsExampleTable = () => (
  <>
    <Typography variant="body2" sx={styles.mandatoryFieldsHeader}>
      <FormattedMessage
        id="entitiesImporter.exampleOfMandatoryFieldsAndCustomAttribute"
        defaultMessage="Example of mandatory fields and one custom attribute:"
      />
    </Typography>
    <DataGrid
      columns={tableColumns}
      rows={tableRows}
      disableColumnResize
      disableColumnReorder
      sx={styles.table}
    />
  </>
);

export default FieldsExampleTable;
