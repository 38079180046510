export const MOCK_REVIEWS = [
  {
    id: 'review_id_1',
    title: 'Review Title 1',
    text: 'Consectetur ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae tortor varius, convallis nulla a, convallis mauris. Aenean eleifend, mi non euismod blandit, metus libero fermentum elit, nec sollicitudin magna arcu a mi. Nulla facilisi. Nam malesuada interdum justo, a ultrices justo convallis at. Proin in dolor mi. In sem quam, fringilla vitae fermentum et, accumsan et lorem. Suspendisse potenti. Duis ac efficitur risus, ut pharetra sapien.',
    matches: ['sit', 'consectetur', 'arcu'],
  },
  {
    id: 'review_id_2',
    title: 'Review Title 2',
    text: 'Sed efficitur congue ligula, ut bibendum justo feugiat id. Maecenas euismod est nec justo auctor, non commodo urna dictum. Integer in quam metus. Morbi fringilla laoreet mauris, nec tincidunt sem sodales in. Phasellus nec magna vel nulla accumsan accumsan. Nullam aliquam non lacus eget finibus. Donec suscipit volutpat metus, nec faucibus odio tempus vel.',
    matches: ['ligula', 'justo', 'tempus'],
  },
  {
    id: 'review_id_3',
    title: 'Review Title 3',
    text: 'Quisque euismod eros vel metus sodales, a gravida tortor sodales. Sed scelerisque purus quis lorem eleifend convallis. Nullam et nisi nisl. Aliquam eu tortor suscipit, vehicula lacus sit amet, tincidunt felis. Aenean sed nibh rutrum, vestibulum odio nec, lacinia velit. Mauris sagittis risus sed turpis dictum, vitae condimentum mi pellentesque. In non enim id lectus rutrum iaculis.',
    matches: ['gravida', 'sagittis', 'condimentum'],
  },
  {
    id: 'review_id_4',
    title: 'Review Title 4',
    text: 'Phasellus vulputate mi sit amet libero faucibus, vitae dapibus lorem venenatis. Curabitur pharetra nunc vel viverra posuere. Integer consequat diam et massa egestas ullamcorper. Duis dignissim arcu vel eros rutrum, ut dictum lectus auctor. Nulla auctor velit at lacus interdum consequat. Cras efficitur, tortor quis pharetra vehicula, leo libero convallis elit, eu bibendum magna nulla id magna.',
    matches: ['faucibus', 'viverra', 'convallis'],
  },
  {
    id: 'review_id_5',
    title: 'Review Title 5',
    text: 'Nam dictum dui ligula, et faucibus elit ullamcorper et. Nullam at tortor vitae est tincidunt pharetra. Pellentesque at nisi ac quam malesuada dapibus. Sed fermentum augue nec eros venenatis, vel hendrerit sem tincidunt. Integer luctus nisl odio, nec scelerisque dui tincidunt nec. Ut suscipit enim eget quam aliquam, vel eleifend libero fermentum. Vestibulum condimentum mi quis diam dignissim, nec vehicula lectus aliquet.',
    matches: ['dictum', 'tincidunt', 'aliquam'],
  },
  {
    id: 'review_id_6',
    title: 'Review Title 6',
    text: 'Etiam auctor ante quis risus laoreet, nec accumsan enim ultricies. Nulla efficitur eros in ligula pharetra vestibulum. Sed ultricies mauris a fermentum bibendum. In hac habitasse platea dictumst. Sed ullamcorper elit sit amet nisi ultricies dignissim. Quisque non dui commodo, efficitur orci in, rutrum metus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Vivamus tempus nisl eros, ut suscipit odio vestibulum et.',
    matches: ['risus', 'ultricies', 'ante'],
  },
  {
    id: 'review_id_7',
    title: 'Review Title 7',
    text: 'Vestibulum pulvinar metus sed facilisis auctor. Fusce eget arcu elit. Curabitur vitae nunc sit amet nisi fermentum euismod ut ut sem. Fusce vitae felis vestibulum, lobortis turpis sit amet, fringilla libero. Nam et sapien purus. Nulla facilisi. Cras sed sapien ac lorem placerat dictum. Donec vitae orci a velit feugiat dictum et eu mauris. Suspendisse ut posuere quam.',
    matches: ['metus', 'sed', 'vitae'],
  },
  {
    id: 'review_id_8',
    title: 'Review Title 8',
    text: 'Integer sed tortor eget metus tincidunt molestie. Sed tempus commodo mauris. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Mauris et purus et turpis convallis consequat. Morbi accumsan odio at lacinia eleifend. In tincidunt justo ac scelerisque facilisis. In vehicula, purus at placerat varius, odio mi fermentum magna, vitae bibendum metus enim eget nisi.',
    matches: ['Integer', 'tincidunt', 'ac'],
  },
  {
    id: 'review_id_9',
    title: 'Review Title 9',
    text: 'Pellentesque in tellus vitae leo suscipit condimentum ac sit amet nisi. Praesent nec enim in nisl suscipit lacinia. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Aenean tempor leo ut mi accumsan, vitae lobortis ex sollicitudin. Aliquam erat volutpat. Aenean nec arcu purus. Sed malesuada, metus ac tempus rhoncus, purus neque eleifend arcu, sit amet pharetra orci magna et purus.',
    matches: ['leo', 'ante', 'purus'],
  },
  {
    id: 'review_id_10',
    title: 'Review Title 10',
    text: 'Fusce interdum lacinia est, at fermentum magna, vitae bibendum metus enim eget nisi.',
    matches: ['foo', 'bar', 'baz'],
  },
];
