import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  emptyStateHeader: {
    marginTop: 3,
  },
  emptyStateContainer: { marginTop: 3 },
  addCompetitorIcon: {
    marginRight: 1,
  },
  actionButton: { marginTop: 4 },
};

export default styles;
