import type { AccessTokenResponse } from '@trustyou/shared';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { AuthPendingState } from '../types';

export interface AccessTokenStore {
  [organizationId: string]: {
    org_center: AccessTokenResponse | null;
    analytics: {
      sso_token: string;
    } | null;
  };
}

type AuthState = {
  tokenStore: AccessTokenStore | null;
  setTokenStore: (token: AccessTokenStore | null) => void;
  pendingState: AuthPendingState;
  setPendingState: (pendingState: AuthPendingState) => void;
};

const authorizationStorePersist = () =>
  persist<AuthState>(
    (set) => ({
      tokenStore: null,
      setTokenStore: (tokenStore) => set({ tokenStore }),
      pendingState: AuthPendingState.IDLE,
      setPendingState: (pendingState) => {
        set({ pendingState });
      },
    }),
    { name: 'authorization-store', storage: createJSONStorage(() => sessionStorage) }
  );

export const useAuthorizationStore = create<AuthState>()(authorizationStorePersist());
