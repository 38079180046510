import { FormattedMessage, useIntl } from 'react-intl';

import {
  type CombinedUserAndInvitation,
  FETCH_COMBINED_ORGANIZATION_USERS,
  SEARCH_DEBOUNCE_TIME_MS,
  USER_STATUSES,
  UserViewTypes,
  getPaginationRequest,
  getSortRequest,
  searchPlaceholders,
  useAlertStore,
  useChangelingStore,
  useCombinedUsersByOrganization,
  useCombinedUsersGridStore,
  useCurrentSubscription,
  useDeleteInvitation,
  useDeleteUser,
  useGridParams,
  useMembershipStore,
  useResendInvitation,
  useResponsive,
  useSubscription,
  useUserNavStore,
} from '@trustyou/shared';
import {
  BackdropSpinner,
  Box,
  ContentPane,
  EmptyMemberState,
  SearchBar,
  SecondaryDrawer,
  UserDetailsView,
} from '@trustyou/ui';

import InvitationDeleteModal from './components/DeleteModal/InvitationDeleteModal';
import UserDeleteModal from './components/DeleteModal/UserDeleteModal';
import { InviteUpdateUser } from './components/InviteUpdateUser';
import UsersTable from './components/UsersTable/UsersTable';

import {
  useCurrentUser,
  useManageEntitySubscriptions,
  useManageSubscription,
  useManageUsers,
} from '../../../hooks';
import styles from './styles';

export const AllUsers = () => {
  const intl = useIntl();
  const { isMobileRange } = useResponsive();
  const { alert } = useAlertStore();
  const { membership } = useMembershipStore();
  const { isChangeling } = useChangelingStore();
  const isEntitySubscriptionAllowed = useManageEntitySubscriptions();
  const isSubscriptionAllowed = useManageSubscription();

  const { view, navToList, navToEdit, navToDetail, navToDelete, navToDeleteDiscard } =
    useUserNavStore();
  const { type, user, isDeleting } = view;

  const gridState = useCombinedUsersGridStore();

  const { paginationModel, sortModel, searchKey } = gridState;

  const { onPaginationModelChange, onSortModelChange, onSearch } = useGridParams(
    FETCH_COMBINED_ORGANIZATION_USERS,
    gridState
  );

  const { data, isFetching } = useCombinedUsersByOrganization(
    getPaginationRequest(paginationModel),
    getSortRequest(sortModel),
    { searchKey },
    membership?.organizationId
  );

  const { data: currentSubscription } = useCurrentSubscription();
  const { data: userSubscription } = useSubscription(
    isSubscriptionAllowed ? user?.subscription.id : undefined
  );

  const currentUser = useCurrentUser();

  const isManageUserAllowed = useManageUsers();

  const { mutate: handleDeleteUser, isPending: isDeleteUserInProcess } = useDeleteUser(
    () => {
      alert.info(
        <FormattedMessage
          id="organization.users.userRemovedAlert"
          defaultMessage="{user} removed"
          values={{ user: user?.name || user?.email }}
        />
      );
    },
    () => {
      alert.genericError();
    },
    navToList
  );

  const { mutate: handleResendInvitation, isPending: isResendInProcess } = useResendInvitation(
    (email: string) => {
      alert.success(
        <FormattedMessage
          id="organization.users.invitationResendAlert"
          defaultMessage="Invitation to {email} is resent"
          values={{ email }}
        />
      );
    },
    () => {
      alert.genericError();
    },
    navToList
  );

  const { mutate: handleDeleteInvitation, isPending: isDeleteInvitationInProcess } =
    useDeleteInvitation(
      () => {
        alert.info(
          <FormattedMessage
            id="organization.users.invitationDeleteAlert"
            defaultMessage="Invitation for {email} was canceled"
            values={{ email: user?.email }}
          />
        );
      },
      () => {
        alert.genericError();
      },
      navToList
    );

  const onResendInvitation = (chosenUser: CombinedUserAndInvitation) => {
    handleResendInvitation({
      email: chosenUser.email,
      invitation_id: chosenUser.membership_id,
    });
  };

  const onDeleteUser = () => {
    handleDeleteUser(user?.membership_id as string);
  };

  const onDeleteInvitation = () => {
    handleDeleteInvitation(user?.membership_id as string);
  };

  const enabledColumns = ['name', 'role', 'scope', 'status', 'action'];

  if (isEntitySubscriptionAllowed) {
    enabledColumns.push('subscription');
  }

  if (type === UserViewTypes.INVITE)
    return (
      <InviteUpdateUser subscription={currentSubscription} user={undefined} onCancel={navToList} />
    );

  if (type === UserViewTypes.EDIT)
    return (
      <InviteUpdateUser
        subscription={isSubscriptionAllowed ? userSubscription : currentSubscription}
        user={user}
        onCancel={navToList}
      />
    );

  if (data?.pagination?.total === 0 && !searchKey)
    return (
      <ContentPane>
        <EmptyMemberState />
      </ContentPane>
    );

  return (
    <>
      <ContentPane sx={{ border: 0 }} bodyStyles={{ padding: 0 }}>
        <Box sx={styles.searchBar}>
          <SearchBar
            onSearch={onSearch}
            debounceTime={SEARCH_DEBOUNCE_TIME_MS}
            placeholder={intl.formatMessage(searchPlaceholders.searchMembers)}
            id="users"
            variant="standard"
            defaultValue={searchKey}
          />
        </Box>
        <UsersTable
          data={data}
          isLoading={isFetching}
          handleUserDetails={navToDetail}
          handleEdit={navToEdit}
          handleDelete={navToDelete}
          handleResendInvitation={onResendInvitation}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          paginationModel={paginationModel}
          onPaginationModelChange={onPaginationModelChange}
          containerStyle={styles.tableContainer}
          enabledColumns={enabledColumns}
        />
      </ContentPane>
      <SecondaryDrawer
        header={null}
        open={type === UserViewTypes.DETAIL && !!user}
        onClose={navToList}
        width="400px"
        changelingMode={isChangeling}
      >
        {type === UserViewTypes.DETAIL && user && (
          <UserDetailsView
            user={user}
            isManageUserAllowed={isManageUserAllowed}
            handleDeleteUser={navToDelete}
            handleEditUser={!isMobileRange ? navToEdit : undefined}
            currentUser={currentUser}
          />
        )}
      </SecondaryDrawer>
      <UserDeleteModal
        onClose={navToDeleteDiscard}
        onDelete={onDeleteUser}
        user={isDeleting && user?.status === USER_STATUSES.ACTIVE ? user : undefined}
      />
      <InvitationDeleteModal
        onClose={navToDeleteDiscard}
        onDelete={onDeleteInvitation}
        invitation={isDeleting && user?.status === USER_STATUSES.INVITED ? user : undefined}
      />
      <BackdropSpinner
        isLoading={isDeleteUserInProcess || isDeleteInvitationInProcess || isResendInProcess}
      />
    </>
  );
};
