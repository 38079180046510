import type { ReactNode } from 'react';

import { Box, Chip, Typography } from '@trustyou/ui';

import styles from './styles';

export interface InstructionCardProps {
  stepNumber: number;
  title: ReactNode;
  description: ReactNode;
}

export const InstructionCard = ({ stepNumber, title, description }: InstructionCardProps) => (
  <Box sx={styles.wrapper}>
    <Box sx={styles.titleWrapper}>
      <Chip label={stepNumber} size="small" color="secondary" />
      <Typography variant="subtitle2">{title}</Typography>
    </Box>
    <Typography variant="body2">{description}</Typography>
  </Box>
);
