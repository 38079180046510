import type { ReactNode } from 'react';

import { Stack } from '@trustyou/ui';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

export function TabPanel({ children, value, index, ...props }: TabPanelProps) {
  return (
    <Stack
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...props}
    >
      {value === index && children}
    </Stack>
  );
}
