import { useQuery } from '@tanstack/react-query';
import { $assApi, SURVEY_BASE_PATH } from '@trustyou/shared';

import type { QuestionnaireByEntity, Questionnaire_Output, Survey } from '../../client';
import type { SurveyRowByEntity } from '../../types/survey';
import { QUESTIONNAIRE_LIST, QUESTIONNAIRE_LIST_BY_ENTITY } from '../constants/query-keys';

export async function fetchQuestionnaires({
  surveyId,
}: {
  surveyId: Survey['id'];
}): Promise<Questionnaire_Output[]> {
  const { data } = await $assApi.get(`${SURVEY_BASE_PATH}/survey/list-questionnaires/${surveyId}`);
  return data;
}

export async function fetchQuestionnairesByEntity({
  surveyId,
}: {
  surveyId: Survey['id'];
}): Promise<SurveyRowByEntity[]> {
  const { data } = await $assApi.get(
    `${SURVEY_BASE_PATH}/survey/list-questionnaires/${surveyId}?by_entity=true`
  );
  return (data as QuestionnaireByEntity[]).map((item) => ({
    id: item.entity.id,
    // Some questionnaires could have a null entity name, so we display the entity id in those cases.
    entity: item.entity.name ?? item.entity.id,
    version: item.questionnaire.internal_name,
    url: item.submit_url,
  }));
}

export function useQuestionnaires({ surveyId }: { surveyId: Survey['id'] }) {
  return useQuery({
    queryKey: [QUESTIONNAIRE_LIST, { surveyId }],
    queryFn: () => fetchQuestionnaires({ surveyId }),
  });
}

export function useQuestionnairesByEntity({ surveyId }: { surveyId: Survey['id'] }) {
  return useQuery({
    queryKey: [QUESTIONNAIRE_LIST_BY_ENTITY, { surveyId }],
    queryFn: () => fetchQuestionnairesByEntity({ surveyId }),
  });
}
