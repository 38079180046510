import type { ReactElement, ReactNode } from 'react';

import type { SubscriptionStatus } from '@trustyou/shared';
import { Card, CardContent, CardHeader, Stack, Typography } from '@trustyou/ui';

import { SUBSCRIPTION_STATUS_CHIP_MAP } from '../../../components';
import { styles } from './styles';

type ContentCardProps = {
  title: ReactNode;
  status?: SubscriptionStatus;
  children: ReactElement;
};

const ContentCard = ({ title, status, children }: ContentCardProps) => (
  <Card variant="outlined" sx={styles.card}>
    <CardHeader
      title={
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="subtitle1" sx={styles.cardTitle}>
            {title}
          </Typography>
          {status && SUBSCRIPTION_STATUS_CHIP_MAP[status]}
        </Stack>
      }
    />
    <CardContent sx={styles.cardBody}>{children}</CardContent>
  </Card>
);

export default ContentCard;
