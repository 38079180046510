import { EmailSettingsForm } from '@trustyou/inbox';
import { ContentPane } from '@trustyou/ui';

import { styles } from '../styles';

export function NotificationsSettings() {
  return (
    <ContentPane sx={styles.generalPageContainer}>
      <EmailSettingsForm />
    </ContentPane>
  );
}
