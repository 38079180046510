import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { LoadingButton } from '@mui/lab';
import { useQueryClient } from '@tanstack/react-query';
import { useAlertStore } from '@trustyou/shared';

import { DeleteReportModal } from './DeleteReportModal';
import { useDeleteReportById } from './hooks/reports';
import { reportQueryKeys } from './hooks/reportsQueryKeys';
import { commonMessages } from './messages';

export const DeleteReport = ({
  reportId,
  onDelete,
}: {
  onDelete: () => void;
  reportId: string;
}) => {
  const queryClient = useQueryClient();
  const { alert } = useAlertStore();
  const intl = useIntl();
  const [shouldDelete, setShouldDelete] = useState(false);
  const { mutate: deleteReport, isPending: isLoading } = useDeleteReportById(
    reportId,
    () => {
      queryClient.refetchQueries({ queryKey: reportQueryKeys.lists() });
      alert.info(intl.formatMessage(commonMessages.deleteReportSuccess));
      onDelete();
    },
    () => {
      alert.genericError();
    }
  );
  return (
    <>
      <LoadingButton
        data-testId="analytics-delete-report"
        data-gtm-class="analytics-delete-report"
        data-gtm-id="analytics-delete-report"
        loading={isLoading}
        onClick={() => {
          setShouldDelete(true);
        }}
        variant="text"
        color="error"
      >
        <FormattedMessage {...commonMessages.deleteReportButton} />
      </LoadingButton>
      <DeleteReportModal
        open={shouldDelete}
        onClose={() => {
          setShouldDelete(false);
        }}
        onDelete={() => {
          setShouldDelete(false);
          deleteReport();
        }}
      />
    </>
  );
};
