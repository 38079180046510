import { FormattedMessage } from 'react-intl';

import { faCircleXmark, faTriangleExclamation } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { actionMessages } from '@trustyou/shared';
import { StyledFontAwesomeIcon as Icon, InfoDialog, Stack, Typography } from '@trustyou/ui';

import { manageDrawer } from '../../constants/messages/customAttributes';

export type UpdateConfirmationData = {
  entitiesCount?: number;
  renamed?: string[];
  deleted?: string[];
  new?: string[];
};

type Props = {
  data?: UpdateConfirmationData;
  onCancel: () => void;
  onSave: () => void;
};

export const UpdateConfirmationDialog = ({ data, onCancel, onSave }: Props) => (
  <InfoDialog
    title={<FormattedMessage {...manageDrawer.updateConfirmationDialogTitle} />}
    open={!!data}
    primaryActionText={<FormattedMessage {...actionMessages.save} />}
    secondaryActionText={<FormattedMessage {...actionMessages.cancel} />}
    onPrimaryAction={onSave}
    onSecondaryAction={onCancel}
    dataName="custom-attribute-update-confirmation"
    maxWidth="sm"
  >
    <Stack gap={1.5} paddingX={3} paddingY={1.5}>
      <Typography variant="body1">
        <FormattedMessage
          {...manageDrawer.updateConfirmationDialogPrimaryContent}
          values={{ entities_count: data?.entitiesCount }}
        />
      </Typography>
      {data?.renamed && !!data.renamed.length && (
        <Stack gap={1} direction="row" alignItems="center">
          <Icon sx={{ color: 'info.main' }} icon={faTriangleExclamation} />
          <Typography variant="subtitle1" minWidth="100px">
            <FormattedMessage {...manageDrawer.updateConfirmationDialogRenamed} />
          </Typography>
          <Typography variant="body1">{data?.renamed?.join(', ')}</Typography>
        </Stack>
      )}
      {((data?.deleted && !!data.deleted.length) || (data?.new && !!data.new.length)) && (
        <>
          <Typography variant="body1" paddingTop={2}>
            <FormattedMessage {...manageDrawer.updateConfirmationDialogSecondaryContent} />
          </Typography>
          {data?.deleted && !!data.deleted.length && (
            <Stack gap={1} direction="row" alignItems="center">
              <Icon sx={{ color: 'error.main' }} icon={faCircleXmark} />
              <Typography variant="subtitle1" minWidth="100px">
                <FormattedMessage {...manageDrawer.updateConfirmationDialogDeleted} />
              </Typography>
              <Typography variant="body1">{data?.deleted?.join(', ')}</Typography>
            </Stack>
          )}
          {data?.new && !!data.new.length && (
            <Stack gap={1} direction="row" alignItems="center">
              <Icon sx={{ color: 'info.main' }} icon={faTriangleExclamation} />
              <Typography variant="subtitle1" minWidth="100px">
                <FormattedMessage {...manageDrawer.updateConfirmationDialogNew} />
              </Typography>
              <Typography variant="body1">{data?.new?.join(', ')}</Typography>
            </Stack>
          )}
        </>
      )}
    </Stack>
  </InfoDialog>
);
