import {
  type MembershipFromToken,
  useChangelingStore,
  useMembershipStore,
  useOrganizationLogo,
} from '@trustyou/shared';
import { Box } from '@trustyou/ui';

import { getMembershipIdFromUrl, redirectToOriginalMembership } from '../../../utils/misc';
import MembershipSelector from '../../MembershipSelector/MembershipSelector';
import styles from './styles';

type OrganizationsMenuProps = {
  onClick?: () => void;
};

export const OrganizationsMenu = ({ onClick }: OrganizationsMenuProps) => {
  const { membership, setMembership, organizations } = useMembershipStore();
  const membershipIdFromUrl = getMembershipIdFromUrl();
  const { data } = useOrganizationLogo();
  const { isChangeling } = useChangelingStore();

  if (!organizations || !organizations.length) return undefined;

  const changelingLogo = isChangeling ? data?.logo_uri : undefined;

  const onSelect = (value: MembershipFromToken) => {
    onClick?.();
    setMembership(value);
    if (membershipIdFromUrl) {
      redirectToOriginalMembership(value.id as string, { silent: true });
    } else {
      // we don't need to modify url if no organization id in the path. For example, dev tools
      window.location.reload();
    }
  };

  return (
    <Box sx={styles.orgContainer}>
      <MembershipSelector
        organizations={organizations}
        defaultLogo={changelingLogo}
        selectedMembership={membership?.id}
        onSelect={onSelect}
      />
    </Box>
  );
};
