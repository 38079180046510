import type { UIEventHandler } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_SORT_KEY,
  FETCH_INFINITE_SEGMENT_GROUPS,
  type Segment,
  isScrollEnd,
  useInfiniteSegments,
} from '@trustyou/shared';
import { List, ListItemButton, ListItemText } from '@trustyou/ui';

import styles from './styles';

type SegmentListProps = {
  selectedSegment?: Segment;
  onSegmentSelect: (segment: Segment) => void;
};

const SegmentList = ({ selectedSegment, onSegmentSelect }: SegmentListProps) => {
  const queryClient = useQueryClient();

  const { data, fetchNextPage, hasNextPage } = useInfiniteSegments(
    DEFAULT_PAGE_SIZE,
    DEFAULT_SORT_KEY
  );

  const selectSegment = (segment: Segment) => {
    if (selectedSegment) queryClient.removeQueries({ queryKey: [FETCH_INFINITE_SEGMENT_GROUPS] });
    onSegmentSelect(segment);
  };

  const onScrollReact: UIEventHandler<HTMLUListElement> = (event) => {
    if (hasNextPage && isScrollEnd(event.target as HTMLElement)) {
      fetchNextPage();
    }
  };

  const segments = data?.pages.map((page) => page.data).flat() || [];

  return (
    <List sx={styles.list} onScroll={onScrollReact}>
      {segments.map((segment) => (
        <ListItemButton
          key={segment.id}
          selected={selectedSegment?.id === segment.id}
          onClick={() => selectSegment(segment)}
          data-testid="segment-item"
          sx={styles.listItem}
        >
          <ListItemText primary={segment.name} />
        </ListItemButton>
      ))}
    </List>
  );
};

export default SegmentList;
