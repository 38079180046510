/* eslint-disable @typescript-eslint/no-explicit-any */
import { $assApi, INBOX_BASE_PATH } from '@trustyou/shared';
import axios from 'axios';

import type { SemaLanguage } from '../../../client';

type ObjectOfAny = Record<string, any>;
type ObjectOfStrings = Record<string, string>;

export async function fetchMetaCategory(shortLocale: string): Promise<ObjectOfAny> {
  const { data } = await axios.get(
    `https://analytics.trustyou.com/i18n/bundles/metacategory/${shortLocale}`
  );
  return data;
}

export async function fetchTopLevelCategoryMapping(): Promise<ObjectOfAny> {
  const { data } = await $assApi.get(`${INBOX_BASE_PATH}/sema-mappings/top-level-categories`);
  return data;
}

export async function fetchSubcategoryMapping(): Promise<ObjectOfAny> {
  const { data } = await $assApi.get(`${INBOX_BASE_PATH}/sema-mappings/subcategories`);
  return data;
}

export async function fetchTopLevelCategories(semaLanguage: SemaLanguage): Promise<ObjectOfAny> {
  const { data } = await $assApi.get(
    `${INBOX_BASE_PATH}/sema-mappings/top-level-category-translations`,
    {
      params: {
        language: semaLanguage,
      },
    }
  );
  return data;
}

export async function fetchSubCategories(semaLanguage: SemaLanguage): Promise<ObjectOfAny> {
  const { data } = await $assApi.get(`${INBOX_BASE_PATH}/sema-mappings/subcategory-translations`, {
    params: {
      language: semaLanguage,
    },
  });
  return data;
}

export async function fetchSemaLanguages(semaLanguage: SemaLanguage): Promise<ObjectOfStrings> {
  const { data } = await $assApi.get(`${INBOX_BASE_PATH}/sema-mappings/get-languages`, {
    params: {
      language: semaLanguage,
    },
  });
  return data;
}
