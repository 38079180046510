import { useEffect, useState } from 'react';

type ScriptTypes = {
  tag: string;
  src: string;
  enabled?: boolean;
  onLoadCallback?: () => void;
};

export const useScript = ({ tag, src, enabled = true, onLoadCallback }: ScriptTypes) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    const existingScript = document.getElementById(tag);
    if (enabled && !existingScript) {
      const script = document.createElement('script');
      script.src = src;
      script.id = tag;
      document.body.appendChild(script);
      script.onload = () => {
        setScriptLoaded(true);
        onLoadCallback && onLoadCallback();
      };
    }
    if (existingScript) setScriptLoaded(true);

    return () => {
      const script = document.getElementById(tag);
      if (script) script.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);

  return scriptLoaded;
};
