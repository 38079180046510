import type { IStyles } from '@trustyou/shared';
import { theme } from '@trustyou/ui';

const styles: IStyles = {
  buttonGroup: {
    marginLeft: 'auto',
    boxShadow: 'none',
  },
  firstButton: {
    borderRight: 'none !important',
    borderColor: 'rgba(0, 87, 178, 0.5) !important',
  },
  popper: {
    zIndex: 10,
    marginTop: `${theme.spacing()} !important`,
  },
  singleButtonWrapper: {
    marginLeft: 'auto',
  },
};

export default styles;
