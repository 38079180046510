import { useParams } from 'react-router-dom';

import {
  FETCH_COMBINED_ORGANIZATION_USERS,
  UserViewTypes,
  getPaginationRequest,
  getSortRequest,
  useAccessGroupDetails,
  useCombinedUsersByOrganization,
  useCombinedUsersGridStore,
  useCurrentSubscription,
  useGridParams,
  useMembershipStore,
  useRouteUtils,
  useSubscription,
  useUserNavStore,
} from '@trustyou/shared';
import { ContentPane, EmptyAccessGroupMembersState, Typography } from '@trustyou/ui';

import { useManageSubscription } from '../../../../../hooks';
import { InviteUpdateUser } from '../../components/InviteUpdateUser';
import UsersTable from '../../components/UsersTable/UsersTable';
import styles from './styles';

export const AccessGroupMembers = () => {
  const { goUp } = useRouteUtils();
  const { accessGroupId } = useParams();
  const { view, navToEdit, navToList } = useUserNavStore();
  const { type, user } = view;
  const { membership } = useMembershipStore();
  const isSubscriptionAllowed = useManageSubscription();
  const { data: accessGroup, isFetching: isLoadingAccessGroup } =
    useAccessGroupDetails(accessGroupId);

  const gridState = useCombinedUsersGridStore();

  const { paginationModel, sortModel, searchKey } = gridState;

  const { onPaginationModelChange, onSortModelChange } = useGridParams(
    FETCH_COMBINED_ORGANIZATION_USERS,
    gridState
  );

  const { data, isPending: isLoadingMembers } = useCombinedUsersByOrganization(
    getPaginationRequest(paginationModel),
    getSortRequest(sortModel),
    { searchKey, scopeId: accessGroupId },
    membership?.organizationId
  );

  const { data: currentSubscription } = useCurrentSubscription();
  const { data: userSubscription } = useSubscription(
    isSubscriptionAllowed ? user?.subscription.id : undefined
  );

  if (type === UserViewTypes.INVITE)
    return (
      <InviteUpdateUser subscription={currentSubscription} user={undefined} onCancel={navToList} />
    );

  if (type === UserViewTypes.EDIT)
    return (
      <InviteUpdateUser
        subscription={isSubscriptionAllowed ? userSubscription : currentSubscription}
        user={user}
        onCancel={navToList}
      />
    );

  const isEmpty = !isLoadingMembers && data?.pagination?.total === 0;

  return (
    <ContentPane
      headers={[<Typography variant="h6">{accessGroup?.name}</Typography>]}
      navigationHandle={() => goUp(2)}
      bodyStyles={styles.contentPaneBody}
    >
      {isEmpty ? (
        <EmptyAccessGroupMembersState />
      ) : (
        <UsersTable
          data={data}
          isLoading={isLoadingMembers || isLoadingAccessGroup}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          paginationModel={paginationModel}
          onPaginationModelChange={onPaginationModelChange}
          containerStyle={styles.tableContainer}
          enabledColumns={['name', 'role', 'status', 'action']}
          handleEdit={navToEdit}
        />
      )}
    </ContentPane>
  );
};
