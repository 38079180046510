import type { IStyles } from '@trustyou/shared';

export const styles: IStyles = {
  tabs: {
    minHeight: '42px',
    '.MuiButtonBase-root': {
      minHeight: '42px',
    },
    mb: 2,
  },
};
