import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { TextField } from '@trustyou/ui';

import { DropdownScore, DropdownSource } from '../../dropdowns/filters-dropdowns';

export function KeywordsTextarea() {
  const intl = useIntl();
  const { register } = useFormContext();

  return (
    <TextField
      {...register('filters.search_terms')}
      label={intl.formatMessage({
        id: 'inbox.settings.notifications.keywords',
        defaultMessage: 'Keywords',
      })}
      placeholder={intl.formatMessage({
        id: 'inbox.settings.notifications.keywords.placeholder',
        defaultMessage: 'Enter keywords',
      })}
      helperText={intl.formatMessage({
        id: 'inbox.settings.notifications.keywords.helper-text',
        defaultMessage:
          'Separate keywords with a comma. For an exact phrase, put it in quotation marks (" ")',
      })}
      multiline
      minRows={2}
      InputLabelProps={{ shrink: true }}
    />
  );
}

export function ScoreSelect() {
  const { getValues } = useFormContext();

  return (
    <DropdownScore
      fieldName="filters.score"
      initialValue={getValues('filters.score')}
      fullWidth
      sx={{ width: 220 }}
    />
  );
}

export function SourceSelect() {
  const { getValues } = useFormContext();

  return (
    <DropdownSource
      fieldName="filters.source"
      initialValue={getValues('filters.source')}
      fullWidth
      sx={{ width: 220 }}
    />
  );
}
