// https://zellwk.com/blog/case-conversion/

export function toKebab(string: string) {
  return string
    .split('')
    .map((letter) => {
      if (/[A-Z]/.test(letter)) {
        return ` ${letter.toLowerCase()}`;
      }
      return letter;
    })
    .join('')
    .trim()
    .replace(/[_\s]+/g, '-');
}

export function toSentence(string: string) {
  const interim = toKebab(string).replace(/[-_]/g, ' ');
  return interim.slice(0, 1).toUpperCase() + interim.slice(1);
}

export const capitalizeFirstLetter = (str: string): string =>
  `${str.charAt(0).toUpperCase()}${str.substring(1)}`;
