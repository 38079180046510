import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { commonFields, useAlertStore } from '@trustyou/shared';
import { Button, DeleteModal } from '@trustyou/ui';

import { commonMessages } from '../../constants/messages';
import { useDeleteReport } from '../../service/hooks';
import styles from './styles';

type Props = {
  reportId: string;
  onDeleted: () => void;
};

export const DeleteReport = ({ reportId, onDeleted }: Props) => {
  const { alert } = useAlertStore();
  const [isOpen, setIsOpen] = useState(false);
  const { mutate: deleteReport } = useDeleteReport(() => {
    alert.info(<FormattedMessage {...commonMessages.deleteReportSuccess} />);
    onDeleted();
  });
  return (
    <>
      <Button onClick={() => setIsOpen(true)} color="error" sx={styles.deleteButton}>
        <FormattedMessage {...commonMessages.deleteReport} />
      </Button>
      <DeleteModal
        fullWidth
        maxWidth="xs"
        cancelText={<FormattedMessage {...commonFields.cancel} />}
        content={<FormattedMessage {...commonMessages.deleteReportContent} />}
        dataName="report-delete"
        deleteText={<FormattedMessage {...commonMessages.delete} />}
        onClose={() => setIsOpen(false)}
        onDelete={() => {
          deleteReport(reportId);
        }}
        title={<FormattedMessage {...commonMessages.deleteReportTitle} />}
        open={isOpen}
      />
    </>
  );
};
