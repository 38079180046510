import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  errorStack: { marginTop: 3, width: { xs: '100%', md: '600px' } },
  uploaderContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 3.5,
  },
  uploaderInfoMessage: { maxWidth: '534px', textAlign: 'center', marginBottom: 8.5 },
  uploaderBodyMessage: { color: 'text.secondary' },
};

export default styles;
