import type { Owner, PaginationResponse } from './shared';

export enum OrganizationStatuses {
  ACTIVE = 'active',
  SUSPENDED = 'suspended',
  IN_DRAFT = 'in_draft',
}

export interface OrganizationQuantities {
  users: number;
  'review-providers': number;
  'direct-competitors': number;
}

export interface OrganizationFeatures {
  switches?: string[];
  quantities?: OrganizationQuantities;
}

export interface OrganizationSummary {
  entities_count: number;
  members_count: number;
}

export interface OrganizationLogo {
  logo_uri: string;
}

export interface Organization {
  id: string;
  subscription_id: string;
  chargebee_id: string;
  name: string;
  all_scope_id: string;
  owner_id: string;
  owner?: Owner;
  website?: string;
  pms?: string;
  notes?: string;
  created_at: string;
  updated_at: string;
  status: OrganizationStatuses;
  features?: OrganizationFeatures;
  summary?: OrganizationSummary;
  onboarded?: boolean;
  num_of_entities: number;
  num_of_competitors: number;
  num_of_invited_users: number;
  num_of_active_users: number;
}

export interface OrganizationFormData {
  name: string;
  website?: string;
  pms?: string;
  notes?: string;
}

export interface CreateOrganization {
  name: string;
  website?: string;
  pms?: string;
  notes?: string;
}

export interface CreateOrganizationResponse {
  id: string;
  name: string;
  website: string;
  pms: string;
  notes: string;
  status: OrganizationStatuses;
  created_at: string;
  updated_at: string;
}

export type EditOrganizationResponse = CreateOrganizationResponse;

export interface EditOrganization extends CreateOrganization {
  id: string;
}

export interface UpdateUserOrganization {
  name: string;
  logo_uri?: string;
}
export interface MembershipFromToken {
  id?: string;
  organizationId?: string;
  subscriptionId?: string;
}

export type OrganizationsResponse = PaginationResponse<Organization>;

export enum OrganizationViewTypes {
  CREATE = 'create',
  EDIT = 'edit',
  LIST = 'list',
  TEAM_MEMBERS = 'team_members',
  MEMBER_DETAILS = 'member_details',
  ENTITIES = 'entities',
  INVITE_MEMBER = 'invite_member',
}
