import type { ReactNode } from 'react';

import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import type { SxProps, Theme } from '@mui/material';
import { faCircle } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { faCircleCheck } from '@trustyou/fortawesome/pro-solid-svg-icons';
import { Box, StyledFontAwesomeIcon as Icon, SpotIcon, Typography } from '@trustyou/ui';

import styles from './SelectionCard.styles';

export interface SelectionCardProps {
  id: string;
  title: ReactNode;
  content?: ReactNode;
  icon?: IconDefinition;
  selected: boolean;
  iconAltText?: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  onClick: () => void;
}

export const SelectionCard = ({
  id,
  title,
  content,
  icon,
  iconAltText,
  selected,
  disabled,
  sx,
  onClick,
}: SelectionCardProps) => (
  <Box
    data-testid={`${id}-selection-card`}
    onClick={disabled ? undefined : onClick}
    sx={{
      ...styles.container,
      ...(selected && styles.selectedContainer),
      ...(disabled && styles.disabledContainer),
      ...sx,
    }}
  >
    <Box sx={styles.header}>
      {icon && <SpotIcon icon={icon} disabled={disabled} altText={iconAltText} />}
      <Icon
        size="lg"
        sx={{
          ...styles.checkIcon,
          ...(selected && styles.selectedCheckIcon),
          ...(disabled && styles.disabledText),
        }}
        icon={selected ? faCircleCheck : faCircle}
      />
    </Box>
    <Box sx={{ ...styles.content, ...(disabled && styles.disabledText) }}>
      <Typography variant="subtitle2">{title}</Typography>
      <Typography variant="body2" color="text.secondary">
        {content}
      </Typography>
    </Box>
  </Box>
);
