import { useParams } from 'react-router-dom';

import { useRouteUtils, useSubscription } from '@trustyou/shared';
import { Box, CircularProgress, ContentPane, Typography } from '@trustyou/ui';

import { SubscriptionInfoPanel } from '../../../Subscriptions/Common';
import SubscriptionUsage from '../SubscriptionUsage/SubscriptionUsage';
import { styles } from './styles';

const EntitySubscription = () => {
  const { goUp } = useRouteUtils();
  const { chosenSubscriptionId } = useParams();

  const { data: subscription, isPending: isLoading } = useSubscription(
    chosenSubscriptionId as string
  );

  if (isLoading)
    return (
      <Box sx={styles.loader}>
        <CircularProgress />
      </Box>
    );

  if (!subscription) return null;

  return (
    <ContentPane
      sx={styles.pageContainer}
      headers={[<Typography variant="h6">{subscription.name}</Typography>]}
      navigationHandle={() => goUp(2)}
      bodyStyles={styles.bodyStyles}
    >
      <SubscriptionInfoPanel subscription={subscription} />
      <SubscriptionUsage />
    </ContentPane>
  );
};

export default EntitySubscription;
