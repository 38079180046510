import { useIntl } from 'react-intl';

import { faGear, faGlobe } from '@trustyou/fortawesome/pro-regular-svg-icons';

import { QuestionLibraryEditorSettingsContent } from './content/settings';
import { QuestionLibraryEditorTranslations } from './content/translations';

import type { Step } from '../../shared/editor-side-nav';

export function useQuestionLibraryEditorSteps() {
  const intl = useIntl();

  const steps: Step[] = [
    {
      icon: faGear,
      label: intl.formatMessage({ id: 'survey.common.settings', defaultMessage: 'Settings' }),
      content: <QuestionLibraryEditorSettingsContent />,
    },
    {
      icon: faGlobe,
      label: intl.formatMessage({
        id: 'survey.common.translations',
        defaultMessage: 'Translations',
      }),
      content: <QuestionLibraryEditorTranslations />,
    },
  ];

  return steps;
}
