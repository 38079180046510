import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  DEFAULT_SORT_KEY,
  type Entity,
  useEntityCount,
  useInfiniteEntities,
} from '@trustyou/shared';
import { ListItemText, Skeleton } from '@trustyou/ui';

import { MAX_ENTITIES } from '../../constants';
import { FiltersFormFields } from '../../types';
import { FilterDialogButton } from '../filter-dialog-button';
import { SelectorModal } from '../selector-modal';

interface EntitiesProps {
  initialSelection?: string[] | null;
  isLoading?: boolean;
}

export function Entities({
  initialSelection: initialSelectedIds = [],
  isLoading: isParentLoading,
}: EntitiesProps) {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const { setValue, getValues } = useFormContext();
  const { data, isLoading: isLoadingInfiniteEntitiesData } = useInfiniteEntities(
    MAX_ENTITIES,
    DEFAULT_SORT_KEY
  );
  const { data: totalEntityCount, isLoading: isLoadingEntitiesData } = useEntityCount();
  const [selectedEntities, setSelectedEntities] = useState<Entity[]>([]);
  const [displayedEntities, setDisplayedEntities] = useState<Entity[]>([]);
  const [entities, setEntities] = useState<Entity[]>([]);

  useEffect(() => {
    if (!isLoadingInfiniteEntitiesData && data) {
      const reshapedData = data.pages.flatMap((page) => page.data);
      const initialSelection = reshapedData.filter(({ id }) => initialSelectedIds?.includes(id));
      setValue(
        FiltersFormFields.Entities,
        initialSelection.map(({ id }) => id),
        { shouldDirty: false }
      );
      setSelectedEntities(initialSelection);
      setDisplayedEntities(reshapedData);
      setEntities(reshapedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingInfiniteEntitiesData, data]);

  const openDialog = () => {
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
    setDisplayedEntities(entities);
  };

  const saveEntityIds = () => {
    const ids = selectedEntities.map(({ id }) => id);
    setValue(FiltersFormFields.Entities, ids, { shouldDirty: true });
    closeDialog();
  };

  const cancel = () => {
    setSelectedEntities(
      entities.filter(({ id }) => getValues(FiltersFormFields.Entities)?.includes(id)) || []
    );
    closeDialog();
  };

  const renderRowContent = (entity: Entity) => (
    <ListItemText primary={entity.name} secondary={`${entity.city}, ${entity.country_name}`} />
  );

  const onSearch = (value: string) => {
    if (value === '') {
      setDisplayedEntities(entities);
    } else
      setDisplayedEntities(
        entities.filter(({ name, city, country_name }) =>
          [name, city, country_name].some((key) => key.toLowerCase().includes(value.toLowerCase()))
        )
      );
  };

  const getCount = () => {
    if (selectedEntities.length > 0) {
      return selectedEntities.length;
    }
    if (entities.length > MAX_ENTITIES) {
      return MAX_ENTITIES;
    }
    return entities.length;
  };

  return (
    <>
      <FilterDialogButton
        title={<FormattedMessage id="inbox.filter.entities.name" defaultMessage="Entities" />}
        description={
          isLoadingInfiniteEntitiesData || isLoadingEntitiesData ? (
            <Skeleton variant="rounded" height={20} width={114} />
          ) : (
            <FormattedMessage
              id="inbox.filter.selected-ratio"
              defaultMessage="{count} / {total} selected"
              values={{ count: getCount(), total: totalEntityCount }}
            />
          )
        }
        onClick={openDialog}
      />
      <SelectorModal
        isOpen={isOpen}
        items={displayedEntities}
        selectedItems={selectedEntities}
        setSelectedItems={setSelectedEntities}
        hideButtonGroup
        maxItems={MAX_ENTITIES}
        isLoading={isLoadingInfiniteEntitiesData || isLoadingEntitiesData || isParentLoading}
        onClose={cancel}
        onSave={saveEntityIds}
        renderRowContent={renderRowContent}
        searchPlaceholder={intl.formatMessage({
          id: 'inbox.search.entities',
          defaultMessage: 'Search entities',
        })}
        onSearch={onSearch}
        title={
          <FormattedMessage id="inbox.filter.entities.modal.title" defaultMessage="Entities" />
        }
        subHeader={
          <FormattedMessage
            id="inbox.filter.entity.modal.subheader"
            defaultMessage="Choose up to {maxEntities} entities whose reviews you'd like to view in your Inbox."
            values={{ maxEntities: MAX_ENTITIES }}
          />
        }
        allTab={
          <FormattedMessage
            id="inbox.filter.entities.modal.button.all"
            defaultMessage="All entities"
          />
        }
        selectTab={
          <FormattedMessage
            id="inbox.filter.entity.modal.button.select"
            defaultMessage="Select entities"
          />
        }
        selectedHeaderTitle={
          <FormattedMessage
            id="inbox.filter.entities.modal.selected-count"
            defaultMessage="Selected entities ({count})"
            values={{ count: selectedEntities.length }}
          />
        }
        alertTitle={
          selectedEntities.length === 0 ? (
            <FormattedMessage
              id="inbox.filter.entity.modal.alert-title.min-error"
              defaultMessage="Select at least one entity"
            />
          ) : (
            <FormattedMessage
              id="inbox.filter.entity.modal.alert-title.max-error"
              defaultMessage="More than {maxEntities} entities selected"
              values={{ maxEntities: MAX_ENTITIES }}
            />
          )
        }
        alertDescription={
          selectedEntities.length > MAX_ENTITIES && (
            <FormattedMessage
              id="inbox.filter.entity.modal.alert-description.min-error"
              defaultMessage="Remove a few entities from the list and try again"
            />
          )
        }
      />
    </>
  );
}
