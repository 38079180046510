import { FormattedMessage } from 'react-intl';

import { useScopeStore } from '@trustyou/shared';
import { Alert, GroupSelector, Stack } from '@trustyou/ui';

type GroupSelectionProps = {
  validated?: boolean;
};

export const GroupSelection = ({ validated }: GroupSelectionProps) => {
  const { selectedGroups } = useScopeStore();
  const isEmptySelection = selectedGroups.length === 0;
  return (
    <Stack spacing={2}>
      <GroupSelector
        header={
          <FormattedMessage
            id="access-groups.segment-groups-selection-header"
            defaultMessage="Which segment groups belongs to this access group?"
          />
        }
      />
      {validated && isEmptySelection && (
        <Alert severity="error">
          <FormattedMessage
            id="access-groups.group-selection-alert"
            defaultMessage="Select at least one group"
          />
        </Alert>
      )}
    </Stack>
  );
};
