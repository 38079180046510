import { useQuery } from '@tanstack/react-query';
import { $assApi, ANALYTICS_BASE_PATH } from '@trustyou/shared';

import { REPORT_LIST } from '../../constants/query-keys';
import { useReportsGridStore } from '../../hooks';
import type { ReportListPayload, ReportListResponse } from '../../types/reports';

async function fetchReports(params: ReportListPayload): Promise<ReportListResponse> {
  const url = `${ANALYTICS_BASE_PATH}/report/list`;
  const { data } = await $assApi.get(url, { params });
  return data;
}

export function useReports() {
  const { paginationModel, sortModel, searchKey } = useReportsGridStore();
  const request: ReportListPayload = {
    skip: paginationModel.page * paginationModel.pageSize,
    limit: paginationModel.pageSize,
    type: 'excel',
  };
  if (searchKey) {
    request.search = searchKey;
  }
  if (sortModel.length) {
    request.sort_field = sortModel[0].field;
    request.sort_order = sortModel[0].sort as string;
  }
  return useQuery({
    queryKey: [REPORT_LIST],
    queryFn: () => fetchReports(request),
    refetchOnWindowFocus: false,
    enabled: true,
  });
}
