import { FormattedMessage } from 'react-intl';

import type { StyleUnit } from '@trustyou/shared';
import { EmptyStatePlaceholder } from '@trustyou/ui';

import personSelectingData from '../../assets/illustrations/person-selecting-data.svg';
import styles from './styles';

export type EmptyEntityStateForReadOnlyProps = {
  sx?: StyleUnit;
};

export const EmptyEntityStateForReadOnly = ({ sx }: EmptyEntityStateForReadOnlyProps) => (
  <EmptyStatePlaceholder
    img={personSelectingData}
    title={
      <FormattedMessage id="tyDesign.emptyState.noEntitiesYet" defaultMessage="No entities yet" />
    }
    description={
      <FormattedMessage
        id="tyDesign.emptyState.noEntitiesReadOnlyDescription"
        defaultMessage="It seems that no entities were added to your organization, or you don't have access to the data. Let your admin know and make sure that you have the correct scope."
      />
    }
    emptyStateBody={styles.noDataDescription}
    emptyStateHeader={styles.emptyStateHeader}
    emptyStateContainer={{ ...styles.emptyStateContainer, ...styles.noDataContainer, ...sx }}
  />
);
