import type { ReactElement } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { usePermissionStore } from '@trustyou/shared';

import { ForbiddenPage } from './ForbiddenPage';

export type ProtectedRouteProps = {
  children?: ReactElement;
};

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { permissions, hasRoutePermissions } = usePermissionStore();
  const location = useLocation();

  if (!permissions) {
    console.error('permissions should be defined when protected route renders');
    return null;
  }

  const isCurrentLocationAllowed = hasRoutePermissions(location.pathname);

  if (!isCurrentLocationAllowed) return <ForbiddenPage />;

  return children || <Outlet />;
};
