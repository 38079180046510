import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { faChevronLeft, faChevronRight } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Box, Button, StyledFontAwesomeIcon as Icon } from '@trustyou/ui';

import styles from './styles';

type FooterProps = {
  children?: ReactNode;
  onClickBack: () => void;
  onClickNext?: () => void;
};

export const Footer = ({ onClickBack, onClickNext, children }: FooterProps) => (
  <Box sx={styles.container}>
    <Button
      data-testid="back-button"
      sx={styles.backBtn}
      variant="outlined"
      onClick={onClickBack}
      startIcon={<Icon icon={faChevronLeft} />}
      size="large"
    >
      <FormattedMessage id="action.back" defaultMessage="Back" />
    </Button>
    {children}
    {onClickNext && (
      <Button
        data-testid="next-button"
        variant="contained"
        onClick={onClickNext}
        endIcon={<Icon icon={faChevronRight} />}
        size="large"
      >
        <FormattedMessage id="action.next" defaultMessage="Next" />
      </Button>
    )}
  </Box>
);
