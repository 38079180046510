// https://mui.com/material-ui/react-autocomplete/#country-select
import { useIntl } from 'react-intl';

import { InputLabel, createFilterOptions } from '@mui/material';
import { Autocomplete, Box, TextField } from '@trustyou/ui';

import { countries } from './countries';

import type { TextQuestionProps } from '../question-types';

export function CountrySelect({ label }: Pick<TextQuestionProps, 'label'>) {
  const intl = useIntl();
  return (
    <Autocomplete
      sx={{ minWidth: 123 }}
      componentsProps={{ paper: { sx: { minWidth: 300 } } }}
      options={countries}
      autoHighlight
      getOptionLabel={(option) => option.phone}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            alt=""
          />
          {option.label} ({option.code}) {option.phone}
        </Box>
      )}
      renderInput={(params) => (
        <>
          <InputLabel shrink>{label}</InputLabel>
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
            placeholder={intl.formatMessage({
              id: 'survey.question.text.phone-country-code.placeholder',
              defaultMessage: '+000',
            })}
          />
        </>
      )}
      filterOptions={createFilterOptions({ stringify: (option) => JSON.stringify(option) })}
    />
  );
}
