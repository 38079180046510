import type { AxiosError } from 'axios';

import { isStorybook } from '../constants/importMeta';

export const logForEverythingExept = (env: string, message: string) => {
  if (process.env.NODE_ENV === env) return;
  console.info(message);
};

export const areUITestsRunning = (): boolean => {
  if (process.env.STORYBOOK) return true;
  if (isStorybook) return true;
  if (process.env.NODE_ENV === 'test') return true;
  return false;
};

export const getEnvironment = () => {
  const env = `${process.env.REACT_APP_ENVIRONMENT}`;
  if (env === 'undefined') return 'local';
  return env;
};

export const getRealm = () => `${process.env.REACT_APP_AUTH_REALM}`;

export const isOperationsRealm = () => getRealm() === 'cxp-operations';

export const isCustomersRealm = () => getRealm() === 'cxp-customers';

export const isClientSideError = (error: Error | null) => {
  if (!error) return false;
  const status = (error as AxiosError).response?.status;
  if (!status) return false;
  return status >= 400 && status < 500;
};
