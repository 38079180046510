import type { QueryClient } from '@tanstack/react-query';
import {
  type CentralizedMockData,
  getEnvironment,
  setCentralizedMockData,
  useMockStore,
} from '@trustyou/shared';

import previewEntityData from '../data/preview_entities.json';
import testData from '../data/test_data.json';

export const centralizedNonProductionMockData: CentralizedMockData = {
  preview_entities: previewEntityData,
};

export const centralizedNonProductionMutationMock: CentralizedMockData = {
  test_mutation: testData,
};

export const useNonProductionMock = (queryClient: QueryClient) => {
  const currentEnv = getEnvironment();
  const { nonProductionMockData, nonProductionMutationMock, setNonProductionMockData } =
    useMockStore();
  const areMocksSet =
    nonProductionMockData === centralizedNonProductionMockData &&
    nonProductionMutationMock === centralizedNonProductionMutationMock;
  if (areMocksSet) return;
  if (currentEnv !== 'production') {
    console.info('set centralized mock data on environment: ', currentEnv);
    setCentralizedMockData(queryClient, centralizedNonProductionMockData);
    setNonProductionMockData({
      query: centralizedNonProductionMockData,
      mutation: centralizedNonProductionMutationMock,
    });
  }
};
