import { type ElementType, useEffect, useState } from 'react';

import type { PopperPlacementType, SxProps, Theme } from '@mui/material';
import { useIsTabActive } from '@trustyou/shared';
import { ClickAwayListener, Grow, Paper, Popper } from '@trustyou/ui';

import styles from './PopperMenu.styles';

export interface PopperMenuProps {
  Trigger: ElementType;
  Content: ElementType;
  placement?: PopperPlacementType;
  popperStyle?: SxProps<Theme>;
  disablePortal?: boolean;
}

export const PopperMenu = ({
  Trigger,
  Content,
  placement = 'bottom-end',
  popperStyle,
  disablePortal = true,
}: PopperMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isTabFocused = useIsTabActive();

  const toggleMenu = (event?: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl || !event ? null : event.currentTarget);
  };

  useEffect(() => {
    !isTabFocused && setAnchorEl(null);
  }, [isTabFocused]);

  return (
    <>
      <Trigger onClick={toggleMenu} isOpen={!!anchorEl} />
      {anchorEl && (
        <Popper
          sx={{ ...styles.popper, ...popperStyle }}
          open
          anchorEl={anchorEl}
          transition
          disablePortal={disablePortal}
          placement={placement}
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={() => toggleMenu()}>
              <Grow {...TransitionProps}>
                <Paper variant="elevation" elevation={8} square={false}>
                  <Content onClick={toggleMenu} />
                </Paper>
              </Grow>
            </ClickAwayListener>
          )}
        </Popper>
      )}
    </>
  );
};
