import type { ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import type { TextFieldProps } from '@mui/material';
import { Asterisk, FormHelperText, InputLabel, StyledFormControl, TextField } from '@trustyou/ui';

export type TextFieldControllerProps = {
  name: string;
  label?: ReactNode;
  required?: boolean;
  error?: boolean;
  helperText?: ReactNode;
  placeholder?: string;
  size?: TextFieldProps['size'];
  multiline?: TextFieldProps['multiline'];
  rows?: TextFieldProps['rows'];
};

export const TextFieldController = ({
  name,
  label,
  required,
  error,
  helperText,
  placeholder,
  size,
  multiline,
  rows,
}: TextFieldControllerProps) => {
  const { control } = useFormContext();

  return (
    <p>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <StyledFormControl fullWidth>
            {label && (
              <InputLabel shrink htmlFor={name}>
                {label}
                {required && <Asterisk />}
              </InputLabel>
            )}
            <TextField
              aria-labelledby={name}
              id={name}
              value={value}
              onChange={onChange}
              error={error}
              placeholder={placeholder}
              size={size}
              multiline={multiline}
              rows={rows}
            />
            {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
          </StyledFormControl>
        )}
      />
    </p>
  );
};
