import { useUserOrganization } from '../service/hooks/domains/ass/organization';

export const useMaxCompetitors = (): number => {
  const { data: organizationDetails } = useUserOrganization();

  const DEFAULT_MAX_COMPETITORS = 5;

  const MAX_COMPETOTORS_ALLOWED =
    organizationDetails?.features?.quantities?.['direct-competitors'] || DEFAULT_MAX_COMPETITORS;

  return MAX_COMPETOTORS_ALLOWED;
};
