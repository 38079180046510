import type { Option } from '../../feedback/types';

export const QuestionEditorScaleOneToFiveOptions: Option[] = [
  { value: 1, label: '', color: 'error' },
  { value: 2, label: '', color: 'error' },
  { value: 3, label: '', color: 'warning' },
  { value: 4, label: '', color: 'success' },
  { value: 5, label: '', color: 'success' },
];

export const QuestionEditorScaleOneToTenOptions: Option[] = [
  { value: 1, label: '', color: 'error' },
  { value: 2, label: '', color: 'error' },
  { value: 3, label: '', color: 'error' },
  { value: 4, label: '', color: 'error' },
  { value: 5, label: '', color: 'error' },
  { value: 6, label: '', color: 'warning' },
  { value: 7, label: '', color: 'warning' },
  { value: 8, label: '', color: 'warning' },
  { value: 9, label: '', color: 'success' },
  { value: 10, label: '', color: 'success' },
];

export const QuestionEditorScaleZeroToTenOptions: Option[] = [
  { value: 0, label: '', color: 'error' },
  { value: 1, label: '', color: 'error' },
  { value: 2, label: '', color: 'error' },
  { value: 3, label: '', color: 'error' },
  { value: 4, label: '', color: 'error' },
  { value: 5, label: '', color: 'error' },
  { value: 6, label: '', color: 'error' },
  { value: 7, label: '', color: 'warning' },
  { value: 8, label: '', color: 'warning' },
  { value: 9, label: '', color: 'success' },
  { value: 10, label: '', color: 'success' },
];
