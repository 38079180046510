import { blue, green, grey } from '@mui/material/colors';
import { alpha } from '@mui/system';
import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  pageContainer: {
    position: 'relative',
    justifyContent: 'center',
    flex: 1,
  },
  detailsCell: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
    whiteSpace: 'pre-line',
  },
  detailsCellText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  connectWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 2,
  },
  authWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    mt: 1,
  },
  googleStaticConnectWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    gap: 2,
  },
  successIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '64px',
    height: '64px',
    borderRadius: '50%',
    color: 'success.main',
    backgroundColor: green[50],
  },
  disconnectAPIButton: {
    position: 'absolute',
    top: '8px',
    right: '24px',
    zIndex: 2,
    color: 'error.main',
    border: (theme) => `1px solid ${alpha(theme.palette.error.main, 0.5)}`,
    '&:hover': {
      filter: 'blur(0.5px)',
    },
  },
  providerAccountWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '320px',
    paddingY: 1,
    paddingX: 2,
    gap: 1,
    borderRadius: '4px',
    backgroundColor: grey[50],
  },
  providerAccountAvatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: blue[300],
  },
  providerAccountInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingY: 1,
    '& > span': {
      color: 'text.secondary',
    },
  },
  providerAccountDeleteIcon: {
    margin: 'auto 0 auto auto',
  },
  providerAccountSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  providerAccountList: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 2,
    mb: 5,
  },
  initialLoadingWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '200px',
  },
};

export default styles;
