import { useIntl } from 'react-intl';

import type { Issue } from '../types';

/**
 * Hook to get the feedback topic options for generated responses.
 */
export const useResponseAIIssueOptions = (): { label: string; value: Issue }[] => {
  const intl = useIntl();

  return [
    {
      label: intl.formatMessage({
        id: 'inbox.response.ai.rating.dialog.feedback.tone',
        defaultMessage: 'Tone issues',
      }),
      value: 'tone_issues',
    },
    {
      label: intl.formatMessage({
        id: 'inbox.response.ai.rating.dialog.feedback.length',
        defaultMessage: 'Text length',
      }),
      value: 'text_length',
    },
    {
      label: intl.formatMessage({
        id: 'inbox.response.ai.rating.dialog.translation',
        defaultMessage: 'Translation',
      }),
      value: 'translation',
    },
    {
      label: intl.formatMessage({
        id: 'inbox.response.ai.rating.dialog.signature',
        defaultMessage: 'Signature issues',
      }),
      value: 'signature_issues',
    },
    {
      label: intl.formatMessage({
        id: 'inbox.response.ai.rating.dialog.missingGuide',
        defaultMessage: 'Missing guide',
      }),
      value: 'missing_guide',
    },
    {
      label: intl.formatMessage({
        id: 'inbox.response.ai.rating.dialog.missingContent',
        defaultMessage: 'Missing content',
      }),
      value: 'missing_content',
    },
    {
      label: intl.formatMessage({
        id: 'inbox.response.ai.rating.dialog.falseContent',
        defaultMessage: 'Generated false content',
      }),
      value: 'generated_false_content',
    },
    {
      label: intl.formatMessage({
        id: 'inbox.response.ai.rating.dialog.misunderstanding',
        defaultMessage: 'Misunderstood sentiment',
      }),
      value: 'misunderstood_sentiment',
    },
    {
      label: intl.formatMessage({
        id: 'inbox.response.ai.rating.dialog.other-reason',
        defaultMessage: 'Other',
      }),
      value: 'other',
    },
  ];
};
