import { FormattedMessage } from 'react-intl';

import { createSvgIcon } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  type GridToolbarContainerProps,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
import { SEARCH_DEBOUNCE_TIME_MS } from '@trustyou/shared';
import { Button, SearchBar } from '@trustyou/ui';

const ExportIcon = createSvgIcon(
  <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
  'SaveAlt'
);

interface ToolbarProps extends GridToolbarContainerProps {
  defaultSearchValue?: string;
  enableToolbarColumnsButton?: boolean;
  enableToolbarDensityButton?: boolean;
  onSearch?: (value: string) => void;
  onCsvExport?: () => void;
}

export const Toolbar = ({
  defaultSearchValue,
  enableToolbarColumnsButton,
  enableToolbarDensityButton,
  onSearch,
  onCsvExport,
}: ToolbarProps) => {
  if (
    !defaultSearchValue &&
    !enableToolbarColumnsButton &&
    !enableToolbarDensityButton &&
    !onSearch &&
    !onCsvExport
  ) {
    return null;
  }

  // TODO: Extend this to use other tools.
  return (
    <GridToolbarContainer>
      {onCsvExport && (
        <Button
          color="primary"
          size="small"
          startIcon={<ExportIcon />}
          onClick={onCsvExport}
          data-testid="datagrid-download-as-csv"
        >
          <FormattedMessage id="tyDesign.export" defaultMessage="Export" />
        </Button>
      )}
      {enableToolbarColumnsButton && <GridToolbarColumnsButton />}
      {enableToolbarDensityButton && <GridToolbarDensitySelector />}
      {onSearch && (
        <SearchBar
          onSearch={onSearch}
          debounceTime={SEARCH_DEBOUNCE_TIME_MS}
          id="grid-search"
          variant="standard"
          defaultValue={defaultSearchValue}
          sx={{ ml: 'auto' }}
        />
      )}
    </GridToolbarContainer>
  );
};
