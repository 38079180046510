import type { NodeType } from '@trustyou/shared';
import { DrawerNode, List } from '@trustyou/ui';

type DrawerNodeListProps = {
  nodes: NodeType[];
  isPermanentlyExpanded?: boolean;
};
export function DrawerNodeList({ nodes, isPermanentlyExpanded = false }: DrawerNodeListProps) {
  return (
    <List
      dense
      disablePadding
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 0.5,
        paddingInline: 1.25,
      }}
    >
      {nodes
        .filter((node) => !node.isHidden)
        .map((node) => (
          <DrawerNode key={node.id} node={node} isPermanentlyExpanded={isPermanentlyExpanded} />
        ))}
    </List>
  );
}
