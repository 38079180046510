import { FormattedMessage } from 'react-intl';

import { Button } from '@trustyou/ui';

type TranslateButtonProps = {
  disabled?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

export function TranslateButton({ disabled, onClick }: TranslateButtonProps) {
  return (
    <Button
      size="small"
      sx={{ marginY: 2, width: 'fit-content', alignSelf: 'center' }}
      disabled={disabled}
      onClick={onClick}
      data-gtm-id="inbox_response_ai_translate"
    >
      <FormattedMessage id="inbox.response.ai.action.translate" defaultMessage="Translate" />
    </Button>
  );
}
