import { useQuery } from '@tanstack/react-query';
import {
  FETCH_BENCHMARKS,
  FETCH_BENCHMARK_COMPETITOR_ENTITIES,
  FETCH_BENCHMARK_OWNED_ENTITIES,
  type PaginationRequest,
  fetchBenchmarkCompetitorEntities,
  fetchBenchmarkOwnedEntities,
  fetchBenchmarks,
} from '@trustyou/shared';

export function useBenchmarks(pagination: PaginationRequest, sortKey: string) {
  return useQuery({
    queryKey: [FETCH_BENCHMARKS, { pagination, sortKey }],
    queryFn: () => fetchBenchmarks(pagination, sortKey),
    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useBenchmarkOwnedEntities(
  benchmarkId: string,
  pagination: PaginationRequest,
  sortKey: string
) {
  return useQuery({
    queryKey: [FETCH_BENCHMARK_OWNED_ENTITIES, { pagination, sortKey }],
    queryFn: () => fetchBenchmarkOwnedEntities(benchmarkId, pagination, sortKey),
    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useBenchmarkCompetitorEntities(
  benchmarkId: string,
  pagination: PaginationRequest,
  sortKey: string
) {
  return useQuery({
    queryKey: [FETCH_BENCHMARK_COMPETITOR_ENTITIES, { benchmarkId, pagination, sortKey }],
    queryFn: () => fetchBenchmarkCompetitorEntities(benchmarkId, pagination, sortKey),
    refetchOnWindowFocus: false,
    enabled: true,
  });
}
