import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  container: {
    mt: 'auto',
    paddingY: 2,
    alignItems: 'center',
    display: 'flex',
    position: 'sticky',
    bottom: '0',
    background: 'rgba(255, 255, 255, 0.85)',
    backdropFilter: 'blur(2px)',
    zIndex: '1',
  },
  backBtn: {
    marginRight: 'auto',
  },
};

export default styles;
