import { useState } from 'react';
import { type UseControllerProps, useController } from 'react-hook-form';

import { FormControl, FormControlLabel, RadioGroup as MuiRadioGroup, Radio } from '@trustyou/ui';

import type { Option } from '../types';

type RadioGroupProps = UseControllerProps & {
  options: Option[];
};

export function RadioGroup({ name, control, options }: RadioGroupProps) {
  const { field } = useController({ name, control });
  const [selectedOption, setSelectedOption] = useState<Option>(field.value);

  const handleChange = (option: Option) => {
    field.onChange([option.value]);
    setSelectedOption(option);
  };

  return (
    <FormControl>
      <MuiRadioGroup
        aria-labelledby="radio-buttons-group"
        name="radio-buttons-group"
        value={selectedOption?.value ?? null}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        onChange={(event, value) => handleChange(options.find((option) => option.value === value)!)}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value.toString()}
            value={option.value}
            control={<Radio />}
            label={option.label}
            onChange={() => handleChange(option)}
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
}
