import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  emptyStateHeader: {
    marginTop: 3,
  },
  emptyStateContainer: {
    margin: 'auto',
  },
  actionButton: {
    marginTop: 4,
  },
  entitySourcesContainer: {
    marginTop: 8,
  },
  noDataContainer: {
    minHeight: '500px',
  },
  noDataDescription: {
    maxWidth: '600px',
    textAlign: 'center',
  },
  manageNoEntitiesWrapper: {
    mt: 4,
  },
};

export default styles;
