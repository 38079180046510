import { ZeroToTenOptions } from '../../components/scale-options';
import type { Option } from '../../types';
import { ScaleQuestion, type ScaleQuestionProps } from '../scale/scale-question';

type NPSQuestionProps = Omit<ScaleQuestionProps, 'options'> & {
  options?: Option[];
};

export function NPSQuestion({ options, ...props }: NPSQuestionProps) {
  return <ScaleQuestion variant="numbers" options={options ?? ZeroToTenOptions} {...props} />;
}
