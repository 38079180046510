import { type TemporaryHiddenFeature, temporaryHiddenEnvironments } from '../constants';
import { areUITestsRunning, getEnvironment } from '../service/utils';

export const isTemporaryHidden = (feature: TemporaryHiddenFeature) => {
  if (areUITestsRunning()) return false;
  const hiddenEnvs = temporaryHiddenEnvironments[feature];
  if (!hiddenEnvs) return false;
  if (hiddenEnvs.includes('none')) return false;
  if (hiddenEnvs.includes('all')) return true;
  const currentEnv = getEnvironment();

  return hiddenEnvs.includes(currentEnv);
};
