import type { AccessTokenStore } from '../store';
import type { DashboardFilter } from '../types';

export const getDashboardExcludedFilters = (filters: DashboardFilter[]) => {
  const excludedFilters = filters
    .filter(
      (row) =>
        row.jaql && (row.psHideFilter || /^segment\d$/.test(row.jaql.column || row.jaql.title))
    )
    .map((row) => row.jaql.column || row.jaql.title);
  return JSON.stringify(excludedFilters);
};

// Function to apply security filters on dashboard. These filters will be hidden on Sisense side by a JS script.
export const applySecurityFilters = (
  dashboardFilters: DashboardFilter[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sisenseFrame: any,
  token: AccessTokenStore[keyof AccessTokenStore]
) => {
  const filterValueMap: { [key: string]: string | undefined } = {
    organization_id: token.org_center?.claims.organization_id,
    scope_id: token.org_center?.claims.scope_id,
  };

  const filters = dashboardFilters
    .filter(
      (filter) =>
        filter.jaql &&
        filter.jaql.column &&
        Object.keys(filterValueMap).includes(filter.jaql.column)
    )
    .map((filter) => ({
      jaql: {
        title: filter.jaql.title,
        dim: filter.jaql.dim,
        datatype: 'text',
        filter: {
          members: [filterValueMap[filter.jaql.column as string]],
        },
      },
      panel: 'scope',
    }));
  sisenseFrame.dashboard.applyFilters(filters);
};
