import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import { faMagnifyingGlass, faXmark } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { SEARCH_DEBOUNCE_TIME_MS, useInboxStore } from '@trustyou/shared';
import { StyledFontAwesomeIcon } from '@trustyou/ui';

import { useDebounce } from '../../hooks';
import { getSearchTerms, parseSearchTerms } from '../../utils/keyword-search';
import { IconButtonWithTooltip } from '../icon-button-with-tooltip';

export function CustomGridToolbarQuickFilter() {
  const intl = useIntl();
  const currentView = useInboxStore((state) => state.currentView);
  const setCurrentView = useInboxStore((state) => state.setCurrentView);
  const stringifiedSearchTerms = parseSearchTerms(currentView.filters?.search_terms ?? []);
  const [value, setValue] = useState(stringifiedSearchTerms);
  const debouncedStringifiedSearchTerms = useDebounce(value, SEARCH_DEBOUNCE_TIME_MS);

  const handleTyping = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  const clear = () => {
    setValue('');
    setCurrentView({
      ...currentView,
      filters: {
        ...currentView.filters,
        search_terms: [],
      },
    });
  };

  useEffect(() => {
    setCurrentView({
      ...currentView,
      filters: {
        ...currentView.filters,
        search_terms: getSearchTerms(debouncedStringifiedSearchTerms),
      },
    });

    // avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedStringifiedSearchTerms]);

  return (
    <GridToolbarQuickFilter
      variant="filled"
      placeholder={intl.formatMessage({ id: 'inbox.action.search', defaultMessage: 'Search' })}
      value={value}
      onChange={handleTyping}
      // debounceMs={SEARCH_DEBOUNCE_TIME_MS} // without effect because DataGrid filterModel is not being used
      InputProps={{
        startAdornment: (
          <StyledFontAwesomeIcon
            icon={faMagnifyingGlass}
            size="lg"
            sx={{ color: (theme) => theme.palette.action.active }}
          />
        ),
        endAdornment: value && (
          <IconButtonWithTooltip
            icon={faXmark}
            tooltip={<FormattedMessage id="inbox.action.clear" defaultMessage="Clear" />}
            onClick={clear}
            sx={{ height: 32, width: 32 }}
          />
        ),
        sx: {
          '& .MuiInputBase-input': {
            paddingBlock: 1,
            marginLeft: 1,
          },
        },
      }}
      sx={{
        width: 220,
        '& .MuiInputBase-root': {
          paddingRight: 0.5,
          backgroundColor: (theme) => theme.palette.background.default,
          '& fieldset': {
            borderColor: 'transparent',
          },
        },
      }}
    />
  );
}
