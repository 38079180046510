import type {
  AccessGroup,
  AccessGroupCreateUpdatePayload,
  AccessGroupsResponse,
  PaginationRequest,
} from '../../../../types';
import { $assApi } from '../../../api';
import { SCOPES_BASE_PATH } from '../../path';

export async function fetchAccessGroups(
  { limit, offset }: PaginationRequest,
  searchKey?: string
): Promise<AccessGroupsResponse> {
  const { data } = await $assApi.get(`${SCOPES_BASE_PATH}/get_reusable_scopes`, {
    params: {
      limit,
      offset,
      ...(searchKey && { q: searchKey }),
    },
  });
  return data;
}

export async function fetchAccessGroupDetails(id?: string): Promise<AccessGroup> {
  const { data } = await $assApi.get(`${SCOPES_BASE_PATH}/get_scope`, {
    params: {
      id,
    },
  });
  return data.data;
}

export async function createUpdateAccessGroup(
  accessGroup: AccessGroupCreateUpdatePayload
): Promise<AccessGroup> {
  const url = accessGroup.id
    ? `${SCOPES_BASE_PATH}/update_reusable_scope`
    : `${SCOPES_BASE_PATH}/create_reusable_scope`;
  const { data } = await $assApi.post(url, accessGroup);
  return data;
}

export async function deleteAccessGroup(id: string): Promise<string> {
  const { data } = await $assApi.delete(`${SCOPES_BASE_PATH}/delete_reusable_scope`, {
    params: {
      id,
    },
  });
  return data;
}

export async function isValidAccessGroupName(name: string): Promise<boolean> {
  const inValidResponse = { data: { valid: false } };
  const { data } = await $assApi
    .get(`${SCOPES_BASE_PATH}/is_valid_scope_name`, { params: { name } })
    .then((response) => response || inValidResponse)
    .catch(() => inValidResponse);
  return data.valid;
}
