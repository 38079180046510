import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  type BookingConnectionRequestCredentials,
  type BookingConnectionResponse,
  type FormErrorResponseData,
  useAlertStore,
  useCreateBookingConnection,
  validationErrors,
} from '@trustyou/shared';
import { Box, SetupStepper, Typography } from '@trustyou/ui';
import type { AxiosError } from 'axios';
import * as yup from 'yup';

import AuthorizeStep from './AuthorizeStep';
import BookingConnectInstructions from './BookingConnectInstructions';
import ContactEmailForm from './ContactEmailForm';
import FinalStep from './FinalStep';
import Footer from './Footer';
import LegalEntityIDForm from './LegalEntityIDForm';

import styles from './styles';

const bookingLoginHref =
  'https://admin.booking.com/?groups_redirect=%2Fhotel%2Fhoteladmin%2Fextranet_ng%2Fmanage%2Fchannel-manager%2Fwidget.html?provider_id=1608';

const steps = defineMessages({
  1: {
    id: 'review-providers.booking.stepper.step1',
    defaultMessage: 'Authorize via Booking.com',
  },
  2: {
    id: 'review-providers.booking.stepper.step2',
    defaultMessage: 'Copy the Legal Entity ID',
  },
  3: {
    id: 'review-providers.booking.stepper.step3',
    defaultMessage: 'Wait for 24-48h',
  },
});

export enum BookingContactFormVariant {
  LEID = 'leid',
  CONTACT_EMAL = 'contact_email',
}

export type BookingConnectManagerProps = {
  onClose: () => void;
};

const BookingConnectManager = ({ onClose }: BookingConnectManagerProps) => {
  const intl = useIntl();
  const [activeStep, setActiveStep] = useState(-1);
  const [activeFormVariant, setActiveFormVariant] = useState<BookingContactFormVariant>(
    BookingContactFormVariant.LEID
  );
  const [isLogIntoButtonClicked, setLogIntoButtonClicked] = useState(false);

  const { alert } = useAlertStore();

  const [nonUniqueLEIDs, setNonUniqueLEIDs] = useState<string[]>([]);
  const [nonUniqueContactEmails, setNonUniqueContactEmails] = useState<string[]>([]);

  const legalEntityIDNotUnique = intl.formatMessage({
    id: 'review-providers.booking.legalEntityID.unique',
    defaultMessage: 'This Legal Entity ID already exists',
  });

  const constactEmailNotUnique = intl.formatMessage({
    id: 'review-providers.booking.contact-email.unique',
    defaultMessage: 'This contact email already exists',
  });

  const schema = yup
    .object()
    .shape({
      legal_entity_id: yup
        .string()
        .test('legal_entity_id_unique', legalEntityIDNotUnique, (value) => {
          if (!value) return true;
          return !nonUniqueLEIDs.includes(value);
        }),
      contact_email: yup
        .string()
        .email(intl.formatMessage(validationErrors.invalidEmail))
        .test('contact_email_unique', constactEmailNotUnique, (value) => {
          if (!value) return true;
          return !nonUniqueContactEmails.includes(value);
        }),
    })
    .test(
      'legal_entity_id_or_contact_email',
      'Either legal_entity_id or contact_email is required',
      (value) => {
        // Ensure at least one of the fields is filled
        return !!(value.legal_entity_id || value.contact_email);
      }
    );

  const formData = useForm<BookingConnectionRequestCredentials>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset, formState, trigger } = formData;

  useEffect(() => {
    trigger();
  }, [nonUniqueLEIDs, nonUniqueContactEmails, trigger]);

  const handleBookingConnectionError = (error: AxiosError): boolean => {
    const responseData = error.response?.data as FormErrorResponseData;
    const bookingValidationErrors = responseData.error.validation_errors;
    if (!bookingValidationErrors) return true;
    if (!bookingValidationErrors.some((item) => item.code === 'uniqueness_error')) return true;
    const configData = JSON.parse(error.config?.data) as BookingConnectionRequestCredentials;
    if (configData.legal_entity_id) {
      setNonUniqueLEIDs([configData.legal_entity_id, ...nonUniqueLEIDs]);
    }
    if (configData.contact_email) {
      setNonUniqueContactEmails([configData.contact_email, ...nonUniqueContactEmails]);
    }
    return false;
  };

  const { mutate: createBookingConnection } = useCreateBookingConnection(
    ({ provider_account_id }: BookingConnectionResponse) => {
      onClickNextHandler();
    },
    (error) => {
      const needShowDefaultAlert = handleBookingConnectionError(error as AxiosError);
      if (needShowDefaultAlert) {
        alert.genericError();
      }
    }
  );

  const onSubmit = ({ legal_entity_id, contact_email }: BookingConnectionRequestCredentials) => {
    const filteredData: BookingConnectionRequestCredentials = legal_entity_id
      ? { legal_entity_id }
      : { contact_email };
    createBookingConnection(filteredData);
  };

  const onClickNextHandler = () => {
    setActiveStep((step) => step + 1);
  };

  const onClickBackHandler = () => {
    setActiveStep((step) => step - 1);
  };

  const getNextButtonHidden = () => {
    if (activeStep === 0 && !isLogIntoButtonClicked) return true;
    if (activeStep === 2) return true;
    return false;
  };

  const getNextButtonDisabled = () => {
    if (activeStep === 1 && !formState.isValid) return true;
    return false;
  };

  const getBackButtonHidden = () => {
    if (activeStep === 2) return true;
    return false;
  };

  const handleNextStep = () => {
    if (activeStep === 1) {
      handleSubmit(onSubmit)();
    } else {
      onClickNextHandler();
    }
  };

  const showAuthoriseFooterMessage = activeStep === 0 && isLogIntoButtonClicked;

  const toggleForm = () => {
    reset();
    const anotherVariant =
      activeFormVariant === BookingContactFormVariant.LEID
        ? BookingContactFormVariant.CONTACT_EMAL
        : BookingContactFormVariant.LEID;
    setActiveFormVariant(anotherVariant);
  };

  const handleLogInto = () => {
    setLogIntoButtonClicked(true);
    window.open(bookingLoginHref, '_blank');
  };

  if (activeStep === -1) return <BookingConnectInstructions onNextClick={onClickNextHandler} />;

  return (
    <Box sx={styles.dialogBodyWrapper}>
      <SetupStepper steps={steps} activeStep={activeStep} />
      <Box sx={styles.stepperBody}>
        {activeStep === 0 && <AuthorizeStep handleLogInto={handleLogInto} />}
        {activeStep === 1 &&
          (activeFormVariant === BookingContactFormVariant.LEID ? (
            <LegalEntityIDForm formData={formData} toggleForm={toggleForm} />
          ) : (
            <ContactEmailForm formData={formData} toggleForm={toggleForm} />
          ))}
        {activeStep === 2 && <FinalStep backToReviewProviders={onClose} />}
      </Box>
      <Footer
        isNextButtonDisabled={getNextButtonDisabled()}
        onClickBack={getBackButtonHidden() ? undefined : onClickBackHandler}
        onClickNext={getNextButtonHidden() ? undefined : handleNextStep}
      >
        {showAuthoriseFooterMessage ? (
          <Typography sx={styles.authoriseFooterMessage} variant="body2">
            <FormattedMessage
              id="review-providers.booking.footer.authorizeMessage"
              defaultMessage="I’ve authorized TrustYou"
            />
          </Typography>
        ) : null}
      </Footer>
    </Box>
  );
};

export default BookingConnectManager;
