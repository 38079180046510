import { Box, BrandedAvatar, Typography } from '@trustyou/ui';

import styles from './styles';

export type UserNameCellProps = {
  email: string;
  name?: string;
  isInvited?: boolean;
};

export const UserCredentialsCell = ({ name, email, isInvited }: UserNameCellProps) => (
  <Box sx={{ ...styles.userCredentialsCell, ...(isInvited && styles.invitedUserCredentialsCell) }}>
    <BrandedAvatar name={name} />
    <Box sx={styles.nameCell}>
      <Typography sx={styles.nameText} variant="subtitle2">
        {name || email}
      </Typography>
      {name && (
        <Typography sx={styles.nameText} variant="caption" color="text.secondary">
          {email}
        </Typography>
      )}
    </Box>
  </Box>
);
