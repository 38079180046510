import { DASHBOARD_WIDTH, type IStyles } from '@trustyou/shared';

const styles: IStyles = {
  container: {
    height: '100%',
    textAlign: 'center',
    overflowX: 'auto',
    overflowY: 'hidden',
    '> iframe': { width: `${DASHBOARD_WIDTH}px !important` },
  },
};

export default styles;
