import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import type { Membership, OrganizationWithMemberships } from '../types/model/auth';
import type { MembershipFromToken } from '../types/model/organization';

type MembershipState = {
  membership?: MembershipFromToken;
  setMembership: (membership?: MembershipFromToken) => void;
  setDefaultMembership: () => void;
  organizations?: OrganizationWithMemberships[];
  setOrganizations: (organizations?: OrganizationWithMemberships[]) => void;
  getMembershipDetails: () => Membership | undefined;
  getMemberships: () => Membership[] | undefined;
  getMembershipsCount: () => number | undefined;
  hasValidMembership: (id?: string) => boolean;
  clearStore: () => void;
};

const initialState = {
  membership: undefined,
  organizations: undefined,
};

const membershipPersist = () =>
  persist<MembershipState>(
    (set, get) => ({
      membership: undefined,
      setMembership: (membership) => {
        set({ membership });
      },
      setDefaultMembership: () => {
        const { organizations } = get();
        if (organizations?.length) {
          set({
            membership: {
              id: organizations[0].memberships[0].id,
              organizationId: organizations[0].id,
              subscriptionId: organizations[0].memberships[0].subscription.id,
            },
          });
        }
      },
      organizations: undefined,
      setOrganizations: (organizations) => {
        set({ organizations });
      },
      getMembershipDetails: () => {
        const memberships = get().getMemberships();
        const { membership } = get();
        if (!memberships) return undefined;
        return memberships.find((item) => item.id === membership?.id);
      },
      getMemberships: () => {
        const { organizations } = get();
        return organizations?.flatMap((org) =>
          org.memberships.map((row) => ({
            ...row,
            organization: {
              id: org.id,
              name: org.name,
              onboarded: true,
            },
          }))
        );
      },
      getMembershipsCount: () => get().getMemberships()?.length,
      hasValidMembership: (id) => {
        const memberships = get().getMemberships();
        if (!memberships) return false;
        return memberships.some((item) => item.id === id);
      },
      clearStore: () => {
        set({ ...initialState });
        sessionStorage.removeItem('userOrganisationsState');
      },
    }),
    {
      name: `userOrganisationsState`, // Specify the name for sessionStorage
      storage: createJSONStorage(() => sessionStorage), // Use sessionStorage
    }
  );

export const useMembershipStore = create<MembershipState>()(membershipPersist());
