import { useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAlertStore, useChangelingStore, validationErrors } from '@trustyou/shared';
import { Button, ComposableDrawerWithStickyFooter } from '@trustyou/ui';
import * as yup from 'yup';

import { GuidelinesStep } from './guidelines-step';
import { guidelinesMessages, messages } from './messages';
import { ReasonAndNotesStep } from './reason-and-notes-step';

import type { ModerationRequestReasonEnum } from '../../client';
import { DRAWER_WIDTH } from '../../constants';
import { useRequestModeration } from '../../hooks';
import { useStore } from '../../store/store';
import type { ReviewRoot } from '../../types';

export type FlagFormValues = {
  agreeToGuidelines: boolean;
  reason: ModerationRequestReasonEnum;
  notes: string;
};

type SurveyModerationSteps = 'guidelines' | 'reason-and-notes';

type FormDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
  onConfirm: () => void;
};

export function FormDrawer({ isOpen, onClose, onCancel, onConfirm }: FormDrawerProps) {
  const intl = useIntl();
  const { alert } = useAlertStore();
  const { accessTokenPayload } = useOidcAccessToken();
  const { isChangeling } = useChangelingStore();
  const reviewRoot = useStore.use.reviewRoot() as ReviewRoot;
  const requestModeration = useRequestModeration();

  const guidelinesCheckboxHelperText = intl.formatMessage(guidelinesMessages.helperText);
  const requiredFieldHelperText = intl.formatMessage(validationErrors.required);
  const schema = yup.object().shape({
    agreeToGuidelines: yup
      .boolean()
      .required()
      .default(false)
      .oneOf([true], guidelinesCheckboxHelperText),
    reason: yup.string().required(requiredFieldHelperText),
    notes: yup.string().required(requiredFieldHelperText),
  });
  const methods = useForm<FlagFormValues>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const { control, handleSubmit, trigger, reset } = methods;
  const agreeToGuidelines = useWatch({ control, name: 'agreeToGuidelines' });

  const [step, setStep] = useState<SurveyModerationSteps>('guidelines');

  const goToNextStep = () => {
    trigger('agreeToGuidelines');
    if (!agreeToGuidelines) return;
    setStep('reason-and-notes');
  };

  const onSubmit = (formData: FlagFormValues) => {
    const payload = {
      review_id: reviewRoot.review.id,
      reason: formData.reason,
      notes: formData.notes,
      username: accessTokenPayload.name,
    };
    requestModeration.mutate(payload, {
      onSuccess: () => {
        alert.success(intl.formatMessage(messages.markedAsInappropriate));
      },
    });
    onConfirm();
  };

  useEffect(() => {
    if (!isOpen) {
      reset();
      setStep('guidelines');
    }
  }, [isOpen, reset]);

  return (
    <FormProvider {...methods}>
      <ComposableDrawerWithStickyFooter
        open={isOpen}
        onClose={onClose}
        anchor="right"
        PaperProps={{
          sx: {
            width: DRAWER_WIDTH,
            paddingTop: isChangeling ? 8 : 0,
          },
        }}
      >
        <ComposableDrawerWithStickyFooter.Header
          title={intl.formatMessage(messages.markAsInappropriate)}
          sx={{ paddingInline: 3 }}
        />
        <ComposableDrawerWithStickyFooter.Content
          sx={{
            paddingInline: 3,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          {step === 'guidelines' && <GuidelinesStep />}
          {step === 'reason-and-notes' && <ReasonAndNotesStep />}
        </ComposableDrawerWithStickyFooter.Content>
        <ComposableDrawerWithStickyFooter.Footer
          primaryButton={
            step === 'reason-and-notes' ? (
              <Button onClick={handleSubmit(onSubmit)} variant="contained">
                <FormattedMessage
                  id="inbox.action.confirm-and-finish"
                  defaultMessage="Confirm & finish"
                />
              </Button>
            ) : (
              <Button onClick={goToNextStep} type="button" variant="contained">
                <FormattedMessage id="inbox.action.next" defaultMessage="Next" />
              </Button>
            )
          }
          secondaryButton={
            <Button onClick={onCancel} type="button" color="inherit">
              <FormattedMessage id="inbox.action.cancel" defaultMessage="Cancel" />
            </Button>
          }
          sx={{ boxShadow: 5 }}
        />
      </ComposableDrawerWithStickyFooter>
    </FormProvider>
  );
}
