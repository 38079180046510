import { FormattedMessage } from 'react-intl';

import { Typography } from '@trustyou/ui';
import dayjs from 'dayjs';

import { formatTotal } from '../../utils/number-format';

export type TotalReviewsAndLastUpdateProps = {
  hasReviews: boolean;
  total: number;
  lastProducedAt: string;
};

export function TotalReviewsAndLastUpdate({
  hasReviews,
  total,
  lastProducedAt,
}: TotalReviewsAndLastUpdateProps) {
  return (
    <Typography variant="caption" color="text.secondary">
      {hasReviews ? (
        <FormattedMessage
          id="inbox.total-reviews-and-last-update"
          defaultMessage="{total} Total Reviews · Last update: {lastUpdate} UTC"
          values={{
            total: formatTotal(total),
            lastUpdate: dayjs(lastProducedAt).format('DD.MM.YYYY, HH:mm'),
          }}
        />
      ) : (
        <FormattedMessage id="inbox.no-reviews-present" defaultMessage="No reviews present" />
      )}
    </Typography>
  );
}
