import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

type UseUnregisterUnmountedQuestionProps = {
  questionTypes: string[];
  fieldsToUnregister: string[];
};

// This function is used for unregistering fields when chosen question type is
// unmounted when user switch to another question type inside question editor
export function useUnregisterUnmountedQuestion({
  questionTypes,
  fieldsToUnregister,
}: UseUnregisterUnmountedQuestionProps) {
  const isMounted = useRef(false);
  const { watch, unregister } = useFormContext();

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
      if (!isMounted.current && !questionTypes.includes(watch('questionType') as string)) {
        fieldsToUnregister.forEach((field: string) => {
          unregister(field);
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
