import { create } from 'zustand';

import { type CombinedUserAndInvitation, GlobalUsersViewTypes } from '../../types/model/user';

export type GlobalUsersView = {
  user?: CombinedUserAndInvitation;
  type: GlobalUsersViewTypes;
  isDeleting?: boolean;
  isDiscarding?: boolean;
};

type GlobalUsersNavState = {
  view: GlobalUsersView;
  setView: (view: GlobalUsersView) => void;
  navToOrganizationList: () => void;
  navToServiceList: () => void;
  navToOrganizationUserDetails: (user: CombinedUserAndInvitation) => void;
};

export const useGlobalUsersNavStore = create<GlobalUsersNavState>((set) => ({
  view: {
    type: GlobalUsersViewTypes.ORGANIZATION_LIST,
    user: undefined,
  },
  setView: (view) => set({ view }),
  navToOrganizationList: () => set({ view: { type: GlobalUsersViewTypes.ORGANIZATION_LIST } }),
  navToServiceList: () => set({ view: { type: GlobalUsersViewTypes.SERVICE_LIST } }),
  navToOrganizationUserDetails: (user: CombinedUserAndInvitation) =>
    set({ view: { type: GlobalUsersViewTypes.ORGANIZATION_USER_DETAILS, user } }),
}));
