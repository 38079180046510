import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  orgContainer: {
    paddingY: 2,
    maxHeight: '50vh',
    overflowY: 'auto',
    width: '320px',
  },
  orgHeader: { textTransform: 'uppercase', color: 'text.secondary', paddingX: 2 },
};

export default styles;
