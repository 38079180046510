import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { useAlertStore } from '@trustyou/shared';

import { ReviewDeletionDialog } from './review-deletion-dialog';
import { TrashIconButton } from './trash-icon-button';

import { useMarkAsDeleted } from '../../hooks';
import { useStore } from '../../store/store';
import type { ReviewRoot } from '../../types';

const deletionMessages = defineMessages({
  success: {
    id: 'inbox.review.delete.success',
    defaultMessage: 'Marked as deleted',
  },
});

export function ReviewDeletion() {
  const intl = useIntl();
  const { alert } = useAlertStore();
  const { review } = useStore.use.reviewRoot() as ReviewRoot;
  const { accessTokenPayload } = useOidcAccessToken();
  const markAsDeleted = useMarkAsDeleted();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  const handleConfirm = () => {
    markAsDeleted.mutate(
      {
        username: accessTokenPayload.name,
        review_id: review.id,
      },
      {
        onSuccess: () => {
          closeDialog();
          alert.success(intl.formatMessage(deletionMessages.success));
        },
      }
    );
  };

  return (
    <>
      <TrashIconButton onClick={openDialog} />
      <ReviewDeletionDialog
        open={isDialogOpen}
        onClose={closeDialog}
        onCancel={closeDialog}
        onConfirm={handleConfirm}
      />
    </>
  );
}
