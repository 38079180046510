import { useIntl } from 'react-intl';

import {
  FETCH_GROUP_ENTITIES,
  SEARCH_DEBOUNCE_TIME_MS,
  type SegmentGroup,
  getPaginationRequest,
  getSortRequest,
  searchPlaceholders,
  useGridParams,
  useGroupEntities,
  useGroupEntitiesGridStore,
} from '@trustyou/shared';
import { Box, EmptyGroupEntityState, SearchBar } from '@trustyou/ui';

import styles from '../../../AllEntities/styles';
import EntitiesTable from '../../../Common/EntitiesTable';

export type GroupEntitiesProps = {
  group: SegmentGroup;
};

const GroupEntities = ({ group }: GroupEntitiesProps) => {
  const gridState = useGroupEntitiesGridStore();
  const intl = useIntl();

  const { paginationModel, sortModel, searchKey, columnVisibilityModel, setColumnVisibilityModel } =
    gridState;

  const { onPaginationModelChange, onSortModelChange, onSearch } = useGridParams(
    FETCH_GROUP_ENTITIES,
    gridState
  );

  const { data, isFetching } = useGroupEntities(
    group.id,
    getPaginationRequest(paginationModel),
    getSortRequest(sortModel),
    searchKey
  );

  if (data?.pagination.total === 0 && !searchKey) return <EmptyGroupEntityState />;

  return (
    <>
      <Box sx={styles.searchBar}>
        <SearchBar
          placeholder={intl.formatMessage(searchPlaceholders.searchEntities)}
          onSearch={onSearch}
          debounceTime={SEARCH_DEBOUNCE_TIME_MS}
          id="entities"
          variant="standard"
          defaultValue={searchKey}
        />
      </Box>
      <EntitiesTable
        data={data}
        isFetching={isFetching}
        sortModel={sortModel}
        searchKey={searchKey}
        onSortModelChange={onSortModelChange}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        groupId={group.id}
        actionsDisabled
      />
    </>
  );
};

export default GroupEntities;
