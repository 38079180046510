import type { SxProps, Theme } from '@mui/material';
import { Typography } from '@trustyou/ui';

export type SurveySectionTitleProps = {
  title: string;
  sx?: SxProps<Theme>;
};

export const SurveySectionTitle = ({ title, sx = {} }: SurveySectionTitleProps) => {
  return (
    <Typography component="h2" variant="h6" sx={{ textAlign: 'center', ...sx }}>
      {title}
    </Typography>
  );
};
