import { FormattedMessage, useIntl } from 'react-intl';

import {
  type Entity,
  getTranslatedNumbers,
  useCompetitorStore,
  useMaxCompetitors,
} from '@trustyou/shared';
import { Stack, Typography } from '@trustyou/ui';

import { CompetitorSelector } from './CompetitorSelector';

type CompetitorSelectionProps = {
  entity: Entity;
};

export const CompetitorSelection = ({ entity }: CompetitorSelectionProps) => {
  const intl = useIntl();
  const { selectedCompetitors } = useCompetitorStore();
  const MAX_COMPETITORS_ALLOWED = useMaxCompetitors();

  return (
    <Stack gap={2} height="100%">
      <Typography variant="body1">
        <FormattedMessage
          id="organization.competitors.select"
          defaultMessage="Select up to {max} competitors for <bold>{entity}</bold>"
          values={{
            entity: entity.name,
            max: getTranslatedNumbers(intl, MAX_COMPETITORS_ALLOWED),
            // eslint-disable-next-line react/no-unstable-nested-components
            bold: (str) => <b>{str}</b>,
          }}
        />
      </Typography>
      <CompetitorSelector
        initialSelection={entity.competitors}
        isListDisabled={selectedCompetitors.length >= MAX_COMPETITORS_ALLOWED}
      />
    </Stack>
  );
};
