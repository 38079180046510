import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { faArrowRight } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  Box,
  Button,
  Chip,
  StyledFontAwesomeIcon as Icon,
  Stack,
  Typography,
  reviewProvidersLogo,
} from '@trustyou/ui';
import { capitalize } from 'lodash';

import { CONNECT_PROVIDER_NAMES } from '../../constants/sources';
import { useConnectReviewProviderPermission } from '../../hooks';
import styles from './styles';

type ReviewProviderCardProps = {
  title: string;
  id: string;
  connectedAPIs?: number;
  isConfiguring?: boolean;
  handleConnectProvider: (name: string) => void;
};

const ReviewProviderCard = ({
  id,
  title,
  connectedAPIs = 0,
  isConfiguring,
  handleConnectProvider,
}: ReviewProviderCardProps) => {
  const navigate = useNavigate();
  const isConnectEnabled = useConnectReviewProviderPermission();

  const onClick = () => {
    if (isConnectEnabled && connectedAPIs > 0)
      navigate(id, { state: { header: { id, message: capitalize(title) } } });
  };

  const renderActionOrStatus = () => {
    const isBookingConnectEnabled =
      connectedAPIs === 0 && title === CONNECT_PROVIDER_NAMES.BOOKING && isConnectEnabled;
    const isGoogleConnectEnabled =
      connectedAPIs === 0 && title === CONNECT_PROVIDER_NAMES.GOOGLE && isConnectEnabled;
    if (isConfiguring)
      return (
        <Chip
          label={
            <FormattedMessage id="reviewProvider.card.configuring" defaultMessage="Configuring" />
          }
          variant="pastelWarning"
          size="small"
        />
      );
    if (isBookingConnectEnabled)
      return (
        <Button
          variant="outlined"
          onClick={() => handleConnectProvider(CONNECT_PROVIDER_NAMES.BOOKING)}
        >
          <FormattedMessage id="common.connect" defaultMessage="Connect" />
        </Button>
      );
    if (isGoogleConnectEnabled)
      return (
        <Button
          variant="outlined"
          onClick={() => handleConnectProvider(CONNECT_PROVIDER_NAMES.GOOGLE)}
        >
          <FormattedMessage id="common.connect" defaultMessage="Connect" />
        </Button>
      );
    if (connectedAPIs > 0)
      return (
        <Chip
          label={
            connectedAPIs > 1 ? (
              <FormattedMessage
                id="reviewProvider.card.apiConnectionsCount"
                defaultMessage="{count} API connections"
                values={{ count: connectedAPIs }}
              />
            ) : (
              <FormattedMessage
                id="reviewProvider.card.connectedAPI"
                defaultMessage="Connected To API"
              />
            )
          }
          variant="pastelInfo"
          size="small"
        />
      );
    return null;
  };

  return (
    <Stack
      onClick={onClick}
      sx={{
        ...styles.container,
        ...(isConnectEnabled && connectedAPIs > 0 && styles.selectableCard),
      }}
    >
      <Box sx={styles.subContainer}>
        <Stack sx={styles.imgChipContainer}>
          <Box
            component="img"
            alt={title}
            sx={styles.img}
            src={reviewProvidersLogo[title] || reviewProvidersLogo.default}
          />
          {renderActionOrStatus()}
        </Stack>
        <Box sx={styles.titleContainer}>
          <Typography variant="subtitle1" color="text.primary">
            {title}
          </Typography>
          {isConnectEnabled && connectedAPIs > 0 && <Icon size="lg" icon={faArrowRight} />}
        </Box>
      </Box>
    </Stack>
  );
};

export default ReviewProviderCard;
