/* eslint-disable @typescript-eslint/no-explicit-any */
import { type CSSProperties, type JSXElementConstructor, useMemo, useRef, useState } from 'react';

import type { BoxProps } from '@mui/material';
import {
  DataGridPro,
  type DataGridProProps,
  type GridColDef,
  type GridRowModel,
  type GridRowSelectionModel,
  type GridSortDirection,
} from '@mui/x-data-grid-pro';
import { useDataGridIntl, useLanguageStore } from '@trustyou/shared';
import { Box } from '@trustyou/ui';

import { CustomColumnMenu } from './CustomGridColumnMenu';
import { NoRowsOverlay } from './NoRowsOverlay';
import { Toolbar } from './Toolbar';

import styles from './styles';

const DEFAULT_PAGE_SIZE_OPTIONS = [10, 25, 50];
const SORTING_ORDER: GridSortDirection[] = ['asc', 'desc'];

export const ROWS_PER_PAGE_DEFAULT = DEFAULT_PAGE_SIZE_OPTIONS[0];

export type DataGridCompProps = {
  rows: GridRowModel[];
  columns: GridColDef[];
  isMultiSelect?: boolean;
  containerStyle?: BoxProps['sx'];
  customNoRowsOverlay?: JSXElementConstructor<unknown>;
  customNoResultsOverlay?: JSXElementConstructor<unknown>;
  customPagination?: JSXElementConstructor<unknown>;
  customOverlayHeight?: CSSProperties['height'];
  defaultSearchValue?: string;
  enableToolbarColumnsButton?: boolean;
  enableToolbarDensityButton?: boolean;
  onCsvExport?: () => void;
  onSearch?: (value: string) => void;
} & DataGridProProps;

export const DataGrid = ({
  rows,
  columns,
  isMultiSelect = false,
  sx,
  hideFooterPagination,
  paginationMode,
  paginationModel,
  rowCount,
  initialState,
  containerStyle,
  customOverlayHeight,
  customNoRowsOverlay = NoRowsOverlay,
  customNoResultsOverlay,
  customPagination,
  defaultSearchValue,
  enableToolbarColumnsButton,
  enableToolbarDensityButton,
  onCsvExport,
  onSearch,
  ...props
}: DataGridCompProps) => {
  const { locale } = useLanguageStore();
  const dataGridIntl = useDataGridIntl();
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  const rowCountRef = useRef(rowCount || 0);
  const memoizedRowCount = useMemo(() => {
    if (paginationMode !== 'server') return rowCount;
    if (rowCount !== undefined) {
      rowCountRef.current = rowCount;
    }
    return rowCountRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowCount]);

  const customProps = {
    hideFooter:
      (paginationMode === 'server' ? Number(memoizedRowCount) : rows.length) <=
      ROWS_PER_PAGE_DEFAULT,
    initialState: {
      pagination: {
        paginationModel: hideFooterPagination
          ? undefined
          : paginationModel || {
              pageSize: ROWS_PER_PAGE_DEFAULT,
            },
      },
      ...initialState,
    },
  };

  const isCustomNoRowsOverlayShown = !rows.length && !props.loading;

  const style = {
    border: 'none',
    '& .MuiDataGrid-row': {
      cursor: props.onRowClick ? 'pointer' : 'default',
    },
    ...(isCustomNoRowsOverlayShown
      ? {
          '& .MuiDataGrid-overlayWrapperInner': {
            height: customOverlayHeight || '440px !important',
          },
          '& .MuiDataGrid-virtualScrollerContent': {
            height: customOverlayHeight || '440px !important',
          },
        }
      : {}),
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-columnHeader:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-columnHeader:focus-within': {
      outline: 'none',
    },
    '& .MuiDataGrid-virtualScroller': !rows.length &&
      props.loading && {
        minHeight: '150px',
        '--unstable_DataGrid-overlayBackground': 'none',
      },
    ...sx,
  };

  return (
    <Box sx={{ width: '100%', ...containerStyle }}>
      <DataGridPro
        rows={rows}
        columns={columns}
        disableColumnMenu
        autoHeight
        pageSizeOptions={props.pageSizeOptions || DEFAULT_PAGE_SIZE_OPTIONS}
        sortingOrder={props.sortingOrder || SORTING_ORDER}
        sx={{ ...styles.dataGrid, ...style }}
        pagination
        paginationMode={paginationMode}
        paginationModel={paginationModel}
        rowCount={memoizedRowCount}
        slotProps={{
          basePopper: {
            sx: styles.basePopper,
          },
          pagination: {
            SelectProps: {
              sx: styles.paginationSelect,
              MenuProps: {
                sx: styles.paginationSelectMenu,
              },
            },
            labelDisplayedRows: customPagination ? () => null : undefined,
          },
          toolbar: {
            defaultSearchValue,
            enableToolbarColumnsButton,
            enableToolbarDensityButton,
            onSearch,
            onCsvExport,
          },
          columnsPanel: {
            sx: styles.columnsPanel,
          },
        }}
        {...(isMultiSelect
          ? {
              checkboxSelection: true,
              onRowSelectionModelChange: (newRowSelectionModel) => {
                setRowSelectionModel(newRowSelectionModel);
              },
              rowSelectionModel,
            }
          : {
              disableRowSelectionOnClick: true,
            })}
        {...customProps}
        slots={{
          toolbar: Toolbar,
          noRowsOverlay: customNoRowsOverlay,
          noResultsOverlay: customNoResultsOverlay,
          columnMenu: CustomColumnMenu,
          pagination: customPagination,
        }}
        localeText={dataGridIntl[locale].components.MuiDataGrid.defaultProps.localeText}
        {...props}
      />
    </Box>
  );
};
