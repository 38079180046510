import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { type SelectChangeEvent, styled } from '@mui/material';
import { alpha } from '@mui/material/styles';
import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Radio,
  Select,
  type SelectProps,
} from '@trustyou/ui';

import type { DropdownGroup } from '../../../types';

const StyledRadio = styled(Radio)(({ theme }) => ({
  padding: theme.spacing(0.5),
  marginInlineStart: theme.spacing(-0.5),
  marginInlineEnd: theme.spacing(1),
}));

export type DropdownRadioProps = {
  label: string;
  fieldName: string;
  groups: DropdownGroup[];
  defaultValue?: string;
  disabled?: boolean;
  dataTestId?: string;
  onOpenDialog?: () => void;
  size?: SelectProps['size'];
  fullWidth?: SelectProps['fullWidth'];
};

export const DropdownRadio = ({
  label,
  fieldName,
  groups,
  defaultValue,
  disabled = false,
  dataTestId,
  onOpenDialog,
  size = 'medium',
  fullWidth = false,
}: DropdownRadioProps) => {
  const { register } = useFormContext();
  const [currentValue, setCurrentValue] = useState(defaultValue);
  const fieldSlug = fieldName.toLowerCase().replaceAll('_', '-');

  const handleChange = (event: SelectChangeEvent) => {
    setCurrentValue(event.target.value);
  };

  useEffect(() => {
    setCurrentValue(defaultValue);
  }, [defaultValue]);

  const renderTranslated = (value: string) => {
    let translatedText = value ?? '';
    groups?.forEach((group) => {
      group.options.forEach((option) => {
        if (option.value === value) translatedText = option.label;
      });
    });
    return translatedText;
  };

  return (
    <Box data-testid={dataTestId}>
      <FormControl fullWidth={fullWidth}>
        <InputLabel id={`dropdown-radio-label-${fieldSlug}`}>{label}</InputLabel>
        <Select
          id={`dropdown-radio-${fieldSlug}`}
          labelId={`dropdown-radio-label-${fieldSlug}`}
          size={size}
          name={fieldName}
          input={<OutlinedInput label={label} inputProps={{ ...register(fieldName) }} />}
          value={currentValue}
          onChange={handleChange}
          renderValue={(value) => renderTranslated(value)}
          disabled={disabled}
          MenuProps={{ autoFocus: false, sx: { maxWidth: 220 } }}
        >
          {groups?.map(
            ({ value: groupValue, label: groupLabel, options: groupFilters }: DropdownGroup) => [
              groupValue && (
                <ListSubheader
                  key={`subheader-${groupValue}`}
                  sx={{
                    lineHeight: (theme) => theme.spacing(4),
                    color: (theme) => theme.palette.primary.dark,
                    backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.04),
                    borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                  }}
                >
                  {groupLabel}
                </ListSubheader>
              ),
              groupFilters.map(({ label, value }) => (
                <MenuItem key={value} value={value} dense>
                  <StyledRadio size="small" checked={currentValue === value} />
                  <ListItemText
                    sx={{ marginInlineStart: 0.5, whiteSpace: 'break-spaces' }}
                    primary={label}
                  />
                </MenuItem>
              )),
            ]
          )}
          <Divider />
          <MenuItem
            value={currentValue}
            onClick={onOpenDialog}
            sx={{
              color: 'primary.main',
              fontSize: 14,
              fontWeight: 500,
              '&.Mui-selected': { backgroundColor: 'transparent' },
            }}
          >
            <FormattedMessage id="inbox.custom-range" defaultMessage="Custom range" />
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};
