import dayjs from 'dayjs';

import type { SurveyContactInfo } from '../client';
import { NumericScaleOptions, type SurveyQuestion } from '../types';

export type GuestInfo = {
  label: string | null;
  value: string | number | undefined | null;
};

export const formatNewSurveyGuestInfo = (data?: SurveyContactInfo | null): GuestInfo[] => {
  if (!data) return [];
  return Object.keys(data)
    .filter((key) => !!data[key as keyof typeof data])
    .map((key) => ({ label: key, value: data[key as keyof typeof data] }));
};

export const formatClassicSurveyGuestInfo = (
  data?: SurveyQuestion[],
  contactData?: Partial<SurveyContactInfo>
): GuestInfo[] => {
  const newSurveyInfo = contactData ? formatNewSurveyGuestInfo(contactData) : [];
  let classicSurveyInfo: GuestInfo[] = [];
  if (data) {
    classicSurveyInfo = data.map(({ name, value, options }) => {
      // i.e. value = { "dropdown": ["1", "2", "3"] } ---> type = "dropdown"
      const type = Object.keys(value)[0] as keyof typeof value;
      if (!value[type]) return { label: name, value: undefined };

      let formattedValue = value[type];
      if (type === 'date') {
        formattedValue = dayjs(value?.date).format('DD.MM.YYYY');
      }

      if (type === 'boolean') {
        formattedValue = formattedValue ? 'Yes' : 'No';
      }

      if (options?.scale) {
        formattedValue += ` / ${options?.scale === NumericScaleOptions.ONE_TO_FIVE ? 5 : 10}`;
      }

      if (Array.isArray(formattedValue)) {
        formattedValue = formattedValue.join(', ');
      }

      return { label: name, value: formattedValue as string | number | undefined };
    });
  }

  return [...newSurveyInfo, ...classicSurveyInfo];
};
