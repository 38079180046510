import { Tooltip, Typography } from '@trustyou/ui';

import type { View } from '../../types/user';

export type ViewItemProps = {
  view: View;
};

export const ViewItem = ({ view }: ViewItemProps) => {
  return (
    <Tooltip
      arrow
      placement="left"
      title={view.label}
      disableHoverListener={view.label.length <= 25}
    >
      <Typography noWrap>{view.label}</Typography>
    </Tooltip>
  );
};
