import { type ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { faChevronRight } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { type Segment, type SegmentGroup, useScopeStore } from '@trustyou/shared';
import { Box, Typography } from '@trustyou/ui';

import GroupList from './GroupList';
import SegmentList from './SegmentList';
import SelectedGroupList from './SelectedGroupList';

import { StyledFontAwesomeIcon } from '../../StyledFontAwesomeIcon/StyledFontAwesomeIcon';
import styles from './styles';

type GroupSelectorProps = {
  header?: ReactNode;
};

export function GroupSelector({ header }: GroupSelectorProps) {
  const [selectedSegment, setSelectedSegment] = useState<Segment>();
  const { selectedGroups, setSelectedGroups } = useScopeStore();

  const selectedGroupIds = selectedGroups.map((group) => group.id);

  const onSelectMultiple = (elements: SegmentGroup[]) => {
    // when all provided entries are already selected, remove them instead
    const selectionIds = selectedGroups.map(({ id }) => id);
    const newIdsArray = elements.map(({ id }) => id);
    if (newIdsArray.every((id) => selectionIds.includes(id))) {
      setSelectedGroups(selectedGroups.filter(({ id }) => !newIdsArray.includes(id)));
    } else {
      // otherwise add them to the selection (without duplicates)
      setSelectedGroups([
        ...selectedGroups,
        ...elements.filter(({ id }) => !selectionIds.includes(id)),
      ]);
    }
  };

  /**
   * Toggle an item.
   * When multiple items are selected, they are added to the selection.
   * If all items were previously selected, they will be deselected instead.
   * @param categories
   * @returns
   */
  const onToggle = (elements: SegmentGroup[]) => {
    // "select all search results" clicked
    if (elements.length > 1) return onSelectMultiple(elements);

    // otherwise toggle the clicked row
    const element = elements[0];
    if (selectedGroups.map((item) => item.id).includes(element.id)) {
      setSelectedGroups(selectedGroups.filter((row) => row.id !== element.id));
    } else {
      setSelectedGroups([...selectedGroups, element]);
    }
  };

  return (
    <Box sx={styles.body}>
      <Box sx={styles.segmentsGroupsContainer}>
        <Typography variant="body1">
          {header || (
            <FormattedMessage
              id="organization.users.whichGroupsCanThisMemberAccess"
              defaultMessage="Which groups can this member access?"
            />
          )}
        </Typography>
        <Box sx={styles.groupsBody}>
          <SegmentList selectedSegment={selectedSegment} onSegmentSelect={setSelectedSegment} />
          <Box sx={styles.groupsContainer}>
            {selectedSegment && (
              <GroupList
                segment={selectedSegment}
                selectedGroups={selectedGroupIds}
                onGroupSelect={onToggle}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={styles.arrowContainer}>
        {selectedSegment && !!selectedGroups.length && (
          <Box sx={styles.arrow}>
            <StyledFontAwesomeIcon size="lg" icon={faChevronRight} />
          </Box>
        )}
      </Box>
      <Box sx={styles.selectedGroupsContainer}>
        {!!selectedGroups.length && (
          <SelectedGroupList groups={selectedGroups} onGroupRemove={onToggle} />
        )}
      </Box>
    </Box>
  );
}
