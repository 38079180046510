import { FormattedMessage } from 'react-intl';

import { Button, ButtonGroup, Stack, Typography } from '@trustyou/ui';

import { commonMessages, newReportWizardMessages } from '../../../../constants/messages';
import { useHasSchedulePermission } from '../../../../hooks';
import { useNewReportStore } from '../../../../store';

export const FrequencyTypeSelector = () => {
  const { newReport, setIsOneTime } = useNewReportStore();
  const hasSchedulePermission = useHasSchedulePermission();

  if (!hasSchedulePermission) return null;

  return (
    <Stack>
      <Typography marginBottom={2} variant="subtitle1">
        <FormattedMessage {...newReportWizardMessages.frequencyTypeHeader} />
      </Typography>
      <ButtonGroup variant="outlined">
        <Button
          onClick={() => setIsOneTime(true)}
          variant={!newReport?.isOneTime ? 'outlined' : 'contained'}
        >
          <FormattedMessage {...commonMessages.frequencyTypeOneTime} />
        </Button>
        <Button
          onClick={() => setIsOneTime(false)}
          variant={newReport?.isOneTime ? 'outlined' : 'contained'}
        >
          <FormattedMessage {...commonMessages.frequencyTypeScheduled} />
        </Button>
      </ButtonGroup>
    </Stack>
  );
};
