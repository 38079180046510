import { useQuery } from '@tanstack/react-query';
import { $assApi, ANALYTICS_BASE_PATH } from '@trustyou/shared';

import { DASHBOARD_ID_LIST } from '../../constants/query-keys';

async function fetchDashboardIds(): Promise<Record<string, string>> {
  const url = `${ANALYTICS_BASE_PATH}/sisense/get_reporting_dashboard_id`;
  const { data } = await $assApi.get(url);
  return data;
}

export function useDashboardIds() {
  return useQuery({
    queryKey: [DASHBOARD_ID_LIST],
    queryFn: fetchDashboardIds,
    refetchOnWindowFocus: false,
    enabled: true,
  });
}
