import { type Control, Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { range } from '@trustyou/shared';
import { FormControl, FormHelperText, MenuItem, Select } from '@trustyou/ui';

import { frequencySelectorMessages } from '../../constants/messages';
import { FormControlLabel } from '../form-control-label/form-control-label';
import styles from './styles';

type Props = {
  control: Control;
};

const DAYS_OF_MONTH = range(1, 31);

export const DayOfMonthFormControl = ({ control }: Props) => (
  <FormControl size="small" sx={styles.formControl}>
    <FormControlLabel>
      <FormattedMessage {...frequencySelectorMessages.dayOfMonth} />
    </FormControlLabel>
    <Controller
      control={control}
      name="dayOfMonth"
      render={({ field: { value, onChange } }) => (
        <Select
          aria-labelledby="dayOfMonth"
          value={value}
          defaultValue={control._defaultValues.dayOfMonth}
          id="dayOfMonth"
          MenuProps={{ PaperProps: { sx: styles.dayOfMonthPaper } }}
          onChange={(event) => {
            onChange(event.target.value);
          }}
        >
          {DAYS_OF_MONTH.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      )}
    />
    <FormHelperText>
      <FormattedMessage {...frequencySelectorMessages.dayOfMonthHelper} />
    </FormHelperText>
  </FormControl>
);
