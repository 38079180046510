/**
 * Do not edit directly
 * Generated on Tue, 20 Jun 2023 12:18:42 GMT
 */

export const FontFamiliesRoboto = 'Roboto';
export const LineHeights0 = '116.7%';
export const LineHeights1 = '120%';
export const LineHeights2 = '123.5%';
export const LineHeights3 = '133.4%';
export const LineHeights4 = '160%';
export const LineHeights5 = '150%';
export const LineHeights6 = '143%';
export const LineHeights7 = '175%';
export const LineHeights8 = '157%';
export const LineHeights9 = '266%';
export const LineHeights10 = '166%';
export const LineHeights11 = '20px';
export const LineHeights12 = '26px';
export const LineHeights13 = '24px';
export const LineHeights14 = '22px';
export const LineHeights15 = '12px';
export const LineHeights16 = '18px';
export const LineHeights17 = '14px';
export const LineHeights18 = '48px';
export const FontWeightsLight = 300;
export const FontWeightsRegular = 400;
export const FontWeightsMedium = 500;
export const FontSize0 = '10px';
export const FontSize1 = '12px';
export const FontSize2 = '13px';
export const FontSize3 = '14px';
export const FontSize4 = '15px';
export const FontSize5 = '16px';
export const FontSize6 = '20px';
export const FontSize7 = '24px';
export const FontSize8 = '34px';
export const FontSize9 = '48px';
export const FontSize10 = '60px';
export const FontSize11 = '96px';
export const LetterSpacing0 = '-1.5px';
export const LetterSpacing1 = '-0.5px';
export const LetterSpacing2 = '0px';
export const LetterSpacing3 = '0.25px';
export const LetterSpacing4 = '0.15px';
export const LetterSpacing5 = '0.17px';
export const LetterSpacing6 = '0.1px';
export const LetterSpacing7 = '1px';
export const LetterSpacing8 = '0.4px';
export const LetterSpacing9 = '0.14px';
export const LetterSpacing10 = '0.46px';
export const LetterSpacing11 = '0.16px';
export const ParagraphSpacingNone = '0';
export const ComponentsAlertTitle = {
  fontFamily: 'Roboto',
  fontWeight: 500,
  lineHeight: '150%',
  fontSize: '16px',
  letterSpacing: '0.15px',
  paragraphSpacing: '0',
  paragraphIndent: '0',
  textDecoration: 'none',
  textTransform: 'none',
};
export const ComponentsAvatarInitials = {
  fontFamily: 'Roboto',
  fontWeight: 400,
  lineHeight: '20px',
  fontSize: '20px',
  letterSpacing: '0.14px',
  paragraphSpacing: '0',
  paragraphIndent: '0',
  textDecoration: 'none',
  textTransform: 'none',
};
export const ComponentsBadgeLabel = {
  fontFamily: 'Roboto',
  fontWeight: 500,
  lineHeight: '20px',
  fontSize: '12px',
  letterSpacing: '0.14px',
  paragraphSpacing: '0',
  paragraphIndent: '0',
  textDecoration: 'none',
  textTransform: 'none',
};
export const ComponentsButtonLarge = {
  fontFamily: 'Roboto',
  fontWeight: 500,
  lineHeight: '26px',
  fontSize: '15px',
  letterSpacing: '0.46px',
  paragraphSpacing: '0',
  paragraphIndent: '0',
  textDecoration: 'none',
  textTransform: 'uppercase',
};
export const ComponentsButtonMedium = {
  fontFamily: 'Roboto',
  fontWeight: 500,
  lineHeight: '24px',
  fontSize: '14px',
  letterSpacing: '0.4px',
  paragraphSpacing: '0',
  paragraphIndent: '0',
  textDecoration: 'none',
  textTransform: 'uppercase',
};
export const ComponentsButtonSmall = {
  fontFamily: 'Roboto',
  fontWeight: 500,
  lineHeight: '22px',
  fontSize: '13px',
  letterSpacing: '0.46px',
  paragraphSpacing: '0',
  paragraphIndent: '0',
  textDecoration: 'none',
  textTransform: 'uppercase',
};
export const ComponentsInputLabel = {
  fontFamily: 'Roboto',
  fontWeight: 400,
  lineHeight: '12px',
  fontSize: '12px',
  letterSpacing: '0.15px',
  paragraphSpacing: '0',
  paragraphIndent: '0',
  textDecoration: 'none',
  textTransform: 'none',
};
export const ComponentsHelperText = {
  fontFamily: 'Roboto',
  fontWeight: 400,
  lineHeight: '166%',
  fontSize: '12px',
  letterSpacing: '0.4px',
  paragraphSpacing: '0',
  paragraphIndent: '0',
  textDecoration: 'none',
  textTransform: 'none',
};
export const ComponentsInputText = {
  fontFamily: 'Roboto',
  fontWeight: 400,
  lineHeight: '24px',
  fontSize: '16px',
  letterSpacing: '0.15px',
  paragraphSpacing: '0',
  paragraphIndent: '0',
  textDecoration: 'none',
  textTransform: 'none',
};
export const ComponentsChipLabel = {
  fontFamily: 'Roboto',
  fontWeight: 400,
  lineHeight: '18px',
  fontSize: '13px',
  letterSpacing: '0.16px',
  paragraphSpacing: '0',
  paragraphIndent: '0',
  textDecoration: 'none',
  textTransform: 'none',
};
export const ComponentsTooltipLabel = {
  fontFamily: 'Roboto',
  fontWeight: 500,
  lineHeight: '14px',
  fontSize: '10px',
  letterSpacing: '0px',
  paragraphSpacing: '0',
  paragraphIndent: '0',
  textDecoration: 'none',
  textTransform: 'none',
};
export const ComponentsTableHeader = {
  fontFamily: 'Roboto',
  fontWeight: 500,
  lineHeight: '24px',
  fontSize: '14px',
  letterSpacing: '0.17px',
  paragraphSpacing: '0',
  paragraphIndent: '0',
  textDecoration: 'none',
  textTransform: 'none',
};
export const ComponentsMenuItem = {
  fontFamily: 'Roboto',
  fontWeight: 400,
  lineHeight: '150%',
  fontSize: '16px',
  letterSpacing: '0.15px',
  paragraphSpacing: '0',
  paragraphIndent: '0',
  textDecoration: 'none',
  textTransform: 'none',
};
export const ComponentsMenuItemDense = {
  fontFamily: 'Roboto',
  fontWeight: 400,
  lineHeight: '24px',
  fontSize: '14px',
  letterSpacing: '0.17px',
  paragraphSpacing: '0',
  paragraphIndent: '0',
  textDecoration: 'none',
  textTransform: 'none',
};
export const ComponentsListSubheader = {
  fontFamily: 'Roboto',
  fontWeight: 500,
  lineHeight: '48px',
  fontSize: '14px',
  letterSpacing: '0.1px',
  paragraphSpacing: '0',
  paragraphIndent: '0',
  textDecoration: 'none',
  textTransform: 'none',
};
export const ComponentsBottomNavigationActiveLabel = {
  fontFamily: 'Roboto',
  fontWeight: 400,
  lineHeight: '166%',
  fontSize: '14px',
  letterSpacing: '0.4px',
  paragraphSpacing: '0',
  paragraphIndent: '0',
  textDecoration: 'none',
  textTransform: 'none',
};
export const TextCaseNone = 'none';
export const TextCaseUppercase = 'uppercase';
export const TextDecorationNone = 'none';
export const ParagraphIndentNone = '0';
export const InputStandardEnabledBorder = 'rgba(0,0,0,0.42)';
export const InputStandardHoverBorder = 'rgba(0,0,0,1)';
export const InputFilledEnabledFill = 'rgba(0,0,0,0.06)';
export const InputFilledHoverFill = 'rgba(0,0,0,0.09)';
export const InputOutlinedEnabledBorder = 'rgba(0,0,0,0.23)';
export const InputOutlinedHoverBorder = 'rgba(0,0,0,1)';
export const SwitchKnobFill = '#FAFAFA';
export const SwitchSlideFill = 'rgba(0,0,0,1)';
export const AvatarFill = '#BDBDBD';
export const ChipDefaultCloseFill = 'rgba(0,0,0, 1)';
export const ChipDefaultHoverFill = 'rgba(0,0,0,0.12)';
export const ChipDefaultEnabledBorder = '#616161';
export const AlertErrorContent = '#541313';
export const AlertErrorFill = '#FBEAEA';
export const AlertWarningContent = '#5F2B01';
export const AlertWarningFill = '#FDF0E6';
export const AlertInfoContent = '#014361';
export const AlertInfoFill = '#E5F6FD';
export const AlertSuccessContent = '#123214';
export const AlertSuccessFill = '#EAF2EA';
export const BackdropFill = 'rgba(0,0,0,0.5)';
export const BreadcrumbsCollapseFill = '#F5F5F5';
export const AppbarDefaultFill = '#F5F5F5';
export const RatingEnabledBorder = 'rgba(0,0,0,0.23)';
