import { ALL_REVIEWS_FILTERS } from '@trustyou/shared';

import { getViewFilters } from './mappers';

import type { ReviewView } from '../client';
import type { FiltersForm, GetSourceListSuccess } from '../types';

export function countActiveFilters(
  filters: ReviewView,
  sources: GetSourceListSuccess = {},
  languages: Record<string, string> = {}
): number {
  // heavy destructuring to shorten next assignments expressions
  const {
    status,
    relative_timerange,
    preset_timerange,
    timerange,
    score,
    source,
    survey_ids,
    entity_ids,
    sema_subcategories,
    sema_categories,
    respondable,
    language,
    // search_terms,
  } = filters;

  let count = 0;

  count += ALL_REVIEWS_FILTERS.status?.length !== status?.length ? 1 : 0;
  if (
    (relative_timerange && ALL_REVIEWS_FILTERS.relative_timerange !== relative_timerange) ||
    preset_timerange ||
    timerange
  )
    count++;
  count += ALL_REVIEWS_FILTERS.score?.length !== score?.length ? 1 : 0;
  count += Object.keys(sources)?.length !== source?.length && !source?.includes('all') ? 1 : 0;
  count +=
    Object.keys(languages)?.length !== language?.length && !language?.includes('all') ? 1 : 0;
  count += survey_ids?.length && !survey_ids?.includes('all') ? 1 : 0;
  count += sema_categories?.length ? 1 : 0;
  count += sema_subcategories?.length ? 1 : 0;
  count += entity_ids?.length ? 1 : 0;
  count += respondable ? 1 : 0;
  // TODO: Mariana agrees; let's wait for Reka's confirmation or denial https://trustyou.slack.com/archives/C062F93HDUZ/p1706630591237629
  // count += search_terms?.length ? 1 : 0;

  return count;
}

// TODO: check if this function is useful for survey types when re-introducing them in Inbox; if not, remove it
export const getSurveyTypeKey = (label: string): string => {
  return label
    .toLocaleLowerCase()
    .replace('&', 'And')
    .split(/[\s-]/gi)
    .map((item, index) => {
      if (index > 0) {
        item = item[0].toUpperCase() + item.substring(1);
      }
      return item;
    })
    .join('');
};

export const getViewFromFields = ({
  fields,
  viewLabel,
}: {
  fields: FiltersForm;
  viewLabel: string;
}) => {
  return {
    label: viewLabel,
    filters: getViewFilters(fields),
    isNew: true,
  };
};
