import { FormattedMessage } from 'react-intl';

import type { CombinedUserAndInvitation } from '@trustyou/shared';

export const NavHeader = ({ user }: { user?: CombinedUserAndInvitation | null }) =>
  user ? (
    <FormattedMessage id="organization.users.editDetails" defaultMessage="Edit details" />
  ) : (
    <FormattedMessage
      id="organization.users.inviteNewMember"
      defaultMessage="Invite a new member"
    />
  );
