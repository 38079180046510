import type { IStyles } from '@trustyou/shared';
import { theme } from '@trustyou/ui';

const drawerWidth = 256;
const miniDrawerWidth = 64;

const styles: IStyles = {
  container: { display: 'flex' },
  changelingMode: { paddingTop: 8 },
  topBar: { position: 'relative' },
  rightContainer: { height: '100vh' },
  expanded: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  collapsed: {
    width: `calc(100% - ${miniDrawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  mainContainer: {
    position: 'relative',
    paddingBottom: 3,
    paddingX: 3,
    height: 'calc(100% - 64px)',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  collapsedMainContainer: {
    width: `calc(100% - ${drawerWidth}px)`,
  },
};

export default styles;
