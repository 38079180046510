import type { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { green, lightBlue, purple } from '@mui/material/colors';
import { Chip, FormControl, InputLabel, MenuItem, Stack, Typography } from '@trustyou/ui';

import { LANGUAGE_OPTIONS } from '../../../feedback/constants';
import { CustomTextFieldWithSeparatedLabel } from '../../../shared';

type CustomSelectFieldWithChipProps = {
  label: ReactNode;
  name: string;
  chip?: {
    text: ReactNode;
    textColor: string;
    backgroundColor: string;
  };
};

const CustomSelectFieldWithChip = ({ label, name, chip }: CustomSelectFieldWithChipProps) => {
  const { register } = useFormContext();
  return (
    <Stack sx={{ flexDirection: 'row', alignItems: 'flex-end', gap: 2 }}>
      <FormControl variant="standard" fullWidth sx={{ maxWidth: 400 }}>
        <InputLabel shrink>{label}</InputLabel>
        <CustomTextFieldWithSeparatedLabel fullWidth select size="small" {...register(name)}>
          {LANGUAGE_OPTIONS.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </CustomTextFieldWithSeparatedLabel>
      </FormControl>
      {chip && (
        <Chip
          label={chip.text}
          sx={{ backgroundColor: chip.backgroundColor, marginBottom: 0.5, color: chip.textColor }}
        />
      )}
    </Stack>
  );
};

export function EditorSubmitSectionContent() {
  return (
    <Stack spacing={4}>
      <Stack>
        <Typography variant="h6">
          <FormattedMessage id="survey.common.submit-section" defaultMessage="Submit section" />
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <FormattedMessage
            id="survey.survey-editor.submit-section.description"
            defaultMessage="Displayed at the very end of the Survey. You may edit these elements in the Question- and Content Library."
          />
        </Typography>
      </Stack>

      <Stack spacing={3}>
        <Stack spacing={3}>
          <Typography variant="subtitle1">
            <FormattedMessage
              id="survey.survey-editor.submit-section.title-of-section"
              defaultMessage="Title of the section"
            />
          </Typography>
          <CustomSelectFieldWithChip
            label={
              <FormattedMessage
                id="survey.survey-editor.submit-section.section-title"
                defaultMessage="Section title"
              />
            }
            name={'surveyEditorSubmitSectionTitle'}
            chip={{
              text: (
                <FormattedMessage
                  id="survey.survey-editor.submit-section.section-title"
                  defaultMessage="Section title"
                />
              ),
              textColor: purple[800],
              backgroundColor: purple[50],
            }}
          />
        </Stack>
        <Stack spacing={3}>
          <Typography variant="subtitle1">
            <FormattedMessage
              id="survey.survey-editor.submit-section.optional-questions"
              defaultMessage="Optional questions"
            />
          </Typography>
          <CustomSelectFieldWithChip
            label={
              <FormattedMessage
                id="survey.survey-editor.submit-section.respondents-name.label"
                defaultMessage="Respondent’s name"
              />
            }
            name={'surveyEditorSubmitSectionRespondentsName'}
            chip={{
              text: (
                <FormattedMessage id="survey.common.text-question" defaultMessage="Text question" />
              ),
              textColor: 'info.dark',
              backgroundColor: lightBlue[50],
            }}
          />
          <CustomSelectFieldWithChip
            label={
              <FormattedMessage id="survey.common.email-address" defaultMessage="Email address" />
            }
            name={'surveyEditorSubmitSectionEmailAddress'}
            chip={{
              text: (
                <FormattedMessage id="survey.common.text-question" defaultMessage="Text question" />
              ),
              textColor: 'info.dark',
              backgroundColor: lightBlue[50],
            }}
          />
          <CustomSelectFieldWithChip
            label={
              <FormattedMessage
                id="survey.common.additional-message"
                defaultMessage="Additional message"
              />
            }
            name={'surveyEditorSubmitSectionAdditionalMessage'}
            chip={{
              text: <FormattedMessage id="survey.common.message" defaultMessage="Message" />,
              textColor: 'success.dark',
              backgroundColor: green[50],
            }}
          />
        </Stack>
        <Stack spacing={3}>
          <Typography variant="subtitle1">
            <FormattedMessage
              id="survey.survey-editor.submit-section.legal-other-content"
              defaultMessage="Legal & other content"
            />
          </Typography>
          <CustomSelectFieldWithChip
            label={
              <FormattedMessage
                id="survey.survey-editor.submit-section.data-privacy-agreement"
                defaultMessage="Data privacy agreement"
              />
            }
            name={'surveyEditorDataPrivacyAgreement'}
            chip={{
              text: (
                <FormattedMessage
                  id="survey.common.binary-question"
                  defaultMessage="Binary question"
                />
              ),
              textColor: 'info.dark',
              backgroundColor: lightBlue[50],
            }}
          />
          <CustomSelectFieldWithChip
            label={
              <FormattedMessage
                id="survey.survey-editor.submit-section.term-conditions-agreement"
                defaultMessage="Terms & Conditions agreement"
              />
            }
            name={'surveyEditorTermsConditionsAgreement'}
            chip={{
              text: (
                <FormattedMessage
                  id="survey.common.binary-question"
                  defaultMessage="Binary question"
                />
              ),
              textColor: 'info.dark',
              backgroundColor: lightBlue[50],
            }}
          />
          <CustomSelectFieldWithChip
            label={
              <FormattedMessage
                id="survey.survey-editor.submit-section.push-to-google"
                defaultMessage="Push to Google"
              />
            }
            name={'surveyEditorPushToGoogle'}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
