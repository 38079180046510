import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export type FeedbackPaginationState = {
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
};

export const useFeedbackPaginationStore = create<FeedbackPaginationState>()(
  persist(
    (set) => ({
      currentPage: 0,
      setCurrentPage: (currentPage) => set({ currentPage }),
    }),
    {
      name: 'feedback-pagination-storage',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
