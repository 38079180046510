import { FormattedMessage } from 'react-intl';

import type { CombinedUserAndInvitation } from '@trustyou/shared';
import { Button } from '@trustyou/ui';

export const CancelButton = ({ cancelSave }: { cancelSave: () => void }) => (
  <Button data-testid="cancel-invite-user" variant="outlined" onClick={cancelSave}>
    <FormattedMessage id="organization.users.cancel" defaultMessage="Cancel" />
  </Button>
);

export const SubmitButton = ({
  user,
  onSave,
}: {
  user?: CombinedUserAndInvitation | null;
  onSave: () => Promise<void>;
}) => (
  <Button
    data-testid="invite-user"
    data-gtm-id={user ? 'update_user' : 'invite_user'}
    variant="contained"
    onClick={onSave}
  >
    {user ? (
      <FormattedMessage id="organization.users.saveChanges" defaultMessage="Save changes" />
    ) : (
      <FormattedMessage id="organization.users.sendInvitation" defaultMessage="Send invitation" />
    )}
  </Button>
);
