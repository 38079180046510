import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, ContentPane, Typography } from '@trustyou/ui';

import EntitySubscriptions from './EntitySubscriptions';
import OrganizationSubscription from './OrganizationSubscription';

import { styles } from './styles';

export const GlobalSubscriptions = () => (
  <Box sx={styles.pageContainer}>
    <ContentPane
      headers={[
        <Typography sx={styles.paneHeader} variant="subtitle1" component="span">
          <FormattedMessage
            id="subscription.global.organization-subscription-header"
            defaultMessage="Organization subscription"
          />
        </Typography>,
      ]}
    >
      <OrganizationSubscription />
    </ContentPane>
    <ContentPane
      headers={[
        <Typography sx={styles.paneHeader} variant="subtitle1" component="span">
          <FormattedMessage
            id="subscription.global.entity-subscriptions-header"
            defaultMessage="Entity subscriptions"
          />
        </Typography>,
      ]}
    >
      <EntitySubscriptions />
    </ContentPane>
  </Box>
);
