import { useNavigate } from 'react-router-dom';

import { ForbiddenRoute } from '@trustyou/ui';

import { TopBarLayout } from '../Nav/TopBarLayout/TopBarLayout';

export const ForbiddenPage = () => {
  const navigate = useNavigate();
  const onGoBack = () => navigate(`/`);

  return (
    <TopBarLayout>
      <ForbiddenRoute onGoBack={onGoBack} />
    </TopBarLayout>
  );
};
