export type FontProps = {
  name: string;
  value: string;
};

export const sansSerifFonts = [
  { id: 'fira_sans', name: 'Fira Sans', value: 'Fira Sans, sans-serif' },
  { id: 'inter', name: 'Inter', value: 'Inter, sans-serif' },
  { id: 'lato', name: 'Lato', value: 'Lato, sans-serif' },
  { id: 'montserrat', name: 'Montserrat', value: 'Montserrat, sans-serif' },
  { id: 'noto_sans', name: 'Noto Sans', value: 'Noto Sans, sans-serif' },
  { id: 'open_sans', name: 'Open Sans', value: 'Open Sans, sans-serif' },
  { id: 'oswald', name: 'Oswald', value: 'Oswald, sans-serif' },
  { id: 'poppins', name: 'Poppins', value: 'Poppins, sans-serif' },
  { id: 'roboto', name: 'Roboto', value: 'Roboto, sans-serif' },
];

export const serifFonts = [
  { id: 'bitter', name: 'Bitter', value: 'Bitter, serif' },
  { id: 'crimson_text', name: 'Crimson Text', value: 'Crimson Text, serif' },
  { id: 'eb_garamond', name: 'EB Garamond', value: 'EB Garamond, serif' },
  { id: 'ibm_plex_serif', name: 'IBM Plex Serif', value: 'IBM Plex Serif, serif' },
  { id: 'lora', name: 'Lora', value: 'Lora, serif' },
  { id: 'merriweather', name: 'Merriweather', value: 'Merriweather, serif' },
  { id: 'playfair_display', name: 'Playfair Display', value: 'Playfair Display, serif' },
  { id: 'source_serif_4', name: 'Source Serif 4', value: 'Source Serif 4, serif' },
  { id: 'vollkorn', name: 'Vollkorn', value: 'Vollkorn, serif' },
];

export function findFont(id: string | undefined) {
  const font = sansSerifFonts.find((font) => font.id === id);
  if (font) {
    return font.value;
  }

  const serifFont = serifFonts.find((font) => font.id === id);
  if (serifFont) {
    return serifFont.value;
  }

  return sansSerifFonts[8].value;
}
