import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  searchBar: {
    display: 'flex',
    justifyContent: {
      xs: 'flex-start',
      sm: 'flex-end',
    },
    position: 'relative',
    height: 0,
    zIndex: 2,
    '& .MuiFormControl-root': {
      width: '220px',
      top: {
        xs: '20px',
        sm: 0,
      },
      left: {
        xs: '3px',
        sm: 0,
      },
    },
  },
};

export default styles;
