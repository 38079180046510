import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, ButtonGroup, IconButton } from '@mui/material';
import { faArrowLeft, faPlus } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Box, Button, Stack, StyledFontAwesomeIcon, Typography } from '@trustyou/ui';

import { DataGrid as ThemeDetailByEntityDataGrid } from '../../../components/theme/detail/by-entity/data-grid';
import { DataGrid as ThemeDetailByVersionDataGrid } from '../../../components/theme/detail/by-version/data-grid';
import { useSurveys } from '../../../service/hooks/use-surveys';

type ThemeDetailView = 'by-version' | 'by-entity';

export const ThemeDetail = () => {
  const navigate = useNavigate();
  const { membershipId } = useParams();
  const [view, setView] = useState<ThemeDetailView>('by-version');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: themes = [] } = useSurveys();

  const navigateToThemeList = () => {
    navigate(`/org/${membershipId}/survey-manager/theme/overview`);
  };

  return (
    <Stack>
      <Stack direction="row" spacing={1} alignItems="center" marginBottom={2}>
        <IconButton onClick={navigateToThemeList}>
          <StyledFontAwesomeIcon icon={faArrowLeft} size="sm" />
        </IconButton>
        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          <FormattedMessage
            id="survey.theme-detail.navigation-back"
            defaultMessage="Themes overview"
          />
        </Typography>
      </Stack>
      <Typography variant="h6" component="h2" marginBottom={3}>
        {/* TODO: replace surveys find with themes find */}
        {/* {surveys.find((survey) => survey.id === surveyId)?.name} */}
        Post-stay theme
      </Typography>
      <Stack direction="row" spacing={5} alignItems="center" marginBottom={1}>
        <ButtonGroup>
          <Button
            variant={view === 'by-version' ? 'contained' : 'outlined'}
            onClick={() => setView('by-version')}
          >
            <FormattedMessage id="survey.common.by-version" defaultMessage="By version" />
          </Button>
          <Button
            variant={view === 'by-entity' ? 'contained' : 'outlined'}
            onClick={() => setView('by-entity')}
          >
            <FormattedMessage id="survey.common.by-entity" defaultMessage="By entity" />
          </Button>
        </ButtonGroup>
        <Alert severity="warning">
          <FormattedMessage
            id="survey.theme-detail.missing-entities"
            defaultMessage="{missingEntityCount} entities are missing a version. They’ll use the default TrustYou theme"
            values={{ missingEntityCount: 10 }}
          />
        </Alert>
        <Box component="span" flexGrow={1} />
        <Button
          onClick={() => alert('navigate to theme editor')}
          variant="contained"
          startIcon={<FontAwesomeIcon icon={faPlus} />}
        >
          <FormattedMessage id="survey.common.new-version" defaultMessage="New version" />
        </Button>
      </Stack>
      {/* TODO: replace SurveyDetails grids with theme ones */}
      {view === 'by-version' && <ThemeDetailByVersionDataGrid />}
      {view === 'by-entity' && <ThemeDetailByEntityDataGrid />}
    </Stack>
  );
};
