import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Stack, Typography } from '@trustyou/ui';

import { Categories } from './categories';

import { useFetchSubcategoryMapping, useFetchTopLevelCategoryMapping } from '../../hooks';
import { FiltersFormFields, type SemaTopics } from '../../types';
import { Subcategories } from './subcategories';

export interface Category {
  id: string;
  title: string;
}

export type Subcategory = Category;

type Mapping = {
  [sector: string]: {
    [categoryId: string]: string[];
  };
};

interface CategoryFilterProps {
  initial?: {
    subcategories?: string[] | null;
    categories?: string[] | null;
  };
}

export const CategoryFilter = ({ initial }: CategoryFilterProps) => {
  const { setValue, getValues } = useFormContext();
  const { data: topLevelCategoryMapping, isPending: topLevelLoading } =
    useFetchTopLevelCategoryMapping();
  const { data: subcategoryMapping, isPending: subLevelLoading } = useFetchSubcategoryMapping();

  const mapBySectors = (ids: string[], mapping?: Mapping, sectors?: string[]) => {
    if (!mapping) return;
    return Object.keys(mapping).reduce((result: SemaTopics, sector: string) => {
      if (sectors && !sectors.includes(sector)) return result;

      // all filtered unique sector entries
      const sectorEntries = [
        ...new Set([
          ...(result?.[sector] ?? []),
          // get array of mapped sector content for given ids
          ...ids.reduce(
            (res: string[], id: string) => [...res, ...(mapping[sector][id] ?? [])],
            []
          ),
        ]),
      ];
      return {
        ...result,
        ...(sectorEntries.length > 0 && { [sector.toUpperCase()]: sectorEntries }),
      };
    }, {});
  };

  const mergeSectorMaps = (map1?: SemaTopics, map2?: SemaTopics) => {
    const res: SemaTopics = { ...map1 };
    // sector content union of both maps without duplicates
    map2 &&
      Object.keys(map2).forEach((sector) => {
        res[sector] = [...new Set([...(res[sector] ?? []), ...(map2[sector] ?? [])])];
      });
    return res;
  };

  const mapToSubCategories = (topLevelSectors?: SemaTopics) => {
    if (!topLevelSectors || !subcategoryMapping) return;
    return Object.keys(topLevelSectors).reduce(
      (result: SemaTopics, sector: string) => ({
        ...result,
        // get all unique subcategories for each top level category in one array per sector
        [sector.toUpperCase()]: topLevelSectors[sector].reduce(
          (res: string[], id: string) => [
            ...new Set([...res, ...subcategoryMapping[sector.toLowerCase()][id]]),
          ],
          []
        ),
      }),
      {}
    );
  };

  const saveTopicIds = () => {
    const topLevelCategoryIds = getValues(FiltersFormFields.Categories);
    const subcategoryIds = getValues(FiltersFormFields.Subcategories);
    const topLevelSectors = mapBySectors(topLevelCategoryIds, topLevelCategoryMapping);
    const subLevelSectors = mapBySectors(subcategoryIds, subcategoryMapping);
    // top level sectors need to be mapped to the sub levels to filter by correct topic ids
    const mappedTopLevelSectors = mapToSubCategories(topLevelSectors);

    const topicIds = mergeSectorMaps(mappedTopLevelSectors, subLevelSectors);
    setValue(FiltersFormFields.SemaTopics, topicIds);
  };

  return (
    <>
      <Typography variant="subtitle1" fontWeight={500}>
        <FormattedMessage
          id="inbox.filter.section.semantic-analysis"
          defaultMessage="Semantic analysis"
        />
      </Typography>
      <Stack marginTop={2} gap={3}>
        <Categories
          saveTopicIds={saveTopicIds}
          initialSelection={initial?.categories}
          isLoading={topLevelLoading}
        />
        <Subcategories
          saveTopicIds={saveTopicIds}
          initialSelection={initial?.subcategories}
          isLoading={subLevelLoading}
        />
      </Stack>
    </>
  );
};
