import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  loadingContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingTitle: {
    mt: 1,
  },
};

export default styles;
