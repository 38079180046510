import { Outlet, useLocation } from 'react-router-dom';

import { isRouteEndsWith, useEntityCount } from '@trustyou/shared';
import { Box, ContentPane, Stack } from '@trustyou/ui';

import { InboxEmptyState } from '../../components/inbox-empty-state';
import { InboxHeader } from '../../components/inbox-header';
import { ReviewsDataGrid } from '../../components/reviews-data-grid/reviews-data-grid';

export function Inbox() {
  const location = useLocation();
  const isParentRoute = isRouteEndsWith(location.pathname, 'inbox');
  const { data: entityCount } = useEntityCount();

  const isInboxEmpty = entityCount === 0;

  return (
    <ContentPane sx={{ height: '100%' }}>
      <Outlet />
      {isInboxEmpty ? (
        <InboxEmptyState />
      ) : (
        isParentRoute && (
          <Stack sx={{ height: '100%', gap: 5 }}>
            <InboxHeader />
            <Box sx={{ height: '100%', marginBlockEnd: -3, marginInline: -3 }}>
              <ReviewsDataGrid />
            </Box>
          </Stack>
        )
      )}
    </ContentPane>
  );
}
