import type { IStyles } from '../../types/common';

const styles: IStyles = {
  listItemButton: {
    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
  },
  checkedIcon: {
    color: 'primary.main',
  },
  list: {
    border: (theme) => `1px solid ${theme.palette.divider}`,
    borderRadius: 1,
    flex: '1',
    height: 'fit-content',
    overflowY: 'auto',
  },
  disabledList: {
    overflowY: 'hidden',
  },
  listItemIcon: {
    minWidth: 'unset',
  },
};

export default styles;
