import { type IFrameMessage, usePayloadStore, useRouteUtils } from '@trustyou/shared';

export function useIframeMessage() {
  const { setPayload } = usePayloadStore();
  const { navigateWithBase } = useRouteUtils();

  const handleIframeMessage = (message: IFrameMessage) => {
    if (message.type === 'NAVIGATION') {
      switch (message.path) {
        case undefined:
          setPayload(null);
          console.error('a navigate type message should have a path!');
          return;
        case 'inbox':
          console.info('handle navigate to inbox');
          setPayload(message.payload);
          navigateWithBase('inbox');
          return;
        default:
          console.error('a path from navigation message is not mapped properly');
          setPayload(null);
      }
    }
  };

  return handleIframeMessage;
}
