import { FormattedMessage } from 'react-intl';

import { Alert, AlertTitle } from '@trustyou/ui';

export const ReadOnlyRoleAlert = () => (
  <Alert severity="info" sx={{ height: '92px' }}>
    <AlertTitle>
      <FormattedMessage
        id="inbox.user.roles.read.only.inbox.alert.description"
        defaultMessage="You can’t respond to reviews"
      />
    </AlertTitle>
    <FormattedMessage
      id="inbox.user.roles.read.only.inbox.alert"
      defaultMessage="Your role is read only"
    />
  </Alert>
);
