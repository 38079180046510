import { type SyntheticEvent, useState } from 'react';
import { type IntlShape, useIntl } from 'react-intl';

import type { Benchmark } from '@trustyou/shared';
import { Box, Tab, Tabs } from '@trustyou/ui';

import BenchmarkCompetitorEntities from './BenchmarkCompetitorEntities';
import BenchmarkOwnEntities from './BenchmarkOwnEntities';

import { styles } from './styles';

type BenchmarkDetailViewProps = {
  benchmark: Benchmark;
};

interface BenchmarkDetailsTabStructure {
  ownEntities: string;
  competitorEntities: string;
}

const getDetailsTabs = (intl: IntlShape): BenchmarkDetailsTabStructure => ({
  ownEntities: intl.formatMessage({
    id: 'benchmark.details.own-entities',
    defaultMessage: 'Own entities',
  }),
  competitorEntities: intl.formatMessage({
    id: 'benchmark.details.competitor-entities',
    defaultMessage: 'Competitor entities',
  }),
});

export const BenchmarkDetailView = ({ benchmark }: BenchmarkDetailViewProps) => {
  const [value, setValue] = useState(0);
  const intl = useIntl();
  const tabs = getDetailsTabs(intl);

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={styles.container}>
      <Tabs sx={styles.barContainer} value={value} onChange={handleChange}>
        {Object.entries(tabs).map(([id, message]) => (
          <Tab data-testid={`benchmark-detail-tab-${message}`} key={id} label={message} />
        ))}
      </Tabs>
      <Box sx={styles.body}>
        {value === 0 && <BenchmarkOwnEntities benchmarkId={benchmark.id} />}
        {value === 1 && <BenchmarkCompetitorEntities benchmarkId={benchmark.id} />}
      </Box>
    </Box>
  );
};
