import type { CardProps } from '@mui/material';
import { Card } from '@trustyou/ui';

type CustomCardProps = CardProps & {
  withoutCardWrapper?: boolean;
  children: React.ReactNode;
};

export function CustomCard({ withoutCardWrapper = false, children, ...props }: CustomCardProps) {
  if (withoutCardWrapper) return children;

  return (
    <Card
      variant="outlined"
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        paddingBlock: 3,
        paddingInline: 2,
        [theme.breakpoints.only('xs')]: {
          borderRadius: 0,
          borderInline: 'none',
        },
      })}
      {...props}
    >
      {children}
    </Card>
  );
}
