import { Box } from '@trustyou/ui';

import LanguageChange from './LanguageChange';

import { styles } from '../styles';

export function GeneralSettings() {
  return (
    <Box sx={styles.generalPageContainer}>
      <LanguageChange />
    </Box>
  );
}
