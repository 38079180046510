import { FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';

// Form field with a non-floating label
export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  paddingBlockStart: theme.spacing(3.5),
  '& .MuiFormLabel-root': {
    transform: 'none',
    marginTop: 0,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
  },
  '& .MuiFormHelperText-root': {
    marginLeft: 0,
  },
}));
