import { useIntl } from 'react-intl';

import { Skeleton, type SxProps, type Theme } from '@mui/material';
import { useLanguageStore } from '@trustyou/shared';
import type { SelectProps } from '@trustyou/ui';

import type { SemaLanguage } from '../../../client';
import { useFetchSemaLanguages } from '../../../hooks';
import type { Option } from '../../../types';
import { DropdownMultiple } from '../dropdown-multiple';

export type DropdownLanguageProps = {
  fieldName: string;
  initialValue?: string[];
  size?: SelectProps['size'];
  fullWidth?: SelectProps['fullWidth'];
  sx?: SxProps<Theme>;
};

export const DropdownLanguage = ({
  fieldName,
  initialValue,
  size = 'medium',
  fullWidth = false,
  sx = {},
}: DropdownLanguageProps) => {
  const intl = useIntl();

  const { locale } = useLanguageStore();
  const { data, isPending: isLoading } = useFetchSemaLanguages(locale as SemaLanguage);

  if (isLoading)
    return (
      // @ts-expect-error sx?.width: Property 'width' does not exist on type 'CSSPseudoSelectorProps<Theme>'.
      <Skeleton variant="rounded" height={size === 'small' ? 41 : 57} width={sx?.width ?? 220} />
    );

  // TODO: method `toSorted()` can be used instead of the in-place `sort()` when bumping Node to v20+
  const sortedLangOptions: Option[] = Object.entries(data ?? {})
    .map(([langKey, label]) => ({
      label,
      value: langKey,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
    .concat([
      {
        label: intl.formatMessage({
          id: 'inbox.filter.language.noLanguage',
          defaultMessage: 'No language',
        }),
        value: 'no_language',
      },
    ]);

  return (
    <DropdownMultiple
      fieldName={fieldName}
      options={sortedLangOptions}
      allValuesLabel={intl.formatMessage({
        id: 'inbox.filter.language.all',
        defaultMessage: 'All languages',
      })}
      dataTestId="filterFieldLanguage"
      label={intl.formatMessage({
        id: 'inbox.filter.language.label',
        defaultMessage: 'Language',
      })}
      initialSelectedValues={initialValue}
      size={size}
      fullWidth={fullWidth}
      sx={sx}
      showLabelAsResult
    />
  );
};
