import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import {
  InfoModal,
  ReportsDrawer,
  Reviews,
  useExportDashboardHandle,
  useSendImpactScore,
} from '@trustyou/analytics';
import { useHasSchedulePermission } from '@trustyou/reports';
import {
  SISENSE_SERVER_PATH,
  applySecurityFilters,
  trackDashboardLoading,
  useAuthorizationStore, // useDashboardId,
  useMembershipStore,
  useRouteUtils,
  useShowBenchmarks,
} from '@trustyou/shared';
import { Box, DashboardLoader } from '@trustyou/ui';

import styles from './styles';

type SisenseProps = { show?: boolean; isChangeling?: boolean };

export const SisenseDebugger = ({ show = true, isChangeling }: SisenseProps) => {
  const [searchParams] = useSearchParams();
  const isShowBenchmarksAllowed = useShowBenchmarks();
  const [dashboardLoaded, setDashboardLoaded] = useState(false);
  const hasSchedulePermission = useHasSchedulePermission();
  const [showReports, setShowReports] = useState<string | undefined>();
  const { tokenStore } = useAuthorizationStore();
  const { membership } = useMembershipStore();
  // const { data: dashboardIdResponse } = useDashboardId();
  // Hardcoded dashboard id
  const dashboardIdResponse = {
    id: searchParams.get('dashboard_id') || '66d5611e8f8bf900335e7fb2',
  };
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const sisenseFrameRef = useRef<unknown>(null);
  const loadingStartTimeRef = useRef<unknown>(null);
  const [lastSelectedDashboard, setLastSelectedDashboard] = useState<string>();

  const { sendImpactScore } = useSendImpactScore(iFrameRef);

  // const contestFilterList = useRef<Record<string, never> | null>(null);
  const { exportDashboard } = useExportDashboardHandle();

  const navigate = useNavigate();
  const { navigateWithBase } = useRouteUtils();
  const location = useLocation();

  const trackLoadingStart = () => {
    if (!loadingStartTimeRef.current) {
      loadingStartTimeRef.current = new Date();
    }
  };

  const trackLoadingEnd = (id: string, title: string) => {
    trackDashboardLoading(id, title, loadingStartTimeRef.current as Date);
    loadingStartTimeRef.current = null;
  };

  // Navigate to previous page after back button
  const popEventHandler = () => {
    if (window.location.href.includes('/sisense-debugger') && !location.state?.path) {
      // Skip sisense jwt route on back button click
      navigate(-2);
    }
  };

  const messageEventHandler = (event: MessageEvent) => {
    if (!window.location.href.includes('/sisense-debugger')) return;
    if (event.origin !== SISENSE_SERVER_PATH) return;

    sendImpactScore(event);

    if (typeof event.data !== 'string') return;

    if (event.data.includes('addCompetitors')) {
      navigateWithBase('organization/entities');
    } else if (event.data.includes('dashboardAction')) {
      const split = event.data.split('-');
      const action = split[1];
      const dashboardId = split[2];
      if (action === 'reports') {
        setShowReports(dashboardId);
      } else if (action === 'downloadExcel') {
        exportDashboard(dashboardId, iFrameRef);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('popstate', popEventHandler);
    window.addEventListener('message', messageEventHandler);
    return () => {
      window.removeEventListener('popstate', popEventHandler);
      window.removeEventListener('message', messageEventHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // AccessToken from
  const accessToken = tokenStore && membership?.id ? tokenStore[membership.id] : false;

  useEffect(() => {
    renderDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, dashboardIdResponse?.id]);

  const enableDashboardActions = () => {
    iFrameRef.current?.contentWindow?.postMessage(
      {
        action: 'dashboardActions-enable',
        enableReports: hasSchedulePermission,
        enableBenchmark: isShowBenchmarksAllowed,
      },
      SISENSE_SERVER_PATH as string
    );
  };

  const renderDashboard = () => {
    if (!accessToken || !dashboardIdResponse?.id) return;
    trackLoadingStart();

    // TODO: Investigate why declaration.d.ts is not considered by current tsconfig
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { SisenseFrame } = window['sisense.embed'];

    // Create an instance of SisenseFrame
    const sisenseFrame = new SisenseFrame({
      url: SISENSE_SERVER_PATH,
      dashboard: lastSelectedDashboard || dashboardIdResponse?.id,
      settings: {
        showToolbar: false,
        showLeftPane: false,
        showRightPane: true,
      },
      // Existing iFrame DOM element
      element: iFrameRef.current,
      // Disable the autosave of user interactions with the dashboard
      // volatile: true,
    });
    sisenseFrameRef.current = sisenseFrame;

    // Calling render() will apply the above configuration to the existing iFrame element
    sisenseFrame.render();
    sisenseFrame.app.on('stateappchanged', trackLoadingStart);

    // When dashboard is loaded
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sisenseFrame.dashboard.on('dashboardloaded', (args: any) => {
      // Push state to trigger pop state event and skip the empty page (jwt return_to)
      window.history.pushState(null, '', window.location.href);
      setLastSelectedDashboard(args.dashboard.oid);
      applySecurityFilters(args.dashboard.filters, sisenseFrame, accessToken);
      enableDashboardActions();
      // Some delay to render actions widgets in firefox
      setTimeout(() => {
        enableDashboardActions();
      }, 500);
      setDashboardLoaded(true);
      trackLoadingEnd(args.dashboard.oid, args.dashboard.title);
    });

    // When any filter change
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // sisenseFrame.dashboard.on('dashboardfilterschanged', (args: any) => {
    //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //   const contextFilterExists = args.dashboard.filters.find((filter: any) =>
    //     ['organization_id', 'scope_id'].includes(filter.jaql.title)
    //   );

    //   // If context filters do not exist in current filter list we stop.
    //   if (!contextFilterExists) return;

    //   // I need to save current list of dashboard filters to check if something changed on next step.
    //   // The event 'dashboardfilterschanged' is triggered at every filter change and on args I can just receive
    //   // the list of all current filters and not the filter that triggered the event.
    //   const newContextFilters = args.dashboard.filters;

    //   // If some filters changed I re-apply the context filters. This prevent to get the default value on clicking
    //   // the restore button on dashboard.
    //   if (JSON.stringify(contestFilterList.current) !== JSON.stringify(newContextFilters)) {
    //     applyFilters(sisenseFrame, accessToken);
    //   }

    //   // I save current list of filters on ref for next round.
    //   contestFilterList.current = newContextFilters;
    // });
  };

  return (
    <Box sx={{ ...styles.container, ...(!show && { display: 'none' }) }}>
      {!dashboardLoaded && (
        <DashboardLoader
          message={
            <FormattedMessage
              id="analytics.loadingDashboard"
              defaultMessage="Loading your dashboard..."
            />
          }
        />
      )}
      <iframe
        ref={iFrameRef}
        title="Sisense Embed"
        width="80%"
        height="100%"
        style={{
          border: 'none',
          ...(!dashboardLoaded && { display: 'none' }),
        }}
      />
      {hasSchedulePermission && showReports && show ? (
        <ReportsDrawer
          dashboardId={showReports}
          onClose={() => {
            setShowReports(undefined);
          }}
          changelingMode={isChangeling}
        />
      ) : null}
      {show && <Reviews />}
      {show && <InfoModal />}
    </Box>
  );
};
