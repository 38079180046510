import { defineMessages, useIntl } from 'react-intl';

import { faChevronsLeft } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { useSidebarStore } from '@trustyou/shared';
import { ICON_BUTTON_HEIGHT_SMALLER, ICON_BUTTON_WIDTH_SMALLER, NavIcon } from '@trustyou/ui';

const toggleMessages = defineMessages({
  collapse: {
    id: 'drawer.action.collapse',
    defaultMessage: 'Collapse',
  },
  expand: {
    id: 'drawer.action.expand',
    defaultMessage: 'Expand',
  },
});

export function ToggleSidebarButton() {
  const intl = useIntl();
  const { isCollapsed, toggleSidebar } = useSidebarStore();

  return (
    <NavIcon
      data-testid="toggle-button"
      data-gtm-id="toggle-button"
      data-gtm-class="toggle-button"
      icon={faChevronsLeft}
      tooltip={
        isCollapsed
          ? intl.formatMessage(toggleMessages.expand)
          : intl.formatMessage(toggleMessages.collapse)
      }
      onClick={toggleSidebar}
      sx={{
        height: ICON_BUTTON_HEIGHT_SMALLER,
        width: ICON_BUTTON_WIDTH_SMALLER,
        '> svg': {
          height: '14px',
          width: '14px',
          transition: 'transform 0.3s ease',
          transform: isCollapsed ? 'rotate(180deg)' : 'rotate(0deg)',
        },
        backgroundColor: (theme) => theme.palette.action.hover,
        '&:hover': {
          backgroundColor: (theme) => theme.palette.action.selected,
        },
        marginBlock: 1.75,
        marginInlineStart: 1.85,
      }}
      disableRipple
    />
  );
}
