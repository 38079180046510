import { useState } from 'react';
import { useController } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { TextField } from '@trustyou/ui';

import { Question, type QuestionProps } from '../../components/question';

export type NumberQuestionProps = QuestionProps & {
  min?: number;
  max?: number;
};

export function NumberQuestion({ name, control, errors, min, max, ...props }: NumberQuestionProps) {
  const intl = useIntl();
  const {
    field,
    fieldState: { error },
  } = useController({ name, control });
  const errorMessage = error?.message ?? '';
  const [value, setValue] = useState(field.value ?? '');

  const isMin = min !== undefined;
  const isMax = max !== undefined;
  const isRange = isMin && isMax;

  const isMinError = isMin && Number(value) < min;
  const isMaxError = isMax && Number(value) > max;
  const isRangeError = isRange && (isMinError || isMaxError);
  const isError = isMinError || isMaxError || isRangeError;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const numericInputValue = Number(inputValue);

    field.onChange(numericInputValue);
    setValue(inputValue);
  };

  const getHelperText = () => {
    if (value.length === 0) {
      return errorMessage ?? '';
    }
    if (isRangeError) {
      return intl.formatMessage(
        {
          id: 'survey.question.number.range.helper-text',
          defaultMessage: 'The number must be between {min} and {max}',
        },
        { min, max }
      );
    }
    if (isMinError) {
      return intl.formatMessage(
        {
          id: 'survey.question.number.min.helper-text',
          defaultMessage: 'The number must be {min} or more',
        },
        { min }
      );
    }
    if (isMaxError) {
      return intl.formatMessage(
        {
          id: 'survey.question.number.max.helper-text',
          defaultMessage: 'The number must be {max} or less',
        },
        { max }
      );
    }
  };

  return (
    <Question name={name} control={control} {...props}>
      <TextField
        type="number"
        value={value}
        onChange={handleChange}
        inputProps={{ min, max }}
        error={isError}
        helperText={getHelperText()}
        fullWidth
        FormHelperTextProps={{ sx: { marginInline: 0 } }}
      />
    </Question>
  );
}
