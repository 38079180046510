import type { IStyles } from '../../types/common';

const styles: IStyles = {
  standardInput: {
    '& input': {
      paddingBottom: 1,
    },
  },
};

export default styles;
