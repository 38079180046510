import type { ReactNode } from 'react';

import type { CardProps, SxProps, Theme } from '@mui/material';
import { Card } from '@trustyou/ui';

type BaseCardProps = {
  children: ReactNode;
  sx?: SxProps<Theme>;
  onClick: CardProps['onClick'];
};

export function BaseCard({ onClick, sx, children }: BaseCardProps) {
  return (
    <Card
      onClick={onClick}
      variant="outlined"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: 3,
        transition: 'translate .15s ease-in-out',
        '&:hover': {
          cursor: 'pointer',
          borderColor: 'primary.main',
          translate: '0 -4px',
        },
        ...sx,
      }}
    >
      {children}
    </Card>
  );
}
