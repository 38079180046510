import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { faCopy, faPencil, faTrashCan } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Stack } from '@trustyou/ui';

import { IconButtonWithTooltip } from '../../icon-button-with-tooltip';

export type ResponseFieldActionWrapperProps = {
  children: ReactNode;
  isDisabled?: boolean;
  onCopy: () => void;
  onEdit: () => void;
  onDelete: () => void;
};

export const ResponseFieldActionWrapper = ({
  children,
  isDisabled,
  onCopy,
  onEdit,
  onDelete,
}: ResponseFieldActionWrapperProps) => {
  const intl = useIntl();

  return (
    <Stack direction="row" spacing={0.5}>
      {children}
      <Stack justifyContent="space-between">
        <Stack spacing={1}>
          <IconButtonWithTooltip
            aria-label="textbox copy button"
            data-gtm-class="inbox_response_textbox_action"
            data-gtm-id="copy"
            tooltip={intl.formatMessage({
              id: 'inbox.action.copy',
              defaultMessage: 'Copy',
            })}
            icon={faCopy}
            onClick={onCopy}
            disabled={isDisabled}
          />
          <IconButtonWithTooltip
            aria-label="textbox edit button"
            data-gtm-class="inbox_response_textbox_action"
            data-gtm-id="edit"
            tooltip={intl.formatMessage({
              id: 'inbox.edit',
              defaultMessage: 'Edit',
            })}
            icon={faPencil}
            onClick={onEdit}
            disabled={isDisabled}
          />
        </Stack>
        <IconButtonWithTooltip
          aria-label="textbox delete button"
          data-gtm-class="inbox_response_textbox_action"
          data-gtm-id="delete"
          tooltip={intl.formatMessage({
            id: 'inbox.response.info.delete',
            defaultMessage: 'Delete text',
          })}
          icon={faTrashCan}
          onClick={onDelete}
          disabled={isDisabled}
        />
      </Stack>
    </Stack>
  );
};
