import { FormattedMessage } from 'react-intl';

import {
  type Segment,
  type SegmentGroup,
  type SegmentGroupCreated,
  type SegmentGroupPayload,
  useAlertStore,
  useCreateGroup,
  useUpdateGroup,
} from '@trustyou/shared';
import { BackdropSpinner, Box, FullScreenModal } from '@trustyou/ui';
import type { AxiosError } from 'axios';

import UpdateGroupsForm from './UpdateGroupsForm';

import { DashboardDelay } from '../../../../../../components';
import styles from './styles';

type UpdateSegmentsProps = {
  onClose: () => void;
  segment: Segment;
  group?: SegmentGroup;
  onGroupEdit?: (group: SegmentGroup) => void;
};

const UpdateGroups = ({ segment, group, onGroupEdit, onClose }: UpdateSegmentsProps) => {
  const { alert } = useAlertStore();
  const onError = (error: AxiosError) => {
    if (JSON.stringify(error.response?.data).includes('"code":"mece_rule"')) {
      alert.error(
        <FormattedMessage
          id="organization.segments.groupCreateFailed"
          defaultMessage="The entities matching the selected rule are already assigned to another group within this segment"
        />
      );
    } else {
      alert.genericError();
    }
  };
  const { mutate: createGroup, isPending: isCreating } = useCreateGroup(
    (data: SegmentGroupCreated) => {
      alert.success(
        <FormattedMessage
          id="organization.segments.groupCreateSuccess"
          defaultMessage="{group} added. {delay}."
          values={{ group: data.name, delay: <DashboardDelay /> }}
        />
      );
      onClose();
    },
    onError
  );
  const { mutate: updateGroup, isPending: isUpdating } = useUpdateGroup(
    (data: SegmentGroupCreated) => {
      alert.success(
        <FormattedMessage
          id="organization.segments.changesSaved"
          defaultMessage="Changes saved. {delay}"
          values={{ delay: <DashboardDelay /> }}
        />
      );
      if (onGroupEdit) {
        onGroupEdit({ ...(group as SegmentGroup), ...data });
      }
      onClose();
    },
    onError
  );

  const onSave = (data: SegmentGroupPayload) => {
    if (data.id) {
      updateGroup(data);
    } else {
      createGroup({
        ...data,
        segment_id: segment.id,
      });
    }
  };

  return (
    <FullScreenModal open onClose={onClose}>
      <Box sx={styles.modalBody}>
        <UpdateGroupsForm
          segment={segment}
          group={group}
          dataName={group ? 'update-group' : 'add-group'}
          onSave={onSave}
        />
      </Box>
      <BackdropSpinner isLoading={isCreating || isUpdating} />
    </FullScreenModal>
  );
};

export default UpdateGroups;
