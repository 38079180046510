import { FormattedMessage } from 'react-intl';

import { DialogContentText } from '@mui/material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@trustyou/ui';

type DeleteDialogProps = {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
};

export function DeleteViewDialog({ open, onClose, onDelete }: DeleteDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        <FormattedMessage id="inbox.delete-this-view" defaultMessage="Delete this view?" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage
            id="inbox.not-able-to-recover-it"
            defaultMessage="You will not be able to recover it."
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          <FormattedMessage id="inbox.action.cancel" defaultMessage="Cancel" />
        </Button>
        <Button onClick={onDelete} variant="contained" color="error">
          <FormattedMessage id="inbox.action.delete" defaultMessage="Delete" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
