import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import type { GridPaginationModel, GridRowParams, GridSortModel } from '@mui/x-data-grid-pro';
import { useQueryClient } from '@tanstack/react-query';
import {
  DEFAULT_PAGINATION_MODEL,
  DEFAULT_SORT_MODEL,
  FETCH_SEGMENTS,
  type Segment,
  getPaginationRequest,
  getSessionState,
  getSortRequest,
  setSessionState,
  useAlertStore,
  useDeleteSegment,
  useSegments,
} from '@trustyou/shared';
import { DataGrid, EmptySegmentState } from '@trustyou/ui';

import DeleteSegments from './DeleteSegments';
import EditSegments from './EditSegments';
import Groups from './Groups';
import GroupEntities from './Groups/GroupEntities';
import { UpdateGroups } from './Groups/UpdateGroups';
import SegmentNavBar from './SegmentNavBar';
import useSegmentsTableColumns from './useSegmentsTableColumns';

import { DashboardDelay } from '../../../../components';
import { useSegmentStore } from '../../../../store';
import styles from './styles';

const PAGINATION_STATE_KEY = 'SEGMENTS_PAGINATION';
const SORT_STATE_KEY = 'SEGMENTS_SORT';

export const Segments = () => {
  const queryClient = useQueryClient();
  const {
    selectedSegment,
    setSelectedSegment,
    selectedGroup,
    setSelectedGroup,
    editDashboardSegmentsModelOpen,
    setEditDashboardSegmentsModelOpen,
  } = useSegmentStore();

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
    getSessionState(PAGINATION_STATE_KEY) || DEFAULT_PAGINATION_MODEL
  );
  const [sortModel, setSortModel] = useState<GridSortModel>(
    getSessionState(SORT_STATE_KEY) || DEFAULT_SORT_MODEL
  );
  const { data, isFetching } = useSegments(
    getPaginationRequest(paginationModel),
    getSortRequest(sortModel)
  );
  const [deletableSegment, setDeletableSegment] = useState<Segment>();
  const { alert } = useAlertStore();
  const [openEditPopup, setOpenEditPopup] = useState(false);

  const toggleEditPopup = () => {
    setOpenEditPopup(!openEditPopup);
  };
  const handleRowClick = (params: GridRowParams) => {
    setSelectedSegment(params.row);
  };

  const onGoBack = () => {
    if (selectedGroup) {
      setSelectedGroup(undefined);
    } else {
      setSelectedSegment(undefined);
    }
  };

  const { mutate: handleDeleteSegment } = useDeleteSegment(
    () => {
      alert.info(
        <FormattedMessage
          id="organization.segments.deleted"
          defaultMessage="{segment} deleted. {delay}"
          values={{ segment: deletableSegment?.name, delay: <DashboardDelay /> }}
        />
      );
    },
    () => {
      alert.genericError();
    },
    () => setDeletableSegment(undefined)
  );

  const onDelete = () => {
    handleDeleteSegment(deletableSegment?.id as string);
  };

  const onPaginationModelChange = (model: GridPaginationModel) => {
    queryClient.removeQueries({ queryKey: [FETCH_SEGMENTS] });
    setPaginationModel(model);
    setSessionState(PAGINATION_STATE_KEY, model);
  };

  const onSortModelChange = (model: GridSortModel) => {
    if (!model.length) return;
    queryClient.removeQueries({ queryKey: [FETCH_SEGMENTS] });
    setSortModel(model);
    setSessionState(SORT_STATE_KEY, model);
    setPaginationModel((state) => {
      const newState = { ...state, page: DEFAULT_PAGINATION_MODEL.page };
      setSessionState(PAGINATION_STATE_KEY, newState);
      return newState;
    });
  };

  const columns = useSegmentsTableColumns({
    onEditDashboardSegments: () =>
      setEditDashboardSegmentsModelOpen(!editDashboardSegmentsModelOpen),
    onDelete: setDeletableSegment,
  });

  if (!data || isFetching) {
    // TODO: Display loader when the design is ready
    return null;
  }

  if (selectedSegment) {
    return (
      <>
        <SegmentNavBar
          segment={selectedSegment}
          group={selectedGroup}
          onGoBack={onGoBack}
          onEdit={toggleEditPopup}
        />
        {selectedGroup ? (
          <>
            <GroupEntities group={selectedGroup} />
            {openEditPopup && (
              <UpdateGroups
                segment={selectedSegment}
                group={selectedGroup}
                onGroupEdit={setSelectedGroup}
                onClose={toggleEditPopup}
              />
            )}
          </>
        ) : (
          <>
            <Groups segment={selectedSegment} onGroupSelect={setSelectedGroup} />
            {openEditPopup && (
              <EditSegments
                segment={selectedSegment}
                onSegmentEdit={setSelectedSegment}
                onClose={toggleEditPopup}
              />
            )}
          </>
        )}
      </>
    );
  }

  if (data.pagination.total === 0) return <EmptySegmentState />;

  return (
    <>
      <DataGrid
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        rowCount={data.pagination.total}
        sx={styles.table}
        rowHeight={64}
        columns={columns}
        rows={data.data}
        onRowClick={handleRowClick}
        containerStyle={styles.tableContainer}
        autoHeight={false}
      />
      <DeleteSegments
        onClose={() => {
          setDeletableSegment(undefined);
        }}
        onDelete={onDelete}
        segment={deletableSegment}
      />
    </>
  );
};
