import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  container: {
    top: '64px',
    position: 'relative',
    overflowY: 'auto',
    height: 'calc(100vh - 64px)',
  },
  changelingMode: {
    paddingTop: 8,
    '& .MuiDrawer-paper': {
      paddingTop: 8,
    },
    '& > header': {
      marginTop: 8,
    },
  },
  changelingModeContainer: {
    height: 'calc(100vh - 128px)',
  },
};

export default styles;
