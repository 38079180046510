import { useState } from 'react';
import { useParams } from 'react-router-dom';

import type { GridColDef, GridPaginationModel } from '@mui/x-data-grid-pro';
import {
  DEFAULT_PAGE_SIZE,
  stringToShortDate,
  useDataGridIntl,
  useLanguageStore,
} from '@trustyou/shared';
import { Chip, DataGridPro, Typography } from '@trustyou/ui';

import type { Questionnaire_Output } from '../../../../client';
import { useQuestionnaires } from '../../../../service/hooks/use-questionnaires';
import { CustomToolbar } from '../../../shared/custom-toolbar';
import styles from '../../../shared/styles';

function useColumns() {
  const columns: GridColDef<Questionnaire_Output>[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
    },
    {
      field: 'version',
      headerName: 'Questionnaire',
      flex: 1,
      valueGetter: ({ row }) => row.internal_name,
      renderCell: ({ value }) => (
        <Typography variant="body2" fontWeight={500}>
          {value}
        </Typography>
      ),
    },
    {
      field: 'entityCount',
      headerName: 'Entities',
      flex: 1,
      valueGetter: ({ row }) => row.metadata.scopes?.length ?? 0,
    },
    {
      field: 'entitesAssigned',
      headerName: 'Entities assigned',
      flex: 1,
      valueGetter: ({ row }) =>
        row.metadata.scopes && row.metadata.scopes?.length > 0 ? 'Assigned' : 'Not assigned',
      renderCell: ({ value }) => (
        <Chip
          label={value}
          size="small"
          variant={value === 'Assigned' ? 'pastelSuccess' : 'pastelWarning'}
        />
      ),
    },
    {
      field: 'created',
      headerName: 'Created',
      flex: 1,
      valueGetter: ({ row }) => stringToShortDate(row.metadata.created_at ?? ''),
      renderCell: ({ value }) => (
        <Typography variant="body2" color="text.secondary">
          {value}
        </Typography>
      ),
    },
    {
      field: 'modified',
      headerName: 'Last modified',
      flex: 1,
      valueGetter: ({ row }) => stringToShortDate(row.metadata.updated_at ?? ''),
      renderCell: ({ value }) => (
        <Typography variant="body2" color="text.secondary">
          {value}
        </Typography>
      ),
    },
  ];
  return columns;
}

export function DataGrid() {
  const dataGridIntl = useDataGridIntl();
  const { locale } = useLanguageStore();
  const { surveyId = '' } = useParams();
  const columns = useColumns();
  const { data: rows = [] } = useQuestionnaires({ surveyId });
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  return (
    <DataGridPro
      columns={columns}
      rows={rows}
      pageSizeOptions={[15, 50, 100]}
      pagination
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      disableRowSelectionOnClick
      ignoreDiacritics
      slots={{
        toolbar: CustomToolbar,
      }}
      sx={styles.disableCellOutline}
      localeText={dataGridIntl[locale].components.MuiDataGrid.defaultProps.localeText}
      density="standard"
      initialState={{
        columns: {
          columnVisibilityModel: {
            id: false,
          },
        },
      }}
    />
  );
}
