import { useState } from 'react';

import { useGoogleAuthUrl } from '@trustyou/shared';

import GoogleConnectInstructions from './GoogleConnectInstructions';

import { GOOGLE_REDIRECT_URL } from '../../../constants/reviewProviders';

const GoogleConnectManager = () => {
  const [isConnectingGoogle, setIsConnectingGoogle] = useState<boolean>(false);

  const { data } = useGoogleAuthUrl(GOOGLE_REDIRECT_URL, isConnectingGoogle);

  if (data?.auth_url) {
    window.location.href = data.auth_url;
    return null;
  }

  return <GoogleConnectInstructions onAuthorize={() => setIsConnectingGoogle(true)} />;
};

export default GoogleConnectManager;
