import { grey, lightBlue } from '@mui/material/colors';

import { ICON_BUTTON_HEIGHT, ICON_BUTTON_WIDTH } from '../../constants';
import type { IStyles } from '../../types/common';

const styles: IStyles = {
  box: {
    width: ICON_BUTTON_WIDTH,
    height: ICON_BUTTON_HEIGHT,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 1,
    background: lightBlue[50],
    color: lightBlue[300],
  },
  disabled: {
    background: grey[50],
    color: grey[300],
  },
};

export default styles;
