import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import type { StyleUnit } from '@trustyou/shared';
import { EmptyStatePlaceholder } from '@trustyou/ui';

import personSelectingData from '../../assets/illustrations/person-selecting-data.svg';
import styles from './styles';

export type NoEntitiesAdminProps = {
  onImportEntities?: () => void;
  sx?: StyleUnit;
  children?: ReactNode;
};

export const NoEntitiesAdmin = ({ sx, onImportEntities, children }: NoEntitiesAdminProps) => (
  <EmptyStatePlaceholder
    img={personSelectingData}
    title={<FormattedMessage id="tyDesign.noEntitiesYet" defaultMessage="No entities yet" />}
    description={
      <FormattedMessage
        id="tyDesign.emptyState.nonOnboardedAdminDescription"
        defaultMessage="It seems that no entities were added. When you import entities, they will show up here."
      />
    }
    emptyStateHeader={styles.emptyStateHeader}
    emptyStateContainer={{ ...styles.emptyStateContainer, ...styles.noDataContainer, ...sx }}
    action={
      onImportEntities && {
        testId: 'onboard-entities-button',
        message: (
          <FormattedMessage
            id="organization.action.imporEntities"
            defaultMessage="Import Entities"
          />
        ),
        handler: onImportEntities,
        styles: styles.actionButton,
      }
    }
  >
    {children}
  </EmptyStatePlaceholder>
);
