import { FormattedMessage } from 'react-intl';

import { TENTATIVE_WAITING_TIME_IN_MINUTES } from '../../constants/organization';

export const DashboardDelayTime = () => (
  <b>
    <FormattedMessage
      id="notifications.dashboardDelay.time"
      defaultMessage="up to {minutes} minutes"
      values={{ minutes: TENTATIVE_WAITING_TIME_IN_MINUTES }}
    />
  </b>
);

export const DashboardDelay = () => (
  <FormattedMessage
    id="notifications.dashboardDelay"
    defaultMessage="It might take {delay} for this change to reflect on your dashboard."
    values={{ delay: <DashboardDelayTime /> }}
  />
);
