import { DateTime } from 'luxon';
import * as yup from 'yup';

export const schedulingSchema = yup.object().shape({
  name: yup.string().required(),
  dashboardId: yup.string().required(),
  recipients: yup
    .array()
    .of(yup.object({ value: yup.string().required(), label: yup.string().required() }))
    .test({ test: (array = []) => array.length > 0 })
    .default([]),
  // NOTE: png will be supported in the future
  fileFormat: yup.string().oneOf(['pdf', 'excel']).default('pdf'),
  // NOTE: portrait not fully supported, default to landscape
  // fileOrientation: yup.string().oneOf(['portrait', 'landscape']).default('landscape'),
  frequency: yup.string().oneOf(['weekly', 'monthly', 'daily']).default('weekly'),
  dayOfWeek: yup
    .number()
    .min(1)
    .max(7)
    .when('frequency', {
      is: 'weekly',
      then: (schema) => schema.required().default(1),
      otherwise: (schema) => schema.optional(),
    }),
  dayOfMonth: yup
    .number()
    .min(1)
    .max(31)
    .when('frequency', {
      is: 'monthly',
      then: (schema) => schema.required().default(1),
      otherwise: (schema) => schema.optional(),
    }),
  timeOfDay: yup
    .string()
    .default(DateTime.local().set({ hour: 11, minute: 0, second: 0, millisecond: 0 }).toISO()),
  dashboardFilters: yup.string().default(''),
  excludedFilters: yup.string().default(''),
  language: yup.string().default(''),
});

export type SchedulingForm = yup.InferType<typeof schedulingSchema>;
