import type { IStyles } from '@trustyou/shared';

export const styles: IStyles = {
  pageContainer: {
    flex: 1,
    overflow: 'auto',
  },
  body: { mt: 5 },
  loader: { paddingY: 5, textAlign: 'center' },
};
