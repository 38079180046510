import { FormattedMessage } from 'react-intl';

import { faBookBlank, faEnvelope, faSignsPost } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { openFeatureTour } from '@trustyou/shared';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  SpotIcon,
  Typography,
} from '@trustyou/ui';

import { useZendeskLinks } from '../../../hooks';
import styles from './styles';

const HelpLinks = () => {
  const { openHelpManual, openSupportAndFeedback } = useZendeskLinks();

  const links = [
    {
      id: 'help-manual',
      name: <FormattedMessage id="home.helpLinks.helpManual" defaultMessage="Help manual" />,
      onClick: openHelpManual,
      icon: faBookBlank,
    },
    {
      id: 'support-and-feedback',
      name: (
        <FormattedMessage
          id="home.helpLinks.supportAndFeedback"
          defaultMessage="Support and feedback"
        />
      ),
      onClick: openSupportAndFeedback,
      icon: faEnvelope,
    },
    {
      id: 'feature-tour',
      name: <FormattedMessage id="home.helpLinks.featureTour" defaultMessage="Feature tour" />,
      onClick: openFeatureTour,
      icon: faSignsPost,
    },
  ];
  return (
    <Box sx={styles.container}>
      <Typography variant="subtitle1">
        <FormattedMessage id="home.needHelp" defaultMessage="Need help?" />
      </Typography>
      <List disablePadding>
        {links.map(({ id, name, icon, onClick }) => (
          <ListItem key={id} dense disablePadding>
            <ListItemButton
              data-testid={`${id}-help-link-button`}
              sx={styles.listItemButton}
              onClick={onClick}
            >
              <SpotIcon icon={icon} sx={styles.icon} />
              <ListItemText primary={<Typography variant="body2">{name}</Typography>} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default HelpLinks;
