import { useIntl } from 'react-intl';

import type { ButtonProps } from '@mui/material';
import { faTrash } from '@trustyou/fortawesome/pro-regular-svg-icons';

import { messages } from './messages';

import useDeletionPermission from '../../hooks/permissions/use-deletion-permission';
import { useStore } from '../../store/store';
import type { ReviewRoot } from '../../types';
import { IconButtonWithTooltip } from '../icon-button-with-tooltip';

type TrashIconButtonProps = {
  onClick: ButtonProps['onClick'];
};

export function TrashIconButton({ onClick }: TrashIconButtonProps) {
  const intl = useIntl();
  const hasDeletionPermission = useDeletionPermission();
  const { can_be_deleted, deletion } = useStore.use.reviewRoot() as ReviewRoot;

  const isDeletionProcessStarted = ['requested', 'pending'].includes(deletion?.status ?? '');
  const isDeletionProcessFinished = ['confirmed'].includes(deletion?.status ?? '');

  const isDisabled =
    !hasDeletionPermission ||
    !can_be_deleted ||
    isDeletionProcessStarted ||
    isDeletionProcessFinished;

  return (
    <IconButtonWithTooltip
      data-gtm-class="review_action_on_review"
      data-gtm-id="delete"
      color="primary"
      onClick={onClick}
      icon={faTrash}
      disabled={isDisabled}
      size="small"
      tooltip={intl.formatMessage(messages.markAsDeleted)}
    />
  );
}
