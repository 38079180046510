import { useMutation } from '@tanstack/react-query';
import { $assApi, ANALYTICS_BASE_PATH } from '@trustyou/shared';
import type { AxiosError } from 'axios';

import { IMPACT_SCORE } from '../../constants/query-keys';
import type { ImpactScoreRequest, ImpactScoreResponse } from '../../types';

export const fetchImpactScore = async (
  request: ImpactScoreRequest
): Promise<ImpactScoreResponse> => {
  const { data } = await $assApi.post(`${ANALYTICS_BASE_PATH}/impact_score/calculate`, request);
  return data;
};

export const useImpactScore = (
  onSuccess: (data: ImpactScoreResponse) => void,
  onError?: (error: AxiosError<unknown>) => void
) => {
  return useMutation({
    mutationFn: fetchImpactScore,
    mutationKey: [IMPACT_SCORE],
    onSuccess,
    onError,
  });
};
