import { FormattedMessage, useIntl } from 'react-intl';

import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Typography } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import {
  faCheckDouble,
  faClock,
  faEnvelope,
  faEnvelopeOpen,
  faFlag,
  faTrash,
  faTrashCheck,
} from '@trustyou/fortawesome/pro-regular-svg-icons';
import { faFlag as faFlagSolid } from '@trustyou/fortawesome/pro-solid-svg-icons';
import { useEntityCount } from '@trustyou/shared';
import { StyledFontAwesomeIcon, styled } from '@trustyou/ui';

import { useTranslatedText } from './use-translated-text';

import { IconWithTooltip } from '../components/icon-with-tooltip';
import { CustomTooltip } from '../components/reviews-data-grid/custom-tooltip';
import { SearchTermsHighlighter } from '../components/reviews-data-grid/search-terms-highlighter';
import { ScoreChip } from '../components/score-chip';

// Normally, DataGrid should automatically truncate text, but may not work due to the search terms highlighter.
const TruncatedText = styled('span')(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

function getStatusIcon(status: string) {
  const icons: Record<string, IconProp> = {
    read: faEnvelopeOpen,
    unread: faEnvelope,
    responded: faClock,
    confirmed: faCheckDouble,
    marked_as_deleted: faTrash,
    deleted: faTrashCheck,
    marked_as_inappropriate: faFlag,
    inappropriate: faFlagSolid,
  };

  return icons[status];
}

export function useInboxColumns() {
  const intl = useIntl();
  const { getTranslatedText } = useTranslatedText();
  const { data: totalEntityCount = 0 } = useEntityCount();

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
    },
    {
      field: 'score',
      headerName: intl.formatMessage({ id: 'inbox.header.score', defaultMessage: 'Score' }),
      align: 'center',
      maxWidth: 80,
      headerAlign: 'center',
      renderCell: ({ value }) => <ScoreChip dataTestId="score" value={value} />,
    },
    {
      field: 'author',
      headerName: intl.formatMessage({ id: 'inbox.header.author', defaultMessage: 'Author' }),
      sortable: false,
      flex: 2,
      renderCell: ({ value }) => (
        <TruncatedText>
          <SearchTermsHighlighter value={value} />
        </TruncatedText>
      ),
    },
    {
      field: 'text',
      headerName: intl.formatMessage({ id: 'inbox.header.text', defaultMessage: 'Text' }),
      sortable: false,
      flex: 8,
      renderCell: ({ row, value }) => (
        <>
          {['requested', 'marked_as_inappropriate', 'inappropriate'].includes(row.status) && (
            <StyledFontAwesomeIcon
              icon={getStatusIcon(row.status)}
              sx={{ color: 'error.main', marginInlineEnd: 1.5 }}
            />
          )}
          <TruncatedText>
            {value ? (
              <SearchTermsHighlighter value={value} />
            ) : (
              <Typography variant="caption" color="text.secondary">
                <FormattedMessage
                  defaultMessage="Review without text"
                  id="inbox.reviewWithoutText"
                />
              </Typography>
            )}
          </TruncatedText>
        </>
      ),
    },
    {
      field: 'source',
      headerName: intl.formatMessage({ id: 'inbox.header.source', defaultMessage: 'Source' }),
      sortable: false,
      renderCell: ({ value }) => <TruncatedText>{value}</TruncatedText>,
    },
    {
      field: 'status',
      headerName: intl.formatMessage({ id: 'inbox.header.status', defaultMessage: 'Status' }),
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ value }) => (
        <IconWithTooltip
          icon={getStatusIcon(value)}
          tooltip={getTranslatedText(value)}
          IconProps={{ sx: { color: (theme) => theme.palette.action.active } }}
        />
      ),
    },
    {
      field: 'date',
      headerName: intl.formatMessage({ id: 'inbox.header.date', defaultMessage: 'Date' }),
      renderCell: ({ value }) => <SearchTermsHighlighter value={value} />,
    },
  ];

  if (totalEntityCount > 1) {
    columns.push({
      field: 'entity',
      headerName: intl.formatMessage({ id: 'inbox.header.entity', defaultMessage: 'Entity' }),
      sortable: false,
      flex: 3,
      renderCell: ({ value }) => (
        <CustomTooltip sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} title={value}>
          <SearchTermsHighlighter value={value} />
        </CustomTooltip>
      ),
    });
  }
  return columns;
}
