import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  orgIcon: {
    color: 'rgba(0, 0, 0, 0.56)',
  },
  roleChip: {
    marginLeft: 'auto',
  },
  list: {
    paddingY: 0,
  },
  divider: {
    marginTop: 2,
    marginBottom: 2,
  },
  orgLogoTitleWrapper: {
    py: 1,
    px: 2,
    alignItems: 'center',
    flexDirection: 'row',
    gap: 2,
  },
  orgLogoTitleMinHeight: {
    minHeight: '64px',
  },
  orgLogo: {
    maxHeight: '48px',
    maxWidth: '160px',
  },
  listItem: {
    display: 'flex',
    paddingX: 2,
    paddingY: 1,
    gap: 2,
    cursor: 'pointer',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'action.hover',
    },
  },
  listItemSelected: {
    backgroundColor: 'rgba(0, 87, 178, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 87, 178, 0.08)',
    },
  },
  listItemInner: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 0,
  },
  plan: {
    color: 'text.secondary',
  },
};

export default styles;
