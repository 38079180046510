import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  type ChangelingUserInfo,
  type ImpersonatePayload,
  type OrganizationWithMemberships,
  USER_ROLES,
  base64UrlToObj,
  useChangelingStore,
  useMembershipStore,
} from '@trustyou/shared';

const useInitChangeling = () => {
  const [isCompleted, setIsCompleted] = useState(false);
  const [searchParams] = useSearchParams();
  const { setIsChangeling, setIsReadyToAuthToAdmin, setUserInfo } = useChangelingStore();
  const { setMembership, setOrganizations } = useMembershipStore();

  useEffect(() => {
    // I check the address for query params
    const changelingTokenQueryParam = searchParams.get('changeling');
    const changelingAuthQueryParam = searchParams.get('changeling-auth');

    // const tokenStoreString = localStorage.getItem('tokenStore');
    // if (tokenStoreString) {
    //   const tokens = JSON.parse(tokenStoreString as string);
    //   setTokenStore(tokens);
    // }

    if (changelingTokenQueryParam) {
      // base64 decode of changeling token
      const changelingPayload = base64UrlToObj(changelingTokenQueryParam) as ImpersonatePayload;
      const changelingOrganization: OrganizationWithMemberships = {
        id: changelingPayload.organization_id,
        name: changelingPayload.organization_name,
        memberships: [
          {
            id: changelingPayload.membership_id,
            role: changelingPayload.role,
            subscription: {
              id: changelingPayload.subscription_id,
              name: changelingPayload.subscription_name || changelingPayload.organization_name,
              plan: changelingPayload.plan,
            },
            scope: changelingPayload.scope_entities
              ? {
                  entities: changelingPayload.scope_entities,
                }
              : undefined,
          },
        ],
      };
      const changelingUserInfo: ChangelingUserInfo = {
        name: changelingPayload.user_name,
        email: changelingPayload.user_email,
        isOwner: changelingPayload.role === USER_ROLES.OWNER,
      };
      setMembership({
        id: changelingPayload.membership_id,
        organizationId: changelingPayload.organization_id,
        subscriptionId: changelingPayload.subscription_id,
      });
      setOrganizations([changelingOrganization]);
      setUserInfo(changelingUserInfo);
      setIsChangeling(true);
    }

    if (changelingAuthQueryParam === 'ready') {
      setIsReadyToAuthToAdmin(true);
    }

    setIsCompleted(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isCompleted };
};

export default useInitChangeling;
