import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { PaginationRequest } from '@trustyou/shared';

import type {
  BookingConnectionRequestCredentials,
  BookingConnectionResponse,
  GoogleConnectionRequestCredentials,
  GoogleConnectionResponse,
} from '../../../../types/model/reviewProvider';
import {
  CREATE_BOOKING_CONNECTION,
  CREATE_GOOGLE_CONNECTION,
  DELETE_PROVIDER_ACCOUNT,
  DISCONNECT_PROVIDER,
  FETCH_GOOGLE_AUTH_URL,
  FETCH_PROVIDER_ACCOUNTS,
  FETCH_REVIEW_PROVIDERS,
  FETCH_REVIEW_PROVIDER_DETAILS,
} from '../../../constants';
import {
  createBookingConnection,
  createGoogleConnection,
  deleteProviderAccount,
  disconnectProvider,
  fetchGoogleAuthUrl,
  fetchProviderAccounts,
  fetchReviewProviderDetails,
  fetchReviewProviders,
} from '../../../queries/domains/ass/reviewProvider';

export function useReviewProviders() {
  return useQuery({
    queryKey: [FETCH_REVIEW_PROVIDERS],
    queryFn: fetchReviewProviders,
    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useReviewProviderDetails(reviewProviderId: string, pagination: PaginationRequest) {
  return useQuery({
    queryKey: [FETCH_REVIEW_PROVIDER_DETAILS, { reviewProviderId, pagination }],
    queryFn: () => fetchReviewProviderDetails(reviewProviderId, pagination),
    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useCreateBookingConnection(
  onSuccess: (data: BookingConnectionResponse) => void,
  onError?: (error: Error) => void,
  onSettled?: () => void
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (credentials: BookingConnectionRequestCredentials) =>
      createBookingConnection(credentials),
    mutationKey: [CREATE_BOOKING_CONNECTION],
    onSuccess: (data: BookingConnectionResponse) => {
      queryClient.refetchQueries({ queryKey: [FETCH_REVIEW_PROVIDERS] });
      queryClient.refetchQueries({ queryKey: [FETCH_PROVIDER_ACCOUNTS] });
      queryClient.setQueryData([CREATE_BOOKING_CONNECTION], 'booking-success');
      onSuccess(data);
    },
    onError: (error: Error) => {
      queryClient.setQueryData([CREATE_BOOKING_CONNECTION], 'booking-failed');
      onError && onError(error);
    },
    onSettled,
  });
}

export function useGoogleAuthUrl(redirect_url: string, enabled: boolean) {
  return useQuery({
    queryKey: [FETCH_GOOGLE_AUTH_URL, { redirect_url }],
    queryFn: () => fetchGoogleAuthUrl(redirect_url),
    refetchOnWindowFocus: false,
    enabled,
  });
}

export function useCreateGoogleConnection(
  onSuccess: (data: GoogleConnectionResponse) => void,
  onError?: (error: Error) => void,
  onSettled?: () => void
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (credentials: GoogleConnectionRequestCredentials) =>
      createGoogleConnection(credentials),
    mutationKey: [CREATE_GOOGLE_CONNECTION],
    onSuccess: (data: GoogleConnectionResponse) => {
      queryClient.refetchQueries({ queryKey: [FETCH_REVIEW_PROVIDERS] });
      queryClient.refetchQueries({ queryKey: [FETCH_PROVIDER_ACCOUNTS] });
      onSuccess(data);
    },
    onError,
    onSettled,
  });
}

export function useDeleteProviderAccount(
  onSuccess: (data: string) => void,
  onError?: (error: Error) => void,
  onSettled?: () => void
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (providerAccountId: string) => deleteProviderAccount(providerAccountId),
    mutationKey: [DELETE_PROVIDER_ACCOUNT],
    onSuccess: (data: string) => {
      queryClient.refetchQueries({ queryKey: [FETCH_REVIEW_PROVIDERS] });
      queryClient.refetchQueries({ queryKey: [FETCH_PROVIDER_ACCOUNTS] });
      onSuccess(data);
    },
    onError,
    onSettled,
  });
}

export function useDisconnectProvider(
  onSuccess: (data: string) => void,
  onError?: (error: Error) => void,
  onSettled?: () => void
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (reviewProviderId: string) => disconnectProvider(reviewProviderId),
    mutationKey: [DISCONNECT_PROVIDER],
    onSuccess: (data) => {
      queryClient.refetchQueries({ queryKey: [FETCH_REVIEW_PROVIDERS] });
      onSuccess(data);
    },
    onError,
    onSettled,
  });
}

export function useProviderAccounts(reviewProviderId: string) {
  return useQuery({
    queryKey: [FETCH_PROVIDER_ACCOUNTS, { reviewProviderId }],
    queryFn: () => fetchProviderAccounts(reviewProviderId),
    refetchOnWindowFocus: false,
    enabled: true,
  });
}
