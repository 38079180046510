import { Controller, FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useGetEmailNotification, useSetEmailNotification } from '@trustyou/inbox';
import { commonFields, useAlertStore } from '@trustyou/shared';
import {
  Button,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  Stack,
  Switch,
  Typography,
} from '@trustyou/ui';

import { EntitySelectorForEmailNotifications } from './entity-selector-for-email-notifications';
import { KeywordsTextarea, ScoreSelect, SourceSelect } from './form-fields';

import type { EmailScheduleType, ViewScoreEnum, Weekday } from '../../../client';
import useGetSettingsPermission from '../../../hooks/permissions/use-get-settings-permission';
import useModerationPermission from '../../../hooks/permissions/use-moderation-permission';
import useSetSettingsPermission from '../../../hooks/permissions/use-set-settings-permission';
import { getSearchTerms, parseSearchTerms } from '../../../utils/keyword-search';

type Filters = {
  score: ViewScoreEnum[];
  source: string[];
  search_terms: string;
  entity_ids: string[];
};

export type FormType = {
  daily_email_enabled: boolean;
  schedule_type: EmailScheduleType;
  schedule_hour?: number;
  schedule_day?: Weekday;
  filters: Filters;
  survey_moderation_email_enabled?: boolean;
};

const DAILY_DEFAULT_VALUES: FormType = {
  daily_email_enabled: true,
  schedule_type: 'daily',
  schedule_hour: 2,
  filters: {
    score: ['no_score', 'low', 'medium', 'high'],
    source: ['all'],
    search_terms: '',
    entity_ids: [],
  },
  survey_moderation_email_enabled: true,
};

export function EmailSettingsForm() {
  const intl = useIntl();
  const { alert } = useAlertStore();
  const saveEmailNotifications = useSetEmailNotification();
  const isAllowedToGetSettings = useGetSettingsPermission();
  const isAllowedToSetSettings = useSetSettingsPermission();
  const hasModerationPermission = useModerationPermission();

  const { data: emailSettings, isPending } = useGetEmailNotification();
  const dailyEmailSettings = emailSettings?.find(({ schedule_type }) => schedule_type === 'daily');
  const dailyDefaultValues: FormType = {
    daily_email_enabled:
      (dailyEmailSettings?.enabled ?? DAILY_DEFAULT_VALUES.daily_email_enabled) &&
      (dailyEmailSettings?.filters?.entity_ids ?? [])?.length > 0,
    schedule_type: dailyEmailSettings?.schedule_type ?? DAILY_DEFAULT_VALUES.schedule_type,
    schedule_hour: dailyEmailSettings?.schedule_hour ?? DAILY_DEFAULT_VALUES.schedule_hour,
    filters: {
      score: dailyEmailSettings?.filters?.score ?? DAILY_DEFAULT_VALUES.filters.score,
      source: dailyEmailSettings?.filters?.source ?? DAILY_DEFAULT_VALUES.filters.source,
      search_terms: parseSearchTerms(dailyEmailSettings?.filters?.search_terms ?? []),
      entity_ids: dailyEmailSettings?.filters?.entity_ids ?? [],
    },
    survey_moderation_email_enabled: true,
  };
  const methods = useForm({
    values: dailyDefaultValues,
    resetOptions: {
      // Keep dirty fields unchanged, but update defaultValues.
      keepDirtyValues: true,
    },
  });
  const { handleSubmit, control, watch } = methods;
  const isDailyEmailEnabled = watch('daily_email_enabled');

  const onSubmit = (form: FormType) => {
    if (form.daily_email_enabled && !form.filters.entity_ids.length) {
      alert.error(
        intl.formatMessage({
          id: 'inbox.settings.notifications.entities.select-at-least-one-entity',
          defaultMessage: 'Select at least one entity',
        })
      );
      return;
    }
    if (!isAllowedToSetSettings) {
      alert.warning('Permissions are needed to change Inbox settings');
      return;
    }
    const payload = {
      enabled: form.daily_email_enabled,
      ...form,
      filters: {
        ...form.filters,
        search_terms: getSearchTerms(form.filters.search_terms),
      },
      // TODO:
      // survey_moderation_email_enabled: form.survey_moderation_email_enabled,
    };
    saveEmailNotifications.mutate(payload, {
      onSuccess: () => {
        alert.success(intl.formatMessage(commonFields.changesSaved));
      },
      onError: () => {
        alert.genericError();
      },
    });
  };

  if (!isAllowedToGetSettings) {
    alert.warning('Permissions are needed to see Inbox settings');
    return;
  }

  if (isPending) return <LinearProgress />;

  return (
    <FormProvider {...methods}>
      <Typography variant="h6" sx={{ marginBlockEnd: 2 }}>
        <FormattedMessage
          id="inbox.settings.notifications.title"
          defaultMessage="Inbox notifications"
        />
      </Typography>
      <Stack component="form" onSubmit={handleSubmit(onSubmit)}>
        <FormGroup
          sx={{
            gap: 2,
            ...(isDailyEmailEnabled && { paddingBlockEnd: 3 }),
            alignItems: 'start',
            maxWidth: (theme) => theme.spacing(63),
          }}
        >
          <Controller
            control={control}
            name="daily_email_enabled"
            render={({ field }) => (
              <FormControlLabel
                control={<Switch {...field} checked={field.value} />}
                label={intl.formatMessage({
                  id: 'inbox.settings.notifications.daily.label',
                  defaultMessage: 'Receive a daily email summary of new reviews',
                })}
              />
            )}
          />
          <Stack
            spacing={3}
            sx={{
              marginLeft: 6,
              ...(!isDailyEmailEnabled && { height: 0, visibility: 'hidden', overflow: 'hidden' }),
            }}
          >
            <EntitySelectorForEmailNotifications />
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
              <ScoreSelect />
              <SourceSelect />
            </Stack>
            <Stack spacing={2}>
              <Typography variant="body2" sx={{ paddingTop: 1 }}>
                <FormattedMessage
                  id="inbox.settings.notifications.keywords-statement"
                  defaultMessage="Include only reviews that contain the following keywords:"
                />
              </Typography>
              <KeywordsTextarea />
            </Stack>
          </Stack>
        </FormGroup>
        {hasModerationPermission && (
          <FormGroup>
            <Controller
              control={control}
              name="survey_moderation_email_enabled"
              render={({ field }) => (
                <FormControlLabel
                  control={<Switch {...field} checked={field.value} />}
                  label={intl.formatMessage({
                    id: 'inbox.settings.notifications.survey-moderation.label',
                    defaultMessage: 'Receive survey review moderation requests via email',
                  })}
                />
              )}
            />
          </FormGroup>
        )}
        <Button
          type="submit"
          variant="contained"
          data-testid="save-changes-inbox-notifications"
          sx={{ alignSelf: 'end', marginBlockStart: 5 }}
        >
          <FormattedMessage {...commonFields.saveChanges} />
        </Button>
      </Stack>
    </FormProvider>
  );
}
