/**
 * Documentation references to implement "manual" server-side:
 *    https://mui.com/x/react-data-grid/filtering/server-side/
 *    https://mui.com/x/react-data-grid/pagination/#server-side-pagination
 *    https://mui.com/x/react-data-grid/sorting/#server-side-sorting
 *
 * Server-side data support by MUI-X (PLANNED):
 *    https://mui.com/x/react-data-grid/server-side-data/
 *    https://github.com/mui/mui-x/issues/8179
 *
 * Persist DataGrid state:
 *    https://mui.com/x/react-data-grid/state/#save-and-restore-the-state-from-external-storage
 */
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { alpha, styled } from '@mui/material';
import {
  DataGridPro,
  type GridEventListener,
  type GridFilterModel,
  type GridPaginationModel,
  type GridRowId,
  type GridRowModel,
  type GridRowParams,
  type GridRowSelectionModel,
  type GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import {
  DEFAULT_INBOX_PAGE_SIZE_OPTIONS,
  useBasicEntities,
  useDataGridIntl,
  useEntityCount,
  useInboxStore,
  useLanguageStore,
} from '@trustyou/shared';

import { CustomColumnMenu } from './custom-column-menu';
import { CustomNoRowsOverlay } from './custom-no-rows-overlay';
import { CustomToolbar } from './custom-toolbar';
import { CustomTooltipLink } from './custom-tooltip-link';

import type { AllowedSortField, ReviewSchema, ReviewView, SortOrder } from '../../client';
import { NON_SELECTABLE_REVIEW_STATUSES } from '../../constants';
import { useFetchPaginatedReviews } from '../../hooks';
import { useInboxColumns } from '../../hooks/use-inbox-columns';
import { useStore } from '../../store/store';
import type { PrePayload } from '../../types';
import { getReviewListDate } from '../../utils/date';

// Copied from `node_modules/@mui/x-data-grid-generator/hooks/useQuery.d.ts`.
export interface QueryOptions {
  cursor?: GridRowId;
  page?: number;
  pageSize?: number;
  filterModel?: GridFilterModel;
  sortModel?: GridSortModel;
  firstRowToRender?: number;
  lastRowToRender?: number;
}

const StyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
  borderInline: 'none',
  borderBottom: 'none',
  borderRadius: 0,

  // row styles based on review status
  '& .inbox-app-theme--unread': {
    fontWeight: 700,
  },
  '& .inbox-app-theme--read': {
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
  },
  '& .inbox-app-theme--responded': {
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
  },
  '& .inbox-app-theme--confirmed': {
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
  },
  '& .inbox-app-theme--marked_as_deleted': {
    opacity: 0.3,
  },
  '& .inbox-app-theme--deleted': {
    opacity: 0.3,
  },
  '& .inbox-app-theme--marked_as_inappropriate': {
    opacity: 1,
  },
  '& .inbox-app-theme--inappropriate': {
    opacity: 0.3,
  },

  // hover styles
  '& .MuiDataGrid-row': {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.3),
      },
    },
  },
}));

function getFormattedReviewTitleAndText(review: ReviewSchema) {
  if (review.title) return review.title?.concat(review.text ? ' | ' + review.text : '');
  return review.text;
}

export function useReviewsData(
  queryOptions: QueryOptions,
  filters: ReviewView
): {
  rows: GridRowModel[];
  filteredTotal?: number;
  isLoading: boolean;
  isRefetching: boolean;
  refetch: () => void;
} {
  const updateReviewsList = useStore.use.updateReviewsList();
  const updateRestrictedScope = useStore.use.updateRestrictedScope();
  const { data: totalEntityCount = 0 } = useEntityCount();
  const prePayload: PrePayload = {
    page: queryOptions.page ?? 0,
    pageSize: queryOptions.pageSize ?? 50,
    sort: {
      field: queryOptions.sortModel?.[0]?.field as AllowedSortField,
      order: queryOptions.sortModel?.[0]?.sort as SortOrder,
    },
    filters,
  };
  const {
    data: paginatedReviewsData,
    isPending: isLoading,
    isRefetching,
    refetch,
  } = useFetchPaginatedReviews(prePayload);
  const { data: entityData } = useBasicEntities(
    paginatedReviewsData?.data.map((item) => item.meta.entity_id) ?? []
  );

  if (!paginatedReviewsData) {
    updateReviewsList([]);
    return { rows: [], filteredTotal: undefined, isLoading: true, isRefetching: true, refetch };
  }

  updateReviewsList(paginatedReviewsData.data.map((item) => item.review));

  // The `meta.restricted_scope` is an array of entity ids that the backend exposes when the inbox has been implicitly filtered by the LIMIT (25) of permitted entities at the same time.
  // This is a solution to prevent performance issues and long loading times for organizations with a lot of entities.
  updateRestrictedScope(paginatedReviewsData.meta.restricted_scope);

  return {
    rows: paginatedReviewsData.data.map((item) => {
      const entityName = entityData?.find(({ id }) => id === item.meta.entity_id)?.name;
      return {
        id: item.id,
        score: item.review.score,
        author: item.review.author,
        text: getFormattedReviewTitleAndText(item.review),
        source: (
          <CustomTooltipLink href={item.review.url ?? ''} label={item.review.domain_name ?? ''} />
        ),
        status: item.meta.status,
        date: getReviewListDate(item.review.date),
        ...(entityName && totalEntityCount > 1
          ? {
              entity: entityName,
            }
          : {}),
      };
    }),
    filteredTotal: paginatedReviewsData.meta.filtered_total ?? undefined,
    isLoading,
    isRefetching,
    refetch,
  };
}

export function ReviewsDataGrid() {
  const apiRef = useGridApiRef();

  const columns = useInboxColumns();
  const dataGridIntl = useDataGridIntl();
  const { locale } = useLanguageStore();
  const navigate = useNavigate();

  const { dataGrid, setDataGrid, currentView } = useInboxStore();

  const queryOptions: QueryOptions = {
    sortModel: dataGrid.sorting?.sortModel,
    page: dataGrid.pagination?.paginationModel?.page,
    pageSize: dataGrid.pagination?.paginationModel?.pageSize,
  };
  const {
    isLoading,
    rows,
    filteredTotal: rowCount,
    isRefetching,
  } = useReviewsData(queryOptions, currentView.filters ?? {});

  // https://mui.com/x/react-data-grid/pagination/#basic-implementation
  const [rowCountState, setRowCountState] = useState(rowCount || 0);
  useEffect(() => {
    setRowCountState((previous) => (rowCount !== undefined ? rowCount : previous));
  }, [rowCount]);

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  const handleFilter = useCallback(
    (filterModel: GridFilterModel) => {
      setDataGrid({ ...dataGrid, filter: { filterModel } });
    },
    [dataGrid, setDataGrid]
  );

  const handlePagination = useCallback(
    (paginationModel: GridPaginationModel) => {
      setDataGrid({ ...dataGrid, pagination: { paginationModel } });
    },
    [dataGrid, setDataGrid]
  );

  const handleSort = useCallback(
    (sortModel: GridSortModel) => {
      setDataGrid({
        ...dataGrid,
        sorting: {
          sortModel,
        },
        // reset to the first page to visually get clear results after re-sorting
        pagination: {
          paginationModel: {
            pageSize: dataGrid.pagination?.paginationModel?.pageSize,
            page: 0,
          },
        },
      });
    },
    [dataGrid, setDataGrid]
  );

  const handleSelection = useCallback((rowSelectionModel: GridRowSelectionModel) => {
    setRowSelectionModel(rowSelectionModel);
  }, []);

  const navigateToReview: GridEventListener<'rowClick'> = (params) => {
    navigate(params.row.id);
  };

  return (
    <StyledDataGridPro
      apiRef={apiRef}
      rows={rows}
      columns={columns}
      rowCount={rowCountState}
      loading={isLoading || isRefetching}
      initialState={dataGrid}
      density="standard"
      // 📊 Filtering
      // not used at the moment because a custom filtering logic for inbox has been implemented instead
      // filtering options are manually hidden from each column header
      // docs for future reference:
      //   - https://mui.com/x/react-data-grid/filtering/
      //   - https://mui.com/x/react-data-grid/filtering-recipes/#quick-filter-outside-of-the-grid
      filterMode="server"
      onFilterModelChange={handleFilter}
      ignoreDiacritics
      // 🔀 Sorting
      sortingMode="server"
      sortingOrder={['asc', 'desc']}
      onSortModelChange={handleSort}
      // 📄 Pagination
      pagination
      pageSizeOptions={DEFAULT_INBOX_PAGE_SIZE_OPTIONS}
      paginationMode="server"
      paginationModel={dataGrid.pagination?.paginationModel as GridPaginationModel}
      onPaginationModelChange={handlePagination}
      // ✅ Selection
      checkboxSelection
      isRowSelectable={({ row }: GridRowParams) =>
        !NON_SELECTABLE_REVIEW_STATUSES.includes(row.status)
      }
      keepNonExistentRowsSelected
      rowSelectionModel={rowSelectionModel}
      onRowSelectionModelChange={handleSelection}
      // 👆🏼 Click/tap on row
      onRowClick={navigateToReview}
      disableRowSelectionOnClick
      // ✨ Customization
      getRowClassName={({ row }) => `inbox-app-theme--${row.status}`}
      localeText={dataGridIntl[locale]?.components.MuiDataGrid.defaultProps.localeText}
      slots={{
        noRowsOverlay: CustomNoRowsOverlay,
        toolbar: CustomToolbar,
        columnMenu: CustomColumnMenu,
      }}
      slotProps={{
        toolbar: {
          selectedRows: rowSelectionModel,
          resetSelectedRows: () => setRowSelectionModel([]),
        },
      }}
    />
  );
}
