import type { ReactNode } from 'react';

import { Box, CircularProgress, Typography } from '@trustyou/ui';

import styles from './LoadingPlaceholder.styles';

import type { StyleUnit } from '../../types/common';

export interface LoadingPlaceholderProps {
  img?: string;
  title?: ReactNode;
  description?: ReactNode;
  children?: ReactNode;
  loaderContainer?: StyleUnit;
  loaderHeader?: StyleUnit;
  loaderBody?: StyleUnit;
  loaderIcon?: StyleUnit;
}

export const LoadingPlaceholder = ({
  img,
  title,
  description,
  children,
  loaderContainer,
  loaderHeader,
  loaderBody,
  loaderIcon,
}: LoadingPlaceholderProps) => (
  <Box sx={{ ...styles.basicLoaderContainer, ...loaderContainer }}>
    {img && <img src={img} alt="Uploading" />}
    {title && (
      <Typography variant="h6" sx={loaderHeader}>
        {title}
      </Typography>
    )}
    {description && (
      <Typography variant="body2" sx={loaderBody}>
        {description}
      </Typography>
    )}
    <CircularProgress sx={loaderIcon} />
    {children}
  </Box>
);
