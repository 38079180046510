import { create } from 'zustand';

import type { CentralizedMockData } from '../service/utils';

type MockSet = {
  query: CentralizedMockData;
  mutation: CentralizedMockData;
};

type MockState = {
  testMockData: CentralizedMockData;
  testMutationMock: CentralizedMockData;
  storybookMockData: CentralizedMockData;
  storybookMutationMock: CentralizedMockData;
  nonProductionMockData: CentralizedMockData;
  nonProductionMutationMock: CentralizedMockData;
  setTestMockData: (data: MockSet) => void;
  setStorybookMockData: (data: MockSet) => void;
  setNonProductionMockData: (data: MockSet) => void;
};

export const useMockStore = create<MockState>((set) => ({
  testMockData: {},
  testMutationMock: {},
  storybookMockData: {},
  storybookMutationMock: {},
  nonProductionMockData: {},
  nonProductionMutationMock: {},
  setTestMockData: (data) => set({ testMockData: data.query, testMutationMock: data.mutation }),
  setStorybookMockData: (data) =>
    set({ storybookMockData: data.query, storybookMutationMock: data.mutation }),
  setNonProductionMockData: (data) =>
    set({ nonProductionMockData: data.query, nonProductionMutationMock: data.mutation }),
}));
