import { useState } from 'react';
import { defineMessages } from 'react-intl';

import type { PreviewEntityData } from '@trustyou/shared';
import { Box, SetupStepper } from '@trustyou/ui';

import { IntroductionView, IntroductionViewAdditionalEntities } from './IntroductionView';
import { PreviewEntities } from './PreviewEntities';
import UploadSpreadsheet from './UploadSpreadsheet';

import styles from './styles';

const steps = defineMessages({
  1: {
    id: 'entities.importer.download.spreadsheet',
    defaultMessage: 'Download and fill in spreadsheet',
  },
  2: {
    id: 'entities.importer.upload.spreadsheet',
    defaultMessage: 'Upload spreadsheet',
  },
  3: {
    id: 'entities.importer.preview.data',
    defaultMessage: 'Preview data and complete',
  },
});

type DataLoaderProps = {
  additionalEntities?: boolean;
  onClose?: () => void;
};

export const EntitiesImporter = ({ additionalEntities, onClose }: DataLoaderProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const [previewEntityData, setPreviewEntityData] = useState<PreviewEntityData>();

  const onClickNextHandler = () => {
    setActiveStep((step) => step + 1);
  };

  const onPreviewEntities = (data: PreviewEntityData) => {
    setPreviewEntityData(data);
    onClickNextHandler();
  };

  const onClickBackHandler = () => {
    setPreviewEntityData(undefined);
    setActiveStep((step) => step - 1);
  };

  return (
    <>
      <SetupStepper steps={steps} activeStep={activeStep} />
      <Box sx={styles.stepperBody}>
        {activeStep === 0 && !additionalEntities && (
          <IntroductionView onClickNext={onClickNextHandler} />
        )}
        {activeStep === 0 && additionalEntities && onClose && (
          <IntroductionViewAdditionalEntities
            onClickBack={onClose}
            onClickNext={onClickNextHandler}
          />
        )}
        {activeStep === 1 && (
          <UploadSpreadsheet onClickBack={onClickBackHandler} onSuccess={onPreviewEntities} />
        )}
        {activeStep === 2 && previewEntityData && (
          <PreviewEntities
            previewEntityData={previewEntityData}
            additionalEntities={additionalEntities}
            onClickBack={onClickBackHandler}
            onClose={onClose}
          />
        )}
      </Box>
    </>
  );
};
