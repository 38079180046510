import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  container: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    mt: 1,
  },
};

export default styles;
