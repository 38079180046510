import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
  },
};

export default styles;
