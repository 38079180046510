import type { QueryClient } from '@tanstack/react-query';

export type CentralizedMockData = {
  [key: string]: object;
};

export const setCentralizedMockData = (queryClient: QueryClient, data: CentralizedMockData) => {
  // @ts-expect-error Argument of type 'object' is not assignable to parameter of type 'Updater<void | undefined, void | undefined>'.
  Object.entries(data).forEach(([key, value]) => queryClient.setQueryData([key], value));
};
