import type { ReactNode } from 'react';

import { Stack } from '@trustyou/ui';

type EditorContentProps = {
  sideNavigator: ReactNode;
  content: ReactNode;
  contentExtraStyles?: Record<string, unknown>;
  preview?: ReactNode;
  previewExtraStyles?: Record<string, unknown>;
};

export const EditorContent = ({
  sideNavigator,
  content,
  contentExtraStyles,
  preview,
  previewExtraStyles,
}: EditorContentProps) => {
  return (
    <>
      <Stack
        sx={{
          gridColumn: '1',
          gridRow: '2 / 4',
          backgroundColor: 'primary.main',
        }}
      >
        {sideNavigator}
      </Stack>
      <Stack
        sx={{
          gridColumn: '2',
          gridRow: '2',
          padding: 3,
          overflowY: 'auto',
          ...contentExtraStyles,
        }}
      >
        {content}
      </Stack>
      <Stack
        sx={{
          gridColumn: '3',
          gridRow: '2 / 4',
          backgroundColor: (theme) => theme.palette.background.default,
          ...previewExtraStyles,
        }}
      >
        {preview}
      </Stack>
    </>
  );
};
