import type { ReactNode } from 'react';
import { type DropzoneOptions, type FileRejection, useDropzone } from 'react-dropzone';

import { Box, Button, FormControl } from '@trustyou/ui';

import styles from './styles';

export interface FileUploadProps extends Omit<DropzoneOptions, 'onDrop' | 'onDropAccepted'> {
  onChange: (files: File[]) => void;
  onUploadError: (errors: string[]) => void;
  children?: ReactNode;
  buttonContent: ReactNode;
}

export const FileUploader = ({
  onChange,
  onUploadError,
  maxSize,
  children,
  buttonContent,
  ...options
}: FileUploadProps) => {
  const onDropRejected = (fileRejections: FileRejection[]) => {
    const errorCodes = fileRejections.map((row) => row.errors.map((error) => error.code)).flat(1);
    onUploadError(errorCodes);
  };
  const { getRootProps, getInputProps, open } = useDropzone({
    ...options,
    maxSize,
    onDropAccepted: onChange,
    noClick: true,
    noKeyboard: true,
    onDropRejected,
  });

  return (
    <Box {...getRootProps()} sx={styles.container}>
      <FormControl sx={styles.formController}>
        <input {...getInputProps()} />
        {children}
        <Button
          data-testid="open-file-to-upload"
          variant="contained"
          onClick={open}
          sx={styles.uploadButton}
        >
          {buttonContent}
        </Button>
      </FormControl>
    </Box>
  );
};
