import { $assApi, BENCHMARKS_BASE_PATH, type PaginationRequest } from '@trustyou/shared';

import type { BenchmarkEntitiesResponse, BenchmarksResponse } from '../../../../types/model';

export async function fetchBenchmarks(
  { limit, offset }: PaginationRequest,
  sortKey: string
): Promise<BenchmarksResponse> {
  const url = `${BENCHMARKS_BASE_PATH}/get_benchmarks`;
  const { data } = await $assApi.get(url, {
    params: {
      limit,
      offset,
      order: sortKey,
    },
  });
  return data;
}

export async function fetchBenchmarkOwnedEntities(
  benchmarkId: string,
  { limit, offset }: PaginationRequest,
  sortKey: string
): Promise<BenchmarkEntitiesResponse> {
  const { data } = await $assApi.get(`${BENCHMARKS_BASE_PATH}/get_benchmark_owned_entities`, {
    params: {
      benchmark_id: benchmarkId,
      limit,
      offset,
      order: sortKey,
    },
  });
  return data;
}

export async function fetchBenchmarkCompetitorEntities(
  benchmarkId: string,
  { limit, offset }: PaginationRequest,
  sortKey: string
): Promise<BenchmarkEntitiesResponse> {
  const { data } = await $assApi.get(`${BENCHMARKS_BASE_PATH}/get_benchmark_competitor_entities`, {
    params: {
      benchmark_id: benchmarkId,
      limit,
      offset,
      order: sortKey,
    },
  });
  return data;
}
