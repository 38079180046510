import { FormattedMessage } from 'react-intl';

import { faPlus } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  EmptyStatePlaceholder,
  StyledFontAwesomeIcon as Icon,
  peopleAnalyzingCharts,
} from '@trustyou/ui';

import styles from './styles';

export type EmptyStateProps = {
  onAddCompetitors?: () => void;
};

export const EmptyState = ({ onAddCompetitors }: EmptyStateProps) => (
  <EmptyStatePlaceholder
    dataName="entity-competitors"
    img={peopleAnalyzingCharts}
    title={<FormattedMessage id="tyDesign.noCompetitorsYet" defaultMessage="No competitors yet" />}
    description={
      <FormattedMessage
        id="tyDesign.addCompetitorsToCompareAgainst"
        defaultMessage="Add competitors to compare against"
      />
    }
    emptyStateHeader={styles.emptyStateHeader}
    emptyStateContainer={styles.emptyStateContainer}
    action={
      onAddCompetitors && {
        testId: 'add-competitors-button',
        message: (
          <>
            <Icon icon={faPlus} sx={styles.addCompetitorIcon} />
            <FormattedMessage id="tyDesign.addCompetitors" defaultMessage="Add competitors" />
          </>
        ),
        handler: onAddCompetitors,
        styles: styles.actionButton,
      }
    }
  />
);
