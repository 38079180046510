import { FormattedMessage } from 'react-intl';

import { ExplanationModal, Typography } from '@trustyou/ui';

import styles from './WhatIsCountryCode.styles';

const ISO_URL = 'https://www.iso.org/obp/ui/#search';

export interface WhatIsCountryCodeProps {
  open: boolean;
  onClose: () => void;
}

export const WhatIsCountryCode = ({ open, onClose }: WhatIsCountryCodeProps) => (
  <ExplanationModal
    open={open}
    onClose={onClose}
    dataName="what-is-country-code"
    title={
      <FormattedMessage
        id="tyDesign.whatIsCountryCodeTitle"
        defaultMessage="What is country code?"
      />
    }
    buttonText={<FormattedMessage id="tyDesign.whatIsSectorButton" defaultMessage="Got it" />}
    points={[]}
  >
    <Typography variant="body2" sx={styles.isoLink}>
      <FormattedMessage
        id="tyDesign.whatIsCountryCodeContent"
        defaultMessage="A country code is a two-letter code assigned to a country. For example, “US” is the ISO 2 country code of United States. Refer to {link}"
        values={{
          link: (
            <a href={ISO_URL} target="_blank" rel="noreferrer">
              <FormattedMessage
                id="tyDesign.fullListOfCountryCodes"
                defaultMessage="the full list of country codes."
              />
            </a>
          ),
        }}
      />
    </Typography>
  </ExplanationModal>
);
