import type { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';

import { Box } from '@trustyou/ui';

import useAlertChangeling from '../../../service/hooks/changeling/useAlertChangeling';
import { TopBar } from '../TopBar/TopBar';
import styles from './styles';

export type TopBarLayoutProps = {
  children?: ReactElement;
};

export const TopBarLayout = ({ children }: TopBarLayoutProps) => {
  const { JSX: changelingAlert } = useAlertChangeling();

  return (
    <Box sx={styles.container}>
      {changelingAlert}
      <TopBar sx={styles.topBar} />
      <Box component="main" sx={styles.main}>
        {children || <Outlet />}
      </Box>
    </Box>
  );
};
