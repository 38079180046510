import { type Control, useWatch } from 'react-hook-form';

import { Stack } from '@trustyou/ui';

import { DayOfMonthFormControl } from './day-of-month-form-control';
import { DayOfWeekFormControl } from './day-of-week-form-control';
import { FrequencyFormControl } from './frequency-form-control';
import { TimeOfDayFormControl } from './time-of-day-form-control';

import styles from './styles';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  direction?: 'row' | 'column';
};

export const FrequencySelector = ({ control, direction }: Props) => {
  const frequency = useWatch({
    name: 'frequency',
    control,
  });
  return (
    <Stack sx={direction === 'row' ? styles.rowContainer : styles.columnContainer}>
      <FrequencyFormControl control={control} />
      {frequency === 'weekly' && <DayOfWeekFormControl control={control} />}
      {frequency === 'monthly' && <DayOfMonthFormControl control={control} />}
      <TimeOfDayFormControl control={control} />
    </Stack>
  );
};
