import { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';

import { Button, ButtonGroup, Stack, Typography } from '@trustyou/ui';

import type { ScaleQuestionProps } from '../question-types';
import { getLabels } from '../utils';

type NumericButtonGroupProps = ScaleQuestionProps;

export function NumericButtonGroup({
  name,
  control,
  initialValue,
  options,
}: NumericButtonGroupProps) {
  const { field } = useController({ name, control });
  const [hover, setHover] = useState<number | null>(null);
  const [value, setValue] = useState<number | null>(initialValue ?? field.value ?? null);

  const isHovered = hover !== null;
  const hasValue = value !== null;

  const labels = getLabels(options);
  const firstLabel = Object.values(labels).at(0);
  const lastLabel = Object.values(labels).at(-1);

  const hoveredOption = options.find((option) => option.value === hover);
  const selectedOption = options.find((option) => option.value === value);

  useEffect(() => {
    if (initialValue) {
      field.onChange(initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLabel = () => {
    if (isHovered) return labels[hover];
    if (hasValue) return labels[value];
    return null;
  };

  const getColor = () => {
    if (isHovered) return hoveredOption?.color;
    if (hasValue) return selectedOption?.color;
    return 'inherit';
  };

  const isLessOrEqual = (optionValue: number) => {
    if (isHovered) return optionValue <= (hover as number);
    if (hasValue) return optionValue <= (value as number);
    return false;
  };

  const handleClick = (numericValue: number) => {
    field.onChange(numericValue);
    setValue(numericValue);
  };

  return (
    <Stack spacing={1} sx={{ color: 'text.secondary' }}>
      <ButtonGroup disableElevation fullWidth sx={{ '& .MuiButtonGroup-grouped': { minWidth: 8 } }}>
        {options.map((option) => {
          const numericValue = Number(option.value);
          return (
            <Button
              key={option.value.toString()}
              onMouseEnter={() => setHover(numericValue)}
              onMouseLeave={() => setHover(null)}
              onClick={() => handleClick(numericValue)}
              sx={{
                paddingInline: 0,
                transition: 'none',
                color: isLessOrEqual(numericValue) ? 'common.white' : 'text.secondary',
                backgroundColor: isLessOrEqual(numericValue) ? `${getColor()}.main` : 'inherit',
                borderColor: isLessOrEqual(numericValue) ? 'transparent' : 'text.secondary',
                '&:hover': {
                  color: 'common.white',
                  backgroundColor: `${getColor()}.dark`,
                  borderColor: 'transparent',
                },
              }}
            >
              {option.value}
            </Button>
          );
        })}
      </ButtonGroup>
      {isHovered || hasValue ? (
        <Typography variant="body2" color="text.primary" fontWeight={500} textAlign="center">
          {getLabel()}
        </Typography>
      ) : (
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Typography variant="body2" color="text.secondary">
            {firstLabel}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {lastLabel}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}
