import { GET_REUSABLE_SCOPES_PERMISSION, usePermissionStore } from '@trustyou/shared';

const useManageAccessGroups = () => {
  const { hasPermissions } = usePermissionStore();

  const isManageAccessGroupsAllowed = hasPermissions(GET_REUSABLE_SCOPES_PERMISSION);

  return isManageAccessGroupsAllowed;
};

export default useManageAccessGroups;
