import type { GridSortModel } from '@mui/x-data-grid-pro';

export const DEFAULT_PAGINATION_MODEL = {
  pageSize: 10,
  page: 0,
};

export const DRAWER_PAGINATION_MODEL = {
  pageSize: 5,
  page: 0,
};

export const DEFAULT_SORT_MODEL: GridSortModel = [{ field: 'name', sort: 'asc' }];

export const ENTITY_GLOBAL_ATTRIBUTES = [
  'name',
  'brand',
  'sector',
  'type',
  'country',
  'continent',
  'city',
  'zip',
  'address',
];

// https://lawsofux.com/doherty-threshold/
export const SEARCH_DEBOUNCE_TIME_MS = 400;

export const DEFAULT_SORT_KEY = 'name';
export const DEFAULT_PAGE_SIZE = 15;
export const DEFAULT_CONTENT_LIBRARY_PAGE_SIZE = 10;
