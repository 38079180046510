import { Stack, Typography } from '@trustyou/ui';
import DOMPurify from 'dompurify';

import { CustomCard } from '../../components/custom-card';
import { makeLinksOpenInNewTab } from '../../utils';

export type SurveyMessageContentProps = {
  title?: string;
  description?: string;
  imageUrl?: string;
  withoutCardWrapper?: boolean;
};

export const SurveyMessageContent = ({
  title = '',
  description = '',
  imageUrl = '',
  withoutCardWrapper,
}: SurveyMessageContentProps) => {
  if (!title && !description && !imageUrl) return null;

  const sanitizedTitle = DOMPurify.sanitize(title);
  const sanitizedDescription = DOMPurify.sanitize(description);

  const modifiedTitle = makeLinksOpenInNewTab(sanitizedTitle);
  const modifiedDescription = makeLinksOpenInNewTab(sanitizedDescription);

  if (withoutCardWrapper) {
    return (
      <Stack spacing={2}>
        {title && (
          <Typography
            component="h3"
            variant="subtitle1"
            dangerouslySetInnerHTML={{ __html: modifiedTitle }}
          />
        )}
        {description && (
          <Typography
            component="div"
            variant="body2"
            dangerouslySetInnerHTML={{ __html: modifiedDescription }}
          />
        )}
      </Stack>
    );
  }

  return (
    <CustomCard>
      {/* TODO: https://www.figma.com/file/gkhbqJ3wm1vslwB85fYS5L?type=design&node-id=47-36612&mode=dev#728714250 */}
      {/* {imageUrl && (
        <CardMedia
          image={imageUrl}
          component="img"
          height={MAX_HEADER_IMAGE_HEIGHT_PX}
          sx={{
            width: '100%',
            height: 'auto',
            objectFit: 'contain',
          }}
        />
      )} */}
      <Stack spacing={2}>
        {title && (
          <Typography
            component="h3"
            variant="subtitle1"
            dangerouslySetInnerHTML={{ __html: modifiedTitle }}
          />
        )}
        {description && (
          <Typography
            component="div"
            variant="body2"
            dangerouslySetInnerHTML={{ __html: modifiedDescription }}
          />
        )}
      </Stack>
    </CustomCard>
  );
};
