import { FormattedMessage } from 'react-intl';

import { faInfoCircle } from '@trustyou/fortawesome/pro-regular-svg-icons';

import { IconWithTooltip } from '../icon-with-tooltip';

export function InfoIcon() {
  return (
    <IconWithTooltip
      tooltip={
        <FormattedMessage
          id="inbox.search.info"
          defaultMessage='To search for an exact phrase, put it in quotation marks (" ")'
        />
      }
      icon={faInfoCircle}
      size="xl"
      IconProps={{ sx: { color: (theme) => theme.palette.action.active } }}
    />
  );
}
