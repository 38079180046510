import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Alert, AlertTitle } from '@trustyou/ui';

const InvalidFileTypeError = () => (
  <Alert severity="error">
    <AlertTitle>
      <FormattedMessage
        id="entitiesImporter.uploadSpreadsheet.wrongFileType"
        defaultMessage="Wrong file type"
      />
    </AlertTitle>
    <FormattedMessage
      id="entitiesImporter.uploadSpreadsheet.wrongFileTypeErrorDescription"
      defaultMessage="The accepted file type is .csv"
    />
  </Alert>
);

export default InvalidFileTypeError;
