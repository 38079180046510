import type { ReactNode } from 'react';

import { Box, Typography } from '@trustyou/ui';

export type QuestionWrapperProps = {
  children: ReactNode;
  name: string;
};

export const QuestionWrapper = ({ children, name }: QuestionWrapperProps) => (
  <Box>
    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
      {name}
    </Typography>
    {children}
  </Box>
);
