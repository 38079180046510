import {
  SISENSE_SERVER_PATH,
  downloadExcel,
  useAlertStore,
  useExportDashboardExcel,
  useLanguageStore,
} from '@trustyou/shared';

export const useExportDashboardHandle = () => {
  const { locale } = useLanguageStore();
  const { mutate: exportExcel, isPending: isLoading } = useExportDashboardExcel();

  const { alert } = useAlertStore();

  const exportDashboard = (dashboardId: string, iframe: React.RefObject<HTMLIFrameElement>) => {
    const stopLoader = () => {
      iframe.current?.contentWindow?.postMessage(
        {
          action: 'dashboardActions-stopLoader',
        },
        SISENSE_SERVER_PATH as string
      );
    };
    exportExcel(
      {
        id: dashboardId,
        language: locale.split('-')[0],
      },
      {
        onSuccess: (response) => {
          downloadExcel(response.name, response.content);
        },
        onError: () => {
          alert.genericError();
        },
        onSettled: stopLoader,
      }
    );
  };

  return { exportDashboard, isPending: isLoading };
};
