import { type ReactNode, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@trustyou/fortawesome/pro-solid-svg-icons';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@trustyou/ui';

export type CustomAccordionProps = {
  summary: string;
  details: ReactNode;
};

const CustomAccordion = ({ summary, details }: CustomAccordionProps) => {
  const [expanded, setExpanded] = useState(true);

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      disableGutters
      elevation={0}
      square
    >
      <AccordionSummary
        expandIcon={<FontAwesomeIcon icon={faChevronUp} size="xs" />}
        aria-controls="semantic-analysis-content"
        id="semantic-analysis-header"
        data-gtm-id="inbox_hide_sema_box"
      >
        <Typography sx={{ fontSize: 14, fontWeight: 600 }}>{summary}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ maxHeight: '30vh', overflowY: 'auto' }}>{details}</AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;
