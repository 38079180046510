import type { ReviewRoot } from './reviews';

import type { EntityGuides, LanguageEnum, ToneOfVoice } from '../client';

export type ReviewsFilterDataResponse = {
  total: number;
  filtered_total: number;
  items: ReviewRoot[];
  offset: number;
};

export type FetchResponseAITranslateData = {
  text: string;
  language_source: string;
  language_result: string;
};

export type Language =
  | 'BG'
  | 'CS'
  | 'DA'
  | 'DE'
  | 'EL'
  | 'EN'
  | 'EN-GB'
  | 'EN-US'
  | 'ES'
  | 'ET'
  | 'FI'
  | 'FR'
  | 'HU'
  | 'ID'
  | 'IT'
  | 'JA'
  | 'KO'
  | 'LT'
  | 'LV'
  | 'NB'
  | 'NL'
  | 'PL'
  | 'PT-BR'
  | 'PT-PT'
  | 'RO'
  | 'RU'
  | 'SK'
  | 'SL'
  | 'SV'
  | 'TR'
  | 'UK'
  | 'ZH'
  | 'AF'
  | 'SQ'
  | 'AM'
  | 'AR'
  | 'HY'
  | 'AS'
  | 'AY'
  | 'AZ'
  | 'BM'
  | 'EU'
  | 'BE'
  | 'BN'
  | 'BHO'
  | 'BS'
  | 'CA'
  | 'CEB'
  | 'ZH-CN'
  | 'ZH-TW'
  | 'CO'
  | 'HR'
  | 'DV'
  | 'DOI'
  | 'EO'
  | 'EE'
  | 'FIL'
  | 'FY'
  | 'GL'
  | 'KA'
  | 'GN'
  | 'GU'
  | 'HT'
  | 'HA'
  | 'HAW'
  | 'HE'
  | 'IW'
  | 'HI'
  | 'HMN'
  | 'IS'
  | 'IG'
  | 'ILO'
  | 'GA'
  | 'JV'
  | 'JW'
  | 'KN'
  | 'KK'
  | 'KM'
  | 'RW'
  | 'GOM'
  | 'KRI'
  | 'KU'
  | 'CKB'
  | 'KY'
  | 'LO'
  | 'LA'
  | 'LN'
  | 'LG'
  | 'LB'
  | 'MK'
  | 'MAI'
  | 'MG'
  | 'MS'
  | 'ML'
  | 'MT'
  | 'MI'
  | 'MR'
  | 'MNI-MTEI'
  | 'LUS'
  | 'MN'
  | 'MY'
  | 'NE'
  | 'NO'
  | 'NY'
  | 'OR'
  | 'OM'
  | 'PS'
  | 'FA'
  | 'PT'
  | 'PA'
  | 'QU'
  | 'SM'
  | 'SA'
  | 'GD'
  | 'NSO'
  | 'SR'
  | 'ST'
  | 'SN'
  | 'SD'
  | 'SI'
  | 'SO'
  | 'SU'
  | 'SW'
  | 'TL'
  | 'TG'
  | 'TA'
  | 'TT'
  | 'TE'
  | 'TH'
  | 'TI'
  | 'TS'
  | 'TK'
  | 'AK'
  | 'UR'
  | 'UG'
  | 'UZ'
  | 'VI'
  | 'CY'
  | 'XH'
  | 'YI'
  | 'YO'
  | 'ZU';

export type LanguageItem = {
  label: string;
  value: Language | 'AUTO';
};

export type CreditData = {
  consumed: number;
  total: number;
  expire_on: string;
};

export enum ReplyLengthLabels {
  Auto = 'auto',
  Detailed = 'detailed',
  Brief = 'brief',
}

export type UserProfileData = {
  guides: EntityGuides[];
  reply_length: ReplyLengthLabels;
  tone_of_voice: ToneOfVoice;
  reply_language: Language | 'AUTO';
  translation_language: LanguageEnum;
};

export type ResponseResult = {
  consumption_log_id: string;
  response_text: string;
  response_language: Language;
  response_language_name: string;
  response_translation_language: Language;
  response_translation_language_name: string;
  translated_response_text: string;
  templates_used: string[];
};

export type Score = 1 | 2 | 3 | 4 | 5;

export type Issue =
  | 'tone_issues'
  | 'text_length'
  | 'translation'
  | 'signature_issues'
  | 'missing_guide'
  | 'missing_content'
  | 'generated_false_content'
  | 'misunderstood_sentiment'
  | 'other';

export type ResponseAIFeedback = {
  consumption_log_id: string;
  score: Score;
  issues?: Issue[];
  description?: string;
};
