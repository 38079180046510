import { useQuery } from '@tanstack/react-query';
import { $assApi, ANALYTICS_BASE_PATH } from '@trustyou/shared';

import { HAS_REPORTS_CREATED } from '../../constants/query-keys';

async function fetchReportsCreatedStatus(): Promise<boolean> {
  // TODO: Get this status from BE in one api.
  const { data: reportsData } = await $assApi.get(`${ANALYTICS_BASE_PATH}/report/list`, {
    params: {
      skip: 0,
      limit: 1,
      type: 'excel',
    },
  });
  if ((reportsData?.meta?.totalRecords || 0) > 0) return true;
  const { data: historyData } = await $assApi.get(
    `${ANALYTICS_BASE_PATH}/report/list_reports_with_status`,
    {
      params: {
        skip: 0,
        limit: 1,
        type: 'excel',
      },
    }
  );
  return (historyData?.meta?.totalRecords || 0) > 0;
}

export function useHasReportsCreated() {
  return useQuery({
    queryKey: [HAS_REPORTS_CREATED],
    queryFn: () => fetchReportsCreatedStatus(),
    refetchOnWindowFocus: false,
    enabled: true,
  });
}
