import { useIntl } from 'react-intl';

import { faBrush, faGear, faImage, faUserGear } from '@trustyou/fortawesome/pro-regular-svg-icons';

import { ThemeEditorImageUploadContent } from './content/image-upload';
import { ThemeEditorSettingsContent } from './content/settings';
import { ThemeEditorStyleContent } from './content/style';

import { EditorScopeContent } from '../../shared/editor-scope-content';
import type { Step } from '../../shared/editor-side-nav';

export function useThemeEditorSteps() {
  const intl = useIntl();

  const steps: Step[] = [
    {
      icon: faGear,
      label: intl.formatMessage({ id: 'survey.common.settings', defaultMessage: 'Settings' }),
      content: <ThemeEditorSettingsContent />,
    },
    {
      icon: faBrush,
      label: intl.formatMessage({ id: 'survey.common.style', defaultMessage: 'Style' }),
      content: <ThemeEditorStyleContent />,
    },
    {
      icon: faImage,
      label: intl.formatMessage({ id: 'survey.common.images', defaultMessage: 'Images' }),
      content: <ThemeEditorImageUploadContent />,
    },
    {
      icon: faUserGear,
      label: intl.formatMessage({ id: 'survey.common.scope', defaultMessage: 'Scope' }),
      content: <EditorScopeContent variant="theme" />,
    },
  ];

  return steps;
}
