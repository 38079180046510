import { FormattedMessage } from 'react-intl';

import { faPlus } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  EmptyStatePlaceholder,
  StyledFontAwesomeIcon as Icon,
  personSelectingData,
} from '@trustyou/ui';

import { emptyState } from '../../../constants/messages/customAttributes';
import { styles } from './styles';

type Props = {
  onCreate: () => void;
};

export const EmptyState = ({ onCreate }: Props) => (
  <EmptyStatePlaceholder
    emptyStateContainer={styles.emptyStateContainer}
    emptyStateHeader={styles.emptyStateHeader}
    img={personSelectingData}
    dataName="custom-attributes"
    title={<FormattedMessage {...emptyState.title} />}
    description={<FormattedMessage {...emptyState.description} />}
    action={{
      testId: 'custom-attributes-empty-state',
      message: <FormattedMessage {...emptyState.newAttribute} />,
      handler: onCreate,
      styles: styles.emptyStateAction,
      startIcon: <Icon icon={faPlus} />,
    }}
  />
);
