import { grey } from '@mui/material/colors';
import type { IStyles } from '@trustyou/shared';

export const styles: IStyles = {
  generalPageContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
    gap: 2,
    overflowY: 'auto',
  },
  organizationDataWrapper: {
    mt: 5,
    display: 'flex',
    flexDirection: {
      xs: 'column-reverse',
      sm: 'row',
    },
  },
  statsContainer: {
    ml: 'auto',
    height: '52px',
    '& > div': {
      pl: 3,
    },
    '& > div:first-child': {
      borderRight: `1px solid ${grey[300]}`,
      pr: 3,
    },
  },
  organizationName: {
    minWidth: '400px',
    width: {
      xs: '100%',
      sm: 'auto',
    },
    mt: {
      xs: 2,
      sm: 0,
    },
  },
  pageActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 5,
    gap: 2,
  },
  logoUploadButtons: { width: 'fit-content' },
  logoView: { maxHeight: 100, maxWidth: 400, width: 'fit-content' },
};
