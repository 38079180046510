import { useIntl } from 'react-intl';

import { Chip, PopperMenu } from '@trustyou/ui';

import { USER_PLACEHOLDER } from '../../constants';
import { commonMessages } from '../../constants/messages';
import { useStore } from '../../store/store';
import type { ReviewRoot } from '../../types';
import { getHumanDate } from '../../utils/date';
import { messages } from '../survey-moderation/messages';
import { StatusList } from './status-list';

export const InappropriateChip = () => {
  const intl = useIntl();
  const { moderation } = useStore.use.reviewRoot() as ReviewRoot;

  return (
    <PopperMenu
      disablePortal={false}
      placement="bottom"
      Trigger={({ onClick }) => (
        <Chip
          label={intl.formatMessage(messages.inappropriate)}
          size="small"
          variant="pastelError"
          onClick={onClick}
        />
      )}
      Content={() => (
        <StatusList
          items={[
            {
              concept: intl.formatMessage(messages.markedAsInappropriate),
              content: getHumanDate(moderation?.request.created_at ?? ''),
            },
            {
              concept: intl.formatMessage(commonMessages.by),
              content: moderation?.request.username ?? USER_PLACEHOLDER,
            },
            {
              concept: intl.formatMessage(messages.moderationConfirmed),
              content: getHumanDate(moderation?.revision?.created_at ?? ''),
            },
            {
              concept: intl.formatMessage(commonMessages.by),
              content: moderation?.revision?.username ?? USER_PLACEHOLDER,
            },
          ]}
        />
      )}
    />
  );
};
