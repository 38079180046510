import { FormattedMessage, useIntl } from 'react-intl';

import { styled } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@trustyou/ui';

import { getValueFromMsgs, guestInfoMessages } from '../../constants/messages';
import type { GuestInfo } from '../../utils/guest-information';

export type GuestInformationTableProps = {
  rows: GuestInfo[];
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(0.75, 2),
  verticalAlign: 'top',
}));

export const GuestInformationTable = ({ rows }: GuestInformationTableProps) => {
  const intl = useIntl();

  return (
    <TableContainer sx={{ marginBlock: 2, maxHeight: 500 }}>
      <Table>
        <TableBody>
          {rows.map(({ label, value }) => (
            <TableRow key={`${label}:${value}`}>
              <StyledTableCell sx={{ width: 220 }} component="th" scope="row">
                {getValueFromMsgs(guestInfoMessages, label ?? '')
                  ? intl.formatMessage(getValueFromMsgs(guestInfoMessages, label ?? ''))
                  : label}
              </StyledTableCell>
              {value ? (
                <StyledTableCell>{value}</StyledTableCell>
              ) : (
                <StyledTableCell sx={{ color: 'text.disabled' }}>
                  <FormattedMessage id="inbox.not-available" defaultMessage="Not available" />
                </StyledTableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
