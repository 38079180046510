import type { IStyles } from '@trustyou/shared';
import { theme } from '@trustyou/ui';

const styles: IStyles = {
  dialog: {
    '& .MuiDialog-paper': {
      minWidth: '600px',
      minHeight: '400px',
      maxHeight: '700px',
    },
  },
  dialogTitle: {
    padding: 2,
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
  },
  dialogContent: {
    padding: 2,
    paddingTop: `${theme.spacing(2)} !important`,
  },
  closeButton: {
    marginLeft: 'auto',
    mt: 1,
  },
  list: {
    paddingY: 0,
    '& .MuiListItemText-primary': {
      display: 'flex',
      flexDirection: 'row',
    },
    '& .MuiListItem-root': {
      padding: 0,
    },
  },
  listItemIcon: { minWidth: '24px', textAlign: 'center', paddingTop: 0.25 },
  listItemPrimaryText: { display: 'inline', fontWeight: 500 },
};

export default styles;
