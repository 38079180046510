import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  markAsDeleted: {
    id: 'inbox.review.delete.tooltip',
    defaultMessage: 'Mark as deleted',
  },
  markedDeleted: {
    id: 'inbox.response.status.chip.marked-deleted',
    defaultMessage: 'Marked deleted',
  },
  markedAsDeleted: {
    id: 'inbox.response.status.chip.marked-as-deleted',
    defaultMessage: 'Marked as deleted',
  },
  deleted: {
    id: 'inbox.response.status.deleted',
    defaultMessage: 'Deleted',
  },
  removingFromAnalyticsDisclaimer: {
    id: 'inbox.response.status.removing-from-analytics-disclaimer',
    defaultMessage:
      'We’re removing the review from analytics. This might take a couple of minutes.',
  },
});
