import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import type { IconButtonProps, TooltipProps } from '@mui/material';
import {
  Box,
  IconButton,
  StyledFontAwesomeIcon,
  type StyledFontAwesomeIconProps,
  Tooltip,
} from '@trustyou/ui';

export type IconButtonWithTooltipProps = IconButtonProps & {
  icon: FontAwesomeIconProps['icon'];
  tooltip: TooltipProps['title'];
  IconProps?: Partial<StyledFontAwesomeIconProps>;
  hide?: boolean;
};

export const IconButtonWithTooltip = ({
  icon,
  tooltip,
  IconProps,
  hide,
  ...rest
}: IconButtonWithTooltipProps) =>
  hide ? null : (
    <Tooltip arrow placement="top" title={tooltip}>
      <Box>
        <IconButton sx={{ width: 40, height: 40 }} {...rest}>
          <StyledFontAwesomeIcon icon={icon} size="xs" {...IconProps} />
        </IconButton>
      </Box>
    </Tooltip>
  );
