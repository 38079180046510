import { useEffect, useState } from 'react';

import { useOidc, useOidcAccessToken } from '@axa-fr/react-oidc';
import { useChangelingStore } from '@trustyou/shared';

const useAuthChangeling = () => {
  const [isCompleted, setIsCompleted] = useState(false);
  const { isChangeling, isReadyToAuthToAdmin } = useChangelingStore();
  const { logout, isAuthenticated } = useOidc();
  const { accessTokenPayload: keycloakAccessTokenPayload } = useOidcAccessToken();

  useEffect(() => {
    (async () => {
      if (!isChangeling) {
        // When is not a changeling flow, I stop here
        setIsCompleted(true);
      } else if (!isAuthenticated && !isReadyToAuthToAdmin) {
        // If not authenticated to cxp-customers and not in ready state I redirect passing the changeling-auth query param
        window.location.replace(`${window.location.href.split('?')[0]}?changeling-auth=ready`);
      } else if (keycloakAccessTokenPayload?.azp === 'cxp-customers') {
        // I remove the tokenStore before logout
        // localStorage.removeItem('tokenStore');
        // If I'm authenticated to cxp-customers I logout and redirect passing the changeling-auth query param
        await logout(`${window.location.href.split('?')[0]}?changeling-auth=ready`);
      } else {
        // If I'm authenticated with cxp-operations than flow is complete
        setIsCompleted(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangeling]);

  useEffect(() => {
    if (!isAuthenticated) return;
    if (keycloakAccessTokenPayload?.azp === 'cxp-customers') return;
    // When flow is complete and I'm authenticated with cxp-operations I replace the address removing the query params
    window.history.replaceState(null, '', window.location.href.split('?')[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompleted]);

  return { isCompleted };
};

export default useAuthChangeling;
