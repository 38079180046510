import { create } from 'zustand';

import { routePermissions } from '../constants';
import type { Permissions } from '../types';
import { checkUserPermission, isRouteAllowed } from '../utils';

type PermissionState = {
  permissions: Permissions | null;
  setPermissions: (permissions: Permissions) => void;
  hasPermissions: (permission: string) => boolean;
  hasRoutePermissions: (route: string) => boolean;
};

export const usePermissionStore = create<PermissionState>((set, get) => ({
  permissions: null,
  setPermissions: (permissions) => set({ permissions }),
  hasPermissions: (permission) => checkUserPermission(permission, get().permissions),
  hasRoutePermissions: (route) => {
    const { permissions } = get();
    return permissions ? isRouteAllowed(route, routePermissions, permissions) : false;
  },
}));
