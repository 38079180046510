import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  container: {
    pl: 2.5,
    mb: 0,
  },
};

export default styles;
