import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type {
  CustomAttributeDefinition,
  CustomAttributeDeleteCheckResponse,
  CustomAttributeUpdateCheckResponse,
} from '@trustyou/shared';
import type { AxiosError } from 'axios';

import {
  CREATE_CUSTOM_ATTRIBUTE_DEFINITION,
  DELETE_CUSTOM_ATTRIBUTE_DEFINITION,
  FETCH_ATTRIBUTES,
  FETCH_CAN_DELETE_CUSTOM_ATTRIBUTE_DEFINITION,
  FETCH_CAN_MARK_AS_UNIQUE_CUSTOM_ATTRIBUTE,
  FETCH_CAN_UPDATE_CUSTOM_ATTRIBUTE_DEFINITION,
  FETCH_CUSTOM_ATTRIBUTE_DEFINITION,
  FETCH_CUSTOM_ATTRIBUTE_DEFINITIONS,
  FETCH_ENTITIES,
  FETCH_ENTITY_DETAILS,
  IS_VALID_CUSTOM_ATTRIBUTE_NAME,
  UPDATE_CUSTOM_ATTRIBUTE_DEFINITION,
  UPDATE_ENTITY_CUSTOM_ATTRIBUTES,
} from '../../../constants/queryKeys';
import {
  canDeleteCustomAttributeDefinition,
  canMarkAsUniqueCustomAttribute,
  canUpdateCustomAttributeDefinition,
  createCustomAttributeDefinition,
  deleteCustomAttributeDefinition,
  fetchCustomAttributeDefinition,
  fetchCustomAttributeDefinitions,
  isValidCustomAttributeName,
  updateCustomAttributeDefinition,
  updateEntityCustomAttributes,
} from '../../../queries';

export function useCustomAttributeDefinitions(enabled = true) {
  return useQuery({
    queryKey: [FETCH_CUSTOM_ATTRIBUTE_DEFINITIONS],
    queryFn: fetchCustomAttributeDefinitions,
    refetchOnWindowFocus: false,
    enabled,
  });
}

export function useCustomAttributeDefinition(id?: string) {
  return useQuery({
    queryKey: [FETCH_CUSTOM_ATTRIBUTE_DEFINITION],
    queryFn: () => fetchCustomAttributeDefinition(id as string),
    refetchOnWindowFocus: false,
    enabled: !!id,
  });
}

export function useValidCustomAttributeName() {
  return useMutation({
    mutationKey: [IS_VALID_CUSTOM_ATTRIBUTE_NAME],
    mutationFn: isValidCustomAttributeName,
  });
}

export function useCreateCustomAttributeDefinition(
  onSuccess: (data: CustomAttributeDefinition) => void,
  onError?: (error: AxiosError) => void,
  onSettled?: () => void
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createCustomAttributeDefinition,
    mutationKey: [CREATE_CUSTOM_ATTRIBUTE_DEFINITION],
    onSuccess: (data: CustomAttributeDefinition) => {
      queryClient.refetchQueries({ queryKey: [FETCH_CUSTOM_ATTRIBUTE_DEFINITIONS] });
      queryClient.removeQueries({ queryKey: [FETCH_ATTRIBUTES] });
      onSuccess(data);
    },
    onError,
    onSettled,
  });
}

export function useUpdateCustomAttributeDefinition(
  onSuccess: (data: CustomAttributeDefinition) => void,
  onError?: (error: AxiosError) => void,
  onSettled?: () => void
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateCustomAttributeDefinition,
    mutationKey: [UPDATE_CUSTOM_ATTRIBUTE_DEFINITION],
    onSuccess: (data: CustomAttributeDefinition) => {
      queryClient.refetchQueries({ queryKey: [FETCH_CUSTOM_ATTRIBUTE_DEFINITIONS] });
      queryClient.removeQueries({ queryKey: [FETCH_ATTRIBUTES] });
      onSuccess(data);
    },
    onError,
    onSettled,
  });
}

export function useDeleteCustomAttributeDefinition(
  onSuccess: () => void,
  onError?: (error: Error) => void
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteCustomAttributeDefinition,
    mutationKey: [DELETE_CUSTOM_ATTRIBUTE_DEFINITION],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [FETCH_CUSTOM_ATTRIBUTE_DEFINITIONS] });
      queryClient.removeQueries({ queryKey: [FETCH_ATTRIBUTES] });
      onSuccess();
    },
    onError,
  });
}

export function useCanCustomAttributeMarkAsUnique(id?: string) {
  return useQuery({
    queryKey: [FETCH_CAN_MARK_AS_UNIQUE_CUSTOM_ATTRIBUTE, id],
    queryFn: () => canMarkAsUniqueCustomAttribute(id as string),
    refetchOnWindowFocus: false,
    enabled: !!id,
  });
}

export function useCanDeleteCustomAttributeDefinition(
  onSuccess: (data: CustomAttributeDeleteCheckResponse) => void,
  onError: (error: Error) => void
) {
  return useMutation({
    mutationFn: canDeleteCustomAttributeDefinition,
    mutationKey: [FETCH_CAN_DELETE_CUSTOM_ATTRIBUTE_DEFINITION],
    onSuccess,
    onError,
  });
}

export function useCanUpdateCustomAttributeDefinition(
  onSuccess: (data: CustomAttributeUpdateCheckResponse, payload: CustomAttributeDefinition) => void,
  onError: (error: Error) => void
) {
  return useMutation({
    mutationFn: canUpdateCustomAttributeDefinition,
    mutationKey: [FETCH_CAN_UPDATE_CUSTOM_ATTRIBUTE_DEFINITION],
    onSuccess,
    onError,
  });
}

export function useUpdateEntityCustomAttributes(
  onSuccess: () => void,
  onError?: (error: AxiosError) => void
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateEntityCustomAttributes,
    mutationKey: [UPDATE_ENTITY_CUSTOM_ATTRIBUTES],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [FETCH_ENTITY_DETAILS] });
      queryClient.refetchQueries({ queryKey: [FETCH_ENTITIES] });
      onSuccess();
    },
    onError,
  });
}
