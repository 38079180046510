import type { IStyles } from '@trustyou/shared';

import blueBanner from '../../assets/banners/blue-banner.svg';

const styles: IStyles = {
  userCard: {
    display: 'flex',
    gap: 1,
    borderRadius: '4px',
    paddingX: 3,
    paddingY: 5,
    boxShadow:
      '0px 2px 1px -1px rgba(69, 90, 100, 0.10), 0px 1px 1px 0px rgba(69, 90, 100, 0.07), 0px 1px 3px 0px rgba(69, 90, 100, 0.06)',
    flexDirection: 'column',
    color: 'white',
    backgroundImage: `url(${blueBanner})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  },
};

export default styles;
