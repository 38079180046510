import { useLanguageStore } from '@trustyou/shared';

import {
  useFetchMetaCategory,
  useFetchSubCategories,
  useFetchSubcategoryMapping,
} from './api-query';
import { useShortLocale } from './use-short-locale';

import type { SemaLanguage, SemaMatchesSchema } from '../client';
import { useStore } from '../store/store';

const onlyUnique = (value: string, index: number, array: string[]) =>
  array.indexOf(value) === index;

const getSemanticCategoryItemsByTokens = (tokens: string[], data?: Record<string, string>) =>
  tokens.filter(onlyUnique).map((token) => data?.[token]) as string[];

export function useSemanticCategoryItems() {
  const { locale } = useLanguageStore();
  const shortLocale = useShortLocale();
  const { data } = useFetchMetaCategory(shortLocale);
  const { data: subcategories } = useFetchSubCategories(locale as SemaLanguage);
  const { data: subcategoryMap } = useFetchSubcategoryMapping();
  const reviewRoot = useStore.use.reviewRoot();
  const { p, o, n, opinions } = reviewRoot?.sema_matches as SemaMatchesSchema;

  const getSemanticCategoryItemsByOpinions = (sentiment: 'p' | 'n' | 'o'): string[] => {
    if (!opinions || !subcategoryMap) return [];

    //unique topic ids for given sentiment
    const topics = [
      ...new Set(
        opinions
          .filter((opinion) => opinion.sentiment === sentiment)
          .map((opinion) => opinion.topic_id)
      ),
    ];

    const subcategoryTopics = Object.keys(subcategoryMap).reduce(
      (result: string[], sector: string) => {
        // filter mapping sectors that do not match the sector of the current review
        if (reviewRoot?.meta.sector !== sector.toUpperCase()) return result;

        // all combined unique sector mappings
        return [
          ...new Set([
            ...result,
            // get array of category keys for given topics
            ...Object.keys(subcategoryMap[sector]).filter((subcategory) =>
              topics.some((topic) => subcategoryMap[sector][subcategory].includes(topic))
            ),
          ]),
        ];
      },
      []
    );

    // extract the subcategory labels
    return subcategoryTopics?.map((key: string) => subcategories?.[key]) ?? [];
  };

  const getSemanticCategoryItems = (sentiment: 'p' | 'n' | 'o') => {
    const tokens = sentiment === 'p' ? p : sentiment === 'n' ? n : o;
    return [
      ...new Set([
        ...getSemanticCategoryItemsByOpinions(sentiment),
        ...getSemanticCategoryItemsByTokens(tokens ?? [], data),
      ]),
    ];
  };

  return { getSemanticCategoryItems };
}
