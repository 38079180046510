import { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';

import { faFileArrowUp } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { commonFields } from '@trustyou/shared';
import { Box, Button, StyledFontAwesomeIcon as Icon, Stack, Typography } from '@trustyou/ui';

import type { FileProp } from './ImageFilePreview';

export type DropzoneProps = {
  file: FileProp;
  setFile: (file: FileProp) => void;
};

export default function Dropzone({ file, setFile }: DropzoneProps) {
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/svg': ['.svg'],
    },
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFile(
        Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(file ? file : acceptedFiles[0]),
        }) as File & { preview: string }
      );
    },
  });

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => URL.revokeObjectURL(file ? file.preview : '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ width: { sm: '550px' } }}>
      <Stack
        {...getRootProps()}
        spacing={2}
        sx={{ border: '2px dashed rgba(0, 0, 0, 0.23)', padding: 4 }}
      >
        <Icon
          icon={faFileArrowUp}
          size="6x"
          sx={{ alignSelf: 'center', color: 'rgba(0, 0, 0, 0.54)' }}
        />
        <Box>
          <Typography variant="h6" align="center" sx={{ marginBottom: 0 }}>
            <FormattedMessage
              id="common.image-upload.click-to-upload"
              defaultMessage="Click to upload"
            />
          </Typography>
          <Typography variant="body2" align="center" color="text.secondary">
            <FormattedMessage
              id="image-upload.dropzone.description"
              defaultMessage="or drag & drop here. PNG, SVG and JPG  supported, maximum 1MB"
            />
          </Typography>
        </Box>
        <Button variant="contained" onClick={open} sx={{ alignSelf: 'center' }}>
          <FormattedMessage {...commonFields.selectFile} />
        </Button>
        <input {...getInputProps()} />
      </Stack>
    </Box>
  );
}
