import { useChangelingStore, useCustomAttributeDefinition } from '@trustyou/shared';
import { ComposableDrawerWithStickyFooter } from '@trustyou/ui';

import { Form } from './Form';

const SLIDE_ANIMATION_DURATION = 250;
const DRAWER_WIDTH_PX = 600;

type Props = {
  id?: string;
  onClose: () => void;
};

export const CustomAttributeManageDrawer = ({ id, onClose }: Props) => {
  const { isChangeling } = useChangelingStore();
  const { data, isFetching } = useCustomAttributeDefinition(id);
  return (
    <ComposableDrawerWithStickyFooter
      open
      anchor="right"
      onClose={onClose}
      PaperProps={{
        sx: {
          width: DRAWER_WIDTH_PX,
          paddingTop: isChangeling ? 8 : 0,
        },
      }}
      SlideProps={{ appear: true, timeout: SLIDE_ANIMATION_DURATION }}
    >
      {id ? (
        !isFetching && data && <Form data={data} onClose={onClose} />
      ) : (
        <Form onClose={onClose} />
      )}
    </ComposableDrawerWithStickyFooter>
  );
};
