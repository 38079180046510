import { useState } from 'react';
import { useController } from 'react-hook-form';

import type { DateView } from '@mui/x-date-pickers-pro';
import { useLanguageStore } from '@trustyou/shared';
import { AdapterDayjs, DatePicker, LocalizationProvider } from '@trustyou/ui';
import dayjs, { type Dayjs } from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/ja';
import 'dayjs/locale/pt';
import 'dayjs/locale/ru';
import 'dayjs/locale/zh';

import { Question, type QuestionProps } from '../../components/question';

export type DateQuestionVariant = 'day-month-year' | 'month-year';

export type DateQuestionProps = QuestionProps & {
  variant?: DateQuestionVariant;
};

const DATE_VARIANT_SETTINGS = {
  'day-month-year': {
    format: 'DD/MM/YYYY',
    views: ['year', 'month', 'day'],
    formatFn: (yearMonthDay: Dayjs | null) => dayjs(yearMonthDay).format('YYYY-MM-DD'),
  },
  'month-year': {
    format: 'MM/YYYY',
    views: ['year', 'month'],
    // Submit API expects the first day of the selected month, for example 2024-04-01.
    formatFn: (yearMonth: Dayjs | null) => dayjs(yearMonth).startOf('month').format('YYYY-MM-DD'),
  },
};

export const DateQuestion = ({
  name,
  control,
  variant = 'day-month-year',
  ...props
}: DateQuestionProps) => {
  const { locale } = useLanguageStore();
  const { field } = useController({ name, control });
  const [value, setValue] = useState<Dayjs | null>(null);

  const handleChange = (newValue: Dayjs | null) => {
    field.onChange(DATE_VARIANT_SETTINGS[variant]?.formatFn(newValue));
    setValue(newValue);
  };

  return (
    <Question name={name} control={control} {...props}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale.substring(0, 2)}>
        <DatePicker
          sx={{ maxWidth: '320px', width: '100%' }}
          format={DATE_VARIANT_SETTINGS[variant]?.format}
          views={DATE_VARIANT_SETTINGS[variant]?.views as DateView[]}
          value={value}
          onChange={handleChange}
        />
      </LocalizationProvider>
    </Question>
  );
};
