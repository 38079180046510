import { type ReactNode, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { faXmark } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Selector,
  type SelectorItem,
  StyledFontAwesomeIcon,
  Typography,
} from '@trustyou/ui';

export interface SelectorModalProps<T> {
  items: (T & SelectorItem)[];
  selectedItems: (T & SelectorItem)[];
  isLoading?: boolean;
  title: ReactNode;
  subHeader?: ReactNode;
  allTab: ReactNode;
  selectTab: ReactNode;
  selectedHeaderTitle: ReactNode;
  alertTitle?: ReactNode;
  alertDescription?: ReactNode;
  defaultValue?: string;
  searchPlaceholder?: string;
  hideButtonGroup?: boolean;
  maxItems?: number;
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  onSearch: (value: string) => void;
  setSelectedItems: (items: (T & SelectorItem)[]) => void;
  renderRowContent: (item: T & SelectorItem) => ReactNode;
  onFetchNextPage?: () => void;
}

export const SelectorModal = <T,>({
  items,
  selectedItems,
  isLoading,
  title,
  subHeader,
  allTab,
  selectTab,
  selectedHeaderTitle,
  alertTitle,
  alertDescription,
  defaultValue,
  searchPlaceholder,
  hideButtonGroup,
  maxItems,
  isOpen,
  onClose,
  onSave,
  setSelectedItems,
  renderRowContent,
  onFetchNextPage,
  onSearch,
}: SelectorModalProps<T>) => {
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [showSelectionTab, setShowSelectionTab] = useState(
    hideButtonGroup || selectedItems.length > 0
  );

  useEffect(() => {
    setIsAlertVisible(false);
  }, [showSelectionTab, selectedItems]);

  const select = () => {
    if (
      showSelectionTab &&
      (selectedItems.length === 0 || (maxItems !== undefined && selectedItems.length > maxItems))
    ) {
      setIsAlertVisible(true);
      return;
    }
    onSave();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle sx={{ display: 'flex', paddingBottom: 4 }}>
        <Box>
          {title}
          <Typography variant="body2" color="text.secondary">
            {subHeader}
          </Typography>
        </Box>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: (theme) => theme.spacing(2),
          }}
        >
          <StyledFontAwesomeIcon icon={faXmark} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ paddingTop: 2 }}>
        {!hideButtonGroup && (
          <ButtonGroup sx={{ marginBottom: 5, paddingTop: 2 }}>
            <Button
              variant={!showSelectionTab ? 'contained' : 'outlined'}
              onClick={() => {
                setShowSelectionTab(false);
                setSelectedItems([]);
              }}
            >
              {allTab}
            </Button>
            <Button
              variant={showSelectionTab ? 'contained' : 'outlined'}
              onClick={() => setShowSelectionTab(true)}
            >
              {selectTab}
            </Button>
          </ButtonGroup>
        )}
        {showSelectionTab && (
          <Selector
            defaultValue={defaultValue}
            searchPlaceholder={searchPlaceholder}
            items={items}
            selectedHeaderTitle={selectedHeaderTitle}
            onSelectItems={setSelectedItems}
            selectedItems={selectedItems}
            renderRowContent={renderRowContent}
            isLoading={isLoading}
            onFetchNextPage={onFetchNextPage}
            onSearch={onSearch}
          />
        )}
        {isAlertVisible && (
          <Alert severity="error" sx={{ marginTop: 2 }}>
            <AlertTitle sx={{ ...(!alertDescription && { marginBottom: 0 }) }}>
              {alertTitle}
            </AlertTitle>
            {alertDescription}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onClose}>
          <FormattedMessage id="inbox.action.cancel" defaultMessage="Cancel" />
        </Button>
        <Button onClick={select} variant="contained" color="primary">
          <FormattedMessage id="inbox.action.select" defaultMessage="Select" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
