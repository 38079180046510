import type { SxProps, Theme } from '@mui/material';
import {
  type IconDefinition,
  faBed,
  faFerrisWheel,
  faPlane,
  faScreenUsers,
  faUtensils,
} from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Avatar, StyledFontAwesomeIcon as Icon, SpotIcon } from '@trustyou/ui';

import styles from './styles';

type SectorIconProps = {
  sector: string;
  sx?: SxProps<Theme>;
};

type IconsMap = { [key: string]: IconDefinition };

const ICON_MAP: IconsMap = {
  accommodation: faBed,
  food_and_beverage: faUtensils,
  travel_and_tourism: faPlane,
  point_of_interest: faFerrisWheel,
  meetings_and_events: faScreenUsers,
};

const getKey = (sector: string) => sector?.toLowerCase().replace(/ /g, '_');

export const SectorAvatarIcon = ({ sector, sx }: SectorIconProps) => (
  <Avatar sx={{ ...styles.avatar, ...sx }}>
    <Icon size="lg" icon={ICON_MAP[getKey(sector)]} title={sector} />
  </Avatar>
);

export const SectorSpotIcon = ({ sector, sx }: SectorIconProps) => (
  <SpotIcon icon={ICON_MAP[getKey(sector)]} sx={{ ...styles.spotIcon, ...sx }} altText="sector" />
);
