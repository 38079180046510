import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  appBar: {
    background: '#FFF',
    color: 'text.primary',
    paddingX: 1.5,
    paddingY: 1,
    height: '64px',
    display: 'flex',
    gap: 2,
    flexDirection: 'row',
    alignItems: 'center',
    boxShadow:
      '0px 2px 1px -1px rgba(69, 90, 100, 0.10), 0px 1px 1px 0px rgba(69, 90, 100, 0.07), 0px 1px 3px 0px rgba(69, 90, 100, 0.06)',
  },
  iconButton: { color: 'text.primary', padding: 1.5 },
  header: { color: 'text.primary', fontWeight: 500 },
};

export default styles;
