import type { IStyles } from '@trustyou/shared';

export const styles: IStyles = {
  formControl: { width: '320px' },
  multiSelect: {
    '.MuiSelect-select': {
      padding: '8.5px 14px',
    },
  },
  multiSelectCount: {
    paddingX: 3.5,
    height: '38px',
    opacity: '1 !important',
  },
  multiSelectMenuItem: {
    height: '38px',
  },
};
