import { Box, Typography } from '@trustyou/ui';

export type DataGridCellProps = {
  primaryValue: React.ReactNode;
  secondaryValue?: string;
};

export const DataGridCell = ({ primaryValue, secondaryValue }: DataGridCellProps) => (
  <Box>
    <Typography variant="body2">{primaryValue}</Typography>
    {secondaryValue && (
      <Typography variant="caption" color="text.secondary">
        {secondaryValue}
      </Typography>
    )}
  </Box>
);
