import type { ReactElement } from 'react';

import { Typography } from '@trustyou/ui';

import { QuestionWrapper } from './question-wrapper';

export type TextQuestionProps = {
  name: string;
  text?: string | ReactElement;
  description?: string;
};

export const TextQuestion = ({ name, text, description }: TextQuestionProps) => (
  <QuestionWrapper name={name}>
    <Typography variant="body2">{text}</Typography>
    {description && (
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {description}
      </Typography>
    )}
  </QuestionWrapper>
);
