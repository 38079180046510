import { useState } from 'react';

import { Box, Dialog } from '@trustyou/ui';

import type { ImageRow } from '../../../types/content';

type ThumbnailProps = {
  row: ImageRow;
};

export function Thumbnail({ row }: ThumbnailProps) {
  const [open, setOpen] = useState(false);

  // FIXME: read `banner` or `logo` from backend data
  const isLogo = row.path.endsWith('.png');
  const src = isLogo
    ? 'https://cxp.trustyou.com/app/favicon.ico'
    : 'https://cxp.trustyou.com/app/analytics/ty-logo-white.png';

  return (
    <>
      <Box
        component="img"
        // src={row.path}
        src={src}
        sx={{
          width: isLogo ? 53 : 240,
          // borderRadius: 1,
          cursor: 'pointer',
        }}
        onClick={() => setOpen(true)}
      />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Box component="img" src={src} />
      </Dialog>
    </>
  );
}
