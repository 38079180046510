import { grey } from '@mui/material/colors';
import type { IStyles } from '@trustyou/shared';

import { ICON_BUTTON_HEIGHT, ICON_BUTTON_WIDTH } from '../../constants';

const styles: IStyles = {
  container: {
    width: ICON_BUTTON_WIDTH,
    display: 'flex',
    alignItems: 'center',
  },
  arrow: {
    backgroundColor: grey[100],
    borderRadius: '22px',
    height: ICON_BUTTON_HEIGHT,
    width: ICON_BUTTON_WIDTH,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'action.active',
  },
};
export default styles;
