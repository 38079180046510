import { DEFAULT_TIME_RANGE_OPTION_ENGLISH } from './constants';

import type { View } from '../types/user';
import { getValidDate } from '../utils/date';
import { getTimeRangeSemanticName } from '../utils/mappers';

const isCustomTimerange = (timeRange: string) => {
  if (typeof timeRange === 'string') {
    const regexp = new RegExp('[\\d.]+', 'g');
    const matchDateRange = timeRange.match(regexp);
    return matchDateRange?.length === 2;
  }
  return false;
};

export const getDefaultValueForTimeRange = (view: View, timeRange: string) => {
  if (timeRange && isCustomTimerange(timeRange)) {
    const [start, end] = timeRange.split(' - ');
    return `${start} - ${end}`;
  }
  if (view?.filters?.timerange) {
    const start = getValidDate(view.filters.timerange.start ?? 0);
    const end = getValidDate(view.filters.timerange.end ?? 0);
    return `${start} - ${end}`;
  } else if (timeRange) {
    return timeRange;
  } else if (view?.filters?.relative_timerange) {
    return getTimeRangeSemanticName(view.filters.relative_timerange);
  } else if (view?.filters?.preset_timerange) {
    return getTimeRangeSemanticName(JSON.stringify(view.filters.preset_timerange));
  } else {
    return DEFAULT_TIME_RANGE_OPTION_ENGLISH;
  }
};
