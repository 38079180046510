import { FormattedMessage } from 'react-intl';

import { absentValues } from '@trustyou/shared';

export const getNumberOfEntitiesValue = (
  number_of_consumed_entities: number,
  number_of_allowed_entities: number
) => {
  if (number_of_allowed_entities === 0 && number_of_consumed_entities === 0)
    return <FormattedMessage {...absentValues.notApplicable} />;
  return `${number_of_consumed_entities}/${number_of_allowed_entities}`;
};
