import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { FormControl, InputLabel, Stack } from '@trustyou/ui';

import { useUnregisterUnmountedQuestion } from '../../../../../service/hooks/use-unregister-unmounted-question';
import { QUESTION_TYPES_BY_CATEGORY } from '../../../../feedback/constants';
import { CustomTextFieldWithSeparatedLabel } from '../../../../shared';

export function QuestionEditorTextType() {
  const { register, setValue, watch } = useFormContext();

  useUnregisterUnmountedQuestion({
    questionTypes: QUESTION_TYPES_BY_CATEGORY.text,
    fieldsToUnregister: ['textMaxCharacters', 'text'],
  });

  useEffect(() => {
    if (watch('questionTypeVariation') === 'short_text') {
      setValue('textMaxCharacters', 400);
    } else if (watch('questionTypeVariation') === 'long_text') {
      setValue('textMaxCharacters', 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('questionTypeVariation')]);

  return (
    <Stack spacing={2}>
      {(watch('questionTypeVariation') === 'short_text' ||
        watch('questionTypeVariation') === 'long_text') && (
        <FormControl variant="standard" fullWidth>
          <InputLabel shrink>
            <FormattedMessage id="survey.common.max-char" defaultMessage="Maximum characters" />
          </InputLabel>
          <CustomTextFieldWithSeparatedLabel
            size="small"
            sx={{ width: 100 }}
            {...register('textMaxCharacters')}
          />
        </FormControl>
      )}
    </Stack>
  );
}
