import {
  ADD_COMPETITORS_PERMISSION,
  isTemporaryHidden,
  usePermissionStore,
} from '@trustyou/shared';

const useShowCompetitors = () => {
  const { hasPermissions } = usePermissionStore();

  const isSetCompetitorsAllowed = hasPermissions(ADD_COMPETITORS_PERMISSION);
  const isCompetitorsEnabled = !isTemporaryHidden('CXP-customers-competitors');

  return isSetCompetitorsAllowed && isCompetitorsEnabled;
};

export default useShowCompetitors;
