import { FormattedMessage } from 'react-intl';

import { type SelectionMode, useScopeStore } from '@trustyou/shared';
import { Alert, Stack, SubscriptionEntitySelector, Typography } from '@trustyou/ui';

type SubscriptionEntitySelectionProps = {
  subscriptionId: string;
  validated?: boolean;
  mode?: SelectionMode;
};

export const SubscriptionEntitySelection = ({
  subscriptionId,
  validated,
  mode,
}: SubscriptionEntitySelectionProps) => {
  const { selectedEntities } = useScopeStore();

  const isEmptySelection = selectedEntities.length === 0;

  return (
    <Stack gap={2}>
      <Typography variant="body1">
        <FormattedMessage
          id="organization.users.whichSubscriptionEntitiesCanThisMemberAccess"
          defaultMessage="Which subscription entities can this member access?"
        />
      </Typography>
      <SubscriptionEntitySelector
        subscriptionId={subscriptionId}
        initialSelectedEntities={selectedEntities}
        mode={mode}
      />
      {validated && isEmptySelection && (
        <Alert severity="error">
          <FormattedMessage
            id="organization.users.selectAtLeastOneEntity"
            defaultMessage="Select at least one entity"
          />
        </Alert>
      )}
    </Stack>
  );
};
