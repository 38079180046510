import React, { useEffect, useState } from 'react';

import { isString } from 'lodash';

import { ReviewsModal } from '../components/ReviewsModal';
import { SISENSE_SERVER_PATH } from '@trustyou/shared';

type InputData = {
  categoryName: string;
  categoryType: string;
  categoryId: string;
  reviewIds: string[];
  impactScore?: string;
};

export const Reviews = () => {
  const [inputData, setInputData] = useState<InputData | undefined>();

  const messageEventHandler = (event: MessageEvent) => {
    if (
      event.origin === SISENSE_SERVER_PATH &&
      isString(event.data) &&
      event.data.includes('show-review-ids')
    ) {
      const inputData = JSON.parse(event.data);
      setInputData(inputData);
    }
  };

  useEffect(() => {
    window.addEventListener('message', messageEventHandler);
    return () => {
      window.removeEventListener('message', messageEventHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = () => {
    setInputData(undefined);
  };

  if (!inputData) return null;

  return <ReviewsModal {...inputData} onClose={onClose} />;
};
