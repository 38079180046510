import { useShortLocale } from './use-short-locale';

import type { Content } from '../types/content';

function getContentText(row: Content, nestedObj: Record<string, string>, shortLocale: string) {
  return nestedObj[shortLocale] ?? nestedObj[row.default_language ?? 'en'];
}

export function useTextContent(row: Content) {
  const shortLocale = useShortLocale();
  const isEmailInvitation = row.type === 'email_invitation';

  let title = row.title?.[shortLocale] ?? row.title?.[row.default_language ?? 'en'];
  let description =
    row.description?.[shortLocale] ?? row.description?.[row.default_language ?? 'en'];

  let secondTitle;
  let secondDescription;

  if (isEmailInvitation) {
    // email invitation
    title = getContentText(row, row.invitation_email.subject, shortLocale);
    const body = getContentText(row, row.invitation_email.body, shortLocale);
    const footer = getContentText(row, row.invitation_email.footer ?? {}, shortLocale);
    description = `<p>${body}</p><p>${footer}</p>`;

    // reminder email
    secondTitle = getContentText(row, row.reminder_email?.subject ?? {}, shortLocale);
    const reminderEmailBody = getContentText(row, row.reminder_email?.body ?? {}, shortLocale);
    const reminderEmailFooter = getContentText(row, row.reminder_email?.footer ?? {}, shortLocale);
    secondDescription = `<p>${reminderEmailBody}</p><p>${reminderEmailFooter}</p>`;
  }

  return {
    title,
    description,
    secondTitle,
    secondDescription,
    isEmailInvitation,
  };
}
