import { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { actionMessages } from '@trustyou/shared';
import { Divider, InfoDialog, Stack, Typography } from '@trustyou/ui';

import { common, info } from '../../constants/messages/customAttributes';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const CustomAttributesInfoDialog = ({ open, onClose }: Props) => {
  const intl = useIntl();
  const ATTRIBUTE_DATA = [
    {
      type: intl.formatMessage(common.freeTextType),
      description: intl.formatMessage(info.freeTextTypeDescription),
      exampleName: 'Hotel code',
      exampleValues: ['HT-1234'],
    },
    {
      type: intl.formatMessage(common.singleSelectType),
      description: intl.formatMessage(info.singleSelectTypeDescription),
      exampleName: 'Star rating',
      exampleValues: ['5'],
    },
    {
      type: intl.formatMessage(common.multiSelectType),
      description: intl.formatMessage(info.multiSelectTypeDescription),
      exampleName: 'Service facilities',
      exampleValues: ['Pool', 'Spa', 'Gym'],
    },
  ];

  return (
    <InfoDialog
      title={<FormattedMessage {...info.infoModalHeader} />}
      open={open}
      primaryActionText={<FormattedMessage {...actionMessages.okay} />}
      onPrimaryAction={onClose}
      dataName="custom-attributes"
      maxWidth="md"
      showHeaderCloseButton
    >
      <Typography variant="body1" padding={3}>
        <FormattedMessage {...info.description} />
      </Typography>
      <Stack gap={3} direction="row" paddingX={3} paddingBottom={1}>
        <Typography variant="subtitle1" minWidth="600px">
          <FormattedMessage {...info.infoModalAttributeTypeHeader} />
        </Typography>
        <Typography variant="subtitle1">
          <FormattedMessage {...info.infoModalAttributeTypeExample} />
        </Typography>
      </Stack>
      {ATTRIBUTE_DATA.map((row) => (
        <Fragment key={row.type}>
          <Divider />
          <Stack gap={3} direction="row" padding={3}>
            <Stack width="600px">
              <Typography variant="subtitle1">{row.type}</Typography>
              <Typography variant="body1">{row.description}</Typography>
            </Stack>
            <Stack gap={0.5}>
              <Typography variant="subtitle2">{row.exampleName}</Typography>
              {row.exampleValues.map((value) => (
                <Typography key={value} variant="body2">
                  {value}
                </Typography>
              ))}
            </Stack>
          </Stack>
        </Fragment>
      ))}
    </InfoDialog>
  );
};
