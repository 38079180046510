import { FormattedMessage } from 'react-intl';

import type { SubmitButtonProps } from '../submit-region';
import { SubmitButton } from './submit-button';

export function PrivateSurveyRespondButton({ onSubmit, isDisabled }: SubmitButtonProps) {
  return (
    <SubmitButton onClick={onSubmit} data-gtm-id="survey_response" disabled={isDisabled}>
      <FormattedMessage id="inbox.response.action.send-email" defaultMessage="Send email" />
    </SubmitButton>
  );
}
