import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import type { AlertProps } from '@mui/material';
import { create } from 'zustand';

type AlertObjectType = {
  success: (content?: ReactNode, title?: ReactNode) => void;
  info: (content?: ReactNode, title?: ReactNode) => void;
  warning: (content?: ReactNode, title?: ReactNode) => void;
  error: (content?: ReactNode, title?: ReactNode) => void;
  genericError: () => void;
};

type AlertState = {
  severity: AlertProps['severity'];
  title: ReactNode;
  content: ReactNode;
  alert: AlertObjectType;
  closeSnackbar: () => void;
};

function GenericErrorMessage() {
  return (
    <FormattedMessage
      id="alert.default.error"
      defaultMessage="Something went wrong. Please try again."
    />
  );
}

export const useAlertStore = create<AlertState>((set, get) => ({
  severity: 'info',
  title: '',
  content: '',
  alert: {
    success: (content, title) => set({ severity: 'success', content, title }),
    info: (content, title) => set({ severity: 'info', content, title }),
    warning: (content, title) => set({ severity: 'warning', content, title }),
    error: (content, title) => set({ severity: 'error', content, title }),
    genericError: () => get().alert.error(<GenericErrorMessage />),
  },
  closeSnackbar: () => set({ content: '', title: '' }),
}));
