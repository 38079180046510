import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { styled } from '@mui/material';
import { useAlertStore } from '@trustyou/shared';
import { Box, CircularProgress, FormControlLabel, Radio, Stack, TextField } from '@trustyou/ui';

import { ResponseFieldActionWrapper } from './response-field-action-wrapper';
import { ResponseRating } from './response-rating';

import { type LanguageSource, useLanguage } from '../../../hooks';
import { useStore } from '../../../store/store';
import type { ResponseFormSchema, ResponseTranslationOption } from '../../../types';
import { DropDownChip, type Option } from '../../dropdowns/dropdown-chip';

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '2px',
    },
  },
});

type ResponseTextFieldId = 'response' | 'translatedResponse';

type ResponseTextFieldProps = {
  id: ResponseTextFieldId;
  defaultLanguage?: LanguageSource;
  translationLanguage?: LanguageSource;
  translationTarget?: ResponseTranslationOption;
  radioValue?: ResponseTranslationOption;
  onChangeSelectedTextbox?: (value: ResponseTranslationOption) => void;
  onChangeLanguage?: (newLanguage: Option, textFieldId: ResponseTranslationOption) => void;
};

export function ResponseTextField({
  id,
  defaultLanguage,
  translationLanguage,
  translationTarget,
  radioValue,
  onChangeSelectedTextbox,
  onChangeLanguage,
}: ResponseTextFieldProps) {
  const intl = useIntl();
  const { alert } = useAlertStore();
  const { setValue, getValues, setFocus, control } = useFormContext<ResponseFormSchema>();
  const { getLanguageByLanguageSource, languages } = useLanguage();
  const hasGeneratedResponse = useStore.use.hasGeneratedResponse();

  const onEdit = (id: ResponseTextFieldId) => {
    onChangeSelectedTextbox?.(id === 'response' ? 'original' : 'translated');
    // FIXME: The timeout makes `setFocus` work because the text field needs to change first from disabled to enabled.
    setTimeout(() => setFocus?.(id), 0);
  };

  const onCopy = (id: ResponseTextFieldId) => {
    navigator.clipboard.writeText(getValues?.(id) as string);
    alert.success(
      intl.formatMessage({
        id: 'inbox.response.copy.successful',
        defaultMessage: 'Copied to clipboard',
      })
    );
  };

  const renderLanguageDropdown = (id: ResponseTextFieldId, isLoading?: boolean) => {
    const currentLanguage = id === 'response' ? defaultLanguage : translationLanguage;
    const currentOption: Option = getLanguageByLanguageSource(currentLanguage) ?? {
      label: '',
      value: '',
    };

    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <DropDownChip
          title={id}
          isDisabled={!!translationTarget}
          currentValue={currentOption}
          options={languages ?? []}
          onChange={(value) =>
            onChangeLanguage?.(value, id === 'response' ? 'original' : 'translated')
          }
        />
        {isLoading && <CircularProgress size={16} />}
      </Stack>
    );
  };

  const translationOptionMap: Record<'response' | 'translatedResponse', ResponseTranslationOption> =
    {
      response: 'original',
      translatedResponse: 'translated',
    };

  const isLoading = translationOptionMap[id] === translationTarget;
  const isActive = translationOptionMap[id] === radioValue;

  return (
    <Stack spacing={0.5}>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          justifyItems: 'center',
          paddingInlineEnd: 4.5,
        }}
      >
        {hasGeneratedResponse &&
          (translationLanguage ? (
            <FormControlLabel
              value={id === 'response' ? 'original' : 'translated'}
              control={
                <Radio
                  size="small"
                  disabled={!!translationTarget}
                  checked={isActive}
                  data-testid={`${id}-radio`}
                />
              }
              label={renderLanguageDropdown(id, isLoading)}
            />
          ) : (
            renderLanguageDropdown(id, isLoading)
          ))}
        {hasGeneratedResponse && id === 'response' && <ResponseRating />}
      </Stack>
      <ResponseFieldActionWrapper
        onCopy={() => onCopy(id)}
        onEdit={() => onEdit(id)}
        onDelete={() => setValue(id, '', { shouldDirty: false })}
        isDisabled={!!translationTarget}
      >
        <Box
          style={{ display: 'inline-block', position: 'relative', width: '100%' }}
          onClick={() => {
            !translationTarget && !isActive && onEdit(id);
          }}
        >
          <Controller
            name={id}
            control={control}
            render={({ field }) => (
              <StyledTextField
                {...field}
                placeholder={intl.formatMessage({
                  id: 'inbox.response.placeholder',
                  defaultMessage: 'Type your response here',
                })}
                inputProps={{ 'data-testid': `${id}-textarea` }}
                disabled={!isActive || !!translationTarget}
                fullWidth
                multiline
                minRows={4}
              />
            )}
          />
          {!isActive && (
            // Disabled text fields block onclick events (in this case also the one of the parent).
            // To solve the problem, this hidden box lays on top of the disabled field.
            <Box style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 }} />
          )}
        </Box>
      </ResponseFieldActionWrapper>
    </Stack>
  );
}
