import { FormattedMessage } from 'react-intl';

import { faUser } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  type CombinedUserAndInvitation,
  type CurrentUser,
  USER_ACTIONS,
  USER_STATUSES,
  getUserRoleDefaultMessage,
  manageUserActions,
} from '@trustyou/shared';
import {
  Box,
  Button,
  ButtonGroup,
  StyledFontAwesomeIcon as Icon,
  Typography,
  UserInfo,
} from '@trustyou/ui';

import { ScopeDetails } from './ScopeDetails';

import styles from './styles';

export interface UserDetailsViewProps {
  user: CombinedUserAndInvitation;
  isManageUserAllowed: boolean;
  handleEditUser?: (user: CombinedUserAndInvitation) => void;
  handleDeleteUser?: (user: CombinedUserAndInvitation) => void;
  currentUser?: CurrentUser;
}

export const UserDetailsView = ({
  user,
  handleEditUser,
  handleDeleteUser,
  currentUser,
  isManageUserAllowed,
}: UserDetailsViewProps) => {
  const availableAction = manageUserActions(user, isManageUserAllowed, currentUser);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.userInfoWrapper}>
        <UserInfo
          name={user.name}
          email={user.email}
          isInvited={user.status === USER_STATUSES.INVITED}
        />
        <Box sx={styles.role}>
          <Icon icon={faUser} />
          <Typography variant="body2">
            <FormattedMessage
              id={user.role}
              defaultMessage={getUserRoleDefaultMessage(user.role)}
            />
          </Typography>
        </Box>
        <ScopeDetails scope={user.scope} />
      </Box>
      {availableAction === USER_ACTIONS.EDIT_AND_DELETE && (handleEditUser || handleDeleteUser) && (
        <Box sx={styles.actions}>
          <ButtonGroup variant="outlined">
            {handleDeleteUser && (
              <Button data-testid="remove_user" onClick={() => handleDeleteUser(user)}>
                <FormattedMessage id="tyDesign.user.removeUser" defaultMessage="Remove" />
              </Button>
            )}
            {handleEditUser && (
              <Button data-testid="edit_user" onClick={() => handleEditUser(user)}>
                <FormattedMessage id="tyDesign.user.editUser" defaultMessage="Edit" />
              </Button>
            )}
          </ButtonGroup>
        </Box>
      )}
    </Box>
  );
};
