import { grey } from '@mui/material/colors';
import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  container: {
    paddingX: 3,
    borderBottom: '1px solid',
    borderColor: grey[300],
    display: 'flex',
    flexDirection: 'row',
    gap: 1,
  },
  avatarContainer: { pt: 2, gap: 1, display: 'flex', flexDirection: 'column' },
  body: {
    paddingY: 2,
    paddingX: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  reviewText: { mb: 1 },
  secondaryText: { color: 'text.secondary' },
  translateButton: {
    width: 'fit-content',
    left: '-8px',
  },
};

export default styles;
