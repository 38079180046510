import { FormattedMessage, useIntl } from 'react-intl';

import { faTrash } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { faUserTie } from '@trustyou/fortawesome/pro-solid-svg-icons';

import { commonMessages } from '../../constants/messages';
import type { SpecificStampProps } from '../../types';
import { Stamp } from './stamp';
import { StampPair } from './stamp-pair';

export function DeletedStampPair({ date, author }: SpecificStampProps) {
  const intl = useIntl();

  return (
    <StampPair>
      <Stamp
        status={
          <FormattedMessage
            id="inbox.response.status.date.deleted-at"
            defaultMessage="Deleted: {deletionConfirmedDate}"
            values={{ deletionConfirmedDate: date }}
          />
        }
        icon={faTrash}
      />
      <Stamp
        status={intl.formatMessage(commonMessages.byUsername, { username: author })}
        icon={faUserTie}
      />
    </StampPair>
  );
}
