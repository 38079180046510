import type { ReactNode } from 'react';

import { Stack, Typography } from '@trustyou/ui';

type InputLabelProps = {
  children: ReactNode;
  required?: boolean;
};

// TODO: Use this in reporting module drawer.
export const FormFieldLabel = ({ children, required }: InputLabelProps) => (
  <Stack direction="row" gap={0.5} marginBottom={1}>
    <Typography variant="body2" color="text.secondary">
      {children}
    </Typography>
    {required && (
      <Typography variant="body2" color="error.main">
        *
      </Typography>
    )}
  </Stack>
);
