import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  header: {
    id: 'analytics.info-modal.header',
    defaultMessage: 'What does “Change vs. the previous period” mean?',
  },
  changeVsThePreviousPeriod: {
    id: 'analytics.info-modal.change-vs-the-previous-period',
    defaultMessage: 'Change vs. the previous period',
  },
  changeVsThePreviousPeriodDescription: {
    id: 'analytics.info-modal.change-vs-the-previous-period.description',
    defaultMessage: 'refers to the period immediately preceding the selected timeframe',
  },
  changeVsTheSamePeriodLastYear: {
    id: 'analytics.info-modal.change-vs-the-same-period-last-year',
    defaultMessage: 'Change vs. the same period last year',
  },
  changeVsTheSamePeriodLastYearDescription: {
    id: 'analytics.info-modal.change-vs-the-same-period-last-year.description',
    defaultMessage: 'denotes the corresponding timeframe from the previous year',
  },
  selectedPeriod: {
    id: 'analytics.info-modal.selected-period',
    defaultMessage: 'Selected period',
  },
  februaryWithYear: {
    id: 'analytics.info-modal.february-with-year',
    defaultMessage: 'February {year}',
  },
  previousPeriod: {
    id: 'analytics.info-modal.previous-period',
    defaultMessage: 'Previous period',
  },
  januaryWithYear: {
    id: 'analytics.info-modal.january-with-year',
    defaultMessage: 'January {year}',
  },
  samePeriodLastYear: {
    id: 'analytics.info-modal.same-period-last-year',
    defaultMessage: 'Same period last year',
  },
  januaryWithPeriod: {
    id: 'analytics.info-modal.january-with-period',
    defaultMessage: 'January {period}',
  },
  februaryWithPeriod: {
    id: 'analytics.info-modal.february-with-period',
    defaultMessage: 'February {period}',
  },
});
