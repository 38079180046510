import type { ReactNode } from 'react';

import { Box, Button, Typography } from '@trustyou/ui';

import styles from './EmptyStatePlaceholder.styles';

import type { StyleUnit } from '../../types/common';

export interface EmptyStateAction {
  testId: string;
  startIcon?: ReactNode;
  message: ReactNode;
  handler: () => void;
  styles?: StyleUnit;
}

export interface EmptyStatePlaceholderProps {
  img?: string;
  title?: ReactNode;
  description?: ReactNode;
  children?: ReactNode;
  emptyStateContainer?: StyleUnit;
  emptyStateHeader?: StyleUnit;
  emptyStateBody?: StyleUnit;
  action?: EmptyStateAction;
  dataName?: string;
}

export const EmptyStatePlaceholder = ({
  img,
  title,
  description,
  children,
  emptyStateContainer,
  emptyStateHeader,
  emptyStateBody,
  action,
  dataName,
}: EmptyStatePlaceholderProps) => (
  <Box
    data-testid={`empty-state-${dataName}`}
    sx={{ ...styles.basicContainer, ...emptyStateContainer }}
  >
    {img && <img src={img} alt="No Data" />}
    {title && (
      <Typography variant="h6" sx={emptyStateHeader}>
        {title}
      </Typography>
    )}
    {description && (
      <Typography variant="body2" sx={emptyStateBody}>
        {description}
      </Typography>
    )}
    {action && (
      <Button
        onClick={action.handler}
        data-testid={action.testId}
        variant="contained"
        startIcon={action.startIcon}
        sx={action.styles}
      >
        {action.message}
      </Button>
    )}
    {children}
  </Box>
);
