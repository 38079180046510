/**
 * Do not edit directly
 * Generated on Thu, 29 Jun 2023 11:22:25 GMT
 */

export const avatarsIndigo = '#7986CB';
export const avatarsDeepPurple = '#9575CD';
export const avatarsAmber = '#FFC107';
export const avatarsOrange = '#FF9800';
export const avatarsPink = '#F06292';
export const avatarsPurple = '#BA68C8';
export const avatarsLime = '#C0CA33';
export const avatarsLightBlue = '#4FC3F7';
export const avatarsCyan = '#4DD0E1';
export const avatarsTeal = '#4DB6AC';
export const avatarsBlue = '#64B5F6';
export const avatarsDefault = '#90A4AE';
