/**
 * Do not edit directly
 * Generated on Tue, 09 Apr 2024 13:54:04 GMT
 */

export const colorPrimaryMain = "#0057B2";
export const colorPrimaryDark = "#0D47A1";
export const colorPrimaryLight = "#448AFF";
export const colorPrimaryContrast = "#FFFFFF";
export const colorSecondaryMain = "#039BE5";
export const colorSecondaryDark = "#0277BD";
export const colorSecondaryLight = "#29B6F6";
export const colorSecondaryContrast = "#FFFFFF";
export const colorErrorMain = "#E53935";
export const colorErrorDark = "#C62828";
export const colorErrorLight = "#EF5350";
export const colorErrorContrast = "#FFFFFF";
export const colorWarningMain = "#EF6C00";
export const colorWarningDark = "#E65100";
export const colorWarningLight = "#FFA726";
export const colorWarningContrast = "#FFFFFF";
export const colorInfoMain = "#0288D1";
export const colorInfoDark = "#01579B";
export const colorInfoLight = "#03A9F4";
export const colorInfoContrast = "#FFFFFF";
export const colorSuccessMain = "#388E3C";
export const colorSuccessDark = "#1B5E20";
export const colorSuccessLight = "#4CAF50";
export const colorSuccessContrast = "#FFFFFF";
export const colorTextPrimary = "#212121";
export const colorTextSecondary = "#616161";
export const colorTextDisabled = "#9E9E9E";
export const colorTextHint = "#757575";
export const colorDivider = "#E0E0E0";
export const colorBackgroundDefault = "#F5F5F5";
export const colorBackgroundPaper = "#FFFFFF";
export const borderRadiusShape4 = "4*1";
export const borderRadiusShape8 = "4 *2";
export const borderRadiusShapeBase = "4";
export const borderRadiusShapeHalf = "50%";
export const spacingSpacing2 = "8 * 0.25";
export const spacingSpacing4 = "8 * 0.5";
export const spacingSpacing8 = "8 * 1";
export const spacingSpacing12 = "8 * 1.5";
export const spacingSpacing16 = "8 * 2";
export const spacingSpacing24 = "8 * 3";
export const spacingSpacing32 = "8 * 4";
export const spacingSpacing40 = "8 * 5";
export const spacingSpacing80 = "8 * 10";
export const spacingSpacingBase = "8";
export const breakpointBreakpointXs = "0";
export const breakpointBreakpointSm = "600";
export const breakpointBreakpointMd = "900";
export const breakpointBreakpointLg = "1200";
export const breakpointBreakpointXl = "1536";
export const boxShadowElevation1 = "0px 1px 3px 0px #2121211F,0px 1px 1px 0px #21212124,0px 2px 1px -1px #21212133";
export const boxShadowElevation2 = "0px 1px 5px 0px #6161611F,0px 2px 2px 0px #61616124,0px 3px 1px -2px #61616133";
export const boxShadowElevation3 = "0px 1px 8px 0px #6161611F,0px 3px 4px 0px #61616124,0px 3px 3px -2px #61616133";
export const boxShadowElevation4 = "0px 1px 10px 0px #6161611F,0px 4px 5px 0px #61616124,0px 2px 4px -1px #61616133";
export const boxShadowElevation5 = "0px 1px 14px 0px #6161611F,0px 5px 8px 0px #61616124,0px 3px 5px -1px #61616133";
export const boxShadowElevation6 = "0px 1px 18px 0px #6161611F,0px 6px 10px 0px #61616124,0px 3px 5px -1px #61616133";
export const boxShadowElevation7 = "0px 2px 16px 1px #6161611F,0px 7px 10px 1px #61616124,0px 4px 5px -2px #61616133";
export const boxShadowElevation8 = "0px 3px 14px 2px #6161611F,0px 8px 10px 1px #61616124,0px 5px 5px -3px #61616133";
export const boxShadowElevation9 = "0px 3px 16px 2px #6161611F,0px 9px 12px 1px #61616124,0px 5px 6px -3px #61616133";
export const boxShadowElevation10 = "0px 4px 18px 3px #6161611F,0px 10px 14px 1px #61616124,0px 6px 6px -3px #61616133";
export const boxShadowElevation11 = "0px 4px 20px 3px #6161611F,0px 11px 15px 1px #61616124,0px 6px 7px -4px #61616133";
export const boxShadowElevation12 = "0px 5px 22px 4px #6161611F,0px 12px 17px 2px #61616124,0px 7px 8px -4px #61616133";
export const boxShadowElevation13 = "0px 5px 24px 4px #6161611F,0px 13px 19px 2px #61616124,0px 7px 8px -4px #61616133";
export const boxShadowElevation14 = "0px 5px 26px 4px #6161611F,0px 14px 21px 2px #61616124,0px 7px 9px -4px #61616133";
export const boxShadowElevation15 = "0px 6px 28px 5px #6161611F,0px 15px 22px 2px #61616124,0px 8px 9px -5px #61616133";
export const boxShadowElevation16 = "0px 6px 30px 5px #6161611F,0px 16px 24px 2px #61616124,0px 8px 10px -5px #61616133";
export const boxShadowElevation17 = "0px 6px 32px 5px #6161611F,0px 17px 26px 2px #61616124,0px 8px 11px -5px #61616133";
export const boxShadowElevation18 = "0px 7px 34px 6px #6161611F,0px 18px 28px 2px #61616124,0px 9px 11px -5px #61616133";
export const boxShadowElevation19 = "0px 7px 36px 6px #6161611F,0px 19px 29px 2px #61616124,0px 9px 12px -6px #61616133";
export const boxShadowElevation20 = "0px 8px 38px 7px #6161611F,0px 20px 31px 3px #61616124,0px 10px 13px -6px #61616133";
export const boxShadowElevation21 = "0px 8px 40px 7px #6161611F,0px 21px 33px 3px #61616124,0px 10px 13px -6px #61616133";
export const boxShadowElevation22 = "0px 8px 42px 7px #6161611F,0px 22px 35px 3px #61616124,0px 10px 14px -6px #61616133";
export const boxShadowElevation23 = "0px 9px 44px 8px #6161611F,0px 23px 36px 3px #61616124,0px 11px 14px -7px #61616133";
export const boxShadowElevation24 = "0px 9px 46px 8px #6161611F,0px 24px 38px 3px #61616124,0px 11px 15px -7px #61616133";
export const boxShadowElevationOutlined = {"color":"#E0E0E0","type":"dropShadow","x":"0","y":"0","blur":"0","spread":"1"};
export const typographyTypographySubtitle1 = {"fontWeight":500};
