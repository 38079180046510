import type { ReactNode } from 'react';

import { Stack } from '@trustyou/ui';

type TabActionProps = {
  left?: ReactNode;
  right: ReactNode;
};

export const TabActions = ({ left, right }: TabActionProps) => {
  return (
    <Stack
      direction="row"
      sx={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        zIndex: 10,
        width: '100%',
        justifyContent: 'end',
        padding: 1,
        backgroundColor: 'background.paper',
        boxShadow: 5,
      }}
    >
      {left && (
        <Stack direction="row" gap={1} flex={1} justifyContent="start">
          {left}
        </Stack>
      )}
      <Stack direction="row" gap={1} flex={1} justifyContent="end">
        {right}
      </Stack>
    </Stack>
  );
};
