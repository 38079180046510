import { useState } from 'react';
import { Controller, useController } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { Asterisk, FormControl, InputLabel, Stack } from '@trustyou/ui';
import { isInteger } from 'lodash';

import { CustomTextFieldWithSeparatedLabel } from '../../../shared';
import { CountrySelect } from '../../components/country-select';
import { Question, type QuestionProps } from '../../components/question';

export type TextQuestionVariant = 'short_text' | 'long_text' | 'email' | 'phone';

export type TextQuestionProps = QuestionProps & {
  variant?: TextQuestionVariant;
  minCharacters?: number;
  maxCharacters?: number;
  initialValue?: string;
  label?: React.ReactNode;
  showMandatoryAsteriskForLabel?: boolean;
};

export function TextQuestion({
  name,
  control,
  variant = 'short_text',
  minCharacters = 0,
  maxCharacters = 255,
  label,
  showMandatoryAsteriskForLabel,
  ...props
}: TextQuestionProps) {
  const intl = useIntl();
  const {
    field,
    fieldState: { error },
  } = useController({ name, control });
  const [value, setValue] = useState(field.value ?? '');

  const errorMessage = error?.message ?? '';
  const remainingCharacterCount = maxCharacters - value.length;

  const isShortOrLongText = ['short_text', 'long_text'].includes(variant);
  const isEmail = variant === 'email';
  const isPhone = variant === 'phone';

  const isPhoneError = isPhone && !isInteger(Number(value.replaceAll(' ', '')));
  const isError = !!errorMessage || isPhoneError;

  const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
    event
  ) => {
    field.onChange(event.target.value);
    setValue(event.target.value);
  };

  const getHelperText = () => {
    if (value.length === 0) {
      return errorMessage ?? '';
    }
    if (isShortOrLongText) {
      return intl.formatMessage(
        {
          id: 'survey.question.text.short-or-long.helper-text',
          defaultMessage: 'Remaining characters: {remainingCharacterCount}',
        },
        { remainingCharacterCount }
      );
    }
    if (isEmail && label) {
      if (isError) {
        return intl.formatMessage({
          id: 'survey.question.text.email-error.helper-text',
          defaultMessage: 'Enter a valid email address',
        });
      } else {
        return errorMessage ?? '';
      }
    }
  };

  return (
    <Question name={name} {...props}>
      <Stack direction="row" spacing={2}>
        {isPhone && <CountrySelect label={label} />}
        <FormControl variant="standard" fullWidth>
          {label && (
            <InputLabel shrink>
              {!isPhone && label}
              {showMandatoryAsteriskForLabel && <Asterisk />}
            </InputLabel>
          )}
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <CustomTextFieldWithSeparatedLabel
                {...field}
                fullWidth
                multiline={isShortOrLongText}
                minRows={variant === 'long_text' ? 5 : 1}
                value={value}
                onChange={handleChange}
                helperText={getHelperText()}
                error={isError}
                FormHelperTextProps={{ sx: { marginInline: 0 } }}
              />
            )}
          />
        </FormControl>
      </Stack>
    </Question>
  );
}
