import type { ReactNode } from 'react';

import { Stack, Typography } from '@trustyou/ui';

type FormControlLabelProps = {
  children: ReactNode;
  required?: boolean;
};

export const FormControlLabel = ({ children, required }: FormControlLabelProps) => (
  <Stack direction="row" gap={0.5} marginBottom={1}>
    <Typography variant="body2" color="text.secondary">
      {children}
    </Typography>
    {required && (
      <Typography variant="body2" color="error.main">
        *
      </Typography>
    )}
  </Stack>
);
