import { blue, grey } from '@mui/material/colors';

import type { IStyles } from '../../types/common';

const styles: IStyles = {
  container: {
    border: `1px solid ${grey[300]}`,
    borderRadius: '10px',
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    flex: '1 1 0',
    minHeight: '200px',
    '&:hover': {
      cursor: 'pointer',
      border: `1px solid ${blue[700]}`,
    },
  },
  selectedContainer: {
    borderColor: 'primary.main',
  },
  disabledContainer: {
    borderColor: grey[300],
    '&:hover': {
      cursor: 'default',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  checkIcon: {
    color: 'text.secondary',
  },
  selectedCheckIcon: {
    color: 'primary.main',
  },
  disabledText: {
    color: 'text.disabled',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0.5,
  },
};

export default styles;
