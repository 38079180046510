import { FormattedMessage, useIntl } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faXmark } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { faUserMagnifyingGlass } from '@trustyou/fortawesome/pro-solid-svg-icons';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  StyledFontAwesomeIcon,
} from '@trustyou/ui';

import { GuestInformationTable } from './guest-information-table';

import type { GuestInfo } from '../../utils/guest-information';

export type GuestInformationProps = {
  data?: GuestInfo[];
  isOpen: boolean;
  onClose: () => void;
};

export const GuestInformationDialog = ({ data, isOpen, onClose }: GuestInformationProps) => {
  const intl = useIntl();

  const copyDetails = () => {
    if (!data) return;
    const naMsg = intl.formatMessage({
      id: 'inbox.not-available',
      defaultMessage: 'Not available',
    });
    const formattedDetails = data
      .map(({ label, value }) => {
        return `${label}\t${value ?? naMsg}`;
      })
      .join('\n');
    navigator.clipboard.writeText(formattedDetails);
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle>
        <FormattedMessage id="inbox.guest-info" defaultMessage="Guest info" />
        <IconButton
          aria-label="close guest info"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: (theme) => theme.spacing(2),
          }}
        >
          <StyledFontAwesomeIcon icon={faXmark} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {data?.length ? (
          <>
            <GuestInformationTable rows={data} />
            <Button
              startIcon={<FontAwesomeIcon icon={faCopy} />}
              sx={{ paddingX: 3, paddingY: 0.75 }}
              onClick={copyDetails}
            >
              <FormattedMessage id="inbox.guestInformation.copy" defaultMessage="Copy info" />
            </Button>
          </>
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              color: 'text.secondary',
              gap: 1.5,
            }}
          >
            <FontAwesomeIcon size="xl" icon={faUserMagnifyingGlass} />
            <Box sx={{ width: 225, textAlign: 'center' }}>
              <FormattedMessage
                id="inbox.guestInformation.noData"
                defaultMessage="We don’t have any info about this guest yet"
              ></FormattedMessage>
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          <FormattedMessage id="inbox.action.close" defaultMessage="Close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
