import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import type {
  CreateSegment,
  CreateSegmentResponse,
  UpdateDashboardSegments,
  UpdateSegment,
} from '../../../../types/model/segment';
import type { PaginationRequest } from '../../../../types/model/shared';
import {
  CREATE_SEGMENT,
  DELETE_SEGMENT,
  FETCH_INFINITE_SEGMENTS,
  FETCH_SEGMENTS,
  IS_VALID_SEGMENT,
  UPDATE_DASHBOARD_SEGMENTS,
  UPDATE_SEGMENT,
} from '../../../constants/queryKeys';
import {
  createSegment,
  deleteSegment,
  fetchSegments,
  isValidSegmentName,
  updateDashboardSegments,
  updateSegment,
} from '../../../queries';

export function useSegments(pagination: PaginationRequest, sortKey: string) {
  return useQuery({
    queryKey: [FETCH_SEGMENTS, { pagination, sortKey }],
    queryFn: () => fetchSegments(pagination, sortKey),
    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useInfiniteSegments(pageSize: number, sortKey: string) {
  return useInfiniteQuery({
    queryKey: [FETCH_INFINITE_SEGMENTS, { limit: pageSize, sortKey }],
    queryFn: ({ pageParam }) => fetchSegments({ offset: pageParam, limit: pageSize }, sortKey),
    initialPageParam: 0,
    refetchOnWindowFocus: false,
    enabled: true,
    getNextPageParam: (lastPage) => {
      const newOffset = lastPage.pagination.offset + pageSize;
      return newOffset < lastPage.pagination.total ? newOffset : undefined;
    },
  });
}

export function useDeleteSegment(
  onSuccess: (data: string) => void,
  onError?: (error: Error) => void,
  onSettled?: () => void
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [DELETE_SEGMENT],
    mutationFn: (segmentId: string) => deleteSegment(segmentId),
    onSuccess: (data: string) => {
      queryClient.refetchQueries({ queryKey: [FETCH_SEGMENTS] });
      onSuccess(data);
    },
    onError,
    onSettled,
  });
}

export function useCreateSegment(
  onSuccess: (data: CreateSegmentResponse) => void,
  onError?: (error: Error) => void,
  onSettled?: () => void
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [CREATE_SEGMENT],
    mutationFn: (segment: CreateSegment) => createSegment(segment),
    onSuccess: (data: CreateSegmentResponse) => {
      queryClient.refetchQueries({ queryKey: [FETCH_SEGMENTS] });
      onSuccess(data);
    },
    onError,
    onSettled,
  });
}

export function useUpdateSegment(
  onSuccess: (data: UpdateSegment) => void,
  onError?: (error: Error) => void,
  onSettled?: () => void
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [UPDATE_SEGMENT],
    mutationFn: (segment: UpdateSegment) => updateSegment(segment),
    onSuccess: (data: UpdateSegment) => {
      queryClient.refetchQueries({ queryKey: [FETCH_SEGMENTS] });
      onSuccess(data);
    },
    onError,
    onSettled,
  });
}

export function useValidSegmentName() {
  return useMutation({
    mutationKey: [IS_VALID_SEGMENT],
    mutationFn: (name: string) => isValidSegmentName(name),
  });
}

export function useUpdateDashboardSegments(
  onSuccess: () => void,
  onError?: (error: Error) => void,
  onSettled?: () => void
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [UPDATE_DASHBOARD_SEGMENTS],
    mutationFn: (segment: UpdateDashboardSegments) => updateDashboardSegments(segment),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [FETCH_SEGMENTS] });
      onSuccess();
    },
    onError,
    onSettled,
  });
}
