import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  container: {
    display: 'flex',
    mt: 2,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: 8,
  },
  infoSubHeader: {
    mt: 2,
  },
  hyperlink: {
    display: 'inline-block',
    cursor: 'pointer',
    borderBottom: '1px dotted',
    borderColor: 'primary.main',
  },
};

export default styles;
