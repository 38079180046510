import React, { useEffect, useState } from 'react';

import { ContentPane, Divider, FullScreenModal } from '@trustyou/ui';

import type { MOCK_REVIEWS } from './reviews.mock';
import { useIframeMessage } from './useIframeMessage';

export const ReviewsDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [reviews, setReviews] = useState<typeof MOCK_REVIEWS>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleIframeMessage = useIframeMessage();

  const messageEventHandler = (event: MessageEvent) => {
    if (event.data.type === 'OPEN_DIALOG') {
      console.info('POC message received', event);
      // handleIframeMessage(event.data);
      setReviews(event.data.payload.data);
      setIsOpen(true);
    }
  };

  useEffect(() => {
    window.addEventListener('message', messageEventHandler);
    return () => {
      window.removeEventListener('message', messageEventHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FullScreenModal open={isOpen} onClose={() => setIsOpen(false)}>
      <ContentPane>
        {reviews.map((review) => (
          <React.Fragment key={review.id}>
            <dl>
              <dt style={{ fontWeight: 'bold' }}>Review title</dt>
              <dd>{review.title}</dd>
            </dl>
            <dl>
              <dt style={{ fontWeight: 'bold' }}>Review text</dt>
              <dd>{review.text}</dd>
            </dl>
            <dl>
              <dt style={{ fontWeight: 'bold' }}>Semantic terms</dt>
              <dd>
                <ul>
                  {review.matches.map((match) => (
                    <li key={match}>{match}</li>
                  ))}
                </ul>
              </dd>
            </dl>
            <Divider />
          </React.Fragment>
        ))}
      </ContentPane>
    </FullScreenModal>
  );
};
