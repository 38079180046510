import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightBlue, lime, purple, teal } from '@mui/material/colors';
import {
  Avatar,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@trustyou/ui';

import { useStore } from '../../store/store';
import type { ListItem, Typology } from '../../types/question';
import type { HexColor } from '../../types/styling';

const avatarColors: Record<Typology, HexColor> = {
  Choice: lightBlue[300],
  Rating: purple[300],
  Text: purple[300],
  'Contact information': teal[300],
  Date: lime[300],
};

type QuestionTypologyListProps = {
  typology: Typology;
  items: ListItem[];
};

export function QuestionTypologyList({ typology, items }: QuestionTypologyListProps) {
  const selectedQuestionType = useStore.use.initialQuestionType();
  const setSelectedQuestionType = useStore.use.setInitialQuestionType();
  const setSelectedQuestionValue = useStore.use.setInitialQuestionValueItem();

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    itemText: string,
    item: ListItem
  ) => {
    setSelectedQuestionType(itemText);
    setSelectedQuestionValue(item);
  };

  return (
    <Stack>
      <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
        {typology}
      </Typography>
      <List aria-label="question typology list" dense>
        {items.map((item, index) => (
          <li key={index}>
            <ListItemButton
              selected={selectedQuestionType === item.text}
              onClick={(event) => handleListItemClick(event, item.text, item)}
              // disableGutters
              sx={{
                border: '1px solid transparent',
                borderRadius: 1,
                px: 0.5,
                '&.Mui-selected': {
                  borderColor: (theme) => theme.palette.primary.main,
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: (theme) => theme.spacing(5) }}>
                <Avatar
                  variant="rounded"
                  sx={{
                    height: (theme) => theme.spacing(4),
                    width: (theme) => theme.spacing(4),
                    backgroundColor: avatarColors[typology],
                  }}
                >
                  <FontAwesomeIcon icon={item.icon} />
                </Avatar>
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </li>
        ))}
      </List>
    </Stack>
  );
}
