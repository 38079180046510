import type { ReactNode } from 'react';

import type { Breakpoint } from '@mui/system';
import { faXmark } from '@trustyou/fortawesome/pro-regular-svg-icons';

import { styles } from './InfoDialog.styles';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '../../mui';
import { StyledFontAwesomeIcon } from '../StyledFontAwesomeIcon/StyledFontAwesomeIcon';

export interface InfoDialogProps {
  title: ReactNode;
  content?: ReactNode;
  open: boolean;
  primaryActionText: ReactNode;
  secondaryActionText?: ReactNode;
  onPrimaryAction: () => void;
  onSecondaryAction?: () => void;
  dataName: string;
  children?: ReactNode;
  maxWidth?: Breakpoint;
  showHeaderCloseButton?: boolean;
}

export const InfoDialog = ({
  title,
  open,
  onPrimaryAction,
  onSecondaryAction,
  primaryActionText,
  secondaryActionText,
  dataName,
  children,
  maxWidth,
  showHeaderCloseButton,
}: InfoDialogProps) => (
  <Dialog open={open} onClose={onSecondaryAction || onPrimaryAction} fullWidth maxWidth={maxWidth}>
    <DialogTitle sx={styles.title}>
      {title}
      {showHeaderCloseButton && (
        <IconButton onClick={onSecondaryAction || onPrimaryAction}>
          <StyledFontAwesomeIcon icon={faXmark} />
        </IconButton>
      )}
    </DialogTitle>
    <DialogContent sx={styles.content}>{children}</DialogContent>
    <DialogActions>
      {secondaryActionText && (
        <Button
          data-testid={`${dataName}-secondary-action`}
          onClick={onSecondaryAction}
          sx={styles.button}
          variant="text"
        >
          {secondaryActionText}
        </Button>
      )}
      <Button
        data-testid={`${dataName}-primary-action`}
        onClick={onPrimaryAction}
        sx={styles.button}
        variant="contained"
      >
        {primaryActionText}
      </Button>
    </DialogActions>
  </Dialog>
);
