import { defineMessages } from 'react-intl';

export const commonMessages = defineMessages({
  frequency: {
    id: 'reports.common.frequency',
    defaultMessage: 'Frequency',
  },
  type: {
    id: 'reports.common.type',
    defaultMessage: 'Type',
  },
  format: {
    id: 'reports.common.format',
    defaultMessage: 'Format',
  },
  filters: {
    id: 'reports.common.filters',
    defaultMessage: 'Filters',
  },
  sent: {
    id: 'reports.common.sent',
    defaultMessage: 'Sent',
  },
  lastSent: {
    id: 'reports.common.last-sent',
    defaultMessage: 'Last sent',
  },
  selectAll: {
    id: 'reports.common.select-all',
    defaultMessage: 'Select All',
  },
  name: {
    id: 'reports.common.name',
    defaultMessage: 'Name',
  },
  newReport: {
    id: 'reports.common.new-report',
    defaultMessage: 'New report',
  },
  frequencyTypeScheduled: {
    id: 'reports.frequency-type.scheduled',
    defaultMessage: 'Scheduled',
  },
  frequencyTypeOneTime: {
    id: 'reports.frequency-type.one-time',
    defaultMessage: 'One-time',
  },
  editReport: {
    id: 'reports.actions.edit-report',
    defaultMessage: 'Edit report',
  },
  deleteReport: {
    id: 'reports.actions.delete-report',
    defaultMessage: 'Delete report',
  },
  save: {
    id: 'reports.actions.save',
    defaultMessage: 'Save',
  },
  delete: {
    id: 'reports.actions.delete',
    defaultMessage: 'Delete',
  },
  deleteReportTitle: {
    id: 'reports.delete-report.title',
    defaultMessage: 'Delete report?',
  },
  deleteReportContent: {
    id: 'reports.delete-report.content',
    defaultMessage: 'You won’t be receiving it anymore.',
  },
  deleteReportSuccess: {
    id: 'reports.delete-report.success',
    defaultMessage: 'Report deleted',
  },
  reportNamePlaceHolder: {
    id: 'reports.report-name-form.place-holder',
    defaultMessage: 'Enter a name',
  },
  reportNameError: {
    id: 'reports.report-name-form.error',
    defaultMessage: 'Report name is required',
  },
  nameYourReport: {
    id: 'reports.report-name-form.name-your-report',
    defaultMessage: 'Name your report',
  },
  discardChangesTitle: {
    id: 'reports.discard-changes.title',
    defaultMessage: 'Discard changes?',
  },
  discard: {
    id: 'reports.discard-changes.discard',
    defaultMessage: 'Discard',
  },
  discardChangesContent: {
    id: 'reports.discard-changes.content',
    defaultMessage: 'You’ll lose changes made to your report if you leave this page',
  },
  discardChangesCancel: {
    id: 'reports.discard-changes.cancel',
    defaultMessage: 'Cancel & stay',
  },
  editReportSuccess: {
    id: 'reports.edit-report.success',
    defaultMessage: 'Changes saved',
  },
  keywords: {
    id: 'reports.keywords',
    defaultMessage: 'Keywords',
  },
  optional: {
    id: 'reports.optional',
    defaultMessage: 'Optional',
  },
  enterKeywords: {
    id: 'reports.enter-keywords',
    defaultMessage: 'Enter keywords',
  },
});

export const scheduledReportsEmptyStateMessages = defineMessages({
  title: {
    id: 'reports.scheduled.empty-state.title',
    defaultMessage: 'No reports yet',
  },
  description: {
    id: 'reports.scheduled.empty-state.description',
    defaultMessage: 'Click below to create your first one',
  },
});

export const onetimeReportsEmptyStateMessages = defineMessages({
  title: {
    id: 'reports.onetime.empty-state.title',
    defaultMessage: 'Create a report',
  },
  description: {
    id: 'reports.onetime.empty-state.description',
    defaultMessage:
      'Select widgets to create a custom comprehensive report. Once ready, your report will be sent directly to your email. ',
  },
});

export const newReportWizardSteps = defineMessages({
  1: {
    id: 'reports.new-report-wizard.settings',
    defaultMessage: 'Settings',
  },
  2: {
    id: 'reports.new-report-wizard.filters',
    defaultMessage: 'Filters',
  },
  3: {
    id: 'reports.new-report-wizard.details',
    defaultMessage: 'Details',
  },
  4: {
    id: 'reports.new-report-wizard.recipients',
    defaultMessage: 'Recipients',
  },
});

export const reportNameMessages = defineMessages({
  reviews: {
    id: 'reports.dashboard.reviews',
    defaultMessage: 'Reviews dashboard',
  },
  sentiment: {
    id: 'reports.dashboard.sentiment',
    defaultMessage: 'Sentiment dashboard',
  },
  competitors: {
    id: 'reports.dashboard.competitors',
    defaultMessage: 'Entity competitors dashboard',
  },
  benchmarks: {
    id: 'reports.dashboard.benchmarks',
    defaultMessage: 'Benchmarks dashboard',
  },
  surveyInsights: {
    id: 'reports.dashboard.survey-insights',
    defaultMessage: 'Survey insights',
  },
  reviewsInbox: {
    id: 'reports.inbox.reviews-inbox',
    defaultMessage: 'Reviews inbox',
  },
});

export const newReportWizardMessages = defineMessages({
  next: {
    id: 'reports.new-report-wizard.footer.next',
    defaultMessage: 'Next',
  },
  back: {
    id: 'reports.new-report-wizard.footer.back',
    defaultMessage: 'Back',
  },
  dashboard: {
    id: 'reports.new-report-wizard.settings.dashboard',
    defaultMessage: 'Dashboard',
  },
  content: {
    id: 'reports.new-report-wizard.settings.content',
    defaultMessage: 'Content',
  },
  settingsInfoOne: {
    id: 'reports.new-report-wizard.settings.info-one',
    defaultMessage:
      'Select and combine tables and charts from the dashboard or export reviews from your Inbox to create a comprehensive report, tailored to your needs.',
  },
  settingsInfoTwo: {
    id: 'reports.new-report-wizard.settings.info-two',
    defaultMessage:
      'Once ready, your report will be sent directly to your email. Please note, report generation may take up to 30 minutes. ',
  },
  settingsTypeInfo: {
    id: 'reports.new-report-wizard.settings.type-info',
    defaultMessage: 'For this report, we transform each visualization into a table.',
  },
  filtersLoaderHeader: {
    id: 'reports.new-report-wizard.filters.loader.header',
    defaultMessage: 'Loading...',
  },
  filtersLoaderContent: {
    id: 'reports.new-report-wizard.filters.loader.content',
    defaultMessage: "We're getting the filters ready for you. This might take a few seconds.",
  },
  filtersInfo: {
    id: 'reports.new-report-wizard.filters.info',
    defaultMessage:
      'Adjust the filters as you’d like them to show in your report. Charts will be shown as tables. Tables without available data won’t be added to the report. You can make a selection between tables in the next step.',
  },
  widgetsHeader: {
    id: 'reports.new-report-wizard.widgets.select-widgets',
    defaultMessage: 'Select widgets',
  },
  widgetsSelectedCount: {
    id: 'reports.new-report-wizard.widgets.selected-count',
    defaultMessage: '{count} selected',
  },
  widgetsInfo: {
    id: 'reports.new-report-wizard.widgets.info',
    defaultMessage: 'Choose at least 1 widget to be added to your report.',
  },
  recipientsHeader: {
    id: 'reports.new-report-wizard.recipients.header',
    defaultMessage: 'Where should we send this report?',
  },
  frequencyTypeHeader: {
    id: 'reports.new-report-wizard.frequency-type.header',
    defaultMessage: 'What kind of report is this?',
  },
  successMessageSchedule: {
    id: 'reports.new-report-wizard.success-message.schedule',
    defaultMessage: 'Report scheduled successfully',
  },
  successMessageOneTimeLineOne: {
    id: 'reports.new-report-wizard.success-message.one-time.line-one',
    defaultMessage: 'Your report is on its way!',
  },
  successMessageOneTimeLineTwo: {
    id: 'reports.new-report-wizard.success-message.one-time.line-two',
    defaultMessage: 'It may take up to 30 minutes to arrive to your email inbox. ',
  },
});

export const recipientsSelectorMessages = defineMessages({
  recipients: {
    id: 'reports.recipient-selector.recipients',
    defaultMessage: 'Recipients',
  },
  notFound: {
    id: 'reports.recipient-selector.not-found',
    defaultMessage: 'Recipients not found',
  },
  placeholder: {
    id: 'reports.recipient-selector.placeholder',
    defaultMessage: 'Email addresses',
  },
  required: {
    id: 'reports.recipient-selector.required',
    defaultMessage: 'Recipient is required',
  },
  loading: {
    id: 'reports.recipient-selector.loading',
    defaultMessage: 'Loading...',
  },
});

export const frequencySelectorMessages = defineMessages({
  dayOfWeek: {
    id: 'reports.frequency-selector.day-of-the-week',
    defaultMessage: 'Day of the week',
  },
  dayOfMonth: {
    id: 'reports.frequency-selector.day-of-the-month',
    defaultMessage: 'Day of the month',
  },
  dayOfMonthHelper: {
    id: 'reports.frequency-selector.day-of-the-month.helper',
    defaultMessage:
      'If the selected day is unavailable for the month, \n the report will be sent on the last day of the month',
  },
  timeOfDay: {
    id: 'reports.frequency-selector.time-of-the-day',
    defaultMessage: 'Time of the day',
  },
  timeOfDayHelper: {
    id: 'reports.frequency-selector.time-of-the-day.helper',
    defaultMessage: 'Based on your time zone',
  },
});

export const reportFrequencyOptions = defineMessages({
  weekly: {
    id: 'reports.frequency-option.weekly',
    defaultMessage: 'Weekly',
  },
  daily: {
    id: 'reports.frequency-option.daily',
    defaultMessage: 'Daily',
  },
  monthly: {
    id: 'reports.frequency-option.monthly',
    defaultMessage: 'Monthly',
  },
});

export const inboxFiltersStep = defineMessages({
  defineFiltersTitle: {
    id: 'reports.inbox-filter.define-filters.title',
    defaultMessage: 'Define filters',
  },
  defineFiltersDescription: {
    id: 'reports.inbox-filter.define-filters.description',
    defaultMessage:
      'You can use a saved filter View (managed from the Inbox) or start from scratch.',
  },
});

export const inboxDetailsStep = defineMessages({
  descriptionOne: {
    id: 'reports.inbox-details.description.one',
    defaultMessage: 'If you wish to add some keywords to filter the results, you can do it here.',
  },
  descriptionTwo: {
    id: 'reports.inbox-details.description.two',
    defaultMessage:
      'To search for an exact phrase, put it in quotation marks (e.g. “ coffee “). You can add multiple keywords.',
  },
});
