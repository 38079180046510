import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    marginLeft: 'auto',
    display: {
      xs: 'none',
      md: 'inline-flex',
    },
  },
  buttonIcon: { height: '18px' },
};
export default styles;
