import type { PaginationRequest } from '@trustyou/shared';

import type {
  BookingConnectionRequestCredentials,
  BookingConnectionResponse,
  GoogleAuthUrl,
  GoogleConnectionRequestCredentials,
  GoogleConnectionResponse,
  ProviderAccount,
  ReviewProvider,
  ReviewProviderConnectionsResponse,
} from '../../../../types/model/reviewProvider';
import { $assApi } from '../../../api';
import { CONNECTION_BASE_PATH, ORGANIZATION_BASE_PATH, ORG_BASE_PATH } from '../../path';

export async function fetchReviewProviders(): Promise<ReviewProvider[]> {
  const { data } = await $assApi.get(`${ORGANIZATION_BASE_PATH}/get_review_providers`);
  return data.data;
}

export async function fetchReviewProviderDetails(
  reviewProviderId: string,
  { limit, offset }: PaginationRequest
): Promise<ReviewProviderConnectionsResponse> {
  const { data } = await $assApi.get(`${ORG_BASE_PATH}/connection/get_connected_entities`, {
    params: {
      review_provider_id: reviewProviderId,
      limit,
      offset,
    },
  });
  return data;
}

export async function createBookingConnection(
  credentials: BookingConnectionRequestCredentials
): Promise<BookingConnectionResponse> {
  const { data } = await $assApi.post(
    `${CONNECTION_BASE_PATH}/create_booking_provider_account`,
    credentials
  );
  return data.data;
}

export async function fetchGoogleAuthUrl(redirectUrl: string): Promise<GoogleAuthUrl> {
  const { data } = await $assApi.get(`${CONNECTION_BASE_PATH}/get_google_auth_url`, {
    params: {
      redirect_url: redirectUrl,
    },
  });
  return data;
}

export async function createGoogleConnection(
  credentials: GoogleConnectionRequestCredentials
): Promise<GoogleConnectionResponse> {
  const { data } = await $assApi.post(
    `${CONNECTION_BASE_PATH}/create_google_provider_account`,
    credentials
  );
  return data.data;
}

export async function deleteProviderAccount(providerAccountId: string): Promise<string> {
  const { data } = await $assApi.delete(`${CONNECTION_BASE_PATH}/delete_provider_account`, {
    params: {
      provider_account_id: providerAccountId,
    },
  });
  return data;
}

export async function disconnectProvider(reviewProviderId: string): Promise<string> {
  const { data } = await $assApi.post(`${CONNECTION_BASE_PATH}/disconnect_provider`, {
    params: {
      review_provider_id: reviewProviderId,
    },
  });
  return data;
}

export async function fetchProviderAccounts(reviewProviderId: string): Promise<ProviderAccount[]> {
  const { data } = await $assApi.get(`${CONNECTION_BASE_PATH}/get_provider_accounts`, {
    params: {
      review_provider_id: reviewProviderId,
    },
  });
  return data.data;
}
