import type { Theme } from '@mui/material';

import type { IStyles } from '../../types/common';

const styles = (theme: Theme): IStyles => ({
  codeBlock: {
    position: 'relative',
    display: 'block',
    width: '300px',
    // MUI BlueGray 700
    backgroundColor: '#455A64',
    color: theme.palette.primary.contrastText,
    borderRadius: '4px',
    padding: '16px',
    fontFamily: 'Roboto Mono',
    fontSize: '12px',
    lineHeight: '14.4px',
    letterSpacing: 'normal',
    fontWeight: '400',
    textTransform: 'none',
    textAlign: 'left',
    '&:hover': {
      // MUI BlueGray 700
      backgroundColor: '#455A64',
    },
  },
  preBox: { whiteSpace: 'pre-wrap', wordBreak: 'break-all', margin: 0 },
  hoverBox: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#000000',
      opacity: '0.7',
    },
  },
  icon: {
    fontSize: '1.5rem',
    padding: '8px',
  },
});

export default styles;
