import { type StoreApi, type UseBoundStore, create } from 'zustand';

import { type ResponseSliceState, createResponseSlice } from './slices/response-slice';
import { type ReviewSliceState, createReviewSlice } from './slices/review-slice';

type WithSelectors<S> = S extends { getState: () => infer T }
  ? S & { use: { [K in keyof T]: () => T[K] } }
  : never;

const createSelectors = <S extends UseBoundStore<StoreApi<object>>>(_store: S) => {
  const store = _store as WithSelectors<typeof _store>;
  store.use = {};
  for (const k of Object.keys(store.getState())) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (store.use as any)[k] = () => store((s) => s[k as keyof typeof s]);
  }

  return store;
};

export type StoreTypeIntersection = ReviewSliceState & ResponseSliceState;

export const useStore = createSelectors(
  create<StoreTypeIntersection>()((...a) => ({
    ...createReviewSlice(...a),
    ...createResponseSlice(...a),
  }))
);
