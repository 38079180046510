import type { AutocompleteRenderGetTagProps } from '@mui/material';
import { Avatar, Chip, stringToBrandedColor } from '@trustyou/ui';

import type { Recipient } from './recipient-selector';

export const RenderTags = (tagValue: Recipient[], getTagProps: AutocompleteRenderGetTagProps) =>
  tagValue.map((option, index) => {
    const dynamicColor = stringToBrandedColor(option.name ?? 'a');
    return (
      <Chip
        {...getTagProps({ index })}
        key={option.id}
        size="small"
        label={option.email}
        avatar={
          <Avatar
            sx={{
              backgroundColor: dynamicColor,
              color: (theme) => `${theme.palette.getContrastText(dynamicColor)} !important`,
            }}
          >
            {option.email.charAt(0).toUpperCase()}
          </Avatar>
        }
      />
    );
  });
