import type { IStyles } from '../../types/common';

const styles: IStyles = {
  listItem: {
    minHeight: '56px',
    paddingY: 0.5,
    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
  },
  singleListItem: {
    borderBottom: 'none',
  },
  list: {
    border: (theme) => `1px solid ${theme.palette.divider}`,
    borderRadius: 1,
    height: 'fit-content',
    overflowY: 'auto',
    flex: '1',
  },
  singleList: {
    flex: 'unset',
  },
  header: {
    display: 'flex',
    alignItems: 'flex-end',
  },
};

export default styles;
