import { FormattedMessage } from 'react-intl';

import { capitalize } from '@mui/material';
import { faArrowUpRightFromSquare } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { type SourceUrl, openInNewTab } from '@trustyou/shared';
import {
  Box,
  Chip,
  EmptyEntitySourceState,
  StyledFontAwesomeIcon as Icon,
  ReviewProviderAPIStatus,
  Stack,
  Typography,
  reviewProvidersLogo,
} from '@trustyou/ui';

import styles from './styles';

type EntitySourcesViewProps = {
  sourcesUrls: SourceUrl[];
};

export const EntitySourcesView = ({ sourcesUrls }: EntitySourcesViewProps) => {
  if (!sourcesUrls.length) return <EmptyEntitySourceState />;
  return (
    <Stack data-testid="entity-source-list" direction="column" spacing={2}>
      {sourcesUrls.map((sourceUrl) => (
        <Box data-testid="entity-source-list-item" key={sourceUrl.id} sx={styles.sourceCard}>
          <Box sx={styles.iconRow}>
            <Box
              component="img"
              alt={sourceUrl.source.review_provider.name}
              src={
                reviewProvidersLogo[sourceUrl.source.review_provider.name] ||
                reviewProvidersLogo.default
              }
            />
            <Typography variant="subtitle1">
              {capitalize(sourceUrl.source.review_provider.name)}
            </Typography>
            {sourceUrl.source.type === 'API' && (
              <>
                <Chip
                  label={<FormattedMessage id="common.api" defaultMessage="API" />}
                  variant="pastelInfo"
                  size="small"
                  sx={styles.apiChip}
                />
                <ReviewProviderAPIStatus status={sourceUrl.source.status} />
              </>
            )}
          </Box>
          {sourceUrl.source.type === 'API' ? (
            <Typography sx={styles.sourceValue} variant="body2">
              <FormattedMessage id="common.id" defaultMessage="ID" />:
              {sourceUrl.source.review_provider.id}
            </Typography>
          ) : (
            <Typography
              sx={{ ...styles.sourceValue, ...styles.sourceValueLink }}
              variant="body2"
              onClick={() => openInNewTab(sourceUrl.url)}
            >
              {sourceUrl.url}
              <Icon icon={faArrowUpRightFromSquare} />
            </Typography>
          )}
        </Box>
      ))}
    </Stack>
  );
};
