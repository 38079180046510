import { useNavigate } from 'react-router-dom';

import type { SurveyWithCount } from '../../client';
import { SmallCard } from '../shared/small-card';

type SurveyCardProps = {
  survey: SurveyWithCount;
};

export function SurveyCard({ survey }: SurveyCardProps) {
  const navigate = useNavigate();

  return (
    <SmallCard
      title={survey.name}
      lastUpdated={survey.metadata.updated_at}
      questionnaireCount={survey.questionnaire_count}
      entityCount={survey.entity_count}
      onClick={() => navigate(`survey/detail/${survey.id}`)}
    />
  );
}
