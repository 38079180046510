import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import type { GridPaginationModel, GridRowParams, GridSortModel } from '@mui/x-data-grid-pro';
import { useQueryClient } from '@tanstack/react-query';
import {
  DEFAULT_PAGINATION_MODEL,
  DEFAULT_SORT_MODEL,
  FETCH_SEGMENT_GROUPS,
  type Segment,
  type SegmentGroup,
  getPaginationRequest,
  getSessionState,
  getSortRequest,
  setSessionState,
  useAlertStore,
  useDeleteGroup,
  useSegmentGroups,
} from '@trustyou/shared';
import { BackdropSpinner, DataGrid, EmptyGroupState } from '@trustyou/ui';

import { DeleteGroups } from './DeleteGroups';
import useGroupsTableColumns from './useGroupTableColumns';

import { DashboardDelay } from '../../../../../components';
import styles from './styles';

const PAGINATION_STATE_KEY = 'GROUPS_PAGINATION';
const SORT_STATE_KEY = 'GROUPS_SORT';

export type SegmentGroupsProps = {
  segment: Segment;
  onGroupSelect: (group: SegmentGroup) => void;
};

const Groups = ({ segment, onGroupSelect }: SegmentGroupsProps) => {
  const queryClient = useQueryClient();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
    getSessionState(PAGINATION_STATE_KEY) || DEFAULT_PAGINATION_MODEL
  );
  const [sortModel, setSortModel] = useState<GridSortModel>(
    getSessionState(SORT_STATE_KEY) || DEFAULT_SORT_MODEL
  );
  const { data, isFetching } = useSegmentGroups(
    segment.id,
    getPaginationRequest(paginationModel),
    getSortRequest(sortModel)
  );
  const [deletableGroup, setDeletableGroup] = useState<SegmentGroup>();
  const { alert } = useAlertStore();

  const handleRowClick = (params: GridRowParams) => {
    onGroupSelect(params.row);
  };

  const { mutate: handleDeleteGroup, isPending: isDeleting } = useDeleteGroup(
    () => {
      alert.info(
        <FormattedMessage
          id="organization.segments.groupDeletedAlert"
          defaultMessage="{group} deleted. {delay}"
          values={{ group: deletableGroup?.name, delay: <DashboardDelay /> }}
        />
      );
    },
    () => {
      alert.genericError();
    },
    () => setDeletableGroup(undefined)
  );

  const onDelete = () => {
    handleDeleteGroup(deletableGroup?.id as string);
  };

  const onPaginationModelChange = (model: GridPaginationModel) => {
    queryClient.removeQueries({ queryKey: [FETCH_SEGMENT_GROUPS] });
    setPaginationModel(model);
    setSessionState(PAGINATION_STATE_KEY, model);
  };

  const onSortModelChange = (model: GridSortModel) => {
    if (!model.length) return;
    queryClient.removeQueries({ queryKey: [FETCH_SEGMENT_GROUPS] });
    setSortModel(model);
    setSessionState(SORT_STATE_KEY, model);
    setPaginationModel((state) => {
      const newState = { ...state, page: DEFAULT_PAGINATION_MODEL.page };
      setSessionState(PAGINATION_STATE_KEY, newState);
      return newState;
    });
  };

  const columns = useGroupsTableColumns({ onDelete: setDeletableGroup });

  if (!data || isFetching) {
    // TODO: Display loader when the design is ready
    return null;
  }

  if (data.pagination.total === 0) return <EmptyGroupState />;

  return (
    <>
      <DataGrid
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        rowCount={data.pagination.total}
        sx={styles.table}
        rowHeight={64}
        columns={columns}
        rows={data.data}
        onRowClick={handleRowClick}
        containerStyle={styles.tableContainer}
        autoHeight={false}
      />
      <DeleteGroups
        onClose={() => {
          setDeletableGroup(undefined);
        }}
        onDelete={onDelete}
        group={deletableGroup}
      />
      <BackdropSpinner isLoading={isDeleting} />
    </>
  );
};

export default Groups;
