import type { ChangelingUserInfo } from '@trustyou/shared';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type ChangelingState = {
  isChangeling: boolean;
  isReadyToAuthToAdmin: boolean;
  userInfo?: ChangelingUserInfo;
  setIsChangeling: (isChangeling: boolean) => void;
  setIsReadyToAuthToAdmin: (isReadyToAuthToAdmin: boolean) => void;
  setUserInfo: (info: ChangelingUserInfo) => void;
  clearChangelingState: () => void;
};

export const useChangelingStore = create<ChangelingState>()(
  persist<ChangelingState>(
    (set) => ({
      isChangeling: false,
      isReadyToAuthToAdmin: false,
      userInfo: undefined,
      setIsChangeling: (isChangeling) => set({ isChangeling }),
      setIsReadyToAuthToAdmin: (isReadyToAuthToAdmin) => set({ isReadyToAuthToAdmin }),
      setUserInfo: (userInfo) => set({ userInfo }),
      clearChangelingState: () =>
        set({ isChangeling: false, isReadyToAuthToAdmin: false, userInfo: undefined }),
    }),
    {
      name: 'changeling-state',
    }
  )
);
