import { FormattedMessage } from 'react-intl';

import { Stack, Typography } from '@trustyou/ui';

import { newReportWizardMessages } from '../../../../constants/messages';

type Props = {
  isOneTime?: boolean;
};

export const SuccessMessage = ({ isOneTime }: Props) => {
  if (isOneTime) {
    return (
      <Stack gap={0.5}>
        <Typography variant="subtitle1" color="success">
          <FormattedMessage {...newReportWizardMessages.successMessageOneTimeLineOne} />
        </Typography>
        <FormattedMessage {...newReportWizardMessages.successMessageOneTimeLineTwo} />
      </Stack>
    );
  }
  return <FormattedMessage {...newReportWizardMessages.successMessageSchedule} />;
};
