import { GridColumnMenu, type GridColumnMenuProps } from '@mui/x-data-grid-pro';

export const CustomColumnMenu = (props: GridColumnMenuProps) => {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Hide column menu filter item
        columnMenuFilterItem: null,
      }}
    />
  );
};
