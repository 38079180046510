import { defineMessages } from 'react-intl';

export const translatedContentTypes = defineMessages({
  email_invitation: {
    id: 'survey.common.email-invitation',
    defaultMessage: 'Email invitation',
  },
  image: {
    id: 'survey.common.image',
    defaultMessage: 'Image',
  },
  question: {
    id: 'survey.common.question',
    defaultMessage: 'Question',
  },
  section: {
    id: 'survey.common.section',
    defaultMessage: 'Section',
  },
  text: {
    id: 'survey.common.text',
    defaultMessage: 'Text',
  },
  thankyou_message: {
    id: 'survey.common.thankyou-message',
    defaultMessage: 'Thank you',
  },
  welcome_message: {
    id: 'survey.common.welcome-message',
    defaultMessage: 'Welcome',
  },
});
