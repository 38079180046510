import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type SidebarState = {
  isCollapsed: boolean;
  toggleSidebar: () => void;
};

export const useSidebarStore = create<SidebarState>()(
  persist(
    (set) => ({
      isCollapsed: false,
      toggleSidebar: () => set(({ isCollapsed }) => ({ isCollapsed: !isCollapsed })),
    }),
    {
      name: `sidebar-storage`,
    }
  )
);
