import { blue } from '@mui/material/colors';
import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  container: {
    width: {
      xs: '100%',
      sm: '276px',
    },
    height: '100px',
    border: (theme) => `1px solid ${theme.palette.divider}`,
    borderRadius: 1,
    justifyContent: 'center',
  },
  selectableCard: {
    '&:hover': {
      border: `1px solid ${blue[700]}`,
      boxShadow: '0px 0px 0px 1px rgba(69, 90, 100, 0.14)',
      cursor: 'pointer',
    },
  },
  subContainer: {
    marginX: 1.25,
  },
  img: {
    height: '32px',
    width: '32px',
    borderRadius: 1,
  },
  imgChipContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    '& svg': {
      color: 'primary.main',
    },
  },
};

export default styles;
