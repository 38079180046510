import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  body: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    maxWidth: '480px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  accessOptionSelectionCard: { maxWidth: '320px', minHeight: '150px' },
  accessOptionSelectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  accessOptionSelectionCardContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
  },
};

export default styles;
