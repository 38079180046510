import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import {
  CustomAttributeType,
  type EntityCustomAttributes,
  actionMessages,
  useAlertStore,
  useCustomAttributeDefinitions,
  useEntity,
  useUpdateEntityCustomAttributes,
} from '@trustyou/shared';
import {
  Checkbox,
  Divider,
  FormControl,
  FormFieldLabel,
  FormHelperText,
  InfoDialog,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@trustyou/ui';

import { valuesUpdateDialog } from '../../constants/messages/customAttributes';
import { DashboardDelayTime } from '../DashboardDelay/DashboardDelay';
import { styles } from './styles';

type Props = {
  entityId: string;
  onClose: () => void;
};

export const EntityCustomAttributesUpdateDialog = ({ entityId, onClose }: Props) => {
  const intl = useIntl();
  const { alert } = useAlertStore();

  const { data: entity } = useEntity(entityId);
  const { data: customAttributeDefinitions = [], isFetching } = useCustomAttributeDefinitions();

  const { mutate: updateCustomAttribute } = useUpdateEntityCustomAttributes(() => {
    alert.success(
      intl.formatMessage(valuesUpdateDialog.successMessage, {
        delay: <DashboardDelayTime />,
      })
    );
    onClose();
  }, alert.genericError);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { control, getValues, reset, trigger } = useForm<Record<string, any>>({
    mode: 'onChange',
    defaultValues: {},
  });

  useEffect(() => {
    if (!entity || customAttributeDefinitions.length === 0) return;
    const initialValues = customAttributeDefinitions.reduce(
      (prev, cur) => {
        const defaultValues = (cur.default || []).map((option) => option.name);
        return {
          ...prev,
          [cur.name]:
            entity.custom_attributes[cur.name] ||
            (cur.type === CustomAttributeType.MULTI_SELECT
              ? defaultValues
              : defaultValues[0] || ''),
        };
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {} as Record<string, any>
    );
    reset(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customAttributeDefinitions, entity]);

  if (!entity || isFetching) return null;

  const onSaveValues = async () => {
    const response = await trigger();
    if (response) {
      const attributes = Object.entries(getValues()).reduce(
        (prev, [key, value]) => ({
          ...prev,
          [key]: value === '' && entity.custom_attributes[key] !== '' ? null : value,
        }),
        {} as EntityCustomAttributes
      );
      updateCustomAttribute({
        entity_id: entity.id,
        attributes,
      });
    }
  };

  return (
    <InfoDialog
      title={intl.formatMessage(valuesUpdateDialog.title)}
      open
      primaryActionText={intl.formatMessage(actionMessages.save)}
      secondaryActionText={intl.formatMessage(actionMessages.cancel)}
      onPrimaryAction={onSaveValues}
      onSecondaryAction={onClose}
      dataName="custom-attribute-update-confirmation"
      maxWidth="sm"
    >
      <Stack gap={3} paddingX={3}>
        {customAttributeDefinitions.map((row) => (
          <FormControl key={row.id} sx={styles.formControl}>
            <FormFieldLabel required={row.mandatory}>{row.name}</FormFieldLabel>
            <Controller
              name={row.name}
              control={control}
              rules={{
                required: row.mandatory
                  ? intl.formatMessage(valuesUpdateDialog.mandatoryField)
                  : false,
              }}
              render={({ field, fieldState }) => (
                <>
                  {row.type === CustomAttributeType.TEXT && (
                    <TextField
                      {...field}
                      size="small"
                      id={row.id}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message || row.description}
                      type="text"
                      variant="outlined"
                    />
                  )}
                  {row.type === CustomAttributeType.SELECT && (
                    <>
                      <Select
                        value={field.value}
                        onChange={(event) => {
                          field.onChange(event.target.value);
                        }}
                        error={!!fieldState.error}
                        size="small"
                        displayEmpty
                        renderValue={(value) =>
                          value || intl.formatMessage(valuesUpdateDialog.selectPlaceholder)
                        }
                      >
                        {row.options.map((option) => (
                          <MenuItem key={option.id} value={option.name}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={!!fieldState.error?.message}>
                        {fieldState.error?.message || row.description}
                      </FormHelperText>
                    </>
                  )}
                  {row.type === CustomAttributeType.MULTI_SELECT && (
                    <>
                      <Select
                        multiple
                        value={field.value || []}
                        error={!!fieldState.error}
                        onChange={field.onChange}
                        displayEmpty
                        renderValue={(selected = []) =>
                          selected.join(', ') ||
                          intl.formatMessage(valuesUpdateDialog.selectPlaceholder)
                        }
                        sx={styles.multiSelect}
                      >
                        <MenuItem disabled sx={styles.multiSelectCount}>
                          <Typography variant="body1">
                            {intl.formatMessage(valuesUpdateDialog.selectCount, {
                              count: field.value.length,
                            })}
                          </Typography>
                        </MenuItem>
                        <Divider />
                        {row.options.map((option) => (
                          <MenuItem
                            key={option.id}
                            value={option.name}
                            sx={styles.multiSelectMenuItem}
                          >
                            <Checkbox checked={(field.value || []).indexOf(option.name) > -1} />
                            <ListItemText primary={option.name} />
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={!!fieldState.error?.message}>
                        {fieldState.error?.message || row.description}
                      </FormHelperText>
                    </>
                  )}
                </>
              )}
            />
          </FormControl>
        ))}
      </Stack>
    </InfoDialog>
  );
};
