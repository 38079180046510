import type { IStyles } from '@trustyou/shared';

export const styles: IStyles = {
  formHeader: { paddingInline: 3 },
  formContent: { paddingX: 3, paddingY: 2 },
  formFooter: { boxShadow: 5 },
  deleteButton: { marginRight: 'auto' },
  checkboxFormControl: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 1,
  },
  checkboxFormControlLabel: { marginX: 0, gap: 1 },
};
