import { useIntl } from 'react-intl';

import { type DropdownGroup, FiltersFormFields, type Option } from '../../../types';
import { DropdownRadio } from '../dropdown-radio';

export type DropdownTimeRangeProps = {
  openTimeRangeDialog: () => void;
  initialValue: string;
};

export const DropdownTimeRange = ({
  openTimeRangeDialog,
  initialValue,
}: DropdownTimeRangeProps) => {
  const intl = useIntl();

  const allTimeOptions: Option[] = [
    {
      label: intl.formatMessage({ id: 'inbox.filter.timeRange.all', defaultMessage: 'All time' }),
      value: 'All time',
    },
  ];
  const daysAndWeeksOptions: Option[] = [
    {
      label: intl.formatMessage({
        id: 'inbox.filter.timeRange.yesterday',
        defaultMessage: 'Yesterday',
      }),
      value: 'Yesterday',
    },
    {
      label: intl.formatMessage({
        id: 'inbox.filter.timeRange.last7Days',
        defaultMessage: 'Last 7 days',
      }),
      value: 'Last 7 days',
    },
    {
      label: intl.formatMessage({
        id: 'inbox.filter.timeRange.lastWeek',
        defaultMessage: 'Last week',
      }),
      value: 'Last week',
    },
  ];
  const monthsOptions: Option[] = [
    {
      label: intl.formatMessage({
        id: 'inbox.filter.timeRange.monthToDate',
        defaultMessage: 'Month to date',
      }),
      value: 'Month to date',
    },
    {
      label: intl.formatMessage({
        id: 'inbox.filter.timeRange.lastFullMonth',
        defaultMessage: 'Last full month',
      }),
      value: 'Last full month',
    },
    {
      label: intl.formatMessage({
        id: 'inbox.filter.timeRange.last24Months',
        defaultMessage: 'Last 24 months',
      }),
      value: 'Last 24 months',
    },
  ];

  const quartersOptions: Option[] = [
    {
      label: intl.formatMessage({
        id: 'inbox.filter.timeRange.quarterToDate',
        defaultMessage: 'Quarter to Date',
      }),
      value: 'Quarter to Date',
    },
    {
      label: intl.formatMessage({
        id: 'inbox.filter.timeRange.lastFullQuarter',
        defaultMessage: 'Last full quarter',
      }),
      value: 'Last full quarter',
    },
  ];

  const yearsOptions: Option[] = [
    {
      label: intl.formatMessage({
        id: 'inbox.filter.timeRange.yearToDate',
        defaultMessage: 'Year to date',
      }),
      value: 'Year to date',
    },
    {
      label: intl.formatMessage({
        id: 'inbox.filter.timeRange.yearToLastMonth',
        defaultMessage: 'Year to last month',
      }),
      value: 'Year to last month',
    },
    {
      label: intl.formatMessage({
        id: 'inbox.filter.timeRange.lastFullYear',
        defaultMessage: 'Last full year',
      }),
      value: 'Last full year',
    },
  ];

  const timeRangeGroups: DropdownGroup[] = [
    {
      options: allTimeOptions,
    },
    {
      label: intl.formatMessage({
        id: 'inbox.filter.timeRange.group.daysAndWeeks',
        defaultMessage: 'Days and weeks',
      }),
      value: 'Days and Weeks',
      options: daysAndWeeksOptions,
    },
    {
      label: intl.formatMessage({
        id: 'inbox.filter.timeRange.group.months',
        defaultMessage: 'Months',
      }),
      value: 'Months',
      options: monthsOptions,
    },
    {
      label: intl.formatMessage({
        id: 'inbox.filter.timeRange.group.quarters',
        defaultMessage: 'Quarters',
      }),
      value: 'Quarters',
      options: quartersOptions,
    },
    {
      label: intl.formatMessage({
        id: 'inbox.filter.timeRange.group.years',
        defaultMessage: 'Years',
      }),
      value: 'Years',
      options: yearsOptions,
    },
  ];

  return (
    <DropdownRadio
      fieldName={FiltersFormFields.Rangename}
      groups={timeRangeGroups}
      dataTestId="filterFieldTimeRange"
      label={intl.formatMessage({
        id: 'inbox.filter.timeRange.label',
        defaultMessage: 'Time range',
      })}
      onOpenDialog={openTimeRangeDialog}
      defaultValue={initialValue}
      size="small"
      fullWidth
    />
  );
};
