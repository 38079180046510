import { InputLabel, styled } from '@trustyou/ui';

export const TextLabel = styled(InputLabel)(({ theme }) => ({
  '&.MuiFormLabel-root': {
    top: `-${theme.spacing(0.75)}`,
    marginInlineStart: `-${theme.spacing(1.75)}`,
    fontSize: '1.125rem !important',
    color: theme.palette.text.primary,
  },
  '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
    color: theme.palette.error.main,
  },
}));

export const SelectLabel = styled(TextLabel)(({ theme }) => ({
  '&.MuiFormLabel-root': {
    top: `-${theme.spacing(2)}`,
    fontSize: '1.125rem !important',
    color: theme.palette.text.primary,
  },
}));
