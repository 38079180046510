import { FormattedMessage } from 'react-intl';

import { useScopeStore } from '@trustyou/shared';
import { Alert, GroupSelector, Stack } from '@trustyou/ui';

type GroupSelectionProps = {
  validated?: boolean;
};

export const GroupSelection = ({ validated }: GroupSelectionProps) => {
  const { selectedGroups } = useScopeStore();

  const isEmptySelection = selectedGroups.length === 0;

  return (
    <Stack spacing={2}>
      <GroupSelector />
      {validated && isEmptySelection && (
        <Alert severity="error">
          <FormattedMessage
            id="organization.users.selectAtLeastOneGroup"
            defaultMessage="Select at least one group"
          />
        </Alert>
      )}
    </Stack>
  );
};
