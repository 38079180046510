import type { ErrorMap, UploadError } from '@trustyou/shared';

import InvalidFileSizeError from './InvalidFileSizeError';
import InvalidFileTypeError from './InvalidFileTypeError';
import MandatoryColumnsError from './MandatoryColumnsError';
import MandatoryValuesError from './MandatoryValuesError';
import UndefinedError from './UndefinedError';
import WrongFormattingError from './WrongFormattingError';

const errorsMap: ErrorMap = {
  'file-invalid-type': () => <InvalidFileTypeError key="file-invalid-type" />,
  'file-too-large': () => <InvalidFileSizeError key="file-too-large" />,
  'missing-mandatory-values': () => <MandatoryValuesError key="missing-mandatory-values" />,
  'missing-mandatory-columns': (error: UploadError) => (
    <MandatoryColumnsError {...error} key={error.type} />
  ),
  'wrong-formatting': (error: UploadError) => <WrongFormattingError {...error} key={error.type} />,
  'undefined-error': (error: UploadError) => <UndefinedError {...error} key={error.type} />,
};

export default errorsMap;
