import { FormattedMessage } from 'react-intl';

import type { Entity } from '@trustyou/shared';
import { DeleteModal } from '@trustyou/ui';

export type DeleteCompetitorProps = {
  competitor?: Entity;
  onClose: () => void;
  onDelete: () => void;
};

export const DeleteCompetitor = ({ competitor, onClose, onDelete }: DeleteCompetitorProps) => (
  <DeleteModal
    modalStyles={{
      '& .MuiDialog-paper': {
        minWidth: '444px',
      },
    }}
    cancelText={<FormattedMessage id="tyDesign.action.cancel" defaultMessage="Cancel" />}
    content={
      <FormattedMessage
        id="tyDesign.deleteCompetitorContent"
        defaultMessage="You are deleting {competitor} as a competitor."
        values={{ competitor: competitor?.name }}
      />
    }
    dataName="competitor-delete"
    deleteText={
      <FormattedMessage id="tyDesign.deleteCompetitor" defaultMessage="Delete competitor" />
    }
    onClose={onClose}
    onDelete={onDelete}
    title={<FormattedMessage id="tyDesign.deleteCompetitor?" defaultMessage="Delete competitor?" />}
    open={!!competitor}
  />
);
