import { FormattedMessage } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Button } from '@trustyou/ui';

import { NewQuestionDialog } from './new-question-dialog';

import { useStore } from '../../store/store';

export const NewQuestionButton = () => {
  const isQuestionTypeSelectorOpen = useStore.use.isQuestionTypeSelectorOpen();
  const setIsQuestionTypeSelectorOpen = useStore.use.setIsQuestionTypeSelectorOpen();

  return (
    <>
      <Button
        variant="contained"
        startIcon={<FontAwesomeIcon icon={faPlus} />}
        onClick={() => setIsQuestionTypeSelectorOpen(true)}
      >
        <FormattedMessage
          id="survey.question-library.new-question.button"
          defaultMessage="New question"
        />
      </Button>
      <NewQuestionDialog
        open={isQuestionTypeSelectorOpen}
        onClose={() => setIsQuestionTypeSelectorOpen(false)}
      />
    </>
  );
};
