import { useState } from 'react';
import { useController } from 'react-hook-form';
import { useIntl } from 'react-intl';

import type { AutocompleteValue } from '@mui/material';
import { Autocomplete, TextField } from '@trustyou/ui';

import type { SelectQuestionProps } from '../question-types';
import type { Option } from '../types';

export type DropdownMultipleProps = SelectQuestionProps;

export function DropdownMultiple({ name, control, options }: DropdownMultipleProps) {
  const intl = useIntl();
  const { field } = useController({ name, control });
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(
    field.value ? options.filter((item) => field.value.includes(item.value)) : []
  );
  const placeholder =
    selectedOptions.length === 0
      ? intl.formatMessage({
          id: 'survey.question.dropdown.placeholder',
          defaultMessage: 'Select',
        })
      : '';

  const handleChange = (
    event: React.SyntheticEvent,
    value: AutocompleteValue<Option, true, false, false>
  ) => {
    field.onChange(value.map((option) => option.value));
    setSelectedOptions(value);
  };

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      fullWidth
      limitTags={2}
      options={options}
      getOptionLabel={(option) => option.label as string}
      renderInput={(params) => <TextField {...params} placeholder={placeholder} />}
      value={selectedOptions}
      onChange={handleChange}
    />
  );
}
