import { grey } from '@mui/material/colors';

import type { IStyles } from '../../types/common';

const styles: IStyles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '500px',
    gap: 1,
    backgroundColor: grey[50],
    borderRadius: '4px',
    padding: 3,
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  listWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
};

export default styles;
