import { Box } from '@trustyou/ui';

import OrganizationProfile from './OrganizationProfile';

import { styles } from './styles';

const GeneralApplicationSettings = () => {
  return (
    <Box sx={styles.generalPageContainer}>
      <OrganizationProfile />
    </Box>
  );
};

export default GeneralApplicationSettings;
