import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  icon: {
    padding: 0.25,
  },
};

export default styles;
