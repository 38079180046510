import { useState } from 'react';
import { useIntl } from 'react-intl';

import { Stack } from '@mui/system';
import { faFlag } from '@trustyou/fortawesome/pro-solid-svg-icons';
import { Alert, Button, StyledFontAwesomeIcon } from '@trustyou/ui';

import { SurveyModerationDetailsDialog } from './details-dialog';
import { alertMessages } from './messages';

import { ALERT_INFO_TITLE_COLOR } from '../../constants';

export function InappropriateProcessingReviewAlert() {
  const intl = useIntl();
  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false);

  const openDetailsDialog = () => setIsDetailsDialogOpen(true);
  const closeDetailsDialog = () => setIsDetailsDialogOpen(false);

  return (
    <>
      <Alert
        severity="info"
        icon={<StyledFontAwesomeIcon icon={faFlag} size="xs" color={ALERT_INFO_TITLE_COLOR} />}
        sx={{
          borderRadius: 0,
          padding: 2,
          '& .MuiAlert-message': { padding: 0 },
          '& .MuiAlert-icon': { paddingBlockStart: 0.5 },
        }}
      >
        {intl.formatMessage(alertMessages.inappropriateProcessing)}
        <Stack direction="row" sx={{ justifyContent: 'space-between', marginBlockStart: 1 }}>
          <Button onClick={openDetailsDialog} sx={{ paddingInline: 0, minWidth: 0 }}>
            {intl.formatMessage(alertMessages.details)}
          </Button>
        </Stack>
      </Alert>
      <SurveyModerationDetailsDialog isOpen={isDetailsDialogOpen} onClose={closeDetailsDialog} />
    </>
  );
}
