import type { IStyles } from '@trustyou/shared';

import { ICON_BUTTON_HEIGHT, ICON_BUTTON_WIDTH } from '../../../constants';

const styles: IStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
  },
  segmentsGroupsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    flex: '2',
  },
  groupsBody: {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
  },
  groupsContainer: {
    display: 'flex',
    flex: '1',
  },
  groupListContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    flex: 1,
  },
  groupListState: {
    padding: 2,
    textAlign: 'center',
    minHeight: '268px',
  },
  list: {
    border: (theme) => `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    padding: 0,
    flex: '1',
    height: 'fit-content',
    maxHeight: '340px',
    overflowY: 'auto',
  },
  groupList: {
    maxHeight: '268px',
  },
  listItem: {
    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
  },
  listItemIcon: {
    minWidth: 'unset',
  },
  arrowContainer: {
    width: ICON_BUTTON_WIDTH,
    display: 'flex',
    alignItems: 'center',
  },
  arrow: {
    backgroundColor: (theme) => theme.palette.background.default,
    borderRadius: '22px',
    height: ICON_BUTTON_HEIGHT,
    width: ICON_BUTTON_WIDTH,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'action.active',
  },
  selectedGroupsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    flex: '1',
  },
};

export default styles;
