import type { IStyles } from '@trustyou/shared';

export const styles: IStyles = {
  nodesContainer: {
    display: 'flex',
    width: '100%',
    maxWidth: '1200px',
    justifyContent: 'space-between',
    gap: 3,
    margin: 'auto',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
  },
  topNodes: {
    display: 'flex',
    gap: { md: 0.5 },
    width: { xs: '100%', md: '200px' },
    paddingTop: 0,
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 0,
    margin: { xs: 2, md: 0 },
    height: 'fit-content',
    borderRadius: '4px',
    overflow: 'hidden',
  },
  generalPageContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
    gap: 2,
    overflowY: 'auto',
  },
  pageActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 5,
  },
};
