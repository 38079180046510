import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  container: { display: 'flex', flexDirection: 'column', backgroundColor: 'background.paper' },
  topBar: {
    backgroundColor: 'background.paper',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginTop: 8,
  },
};

export default styles;
