import type { Entity } from '@trustyou/shared';
import { create } from 'zustand';

type CompetitorsState = {
  selectedCompetitors: Entity[];
  setSelectedCompetitors: (competitors: Entity[]) => void;
};

export const useCompetitorStore = create<CompetitorsState>((set, get) => ({
  selectedCompetitors: [],
  setSelectedCompetitors: (selectedCompetitors) => set(() => ({ selectedCompetitors })),
}));
