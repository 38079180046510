import { useState } from 'react';

import type { GridColumnHeaderParams } from '@mui/x-data-grid-pro';
import { faCircleQuestion } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  Box,
  StyledFontAwesomeIcon as Icon,
  IconButton,
  Typography,
  WhatIsCountryCode,
  WhatIsCustomAttribute,
  WhatIsSector,
} from '@trustyou/ui';

import styles from './styles';

const TableInfoHeader = ({ field, colDef }: GridColumnHeaderParams) => {
  const [viewInfo, setViewInfo] = useState<string>();
  const onClick = (event: React.MouseEvent) => {
    setViewInfo(field);
    event.stopPropagation();
  };
  return (
    <Box key={field} sx={styles.container}>
      <Typography variant="subtitle2" sx={styles.headerText} onClick={onClick}>
        {colDef.headerName}
      </Typography>
      <IconButton data-testid={`${field}-info-button`} onClick={onClick}>
        <Icon icon={faCircleQuestion} sx={styles.infoIcon} />
      </IconButton>
      <WhatIsSector open={viewInfo === 'sector'} onClose={() => setViewInfo(undefined)} />
      <WhatIsCustomAttribute open={viewInfo === 'stars'} onClose={() => setViewInfo(undefined)} />
      <WhatIsCountryCode open={viewInfo === 'countryCode'} onClose={() => setViewInfo(undefined)} />
    </Box>
  );
};
export default TableInfoHeader;
