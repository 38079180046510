import { FormattedMessage } from 'react-intl';

import { DeleteModal } from '../DeleteModal/DeleteModal';

export type DiscardChangesModalProps = {
  open: boolean;
  onClose: () => void;
  onDiscard: () => void;
};

export const DiscardChangesModal = ({ open, onClose, onDiscard }: DiscardChangesModalProps) => (
  <DeleteModal
    modalStyles={{
      '& .MuiDialog-paper': {
        minWidth: '444px',
      },
    }}
    cancelText={<FormattedMessage id="tyDesign.action.cancel" defaultMessage="Cancel" />}
    content={
      <FormattedMessage
        id="tyDesign.yourChangesWillNotBeSaved."
        defaultMessage="Your changes will not be saved."
      />
    }
    dataName="discard-modal"
    deleteText={<FormattedMessage id="tyDesign.discardChanges" defaultMessage="Discard changes" />}
    onClose={onClose}
    onDelete={onDiscard}
    title={<FormattedMessage id="tyDesign.discardChanges?" defaultMessage="Discard changes?" />}
    open={open}
  />
);
