import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { isRouteEndsWith } from '@trustyou/shared';
import { Tab, Tabs } from '@trustyou/ui';

import { commonMessages } from '../../constants/messages';
import { useHasSchedulePermission } from '../../hooks';
import { useHasReportsCreated } from '../../service/hooks';
import styles from './styles';

export const ReportViewSelector = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data: hasReportsCreated } = useHasReportsCreated();
  const isOnListPage = isRouteEndsWith(location.pathname, 'reports/list');
  const isOnBasePage = isRouteEndsWith(location.pathname, 'reports');
  const hasSchedulePermission = useHasSchedulePermission();

  useEffect(() => {
    if (!hasSchedulePermission) {
      navigate('history');
    } else if (isOnBasePage) {
      navigate('list');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSchedulePermission, isOnBasePage]);

  if (!hasSchedulePermission || isOnBasePage || !hasReportsCreated) return null;

  const tabs = [
    {
      value: 'list',
      label: <FormattedMessage {...commonMessages.frequencyTypeScheduled} />,
    },
    { value: 'history', label: <FormattedMessage {...commonMessages.sent} /> },
  ];

  return (
    <Tabs
      value={isOnListPage ? 'list' : 'history'}
      sx={styles.reportViewSelector}
      onChange={(_, value) => {
        navigate(value);
      }}
    >
      {tabs.map((tab) => (
        <Tab value={tab.value} key={tab.value} label={tab.label} />
      ))}
    </Tabs>
  );
};
