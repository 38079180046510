import { type ReactNode, useEffect, useState } from 'react';
import { DragDropContext, Draggable, type DropResult, Droppable } from 'react-beautiful-dnd';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import type { GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import {
  faGripDotsVertical,
  faPlus,
  faTrashCan,
} from '@trustyou/fortawesome/pro-regular-svg-icons';
import { capitalizeFirstLetter, useLanguageStore } from '@trustyou/shared';
import {
  Box,
  Button,
  Card,
  Divider,
  EmptyStatePlaceholder,
  StyledFontAwesomeIcon as Icon,
  IconButton,
  Stack,
  Typography,
} from '@trustyou/ui';

import { SurveyContentList } from './components/survey-contents-list';
import { SurveySectionList } from './components/survey-section-list';

// eslint-disable-next-line @nx/enforce-module-boundaries
import personFillingAForm from '../../../../../../../../ui/src/lib/assets/illustrations/person-filling-a-form.svg';
import { useContents, useQuestions } from '../../../../service/hooks/use-questions';
import type {
  Content_Output,
  SurveyEditorContentOutput,
  SurveyEditorContentProps,
} from '../../../../types/survey';
import { filterChosenContents } from '../../../../utils/survey-editor';
import { MAX_CARD_WIDTH_PX } from '../../../feedback/constants';
import { EDITOR_QUESTION_CONTENT_TYPES } from '../../constants/survey-editor-content';

type ContentItemProps = {
  content: SurveyEditorContentOutput;
  type?: 'question' | 'section' | 'page-break' | 'welcome_message';
  isQuestionInsideSection?: boolean;
  onDelete: () => void;
  children?: ReactNode;
};

type QuestionToSectionProps = {
  sectionId: string;
  questions?: Content_Output[];
  questionId?: string;
};

const ContentItem = ({
  content,
  type = 'question',
  isQuestionInsideSection = false,
  onDelete,
  children,
}: ContentItemProps) => {
  const { locale } = useLanguageStore();
  const userSettingLang = locale.split('-')[0];
  const contentDefaultLang = content.default_language ?? 'en';
  const sideBorder =
    type === 'section'
      ? EDITOR_QUESTION_CONTENT_TYPES[content.type ?? 'default'].color
      : EDITOR_QUESTION_CONTENT_TYPES[content.primitive?.repr ?? 'default'].color;

  return (
    <Card
      variant="outlined"
      sx={{
        maxWidth: MAX_CARD_WIDTH_PX,
        ...(!isQuestionInsideSection && {
          borderLeftWidth: 6,
          borderLeftColor: sideBorder,
        }),
        ...(isQuestionInsideSection && {
          borderWidth: 0,
        }),
      }}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingBlock: 2,
          paddingInline: 1,
        }}
      >
        <Stack flexDirection="row">
          <IconButton>
            <Icon icon={faGripDotsVertical} />
          </IconButton>
          <Box>
            <Typography variant="subtitle1">
              {capitalizeFirstLetter(
                type === 'section'
                  ? (content.title[userSettingLang] ?? content.title[contentDefaultLang])
                  : (content.internal_name[userSettingLang] ??
                      content.internal_name[contentDefaultLang])
              )}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {type === 'section'
                ? (content.description[userSettingLang] ?? content.description[contentDefaultLang])
                : (content.title[userSettingLang] ?? content.title[contentDefaultLang])}
            </Typography>
          </Box>
        </Stack>
        <IconButton onClick={onDelete}>
          <Icon icon={faTrashCan} />
        </IconButton>
      </Stack>
      {children}
    </Card>
  );
};

export function EditorSurveyContent() {
  const { control, setValue, watch, getValues } = useFormContext();
  const { move, append, remove } = useFieldArray({
    control,
    name: 'surveyContent',
  });
  //API to get list of question contents
  const { data: contents = [] } = useQuestions();
  const { data: sections = [] } = useContents({ types: ['section'] });
  const [fetchedQuestions, setFetchedQuestions] = useState<Content_Output[]>(contents);
  // @ts-expect-error Argument of type 'Content[]' is not assignable to parameter of type 'Content_Output[] | (() => Content_Output[])'.
  const [fetchedSections, setFetchedSections] = useState<Content_Output[]>(sections);
  //Content List Dialog
  const [openContentDialog, setOpenContentDialog] = useState(false);
  const [contentRowSelectModel, setContentRowSelectModel] = useState<GridRowSelectionModel>([]);
  const [questionToSection, setQuestionToSection] = useState<QuestionToSectionProps | null>(null);
  //Section List Dialog
  const [openSectionDialog, setOpenSectionDialog] = useState(false);
  const [sectionRowSelectModel, setSectionRowSelectModel] = useState<GridRowParams>();

  //User chosen list of contents
  const surveyContent: SurveyEditorContentProps[] = watch('surveyContent');

  useEffect(() => {
    setFetchedQuestions(contents);
    // @ts-expect-error Argument of type 'Content[]' is not assignable to parameter of type 'SetStateAction<Content_Output[]>'.
    setFetchedSections(sections);
  }, [contents, sections]);

  //Filtering the chosen contents from the list of all contents
  useEffect(() => {
    const filteredContents = filterChosenContents({
      allItems: contents,
      chosenItems: surveyContent,
    });
    const filteredSections = filterChosenContents({
      allItems: sections,
      chosenItems: surveyContent,
    });
    // @ts-expect-error Argument of type 'Content[]' is not assignable to parameter of type 'SetStateAction<Content_Output[]>'.
    setFetchedQuestions(filteredContents);
    // @ts-expect-error Argument of type 'Content[]' is not assignable to parameter of type 'SetStateAction<Content_Output[]>'.
    setFetchedSections(filteredSections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyContent]);

  const addQuestion = () => {
    setOpenContentDialog(false);
    const chosenContentData = contents.filter((item) => contentRowSelectModel.includes(item.id));

    if (questionToSection) {
      addQuestionToSection({
        sectionId: questionToSection.sectionId,
        questions: chosenContentData,
      });
      setQuestionToSection(null);
    } else {
      append(chosenContentData);
    }
    setContentRowSelectModel([]);
  };

  const addQuestionToSection = ({ sectionId, questions = [] }: QuestionToSectionProps) => {
    const updatedContent = surveyContent.map((item: SurveyEditorContentProps) => {
      const currentQuestions = item.questions || [];
      if (item.id === sectionId) {
        return {
          ...item,
          questions: [...currentQuestions, ...questions],
        };
      }
      return item;
    });

    setValue('surveyContent', updatedContent);
  };

  const onDeleteContent = (id: number) => {
    remove(id);
  };

  const addSection = () => {
    setOpenSectionDialog(false);
    if (sectionRowSelectModel?.row) {
      append(sectionRowSelectModel.row);
    }
  };

  const deleteQuestionInSection = ({ sectionId, questionId }: QuestionToSectionProps) => {
    const updatedPageContent = surveyContent.map((item: SurveyEditorContentProps) => {
      if (item.id === sectionId) {
        const updatedQuestions = item.questions.filter(
          (q: SurveyEditorContentOutput) => q.id !== questionId
        );
        return {
          ...item,
          questions: updatedQuestions,
        };
      }
      return item;
    });

    setValue('surveyContent', updatedPageContent);
  };

  const addPageBreak = () => {
    append({
      id: new Date().getMilliseconds(),
      type: 'page-break',
      internal_name: {
        en: 'New page',
      },
      title: { en: 'Page break' },
      primitive: {
        repr: 'page-break',
      },
    });
  };

  const handleOnDragEnd = (result: DropResult) => {
    const { source, destination, type } = result;

    if (!destination) {
      return;
    }
    const isSectionQuestion = type === 'section-questions';
    if (isSectionQuestion) {
      const sectionIndex = surveyContent.findIndex(
        (section) => section.id.toString() === source.droppableId
      );
      const questions = getValues(`surveyContent[${sectionIndex}].questions`);

      const [movedQuestion] = questions.splice(source.index, 1);
      questions.splice(destination.index, 0, movedQuestion);

      setValue(`surveyContent[${sectionIndex}].questions`, questions);
    } else {
      move(source.index, destination.index);
    }
  };

  return (
    <Stack>
      <Stack spacing={4}>
        <Stack>
          <Typography variant="h6">
            <FormattedMessage
              id="survey.survey-editor.survey-content.title"
              defaultMessage="Survey content"
            />
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <FormattedMessage
              id="survey.survey-editor.survey-content.description"
              defaultMessage="Edit the main content of your survey and rearrange them in the order you need"
            />
          </Typography>
        </Stack>
        <Stack flexDirection="row" sx={{ gap: 3 }}>
          <Button
            variant="outlined"
            startIcon={<Icon icon={faPlus} />}
            sx={{ width: 140 }}
            onClick={() => setOpenContentDialog(true)}
          >
            <FormattedMessage id="survey.common.content" defaultMessage="Content" />
          </Button>
          <Button
            variant="outlined"
            startIcon={<Icon icon={faPlus} />}
            sx={{ width: 140 }}
            onClick={() => setOpenSectionDialog(true)}
          >
            <FormattedMessage id="survey.common.section" defaultMessage="Section" />
          </Button>
          <Button
            variant="outlined"
            startIcon={<Icon icon={faPlus} />}
            sx={{ width: 140 }}
            onClick={addPageBreak}
          >
            <FormattedMessage id="survey.common.page-break" defaultMessage="Page break" />
          </Button>
        </Stack>
        <Divider style={{ marginInline: '-24px' }} />
      </Stack>
      {!surveyContent ||
        (surveyContent.length < 1 && (
          <EmptyStatePlaceholder
            img={personFillingAForm}
            title={
              <FormattedMessage
                id="survey.survey-editor.content.empty-state.title"
                defaultMessage="No content yet"
              />
            }
            description={
              <FormattedMessage
                id="survey.survey-editor.content.empty-state.description"
                defaultMessage="Click to add questions or messages from the Question and Content library"
              />
            }
            emptyStateContainer={{ height: 450 }}
            action={{
              testId: 'survey-empty-state',
              message: (
                <FormattedMessage id="survey.common.add-content" defaultMessage="Add content" />
              ),
              handler: () => setOpenContentDialog(true),
              styles: { marginTop: 3 },
            }}
          />
        ))}
      <SurveyContentList
        data={fetchedQuestions}
        open={openContentDialog}
        rowSelectionModel={contentRowSelectModel}
        onRowSelectionModelChange={setContentRowSelectModel}
        onCancel={() => setOpenContentDialog(false)}
        onSubmit={addQuestion}
      />
      <SurveySectionList
        data={fetchedSections}
        open={openSectionDialog}
        onRowClick={setSectionRowSelectModel}
        onCancel={() => setOpenSectionDialog(false)}
        onSubmit={addSection}
      />
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppable-survey-content">
          {(provided) => (
            <Stack {...provided.droppableProps} ref={provided.innerRef} spacing={2} mt={3}>
              {surveyContent &&
                surveyContent?.map((content: SurveyEditorContentProps, index) => (
                  <Draggable key={content.id} draggableId={content.id.toString()} index={index}>
                    {(provided) => (
                      <Stack
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        spacing={2}
                      >
                        <ContentItem
                          key={content.id}
                          content={content}
                          type={content.type}
                          onDelete={() => onDeleteContent(index)}
                        >
                          {content.type && content.type === 'section' && (
                            <>
                              <Droppable
                                droppableId={content.id.toString()}
                                type="section-questions"
                              >
                                {(provided) => (
                                  <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {content.questions?.map(
                                      (
                                        question: SurveyEditorContentOutput,
                                        questionIndex: number
                                      ) => (
                                        <Draggable
                                          key={question.id}
                                          draggableId={question.id.toString()}
                                          index={questionIndex}
                                        >
                                          {(provided) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <ContentItem
                                                content={question}
                                                isQuestionInsideSection
                                                onDelete={() =>
                                                  deleteQuestionInSection({
                                                    sectionId: content.id,
                                                    questionId: question.id,
                                                  })
                                                }
                                              />
                                            </div>
                                          )}
                                        </Draggable>
                                      )
                                    )}
                                  </div>
                                )}
                              </Droppable>
                              <Stack sx={{ alignItems: 'center', marginBlock: 2 }}>
                                <Button
                                  variant="text"
                                  startIcon={<Icon icon={faPlus} />}
                                  onClick={() => {
                                    setQuestionToSection({ sectionId: content.id });
                                    setOpenContentDialog(true);
                                  }}
                                >
                                  <FormattedMessage
                                    id="survey.common.content"
                                    defaultMessage="Content"
                                  />
                                </Button>
                              </Stack>
                            </>
                          )}
                        </ContentItem>
                      </Stack>
                    )}
                  </Draggable>
                ))}
            </Stack>
          )}
        </Droppable>
      </DragDropContext>
    </Stack>
  );
}
