import { FormattedMessage, useIntl } from 'react-intl';

import { faCheck, faClock } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { faUserTie } from '@trustyou/fortawesome/pro-solid-svg-icons';

import { commonMessages } from '../../constants/messages';
import { Stamp } from './stamp';
import { StampPair } from './stamp-pair';

export function MarkedAsRespondedStamp({
  date,
  author,
  isConfirmed,
}: {
  date: string;
  author: string;
  isConfirmed: boolean;
}) {
  const intl = useIntl();

  return (
    <StampPair>
      <Stamp
        status={
          <FormattedMessage
            id="inbox.response.status.responded"
            defaultMessage={'Marked as responded: {respondedDate}'}
            values={{ respondedDate: date }}
          />
        }
        icon={isConfirmed ? faCheck : faClock}
      />
      <Stamp
        status={intl.formatMessage(commonMessages.byUsername, { username: author })}
        icon={faUserTie}
      />
    </StampPair>
  );
}
