import { FormattedMessage } from 'react-intl';

import { useResponsive, useUserNavStore } from '@trustyou/shared';
import { Button } from '@trustyou/ui';

export const InviteMemberButton = () => {
  const { isMobileRange } = useResponsive();

  const { navToInvite } = useUserNavStore();

  if (isMobileRange) return null;

  return (
    <Button
      data-testid="invite-user"
      data-gtm-id="invite-member-nav-button"
      variant="outlined"
      onClick={navToInvite}
    >
      <FormattedMessage id="organization.users.inviteMember" defaultMessage="Invite member" />
    </Button>
  );
};
