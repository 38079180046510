import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  topBar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingInline: 2,
    paddingBottom: 2,
  },
  stepBody: {
    display: 'flex',
    height: 'calc(100% - 130px)',
    paddingX: 5,
    paddingTop: 7.5,
    overflow: 'auto',
  },
  footer: {
    paddingX: 5,
    paddingY: 2,
    flexDirection: 'row',
    height: '74px',
  },
  dashboardSelect: { marginBottom: 2, maxWidth: '440px' },
  widgetsHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    paddingY: 1,
  },
  widgetsSelectorContainer: {
    width: '600px',
    marginX: 'auto',
  },
  recipientsContainer: {
    width: '692px',
    marginX: 'auto',
  },
};

export default styles;
