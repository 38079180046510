import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  DEFAULT_SORT_KEY,
  getNumberName,
  useAlertStore,
  useInfiniteSegments,
  useMembershipStore,
  useUpdateDashboardSegments,
} from '@trustyou/shared';
import {
  BackdropSpinner,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SelectionCard,
  Stack,
  Typography,
} from '@trustyou/ui';

import { DashboardDelay } from '../../../../../components';
import styles from './styles';

type EditDashboardSegmentsProps = {
  onClose: () => void;
};

const MAX_DASHBOARD_SEGMENTS = 10;

const EditDashboardSegments = ({ onClose }: EditDashboardSegmentsProps) => {
  const intl = useIntl();
  const [selectedSegments, setSelectedSegments] = useState<string[]>([]);
  const [initialSelectedSegments, setInitialSelectedSegments] = useState<string>('');
  const { data } = useInfiniteSegments(100, DEFAULT_SORT_KEY);
  const segments = useMemo(() => data?.pages.map((page) => page.data).flat() || [], [data]);
  let total = (data?.pages.length && data?.pages[0].pagination.total) || 0;
  total = total > MAX_DASHBOARD_SEGMENTS ? MAX_DASHBOARD_SEGMENTS : total;
  const totalStr = getNumberName(total);
  const { alert } = useAlertStore();
  const { membership } = useMembershipStore();

  const { mutate: save, isPending: isSaving } = useUpdateDashboardSegments(
    () => {
      alert.success(
        <FormattedMessage
          id="organization.segments.changesSaved"
          defaultMessage="Changes saved. {delay}"
          values={{ delay: <DashboardDelay /> }}
        />
      );
      onClose();
    },
    () => {
      alert.genericError();
    }
  );

  useEffect(() => {
    const selected = segments
      .filter((segment) => segment.is_global || segment.selected_for_dashboard)
      .map((segment) => segment.id);
    setSelectedSegments(selected);
    setInitialSelectedSegments(selected.sort().join('_'));
  }, [segments]);

  const isChanged = useMemo(
    () => initialSelectedSegments !== selectedSegments.sort().join('_'),
    [initialSelectedSegments, selectedSegments]
  );

  const toggleSelection = (segmentId: string) => {
    setSelectedSegments(
      selectedSegments.includes(segmentId)
        ? selectedSegments.filter((id) => id !== segmentId)
        : [...selectedSegments, segmentId]
    );
  };

  const onSave = () => {
    save({
      organization_id: membership?.organizationId as string,
      segment_ids: selectedSegments,
    });
  };

  return (
    <>
      <Dialog open onClose={onClose} sx={styles.dialog}>
        <DialogTitle>
          <FormattedMessage
            id="organization.segments.editDashboardSegments"
            defaultMessage="Edit dashboard segments"
          />
          <Stack spacing={1} sx={styles.dialogSubTitle}>
            <Typography variant="body2">
              {totalStr && (
                <FormattedMessage
                  id="organization.segments.dashboardSegmentsSelectionMessage"
                  defaultMessage="Select up to {count} segments to display on the dashboard."
                  values={{ count: intl.formatMessage(totalStr) }}
                />
              )}
            </Typography>
            <Typography variant="subtitle2" textAlign="right" color="text.secondary">
              <FormattedMessage
                id="organization.segments.dashboardSegmentsSelectionCountMessage"
                defaultMessage="{count} of {total} selected"
                values={{ count: selectedSegments.length, total }}
              />
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent sx={styles.dialogContent}>
          <Stack spacing={1}>
            {segments.map((row) => (
              <SelectionCard
                sx={styles.selectionCard}
                key={row.id}
                title={row.name}
                id={row.id}
                selected={selectedSegments.includes(row.id)}
                disabled={
                  row.is_global ||
                  (!selectedSegments.includes(row.id) &&
                    selectedSegments.length === MAX_DASHBOARD_SEGMENTS)
                }
                onClick={() => {
                  toggleSelection(row.id);
                }}
              />
            ))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button data-testid="cancel-save-dashboard-segments" onClick={onClose} color="primary">
            <FormattedMessage id="organization.segments.cancel" defaultMessage="Cancel" />
          </Button>
          <Button
            data-testid="save-dashboard-segments"
            variant="contained"
            onClick={onSave}
            disabled={!isChanged}
          >
            <FormattedMessage
              id="organization.segments.saveChanges"
              defaultMessage="Save changes"
            />
          </Button>
        </DialogActions>
      </Dialog>
      <BackdropSpinner isLoading={isSaving} />
    </>
  );
};

export default EditDashboardSegments;
