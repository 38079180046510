import { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { FETCH_TOKEN_INFO } from '../service/constants';
import { useTokenInfo } from '../service/hooks/domains/ass/auth';
import { usePermissionStore } from '../store';

export const usePermissions = (accessToken?: string | null) => {
  const queryClient = useQueryClient();

  const { permissions, setPermissions, hasPermissions } = usePermissionStore();
  const { data: tokenInfoData } = useTokenInfo({
    enabled: !!accessToken,
  });

  useEffect(() => {
    if (!tokenInfoData || !accessToken) return;

    const newPermissions = Object.entries(tokenInfoData).reduce(
      (prev, [key, value]) => ({
        ...prev,
        [key]: value?.permissions || [],
      }),
      { ...permissions }
    );

    setPermissions(newPermissions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenInfoData]);

  // Update permissions on access token refresh
  useEffect(() => {
    if (accessToken && tokenInfoData) {
      queryClient.removeQueries({ queryKey: [FETCH_TOKEN_INFO] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  return {
    permissions,
    hasPermissions,
  };
};
