export const flipObject = (obj: Record<string, string>): Record<string, string> => {
  return Object.entries(obj).reduce((flipped: Record<string, string>, [key, value]) => {
    flipped[value] = key;
    return flipped;
  }, {});
};

export const capitalizeFirstLetter = (str: string): string =>
  `${str.charAt(0).toUpperCase()}${str.substring(1)}`;

export const areUITestsRunning = (): boolean => {
  if (process.env.STORYBOOK) return true;
  if (process.env.NODE_ENV === 'test') return true;
  return false;
};

export function removeDiacritics(text: string) {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
