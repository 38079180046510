import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useQueryClient } from '@tanstack/react-query';
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_SORT_KEY,
  type Entity,
  FETCH_INFINITE_COMPETITORS,
  searchPlaceholders,
  useCompetitorStore,
  useInfiniteCompetitors,
  useMaxCompetitors,
} from '@trustyou/shared';
import { Box, ListItemText, Selector } from '@trustyou/ui';

interface CompetitorSelectorProps {
  initialSelection?: Entity[];
  isListDisabled?: boolean;
}

export const CompetitorSelector = ({
  initialSelection = [],
  isListDisabled = false,
}: CompetitorSelectorProps) => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const MAX_COMPETITORS_ALLOWED = useMaxCompetitors();

  const { selectedCompetitors, setSelectedCompetitors } = useCompetitorStore();
  const [searchKey, setSearchKey] = useState('');

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isPending: isLoading,
  } = useInfiniteCompetitors(DEFAULT_PAGE_SIZE, DEFAULT_SORT_KEY, searchKey);
  const [competitors, setCompetitors] = useState<Entity[]>([]);

  useEffect(() => {
    setSelectedCompetitors(initialSelection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCompetitors(data?.pages.map((page) => page.data).flat() || []);
  }, [data]);

  const onFetchNextPage = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  const onSearch = (value: string) => {
    setSearchKey(value);
    queryClient.removeQueries({ queryKey: [FETCH_INFINITE_COMPETITORS] });
  };

  const renderRowContent = (entity: Entity) => (
    <ListItemText primary={entity.name} secondary={`${entity.city}, ${entity.country_name}`} />
  );

  return (
    <Box>
      <Selector
        isLoading={isLoading}
        items={competitors}
        onSelectItems={setSelectedCompetitors}
        onSearch={onSearch}
        renderRowContent={renderRowContent}
        selectedItems={selectedCompetitors}
        searchPlaceholder={intl.formatMessage(searchPlaceholders.searchCompetitors)}
        selectedHeaderTitle={
          <FormattedMessage
            id="organization.competitors.selected"
            defaultMessage="Selected competitors ({count}/{max})"
            values={{
              count: selectedCompetitors.length,
              max: MAX_COMPETITORS_ALLOWED,
            }}
          />
        }
        onFetchNextPage={onFetchNextPage}
        isListDisabled={isListDisabled}
        hideSelectResults
      />
    </Box>
  );
};
