import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { lightBlue } from '@mui/material/colors';
import type { GridCellParams, GridPaginationModel, GridRowParams } from '@mui/x-data-grid-pro';
import { DEFAULT_PAGE_SIZE, useLanguageStore } from '@trustyou/shared';
import {
  Button,
  Chip,
  DataGrid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@trustyou/ui';

import type { Content_Output } from '../../../../../types/survey';
import { CustomToolbar } from '../../../../shared/custom-toolbar';
import { EDITOR_QUESTION_CONTENT_TYPES } from '../../../constants/survey-editor-content';

type SurveySectionListProps = {
  data: Content_Output[];
  open: boolean;
  onRowClick: (newSelection: GridRowParams) => void;
  onCancel: () => void;
  onSubmit: () => void;
};

const columns = (lang: string) => [
  {
    field: 'name',
    headerName: 'Name',
    renderCell: ({ row }: GridCellParams) => (
      <Stack>
        <Typography variant="body2">
          {row.title[lang] ?? row.title[row.default_language]}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {row.description[lang] ?? row.description[row.default_language]}
        </Typography>
      </Stack>
    ),
    flex: 1,
  },
  {
    field: 'type',
    headerName: 'Type',
    flex: 1,
    renderCell: ({ row }: GridCellParams) => (
      <Stack sx={{ gap: 1, flexDirection: 'row', alignItems: 'center' }}>
        <Typography variant="body2">
          {
            EDITOR_QUESTION_CONTENT_TYPES[row.type as keyof typeof EDITOR_QUESTION_CONTENT_TYPES]
              ?.label
          }
        </Typography>
      </Stack>
    ),
  },
  {
    field: 'category',
    headerName: 'Category',
    renderCell: ({ row }: GridCellParams) => (
      <Chip
        // label={row.category.toUpperCase()}
        // sx={{
        //   color: row.category === 'custom' ? 'info.main' : blueGrey[600],
        //   backgroundColor: row.category === 'custom' ? lightBlue[50] : blueGrey[50],
        // }}
        label={'Custom'}
        sx={{
          color: 'info.main',
          backgroundColor: lightBlue[50],
        }}
      />
    ),
    flex: 1,
  },
];

export const SurveySectionList = ({
  data,
  open,
  onRowClick,
  onCancel,
  onSubmit,
}: SurveySectionListProps) => {
  const { locale } = useLanguageStore();
  const userSettingLang = locale.split('-')[0];
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  return (
    <Dialog onClose={onCancel} open={open} maxWidth="lg" fullWidth>
      <DialogTitle>
        <FormattedMessage
          id="survey.survey-editor.section-list.dialog.title"
          defaultMessage="Select section title"
        />
        <Typography variant="body2" color="text.secondary">
          <FormattedMessage
            id="survey.survey-editor.section-list.dialog.description"
            defaultMessage="Section titles can be created and edited in the Content library. "
          />
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DataGrid
          disableColumnMenu={false}
          rowHeight={64}
          rows={data}
          columns={columns(userSettingLang)}
          columnVisibilityModel={{
            id: false,
          }}
          pagination
          pageSizeOptions={[5, 10, 25]}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          disableRowSelectionOnClick={false}
          onRowClick={onRowClick}
          slots={{
            toolbar: () => CustomToolbar({ hideColumns: true }),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="inherit">
          <FormattedMessage id="survey.common.cancel" defaultMessage="Cancel" />
        </Button>
        <Button onClick={onSubmit} autoFocus variant="contained">
          <FormattedMessage id="survey.common.add-selected" defaultMessage="Add selected" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
