import type { ReactNode } from 'react';

import type { SxProps, Theme } from '@mui/material';
import {
  Asterisk,
  Box,
  FormControl,
  InputLabel,
  StyledFormControl,
  Typography,
} from '@trustyou/ui';

interface SettingSectionProps {
  fieldId?: string;
  title: ReactNode;
  description: ReactNode;
  label?: ReactNode;
  input?: ReactNode;
  sx?: SxProps<Theme>;
  children?: ReactNode;
}

export function SettingsSection({
  fieldId,
  title,
  description,
  label,
  input,
  sx,
  children,
}: SettingSectionProps) {
  return (
    <Box sx={{ width: '100%', ...sx }}>
      <Typography fontWeight={500} variant="subtitle1">
        {title}
      </Typography>
      <Typography color="text.secondary">{description}</Typography>
      {children}
    </Box>
  );
}
