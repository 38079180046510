import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { commonFields } from '@trustyou/shared';
import { Button } from '@trustyou/ui';

import { DiscardChangesModal } from '..';

type Props = {
  isDirty: boolean;
  onCancel: () => void;
};

export const CancelEditReport = ({ isDirty, onCancel }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Button
        onClick={() => {
          isDirty ? setIsOpen(true) : onCancel();
        }}
        type="button"
      >
        <FormattedMessage {...commonFields.cancel} />
      </Button>
      <DiscardChangesModal isOpen={isOpen} onCancel={() => setIsOpen(false)} onDiscard={onCancel} />
    </>
  );
};
