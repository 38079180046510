export enum CustomAttributeType {
  TEXT = 'text',
  SELECT = 'select',
  MULTI_SELECT = 'multi_select',
}

export type CustomAttributeDefinitionValue = {
  name: string;
  id?: string;
};

export type CustomAttributeDefinition = {
  id: string;
  name: string;
  description?: string;
  type: CustomAttributeType;
  mandatory: boolean;
  default: CustomAttributeDefinitionValue[];
  options: CustomAttributeDefinitionValue[];
  unique: boolean;
};

export type CustomAttributeDeleteCheckResponse = {
  valid: boolean;
  segment_names?: string[];
};

export type CustomAttributeUpdateCheckResponse = {
  valid: boolean;
  number_of_affected_owned_entities?: number;
  segment_names_by_option?: Record<string, string[]>;
};

export type EntityCustomAttributes = {
  [key: string]: string | string[];
};

export type EntityCustomAttributesUpdatePayload = {
  entity_id: string;
  attributes: EntityCustomAttributes;
};
