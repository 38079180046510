import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useChangelingStore, useLanguageStore } from '@trustyou/shared';
import { Box, Divider, Stack, Typography } from '@trustyou/ui';

import { METADATA_SECTION_HEIGHT } from '../../constants';
import { useStore } from '../../store/store';
import type { ReviewRoot } from '../../types';
import { formatNewSurveyGuestInfo } from '../../utils/guest-information';
import { hasMatches } from '../../utils/sema';
import CustomAccordion from '../custom-accordion';
import { GuestInformationDialog } from '../guest-information-dialog';
import { MetaData } from '../meta-data/meta-data';
import { ScoreChip } from '../score-chip';
import { SemanticAnalysis } from '../semantic-analysis';
import { Survey } from '../survey';

export type ReviewItemProps = {
  shouldDisplayTranslation?: boolean;
};

export const ReviewItem = ({ shouldDisplayTranslation = false }: ReviewItemProps) => {
  const intl = useIntl();
  const { isChangeling } = useChangelingStore();
  const { locale } = useLanguageStore();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggle = () => setIsOpen(!isOpen);

  const reviewRoot = useStore.use.reviewRoot();
  const { review, survey, sema_matches } = (reviewRoot ?? {}) as ReviewRoot;

  const translatedTitle = review?.translations?.[locale]?.title;
  const title = shouldDisplayTranslation && translatedTitle ? translatedTitle : review?.title;

  const translatedText = review?.translations?.[locale]?.text;
  const text = shouldDisplayTranslation && translatedText ? translatedText || '' : review?.text;

  const formatGuestInformation = () => {
    if (survey) {
      return formatNewSurveyGuestInfo(survey.contact_info);
    }
    // in this case its just a review so show the author name
    return [{ label: 'name', value: review?.author }];
  };

  return (
    <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
      <MetaData onPressGuestIcon={toggle} />
      <Stack
        sx={{
          height: `calc(100% - ${METADATA_SECTION_HEIGHT})`,
          overflowY: 'auto',
          justifyContent: 'space-between',
        }}
      >
        <Stack spacing={2} padding={3}>
          <Stack direction="row">
            <Box width="40px">
              <ScoreChip value={review?.score ?? 0} isRound />
            </Box>
            <Typography
              variant="h6"
              sx={{
                fontSize: 14,
                marginLeft: 1,
                display: 'flex',
                alignItems: 'center',
                whiteSpace: 'pre-wrap',
                ...(!title && { color: (theme) => theme.palette.text.secondary }),
              }}
            >
              {title || <FormattedMessage id="inbox.no-title" defaultMessage="No title" />}
            </Typography>
          </Stack>
          <Typography
            variant="body2"
            sx={{
              overflowY: 'auto',
              whiteSpace: 'pre-wrap',
              ...(!text && { color: (theme) => theme.palette.text.secondary }),
            }}
          >
            {text || <FormattedMessage id="inbox.no-summary" defaultMessage="No summary" />}
          </Typography>
          {survey && survey?.answers?.length > 0 && <Survey />}
        </Stack>
        {sema_matches && hasMatches(sema_matches) && (
          <Box sx={isChangeling ? { marginBottom: '20px' } : {}}>
            <Divider />
            <Box>
              <CustomAccordion
                summary={intl.formatMessage({
                  id: 'inbox.review.semanticAnalysis.accordion.title',
                  defaultMessage: 'Semantic analysis',
                })}
                details={<SemanticAnalysis />}
              />
            </Box>
          </Box>
        )}
      </Stack>
      <GuestInformationDialog isOpen={isOpen} onClose={toggle} data={formatGuestInformation()} />
    </Box>
  );
};
