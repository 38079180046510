import { useMutation, useQueryClient } from '@tanstack/react-query';
import { $assApi, ANALYTICS_BASE_PATH } from '@trustyou/shared';
import type { AxiosError } from 'axios';

import {
  HAS_REPORTS_CREATED,
  REPORTS_HISTORY,
  REPORT_LIST,
  SCHEDULE_REPORT,
} from '../../constants/query-keys';
import type { NewReportSchema, ScheduledReport } from '../../types/reports';

async function scheduleReport(report: NewReportSchema): Promise<ScheduledReport> {
  const { data } = await $assApi.post(
    `${ANALYTICS_BASE_PATH}/report/${report.period === 'one-time' ? 'one_time_register' : 'register'}`,
    report
  );
  return data;
}

export const useScheduleReport = (
  onSuccess: (data: ScheduledReport) => void,
  onError?: (error: AxiosError<unknown>) => void
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: scheduleReport,
    mutationKey: [SCHEDULE_REPORT],
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [REPORT_LIST] });
      queryClient.invalidateQueries({ queryKey: [REPORTS_HISTORY] });
      queryClient.invalidateQueries({ queryKey: [HAS_REPORTS_CREATED] });
      onSuccess(data);
    },
    onError,
  });
};
