import { IntlErrorCode, type OnErrorFn } from '@formatjs/intl';

export const getLocale = () => localStorage.getItem('locale') || 'en-US';

export const setLocale = (locale: string) => {
  localStorage.setItem('locale', locale);
  window.location.reload();
};

export const ignoreMissingTranslationWarnings: OnErrorFn = (err) => {
  if (err.code === IntlErrorCode.MISSING_TRANSLATION) {
    return;
  }
  console.error(err);
};
