import { useEffect } from 'react';

import {
  initUserlane,
  passUserlaneBreakpoint,
  useLanguageStore,
  useMembershipStore,
  useResponsive,
  useScript,
} from '@trustyou/shared';

import useCurrentUser from '../useCurrentUser';

const USERLANE_URL = 'https://cdn.userlane.com/userlane.js';

const useUserlane = () => {
  const { membership } = useMembershipStore();
  const { locale } = useLanguageStore();
  const { currentBreakpoint } = useResponsive();
  const currentUser = useCurrentUser();

  useScript({
    tag: 'userlane-script',
    src: USERLANE_URL,
    enabled: !!membership && !!process.env.REACT_APP_USERLANE_ID && !!currentUser?.id,
    onLoadCallback: () => initUserlane(currentUser?.id as string, locale, currentBreakpoint),
  });

  useEffect(() => {
    if (currentUser?.id) {
      passUserlaneBreakpoint(currentUser.id, currentBreakpoint);
    }
  }, [currentBreakpoint, currentUser]);
};

export default useUserlane;
