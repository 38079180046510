import type { IStyles } from '@trustyou/shared';

export const styles: IStyles = {
  pageContainer: {
    flex: 1,
    overflow: 'auto',
  },
  headerStyles: { paddingX: 3, paddingTop: 2 },
  emptyStateContainer: { marginBottom: 2 },
  emptyStateHeader: { marginTop: 3 },
  emptyStateAction: { marginTop: 3 },
  createButton: { marginLeft: 'auto' },
};
