import type { ReactNode } from 'react';

import { EmptyStatePlaceholder } from '@trustyou/ui';

import magnifyingGlassSearchingFile from '../../assets/illustrations/magnifying-glass-searching-file.svg';
import styles from './styles';

type EmptyReviewMatchesProps = {
  title: ReactNode;
  description: ReactNode;
};

export const EmptyReviewMatches = ({ title, description }: EmptyReviewMatchesProps) => (
  <EmptyStatePlaceholder
    img={magnifyingGlassSearchingFile}
    title={title}
    description={description}
    emptyStateHeader={styles.emptyStateHeader}
    emptyStateContainer={{ ...styles.emptyStateContainer, mb: 3 }}
  />
);
