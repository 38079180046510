import type { IntlShape } from 'react-intl';

import * as yup from 'yup';

import { EMAIL_VALIDATION, validationErrors } from '../constants';
import {
  type AccessGroup,
  type CombinedUserAndInvitation,
  type CurrentUser,
  type Subscription,
  USER_ROLES,
  USER_SCOPES,
  type UserScopeDetails,
} from '../types';

export const getSimpleUserValidationSchema = (intl: IntlShape) => {
  const invalidError = intl.formatMessage(validationErrors.invalidEmail);

  return yup.object().shape({
    email: yup.string().email(invalidError).matches(EMAIL_VALIDATION, invalidError).required(),
  });
};

export const getUserValidationSchema = (intl: IntlShape) => {
  const invalidError = intl.formatMessage(validationErrors.invalidEmail);

  return yup.object().shape({
    email: yup.string().email(invalidError).matches(EMAIL_VALIDATION, invalidError).required(),
  });
};

export const detectUserScope = (scope?: UserScopeDetails | AccessGroup) => {
  // return none if a scope is null or undefined for any reason
  if (!scope) return USER_SCOPES.NONE;
  if (scope.all) return USER_SCOPES.ALL_ENTITIES;
  if (scope.subscription_id) return USER_SCOPES.ALL_ENTITIES;
  if ('reusable' in scope && scope.reusable) return USER_SCOPES.ACCESS_GROUP;
  if (scope.entities.length) {
    return USER_SCOPES.BY_ENTITIES;
  }
  if (scope.groups.length) {
    return USER_SCOPES.BY_GROUPS;
  }
  if (scope.entities.length === 0) {
    // return 0 entities if lase entity was deleted
    return USER_SCOPES.BY_ENTITIES;
  }
  // return none for other possible specific errors
  return USER_SCOPES.NONE;
};

export const checkUserScopeIsAllowed = (scope: UserScopeDetails, subscription: Subscription) => {
  const detectedScope = detectUserScope(scope);
  // seat type single
  if (subscription.seat_type === 'single') {
    switch (detectedScope) {
      case USER_SCOPES.NONE:
        return true;
      case USER_SCOPES.BY_ENTITIES:
        return scope.entities.length < 2;
      case USER_SCOPES.ALL_ENTITIES:
      case USER_SCOPES.BY_GROUPS:
      case USER_SCOPES.ACCESS_GROUP:
      default:
        return false;
    }
  }

  // single org
  if (subscription.number_of_allowed_entities === 1) {
    switch (detectedScope) {
      case USER_SCOPES.NONE:
      case USER_SCOPES.ALL_ENTITIES:
      case USER_SCOPES.BY_ENTITIES:
        return true;
      case USER_SCOPES.BY_GROUPS:
      case USER_SCOPES.ACCESS_GROUP:
      default:
        return false;
    }
  }

  // seat type multi entity subscription
  if (subscription.is_org_subscription === false) {
    switch (detectedScope) {
      case USER_SCOPES.NONE:
      case USER_SCOPES.ALL_ENTITIES:
      case USER_SCOPES.BY_ENTITIES:
        return true;
      case USER_SCOPES.BY_GROUPS:
      case USER_SCOPES.ACCESS_GROUP:
      default:
        return false;
    }
  }
  return true;
};

export enum USER_ACTIONS {
  READ_ONLY = 'read_only',
  EDIT_AND_DELETE = 'edit_and_delete',
}

export const manageUserActions = (
  user: CombinedUserAndInvitation,
  isAllowed: boolean,
  currentUser?: CurrentUser
): USER_ACTIONS => {
  if (!currentUser) return USER_ACTIONS.READ_ONLY;

  // cannot do anything with owner
  if (user.role === USER_ROLES.OWNER) return USER_ACTIONS.READ_ONLY;

  // current user could be changed only via setting, not via users table
  if (currentUser.membershipId === user.membership_id) return USER_ACTIONS.READ_ONLY;

  if (!isAllowed) return USER_ACTIONS.READ_ONLY;

  return USER_ACTIONS.EDIT_AND_DELETE;
};

export const getDefaultRole = (isEntityManager?: boolean) =>
  isEntityManager ? USER_ROLES.EDITOR : USER_ROLES.ADMIN;

export const getDefaultScope = (subscription: Subscription, isEntityManager?: boolean) => {
  if (subscription.number_of_consumed_entities === 0 && subscription.number_of_allowed_entities > 0)
    return USER_SCOPES.NONE;
  if (subscription.seat_type === 'single' || isEntityManager) return USER_SCOPES.BY_ENTITIES;
  return USER_SCOPES.ALL_ENTITIES;
};
