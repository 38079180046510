import { blueGrey, grey } from '@mui/material/colors';
import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  container: {
    padding: 3,
    border: `1px solid ${grey[300]}`,
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '600px',
  },
  spotIcon: { backgroundColor: blueGrey[300], color: 'white' },
  textColumn: { ml: 2, display: 'flex', flexDirection: 'column' },
  addressRow: { color: 'text.secondary' },
  deleteButton: {
    width: '48px',
    height: '48px',
    marginLeft: 'auto',
  },
};

export default styles;
