/* eslint-disable @typescript-eslint/no-explicit-any */
import MuiPagination from '@mui/material/Pagination';
import type { TablePaginationProps } from '@mui/material/TablePagination';
import { GridPagination } from '@mui/x-data-grid-pro';

const Pagination = ({
  page,
  onPageChange,
  className,
  count,
  rowsPerPage,
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className' | 'count' | 'rowsPerPage'>) => {
  const pageCount = Math.ceil(count / rowsPerPage);

  return (
    <MuiPagination
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1);
      }}
    />
  );
};

export const CustomPagination = (props: any) => {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
};
