/* eslint-disable no-useless-escape */
export const objToBase64Url = (obj: object): string => {
  // First, convert the object to a JSON string
  const jsonString = JSON.stringify(obj);

  // Then, encode this JSON string to a Uint8Array using TextEncoder
  const uintArray = new TextEncoder().encode(jsonString);

  // Convert the Uint8Array to a binary string
  const binaryString = String.fromCharCode(...uintArray);

  // Finally, encode this binary string to base64
  return btoa(binaryString)
    .replace(/\+/g, '-') // Replace '+' with '-'
    .replace(/\//g, '_') // Replace '/' with '_'
    .replace(/=+$/, ''); // Remove trailing '='
};

export const base64UrlToObj = (base64Url: string): object => {
  // Decode the base64 string to a binary string
  const binaryString = atob(base64Url.replace(/-/g, '+').replace(/_/g, '/'));

  // Convert the binary string to a Uint8Array
  const uintArray = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    uintArray[i] = binaryString.charCodeAt(i);
  }

  // Decode the Uint8Array to a JSON string using TextDecoder
  const jsonString = new TextDecoder().decode(uintArray);

  // Parse the JSON string back to an object
  return JSON.parse(jsonString);
};
