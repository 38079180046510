import type { Image } from '../../../client';
import type { ImageRow } from '../../../types/content';

export function useRows(contents: Image[] = []) {
  const rows: ImageRow[] = contents.map((image) => {
    const preview = image.path;

    const fileName = image.path.split(/[\\/]/).pop() ?? 'no_filename';

    // TODO: when a category is 'custom'?
    const category = 'predefined';

    // FIXME: read image type from backend
    const isLogo = image.path.endsWith('.png');
    const imageType = isLogo ? 'logo' : 'banner';

    const size = '1 MB';

    return {
      ...image,
      preview,
      fileName,
      category,
      imageType,
      size,
    };
  });

  return rows;
}
