import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import type { UploadError } from '@trustyou/shared';
import { Alert, AlertTitle } from '@trustyou/ui';

const UndefinedError = ({ type, message }: UploadError) => {
  const getErrorMessage = () => {
    if (type && message) {
      return <FormattedMessage id={type} defaultMessage={message} />;
    }
    if (message) {
      return message;
    }
    return (
      <FormattedMessage
        id="alert.default.error"
        defaultMessage="Something went wrong. Please try again."
      />
    );
  };

  return (
    <Alert severity="error">
      <AlertTitle>{getErrorMessage()}</AlertTitle>
    </Alert>
  );
};

export default UndefinedError;
