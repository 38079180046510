import { ButtonGroup } from '../../components/button-group';
import { CheckboxGroup } from '../../components/checkbox-group';
import { DropdownMultiple } from '../../components/dropdown-multiple';
import { DropdownSingle } from '../../components/dropdown-single';
import { Question, type QuestionProps } from '../../components/question';
import { RadioGroup } from '../../components/radio-group';
import type { Option } from '../../types';

export type SelectQuestionVariant =
  | 'dropdown_single'
  | 'dropdown_multiple'
  | 'checkbox_group'
  | 'radio_group'
  | 'button_group';

export type SelectQuestionProps = QuestionProps & {
  options: Option[];
  variant?: SelectQuestionVariant;
};

export function SelectQuestion({ variant = 'dropdown_single', ...props }: SelectQuestionProps) {
  return (
    <Question {...props}>
      <>
        {variant === 'dropdown_single' && <DropdownSingle {...props} />}
        {variant === 'dropdown_multiple' && <DropdownMultiple {...props} />}
        {variant === 'checkbox_group' && <CheckboxGroup {...props} />}
        {variant === 'radio_group' && <RadioGroup {...props} />}
        {variant === 'button_group' && <ButtonGroup {...props} />}
      </>
    </Question>
  );
}
