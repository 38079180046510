import { $assApi, INBOX_BASE_PATH } from '@trustyou/shared';
import type { AxiosResponse } from 'axios';

import type {
  PaginatedDataResponse_Union_list_ReviewEnvelopeOverviewSchema___list_ReviewEnvelopeDetailSchema___,
  ResponseIn,
  ReviewIn,
  ReviewModerationRequestIn,
  ReviewModerationRevisionIn,
} from '../../../client';
import mockSurveyTypes from '../../../mocks/api/v1/reviews/survey-types.json';
import type {
  GetResponseUrlSuccess,
  GetSourceListSuccess,
  MarkReviewAsDeletedPayload,
  ReviewRoot,
  ReviewsInfo,
  SetReviewStatusPayload,
  SetReviewStatusSuccess,
  UserFilters,
} from '../../../types';
import { mapViewFilters } from '../../../utils/mappers';

export async function fetchReviewsInfo(): Promise<ReviewsInfo> {
  const { data } = await $assApi.get(`${INBOX_BASE_PATH}/reviews/get-info`);
  return data;
}

export async function fetchSources(): Promise<GetSourceListSuccess> {
  const { data } = await $assApi.get(`${INBOX_BASE_PATH}/reviews/get-sources`);
  return data;
}

export const fetchSurveyTypes = async () => {
  // TODO: call the endpoint when backend implement it (it is already implemented in classic platform, cxp will have it in version 1)
  //   const { data } = await $assApi.get(`${INBOX_BASE_PATH}/reviews/get-survey-types`);
  //   return data;
  return mockSurveyTypes;
};

export async function fetchReview({
  reviewId,
  locale,
  isTranslated,
}: {
  reviewId: string;
  locale: string;
  isTranslated?: boolean;
}): Promise<ReviewRoot> {
  let request: Promise<AxiosResponse>;
  if (isTranslated) {
    request = $assApi.get(`${INBOX_BASE_PATH}/reviews/translate/${reviewId}`, {
      params: {
        target_language: locale,
      },
    });
  } else {
    request = $assApi.get(`${INBOX_BASE_PATH}/reviews/get-review/${reviewId}`);
  }
  const { data } = await request;
  return data;
}

export async function saveResponse({
  review_id,
  payload,
}: {
  review_id: string;
  payload: ResponseIn;
}): Promise<string> {
  const { data } = await $assApi.post(
    `${INBOX_BASE_PATH}/reviews/save-response/${review_id}`,
    payload
  );
  return data;
}

export async function fetchReviews(
  payload: ReviewIn
): Promise<PaginatedDataResponse_Union_list_ReviewEnvelopeOverviewSchema___list_ReviewEnvelopeDetailSchema___> {
  const { data } = await $assApi.post(`${INBOX_BASE_PATH}/reviews/filter`, payload);
  return data;
}

export async function fetchDetailedReviews(
  payload: ReviewIn
): Promise<PaginatedDataResponse_Union_list_ReviewEnvelopeOverviewSchema___list_ReviewEnvelopeDetailSchema___> {
  const { data } = await $assApi.post(`${INBOX_BASE_PATH}/reviews/filter`, payload, {
    params: {
      detail: true,
    },
  });
  return data;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function downloadStreamFile({
  response,
  type = 'text/csv',
  filename = 'export.csv',
}: {
  response: AxiosResponse;
  type?: string;
  filename?: string;
}) {
  // Convert data into a blob
  const { data } = response;
  const blob = new Blob([data], { type });

  // URL for the blob
  const url = window.URL.createObjectURL(blob);

  // Create a link and click it to download the file
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  // Append the link to the body (required for Firefox)
  document.body.appendChild(link);
  link.click();

  // Clean up
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}

export async function exportReviews({ filters, sort, offset }: ReviewIn) {
  const sources = await fetchSources();
  return $assApi
    .post(
      `${INBOX_BASE_PATH}/reviews/export`,
      {
        filters: mapViewFilters(filters as UserFilters, sources),
        sort,
        offset,
      },
      {
        responseType: 'blob', // specify the response type as blob
      }
    )
    .then(async (response) => {
      const filename = response.headers['content-disposition']?.split('=')[1];
      const type = response.headers['content-type']?.split(';')[0];

      await downloadStreamFile({ response, type, filename });
    });
}

export async function setReviewReadStatus(
  payload: SetReviewStatusPayload
): Promise<SetReviewStatusSuccess> {
  const { data } = await $assApi.post(`${INBOX_BASE_PATH}/reviews/set-status-read-unread`, payload);
  return data;
}

export async function setReviewRespondedStatus(
  payload: SetReviewStatusPayload
): Promise<SetReviewStatusSuccess> {
  const { data } = await $assApi.post(
    `${INBOX_BASE_PATH}/reviews/set-status-responded-unresponded`,
    payload
  );
  return data;
}

export async function fetchResponseUrl(reviewId: string): Promise<GetResponseUrlSuccess> {
  const { data } = await $assApi.get(`${INBOX_BASE_PATH}/reviews/get-response-url/${reviewId}`);
  return data;
}

export async function markAsDeleted(payload: MarkReviewAsDeletedPayload): Promise<string> {
  const { data } = await $assApi.post(`${INBOX_BASE_PATH}/reviews/mark-as-deleted`, payload);
  return data;
}

export async function requestModeration(payload: ReviewModerationRequestIn): Promise<string> {
  const { data } = await $assApi.post(`${INBOX_BASE_PATH}/reviews/mark-as-inappropriate`, payload);
  return data;
}

export async function rejectModeration(payload: ReviewModerationRevisionIn): Promise<string> {
  const { data } = await $assApi.post(
    `${INBOX_BASE_PATH}/reviews/mark-as-inappropriate/reject`,
    payload
  );
  return data;
}

export async function approveModeration(payload: ReviewModerationRevisionIn): Promise<string> {
  const { data } = await $assApi.post(
    `${INBOX_BASE_PATH}/reviews/mark-as-inappropriate/approve`,
    payload
  );
  return data;
}
