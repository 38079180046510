import type { EntitiesResponse, SetCompetitorsPayload } from '../../../../types/model/entity';
import type { PaginationRequest } from '../../../../types/model/shared';
import { $assApi } from '../../../api';
import { ORGANIZATION_BASE_PATH } from '../../path';

export async function fetchCompetitors(
  { limit, offset }: PaginationRequest,
  sortKey: string,
  searchKey?: string
): Promise<EntitiesResponse> {
  const { data } = await $assApi.get(`${ORGANIZATION_BASE_PATH}/search_competitors`, {
    params: {
      limit,
      offset,
      order: sortKey,
      ...(searchKey && { q: searchKey }),
    },
  });
  return data;
}

export async function setEntityCompetitors(payload: SetCompetitorsPayload): Promise<string> {
  const { data } = await $assApi.post(
    `${ORGANIZATION_BASE_PATH}/set_owned_entity_competitors`,
    payload
  );
  return data.data;
}
