import { Box, ListItem, ListItemText, Typography } from '@trustyou/ui';

import styles from './styles';

type BulletItemProps = {
  primaryText: string;
  secondaryText: string;
};

export const BulletItem = ({ primaryText, secondaryText }: BulletItemProps) => (
  <ListItem>
    <ListItemText>
      <Box sx={styles.listItemIcon}>•</Box>
      <Typography variant="body1">
        <Typography variant="subtitle1" sx={styles.listItemPrimaryText}>
          {primaryText}
        </Typography>
        : {secondaryText}
      </Typography>
    </ListItemText>
  </ListItem>
);
