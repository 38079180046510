import { FormattedMessage } from 'react-intl';

import { Box, Button, Typography } from '@trustyou/ui';

import forbiddenDoor from '../../assets/illustrations/403-forbidden-door.svg';
import styles from './styles';

export interface ForbiddenRouteProps {
  onGoBack: () => void;
}

export const ForbiddenRoute = ({ onGoBack }: ForbiddenRouteProps) => (
  <Box sx={styles.wrapper}>
    <img src={forbiddenDoor} alt="Forbidden door with status code 403" />
    <Box sx={styles.textWrapper}>
      <Typography variant="h6">
        <FormattedMessage id="tyDesign.permissionRequired" defaultMessage="Permission required" />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage
          id="tyDesign.dontHavePermission"
          defaultMessage="It seems you don't have the required permissions to view this page. Let your admin know to gain access to this page."
        />
      </Typography>
    </Box>
    <Button variant="contained" data-testid="go-back-button" onClick={onGoBack}>
      <FormattedMessage id="tyDesign.backToHome" defaultMessage="Back to home" />
    </Button>
  </Box>
);
