import { FormattedMessage } from 'react-intl';

import { faInfoCircle } from '@trustyou/fortawesome/pro-solid-svg-icons';
import { Alert, StyledFontAwesomeIcon } from '@trustyou/ui';

import { ALERT_INFO_TITLE_COLOR } from '../../constants';

export function MarkedAsDeletedReviewAlert() {
  return (
    <Alert
      severity="info"
      icon={<StyledFontAwesomeIcon icon={faInfoCircle} size="xs" color={ALERT_INFO_TITLE_COLOR} />}
      sx={{
        borderRadius: 0,
        padding: 2,
        '& .MuiAlert-message': { padding: 0 },
        '& .MuiAlert-icon': { paddingBlockStart: 0.5 },
      }}
    >
      <FormattedMessage
        id="inbox.review.delete.info-banner.marked-as-deleted"
        defaultMessage="This review was manually marked as deleted. If the deletion cannot be confirmed with the source, the review will reappear in the Inbox as unread within 36 hours."
      />
    </Alert>
  );
}
