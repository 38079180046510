import { FormattedMessage } from 'react-intl';

import { Box, Typography } from '@trustyou/ui';

import magnifyingGlassSearchingFile from '../../assets/illustrations/magnifying-glass-searching-file.svg';
import styles from './styles';

export const EmptyReviewProviderState = () => (
  <Box sx={styles.noRowsWrapper}>
    <img src={magnifyingGlassSearchingFile} alt="No providers" />
    <Box sx={styles.noRowsTextWrapper}>
      <Typography variant="h6">
        <FormattedMessage
          id="review-providers.empty-state.title"
          defaultMessage="No review providers yet"
        />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage
          id="review-providers.empty-state.description"
          defaultMessage="Make sure you’ve verified and published your business profile"
        />
      </Typography>
    </Box>
  </Box>
);
