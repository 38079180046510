import type { ReactNode } from 'react';

import type { DialogProps } from '@mui/material';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '../../mui';

export interface DeleteModalProps extends Omit<DialogProps, 'title' | 'content'> {
  title?: ReactNode;
  content: ReactNode;
  open: boolean;
  deleteText: ReactNode;
  cancelText: ReactNode;
  onClose: () => void;
  onDelete: () => void;
  modalStyles?: object;
  contentStyles?: object;
  dataName: string;
  isDeleteInProgress?: boolean;
}

export const DeleteModal = ({
  title,
  content,
  open,
  onClose,
  onDelete,
  modalStyles,
  contentStyles,
  deleteText,
  cancelText,
  dataName,
  isDeleteInProgress,
  ...props
}: DeleteModalProps) => (
  <Dialog {...props} sx={modalStyles || {}} open={open} onClose={onClose}>
    {title && (
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>{title}</DialogTitle>
    )}
    <DialogContent style={contentStyles}>
      <Typography variant="body2">{content}</Typography>
    </DialogContent>

    <DialogActions>
      <Button
        sx={{ textTransform: 'none' }}
        data-testid={`${dataName}-cancel-delete`}
        onClick={onClose}
        color="inherit"
      >
        {cancelText}
      </Button>
      <Button
        sx={{ textTransform: 'none' }}
        data-testid={`${dataName}-accept-delete`}
        onClick={!isDeleteInProgress ? onDelete : () => {}}
        variant="contained"
        color="error"
        disabled={isDeleteInProgress}
        startIcon={isDeleteInProgress ? <CircularProgress size={20} color="inherit" /> : null}
      >
        {deleteText}
      </Button>
    </DialogActions>
  </Dialog>
);
