import { FormattedMessage } from 'react-intl';

import type { SegmentGroup } from '@trustyou/shared';
import { DeleteModal } from '@trustyou/ui';

type DeleteGroupsProps = {
  group?: SegmentGroup;
  onClose: () => void;
  onDelete: () => void;
};

export const DeleteGroups = ({ group, onClose, onDelete }: DeleteGroupsProps) => (
  <DeleteModal
    cancelText={<FormattedMessage id="organization.segments.cancel" defaultMessage="Cancel" />}
    content={
      <FormattedMessage
        id="organization.segments.deleteGroupContent"
        defaultMessage="You are deleting the following group: {group}. If you confirm, the group will be removed from the organization and user scopes"
        values={{ group: group?.name }}
      />
    }
    dataName="group-delete"
    deleteText={<FormattedMessage id="organization.segments.delete" defaultMessage="Delete" />}
    onClose={onClose}
    onDelete={onDelete}
    title={
      <FormattedMessage
        id="organization.segments.deleteGroupQuestion"
        defaultMessage="Delete group?"
      />
    }
    open={!!group}
  />
);
