import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { faCircleQuestion } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Box, StyledFontAwesomeIcon as Icon, MaxCompetitors, Typography } from '@trustyou/ui';

import styles from './styles';

export const MaxCompetitorsInfo = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Box sx={styles.link} onClick={() => setOpen(true)}>
        <Typography variant="body2">
          <FormattedMessage
            id="tyDesign.maxCompetitorsSelected"
            defaultMessage="Max competitors selected"
          />
        </Typography>
        <Icon icon={faCircleQuestion} sx={styles.linkIcon} />
      </Box>
      <MaxCompetitors open={open} onClose={() => setOpen(false)} />
    </>
  );
};
