import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Box, Stack, Typography, documents, imagesFolder } from '@trustyou/ui';

import { BigCard } from '../../../components/shared/big-card';

function ContentLibraryListHeader() {
  return (
    <Box>
      <Typography variant="h6" component="h2">
        <FormattedMessage id="survey.common.content-library" defaultMessage="Content library" />
      </Typography>
      <Typography variant="body2" color="text.secondary">
        <FormattedMessage
          id="survey.content-library.subtitle"
          defaultMessage="Create and manage all your survey related content here"
        />
      </Typography>
    </Box>
  );
}

export function ContentLibraryList() {
  const navigate = useNavigate();

  return (
    <Stack spacing={3}>
      <ContentLibraryListHeader />
      <Stack direction="row" spacing={3}>
        <BigCard
          image={{ src: documents, alt: 'documents illustration' }}
          title={<FormattedMessage id="survey.common.texts" defaultMessage="Texts" />}
          description={
            <FormattedMessage
              id="survey.content-library.texts.description"
              defaultMessage="Customize the messages to display in various parts of surveys and survey invitations."
            />
          }
          onClick={() => navigate('content-library/texts')}
        />
        <BigCard
          image={{ src: imagesFolder, alt: 'images illustration' }}
          title={<FormattedMessage id="survey.common.images" defaultMessage="Images" />}
          description={
            <FormattedMessage
              id="survey.content-library.images.description"
              defaultMessage="Upload and manage images which you can later use in themes."
            />
          }
          onClick={() => navigate('content-library/images')}
        />
      </Stack>
    </Stack>
  );
}
