import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faCheck,
  faFaceSunglasses,
  faFamily,
  faPeople,
  faPerson,
  faSuitcase,
  faThumbsDown,
  faThumbsUp,
  faXmark,
} from '@trustyou/fortawesome/pro-regular-svg-icons';

export const iconMap: { [key: string]: IconDefinition } = {
  // Yes/No
  faCheck,
  faXmark,
  faThumbsUp,
  faThumbsDown,

  // Traveler
  faSuitcase,
  faFamily,
  faPeople,
  faFaceSunglasses,
  faPerson,
};
