import { useMutation, useQuery } from '@tanstack/react-query';

import {
  fetchResponseAILanguages,
  fetchResponseAITranslate,
  fetchResponseAiGetProfile,
  generateResponseAI,
  saveResponseAIFeedback,
  updateResponseAIManageProfile,
} from './fetchers';

import type { Profile_Input, TranslateIn } from '../../../client';
import {
  RESPONSE_AI_LANGUAGES,
  RESPONSE_AI_PROFILE,
  RESPONSE_AI_RESULT,
  RESPONSE_AI_SEND_FEEDBACK,
} from '../../../constants/query-keys';
import type {
  FetchResponseAITranslateData,
  ResponseAIFeedback,
  ResponseResult,
} from '../../../types';

export function useFetchResponseAITranslate({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: FetchResponseAITranslateData) => void;
  onError?: () => void;
}) {
  return useMutation({
    mutationFn: (data: TranslateIn) => fetchResponseAITranslate(data),
    onSuccess,
    onError,
  });
}

export function useFetchResponseAILanguages({ enabled }: { enabled?: boolean }) {
  return useQuery({
    queryKey: [RESPONSE_AI_LANGUAGES],
    queryFn: fetchResponseAILanguages,
    enabled,
    refetchOnWindowFocus: false,
  });
}

export function useFetchResponseAIGetProfile() {
  return useQuery({
    queryKey: [RESPONSE_AI_PROFILE],
    queryFn: fetchResponseAiGetProfile,
    refetchOnWindowFocus: false,
  });
}

export function useUpdateResponseAIManageProfile({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
}) {
  return useMutation({
    mutationFn: (profileData: Profile_Input) => updateResponseAIManageProfile(profileData),
    onSuccess,
    onError,
  });
}

export function useGenerateResponseAI({
  onError,
  onSuccess,
}: {
  onError?: (error: {
    response: {
      status: number;
    };
  }) => void;
  onSuccess?: (data: ResponseResult) => void;
}) {
  return useMutation({
    mutationFn: generateResponseAI,
    mutationKey: [RESPONSE_AI_RESULT],
    onError,
    onSuccess,
  });
}

export function useSendFeedback() {
  return useMutation({
    mutationFn: (payload: ResponseAIFeedback) => saveResponseAIFeedback(payload),
    mutationKey: [RESPONSE_AI_SEND_FEEDBACK],
  });
}
