import { defineMessage, defineMessages } from 'react-intl';

export const TABS = [
  {
    value: 'all',
    label: defineMessage({
      id: 'analytics.reviews-modal.all-mentions',
      defaultMessage: 'All mentions',
    }),
  },
  {
    value: 'positive',
    label: defineMessage({
      id: 'analytics.reviews-modal.positive',
      defaultMessage: 'Positive',
    }),
  },
  {
    value: 'neutral',
    label: defineMessage({
      id: 'analytics.reviews-modal.neutral',
      defaultMessage: 'Neutral',
    }),
  },
  {
    value: 'negative',
    label: defineMessage({
      id: 'analytics.reviews-modal.negative',
      defaultMessage: 'Negative',
    }),
  },
];

export const SENTIMENTS_MAP = {
  p: 'positive',
  o: 'neutral',
  n: 'negative',
};

export const EMPTY_REVIEWS_DESCRIPTION = defineMessages({
  all: {
    id: 'analytics.reviews-modal.empty-state.category.description',
    defaultMessage: 'No reviews found for the selected category',
  },
  positive: {
    id: 'analytics.reviews-modal.empty-state.positive.description',
    defaultMessage: 'No reviews found with positive sentiment',
  },
  neutral: {
    id: 'analytics.reviews-modal.empty-state.neutral.description',
    defaultMessage: 'No reviews found with neutral sentiment',
  },
  negative: {
    id: 'analytics.reviews-modal.empty-state.negative.description',
    defaultMessage: 'No reviews found with negative sentiment',
  },
});
