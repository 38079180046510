import { type SxProps, type Theme, Typography, type TypographyProps } from '@mui/material';

type ClampedTypographyProps = TypographyProps & {
  maxLines: number;
  children: React.ReactNode;
  sx?: SxProps<Theme>;
};

export function ClampedTypography({ maxLines, children, sx, ...rest }: ClampedTypographyProps) {
  return (
    <Typography
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        // "webkit" prefixes enable support for Safari
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: maxLines,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
}
