import { create } from 'zustand';

import type { IFrameMessagePayload } from '../types';

type PayloadState = {
  payload: IFrameMessagePayload | null;
  setPayload: (payload: IFrameMessagePayload | null) => void;
};

export const usePayloadStore = create<PayloadState>((set) => ({
  payload: null,
  setPayload: (payload) => set({ payload }),
}));
