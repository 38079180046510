import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import {
  type CombinedUserAndInvitation,
  FETCH_SUBSCRIPTION_MEMBERS,
  USER_STATUSES,
  getPaginationRequest,
  getSortRequest,
  useAlertStore,
  useChangelingStore,
  useDeleteInvitation,
  useDeleteUser,
  useGridParams,
  useResendInvitation,
  useSubscriptionMembers,
  useSubscriptionMembersGridStore,
} from '@trustyou/shared';
import { BackdropSpinner, EmptyMemberState, SecondaryDrawer, UserDetailsView } from '@trustyou/ui';

import { useCurrentUser, useManageUsers } from '../../../hooks';
import InvitationDeleteModal from '../../Organization/Users/components/DeleteModal/InvitationDeleteModal';
import UserDeleteModal from '../../Organization/Users/components/DeleteModal/UserDeleteModal';
import UsersTable from '../../Organization/Users/components/UsersTable/UsersTable';

export interface SubscriptionMembersProps {
  onEdit?: (user: CombinedUserAndInvitation) => void;
}

export const SubscriptionMembers = ({ onEdit }: SubscriptionMembersProps) => {
  const [chosenUser, setChosenUser] = useState<CombinedUserAndInvitation>();
  const [deletingUser, setDeletingUser] = useState<CombinedUserAndInvitation>();

  const { isChangeling } = useChangelingStore();
  const { chosenSubscriptionId } = useParams();

  const { alert } = useAlertStore();

  const isManageUserAllowed = useManageUsers();

  const gridState = useSubscriptionMembersGridStore();

  const { paginationModel, sortModel, searchKey } = gridState;

  const { onPaginationModelChange, onSortModelChange } = useGridParams(
    FETCH_SUBSCRIPTION_MEMBERS,
    gridState
  );

  const currentUser = useCurrentUser();

  const { data, isFetching } = useSubscriptionMembers(
    chosenSubscriptionId as string,
    getPaginationRequest(paginationModel),
    getSortRequest(sortModel),
    searchKey
  );

  const onCloseDrawer = () => {
    setChosenUser(undefined);
  };

  const onCloseDelete = () => {
    setDeletingUser(undefined);
  };

  const { mutate: handleDeleteUser, isPending: isDeleteUserInProcess } = useDeleteUser(
    () => {
      alert.info(
        <FormattedMessage
          id="organization.users.userRemovedAlert"
          defaultMessage="{user} removed"
          values={{ user: deletingUser?.name || deletingUser?.email }}
        />
      );
    },
    () => {
      alert.genericError();
    },
    onCloseDelete,
    true
  );

  const { mutate: handleResendInvitation, isPending: isResendInProcess } = useResendInvitation(
    (email: string) => {
      alert.success(
        <FormattedMessage
          id="organization.users.invitationResendAlert"
          defaultMessage="Invitation to {email} is resent"
          values={{ email }}
        />
      );
    },
    () => {
      alert.genericError();
    }
  );

  const { mutate: handleDeleteInvitation, isPending: isDeleteInvitationInProcess } =
    useDeleteInvitation(
      () => {
        alert.info(
          <FormattedMessage
            id="organization.users.invitationDeleteAlert"
            defaultMessage="Invitation for {email} was canceled"
            values={{ email: deletingUser?.email }}
          />
        );
      },
      () => {
        alert.genericError();
      },
      onCloseDelete,
      true
    );

  const onResendInvitation = (resendUser: CombinedUserAndInvitation) => {
    handleResendInvitation({
      email: resendUser.email,
      invitation_id: resendUser.membership_id,
    });
  };

  const onDeleteUser = () => {
    handleDeleteUser(deletingUser?.membership_id as string);
  };

  const onDeleteInvitation = () => {
    handleDeleteInvitation(deletingUser?.membership_id as string);
  };

  const handleDelete = (userToDelete: CombinedUserAndInvitation) => {
    setDeletingUser(userToDelete);
  };

  if (data?.pagination?.total === 0 && !searchKey) return <EmptyMemberState />;

  return (
    <>
      <UsersTable
        data={data}
        isLoading={isFetching}
        handleEdit={onEdit}
        handleDelete={handleDelete}
        handleResendInvitation={onResendInvitation}
        handleUserDetails={setChosenUser}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        enabledColumns={['name', 'role', 'scope', 'status', 'action']}
      />
      <SecondaryDrawer
        header={null}
        open={!!chosenUser}
        onClose={onCloseDrawer}
        width="400px"
        changelingMode={isChangeling}
      >
        {chosenUser && (
          <UserDetailsView
            user={chosenUser}
            isManageUserAllowed={isManageUserAllowed}
            currentUser={currentUser}
            handleEditUser={onEdit}
            handleDeleteUser={handleDelete}
          />
        )}
      </SecondaryDrawer>
      <UserDeleteModal
        onClose={onCloseDelete}
        onDelete={onDeleteUser}
        user={deletingUser?.status === USER_STATUSES.ACTIVE ? deletingUser : undefined}
      />
      <InvitationDeleteModal
        onClose={onCloseDelete}
        onDelete={onDeleteInvitation}
        invitation={deletingUser?.status === USER_STATUSES.INVITED ? deletingUser : undefined}
      />
      <BackdropSpinner
        isLoading={isDeleteUserInProcess || isDeleteInvitationInProcess || isResendInProcess}
      />
    </>
  );
};
