import type { ReactNode } from 'react';
import { type IntlShape, useIntl } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { GridCellParams, GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import { faCircleExclamation } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { type PreviewEntityRow, commonFields } from '@trustyou/shared';
import { Box, DataGrid } from '@trustyou/ui';

import STATUS_CHIP_MAP from './StatusChips';

import styles from './styles';

const renderCell = ({ value, field, row }: GridCellParams): ReactNode => {
  if (field === 'status') return STATUS_CHIP_MAP[value as string];
  if (row.errorColumns && row.errorColumns.includes(field)) {
    return (
      <>
        {value}
        <FontAwesomeIcon style={styles.errorCellIcon} icon={faCircleExclamation} size="1x" />
      </>
    );
  }
  return <Box sx={styles.textCell}>{row[field]}</Box>;
};

const tableColumns = (intl: IntlShape): GridColDef[] => {
  return [
    {
      field: 'status',
      headerName: intl.formatMessage(commonFields.status),
      minWidth: 110,
      renderCell,
    },
    {
      field: 'name',
      headerName: intl.formatMessage(commonFields.name),
      minWidth: 250,
      renderCell,
    },
    {
      field: 'sector',
      headerName: intl.formatMessage(commonFields.sector),
      minWidth: 150,
      renderCell,
    },
    {
      field: 'brand',
      headerName: intl.formatMessage(commonFields.brand),
      minWidth: 250,
      renderCell,
    },
    {
      field: 'continent',
      headerName: intl.formatMessage(commonFields.continent),
      renderCell,
    },
    {
      field: 'country',
      headerName: intl.formatMessage(commonFields.country),
      renderCell,
    },
    {
      field: 'country_name',
      headerName: intl.formatMessage(commonFields.countryName),
      renderCell,
    },
    {
      field: 'city',
      headerName: intl.formatMessage(commonFields.city),
      renderCell,
    },
    {
      field: 'zip',
      headerName: intl.formatMessage(commonFields.zip),
      renderCell,
    },
    {
      field: 'address',
      headerName: intl.formatMessage(commonFields.address),
      minWidth: 200,
      renderCell,
    },
  ];
};

type TableProps = {
  onRowClick: (params: GridRowParams) => void;
  rows: PreviewEntityRow[];
};

const Table = ({ rows, onRowClick }: TableProps) => {
  const intl = useIntl();
  const localAttributes =
    rows.length > 0 && rows[0].custom_attributes ? rows[0].custom_attributes : {};

  const localAttributeColumns = Object.keys(localAttributes).map((field) => ({
    field,
    headerName: field,
    minWidth: 200,
    renderCell: ({ row }: GridCellParams) => (
      <Box sx={styles.textCell}>{row.custom_attributes[field]}</Box>
    ),
  }));

  return (
    <Box
      sx={{
        '& .error': { ...styles.errorCell },
      }}
    >
      <DataGrid
        columns={[...tableColumns(intl), ...localAttributeColumns]}
        rows={rows}
        sx={styles.dataGrid}
        getCellClassName={({ field, row }) =>
          row.errorColumns && row.errorColumns.includes(field) ? 'error' : ''
        }
        getRowId={(row) => row.rowId}
        onRowClick={onRowClick}
        initialState={{
          pinnedColumns: { left: ['status', 'name'] },
          pagination: { paginationModel: { pageSize: 10 } },
        }}
      />
    </Box>
  );
};

export default Table;
