import { useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { yupResolver } from '@hookform/resolvers/yup';
import { commonFields, validationErrors } from '@trustyou/shared';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@trustyou/ui';
import * as yup from 'yup';

import type { FlagFormValues } from './form-drawer';
import { rejectionDialogMessages } from './messages';

import { TextFieldController } from '../text-field-controller';

type SurveyModerationRejectionDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (notes: string) => void;
};

export function SurveyModerationRejectionDialog({
  isOpen,
  onClose,
  onConfirm,
}: SurveyModerationRejectionDialogProps) {
  const intl = useIntl();

  const requiredFieldHelperText = intl.formatMessage(validationErrors.required);
  const schema = yup.object().shape({
    notes: yup.string().required(requiredFieldHelperText),
  });
  const methods = useForm<Pick<FlagFormValues, 'notes'>>({
    mode: 'onChange',
    defaultValues: {
      notes: '',
    },
    resolver: yupResolver(schema),
  });
  const {
    control,
    handleSubmit,
    trigger,
    reset,
    formState: { errors },
  } = methods;
  const notes = useWatch({ control, name: 'notes' });

  const handleCancel = onClose;

  const onSubmit = () => {
    trigger('notes');
    if (!notes) return;
    onConfirm(notes);
    onClose();
  };

  useEffect(() => {
    return () => {
      reset();
    };
  }, [isOpen, reset]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{intl.formatMessage(rejectionDialogMessages.title)}</DialogTitle>
      <DialogContent>
        <Typography sx={{ paddingBlockStart: 2 }}>
          {intl.formatMessage(rejectionDialogMessages.explanation)}
        </Typography>
        <FormProvider {...methods}>
          <TextFieldController
            name="notes"
            label={intl.formatMessage(rejectionDialogMessages.notes)}
            required
            multiline
            rows={3}
            error={!!errors.notes}
            helperText={errors.notes?.message ?? ' '}
          />
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={handleCancel}>
          {intl.formatMessage(commonFields.cancel)}
        </Button>
        <Button variant="contained" onClick={handleSubmit(onSubmit)}>
          {intl.formatMessage(commonFields.confirm)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
