import { useQuery } from '@tanstack/react-query';
import { $assApi, SURVEY_BASE_PATH } from '@trustyou/shared';

import type { SurveyWithCount } from '../../client';
import { SURVEY_LIST } from '../constants/query-keys';

async function fetchSurveyList(): Promise<SurveyWithCount[]> {
  const { data } = await $assApi.get(`${SURVEY_BASE_PATH}/survey/list`);
  return data;
}

export function useSurveys() {
  return useQuery({
    queryKey: [SURVEY_LIST],
    queryFn: fetchSurveyList,
  });
}
