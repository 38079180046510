import React from 'react';
import { FormattedMessage } from 'react-intl';

import { faCheck } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Box, Button, StyledFontAwesomeIcon, Typography } from '@trustyou/ui';

import styles from './styles';

export interface SuccessConnectProps {
  onClose: () => void;
}

const SuccessConnect = ({ onClose }: SuccessConnectProps) => (
  <Box sx={styles.googleFinalWrapper}>
    <Box sx={{ ...styles.icon, ...styles.successIcon }}>
      <StyledFontAwesomeIcon size="2x" icon={faCheck} />
    </Box>
    <Typography variant="h6">
      <FormattedMessage id="review-providers.google.success.title" defaultMessage="All good!" />
    </Typography>
    <Typography variant="body2">
      <FormattedMessage
        id="review-providers.google.success.description"
        defaultMessage="We’re setting up your API connection, and this process could take up to 48 hours."
      />
    </Typography>
    <Button
      size="large"
      data-testid="google-back-to-review-providers"
      onClick={onClose}
      variant="contained"
    >
      <FormattedMessage
        id="review-providers.google.static.button-text"
        defaultMessage="Back to review providers"
      />
    </Button>
  </Box>
);

export default SuccessConnect;
