import { FormattedMessage } from 'react-intl';

import { useMembershipStore } from '@trustyou/shared';
import { Box, Typography } from '@trustyou/ui';

import MembershipSelector from '../MembershipSelector/MembershipSelector';
import styles from './styles';

export const SwitchMembership = () => {
  const { setMembership, organizations } = useMembershipStore();

  if (!organizations || !organizations.length) return undefined;

  return (
    <Box sx={styles.container}>
      <Box sx={styles.card}>
        <Typography variant="subtitle1" sx={styles.header}>
          <FormattedMessage
            id="hallway.select-membership.title"
            defaultMessage="Select a membership to continue"
          />
        </Typography>
        <MembershipSelector organizations={organizations} onSelect={setMembership} />
      </Box>
    </Box>
  );
};
