import { useNavigate } from 'react-router-dom';

import { SmallCard } from '../shared/small-card';

type ThemeCardProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: any;
};

export function ThemeCard({ theme }: ThemeCardProps) {
  const navigate = useNavigate();

  return (
    <SmallCard
      title={theme.name}
      lastUpdated={theme.metadata.updated_at}
      questionnaireCount={theme.questionnaire_count}
      entityCount={theme.entity_count}
      onClick={() => navigate(`theme/detail/${theme.id}`)}
    />
  );
}
