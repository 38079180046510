import { type ReactNode, useState } from 'react';

import {
  type IconDefinition,
  faEllipsisVertical,
} from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Box, StyledFontAwesomeIcon as Icon, IconButton, Menu, MenuItem } from '@trustyou/ui';

import styles from './styles';

export interface SingleActionProps {
  testId: string;
  handler: () => void;
  icon: IconDefinition;
  message: ReactNode;
  isDisabled?: boolean;
}

export interface ActionCellProps {
  testId: string;
  actions: SingleActionProps[];
}

export const ActionCell = ({ actions, testId }: ActionCellProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleAction = (handler: () => void) => {
    handler();
    setAnchorEl(null);
  };

  return (
    <Box sx={styles.actionCell}>
      <IconButton data-testid={testId} sx={styles.close} onClick={onClick}>
        <Icon icon={faEllipsisVertical} />
      </IconButton>

      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {actions.map((action) => (
          <MenuItem
            key={action.testId}
            sx={styles.actionCellMenuItem}
            onClick={() => handleAction(action.handler)}
            disableRipple
            disabled={action.isDisabled}
            data-testid={action.testId}
          >
            <Box sx={styles.actionCellMenuItemBody}>
              <Icon sx={styles.actionCellMenuItemIcon} icon={action.icon} />
              {action.message}
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
