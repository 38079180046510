import { useCallback } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useOrgCenter } from '@trustyou/shared';

import type { AccessTokenResponse, Invitation } from '../../../../types/model/auth';
import {
  FETCH_ACCESS_TOKEN,
  FETCH_MEMBERSHIPS_AND_INVITATIONS,
  FETCH_TOKEN_INFO,
} from '../../../constants/queryKeys';
import {
  acceptInvitation,
  fetchAccessToken,
  fetchChangelingAccessToken,
  fetchMembershipsAndInvitations,
  fetchTokenInfo,
} from '../../../queries';

export function useAccessToken({
  isChangeling,
  onSuccess,
  onError,
}: {
  isChangeling?: boolean;
  onSuccess?: (data: AccessTokenResponse) => void;
  onError?: (error: Error) => void;
}) {
  return useMutation({
    mutationFn: isChangeling ? fetchChangelingAccessToken : fetchAccessToken,
    mutationKey: [FETCH_ACCESS_TOKEN],
    onSuccess,
    onError,
  });
}

export function useTokenInfo({ enabled }: { enabled: boolean }) {
  const orgCenter = useOrgCenter();
  return useQuery({
    queryKey: [FETCH_TOKEN_INFO, { orgCenter }],
    queryFn: () => fetchTokenInfo(orgCenter?.apis || []),
    refetchOnWindowFocus: false,
    enabled,
  });
}

export function useMembershipsAndInvitations({
  keycloakAccessToken,
  enabled,
}: {
  keycloakAccessToken: string;
  enabled: boolean;
}) {
  return useQuery({
    queryKey: [FETCH_MEMBERSHIPS_AND_INVITATIONS, { keycloakAccessToken }],
    queryFn: () => fetchMembershipsAndInvitations({ keycloakAccessToken }),
    enabled,
    refetchOnWindowFocus: false,
  });
}

export function useAcceptAllInvitations({ keycloakAccessToken }: { keycloakAccessToken: string }) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (invitationId: string) => acceptInvitation({ keycloakAccessToken, invitationId }),
  });

  const acceptAll = useCallback(
    async (invitations: Invitation[], onSettled: () => void) => {
      const promises = invitations.map((invitation) => mutation.mutateAsync(invitation.id));

      await Promise.allSettled(promises);

      queryClient.removeQueries({ queryKey: [FETCH_MEMBERSHIPS_AND_INVITATIONS] });

      onSettled();
    },
    [mutation, queryClient]
  );

  return { acceptAll };
}
