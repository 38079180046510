import { useMemo, useState } from 'react';

import { type SxProps, type Theme, debounce } from '@mui/material';
import { faClose, faSearch } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { useDataGridIntl, useLanguageStore } from '@trustyou/shared';
import {
  FormControl,
  StyledFontAwesomeIcon as Icon,
  IconButton,
  InputAdornment,
  TextField,
} from '@trustyou/ui';

import styles from './SearchBar.styles';

export interface SearchBarProps {
  id: string;
  placeholder?: string;
  debounceTime?: number;
  disabled?: boolean;
  variant?: 'standard' | 'outlined';
  onSearch: (value: string) => void;
  defaultValue?: string;
  sx?: SxProps<Theme>;
}

export const SearchBar = ({
  id,
  placeholder,
  debounceTime = 0,
  disabled,
  variant = 'outlined',
  onSearch,
  defaultValue,
  sx,
}: SearchBarProps) => {
  const dataGridIntl = useDataGridIntl();
  const { locale } = useLanguageStore();
  const [searchValue, setSearchValue] = useState<string>(defaultValue || '');

  const debounceSearch = useMemo(() => debounce(onSearch, debounceTime), [onSearch, debounceTime]);
  const onChange = (value: string) => {
    setSearchValue(value);
    debounceSearch(value);
  };

  return (
    <FormControl sx={sx}>
      <TextField
        sx={{ ...(variant === 'standard' && styles.standardInput) }}
        data-testid={`${id}-search-bar`}
        placeholder={
          placeholder ??
          dataGridIntl[locale].components.MuiDataGrid.defaultProps.localeText
            .toolbarQuickFilterPlaceholder
        }
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon size="lg" icon={faSearch} />
            </InputAdornment>
          ),
          endAdornment: variant === 'outlined' && (
            <InputAdornment position="end">
              <IconButton
                sx={{ visibility: searchValue ? 'visible' : 'hidden' }}
                onClick={() => onChange('')}
                edge="end"
                disabled={disabled}
              >
                <Icon size="xs" icon={faClose} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={searchValue}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onChange(event.target.value);
        }}
        disabled={disabled}
        variant={variant}
        size="small"
      />
    </FormControl>
  );
};
