import { Box, InstructionCard, type InstructionCardProps } from '@trustyou/ui';

import styles from './styles';

export interface InstructionCardListProps {
  items: InstructionCardProps[];
}

export const InstructionCardList = ({ items }: InstructionCardListProps) => (
  <Box sx={styles.listWrapper}>
    {items.map((item) => (
      <InstructionCard
        key={item.stepNumber}
        stepNumber={item.stepNumber}
        title={item.title}
        description={item.description}
      />
    ))}
  </Box>
);
