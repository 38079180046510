import { useLocation, useNavigate } from 'react-router-dom';

import { faArrowRight } from '@trustyou/fortawesome/pro-regular-svg-icons';
import type { NodeType } from '@trustyou/shared';
import { Box, StyledFontAwesomeIcon as Icon, SpotIcon, Typography } from '@trustyou/ui';

import styles from './styles';

export function NavCard({ id, path, icon, iconColor, name }: NodeType) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const onClick = () => {
    navigate(path ?? '.', { state: { path: pathname } });
  };

  return (
    <Box sx={styles.container} onClick={onClick} data-gtm-id={`home-nav-card-${id}`}>
      <Box sx={styles.mainColumn}>
        <SpotIcon
          icon={icon}
          sx={{
            ...styles.icon,
            backgroundColor: iconColor,
          }}
        />
        <Typography variant="subtitle1">{name}</Typography>
      </Box>
      <Icon sx={styles.navIcon} icon={faArrowRight} />
    </Box>
  );
}
