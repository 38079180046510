import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { fetchViews, saveViews } from './fetchers';

import { USER_VIEWS, VIEWS } from '../../../constants/query-keys';
import type { View } from '../../../types';

export function useFetchViews() {
  return useQuery({
    queryKey: [VIEWS],
    queryFn: fetchViews,
    refetchOnWindowFocus: false,
  });
}

export function useSaveViews() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (newViews: View[]) => saveViews(newViews),
    onMutate: async (newOrder) => {
      await queryClient.cancelQueries({ queryKey: [VIEWS] });
      const previousViews = queryClient.getQueryData<View[]>([VIEWS]) || [];
      queryClient.setQueryData([VIEWS], newOrder);
      return { previousViews };
    },
    onError: (err, newOrder, context) => {
      queryClient.setQueryData([VIEWS], context?.previousViews || []);
    },
    onSettled: (newViews) => {
      queryClient.setQueryData([USER_VIEWS], newViews);
    },
  });
}
