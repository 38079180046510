import { areUITestsRunning } from '../service/utils';
import type { Permissions } from '../types';

export const checkUserPermission = (
  permission: string,
  userPermissions: Permissions | null
): boolean => {
  // always allow all elements for tests and storybook
  if (areUITestsRunning()) return true;

  if (userPermissions === null) return false;

  return Object.entries(userPermissions).some(([domain, permissions]) =>
    permissions.some((value) => `${domain}/${value}` === permission)
  );
};
