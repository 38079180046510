import { FormattedMessage } from 'react-intl';

import { EmptyStatePlaceholder } from '@trustyou/ui';

import personOnALadder from '../../assets/illustrations/person-on-a-ladder.svg';
import styles from './styles';

export const EmptyEntitySourceState = () => (
  <EmptyStatePlaceholder
    img={personOnALadder}
    title={<FormattedMessage id="tyDesign.noSourcesYet" defaultMessage="No sources yet" />}
    description={
      <FormattedMessage
        id="tyDesign.sourceUrlsWillAppear"
        defaultMessage="The source URLs of this entity will appear here once they are configured"
      />
    }
    emptyStateHeader={styles.emptyStateHeader}
    emptyStateContainer={styles.entitySourcesContainer}
  />
);
