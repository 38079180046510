import { type ReactElement, type SyntheticEvent, useEffect, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  SISENSE_SERVER_PATH,
  useAuthorizationStore,
  useLanguageStore,
  useMembershipStore,
  useRowLevelSecurity,
  useSisenseJWTToken,
} from '@trustyou/shared';
import { Box, DashboardLoader } from '@trustyou/ui';

const SISENSE_JWT_BASE_PATH = `${SISENSE_SERVER_PATH}/jwt`;

type AuthWrapperProps = {
  show?: boolean;
  children?: ReactElement;
  loader?: ReactElement;
};

export const AuthWrapper = ({ show = true, children, loader }: AuthWrapperProps) => {
  const firstLangRender = useRef(true);

  const { tokenStore, setTokenStore } = useAuthorizationStore();
  const { membership } = useMembershipStore();
  const { locale } = useLanguageStore();

  const navigate = useNavigate();
  const location = useLocation();

  // Navigate to previous page after back button
  const popEventHandler = () => {
    if (window.location.href.includes('/analytics') && !location.state?.path) {
      // Skip sisense jwt route on back button click
      navigate(-2);
    }
  };

  useEffect(() => {
    if (show) {
      window.addEventListener('popstate', popEventHandler);
    } else {
      window.removeEventListener('popstate', popEventHandler);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  // Call for JWT Token (used below)
  const { data: ssoTokenData } = useSisenseJWTToken();

  // Call for Row Level Security (used below)
  const { mutate: setRowLevelSecurity, isSuccess } = useRowLevelSecurity({
    language: locale,
  });

  useEffect(() => {
    if (firstLangRender.current) {
      firstLangRender.current = false;
      return;
    }
    setRowLevelSecurity({ language: locale });
  }, [locale, setRowLevelSecurity]);

  // Url to use for auth Iframe
  const url = useMemo(() => {
    if (!ssoTokenData?.sso_token) return '';
    return `${SISENSE_JWT_BASE_PATH}?jwt=${ssoTokenData.sso_token}&return_to=/empty`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ssoTokenData]);

  const onLoadIframeHandler = (event: SyntheticEvent<HTMLIFrameElement, Event>) => {
    if (tokenStore && membership?.id && ssoTokenData?.sso_token) {
      setTokenStore({
        ...tokenStore,
        [membership.id]: {
          ...tokenStore[membership.id],
          analytics: {
            sso_token: ssoTokenData?.sso_token,
          },
        },
      });
    }
    setRowLevelSecurity({ language: locale });
  };

  if (isSuccess) return children;

  return (
    <Box sx={{ height: '100%', width: '100%', ...(!show && { display: 'none' }) }}>
      {!isSuccess &&
        (loader || (
          <DashboardLoader
            message={
              <FormattedMessage
                id="analytics.loadingDashboard"
                defaultMessage="Loading your dashboard..."
              />
            }
          />
        ))}
      {url && (
        <iframe
          src={url}
          title="Sisense Embed"
          style={{
            border: 'none',
            display: 'none',
          }}
          onLoad={onLoadIframeHandler}
        />
      )}
    </Box>
  );
};
