import { FormattedMessage } from 'react-intl';

import { faClose } from '@trustyou/fortawesome/pro-regular-svg-icons';
import type { SegmentGroup } from '@trustyou/shared';
import {
  StyledFontAwesomeIcon as Icon,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@trustyou/ui';

import styles from './styles';

type SelectedGroupListProps = {
  groups: SegmentGroup[];
  onGroupRemove: (group: SegmentGroup[]) => void;
};

const SelectedGroupList = ({ groups, onGroupRemove }: SelectedGroupListProps) => (
  <>
    <Typography variant="body1">
      <FormattedMessage
        id="organization.users.selectedGroups"
        defaultMessage="Selected groups ({count})"
        values={{ count: groups.length }}
      />
    </Typography>
    <List sx={styles.list}>
      {groups.map((group) => (
        <ListItem
          key={group.id}
          sx={styles.listItem}
          secondaryAction={
            <IconButton edge="end" onClick={() => onGroupRemove([group])}>
              <Icon size="xs" icon={faClose} />
            </IconButton>
          }
        >
          <ListItemText primary={group.name} />
        </ListItem>
      ))}
    </List>
  </>
);

export default SelectedGroupList;
