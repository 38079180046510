import { FormattedMessage } from 'react-intl';

import type { ButtonProps, DialogProps } from '@mui/material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@trustyou/ui';

type ReviewDeletionDialogProps = {
  open: boolean;
  onClose?: DialogProps['onClose'];
  onCancel: ButtonProps['onClick'];
  onConfirm: ButtonProps['onClick'];
};

export function ReviewDeletionDialog({
  open,
  onClose,
  onCancel,
  onConfirm,
}: ReviewDeletionDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <FormattedMessage id="inbox.review.delete.dialog.title" defaultMessage="Mark as deleted?" />
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography>
          <FormattedMessage
            id="inbox.review.delete.dialog.paragraph-1"
            defaultMessage="By marking a review as deleted you are confirming that this review is no longer available in the source. We will process your deletion request over the next minutes."
          />
        </Typography>
        <Typography>
          <FormattedMessage
            id="inbox.review.delete.dialog.paragraph-2"
            defaultMessage='Once the process is completed, the review will no longer impact KPIs in the Dashboard and will show as "Deleted" in the inbox.'
          />
        </Typography>
        <Typography>
          <FormattedMessage
            id="inbox.review.delete.dialog.paragraph-3"
            defaultMessage='If the review was not deleted in the source when this request was done, you will find it again in the Inbox as "Unread" and will impact again the Dashboard KPIs in the next 36 hours.'
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="inherit">
          <FormattedMessage id="inbox.action.cancel" defaultMessage="Cancel" />
        </Button>
        <Button onClick={onConfirm} variant="contained">
          <FormattedMessage id="inbox.action.confirm" defaultMessage="Confirm" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
