import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { commonFields, useResponsive } from '@trustyou/shared';
import { Stack, Typography } from '@trustyou/ui';

import { CustomAttributesInfoDialog } from '../../../components';
import { info } from '../../../constants/messages/customAttributes';

export const Header = () => {
  const { isMobileRange } = useResponsive();
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Stack>
      {!isMobileRange && (
        <Typography variant="h6" color="text.primary">
          <FormattedMessage {...commonFields.customAttributes} />
        </Typography>
      )}
      <Typography variant="body2">
        <FormattedMessage {...info.description} />
      </Typography>
      <span onClick={toggleModal}>
        <Typography variant="body2" color="primary" sx={{ cursor: 'pointer' }}>
          <FormattedMessage {...info.moreInfo} />
        </Typography>
      </span>
      <CustomAttributesInfoDialog open={isOpen} onClose={toggleModal} />
    </Stack>
  );
};
