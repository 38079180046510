import { create } from 'zustand';

import { type CombinedUserAndInvitation, UserViewTypes } from '../../types/model/user';

export type UserView = {
  user?: CombinedUserAndInvitation;
  type: UserViewTypes;
  isDeleting?: boolean;
  isDiscarding?: boolean;
};

type UserNavState = {
  view: UserView;
  setView: (view: UserView) => void;
  navToList: () => void;
  navToInvite: () => void;
  navToEdit: (user: CombinedUserAndInvitation) => void;
  navToDetail: (user: CombinedUserAndInvitation) => void;
  navToDelete: (user: CombinedUserAndInvitation) => void;
  navToDeleteDiscard: () => void;
};

export const useUserNavStore = create<UserNavState>((set) => ({
  view: {
    type: UserViewTypes.LIST,
    user: undefined,
  },
  setView: (view) => set({ view }),
  navToList: () => set({ view: { type: UserViewTypes.LIST } }),
  navToInvite: () => set({ view: { type: UserViewTypes.INVITE } }),
  navToEdit: (user: CombinedUserAndInvitation) => set({ view: { user, type: UserViewTypes.EDIT } }),
  navToDetail: (user: CombinedUserAndInvitation) =>
    set({ view: { user, type: UserViewTypes.DETAIL } }),
  navToDelete: (user: CombinedUserAndInvitation) =>
    set(({ view }) => ({ view: { ...view, user, isDeleting: true } })),
  navToDeleteDiscard: () => set(({ view }) => ({ view: { ...view, isDeleting: false } })),
}));
