import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  container: {
    height: 'calc(100% - 48px)',
    overflow: 'auto',
  },
  footer: {
    height: '36px',
    marginTop: 1.5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  addCompetitorIcon: {
    marginRight: 1,
  },
};

export default styles;
