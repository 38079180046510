import { FormattedMessage } from 'react-intl';

import { faCircleCheck, faCircleXmark } from '@trustyou/fortawesome/pro-solid-svg-icons';
import { Box, StyledFontAwesomeIcon as Icon, Typography } from '@trustyou/ui';

import styles from './styles';

type ReviewProviderAPIStatusProps = {
  status: string;
};

export const ReviewProviderAPIStatus = ({ status }: ReviewProviderAPIStatusProps) => {
  if (status === 'ACTIVE' || status === 'pending' || status === 'working')
    return (
      <Box sx={styles.container}>
        <Icon size="lg" sx={{ color: 'success.main', ...styles.icon }} icon={faCircleCheck} />
        <Typography variant="body2" color="success.main">
          <FormattedMessage id="common.status.active" defaultMessage="Active" />
        </Typography>
      </Box>
    );
  return (
    <Box sx={styles.container}>
      <Icon size="lg" sx={{ color: 'error.main', ...styles.icon }} icon={faCircleXmark} />
      <Typography variant="body2" color="error.main">
        <FormattedMessage id="common.status.syncError" defaultMessage="Sync error" />
      </Typography>
    </Box>
  );
};
