import { FormattedMessage } from 'react-intl';

import { useScopeStore } from '@trustyou/shared';
import { Alert, EntitySelector, Stack, Typography } from '@trustyou/ui';

type EntitySelectionProps = {
  validated?: boolean;
};

export const EntitySelection = ({ validated }: EntitySelectionProps) => {
  const { selectedEntities } = useScopeStore();

  const isEmptySelection = selectedEntities.length === 0;

  return (
    <Stack gap={2}>
      <Typography variant="body1">
        <FormattedMessage
          id="access-groups.entity-selection-header"
          defaultMessage="Which entities belong to this access group?"
        />
      </Typography>
      <EntitySelector initialSelectedEntities={selectedEntities} />
      {validated && isEmptySelection && (
        <Alert severity="error">
          <FormattedMessage
            id="access-groups.entity-selection-alert"
            defaultMessage="Select at least one entity"
          />
        </Alert>
      )}
    </Stack>
  );
};
