import { FormattedMessage } from 'react-intl';

import { faBuildings, faListTree } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Alert, Box, SelectionCard, Typography } from '@trustyou/ui';

import styles from './styles';

const roles = [
  {
    id: 'entities',
    titleId: 'access-group.access-option.by-entities',
    title: 'By entities',
    contentId: 'access-group.access-option.by-entities.description',
    content: 'Select entities in your organization',
    icon: faBuildings,
  },
  {
    id: 'segments',
    titleId: 'access-group.access-option.by-segments',
    title: 'By segments',
    contentId: 'access-group.access-option.by-segments.description',
    content: 'Select segment groups of entities (e.g. brand, city) in your organization',
    icon: faListTree,
  },
];

type AccessOptionSelectionProps = {
  validated?: boolean;
  accessOption?: string;
  setAccessOption: (accessOption: string) => void;
};

export const AccessOptionSelection = ({
  validated,
  accessOption,
  setAccessOption,
}: AccessOptionSelectionProps) => {
  return (
    <Box sx={styles.accessOptionSelectionContainer}>
      <Typography variant="body1">
        <FormattedMessage
          id="access-group.choose-access-option"
          defaultMessage="How do you want to define this access group?"
        />
      </Typography>
      <Box sx={styles.accessOptionSelectionCardContainer}>
        {roles.map((row) => (
          <SelectionCard
            {...row}
            key={row.id}
            title={<FormattedMessage id={row.titleId} defaultMessage={row.title} />}
            content={<FormattedMessage id={row.contentId} defaultMessage={row.content} />}
            onClick={() => setAccessOption(row.id)}
            selected={accessOption === row.id}
            iconAltText={row.title}
            sx={styles.accessOptionSelectionCard}
          />
        ))}
      </Box>
      {validated && !accessOption && (
        <Alert severity="error">
          <FormattedMessage
            id="access-group.select-access-option"
            defaultMessage="Select an access option"
          />
        </Alert>
      )}
    </Box>
  );
};
