import type { IStyles } from '@trustyou/shared';

export const styles: IStyles = {
  header: {
    flexDirection: 'row',
    gap: 0.5,
    marginBottom: 1,
  },
  addButton: { width: 'fit-content', marginLeft: 5 },
  newText: { color: 'warning.main', marginTop: 0, height: '40px', alignContent: 'center' },
  option: {
    flexDirection: 'row',
    gap: 1,
    alignItems: 'flex-start',
  },
};
