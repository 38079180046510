export const truncateText = ({
  keywords,
  text,
  maxLength,
}: {
  keywords: string[];
  maxLength: number;
  text?: string;
}) => {
  if (!text) return '';

  const DELIMITER = '...';
  const indices = keywords
    .map((keyword) => text?.toLowerCase().indexOf(keyword?.toLowerCase()))
    .filter((index) => index !== -1);

  if (indices.length === 0) return '';

  const [startIndex, endIndex] = [Math.min(...indices), Math.max(...indices)];

  let truncatedText = text.substring(Math.max(0, startIndex - maxLength), text.length);

  if (startIndex - maxLength > 0) truncatedText = `${DELIMITER} ${truncatedText}`;
  if (endIndex + maxLength < text.length) truncatedText = `${truncatedText}${DELIMITER}`;

  return truncatedText;
};

export const getSearchTerms = (inputValue: string): string[] => {
  const wordsRegexp = new RegExp(
    '[' +
      '\\p{Script=Arabic}' +
      '&&\\p{Script=Katakana}' +
      '&&\\p{Script=Tangut}' +
      '&&\\p{Script=Malayalam}' +
      '&&\\p{Script=Armenian}' +
      '&&\\p{Script=Bengali}' +
      '&&\\p{Script=Lao}' +
      '&&\\p{Script=Kannada}' +
      '&&\\p{Script=Bopomofo}' +
      '&&\\p{Script=Cyrillic}' +
      '&&\\p{Script=Devanagari}' +
      '&&\\p{Script=Georgian}' +
      '&&\\p{Script=Hebrew}' +
      '&&\\p{Script=Greek}' +
      '&&\\p{Script=Gujarati}' +
      '&&\\p{Script=Gurmukhi}' +
      '&&\\p{Script=Latin}' +
      '&&\\p{Script=Hani}' +
      '&&\\p{Script=Hiragana}' +
      '&&\\p{Script=Han}' +
      '&&\\p{Script=Hang}' +
      '&&\\p{Script=Thai}' +
      '&&\\p{Script=Hangul}' +
      '&&\\p{Open_Punctuation}' +
      '&&\\p{Close_Punctuation}' +
      '&&(\u30A0-\u30FF)' +
      '&&(\u2E80-\u2FD5\uFF5F-\uFF9F\u31F0-\u31FF\u3220-\u3243\u3280-\u337F)' +
      "&&'-]{2,}",
    'giu'
  );

  const uuidRegexp = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/g;

  const currencyAndNumberRegexp = /([\p{Currency_Symbol}{1}]+[\p{Number}]+)/giu;

  const phraseRegexp = /"[^\\"]*(\\"[^\\"]*)*"/g;
  const phraseMatches = inputValue.match(phraseRegexp);

  let phrases: string[] = [];
  if (phraseMatches) {
    phrases = phraseMatches.map((word) => word.replaceAll('"', ''));
  }
  const withoutPhrases = inputValue.replaceAll(phraseRegexp, '');

  const wordsMatches = withoutPhrases.match(wordsRegexp);
  const currencyMatches = withoutPhrases.match(currencyAndNumberRegexp);
  const uuidMatches = withoutPhrases.match(uuidRegexp);

  if (uuidMatches) {
    return uuidMatches;
  }

  return [...(wordsMatches || []), ...(currencyMatches || []), ...phrases];
};

const surroundPhrasesWithDoubleQuotes = (term: string) =>
  term.split(' ').length >= 2 ? `"${term}"` : term;

export const parseSearchTerms = (searchTerms: string[] = []): string =>
  searchTerms.map(surroundPhrasesWithDoubleQuotes).join(', ');
