import {
  GET_SEGMENTS_PERMISSION,
  UPLOAD_ENTITIES_PERMISSION,
  usePermissionStore,
} from '@trustyou/shared';

const useShowSegments = () => {
  const { hasPermissions } = usePermissionStore();
  const isShowSegmentsAllowed =
    hasPermissions(GET_SEGMENTS_PERMISSION) && hasPermissions(UPLOAD_ENTITIES_PERMISSION);
  return isShowSegmentsAllowed;
};

export default useShowSegments;
