import type { SemaTopics } from './reviews';

import type { InboxStatusEnum, ViewScoreEnum } from '../client';

export enum DrawerTabs {
  MANAGE = 'MANAGE',
  FILTERS = 'FILTERS',
}

export enum FiltersFormFields {
  Status = 'status',
  Rangename = 'rangename',
  Score = 'score',
  Source = 'source',
  SurveyName = 'survey_name',
  Entities = 'entities',
  Subcategories = 'subcategories',
  Categories = 'categories',
  Sort = 'sort',
  Respondable = 'respondable',
  SemaTopics = 'semaTopics',
  Language = 'language',
}

export type FiltersForm = {
  [FiltersFormFields.Status]: InboxStatusEnum[];
  [FiltersFormFields.Rangename]: string;
  [FiltersFormFields.Score]: ViewScoreEnum[];
  [FiltersFormFields.Source]?: string[];
  [FiltersFormFields.SurveyName]?: string[];
  [FiltersFormFields.Entities]?: string[];
  [FiltersFormFields.Subcategories]?: string[];
  [FiltersFormFields.Categories]?: string[];
  [FiltersFormFields.SemaTopics]?: SemaTopics;
  [FiltersFormFields.Respondable]?: boolean;
  [FiltersFormFields.Language]?: string[];
};
