import { type RefObject, useLayoutEffect, useState } from 'react';

export const useComponentHeight = (myRef: RefObject<HTMLDivElement>) => {
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    const handleResize = () => {
      setHeight(myRef.current?.clientHeight ?? 0);
    };
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myRef.current?.clientHeight]);

  return height;
};
