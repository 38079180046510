import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  appBar: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingX: 3,
    height: '64px',
    flexDirection: 'row',
    background: 'rgba(245, 245, 245, 0.9)',
    boxShadow: 'none',
    alignItems: 'center',
  },
  toolsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexGrow: 1,
    gap: 1,
    alignItems: 'center',
  },
};

export default styles;
