import { type Theme, useMediaQuery, useTheme } from '@mui/material';

const getCurrentBreakpoint = (theme: Theme) => {
  const viewportWidth = window.innerWidth;
  let currentBreakpoint = '';
  theme.breakpoints.keys.forEach((key, index) => {
    const nextKey = theme.breakpoints.keys[index + 1];
    if (
      !currentBreakpoint &&
      theme.breakpoints.values[nextKey] &&
      viewportWidth < theme.breakpoints.values[nextKey]
    ) {
      currentBreakpoint = key;
    }
  });
  return currentBreakpoint || 'xl';
};

// TODO: This is just a draft. We need to sync with design team on breakpoints for responsiveness and realize if we need to detect devices vs desktop
export const useResponsive = () => {
  const theme = useTheme();

  return {
    isSmallPhone: null,
    isPhone: useMediaQuery(theme.breakpoints.down('sm')),
    isTablet: useMediaQuery(theme.breakpoints.between('sm', 'md')),
    // Either phone or tablet
    isMobile: useMediaQuery(theme.breakpoints.down('md')),
    isDesktop: useMediaQuery(theme.breakpoints.down('lg')),
    isDesktopWide: useMediaQuery(theme.breakpoints.between('lg', 'xl')),
    isMobileRange: useMediaQuery(theme.breakpoints.between('xs', 'md')),
    currentBreakpoint: getCurrentBreakpoint(theme),
  };
};
