import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import {
  type IconDefinition,
  faBriefcase,
  faCircleHeart,
  faFamily,
  faPeopleGroup,
  faPerson,
} from '@trustyou/fortawesome/pro-solid-svg-icons';
import { Box, Typography } from '@trustyou/ui';

import { QuestionWrapper } from './question-wrapper';

const ICON_MAP: Record<string, IconDefinition> = {
  business: faBriefcase,
  couple: faCircleHeart,
  family: faFamily,
  friends: faPeopleGroup,
  single: faPerson,
};

const getIcon = (travellerType: string) => ICON_MAP[travellerType] ?? faPerson;

// apply the `styled` mui function with empty rules object just to be able to use the `sx` prop on the FontAwesomeIcon component
const StyleFontAwesomeIcon = styled(FontAwesomeIcon)({});

export type TravellerTypeQuestionProps = {
  name: string;
  text?: string;
};

export const TravellerTypeQuestion = ({ name, text = '' }: TravellerTypeQuestionProps) => (
  <QuestionWrapper name={name}>
    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
      <StyleFontAwesomeIcon
        data-testid="traveller-type-icon"
        size="lg"
        icon={getIcon(text.toLowerCase())}
        sx={{ color: (theme) => theme.palette.action.active }}
      />
      <Typography variant="body2">{text}</Typography>
    </Box>
  </QuestionWrapper>
);
