import { FormattedMessage } from 'react-intl';

import {
  faBookBlank,
  faCircleQuestion,
  faEnvelope,
  faSignsPost,
} from '@trustyou/fortawesome/pro-regular-svg-icons';
import { openFeatureTour, useMembershipStore } from '@trustyou/shared';
import {
  ICON_HEIGHT,
  ICON_WIDTH,
  ListItemIcon,
  MenuItem,
  MenuList,
  NavIcon,
  PopperMenu,
  StyledFontAwesomeIcon,
  Typography,
} from '@trustyou/ui';

import styles from './HelpMenu.styles';

import { useZendeskLinks } from '../../../hooks';

type PopperMenuProps = {
  onClick: () => void;
  isOpen: boolean;
};

const Trigger = ({ onClick, isOpen }: PopperMenuProps) => (
  <NavIcon
    id="help"
    icon={faCircleQuestion}
    shape="circular"
    selected={isOpen}
    tooltip={<FormattedMessage id="topbar.action.help" defaultMessage="Help" />}
    tooltipPlacement="bottom"
    onClick={onClick}
  />
);

const Content = ({ onClick }: PopperMenuProps) => {
  const { openHelpManual, openSupportAndFeedback } = useZendeskLinks();
  const { membership } = useMembershipStore();

  const MENU_ITEMS = [
    {
      id: 'help-manual',
      name: <FormattedMessage id="topbar.helpMenu.helpManual" defaultMessage="Help manual" />,
      onClick: openHelpManual,
      icon: faBookBlank,
    },
    {
      id: 'support-and-feedback',
      name: (
        <FormattedMessage
          id="topbar.helpMenu.supportAndFeedback"
          defaultMessage="Support and feedback"
        />
      ),
      onClick: openSupportAndFeedback,
      icon: faEnvelope,
    },
  ];

  if (membership) {
    MENU_ITEMS.push({
      id: 'feature-tour',
      name: <FormattedMessage id="topbar.helpMenu.featureTour" defaultMessage="Feature tour" />,
      onClick: openFeatureTour,
      icon: faSignsPost,
    });
  }

  return (
    <MenuList autoFocusItem>
      {MENU_ITEMS.map((item) => (
        <MenuItem
          onClick={() => {
            item.onClick();
            onClick();
          }}
          sx={{ paddingBlock: 1.5 }}
        >
          <ListItemIcon>
            <StyledFontAwesomeIcon
              icon={item.icon}
              sx={{
                height: ICON_HEIGHT,
                width: ICON_WIDTH,
              }}
            />
          </ListItemIcon>
          <Typography variant="body2">{item.name}</Typography>
        </MenuItem>
      ))}
    </MenuList>
  );
};

export const HelpMenu = () => (
  <PopperMenu Trigger={Trigger} Content={Content} popperStyle={styles.popper} />
);
