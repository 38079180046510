import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  emptyStateHeader: {
    marginTop: 3,
  },
  emptyStateContainer: {
    marginTop: 3,
  },
  actionButton: {
    marginTop: 4,
  },
  entitySourcesContainer: {
    marginTop: 8,
  },
  noDataContainer: {
    minHeight: { sx: '400px', md: '500px' },
  },
  noDataDescription: {
    maxWidth: '600px',
    textAlign: 'center',
  },
  noRowsWrapper: {
    pt: 2,
    height: '100%',
    display: 'flex',
    gap: 5,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
  noRowsTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 0.5,
    maxWidth: '600px',
    textAlign: 'center',
  },
};

export default styles;
