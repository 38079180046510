import { ContentPane } from '@trustyou/ui';

import { SurveyManagerTabs } from './tabs/tabs';

import { useSurveyRedirect } from '../service/hooks/use-survey-redirect';

export type SurveyManagerProps = {
  isChangeling?: boolean;
};

export const SurveyManager = ({ isChangeling }: SurveyManagerProps) => {
  useSurveyRedirect();

  return (
    <ContentPane sx={{ minHeight: 'auto' }}>
      <SurveyManagerTabs isChangeling={isChangeling} />
    </ContentPane>
  );
};
