import { FormattedMessage } from 'react-intl';

import { INBOX_CONNECTIVITY_MAX_DELAY_IN_HOURS } from '../../../constants/reviewProviders';

const InboxDelayTime = () => (
  <b>
    <FormattedMessage
      id="review-providers.delay.time"
      defaultMessage="up to {hours} hours"
      values={{ hours: INBOX_CONNECTIVITY_MAX_DELAY_IN_HOURS }}
    />
  </b>
);

export default InboxDelayTime;
