import { useQuery } from '@tanstack/react-query';
import { $assApi, ANALYTICS_BASE_PATH } from '@trustyou/shared';

import { REPORT_LAST_STATUS } from '../../constants/query-keys';
import type { ReportLastStatus } from '../../types/reports';

async function fetchReportLastStatus(reportId: string): Promise<ReportLastStatus> {
  const { data } = await $assApi.get(`${ANALYTICS_BASE_PATH}/status/last_status`, {
    params: {
      report_id: reportId,
    },
  });
  return data;
}

export function useReportLastStatus(id: string) {
  return useQuery({
    queryKey: [REPORT_LAST_STATUS, id],
    queryFn: () => fetchReportLastStatus(id),
    refetchOnWindowFocus: false,
    enabled: true,
  });
}
