import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { isRouteEndsWith } from '@trustyou/shared';

export const useSurveyRedirect = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const isParentPage = isRouteEndsWith(pathname, 'survey-manager');
    const isPageSurvey = isRouteEndsWith(pathname, 'survey');
    const isPageQuestion = isRouteEndsWith(pathname, 'question');
    const isContentLibrary = isRouteEndsWith(pathname, 'content-library');
    const isPageTheme = isRouteEndsWith(pathname, 'theme');

    if (isParentPage) {
      navigate('survey/overview', { replace: true });
    }

    if (isPageSurvey) {
      navigate('survey/overview', { replace: true });
    }

    if (isPageQuestion) {
      navigate('question/overview', { replace: true });
    }

    if (isContentLibrary) {
      navigate('content-library/overview', { replace: true });
    }

    if (isPageTheme) {
      navigate('theme/overview', { replace: true });
    }
  }, [pathname, navigate]);
};
