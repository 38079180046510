import { useEffect } from 'react';

import {
  areUITestsRunning,
  gtmAllowedProductCategory,
  useAuthorizationStore,
  useMembershipStore,
} from '@trustyou/shared';

interface LocationProps {
  pathname: string;
  search: string;
  hash: string;
  key: string;
}

const INTERNAL_TRAFFIC_EMAILS = [
  'trustyou',
  'milecrew',
  'reshbd',
  'hospitality-plus.travel',
  'bigt.kz',
  'bigt.ru',
  'hotelpartner.com.tr',
  'dotcomhotel.eu',
  'internotel.gr',
  'pomahamehotelierum.cz',
];

const useGTM = (location?: LocationProps) => {
  const { tokenStore } = useAuthorizationStore();
  const { membership } = useMembershipStore();

  useEffect(() => {
    if (areUITestsRunning()) {
      return;
    }

    const membershipId = membership?.id;

    if (!tokenStore) return;
    if (!membershipId) return;
    if (!tokenStore[membershipId]) return;

    const userId = tokenStore[membershipId].org_center?.claims.scope_id;

    if (!userId) return;
    if (typeof window === 'undefined') return;

    // eslint-disable-next-line prefer-destructuring, @typescript-eslint/no-explicit-any
    const dataLayer = (window as any).dataLayer;
    if (!dataLayer) return;
    if (!Array.isArray(dataLayer)) return;

    const loggedUserEvent = dataLayer.find((elem) => elem.event === 'logged_user_id');
    if (!loggedUserEvent) {
      dataLayer.push({
        event: 'logged_user_id',
        userId,
        organizationId: membership.organizationId,
        subscriptionId: membership.subscriptionId,
      });
      console.info('push new dataLayer event for logged user', userId);
      return;
    }
    // check for duplicates
    if (loggedUserEvent.userId === userId) return;
    loggedUserEvent.userId = userId;
    console.info('replace dataLayer event with new logged user', userId);
  }, [tokenStore, membership]);

  // Event for checking is logged in user trustyou internal user
  useEffect(() => {
    if (areUITestsRunning()) {
      return;
    }

    const membershipId = membership?.id;

    if (!tokenStore) return;
    if (!membershipId) return;
    if (!tokenStore[membershipId]) return;
    const email = tokenStore[membershipId].org_center?.claims.email;

    if (!email) return;
    if (typeof window === 'undefined') return;

    // eslint-disable-next-line prefer-destructuring, @typescript-eslint/no-explicit-any
    const dataLayer = (window as any).dataLayer;
    if (!dataLayer) return;
    if (!Array.isArray(dataLayer)) return;

    const trustyouUserEvent = dataLayer.find((elem) => elem.event === 'trustyouUserEvent');
    const isLoggedUserTrustyou = INTERNAL_TRAFFIC_EMAILS.some((str) => email.includes(str));
    if (!trustyouUserEvent) {
      dataLayer.push({
        event: 'trustyouUserEvent',
        isLoggedUserTrustyou,
      });
      console.info('push new dataLayer event for logged trustyou user', isLoggedUserTrustyou);
      return;
    }
    // check for duplicates
    if (trustyouUserEvent.isLoggedUserTrustyou === isLoggedUserTrustyou) return;
    trustyouUserEvent.isLoggedUserTrustyou = isLoggedUserTrustyou;
    console.info('replace dataLayer event with new logged trustyou user', isLoggedUserTrustyou);
  }, [tokenStore, membership]);

  // Product category dataLayer event
  useEffect(() => {
    if (areUITestsRunning()) {
      return;
    }

    if (!location) return;
    if (!location?.pathname) return;
    if (typeof window === 'undefined') return;

    const productPathName = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    const capitalizedProductPathName =
      productPathName.charAt(0).toUpperCase() + productPathName.slice(1);

    // eslint-disable-next-line prefer-destructuring, @typescript-eslint/no-explicit-any
    const dataLayer = (window as any).dataLayer;
    if (!dataLayer) return;
    if (!Array.isArray(dataLayer)) return;

    if (gtmAllowedProductCategory(location.pathname, capitalizedProductPathName)) {
      dataLayer.push({
        event: 'productEvent',
        productCategory: gtmAllowedProductCategory(location.pathname, capitalizedProductPathName),
      });
      console.info(
        'push dataLayer event for product category',
        gtmAllowedProductCategory(location.pathname, capitalizedProductPathName)
      );
    }
  }, [location, location?.pathname]);
};

export default useGTM;
