import type { StateCreator } from 'zustand';

import { LANGUAGE_ITEM_AUTO } from '../../hooks';
import { type LanguageItem, ResponseTextField } from '../../types';

export interface ResponseSliceState {
  isResponseFormDirty: boolean;
  hasGeneratedResponse: boolean;
  hasExistingResponse: boolean;
  generationLanguage: LanguageItem;
  radioValue: ResponseTextField;
  acceptsDirectResponse: boolean;
  isSubmitDisabled: boolean;
  updateIsSubmitDisabled: (isSubmitDisabled: boolean) => void;
  updateAcceptsDirectResponse: (acceptsDirectResponse: boolean) => void;
  updateRadioValue: (radioValue: ResponseTextField) => void;
  updateGenerationLanguage: (generationLanguage: LanguageItem) => void;
  updateHasExistingResponse: (hasExistingResponse: boolean) => void;
  updateHasGeneratedResponse: (hasGeneratedResponse: boolean) => void;
  updateIsResponseFormDirty: (isResponseFormDirty: boolean) => void;
}

export const createResponseSlice: StateCreator<ResponseSliceState, [], []> = (set) => ({
  isResponseFormDirty: false,
  hasGeneratedResponse: false,
  hasExistingResponse: false,
  generationLanguage: LANGUAGE_ITEM_AUTO,
  radioValue: ResponseTextField.ORIGINAL,
  acceptsDirectResponse: false,
  isSubmitDisabled: true,
  updateIsSubmitDisabled: (isSubmitDisabled) => set(() => ({ isSubmitDisabled })),
  updateAcceptsDirectResponse: (acceptsDirectResponse) => set(() => ({ acceptsDirectResponse })),
  updateRadioValue: (radioValue) => set(() => ({ radioValue })),
  updateGenerationLanguage: (generationLanguage) => set(() => ({ generationLanguage })),
  updateHasExistingResponse: (hasExistingResponse) => set(() => ({ hasExistingResponse })),
  updateHasGeneratedResponse: (hasGeneratedResponse) => set(() => ({ hasGeneratedResponse })),
  updateIsResponseFormDirty: (isResponseFormDirty) => set(() => ({ isResponseFormDirty })),
});
