import { SectorAvatarIcon, Typography } from '@trustyou/ui';

import styles from './styles';

export type NameCellProps = {
  name: string;
  sector: string;
};

const NameCell = ({ name, sector }: NameCellProps) => (
  <>
    <SectorAvatarIcon sx={styles.avatar} sector={sector} />
    <Typography variant="subtitle2">{name}</Typography>
  </>
);

export default NameCell;
