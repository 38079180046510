import type { GridColDef } from '@mui/x-data-grid-pro';
import { Box } from '@trustyou/ui';

import type { SurveyRowByEntity } from '../../../../types/survey';

export function useColumns() {
  const columns: GridColDef<SurveyRowByEntity>[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
    },
    {
      field: 'entity',
      headerName: 'Entity',
      flex: 1,
      renderCell: ({ value }) => <Box fontWeight={500}>{value}</Box>,
    },
    {
      field: 'version',
      headerName: 'Theme version',
      flex: 3,
      renderCell: ({ value }) => (
        <Box fontWeight={500} color="text.secondary">
          {value}
        </Box>
      ),
    },
  ];

  return columns;
}
