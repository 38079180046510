import { type ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Stack,
  StyledFontAwesomeIcon,
  Typography,
} from '@trustyou/ui';

import { useDistributionElements } from './use-distribution-elements';

import { DISTRIBUTION_DRAWER_WIDTH_PX } from '../../../constants/ui';
import type { SurveyRowByEntity } from '../../../types/survey';

type DistributionProps = {
  surveyRowByEntity: SurveyRowByEntity;
};

export type DistributionElementProps = {
  icon: IconProp;
  title: ReactNode;
  description: ReactNode;
  buttonText: ReactNode;
  onButtonClick: () => void;
};

function DistributionElement({
  icon,
  title,
  description,
  buttonText,
  onButtonClick,
}: DistributionElementProps) {
  return (
    <Stack direction="row" spacing={2}>
      <StyledFontAwesomeIcon icon={icon} sx={{ color: 'primary.main', height: 44, width: 44 }} />
      <Box>
        <Typography>{title}</Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
        <Button onClick={onButtonClick} variant="outlined" sx={{ marginTop: 2 }}>
          {buttonText}
        </Button>
      </Box>
    </Stack>
  );
}

export function Distribution({ surveyRowByEntity }: DistributionProps) {
  const [open, setOpen] = useState(false);
  const distributionElements = useDistributionElements(surveyRowByEntity);

  return (
    <>
      <Typography
        variant="body2"
        fontWeight={500}
        color="primary"
        sx={{ cursor: 'pointer' }}
        onClick={() => setOpen(true)}
      >
        <FormattedMessage id="survey.common.view" defaultMessage="View" />
      </Typography>
      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <Stack width={DISTRIBUTION_DRAWER_WIDTH_PX} padding={3}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">{surveyRowByEntity.version}</Typography>
            <IconButton onClick={() => setOpen(false)}>
              <StyledFontAwesomeIcon icon={faXmark} size="sm" />
            </IconButton>
          </Stack>
          <Stack spacing={7}>
            <Typography variant="subtitle1" fontWeight={500} marginTop={2}>
              <FormattedMessage
                defaultMessage="Links"
                id="survey.survey-detail.distribution.links"
              />
            </Typography>
            {distributionElements.map((element, index) => (
              <DistributionElement
                key={index}
                icon={element.icon}
                title={element.title}
                description={element.description}
                buttonText={element.buttonText}
                onButtonClick={element.onButtonClick}
              />
            ))}
          </Stack>
        </Stack>
      </Drawer>
    </>
  );
}
