import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  container: {
    display: 'flex',
    gap: 1,
    borderRadius: '4px',
    background: 'white',
    padding: 3,
    boxShadow:
      '0px 2px 1px -1px rgba(69, 90, 100, 0.10), 0px 1px 1px 0px rgba(69, 90, 100, 0.07), 0px 1px 3px 0px rgba(69, 90, 100, 0.06)',
    cursor: 'pointer',
    transition: 'translate .15s ease-in-out',
    ':hover': {
      translate: '0 -4px',
    },
  },
  mainColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    flexGrow: 1,
  },
  icon: {
    borderRadius: '4px',
    color: 'white',
  },
  navIcon: { color: 'primary.main', width: '24px', height: '24px' },
};

export default styles;
