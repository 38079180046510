import { useIntl } from 'react-intl';

import type { ButtonProps, DialogProps } from '@mui/material';
import { DiscardDialog } from '@trustyou/ui';

import { discardDialogMessages } from './messages';

type FlagDiscardDialogProps = {
  open: boolean;
  onClose?: DialogProps['onClose'];
  onCancel: ButtonProps['onClick'];
  onDiscard: ButtonProps['onClick'];
};

export function SurveyModerationDiscardDialog({
  open,
  onClose,
  onCancel,
  onDiscard,
}: FlagDiscardDialogProps) {
  const intl = useIntl();

  return (
    <DiscardDialog
      title={intl.formatMessage(discardDialogMessages.title)}
      content={intl.formatMessage(discardDialogMessages.content)}
      open={open}
      onClose={onClose}
      onCancel={onCancel}
      onDiscard={onDiscard}
    />
  );
}
