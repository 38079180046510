import { useIntl } from 'react-intl';

import { capitalizeFirstLetter, ordinalDayLocale } from '@trustyou/shared';
import { Stack, Typography } from '@trustyou/ui';
import { DateTime } from 'luxon';

import { commonMessages } from '../../constants/messages';
import { useFrequencyOptions, useLocaleWeekdays } from '../../hooks';
import type { ScheduledReport } from '../../types/reports';

type Props = {
  report: ScheduledReport;
};

export const FrequencyCell = ({ report }: Props) => {
  const intl = useIntl();
  const localeWeekdays = useLocaleWeekdays();
  const frequencyOptions = useFrequencyOptions();

  if (report.period === 'one-time') {
    return (
      <Typography color="text.secondary" variant="body2">
        {intl.formatMessage(commonMessages.frequencyTypeOneTime)}
      </Typography>
    );
  }

  let frequency = '';
  const hour = DateTime.now()
    .set({ hour: report.hour, minute: 0, second: 0, millisecond: 0 })
    .toFormat('HH:00');
  const localeRules = ordinalDayLocale(intl.locale);
  if (report.period === 'weekly') {
    frequency = `${localeWeekdays[report.day_of_week as number]}, ${hour}`;
  } else if (report.period === 'monthly') {
    frequency = `${localeRules(report.day as number)}, ${hour}`;
  } else if (report.period === 'daily') {
    frequency = `${hour}`;
  }

  return (
    <Stack>
      <Typography color="text.secondary" variant="body2">
        {capitalizeFirstLetter(
          frequencyOptions.find((item) => item.value === report.period)?.label as string
        )}
      </Typography>
      <Typography color="text.secondary" variant="body2">
        {frequency}
      </Typography>
    </Stack>
  );
};
