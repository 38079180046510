export const DRAWER_WIDTH_DESKTOP = '256px';
export const DRAWER_WIDTH_MOBILE = '340px';

export const ICON_BUTTON_HEIGHT = '44px';
export const ICON_BUTTON_WIDTH = '44px';

export const ICON_BUTTON_HEIGHT_SMALLER = '32px';
export const ICON_BUTTON_WIDTH_SMALLER = '32px';

export const ICON_HEIGHT = '20px';
export const ICON_WIDTH = '20px';

// #0057B214 equals to #0057B2 with 8% opacity, which is defined in the Figma design:
// https://www.figma.com/design/0WTxp0x1mKHJ3mlRiifbL8/CXP-Customers?node-id=6242-42803&m=dev
export const NAV_ICON_BACKGROUND_COLOR = '#0057B214';
