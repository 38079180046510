import { $assApi, INBOX_BASE_PATH } from '@trustyou/shared';

import type {
  GenerateResponseIn,
  Profile_Input,
  Profile_Output,
  TranslateIn,
} from '../../../client';
import type {
  FetchResponseAITranslateData,
  Language,
  LanguageItem,
  ResponseAIFeedback,
  ResponseResult,
  UserProfileData,
} from '../../../types';

export async function fetchResponseAITranslate(
  payload: TranslateIn
): Promise<FetchResponseAITranslateData> {
  const { data } = await $assApi.post(`${INBOX_BASE_PATH}/response-ai/translate`, payload);
  return data;
}

export async function fetchResponseAILanguages(): Promise<LanguageItem[]> {
  const { data } = await $assApi.get(`${INBOX_BASE_PATH}/response-ai/get-languages`);
  return (
    data as {
      language: Language;
      name: string;
    }[]
  ).map(({ language, name }) => ({
    value: language,
    label: name,
  }));
}

export async function fetchResponseAiGetProfile(): Promise<UserProfileData> {
  const { data } = await $assApi.get<UserProfileData>(`${INBOX_BASE_PATH}/response-ai/get-profile`);
  return data;
}

export async function updateResponseAIManageProfile(
  profileData: Profile_Input
): Promise<Profile_Output> {
  const { data } = await $assApi.put<UserProfileData>(
    `${INBOX_BASE_PATH}/response-ai/edit-profile`,
    profileData
  );
  return data;
}

let abortController: AbortController | undefined;

export const newAbortController = () => {
  if (abortController) {
    abortController = undefined;
  }
  abortController = new AbortController();
};

export const resetAbortController = () => {
  if (abortController) {
    abortController.abort();
  }
  abortController = undefined;
};

export async function generateResponseAI(payload: GenerateResponseIn): Promise<ResponseResult> {
  const { data } = await $assApi.post(`${INBOX_BASE_PATH}/response-ai/generate-response`, payload, {
    signal: abortController?.signal,
  });
  return data;
}

export async function saveResponseAIFeedback(payload: ResponseAIFeedback) {
  const { data } = await $assApi.patch(`${INBOX_BASE_PATH}/response-ai/update-feedback`, payload);
  return data;
}
