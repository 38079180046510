import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import type { DateRange } from '@mui/x-date-pickers-pro';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@trustyou/ui';
import dayjs, { type Dayjs } from 'dayjs';

import CustomDateRangePicker from './custom-date-range-picker';

import type { Timerange } from '../../client';

export type CustomDateRangeDialogProps = {
  initialValue?: Timerange;
  isOpen?: boolean;
  onSubmit: (range: DateRange<Dayjs>) => void;
  onClose: () => void;
};

export const CustomDateRangeDialog = ({
  initialValue = { start: dayjs().unix(), end: dayjs().unix() },
  onSubmit,
  onClose,
  isOpen = false,
}: CustomDateRangeDialogProps) => {
  const { start, end } = initialValue;
  const initialStart = start ? dayjs(start * 1000) : dayjs();
  const initialEnd = end ? dayjs(end * 1000) : dayjs();
  const [range, setRange] = useState<DateRange<Dayjs>>([initialStart, initialEnd]);

  const isValid = () => {
    const [start, end] = range;
    if (start === null || end === null) {
      return false;
    }
    return start <= end;
  };

  const applyDateRange = () => {
    if (!isValid()) return;
    onSubmit(range);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <FormattedMessage id="inbox.custom-date-range" defaultMessage="Custom Date Range" />
      </DialogTitle>
      <DialogContent sx={{ paddingTop: '8px !important' }}>
        <CustomDateRangePicker value={range} onChange={setRange} />
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>
          <FormattedMessage id="inbox.action.cancel" defaultMessage="Cancel" />
        </Button>
        <Button variant="contained" disabled={!isValid()} onClick={applyDateRange}>
          <FormattedMessage id="inbox.action.apply" defaultMessage="Apply" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
