import type { ReactNode } from 'react';

import { Box, Typography } from '@mui/material';

type SurveyResponseProps = {
  heading: ReactNode;
  responseText: ReactNode;
};

export function SurveyResponse({ heading, responseText }: SurveyResponseProps) {
  return (
    <Box>
      <Typography variant="subtitle2" marginBottom={1}>
        {heading}
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ whiteSpace: 'pre-wrap' }}>
        {responseText}
      </Typography>
    </Box>
  );
}
