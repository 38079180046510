import { FormattedMessage } from 'react-intl';

import { Box, Chip, Typography } from '@trustyou/ui';

import styles from './styles';

const ProTips = () => (
  <Box sx={styles.container}>
    <Chip
      sx={styles.chip}
      label={<FormattedMessage id="entitiesImporter.proTip" defaultMessage="Pro tip" />}
      size="small"
    />
    <Typography variant="body2">
      <FormattedMessage
        id="entitiesImporter.exampleMandatoryFields"
        defaultMessage="Use English only in your spreadsheet so that we can match your entities in our database"
      />
    </Typography>
  </Box>
);

export default ProTips;
