import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { IconButton } from '@mui/material';
import { faArrowLeft } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  type FileProp,
  ImageUploadDialog,
  Stack,
  StyledFontAwesomeIcon,
  Typography,
} from '@trustyou/ui';

import { DataGrid as ContentLibraryImagesDataGrid } from '../../../components/content-library/images-detail/data-grid';

export const ContentLibraryImages = () => {
  const navigate = useNavigate();
  const [image, setImage] = useState<FileProp>(null);
  const [open, setOpen] = useState(false);
  const { membershipId } = useParams();

  const navigateToContentLibraryList = () => {
    navigate(`/org/${membershipId}/survey-manager/content-library/overview`);
  };

  return (
    <Stack>
      <Stack direction="row" spacing={1} alignItems="center" marginBottom={2}>
        <IconButton onClick={navigateToContentLibraryList}>
          <StyledFontAwesomeIcon icon={faArrowLeft} size="sm" />
        </IconButton>
        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          <FormattedMessage id="survey.common.content-library" defaultMessage="Content library" />
        </Typography>
      </Stack>
      <Stack flexDirection="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Stack marginBottom={2}>
          <Typography variant="h6">
            <FormattedMessage id="survey.common.images" defaultMessage="Images" />
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <FormattedMessage
              id="survey.content-library.images-detail.description"
              defaultMessage="Upload and manage images which you can later use in themes. If you don’t upload and add images to a theme, the predefined images will be used."
            />
          </Typography>
        </Stack>
        {/* TODO: make it visible again when we decide it */}
        {/* <Button
          variant="contained"
          sx={{ height: 36 }}
          startIcon={<StyledFontAwesomeIcon icon={faPlus} />}
          onClick={() => setOpen(true)}
        >
          <FormattedMessage id="survey.common.new-image" defaultMessage="New image" />
        </Button> */}
      </Stack>
      <ContentLibraryImagesDataGrid />
      <ImageUploadDialog
        file={image}
        setFile={setImage}
        open={open}
        setOpen={(value) => setOpen(value)}
      />
    </Stack>
  );
};
