import { type IntlShape, useIntl } from 'react-intl';

import { commonFields, isTemporaryHidden } from '@trustyou/shared';

import { useShowSegments } from '../../../../hooks';

interface EntityPageTabStructure {
  all_entities: string;
  segments?: string;
  visitData?: string;
}

const getDefaultTabs = (intl: IntlShape): EntityPageTabStructure => ({
  all_entities: intl.formatMessage({ id: 'common.entities.all', defaultMessage: 'All entities' }),
  segments: intl.formatMessage(commonFields.segments),
  ...(!isTemporaryHidden('CXP-customers-visit-data')
    ? { visitData: intl.formatMessage({ id: 'common.visit-data', defaultMessage: 'Visit data' }) }
    : {}),
});

const useEntityPageTabs = () => {
  const intl = useIntl();
  const isShowSegmentsAllowed = useShowSegments();
  const entityPageTabs = getDefaultTabs(intl);
  if (!isShowSegmentsAllowed) {
    delete entityPageTabs.segments;
  }
  //TODO: Add visitData tab checking here
  return entityPageTabs;
};

export default useEntityPageTabs;
