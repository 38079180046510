import { FormattedMessage } from 'react-intl';

import { faTrash } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { StyledFontAwesomeIcon as Icon, IconButton, Tooltip } from '@trustyou/ui';

import styles from './styles';

export type ActionCellProps = {
  deleteTooltipId: string;
  deleteTooltipMessage: string;
  onDelete: () => void;
  enabled?: boolean;
};

const ActionCell = ({
  deleteTooltipId,
  deleteTooltipMessage,
  onDelete,
  enabled = true,
}: ActionCellProps) => {
  const deleteHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onDelete();
  };

  if (!enabled) return null;

  return (
    <Tooltip
      placement="top"
      arrow
      title={<FormattedMessage id={deleteTooltipId} defaultMessage={deleteTooltipMessage} />}
    >
      <IconButton
        data-testid="delete-button"
        data-gtm-id={`${deleteTooltipId}-delete-button`}
        size="small"
        sx={styles.deleteButton}
        onClick={deleteHandler}
      >
        <Icon icon={faTrash} />
      </IconButton>
    </Tooltip>
  );
};

export default ActionCell;
