import type { IStyles } from '@trustyou/shared';
import { ICON_HEIGHT, ICON_WIDTH } from '@trustyou/ui';

export const styles: IStyles = {
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  headerOuterWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
  editIcon: {
    width: ICON_WIDTH,
    height: ICON_HEIGHT,
  },
  loader: {
    paddingY: 5,
    textAlign: 'center',
  },
  paneHeader: {
    pl: 3,
  },
  paneWrapper: {
    pt: 2,
  },
  detailsWrapper: {
    mt: 3,
  },
  tabsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  container: {
    height: '100%',
  },
  barContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingX: 3,
  },
  body: {
    padding: 3,
    paddingBottom: 1.5,
    height: 'calc(100% - 48px)',
    overflow: 'auto',
  },
};
