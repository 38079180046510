import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    borderRadius: '4px',
    background: 'white',
    padding: 3,
    boxShadow:
      '0px 2px 1px -1px rgba(69, 90, 100, 0.10), 0px 1px 1px 0px rgba(69, 90, 100, 0.07), 0px 1px 3px 0px rgba(69, 90, 100, 0.06)',
  },
  listItemButton: {
    padding: 1,
    display: 'flex',
    gap: 2,
  },
  icon: {
    borderRadius: '50%',
  },
};

export default styles;
