import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { ButtonGroup, IconButton } from '@mui/material';
import { faArrowLeft } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Box, Button, Stack, StyledFontAwesomeIcon, Typography } from '@trustyou/ui';

import { DataGrid as SurveyDetailByEntityDataGrid } from '../../../components/survey/detail/by-entity/data-grid';
import { DataGrid as SurveyDetailByQuestionnaireDataGrid } from '../../../components/survey/detail/by-questionnaire/data-grid';
import { useSurveys } from '../../../service/hooks/use-surveys';

type SurveyDetailView = 'by-questionnaire' | 'by-entity';

export const SurveyDetail = () => {
  const navigate = useNavigate();
  const { surveyId, membershipId } = useParams();
  const [view, setView] = useState<SurveyDetailView>('by-questionnaire');
  const { data: surveys = [] } = useSurveys();

  const navigateToSurveyList = () => {
    navigate(`/org/${membershipId}/survey-manager/survey/overview`);
  };

  return (
    <Stack>
      <Stack direction="row" spacing={1} alignItems="center" marginBottom={2}>
        <IconButton onClick={navigateToSurveyList}>
          <StyledFontAwesomeIcon icon={faArrowLeft} size="sm" />
        </IconButton>
        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          <FormattedMessage
            id="survey.survey-detail.navigation-back"
            defaultMessage="Surveys overview"
          />
        </Typography>
      </Stack>
      <Typography variant="h6" component="h2" marginBottom={3}>
        {surveys.find((survey) => survey.id === surveyId)?.name}
      </Typography>
      <Stack direction="row" spacing={5} alignItems="center" marginBottom={1}>
        <ButtonGroup>
          <Button
            variant={view === 'by-questionnaire' ? 'contained' : 'outlined'}
            onClick={() => setView('by-questionnaire')}
          >
            <FormattedMessage
              id="survey.common.by-questionnaire"
              defaultMessage="By questionnaire"
            />
          </Button>
          <Button
            variant={view === 'by-entity' ? 'contained' : 'outlined'}
            onClick={() => setView('by-entity')}
          >
            <FormattedMessage id="survey.common.by-entity" defaultMessage="By entity" />
          </Button>
        </ButtonGroup>
        {/* TODO: Hiding this for now enable it when needed */}
        {/* <Alert severity="warning">
          <FormattedMessage
            id="survey.survey-detail.missing-entities"
            defaultMessage="{missingEntityCount} entities are missing a version"
            values={{ missingEntityCount: 10 }}
          />
        </Alert> */}
        <Box component="span" flexGrow={1} />
      </Stack>
      {view === 'by-questionnaire' && <SurveyDetailByQuestionnaireDataGrid />}
      {view === 'by-entity' && <SurveyDetailByEntityDataGrid />}
    </Stack>
  );
};
