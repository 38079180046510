import { red } from '@mui/material/colors';
import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  statusCounts: {
    mt: 3,
  },
  statusCountBox: { display: 'flex', alignItems: 'center', marginRight: 3 },
  statusCountText: { mr: 1 },
  dataGrid: {
    marginTop: 2,
    overflowX: 'auto',
    width: '100%',
  },
  textCell: {
    whiteSpace: 'break-spaces',
  },
  errorCell: {
    backgroundColor: red[50],
    border: '1px solid',
    borderColor: 'error.main',
    padding: 1,
    position: 'relative',
  },
  errorCellIcon: {
    position: 'absolute',
    top: '3px',
    right: '3px',
    color: red[600],
  },
  footerMessage: {
    textAlign: 'right',
    paddingRight: 3,
    color: 'text.secondary',
  },
};

export default styles;
