import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAlertStore, useInboxStore } from '@trustyou/shared';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@trustyou/ui';

import { useFetchViews, useSaveViews } from '../../hooks';
import type { FiltersForm } from '../../types';
import { getViewFromFields } from '../../utils/filters';

type NewViewButtonProps = {
  onCancel: () => void;
};

export function NewViewButton({ onCancel }: NewViewButtonProps) {
  // Not using react-hook-form for the viewLabel because marking it as required (while not being rendered)
  // prevents applying filters on-the-fly (without saving them into a view).
  const [viewLabel, setViewLabel] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  const intl = useIntl();
  const setCurrentView = useInboxStore((state) => state.setCurrentView);
  const saveViews = useSaveViews();
  const { data: views = [] } = useFetchViews();
  const { getValues } = useFormContext<FiltersForm>();
  const { alert } = useAlertStore();

  const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
    event
  ) => {
    const newViewLabel = event.target.value;
    setViewLabel(newViewLabel);
    const isViewLabelAlreadyTaken = views.some((view) => view.label === newViewLabel);

    if (newViewLabel.trim().length === 0) {
      setErrorMessage(
        intl.formatMessage({
          id: 'inbox.views.label.error.required',
          defaultMessage: 'View label is required',
        })
      );
    } else if (isViewLabelAlreadyTaken) {
      setErrorMessage(
        intl.formatMessage({
          id: 'inbox.views.label.error.already-exists',
          defaultMessage: 'This View label already exists',
        })
      );
    } else {
      setErrorMessage('');
    }
  };

  const isValid = viewLabel.trim().length > 0 && !errorMessage;

  const saveNewView = () => {
    const newView = getViewFromFields({ fields: getValues(), viewLabel: viewLabel });
    const payload = [...views, newView];
    saveViews.mutate(payload, {
      onSuccess: () => alert.success('View saved successfully'),
    });
    setCurrentView(newView);
    onCancel();
  };

  const handleSubmitForm: React.FormEventHandler = (event) => {
    event.preventDefault();
    saveNewView();
    closeDialog();
  };

  return (
    <>
      <Button
        color="secondary"
        size="large"
        onClick={openDialog}
        data-gtm-id="inbox_filters_save_as_view_button"
      >
        <FormattedMessage id="inbox.filter.saveAsView" defaultMessage="Save filters as a View" />
      </Button>
      <Dialog open={isDialogOpen} onClose={closeDialog} maxWidth="xs" fullWidth>
        <form onSubmit={handleSubmitForm}>
          <DialogTitle>
            <FormattedMessage
              id="inbox.filters.custom-view.dialog.title"
              defaultMessage="New custom View"
            />
          </DialogTitle>
          <DialogContent sx={{ height: (theme) => theme.spacing(15) }}>
            <TextField
              value={viewLabel}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              margin="dense"
              autoComplete="off"
              autoFocus
              fullWidth
              label={intl.formatMessage({
                id: 'inbox.filters.custom-view.label',
                defaultMessage: 'Custom View label',
              })}
              placeholder={intl.formatMessage({
                id: 'inbox.filters.custom-view.placeholder',
                defaultMessage: 'My custom View',
              })}
              helperText={errorMessage}
              error={!!errorMessage}
            />
          </DialogContent>
          <DialogActions>
            <Button type="button" color="inherit" onClick={closeDialog}>
              <FormattedMessage id="inbox.action.cancel" defaultMessage="Cancel" />
            </Button>
            <Button type="submit" variant="contained" disabled={!isValid}>
              <FormattedMessage id="inbox.action.save" defaultMessage="Save" />
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
