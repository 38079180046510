import {
  UPDATE_ENTITY_CUSTOM_ATTRIBUTES_PERMISSION,
  isTemporaryHidden,
  usePermissionStore,
} from '@trustyou/shared';

const useUpdateEntityCustomAttributes = () => {
  const { hasPermissions } = usePermissionStore();
  return (
    !isTemporaryHidden('CXP-customers-custom-attributes') &&
    hasPermissions(UPDATE_ENTITY_CUSTOM_ATTRIBUTES_PERMISSION)
  );
};

export default useUpdateEntityCustomAttributes;
