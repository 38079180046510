import type { ReactNode } from 'react';

import type { CardProps } from '@mui/material';
import { faArrowRight } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { CardMedia, StyledFontAwesomeIcon, Typography } from '@trustyou/ui';

import { BaseCard } from './base-card';

type Image = {
  src: string;
  alt: string;
};

type BigCardProps = {
  image: Image;
  title: ReactNode;
  description: ReactNode;
  onClick: CardProps['onClick'];
};

export function BigCard({ image, title, description, onClick }: BigCardProps) {
  return (
    <BaseCard onClick={onClick} sx={{ gap: 2, width: 350 }}>
      <CardMedia component="img" image={image.src} alt={image.alt} />
      <Typography variant="h6" component="h3">
        {title}
      </Typography>
      <Typography variant="body2">{description}</Typography>
      <StyledFontAwesomeIcon
        icon={faArrowRight}
        size="lg"
        sx={{ color: 'primary.main', alignSelf: 'end' }}
      />
    </BaseCard>
  );
}
