import { FormattedMessage } from 'react-intl';

import type { CombinedUserAndInvitation } from '@trustyou/shared';
import { DeleteModal } from '@trustyou/ui';

export type InvitationDeleteModalProps = {
  invitation?: CombinedUserAndInvitation;
  onClose: () => void;
  onDelete: () => void;
};

const InvitationDeleteModal = ({ invitation, onClose, onDelete }: InvitationDeleteModalProps) => (
  <DeleteModal
    cancelText={<FormattedMessage id="organization.users.cancel" defaultMessage="Cancel" />}
    content={
      <FormattedMessage
        id="organization.users.deleteInvitationContent"
        defaultMessage="If you confirm, this invitation will be no longer valid"
      />
    }
    dataName="invitation-delete"
    deleteText={
      <FormattedMessage
        id="organization.users.deleteInvitation"
        defaultMessage="Delete invitation"
      />
    }
    onClose={onClose}
    onDelete={onDelete}
    title={
      <FormattedMessage
        id="organization.users.removeInvitation"
        defaultMessage="Delete invitation for {email}?"
        values={{ email: invitation?.email }}
      />
    }
    open={!!invitation}
  />
);

export default InvitationDeleteModal;
