import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import type { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { faPencil, faTrash } from '@trustyou/fortawesome/pro-regular-svg-icons';
import type { AccessGroup } from '@trustyou/shared';
import { ActionCell } from '@trustyou/ui';

const accessGroupTableMessages = defineMessages({
  name: {
    id: 'access-group.table.header.name',
    defaultMessage: 'Name',
  },
  numberOfEntities: {
    id: 'access-group.table.header.number-of-entities',
    defaultMessage: 'Number of entities',
  },
  usedBy: {
    id: 'access-group.table.header.used-by',
    defaultMessage: 'Used by',
  },
  usedByMembersCount: {
    id: 'access-group.table.column.used-by.members-count',
    defaultMessage: '{count} members',
  },
});

export interface AccessGroupTableColumnsProps {
  onDelete: (accessGroup: AccessGroup) => void;
  onEdit: (accessGroup: AccessGroup) => void;
}

const useAccessGroupTableColumns = ({
  onEdit,
  onDelete,
}: AccessGroupTableColumnsProps): GridColDef[] => {
  const intl = useIntl();
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: intl.formatMessage(accessGroupTableMessages.name),
      minWidth: 350,
      flex: 1,
      sortable: false,
    },
    {
      field: 'entities_count',
      headerName: intl.formatMessage(accessGroupTableMessages.numberOfEntities),
      minWidth: 200,
      flex: 1,
      sortable: false,
    },
    {
      field: 'members_count',
      headerName: intl.formatMessage(accessGroupTableMessages.usedBy),
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: ({ row }: GridCellParams) =>
        intl.formatMessage(accessGroupTableMessages.usedByMembersCount, {
          count: row.members_count,
        }),
    },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      disableColumnMenu: true,
      hideable: false,
      resizable: false,
      renderCell: ({ row }: GridCellParams) => (
        <ActionCell
          testId="access-groups-action-menu"
          actions={[
            {
              testId: 'access-groups-action-edit',
              icon: faPencil,
              handler: () => onEdit(row),
              message: <FormattedMessage id="access-group.action.edit" defaultMessage="Edit" />,
            },
            {
              testId: 'access-groups-action-delete',
              icon: faTrash,
              handler: () => onDelete(row),
              message: <FormattedMessage id="access-group.action.delete" defaultMessage="Delete" />,
              isDisabled: row.in_usage,
            },
          ]}
        />
      ),
    },
  ];

  return columns;
};

export default useAccessGroupTableColumns;
