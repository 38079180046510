import { FormattedMessage } from 'react-intl';

import { FormControl, InputLabel, Stack, Typography } from '@trustyou/ui';

import { CustomTextFieldWithSeparatedLabel } from '../../../shared';

export function ThemeEditorSettingsContent() {
  return (
    <>
      <Typography variant="h6" sx={{ marginBottom: 3 }}>
        <FormattedMessage id="survey.common.settings" defaultMessage="Settings" />
      </Typography>
      <Stack sx={{ maxWidth: 300 }}>
        <FormControl variant="standard" fullWidth>
          <InputLabel shrink>
            <FormattedMessage id="survey.common.name" defaultMessage="Name" />
          </InputLabel>
          <CustomTextFieldWithSeparatedLabel fullWidth size="small" />
        </FormControl>
      </Stack>
    </>
  );
}
