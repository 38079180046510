import { useMutation, useQuery } from '@tanstack/react-query';
import { $assApi, SURVEY_BASE_PATH } from '@trustyou/shared';
import type { AxiosError } from 'axios';

import type { CreateDatapointSchema, Datapoint } from '../../client';
import {
  CREATE_VISIT_DATAPOINT,
  DELETE_VISIT_DATAPOINT,
  FETCH_VISIT_DATAPOINT,
  UPDATE_VISIT_DATAPOINT,
} from '../constants/query-keys';

export async function fetchVisitDatapoints(): Promise<Datapoint> {
  const { data } = await $assApi.get<Datapoint>(`${SURVEY_BASE_PATH}/datapoint/list`);
  return data;
}

export async function createVisitDatapoint(body: CreateDatapointSchema): Promise<Datapoint> {
  const { data } = await $assApi.post<Datapoint>(`${SURVEY_BASE_PATH}/datapoint/create`, body);
  return data;
}

export async function updateVisitDatapoint(
  body: CreateDatapointSchema,
  datapoint_id?: string
): Promise<Datapoint> {
  const { data } = await $assApi.patch<Datapoint>(
    `${SURVEY_BASE_PATH}/datapoint/update/${datapoint_id}`,
    body
  );
  return data;
}

export async function deleteVisitDatapoint(datapoint_id?: string): Promise<Datapoint> {
  const { data } = await $assApi.delete<Datapoint>(
    `${SURVEY_BASE_PATH}/datapoint/delete/${datapoint_id}`
  );
  return data;
}

export function useFetchVisitDatapoints() {
  return useQuery({
    queryKey: [FETCH_VISIT_DATAPOINT],
    queryFn: () => fetchVisitDatapoints(),
  });
}

export const useCreateVisitDatapoint = (
  onSuccess?: (data: CreateDatapointSchema) => void,
  onError?: (error: AxiosError<unknown>) => void
) => {
  const createVisitDatapointMutation = (form: CreateDatapointSchema) => {
    return createVisitDatapoint(form);
  };
  return useMutation({
    mutationFn: createVisitDatapointMutation,
    mutationKey: [CREATE_VISIT_DATAPOINT],
    onSuccess,
    onError,
  });
};

export const useUpdateVisitDatapoint = (
  datapoint_id?: string,
  onSuccess?: (data: CreateDatapointSchema) => void,
  onError?: (error: AxiosError<unknown>) => void
) => {
  const updateVisitDatapointMutation = (form: CreateDatapointSchema) => {
    return updateVisitDatapoint(form, datapoint_id);
  };
  return useMutation({
    mutationFn: updateVisitDatapointMutation,
    mutationKey: [UPDATE_VISIT_DATAPOINT],
    onSuccess,
    onError,
  });
};

export const useDeleteVisitDatapoint = (
  datapoint_id?: string,
  onSuccess?: (data: CreateDatapointSchema) => void,
  onError?: (error: AxiosError<unknown>) => void
) => {
  const deleteVisitDatapointMutation = () => {
    return deleteVisitDatapoint(datapoint_id);
  };
  return useMutation({
    mutationFn: deleteVisitDatapointMutation,
    mutationKey: [DELETE_VISIT_DATAPOINT],
    onSuccess,
    onError,
  });
};
