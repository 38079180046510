/* eslint-disable @nx/enforce-module-boundaries */
import { blueGrey, green, lightBlue, orange, red } from '@mui/material/colors';
import { deDE, enUS, esES, frFR, itIT, jaJP, ptPT, ruRU, zhCN } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';

import * as components from '../design-tokens/components';
import * as baseTokens from '../design-tokens/light/base';

const defaultOptions = {
  palette: {
    type: 'light',
    primary: {
      main: baseTokens.colorPrimaryMain,
      light: baseTokens.colorPrimaryLight,
      dark: baseTokens.colorPrimaryDark,
      contrastText: baseTokens.colorPrimaryContrast,
    },
    secondary: {
      main: baseTokens.colorSecondaryMain,
      light: baseTokens.colorSecondaryLight,
      dark: baseTokens.colorSecondaryDark,
      contrastText: baseTokens.colorSecondaryContrast,
    },
    error: {
      main: baseTokens.colorErrorMain,
      light: baseTokens.colorErrorLight,
      dark: baseTokens.colorErrorDark,
      contrastText: baseTokens.colorErrorContrast,
    },
    warning: {
      main: baseTokens.colorWarningMain,
      light: baseTokens.colorWarningLight,
      dark: baseTokens.colorWarningDark,
      contrastText: baseTokens.colorWarningContrast,
    },
    info: {
      main: baseTokens.colorInfoMain,
      light: baseTokens.colorInfoLight,
      dark: baseTokens.colorInfoDark,
      contrastText: baseTokens.colorInfoContrast,
    },
    success: {
      main: baseTokens.colorSuccessMain,
      light: baseTokens.colorSuccessLight,
      dark: baseTokens.colorSuccessDark,
      contrastText: baseTokens.colorSuccessContrast,
    },
    text: {
      primary: baseTokens.colorTextPrimary,
      secondary: baseTokens.colorTextSecondary,
      disabled: baseTokens.colorTextDisabled,
      hint: baseTokens.colorTextHint,
    },
    // divider: baseTokens.colorDivider,
    background: {
      default: baseTokens.colorBackgroundDefault,
      paper: baseTokens.colorBackgroundPaper,
    },
  },
  shape: {
    borderRadius: +baseTokens.borderRadiusShapeBase,
  },
  spacing: +baseTokens.spacingSpacingBase,
  breakpoints: {
    values: {
      xs: +baseTokens.breakpointBreakpointXs,
      sm: +baseTokens.breakpointBreakpointSm,
      md: +baseTokens.breakpointBreakpointMd,
      lg: +baseTokens.breakpointBreakpointLg,
      xl: +baseTokens.breakpointBreakpointXl,
    },
  },
  components: {
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          ...components.ComponentsAlertTitle,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          ...components.ComponentsAvatarInitials,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          ...components.ComponentsBadgeLabel,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          ...components.ComponentsInputText,
        },
      },
    },
    // TODO: review with designers all these design tokens that are overriding default mui styles
    // MuiInputLabel: {
    //   styleOverrides: {
    //     shrink: {
    //       ...components.ComponentsInputLabel,
    //     },
    //   },
    // },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          ...components.ComponentsTooltipLabel,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          ...components.ComponentsHelperText,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          ...components.ComponentsTableHeader,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ...components.ComponentsMenuItem,
        },
        dense: {
          ...components.ComponentsMenuItemDense,
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          ...components.ComponentsListSubheader,
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        label: {
          ...components.ComponentsBottomNavigationActiveLabel,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          ...components.ComponentsChipLabel,
        },
      },
      variants: [
        {
          props: { variant: 'pastelDefault' },
          style: {
            backgroundColor: blueGrey[50],
            color: blueGrey[600],
            '& .MuiChip-label': {
              textTransform: 'uppercase',
              fontWeight: 600,
            },
          },
        },
        {
          props: { variant: 'pastelError' },
          style: {
            backgroundColor: red[50],
            color: baseTokens.colorErrorMain,
            '& .MuiChip-label': {
              textTransform: 'uppercase',
              fontWeight: 600,
            },
          },
        },
        {
          props: { variant: 'pastelWarning' },
          style: {
            backgroundColor: orange[50],
            color: baseTokens.colorWarningDark,
            '& .MuiChip-label': {
              textTransform: 'uppercase',
              fontWeight: 600,
            },
          },
        },
        {
          props: { variant: 'pastelInfo' },
          style: {
            backgroundColor: lightBlue[50],
            color: baseTokens.colorInfoMain,
            '& .MuiChip-label': {
              textTransform: 'uppercase',
              fontWeight: 600,
            },
          },
        },
        {
          props: { variant: 'pastelSuccess' },
          style: {
            backgroundColor: green[50],
            color: baseTokens.colorSuccessMain,
            '& .MuiChip-label': {
              textTransform: 'uppercase',
              fontWeight: 600,
            },
          },
        },
      ],
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'subtitle1' },
          style: {
            ...baseTokens.typographyTypographySubtitle1,
          },
        },
      ],
    },
  },
  shadows: [
    'none',
    baseTokens.boxShadowElevation1,
    baseTokens.boxShadowElevation2,
    baseTokens.boxShadowElevation3,
    baseTokens.boxShadowElevation4,
    baseTokens.boxShadowElevation5,
    baseTokens.boxShadowElevation6,
    baseTokens.boxShadowElevation7,
    baseTokens.boxShadowElevation8,
    baseTokens.boxShadowElevation9,
    baseTokens.boxShadowElevation10,
    baseTokens.boxShadowElevation11,
    baseTokens.boxShadowElevation12,
    baseTokens.boxShadowElevation13,
    baseTokens.boxShadowElevation14,
    baseTokens.boxShadowElevation15,
    baseTokens.boxShadowElevation16,
    baseTokens.boxShadowElevation17,
    baseTokens.boxShadowElevation18,
    baseTokens.boxShadowElevation19,
    baseTokens.boxShadowElevation20,
    baseTokens.boxShadowElevation21,
    baseTokens.boxShadowElevation22,
    baseTokens.boxShadowElevation23,
    baseTokens.boxShadowElevation24,
  ],
};

// @ts-expect-error argument defaultOptions is not assignable to type ThemeOptions
export const theme = createTheme(defaultOptions);

export const getLocalizedTheme = (locale?: string) => {
  const localesMap: Record<string, typeof enUS> = {
    'de-DE': deDE,
    'en-US': enUS,
    'es-ES': esES,
    'fr-FR': frFR,
    'it-IT': itIT,
    'ja-JP': jaJP,
    'pt-PT': ptPT,
    'zh-CN': zhCN,
    'ru-RU': ruRU,
  };
  // @ts-expect-error argument defaultOptions is not assignable to type ThemeOptions
  if (locale) return createTheme(defaultOptions, localesMap[locale]);
  return theme;
};
