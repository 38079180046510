import { faArrowLeft, faPencil } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  type Segment,
  type SegmentGroup,
  isGroupManageEnabled,
  isSegmentManageEnabled,
} from '@trustyou/shared';
import { Box, StyledFontAwesomeIcon as Icon, IconButton, Typography } from '@trustyou/ui';

import { useManageGroups, useManageSegments } from '../../../../hooks';
import styles from './styles';

export type SegmentNavBarProps = {
  segment: Segment;
  group?: SegmentGroup;
  onGoBack: () => void;
  onEdit: () => void;
};

const SegmentNavBar = ({ segment, group, onGoBack, onEdit }: SegmentNavBarProps) => {
  const isManageSegmentsAllowed = useManageSegments();
  const isManageGroupsAllowed = useManageGroups();

  const isManageAllowed = group ? isManageGroupsAllowed : isManageSegmentsAllowed;
  const isManageEnabled = group ? isGroupManageEnabled(group) : isSegmentManageEnabled(segment);

  const showEditIcon = isManageAllowed && isManageEnabled;

  return (
    <Box sx={styles.segmentsHeader}>
      <IconButton data-testid="back-button" onClick={onGoBack}>
        <Icon icon={faArrowLeft} />
      </IconButton>
      <Typography sx={styles.segmentsHeaderText} variant="h6">
        {[segment.name, group?.name].filter((row) => row).join(' / ')}
      </Typography>
      {showEditIcon && (
        <IconButton
          data-testid="segments-groups-edit-button"
          data-gtm-id={group ? 'group-edit-button' : 'segment-edit-button'}
          size="small"
          onClick={onEdit}
        >
          <Icon size="sm" icon={faPencil} />
        </IconButton>
      )}
    </Box>
  );
};

export default SegmentNavBar;
