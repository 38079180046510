import { FormattedMessage } from 'react-intl';

import { DeleteModal } from '@trustyou/ui';

import { commonMessages } from '../../constants/messages';

type Props = {
  isOpen: boolean;
  onCancel: () => void;
  onDiscard: () => void;
};

export const DiscardChangesModal = ({ isOpen, onCancel, onDiscard }: Props) => (
  <DeleteModal
    fullWidth
    maxWidth="xs"
    cancelText={<FormattedMessage {...commonMessages.discardChangesCancel} />}
    content={<FormattedMessage {...commonMessages.discardChangesContent} />}
    dataName="report-discard"
    deleteText={<FormattedMessage {...commonMessages.discard} />}
    onClose={onCancel}
    onDelete={onDiscard}
    title={<FormattedMessage {...commonMessages.discardChangesTitle} />}
    open={isOpen}
  />
);
