import german from './compiled/de-DE.json';
import english from './compiled/en-US.json';
import spanish from './compiled/es-ES.json';
import french from './compiled/fr-FR.json';
import italian from './compiled/it-IT.json';
import japanese from './compiled/ja-JP.json';
import portuguese from './compiled/pt-PT.json';
import russian from './compiled/ru-RU.json';
import chinese from './compiled/zh-CN.json';

export const messages = {
  'de-DE': german,
  'en-US': english,
  'es-ES': spanish,
  'fr-FR': french,
  'it-IT': italian,
  'ja-JP': japanese,
  'pt-PT': portuguese,
  'zh-CN': chinese,
  'ru-RU': russian,
};
