import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { yupResolver } from '@hookform/resolvers/yup';
import { lightBlue } from '@mui/material/colors';
import { faMagnifyingGlass } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { type FiltersForm, parseSearchTerms, useDebounce } from '@trustyou/inbox';
import {
  SEARCH_DEBOUNCE_TIME_MS,
  useAlertStore,
  useInboxStore,
  useLanguageStore,
} from '@trustyou/shared';
import {
  Autocomplete,
  BackdropSpinner,
  Box,
  Chip,
  InputAdornment,
  Stack,
  StyledFontAwesomeIcon,
  TextField,
  Typography,
} from '@trustyou/ui';

import { commonMessages, inboxDetailsStep } from '../../../../constants/messages';
import { useNewReportStore } from '../../../../store';
import { Footer } from '../footer';
import { schema } from '../inbox-filter-step/inbox-filter-step';
import styles from '../styles';

export const InboxDetailsStep = () => {
  const methods = useForm<FiltersForm>({ resolver: yupResolver(schema) });
  const intl = useIntl();
  const navigate = useNavigate();
  const { locale } = useLanguageStore();
  const { accessTokenPayload } = useOidcAccessToken();
  const currentView = useInboxStore((state) => state.currentView);
  const setCurrentView = useInboxStore((state) => state.setCurrentView);
  const stringifiedSearchTerms = parseSearchTerms(currentView.filters?.search_terms ?? []);
  const [value, setValue] = useState<string[]>(currentView.filters?.search_terms ?? []);
  const debouncedStringifiedSearchTerms = useDebounce(value, SEARCH_DEBOUNCE_TIME_MS);

  const { alert } = useAlertStore();
  const { newReport, prevStep, nextStep, updateNewReport } = useNewReportStore();

  //TODO: come back to this code because search might not work correctly

  useEffect(() => {
    setCurrentView({
      ...currentView,
      filters: {
        ...currentView.filters,
        search_terms: value,
      },
    });

    // avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <FormProvider {...methods}>
      <Box sx={styles.stepBody}>
        <Stack sx={{ width: '100%', alignItems: 'center' }}>
          <Box>
            <Stack sx={{ flexDirection: 'row' }}>
              <Typography variant="h6">{intl.formatMessage(commonMessages.keywords)}</Typography>
              <Chip
                label={intl.formatMessage(commonMessages.optional)}
                sx={{ color: 'info.main', backgroundColor: lightBlue[50], marginLeft: 1 }}
              />
            </Stack>
            <Typography variant="body1">
              {intl.formatMessage(inboxDetailsStep.descriptionOne)}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {intl.formatMessage(inboxDetailsStep.descriptionTwo)}
            </Typography>
            <Stack sx={{ marginTop: 5 }}>
              <Autocomplete
                multiple
                id="tags-filled"
                options={[]}
                freeSolo
                value={value}
                onChange={(_event, newValue) => {
                  setValue(newValue);
                }}
                renderTags={(value: readonly string[], getTagProps) =>
                  value.map((option: string, index: number) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return (
                      <Chip variant="filled" label={option} color="info" key={key} {...tagProps} />
                    );
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      minWidth: 400,
                      maxWidth: 576,
                      '& .MuiInputBase-input': {
                        paddingLeft: '8px', // Adjust as needed
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment position="start">
                            <StyledFontAwesomeIcon
                              icon={faMagnifyingGlass}
                              size="lg"
                              sx={{ color: (theme) => theme.palette.action.active }}
                            />
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      ),
                    }}
                    placeholder={intl.formatMessage(commonMessages.enterKeywords)}
                  />
                )}
              />
            </Stack>
          </Box>
        </Stack>
      </Box>
      <Footer onClickBack={prevStep} onClickNext={nextStep} />
      <BackdropSpinner isLoading={false} />
    </FormProvider>
  );
};
