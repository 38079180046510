import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  stepperBody: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    height: 'calc(100% - 56px)',
    paddingX: 5,
    pt: 4,
  },
};

export default styles;
