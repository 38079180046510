import { FormattedMessage } from 'react-intl';

import type { StyleUnit } from '@trustyou/shared';
import { EmptyStatePlaceholder } from '@trustyou/ui';

import personSelectingData from '../../assets/illustrations/person-selecting-data.svg';
import styles from './styles';

export type NoEntitiesReadOnlyProps = {
  sx?: StyleUnit;
};

export const NoEntitiesReadOnly = ({ sx }: NoEntitiesReadOnlyProps) => (
  <EmptyStatePlaceholder
    img={personSelectingData}
    title={<FormattedMessage id="tyDesign.noEntitiesYet" defaultMessage="No entities yet" />}
    description={
      <FormattedMessage
        id="tyDesign.emptyState.nonOnboardedReadOnlyDescription"
        defaultMessage="It seems that no entities were added. Let your admin know to add entities here."
      />
    }
    emptyStateBody={styles.noDataDescription}
    emptyStateHeader={styles.emptyStateHeader}
    emptyStateContainer={{ ...styles.emptyStateContainer, ...styles.noDataContainer, ...sx }}
  />
);
