import type { PropsWithChildren } from 'react';

import { Stack } from '@trustyou/ui';

export function StampPair({ children }: PropsWithChildren) {
  return (
    <Stack direction="row" sx={{ flexWrap: 'wrap', columnGap: 1, alignItems: 'center' }}>
      {children}
    </Stack>
  );
}
