import type { Content } from '../types/content';

export function getObjectThatHasLanguage(item: Content): Record<string, string> {
  if ('title' in item && item.title) return item.title;
  if ('description' in item && item.description) return item.description;
  if ('internal_name' in item && item.internal_name) return item.internal_name;
  return {};
}

export function getLocale(userLanguage: string) {
  return userLanguage.substring(0, 2);
}
