import { create } from 'zustand';

import type { Entity } from '../../types/model/entity';
import { type Organization, OrganizationViewTypes } from '../../types/model/organization';
import type { CombinedUserAndInvitation } from '../../types/model/user';

export type OrganizationView = {
  organization?: Organization;
  entity?: Entity;
  member?: CombinedUserAndInvitation;
  type: OrganizationViewTypes;
  isDeleting?: boolean;
  isDiscarding?: boolean;
};

type OrganizationNavState = {
  view: OrganizationView;
  setView: (view: OrganizationView) => void;
  navToList: () => void;
  navToMemberDetails: (member: CombinedUserAndInvitation) => void;
  navToMemberList: () => void;
  navToDelete: (organization: Organization) => void;
  navToDeleteDiscard: () => void;
};

export const useOrganizationNavStore = create<OrganizationNavState>((set) => ({
  view: {
    type: OrganizationViewTypes.LIST,
    organization: undefined,
    entity: undefined,
    user: undefined,
  },
  setView: (view) => set({ view }),
  navToList: () => set({ view: { type: OrganizationViewTypes.LIST } }),
  navToMemberDetails: (member: CombinedUserAndInvitation) =>
    set(({ view }) => ({ view: { ...view, member, type: OrganizationViewTypes.MEMBER_DETAILS } })),
  navToMemberList: () =>
    set(({ view }) => ({
      view: { ...view, member: undefined, type: OrganizationViewTypes.TEAM_MEMBERS },
    })),
  navToDelete: (organization: Organization) =>
    set(({ view }) => ({ view: { ...view, organization, isDeleting: true } })),
  navToDeleteDiscard: () => set(({ view }) => ({ view: { ...view, isDeleting: false } })),
}));
