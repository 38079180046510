import { Fragment, type ReactNode } from 'react';

import type { SxProps, Theme } from '@mui/material';
import { faArrowLeft } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Card, StyledFontAwesomeIcon as Icon, IconButton, Stack, Typography } from '@trustyou/ui';

export interface ContentPaneProps {
  headers?: ReactNode[];
  children?: ReactNode;
  sx?: SxProps<Theme>;
  bodyStyles?: SxProps<Theme>;
  headerStyles?: SxProps<Theme>;
  navigationHandle?: () => void;
}

export const ContentPane = ({
  headers,
  children,
  sx,
  bodyStyles,
  headerStyles,
  navigationHandle,
}: ContentPaneProps) => (
  <Card
    elevation={0}
    sx={{
      minHeight: '100%',
      border: (theme) => `1px solid ${theme.palette.divider}`,
      ...sx,
    }}
  >
    {headers && (
      <Stack
        direction="row"
        spacing={1}
        sx={{
          padding: 2,
          alignItems: 'center',
          backdropFilter: 'blur(2px)',
          position: 'sticky',
          zIndex: 2,
          top: 0,
          ...headerStyles,
        }}
      >
        {navigationHandle && (
          <IconButton size="medium" data-testid="back-button" onClick={navigationHandle}>
            <Icon icon={faArrowLeft} />
          </IconButton>
        )}
        {headers.map((header, index) => (
          <Fragment key={index}>
            {!!index && <Typography variant="h6">/</Typography>} {header}
          </Fragment>
        ))}
      </Stack>
    )}
    <Stack sx={{ padding: 3, ...(!headers && { height: '100%' }), ...bodyStyles }}>
      {children}
    </Stack>
  </Card>
);
