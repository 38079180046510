import { deDE, enUS, esES, frFR, itIT, jaJP, ptPT, ruRU, zhCN } from '@mui/x-data-grid-pro';

// TODO: check the Localization type
export const useDataGridIntl = (): Record<string, typeof deDE> => ({
  'de-DE': deDE,
  'en-US': enUS,
  'es-ES': esES,
  'fr-FR': frFR,
  'it-IT': itIT,
  'ja-JP': jaJP,
  'pt-PT': ptPT,
  'zh-CN': zhCN,
  'ru-RU': ruRU,
});
