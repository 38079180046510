import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  wrapper: { height: '100%' },
  header: {
    marginBottom: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerText: {
    ml: 1.5,
    marginRight: 2,
  },
  body: {
    marginX: 6,
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 168px)',
  },
  footer: {
    marginX: 6,
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 7,
  },
  dialog: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        maxWidth: '1000px',
      },
    },
  },
  dialogHeader: {
    ml: 6,
  },
};

export default styles;
