import { faLocationDot } from '@trustyou/fortawesome/pro-solid-svg-icons';
import { type Entity, commonFields } from '@trustyou/shared';
import { Divider, StyledFontAwesomeIcon as Icon, Stack, Typography } from '@trustyou/ui';

import { InfoColumn } from './InfoColumn';

import styles from './styles';

type Props = {
  entity: Entity;
};

export const EntityInfoBanner = ({ entity }: Props) => {
  const addressContent = [entity.address, entity.country_name, entity.continent];
  return (
    <Stack data-testid="entity-details-hotel-info" sx={styles.container}>
      <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={3}>
        <InfoColumn header={commonFields.sector} value={entity.sector} />
        <InfoColumn header={commonFields.brand} value={entity.brand} />
      </Stack>
      <Stack data-testid="entity-details-address-row" direction="row" gap={1}>
        <Icon size="lg" icon={faLocationDot} sx={styles.addressRowIcon} />
        <Typography variant="body2">{addressContent.join(', ')}</Typography>
      </Stack>
    </Stack>
  );
};
