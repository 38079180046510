import { FormattedMessage } from 'react-intl';

import { faArrowLeft, faBars } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { AppBar, StyledFontAwesomeIcon as Icon, IconButton, Typography } from '@trustyou/ui';

import styles from './styles';

export type TopBarProps = {
  header?: {
    id: string;
    message: string;
  };
  isRoot?: boolean;
  onClick: () => void;
};

export const MobileTopBar = ({ header, isRoot, onClick }: TopBarProps) => (
  <AppBar sx={styles.appBar}>
    <IconButton sx={styles.iconButton} onClick={onClick}>
      <Icon icon={isRoot ? faBars : faArrowLeft} />
    </IconButton>
    {header && (
      <Typography variant="subtitle1" sx={styles.header}>
        <FormattedMessage id={header.id} defaultMessage={header.message} />
      </Typography>
    )}
  </AppBar>
);
