import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  drawer: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent',
    },
    zIndex: '1300',
  },
  header: {
    paddingX: 3,
    paddingY: 2.5,
    display: 'flex',
    alignItems: 'center',
  },
  close: { marginLeft: 'auto' },
  body: { height: 'calc(100% - 72px)' },
  changelingMode: {
    '& .MuiDrawer-paper': { paddingTop: 8 },
  },
};

export const getDrawerStyles = (width: string, isChangeling?: boolean) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'transparent',
  },
  zIndex: '1300',
  '& .MuiDrawer-paper': {
    width: { xs: '100%', sm: width },
    ...(isChangeling && { paddingTop: 8 }),
  },
});

export default styles;
