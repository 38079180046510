import type { DOMAIN } from '../misc/common';
import type { USER_ROLES } from './shared';

export interface AccessTokenResponse {
  access_token: string;
  claims: {
    organization_id: string;
    subscription_id: string;
    membership_id: string;
    role: USER_ROLES;
    scope_id: string;
    user_id: string;
    email: string;
  };
  apis: DOMAIN[];
}

export interface TokenInfo {
  permissions: string[];
  claims: {
    aud: string;
    email: string;
    exp: number;
    iss: string;
    organization_id: string;
    role: USER_ROLES;
    scope_id: string;
    sub: string;
    user_id: string;
  };
}

export type DomainsTokenInfo = { [key in DOMAIN]?: TokenInfo };

export enum AuthPendingState {
  LOGGING_IN = 'logging_in',
  LOGGING_OUT = 'logging_out',
  IDLE = 'idle',
}

export interface MemberOrganization {
  id: string;
  name: string;
  onboarded: boolean;
}

interface MembershipEntity {
  city: string;
  country_name: string;
  name: string;
}

interface MembershipScope {
  entities: MembershipEntity[];
}

export interface MemberSubscription {
  id: string;
  name: string;
  plan: string;
  is_org?: boolean;
}

export interface Membership {
  id: string;
  role?: USER_ROLES;
  scope?: MembershipScope;
  organization?: MemberOrganization;
  subscription: MemberSubscription;
}

export interface Invitation {
  id: string;
  email: string;
  name?: string;
  role: USER_ROLES;
  organization_id: string;
  subscription_id: string;
  job_title?: string;
  invited_by?: string;
  scope?: {
    all: boolean;
    group_ids: string[];
    entity_ids: string[];
  };
}

export interface OrganizationWithMemberships {
  id: string;
  logo_uri?: string;
  name: string;
  memberships: Membership[];
}

export interface GetMembershipsAndInvitationsResponse {
  invitations: Invitation[];
  organizations: OrganizationWithMemberships[];
}
