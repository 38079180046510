import type { IntlShape, MessageDescriptor } from 'react-intl';

import { numberNames, roleDefinitions } from '../constants';
import { USER_ROLES } from '../types';

export const getUserRoleIntlId = (role: USER_ROLES): string => {
  switch (role) {
    case USER_ROLES.ADMIN:
      return roleDefinitions[USER_ROLES.ADMIN].id;
    case USER_ROLES.EDITOR:
      return roleDefinitions[USER_ROLES.EDITOR].id;
    case USER_ROLES.OWNER:
      return roleDefinitions[USER_ROLES.OWNER].id;
    case USER_ROLES.READ_ONLY:
      return roleDefinitions[USER_ROLES.READ_ONLY].id;
    case USER_ROLES.SERVICE:
      return roleDefinitions[USER_ROLES.SERVICE].id;
    default:
      return roleDefinitions.unknown.id;
  }
};

export const getUserRoleDefaultMessage = (role: USER_ROLES): string => {
  switch (role) {
    case USER_ROLES.ADMIN:
      return roleDefinitions[USER_ROLES.ADMIN].defaultMessage;
    case USER_ROLES.EDITOR:
      return roleDefinitions[USER_ROLES.EDITOR].defaultMessage;
    case USER_ROLES.OWNER:
      return roleDefinitions[USER_ROLES.OWNER].defaultMessage;
    case USER_ROLES.READ_ONLY:
      return roleDefinitions[USER_ROLES.READ_ONLY].defaultMessage;
    case USER_ROLES.SERVICE:
      return roleDefinitions[USER_ROLES.SERVICE].defaultMessage;
    default:
      return roleDefinitions.unknown.defaultMessage;
  }
};

export const getAllEntitiesDefaultMessage = () => 'all entities';

export const getTranslatedTabNames = (
  intl: IntlShape,
  tabNameMessages: MessageDescriptor[]
): string[] => {
  return tabNameMessages.map((message) => intl.formatMessage(message));
};

export const getNumberName = (num: number): MessageDescriptor | undefined => {
  return numberNames[num as unknown as keyof typeof numberNames];
};

export const getTranslatedNumbers = (intl: IntlShape, num: number): string | number => {
  const value = getNumberName(num);
  // if value is not in dictionary return number without any translation
  if (!value) return num;
  return intl.formatMessage(value);
};
