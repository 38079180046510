import { useMutation, useQueryClient } from '@tanstack/react-query';
import { $assApi, ANALYTICS_BASE_PATH } from '@trustyou/shared';
import type { AxiosError } from 'axios';

import { REPORT_LIST, SINGLE_REPORT, UPDATE_REPORT } from '../../constants/query-keys';
import type { ScheduledReport } from '../../types/reports';

async function updateReport(report: ScheduledReport): Promise<ScheduledReport> {
  const { data } = await $assApi.put(`${ANALYTICS_BASE_PATH}/report/modify`, report);
  return data;
}

export const useUpdateReport = (
  onSuccess: (data: ScheduledReport) => void,
  onError?: (error: AxiosError<unknown>) => void
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateReport,
    mutationKey: [UPDATE_REPORT],
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [REPORT_LIST] });
      queryClient.invalidateQueries({ queryKey: [SINGLE_REPORT] });
      onSuccess(data);
    },
    onError,
  });
};
