import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useMembershipStore } from '@trustyou/shared';

import { ForbiddenPage } from '..';
import { getMembershipIdFromUrl } from '../../utils/misc';

export const InvalidMembershipPage = () => {
  const location = useLocation();
  const { organizations, setMembership, getMemberships } = useMembershipStore();
  const navigate = useNavigate();

  useEffect(() => {
    const membershipIdFromUrl = getMembershipIdFromUrl();
    if (!membershipIdFromUrl) return;

    let validMembershipId = '';
    const memberships = getMemberships();

    if (memberships?.length === 1) {
      // If user has only one valid membership id, then use it.
      validMembershipId = memberships[0].id;
    } else {
      // If the router membership id is equal to a organization id and if that organization has one membership then use it.
      organizations?.forEach((organization) => {
        if (organization.id === membershipIdFromUrl) {
          const membershipIds = organization.memberships.map((membership) => membership.id);
          if (membershipIds.length === 1) {
            [validMembershipId] = membershipIds;
          }
        }
      });
    }
    // Otherwise go to hallway and pick a membership. And use target value to open targeted page
    const targetPath = location.pathname.split(`${membershipIdFromUrl}/`)[1] ?? undefined;
    const newPath = validMembershipId
      ? location.pathname.replace(membershipIdFromUrl, validMembershipId)
      : `/${targetPath ? `?target=${targetPath}` : ''}`;
    navigate(newPath);
    setMembership(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ForbiddenPage />;
};
