import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  stepper: {
    '& .MuiStepper-root': {
      width: '40%',
    },
  },
  container: {
    height: 'calc(100% - 56px)',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 5,
    paddingBottom: 2,
  },
  body: {
    paddingX: '25%',
  },
  segmentNameContainer: {
    height: '100%',
    '& form': {
      height: '100%',
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
    },
  },
  segmentNameForm: { marginTop: 4 },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingX: 5,
    marginTop: 'auto',
  },
  footerNextBtn: {
    marginLeft: 'auto',
  },
};

export default styles;
