import { FormattedMessage, type MessageDescriptor } from 'react-intl';

import type { StepLabelProps, StepProps, StepperProps } from '@mui/material';
import { Box, Step, StepLabel, Stepper } from '@trustyou/ui';

import styles from './SetupStepper.styles';

type Step = {
  id: number;
  label: MessageDescriptor;
};

export type StepperCompProps = {
  steps: Record<string, MessageDescriptor>;
} & StepperProps &
  StepProps &
  StepLabelProps;

export const SetupStepper = ({ steps, sx, ...props }: StepperCompProps) => (
  <Box sx={{ ...styles.stepperContainer, ...sx }}>
    <Stepper {...props} sx={styles.stepper}>
      {Object.entries(steps).map(([id, label]) => (
        <Step key={id}>
          <StepLabel>
            <FormattedMessage {...label} />
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  </Box>
);
