import { type ReactNode, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@trustyou/ui';

export const useLeaveDialog = ({
  isInitiallyOpen = false,
  showDialog = false,
}: {
  isInitiallyOpen?: boolean;
  showDialog?: boolean;
} = {}) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);

  const resolvePromise = useRef<((value: boolean) => void) | undefined>(undefined);

  const defaultTitle = intl.formatMessage({
    id: 'inbox.leave.dialog.title',
    defaultMessage: 'Leave and discard draft?',
  });
  const defaultContent = intl.formatMessage({
    id: 'inbox.leave.dialog.content',
    defaultMessage: 'Your changes will be lost if you leave this page.',
  });

  /**
   * This function is used to check if the code can continue or not.
   * If the form is dirty, it will open the dialog and return a promise.
   * The promise will resolve to true when the user clicked on
   * discard(=continue) and false when cancel is clicked.
   * If the form is not dirty, it will return a resolved promise that is set to true.
   *
   * @returns {Promise<boolean>} - A promise that resolves to true if
   * the user can continue, false otherwise.
   */
  const canContinue = (): Promise<boolean> => {
    resolvePromise.current?.(false);

    if (showDialog) {
      setIsOpen(true);
      return new Promise<boolean>((resolve) => {
        resolvePromise.current = resolve;
      });
    }
    return Promise.resolve(true);
  };

  const renderLeaveDialog = ({
    title = defaultTitle,
    content = defaultContent,
  }: {
    title?: ReactNode;
    content?: ReactNode;
  } = {}) => (
    <Dialog
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
        resolvePromise.current?.(true);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ paddingBottom: 1 }}>
        <Typography sx={{ paddingTop: 1 }}>{content}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ color: 'inherit' }}
          onClick={() => {
            setIsOpen(false);
            resolvePromise.current?.(false);
          }}
        >
          <FormattedMessage id="inbox.action.cancel" defaultMessage="Cancel" />
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            setIsOpen(false);
            resolvePromise.current?.(true);
          }}
          autoFocus
        >
          <FormattedMessage id="inbox.action.discard" defaultMessage="Discard" />
        </Button>
      </DialogActions>
    </Dialog>
  );

  return {
    renderLeaveDialog,
    setIsOpen,
    isOpen,
    canContinue,
  };
};
