import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  dialog: {
    '& .MuiDialog-paper': {
      minWidth: '800px',
      minHeight: '400px',
      maxHeight: '700px',
    },
  },
  dialogTitle: {
    padding: 2,
  },
  dialogContent: {
    padding: 0,
  },
  dialogActions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  titleContent: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
  },
  tabs: {
    minHeight: 'unset',
    mt: 1,
    '& .MuiButtonBase-root': {
      minHeight: '42px',
      paddingY: '9px',
    },
  },
  closeButton: {
    marginLeft: 'auto',
    mt: 1,
  },
  loader: { display: 'flex', mt: 5, justifyContent: 'center' },
};

export default styles;
