import type { GridPaginationModel, GridSortModel } from '@mui/x-data-grid-pro';
import { useQueryClient } from '@tanstack/react-query';

import type { GridState } from '../store';

export const useGridParams = (queryKey: string, gridState: GridState) => {
  const { paginationModel, setPaginationModel, setSortModel, setSearchKey } = gridState;
  const queryClient = useQueryClient();

  const onPaginationModelChange = (model: GridPaginationModel) => {
    setPaginationModel(model);
    queryClient.removeQueries({ queryKey: [queryKey] });
  };

  const onSortModelChange = (model: GridSortModel) => {
    if (!model.length) return;
    setSortModel(model);
    onPaginationModelChange({ ...paginationModel, page: 0 });
  };

  const onSearch = (value: string) => {
    setSearchKey(value);
    onPaginationModelChange({ ...paginationModel, page: 0 });
  };

  return { onPaginationModelChange, onSortModelChange, onSearch };
};
