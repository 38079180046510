import { useFormContext } from 'react-hook-form';

import { useLanguageStore } from '@trustyou/shared';
import { FormControl, RadioGroup, Stack } from '@trustyou/ui';

import { PrivateResponseSection } from './private-response-section';
import { ResponseTemplate } from './response-template';
import { ResponseTextField } from './response-text-field';
import { TranslateButton } from './translate-button';
import { UpdateTranslationButton } from './update-translation-button';

import type { LanguageEnum } from '../../../client';
import { TRUSTYOU_SURVEY_DOMAIN_NAME } from '../../../constants';
import { type LanguageSource, useFetchResponseAIGetProfile, useLanguage } from '../../../hooks';
import { useStore } from '../../../store/store';
import {
  type ResponseFormSchema,
  ResponseTabsEnum,
  type ResponseTranslationOption,
} from '../../../types';
import type { Option } from '../../dropdowns/dropdown-chip';
import { ResponseAIActions } from '../../response-ai/response-ai-actions';
import { SubmitRegion } from './submit-region';

export type ResponseFormProps = {
  isTranslating?: boolean;
  translationLanguage?: LanguageSource;
  translationTarget?: ResponseTranslationOption;
  defaultLanguage?: LanguageSource;
  radioValue?: ResponseTranslationOption;
  type: ResponseTabsEnum;
  onChangeLanguage?: (newLanguage: Option, textFieldId: ResponseTranslationOption) => void;
  onChangeSelectedTextbox?: (value: ResponseTranslationOption) => void;
  onChangeTranslationLanguage?: (language: LanguageSource | undefined) => void;
  onUpdateTranslation?: (updateDefault?: boolean, language?: LanguageEnum) => void;
  onGenerateResponse: () => void;
  onSubmit: () => void;
};

export const ResponseForm = ({
  isTranslating,
  translationLanguage,
  translationTarget,
  defaultLanguage,
  radioValue,
  type,
  onChangeLanguage,
  onChangeSelectedTextbox,
  onChangeTranslationLanguage = () => undefined,
  onUpdateTranslation = () => undefined,
  onGenerateResponse,
  onSubmit,
}: ResponseFormProps) => {
  const { locale } = useLanguageStore();
  const { setValue } = useFormContext<ResponseFormSchema>();
  const { getLanguageSourceByCode } = useLanguage();
  const { data: userProfile } = useFetchResponseAIGetProfile();
  const hasGeneratedResponse = useStore.use.hasGeneratedResponse();
  const reviewRoot = useStore.use.reviewRoot();

  const isSurvey = reviewRoot?.review.domain_name === TRUSTYOU_SURVEY_DOMAIN_NAME;

  const handleTranslationOptionSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeSelectedTextbox?.(event.target.value as ResponseTranslationOption);
  };

  const loadTemplate = (value: string) => {
    setValue('response', value, { shouldDirty: true });
  };

  return (
    <>
      <Stack spacing={2}>
        {type === ResponseTabsEnum.RESPONSE_AI && (
          <ResponseAIActions onGenerateResponse={onGenerateResponse} />
        )}
        {type === ResponseTabsEnum.TEMPLATE && <ResponseTemplate onSelect={loadTemplate} />}
        <FormControl fullWidth>
          <RadioGroup defaultValue="original" onChange={handleTranslationOptionSelection}>
            <ResponseTextField
              id="response"
              defaultLanguage={defaultLanguage}
              translationLanguage={translationLanguage}
              translationTarget={translationTarget}
              radioValue={radioValue}
              onChangeSelectedTextbox={onChangeSelectedTextbox}
              onChangeLanguage={onChangeLanguage}
            />
            {/* Conditionally show the translate button and the second textbox. */}
            {hasGeneratedResponse &&
              (translationLanguage ? (
                <>
                  <UpdateTranslationButton
                    disabled={!!translationTarget}
                    isLoading={isTranslating}
                    onClick={() => onUpdateTranslation()}
                  />
                  <ResponseTextField
                    id="translatedResponse"
                    defaultLanguage={defaultLanguage}
                    translationLanguage={translationLanguage}
                    translationTarget={translationTarget}
                    radioValue={radioValue}
                    onChangeSelectedTextbox={onChangeSelectedTextbox}
                    onChangeLanguage={onChangeLanguage}
                  />
                </>
              ) : (
                <TranslateButton
                  disabled={!!translationTarget}
                  onClick={() => {
                    const isAutoOrUndefined = userProfile
                      ? ['AUTO', 'UND'].includes(userProfile?.translation_language ?? '')
                      : true;
                    const lang = isAutoOrUndefined ? locale : userProfile?.translation_language;
                    onChangeTranslationLanguage(getLanguageSourceByCode(lang));
                    onUpdateTranslation(false, lang as LanguageEnum);
                  }}
                />
              ))}
          </RadioGroup>
        </FormControl>
        {isSurvey && <PrivateResponseSection radioValue={radioValue} />}
      </Stack>
      <SubmitRegion onSubmit={onSubmit} />
    </>
  );
};
