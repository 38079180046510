import type { ReactNode } from 'react';

import { Stack, Typography } from '@trustyou/ui';

import { styles } from './styles';

type TextBlockProps = {
  primary: ReactNode;
  secondary?: ReactNode;
};

const TextBlock = ({ primary, secondary }: TextBlockProps) => (
  <Stack sx={styles.textBlock}>
    <Typography variant="body2">{primary}</Typography>
    {secondary && (
      <Typography variant="caption" color="text.hint">
        {secondary}
      </Typography>
    )}
  </Stack>
);

export default TextBlock;
