import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Typography } from '@trustyou/ui';

import styles from './styles';

type InfoItem = {
  id: number;
  messageId: string;
  defaultMessage: string;
  values?: { [key: string]: ReactNode };
};

type InfoListProps = {
  list: InfoItem[];
};

const InfoList = ({ list }: InfoListProps) => (
  <Box component="ol" sx={styles.container}>
    {list.map((step) => (
      <li key={step.id}>
        <Typography component="span" variant="body2">
          <FormattedMessage
            id={step.messageId}
            defaultMessage={step.defaultMessage}
            values={step.values}
          />
        </Typography>
      </li>
    ))}
  </Box>
);

export default InfoList;
