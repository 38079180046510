import { FormattedMessage, useIntl } from 'react-intl';

import { faMemoCircleInfo } from '@trustyou/fortawesome/pro-solid-svg-icons';
import { Box, Button, Link, Stack, StyledFontAwesomeIcon, Typography } from '@trustyou/ui';

import { METADATA_SECTION_HEIGHT } from '../../constants';
import { useStore } from '../../store/store';
import type { ReviewRoot } from '../../types';
import { getDateFromReview } from '../../utils/date';
import { Stamp } from '../stamps';

const Separator = () => <Typography>·</Typography>;

export type MetaDataProps = {
  onPressGuestIcon?: () => void;
};

export const MetaData = ({ onPressGuestIcon }: MetaDataProps) => {
  const intl = useIntl();
  const reviewRoot = useStore.use.reviewRoot() ?? {};
  const entityData = useStore.use.entityData();
  const { review, survey } = reviewRoot as ReviewRoot;
  const publishDate = getDateFromReview(review?.date);
  const processedDate = getDateFromReview(review?.produced_at);

  return (
    <Box sx={{ backgroundColor: (theme) => theme.palette.grey[50] }}>
      <Stack
        spacing={0.5}
        sx={{
          padding: 2,
          alignItems: 'start',
          minHeight: METADATA_SECTION_HEIGHT,
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          backgroundColor: (theme) => theme.palette.grey[50],
        }}
      >
        <Stack
          direction="row"
          sx={{
            columnGap: 3,
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <Typography variant="h6" sx={{ fontSize: 'initial' }}>
            {review?.author}
          </Typography>
          <Button
            onClick={onPressGuestIcon}
            startIcon={<StyledFontAwesomeIcon icon={faMemoCircleInfo} />}
            size="small"
          >
            <FormattedMessage id="inbox.meta.additional-info" defaultMessage="Additional info" />
          </Button>
        </Stack>
        <Stack spacing={0.5}>
          <Stack
            direction="row"
            sx={{
              columnGap: 1,
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <Stamp
              status={
                <FormattedMessage
                  id="inbox.published-with-date"
                  defaultMessage="Published: {publishDate}"
                  values={{ publishDate }}
                />
              }
              tooltip={intl.formatMessage({
                id: 'inbox.published-with-date-tooltip',
                defaultMessage: 'Review published on the source site',
              })}
            />
            <Separator />
            <Stamp
              status={
                <FormattedMessage
                  id="inbox.processed-with-date"
                  defaultMessage="Processed: {processedDate}"
                  values={{ processedDate }}
                />
              }
              tooltip={intl.formatMessage({
                id: 'inbox.processed-with-date-tooltip',
                defaultMessage: 'Review processed by this Inbox',
              })}
            />
          </Stack>
          <Stack
            direction="row"
            sx={{
              columnGap: 1,
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <Typography variant="caption" color="text.secondary">
              <FormattedMessage
                id="inbox.meta.source"
                defaultMessage="Source: {source}"
                values={{
                  source: (
                    <Link
                      href={review?.url ?? ''}
                      target="_blank"
                      rel="noreferrer"
                      underline="hover"
                      variant="caption"
                    >
                      {survey?.survey_name
                        ? `TrustYou Survey: ${survey.survey_name}`
                        : (review?.domain_name ?? review?.domain_id)}
                    </Link>
                  ),
                }}
              />
            </Typography>
            <Separator />
            <Typography variant="caption" color="text.secondary">
              <FormattedMessage
                id="inbox.review.meta.entity"
                defaultMessage="Entity: {entity}"
                values={{
                  entity: (
                    <Typography color="text.primary" variant="caption">
                      {entityData?.name}
                    </Typography>
                  ),
                }}
              />
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
