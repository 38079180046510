import { FormattedMessage } from 'react-intl';

import { faArrowsRotate } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Button, StyledFontAwesomeIcon } from '@trustyou/ui';

type UpdateTranslationButtonProps = {
  disabled?: boolean;
  isLoading?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

export function UpdateTranslationButton({
  disabled,
  isLoading,
  onClick,
}: UpdateTranslationButtonProps) {
  return (
    <Button
      size="small"
      aria-haspopup="true"
      startIcon={
        !isLoading && (
          <StyledFontAwesomeIcon icon={faArrowsRotate} style={{ fontSize: 'inherit' }} />
        )
      }
      sx={{ marginY: 2, width: 'fit-content', alignSelf: 'center' }}
      disabled={disabled}
      onClick={onClick}
      data-gtm-id="inbox_response_ai_update_translation"
    >
      {isLoading ? (
        <FormattedMessage
          id="inbox.response.ai.action.updatingTranslation"
          defaultMessage="Updating..."
        />
      ) : (
        <FormattedMessage
          id="inbox.response.ai.action.updateTranslation"
          defaultMessage="Update translation"
        />
      )}
    </Button>
  );
}
