import { FormattedMessage, type MessageDescriptor } from 'react-intl';

import { Box, Typography } from '@trustyou/ui';

import styles from './styles';

type InfoColumnProps = {
  header: MessageDescriptor;
  value: string;
};

export const InfoColumn = ({ header, value }: InfoColumnProps) => (
  <Box flex="1">
    <Typography data-testid="info-column-value" variant="body2">
      {value}
    </Typography>
    <Typography data-testid="info-column-header" sx={styles.infoCaption} variant="caption">
      <FormattedMessage id={header.id} defaultMessage={header.defaultMessage} />
    </Typography>
  </Box>
);
