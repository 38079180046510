import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  table: {
    '& .MuiDataGrid-cell': {
      whiteSpace: 'normal !important',
    },
  },
  segmentsHeader: {
    marginBottom: 3,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  segmentsHeaderText: {
    ml: 1.5,
    marginRight: 2,
  },
  tableContainer: {
    height: '100%',
  },
};

export default styles;
