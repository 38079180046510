import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  type CustomAttributeDefinition,
  commonFields,
  useAlertStore,
  useCanDeleteCustomAttributeDefinition,
  useDeleteCustomAttributeDefinition,
} from '@trustyou/shared';
import { Button, DeleteModal } from '@trustyou/ui';

import { DeleteProhibitionDialog } from './DeleteProhibitionDialog';

import { manageDrawer } from '../../constants/messages/customAttributes';
import { DashboardDelay } from '../DashboardDelay/DashboardDelay';
import { styles } from './styles';

type Props = {
  onDelete: () => void;
  data: CustomAttributeDefinition;
};

export const DeleteAction = ({ data, onDelete }: Props) => {
  const { alert } = useAlertStore();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [impactedSegments, setImpactedSegments] = useState<string[]>([]);

  const { mutate: validateDeletion } = useCanDeleteCustomAttributeDefinition((response) => {
    if (!response.valid && response.segment_names && response.segment_names.length > 0) {
      setImpactedSegments(response.segment_names);
    } else {
      setIsDeleteModalOpen(true);
    }
  }, alert.genericError);

  const { mutate: deleteCustomAttribute } = useDeleteCustomAttributeDefinition(() => {
    alert.info(
      <FormattedMessage
        {...manageDrawer.deleteSuccess}
        values={{ name: data?.name, delay: <DashboardDelay /> }}
      />
    );
    onDelete();
  }, alert.genericError);

  return (
    <>
      <Button onClick={() => validateDeletion(data.id)} color="error" sx={styles.deleteButton}>
        <FormattedMessage {...manageDrawer.deleteButton} />
      </Button>
      <DeleteModal
        fullWidth
        maxWidth="xs"
        cancelText={<FormattedMessage {...commonFields.cancel} />}
        content={<FormattedMessage {...manageDrawer.deleteContent} />}
        dataName="custom-attribute-delete"
        deleteText={<FormattedMessage {...manageDrawer.confirmedDeleteButton} />}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={() => deleteCustomAttribute(data.id)}
        title={<FormattedMessage {...manageDrawer.deleteTitle} values={{ name: data?.name }} />}
        open={isDeleteModalOpen}
      />
      <DeleteProhibitionDialog
        segments={impactedSegments}
        onClose={() => setImpactedSegments([])}
      />
    </>
  );
};
