import { FormattedMessage } from 'react-intl';

import { ExplanationModal } from '../ExplanationModal/ExplanationModal';

export interface MaxCompetitorsProps {
  open: boolean;
  onClose: () => void;
}

export const MaxCompetitors = ({ open, onClose }: MaxCompetitorsProps) => (
  <ExplanationModal
    open={open}
    onClose={onClose}
    dataName="max-competitors-info"
    title={
      <FormattedMessage
        id="tyDesign.maxCompetitorsInfoTitle"
        defaultMessage="How can I change the selected competitors?"
      />
    }
    content={
      <FormattedMessage
        id="tyDesign.maxCompetitorsInfoContent"
        defaultMessage="You have selected the maximum number of competitors for this entity. To add further entities upgrade your plan or delete existing competitors."
      />
    }
    buttonText={<FormattedMessage id="tyDesign.maxCompetitorsInfoButton" defaultMessage="Got it" />}
    points={[]}
  />
);
