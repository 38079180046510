import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  wrapper: {
    display: 'flex',
    gap: 5,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 0.5,
    maxWidth: '600px',
    textAlign: 'center',
  },
};

export default styles;
