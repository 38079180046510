import type { EntitiesResponse } from '../../../../types/model/entity';
import type { PaginationRequest } from '../../../../types/model/shared';
import type {
  EditSubscriptionPayload,
  EntitySubscriptionsResponse,
  Subscription,
} from '../../../../types/model/subscription';
import type { CombinedUserResponse } from '../../../../types/model/user';
import { $assApi } from '../../../api';
import { SUBSCRIPTIONS_BASE_PATH } from '../../path';

export async function fetchCurrentSubscription(): Promise<Subscription> {
  const { data } = await $assApi.get(`${SUBSCRIPTIONS_BASE_PATH}/get_current_subscription`);
  return data.data;
}

export async function fetchSubscription(subscriptionId?: string): Promise<Subscription> {
  const { data } = await $assApi.get(`${SUBSCRIPTIONS_BASE_PATH}/get_subscription`, {
    params: {
      subscription_id: subscriptionId,
    },
  });
  return data.data;
}

export async function fetchEntitySubscriptions({
  limit,
  offset,
}: PaginationRequest): Promise<EntitySubscriptionsResponse> {
  const { data } = await $assApi.get(`${SUBSCRIPTIONS_BASE_PATH}/get_entity_subscriptions`, {
    params: {
      limit,
      offset,
    },
  });
  return data;
}

export async function fetchSubscriptionMembers(
  subscriptionId: string,
  { limit, offset }: PaginationRequest,
  sortKey: string,
  searchKey?: string
): Promise<CombinedUserResponse> {
  const { data } = await $assApi.get(`${SUBSCRIPTIONS_BASE_PATH}/get_subscription_members`, {
    params: {
      subscription_id: subscriptionId,
      limit,
      offset,
      order: sortKey,
      ...(searchKey && { q: searchKey }),
    },
  });
  return data;
}

export async function fetchSubscriptionEntities(
  subscriptionId: string,
  { limit, offset }: PaginationRequest,
  sortKey: string,
  searchKey?: string
): Promise<EntitiesResponse> {
  const { data } = await $assApi.get(`${SUBSCRIPTIONS_BASE_PATH}/get_subscription_entities`, {
    params: {
      subscription_id: subscriptionId,
      limit,
      offset,
      order: sortKey,
      ...(searchKey && { q: searchKey }),
    },
  });
  return data;
}

export async function fetchAssignableEntities(
  subscriptionId: string,
  { limit, offset }: PaginationRequest,
  sortKey: string,
  searchKey?: string
): Promise<EntitiesResponse> {
  const { data } = await $assApi.get(`${SUBSCRIPTIONS_BASE_PATH}/get_assignable_entities`, {
    params: {
      subscription_id: subscriptionId,
      limit,
      offset,
      order: sortKey,
      ...(searchKey && { q: searchKey }),
    },
  });
  return data;
}

export async function fetchAssignableEntitiesCount(subscriptionId?: string): Promise<number> {
  const { data } = await $assApi.get(`${SUBSCRIPTIONS_BASE_PATH}/get_assignable_entities`, {
    params: {
      subscription_id: subscriptionId,
      limit: 1,
      offset: 0,
    },
  });
  return data.pagination.total;
}

export async function editSubscription(payload: EditSubscriptionPayload): Promise<Subscription> {
  const { data } = await $assApi.post(`${SUBSCRIPTIONS_BASE_PATH}/edit_subscription`, payload);
  return data.data;
}
