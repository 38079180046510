import type { IStyles } from '@trustyou/shared';

import {
  EDITOR_BOTTOM_BAR_HEIGHT_PX,
  EDITOR_CONTENT_WIDTH_PX,
  EDITOR_SIDE_NAV_WIDTH_PX,
  EDITOR_TOP_BAR_HEIGHT_PX,
} from '../../constants/ui';

const styles: IStyles = {
  editorMain: {
    width: '100vw',
    minWidth: '900px',
    height: '100vh',
    display: 'grid',
    gridTemplateColumns: `${EDITOR_SIDE_NAV_WIDTH_PX} ${EDITOR_CONTENT_WIDTH_PX} auto`,
    gridTemplateRows: `${EDITOR_TOP_BAR_HEIGHT_PX} auto ${EDITOR_BOTTOM_BAR_HEIGHT_PX}`,
  },
  editorContentExtraStyles: {
    gridColumn: '2 / 4',
    gridRow: '2',
  },
  editorPreviewExtraStyles: {
    display: 'none',
  },
  editorFooterExtraStyles: {
    gridColumn: '2 / 4',
    gridRow: '3',
  },
  disableCellOutline: {
    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within':
      {
        outline: 'none',
      },
  },
};

export default styles;
