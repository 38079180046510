export const allowedProductCategories: Record<string, string> = {
  '': 'Init',
  Keyclock: 'Login',
  Home: 'Home',
  Analytics: 'Dashboard',
  Inbox: 'Inbox',
  'Profile-submenu-inbox': 'Profile: Inbox',
  'Specific-inbox-review': 'Inbox',
  'Survey-manager': 'Survey',
  'Question-library': 'Survey: question-library',
  Entities: 'Entities',
  Users: 'Members',
  Application: 'Settings',
  'Review-providers': 'Settings: review providers',
  'Specific-review-provider': 'Settings: review providers',
  User: 'Profile',
  General: 'Profile: General',
  Notifications: 'Profile: notifications',
  Welcome: 'Onboarding',
  'Load-data': 'Entities',
} as const;

export const gtmAllowedProductCategory = (url: string, productCategory: string) => {
  if (url.includes('settings/user/inbox')) return allowedProductCategories['Profile-submenu-inbox'];
  if (url.includes('keyclock') && !allowedProductCategories[productCategory]) {
    return allowedProductCategories['Keyclock'];
  } else if (url.includes('review-providers/') && !allowedProductCategories[productCategory]) {
    return allowedProductCategories['Specific-review-provider'];
  } else if (url.includes('inbox/') && !allowedProductCategories[productCategory]) {
    return allowedProductCategories['Specific-inbox-review'];
  }
  return allowedProductCategories[productCategory];
};
