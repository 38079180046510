import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useInvitationStore } from '../../store';

const useEmailInvite = () => {
  const { setIsInvited } = useInvitationStore();
  const [searchParams] = useSearchParams();

  const hasInvitedParam = searchParams.has('invited');

  useEffect(() => {
    if (!hasInvitedParam) return;
    // if a user is invited, we need to put a flag to the store and reload the app
    setIsInvited(true);
    window.location.replace(`${window.location.href.split('?')[0]}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { hasInvitedParam };
};

export default useEmailInvite;
