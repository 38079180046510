import React from 'react';
import { useIntl } from 'react-intl';

import type { SelectionMode } from '@trustyou/shared';
import { Box, ListSeparator, ListState, Stack } from '@trustyou/ui';

import { SelectedList } from './SelectedList';
import { SelectionList } from './SelectionList';

export type SelectorItem = { id: string };

interface SelectorProps<T> {
  items: (T & SelectorItem)[];
  selectedItems?: (T & SelectorItem)[];
  isListDisabled?: boolean;
  areItemsDisabled?: boolean;
  selectedHeaderTitle?: React.ReactNode;
  isLoading?: boolean;
  hideSelectResults?: boolean;
  defaultValue?: string;
  searchPlaceholder?: string;
  mode?: SelectionMode;
  onSelectItems: (items: (T & SelectorItem)[]) => void;
  renderRowContent: (item: T & SelectorItem) => React.ReactNode;
  onFetchNextPage?: () => void;
  onSearch: (value: string) => void;
  height?: string;
}

export const Selector = <T,>({
  selectedItems = [],
  items,
  isListDisabled = false,
  areItemsDisabled = false,
  selectedHeaderTitle,
  isLoading,
  hideSelectResults = false,
  defaultValue,
  searchPlaceholder,
  mode = 'multiple',
  onSelectItems,
  renderRowContent,
  onFetchNextPage,
  onSearch,
  height = '380px',
}: SelectorProps<T>) => {
  const intl = useIntl();

  const onSelectMultiple = (toggleItems: (T & SelectorItem)[]) => {
    // when all provided entries are already selected, remove them instead
    const selectionIds = selectedItems.map(({ id }) => id);
    const newIdsArray = toggleItems.map(({ id }) => id);
    if (newIdsArray.every((id) => selectionIds.includes(id))) {
      onSelectItems(selectedItems.filter(({ id }) => !newIdsArray.includes(id)));
    } else {
      // otherwise add them to the selection (without duplicates)
      onSelectItems([
        ...selectedItems,
        ...toggleItems.filter(({ id }) => !selectionIds.includes(id)),
      ]);
    }
  };

  /**
   * Toggle an item.
   * When multiple items are selected, they are added to the selection.
   * If all items were previously selected, they will be deselected instead.
   * @param categories
   * @returns
   */
  const onToggle = (toggleItems: (T & SelectorItem)[]) => {
    // "select all search results" clicked
    if (toggleItems.length > 1) return onSelectMultiple(toggleItems);

    // otherwise toggle the clicked row
    const element = toggleItems[0];
    if (selectedItems.map((item) => item.id).includes(element.id)) {
      onSelectItems(selectedItems.filter((row) => row.id !== element.id));
    } else {
      if (mode === 'multiple') {
        onSelectItems([...selectedItems, element]);
      } else {
        onSelectItems([element]);
      }
    }
  };

  const reset = () => {
    onSelectItems([]);
  };

  return (
    <Box>
      <Stack direction="row" gap={2} height={height}>
        <Stack direction="row" width="100%">
          <SelectionList
            defaultValue={defaultValue}
            searchPlaceholder={searchPlaceholder}
            selected={selectedItems.map((item) => item.id)}
            items={items}
            mode={mode}
            onSelect={onToggle}
            onSearch={onSearch}
            fetchNextPage={onFetchNextPage}
            listState={
              <ListState
                isLoading={isLoading}
                emptyMessage={intl.formatMessage({
                  id: 'tyDesign.selector.noSearchResults',
                  defaultMessage: 'No entries found. Make sure the name is spelled correctly.',
                })}
              />
            }
            renderRowContent={renderRowContent}
            disabled={isListDisabled}
            itemsDisabled={areItemsDisabled}
            hideSelectResults={hideSelectResults}
          />
        </Stack>
        <Stack direction="row" visibility={selectedItems.length ? 'unset' : 'hidden'}>
          <ListSeparator />
        </Stack>
        <Stack direction="row" width="100%" visibility={selectedItems.length ? 'unset' : 'hidden'}>
          <SelectedList
            items={selectedItems}
            onRemove={onToggle}
            onReset={mode === 'multiple' ? reset : undefined}
            header={selectedHeaderTitle}
            renderRowContent={renderRowContent}
          />
        </Stack>
      </Stack>
    </Box>
  );
};
