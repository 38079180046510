import type { IntlShape } from 'react-intl';

import { debounce } from '@mui/material';
import * as yup from 'yup';

import { validationErrors } from '../constants';

const VALIDATE_DEBOUNCE_TIME = 500;

type CheckValidation = (name: string) => Promise<boolean>;

export const getAccessGroupValidationSchema = (
  intl: IntlShape,
  checkValidation: CheckValidation,
  defaultValue?: string
) => {
  const duplicateError = intl.formatMessage(validationErrors.existingAccessGroup);
  const requiredError = intl.formatMessage(validationErrors.requiredAccessGroup);

  const debounceDuplicateCheck = debounce((resolve: (val: boolean) => void, value) => {
    if (defaultValue && defaultValue === value) {
      return resolve(true);
    }
    return checkValidation(value).then(resolve);
  }, VALIDATE_DEBOUNCE_TIME);

  return yup.object().shape({
    name: yup
      .string()
      .required(requiredError)
      .test(
        'duplicateCheck',
        duplicateError,
        (value) =>
          new Promise((resolve) => {
            debounceDuplicateCheck(resolve, value);
          })
      ),
  });
};
