import { useIntl } from 'react-intl';

import { useShortLocale } from '../../../hooks/use-short-locale';
import type { Content, TextRow } from '../../../types/content';
import { translatedContentTypes } from '../translations';

export function useRows(contents: Content[] = []) {
  const intl = useIntl();
  const shortLocale = useShortLocale();

  const rows: TextRow[] = contents.map((content) => {
    const displayed_internal_name =
      content.internal_name?.[shortLocale] ??
      `(no_internal_name) ${
        content.title?.[shortLocale] ??
        content.description?.[shortLocale] ??
        content.title?.en ??
        content.description?.en
      }`;

    const displayed_message_type = intl.formatMessage(
      translatedContentTypes[content.type ?? 'text']
    );

    // TODO: when a category is 'custom'?
    const category = 'predefined';

    const languages = [];
    if (content.title) languages.push(...Object.keys(content.title));
    if (content.description) languages.push(...Object.keys(content.description));
    const languageSet = new Set(languages);
    const translations = languageSet.size;

    return {
      ...content,
      displayed_internal_name,
      displayed_message_type,
      category,
      translations,
    };
  });

  return rows;
}
