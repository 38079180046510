import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import type { SxProps, Theme } from '@mui/material';
import { Box, StyledFontAwesomeIcon as Icon } from '@trustyou/ui';

import styles from './SpotIcon.styles';

import { stringToBrandedColor } from '../../utils/stringBrandedAvatar';

export interface SpotIconProps {
  icon: IconDefinition;
  altText?: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  brandedColorString?: string;
}

export const SpotIcon = ({
  icon,
  disabled,
  altText = 'hidden',
  sx,
  brandedColorString,
}: SpotIconProps) => (
  <Box
    sx={{
      ...styles.box,
      ...(brandedColorString && {
        backgroundColor: stringToBrandedColor(brandedColorString),
      }),
      ...sx,
      ...(disabled && styles.disabled),
    }}
  >
    <Icon size="lg" icon={icon} title={altText} />
  </Box>
);
