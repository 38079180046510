import { FormattedMessage } from 'react-intl';

import { EmptyStatePlaceholder } from '@trustyou/ui';

// eslint-disable-next-line @nx/enforce-module-boundaries
import personFillingAForm from '../../../../../../ui/src/lib/assets/illustrations/person-filling-a-form.svg';

export function ViewsEmptyState() {
  return (
    <EmptyStatePlaceholder
      img={personFillingAForm}
      title={<FormattedMessage id="inbox.views.empty-state.title" defaultMessage="No Views yet" />}
      description={
        <FormattedMessage
          id="inbox.views.empty-state.description"
          defaultMessage="Views are saved filter configurations. You can create them in the “Filters” tab above."
        />
      }
      emptyStateContainer={{
        marginTop: 5,
        textAlign: 'center',
        '& > img': { width: 200, height: 200 },
      }}
      emptyStateHeader={{ marginTop: 5 }}
    />
  );
}
