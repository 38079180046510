import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, defineMessage } from 'react-intl';

import { faArrowUpFromLine } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { commonFields, useAlertStore, useOrganizationLogo } from '@trustyou/shared';
import { Box, Button, StyledFontAwesomeIcon as Icon, Stack, Typography } from '@trustyou/ui';

import { styles } from './styles';

const UPLOAD_RULES_LABEL = defineMessage({
  id: 'settings.general.organization-profile.logo-upload-info',
  defaultMessage: 'SVG, PNG, JPG, GIF or WEBP supported, maximum 1MB',
});

interface LogoUploadProps {
  updateAllowed: boolean;
  onUpload: (logo: string) => void;
}

export const LogoUpload = ({ updateAllowed, onUpload }: LogoUploadProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedLogo, setSelectedLogo] = useState<string>();
  const { alert } = useAlertStore();
  const { data } = useOrganizationLogo();

  useEffect(() => {
    setSelectedLogo(data?.logo_uri);
  }, [data?.logo_uri]);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const validTypes = ['image/svg+xml', 'image/png', 'image/jpeg', 'image/gif', 'image/webp'];
      const maxSize = 1 * 1024 * 1024; // 1MB in bytes

      if (file.size > maxSize || !validTypes.includes(file.type)) {
        alert.error(<FormattedMessage {...UPLOAD_RULES_LABEL} />);
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        onUploadLogo(e.target?.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const onUploadLogo = (logo?: string) => {
    setSelectedLogo(logo);
    onUpload(logo || '');
  };

  if (!selectedLogo && !updateAllowed) return null;

  return (
    <Stack mb={5}>
      <Typography variant="subtitle1" mb={2}>
        <FormattedMessage
          id="settings.general.organization-profile.your-logo"
          defaultMessage="Your logo"
        />
      </Typography>
      {!selectedLogo && updateAllowed && (
        <Stack gap={1}>
          <input
            type="file"
            accept=".svg,.png,.jpg,.jpeg,.gif,.webp"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <Button
            sx={styles.logoUploadButtons}
            variant="contained"
            data-testid="organization-profile-logo-select-file"
            startIcon={<Icon icon={faArrowUpFromLine} />}
            onClick={handleButtonClick}
          >
            <FormattedMessage {...commonFields.selectFile} />
          </Button>
          <Typography variant="caption" color="text.secondary">
            <FormattedMessage {...UPLOAD_RULES_LABEL} />
          </Typography>
        </Stack>
      )}
      {selectedLogo && (
        <Stack gap={1}>
          <Box component="img" src={selectedLogo} alt="Selected" sx={styles.logoView} />
          {updateAllowed && (
            <Button
              sx={styles.logoUploadButtons}
              data-testid="organization-profile-logo-remove"
              onClick={() => onUploadLogo()}
            >
              <FormattedMessage {...commonFields.remove} />
            </Button>
          )}
        </Stack>
      )}
    </Stack>
  );
};
