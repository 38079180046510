export const REPORT_LIST = 'report_list';
export const WIDGET_LIST = 'widget_list';
export const USERS_BY_ORGANIZATION = 'users_by_organization';
export const DASHBOARD_ID_LIST = 'dashboard_id_list';
export const SCHEDULE_REPORT = 'schedule_report';
export const UPDATE_REPORT = 'update_report';
export const DELETE_REPORT = 'delete_report';
export const SINGLE_REPORT = 'single_report';
export const REPORTS_HISTORY = 'reports_history';
export const REPORT_LAST_STATUS = 'report_last_status';
export const HAS_REPORTS_CREATED = 'has_reports_created';
