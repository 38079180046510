import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  getDashboardExcludedFilters,
  useAlertStore,
  useDashboard,
  useLanguageStore,
  useRouteUtils,
} from '@trustyou/shared';
import { BackdropSpinner, Box, FormControl, Stack, TextField, Typography } from '@trustyou/ui';
import { DateTime } from 'luxon';

import { FrequencyTypeSelector } from './frequency-type-selector';

import { FormControlLabel, FrequencySelector, RecipientSelector } from '../../../../components';
import { commonMessages, newReportWizardMessages } from '../../../../constants/messages';
import { reportValidationSchema } from '../../../../constants/report-validation-schema';
import { useScheduleReport } from '../../../../service/hooks';
import { useNewReportStore } from '../../../../store';
import type { ReportValidationSchema } from '../../../../types/reports';
import { Footer } from '../footer';
import styles from '../styles';
import { SuccessMessage } from './success-message';

export const RecipientsStep = () => {
  const intl = useIntl();
  const { navigateWithBase } = useRouteUtils();
  const { locale } = useLanguageStore();
  const { accessTokenPayload } = useOidcAccessToken();
  const currentUser = {
    id: accessTokenPayload.sub,
    name: accessTokenPayload.name,
    email: accessTokenPayload.email,
  };

  const { alert } = useAlertStore();
  const { newReport, prevStep, updateNewReport } = useNewReportStore();
  const { data: dashboard, isFetching: isFetchingDashboard } = useDashboard(newReport?.contentId);

  const { mutate: scheduledReport, isPending: isLoading } = useScheduleReport(
    () => {
      alert.success(<SuccessMessage isOneTime={newReport?.isOneTime} />);
      navigateWithBase(newReport?.isOneTime ? 'reports/history' : 'reports/list');
    },
    () => {
      alert.genericError();
    }
  );

  const { register, formState, control } = useForm<ReportValidationSchema>({
    mode: 'onChange',
    resolver: yupResolver(reportValidationSchema),
    defaultValues: Object.assign(reportValidationSchema.getDefault(), {
      name: newReport?.name as string,
      recipients: newReport?.recipients || [currentUser],
      frequency: newReport?.frequency,
      timeOfDay: newReport?.timeOfDay,
      dayOfMonth: newReport?.dayOfMonth,
      dayOfWeek: newReport?.dayOfWeek,
    }),
  });

  const formValues = useWatch({
    control,
  });

  useEffect(() => {
    updateNewReport(formValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  const onSubmit = () => {
    if (newReport) {
      const period = newReport.isOneTime ? 'one-time' : (newReport.frequency as string);
      const timeOfDay = DateTime.fromISO(newReport.timeOfDay as string);
      scheduledReport({
        dashboard_id: newReport.contentId,
        disable: false,
        user_ids: (newReport.recipients || []).map((recipient) => recipient.id).join(','),
        report_name: newReport.name as string,
        hour: timeOfDay.hour,
        period,
        day_of_week: period === 'weekly' ? newReport.dayOfWeek || 0 : null,
        day: period === 'monthly' ? newReport.dayOfMonth || 1 : null,
        timezone: timeOfDay.zone.name,
        file_format: 'excel',
        dashboard_filters: JSON.stringify(dashboard.filters),
        excluded_filters: getDashboardExcludedFilters(dashboard.filters || []),
        language: locale,
        widget_id: (newReport.widgets || []).join(','),
      });
    }
  };

  return (
    <>
      <Box sx={styles.stepBody}>
        <Stack sx={styles.recipientsContainer} gap={4}>
          <Stack>
            <Typography marginBottom={2} variant="subtitle1">
              {intl.formatMessage(newReportWizardMessages.recipientsHeader)}
            </Typography>
            <RecipientSelector control={control} sx={{ width: '440px' }} />
          </Stack>
          <FormControl sx={{ width: '440px' }}>
            <Typography marginBottom={2} variant="subtitle1">
              {intl.formatMessage(commonMessages.nameYourReport)}
            </Typography>
            <FormControlLabel required>{intl.formatMessage(commonMessages.name)}</FormControlLabel>
            <TextField
              size="small"
              placeholder={intl.formatMessage(commonMessages.reportNamePlaceHolder)}
              id="report-name"
              {...register('name')}
              error={!!formState.errors.name}
              helperText={
                !!formState.errors.name && intl.formatMessage(commonMessages.reportNameError)
              }
              type="text"
              variant="outlined"
            />
          </FormControl>
          <Stack gap={2}>
            <FrequencyTypeSelector />
            {!newReport?.isOneTime && <FrequencySelector direction="row" control={control} />}
          </Stack>
        </Stack>
      </Box>
      <Footer
        disableNext={!formState.isValid || isFetchingDashboard}
        onClickBack={prevStep}
        onClickNext={onSubmit}
      />
      <BackdropSpinner isLoading={isLoading} />
    </>
  );
};
