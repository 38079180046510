import type { CSSSelectorObjectOrCssVariables, Theme } from '@mui/system';

export type StyleUnit = CSSSelectorObjectOrCssVariables<Theme>;

export interface IStyles {
  [key: string]: StyleUnit;
}

export type PaginationOptions = {
  page: number;
  rowsPerPage: number;
  total: number;
};

export enum DOMAIN {
  ORG_CENTER = 'org_center',
  ANALYTICS = 'analytics',
  INBOX = 'inbox',
  SURVEY = 'survey',
}

export type Permissions = { [key in DOMAIN]?: string[] };

export type RoutePermissions = {
  [key: string]: string[];
};

export interface RedirectOptions {
  silent?: boolean;
}

export interface IFrameMessagePayload {
  [key: string]: string;
}

export interface IFrameMessage {
  type: string;
  path?: string;
  payload: IFrameMessagePayload;
}

export interface FormValidationError {
  code?: string;
  field?: string;
  message?: string;
}

export interface FormError {
  code?: string;
  message?: string;
  validation_errors?: FormValidationError[];
}

export interface FormErrorResponseData {
  error: FormError;
}
