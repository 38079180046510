import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { useValidCustomAttributeName } from '@trustyou/shared';
import { FormControl, FormFieldLabel, TextField } from '@trustyou/ui';
import { debounce } from 'lodash';

import { common, manageDrawer } from '../../constants/messages/customAttributes';

const VALIDITY_CHECK_DEBOUNCE_TIME = 500;

export const NameController = () => {
  const { control } = useFormContext();
  const intl = useIntl();

  const { mutateAsync: checkValidName } = useValidCustomAttributeName();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceCheck = useCallback(
    debounce(async (value: string) => {
      if (control._defaultValues.name === value) return;
      const response = await checkValidName(value);
      if (!response || response.valid) return;
      let message = '';
      if (response.error === 'matches_global_attribute_name') {
        message = intl.formatMessage(manageDrawer.nameReserved);
      } else if (response.error === 'matches_segment_name') {
        message = intl.formatMessage(manageDrawer.nameExistAsSegment);
      }
      control.setError('name', {
        type: 'custom',
        message: message || intl.formatMessage(manageDrawer.nameExist),
      });
    }, VALIDITY_CHECK_DEBOUNCE_TIME),
    []
  );

  return (
    <FormControl>
      <FormFieldLabel required>{intl.formatMessage(common.name)}</FormFieldLabel>
      <Controller
        name="name"
        control={control}
        rules={{
          required: intl.formatMessage(manageDrawer.nameRequired),
          onChange: async (event) => {
            if (event) debounceCheck(event.target.value);
          },
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            sx={{ '& .MuiInputBase-root': { maxWidth: '300px' } }}
            size="small"
            placeholder={intl.formatMessage(manageDrawer.namePlaceholder)}
            id="custom-attribute-name"
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            type="text"
            variant="outlined"
          />
        )}
      />
    </FormControl>
  );
};
