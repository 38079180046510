import { FormattedMessage } from 'react-intl';

import { Box, Typography } from '@trustyou/ui';

export type ProgressLineProps = {
  value: number;
};

const MAX_LIMIT = 10;

export const ProgressLine = ({ value }: ProgressLineProps) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Box
      sx={{
        width: 110,
        height: 4,
        background: 'rgba(21, 101, 192, 0.5)',
      }}
    >
      <Box
        sx={{
          height: '100%',
          width: `${value * 10}%`,
          background: 'rgba(21, 101, 192, 1)',
        }}
      />
    </Box>
    <Box sx={{ ml: 1 }}>
      <Typography variant="caption" color="text.secondary">
        <FormattedMessage
          id="inbox.settings.progressLine.label"
          defaultMessage="{value}/{max} Guides used"
          values={{ value, max: MAX_LIMIT }}
        />
      </Typography>
    </Box>
  </Box>
);
