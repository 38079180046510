/* eslint-disable @nx/enforce-module-boundaries */
import * as avatars from '../design-tokens/avatars';

const brandColors = Object.values(avatars).filter((color) => color !== avatars.avatarsDefault);

export const directColouredAvatar = (brandColor: string) => {
  const words = brandColor.split(/(?=[A-Z])/).slice(1);

  // Get the first letter of each word
  const initials = words.map((word) => word[0]).join('');

  return {
    sx: {
      backgroundColor: avatars[brandColor as keyof typeof avatars],
    },
    children: initials,
  };
};

export const stringToBrandedColor = (string: string) => {
  let hash = 0;

  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Use the absolute value of the hash modulo the length of the color array to get an index
  const index = Math.abs(hash) % brandColors.length;

  // Return the color at that index
  return brandColors[index];
};

export const stringBrandedAvatar = (name: string) => {
  const normalized = name.trim().replace(/\s+/g, ' ');

  if (normalized.split(' ').length === 1) {
    return {
      sx: {
        backgroundColor: stringToBrandedColor(name),
      },
      children: `${normalized.split(' ')[0][0]}`,
    };
  }
  return {
    sx: {
      backgroundColor: stringToBrandedColor(normalized),
    },
    children: `${normalized.split(' ')[0][0]}${normalized.split(' ')[1][0]}`,
  };
};
