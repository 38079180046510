import { grey } from '@mui/material/colors';
import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  title: {
    paddingX: 5,
    height: '64px',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${grey[100]}`,
  },
  changelingMode: { mt: 8 },
  container: { padding: 0 },
  closeButton: { color: 'text.secondary' },
  closeIcon: { marginRight: 1 },
  noHeaderBorder: { borderBottom: 'none' },
  footer: {
    paddingY: 2,
    paddingX: 3,
  },
};
export default styles;
