import { useState } from 'react';
import { useController } from 'react-hook-form';

import { faStar as outlinedStar } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { faStar as filledStar } from '@trustyou/fortawesome/pro-solid-svg-icons';
import { Rating, Stack, StyledFontAwesomeIcon, Typography } from '@trustyou/ui';

import type { ScaleQuestionProps } from '../question-types';
import { getLabels } from '../utils';

type StarRatingProps = ScaleQuestionProps;

export function StarRating({ name, control, options }: StarRatingProps) {
  const { field } = useController({ name, control });
  const [value, setValue] = useState<number | null>(field.value ?? null);
  const [hover, setHover] = useState(-1);

  const labels = getLabels(options);
  const firstLabel = Object.values(labels).at(0);
  const lastLabel = Object.values(labels).at(-1);

  const getHoverOrSelectedLabel = () => {
    if (hover !== -1) return labels[hover];
    if (value !== null) return labels[value];
    return null;
  };

  return (
    <Stack spacing={1} sx={{ alignItems: 'center' }}>
      <Rating
        max={options.length}
        value={value}
        onChange={(event, newValue) => {
          field.onChange(newValue);
          setValue(newValue);
        }}
        onChangeActive={(event, newHover) => setHover(newHover)}
        getLabelText={(value) => `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`}
        emptyIcon={<StyledFontAwesomeIcon icon={outlinedStar} />}
        icon={<StyledFontAwesomeIcon icon={filledStar} />}
        size="large"
      />
      {hover !== -1 || value !== null ? (
        <Typography variant="body2" color="text.primary" fontWeight={500} textAlign="center">
          {getHoverOrSelectedLabel()}
        </Typography>
      ) : (
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Typography variant="body2" color="text.secondary">
            {firstLabel}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {lastLabel}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}
