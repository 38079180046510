import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  type AccessGroupCreateUpdatePayload,
  getAccessGroupValidationSchema,
  useAccessGroupDetails,
  useAlertStore,
  useCreateUpdateAccessGroup,
  useRouteUtils,
  useScopeStore,
  useValidAccessGroup,
} from '@trustyou/shared';
import { BackdropSpinner, Box, ContentPane, DiscardChangesModal, Typography } from '@trustyou/ui';

import AccessGroupName, { type AccessGroupNameForm } from './AccessGroupName';
import { AccessOptionSelection } from './AccessOptionSelection';
import { EntitySelection } from './EntitySelection';
import { Footer } from './Footer';
import { GroupSelection } from './GroupSelection';

import styles from './styles';

type CreateUpdateAccessGroupProps = {
  isModal?: boolean;
  onLeave?: () => void;
};

export const CreateUpdateAccessGroup = ({ onLeave }: CreateUpdateAccessGroupProps) => {
  const intl = useIntl();
  const isModal = !!onLeave;
  const { goUp } = useRouteUtils();
  const { accessGroupId } = useParams();
  const { selectedEntities, selectedGroups, setSelectedEntities, setSelectedGroups } =
    useScopeStore();

  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [validated, setValidated] = useState(false);
  const [selectedAccessOption, setSelectedAccessOption] = useState<string>();
  const { alert } = useAlertStore();
  const { mutateAsync: checkValidation } = useValidAccessGroup();
  const { data: accessGroup, isFetching: isLoadingAccessGroup } =
    useAccessGroupDetails(accessGroupId);

  const editMode = accessGroupId !== undefined;

  const schema = getAccessGroupValidationSchema(intl, checkValidation, accessGroup?.name);
  const { register, trigger, formState, getValues, setValue } = useForm<AccessGroupNameForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      name: accessGroupId && (accessGroup?.name || '_'),
    },
  });

  const { mutate: createUpdateAccessGroup, isPending: isSaving } = useCreateUpdateAccessGroup(
    () => {
      cancelSave();
      alert.success(
        <FormattedMessage id="organization.users.changesSaved" defaultMessage="Changes saved" />
      );
    },
    () => {
      alert.genericError();
    }
  );

  const navHeader = editMode ? (
    <FormattedMessage id="access-group.edit-details" defaultMessage="Edit details" />
  ) : (
    <FormattedMessage id="access-group.add-access-group" defaultMessage="Add access group" />
  );

  const validateForm = async () => {
    const isFormValid = !!accessGroup || (await trigger());
    if (
      !isFormValid ||
      !selectedAccessOption ||
      (selectedAccessOption === 'entities' && !selectedEntities.length) ||
      (selectedAccessOption === 'segments' && !selectedGroups.length)
    ) {
      return false;
    }
    return true;
  };

  const onSave = async () => {
    const isFormValid = await validateForm();
    setValidated(true);
    if (!isFormValid) {
      return;
    }
    const savingAccessGroup: AccessGroupCreateUpdatePayload = {
      all: false,
      name: getValues('name'),
      entity_ids:
        selectedAccessOption === 'entities' ? selectedEntities.map((entity) => entity.id) : [],
      group_ids: selectedAccessOption === 'segments' ? selectedGroups.map((group) => group.id) : [],
    };
    if (accessGroupId) {
      savingAccessGroup.id = accessGroupId;
    }
    createUpdateAccessGroup(savingAccessGroup);
  };

  const onCancel = () => {
    if (
      !accessGroup &&
      (getValues('name') ||
        selectedAccessOption ||
        selectedGroups.length ||
        selectedEntities.length)
    ) {
      setShowCancelConfirmation(true);
    } else {
      cancelSave();
    }
  };

  const cancelSave = () => {
    if (isModal) {
      onLeave();
    } else {
      goUp(editMode ? 2 : 1);
    }
  };

  useEffect(() => {
    if (accessGroup?.name) {
      setValue('name', accessGroup?.name);
    }
    if (accessGroup?.entities.length) {
      setSelectedAccessOption('entities');
    } else if (accessGroup?.groups.length) {
      setSelectedAccessOption('segments');
    }
    setSelectedEntities(accessGroup?.entities || []);
    setSelectedGroups(accessGroup?.groups || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessGroup]);

  return (
    <ContentPane
      headers={[<Typography variant="h6">{navHeader}</Typography>]}
      navigationHandle={!isModal ? onCancel : undefined}
      headerStyles={isModal ? { pl: 3 } : {}}
    >
      <Box sx={styles.body}>
        <AccessGroupName register={register} formState={formState} />
        <AccessOptionSelection
          accessOption={selectedAccessOption}
          setAccessOption={setSelectedAccessOption}
          validated={validated}
        />
        {selectedAccessOption === 'entities' && <EntitySelection validated={validated} />}
        {selectedAccessOption === 'segments' && <GroupSelection validated={validated} />}
        <Footer actionMode={editMode ? 'update' : 'create'} onCancel={onCancel} onSave={onSave} />
      </Box>
      <DiscardChangesModal
        onClose={() => {
          setShowCancelConfirmation(false);
        }}
        onDiscard={cancelSave}
        open={showCancelConfirmation}
      />
      <BackdropSpinner isLoading={isSaving || isLoadingAccessGroup} />
    </ContentPane>
  );
};
