import { defineMessages } from 'react-intl';

export const commonMessages = defineMessages({
  details: {
    id: 'analytics.reports.details',
    defaultMessage: 'Details',
  },
  delete: {
    id: 'analytics.reports.delete',
    defaultMessage: 'Delete',
  },
  cancel: {
    id: 'analytics.reports.delete-cancel',
    defaultMessage: 'Cancel',
  },
  deleteReportButton: {
    id: 'analytics.reports.delete-report',
    defaultMessage: 'Delete report',
  },
  deleteReportTitle: {
    id: 'analytics.reports.delete-title',
    defaultMessage: 'Delete report?',
  },
  deleteReportContent: {
    id: 'analytics.reports.delete-content',
    defaultMessage: 'You won’t be receiving it anymore.',
  },
  deleteReportSuccess: {
    id: 'analytics.reports.delete-success',
    defaultMessage: 'Report deleted',
  },
  modifyReportSuccess: {
    id: 'analytics.reports.modify-success',
    defaultMessage: 'Changes saved',
  },
  discardChangesCancel: {
    id: 'analytics.reports.discard-cancel',
    defaultMessage: 'Cancel & stay',
  },
  discardChangesDiscard: {
    id: 'analytics.reports.discard-button-text',
    defaultMessage: 'Discard',
  },
  discardChangesTitle: {
    id: 'analytics.reports.discard-title',
    defaultMessage: 'Discard changes?',
  },
  discardChangesContent: {
    id: 'analytics.reports.discard-content',
    defaultMessage: 'You’ll lose changes made to your report if you leave this page',
  },
  downloadDashboard: {
    id: 'analytics.reports.download-dashboard',
    defaultMessage: 'Download dashboard',
  },
  downloading: {
    id: 'analytics.reports.downloading',
    defaultMessage: 'Downloading',
  },
});

export const validationErrorsMessages = defineMessages({
  missingReportName: {
    id: 'analytics.reports.new.name-required',
    defaultMessage: 'Report name is required',
  },
  missingRecipients: {
    id: 'analytics.reports.new.recipients-required',
    defaultMessage: 'Recipient is required',
  },
  timeAfter: {
    id: 'analytics.reports.new.time-of-day-monthly-check',
    defaultMessage: 'Time should be in the future',
  },
});

export const formLabelMessages = defineMessages({
  format: {
    id: 'analytics.reports.new.format',
    defaultMessage: 'Format',
  },
  excel: {
    id: 'analytics.reports.new.format-excel',
    defaultMessage: 'Excel',
  },
  pdf: {
    id: 'analytics.reports.new.format-pdf',
    defaultMessage: 'PDF',
  },
  weekly: {
    id: 'analytics.reports.new.schedule-frequency-weekly',
    defaultMessage: 'Weekly',
  },
  daily: {
    id: 'analytics.reports.new.schedule-frequency-daily',
    defaultMessage: 'Daily',
  },
  monthly: {
    id: 'analytics.reports.new.schedule-frequency-monthly',
    defaultMessage: 'Monthly',
  },
  portrait: {
    id: 'analytics.reports.new.format-orientation-portrait',
    defaultMessage: 'Portrait',
  },
  add: {
    id: 'analytics.reports.new.recipients-add',
    defaultMessage: 'Add',
  },
  noOptions: {
    id: 'analytics.reports.new.recipients-no-options',
    defaultMessage: 'No options',
  },
  loading: {
    id: 'analytics.reports.new.recipients-loading',
    defaultMessage: 'Loading',
  },
  more: {
    id: 'analytics.reports.new.recipients-next-page',
    defaultMessage: 'More',
  },
});

export const formPlaceholderMessages = defineMessages({
  name: {
    id: 'analytics.reports.new.name-placeholder',
    defaultMessage: 'Name',
  },
  recipients: {
    id: 'analytics.reports.new.recipients-input-placeholder',
    defaultMessage: 'Email addresses',
  },
});

export const formHelperTextMessages = defineMessages({
  recipients: {
    id: 'analytics.reports.new.recipients-helper-text',
    defaultMessage: 'You can add multiple addresses',
  },
  timeOfDay: {
    id: 'analytics.reports.new.time-of-day-helper-text',
    defaultMessage: 'Based on your time zone',
  },
  dayOfMonth: {
    id: 'analytics.reports.new.day-of-month-helper-text',
    defaultMessage:
      'If the selected day is unavailable for the month, \n the report will be sent on the last day of the month',
  },
  errorLoadingUsers: {
    id: 'analytics.reports.new.recipients-loading-error',
    defaultMessage: 'Error loading users',
  },
});
