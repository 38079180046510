import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Stack, Typography } from '@trustyou/ui';

import { CountChip } from './count-chip';

import { GroupedItemSelector } from '../../../../components';
import { newReportWizardMessages } from '../../../../constants/messages';
import { useWidgets } from '../../../../service/hooks';
import { useNewReportStore } from '../../../../store';
import { Footer } from '../footer';
import styles from '../styles';

export const WidgetsStep = () => {
  const { newReport, prevStep, nextStep, setWidgets } = useNewReportStore();
  const { data, isFetching: isLoading } = useWidgets(newReport?.contentId as string);
  const widgetList = data?.data || [];

  useEffect(() => {
    if (!isLoading && widgetList.length > 0 && !newReport?.widgets) {
      setWidgets(widgetList.map((row) => row.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetList, isLoading]);

  if (isLoading) return null;

  return (
    <>
      <Box sx={styles.stepBody}>
        <Stack sx={styles.widgetsSelectorContainer}>
          <Box sx={styles.widgetsHeaderContainer}>
            <Typography variant="h6">
              <FormattedMessage {...newReportWizardMessages.widgetsHeader} />
            </Typography>
            <CountChip />
          </Box>
          <Typography variant="body1">
            <FormattedMessage {...newReportWizardMessages.widgetsInfo} />
          </Typography>
          <GroupedItemSelector
            items={widgetList.map((row) => ({ ...row, text: row.name }))}
            selected={newReport?.widgets || []}
            onSelect={setWidgets}
          />
        </Stack>
      </Box>
      <Footer
        disableNext={!newReport?.widgets?.length}
        onClickBack={prevStep}
        onClickNext={nextStep}
      />
    </>
  );
};
