import { AuthWrapper as AnalyticsAuthWrapper } from '@trustyou/analytics';
import { Stack } from '@trustyou/ui';

import FiltersLoader from './filters-loader';
import { ReportDashboard } from './report-dashboard';

import { useNewReportStore } from '../../../../store';
import { Footer } from '../footer';
import styles from '../styles';

export const FiltersStep = () => {
  const { activeStep, isDashboardLoaded, nextStep, prevStep } = useNewReportStore();
  return (
    <>
      <Stack sx={{ ...styles.stepBody, ...(activeStep !== 1 && { display: 'none' }) }}>
        <AnalyticsAuthWrapper loader={<FiltersLoader />}>
          <ReportDashboard />
        </AnalyticsAuthWrapper>
      </Stack>
      {activeStep === 1 && (
        <Footer onClickBack={prevStep} onClickNext={nextStep} disableNext={!isDashboardLoaded} />
      )}
    </>
  );
};
