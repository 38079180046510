import { FormattedMessage } from 'react-intl';

import { DeleteModal } from '@trustyou/ui';

import { commonMessages } from './messages';

export const DiscardReportChangesModal = ({
  open,
  onClose,
  onDiscard,
}: {
  open: boolean;
  onClose: () => void;
  onDiscard: () => void;
}) => {
  return (
    <DeleteModal
      contentStyles={{ width: 444 }}
      cancelText={<FormattedMessage {...commonMessages.discardChangesCancel} />}
      content={<FormattedMessage {...commonMessages.discardChangesContent} />}
      dataName="report-discard"
      deleteText={<FormattedMessage {...commonMessages.discardChangesDiscard} />}
      onClose={onClose}
      onDelete={onDiscard}
      title={<FormattedMessage {...commonMessages.discardChangesTitle} />}
      open={open}
    />
  );
};
