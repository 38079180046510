import { useLanguageStore } from '@trustyou/shared';
import { Stack, Typography } from '@trustyou/ui';

import { QuestionWrapper } from './question-wrapper';

import { useLanguage } from '../../../hooks';

export type MultipleChoiceQuestionProps = {
  name: string;
  answers: Record<string, string>[];
};

export const MultipleChoiceQuestion = ({ name, answers = [] }: MultipleChoiceQuestionProps) => {
  const { locale } = useLanguageStore();
  const { getFormattedLanguageCode } = useLanguage();

  return (
    <QuestionWrapper name={name}>
      {answers.length > 0 && (
        <Stack spacing={0.5}>
          {answers?.map((answer, index) => (
            <Typography key={index} variant="body2">
              {answer?.[getFormattedLanguageCode(locale)] ?? answer?.en}
            </Typography>
          ))}
        </Stack>
      )}
    </QuestionWrapper>
  );
};
