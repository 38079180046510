import german from './compiled/de-DE.json';
import english from './compiled/en-US.json';
import spanish from './compiled/es-ES.json';
import french from './compiled/fr-FR.json';
import italian from './compiled/it-IT.json';
import japanese from './compiled/ja-JP.json';
import portuguese from './compiled/pt-PT.json';
import russian from './compiled/ru-RU.json';
import chinese from './compiled/zh-CN.json';

export const messages = {
  'de-DE': german,
  'en-US': english,
  'es-ES': spanish,
  'fr-FR': french,
  'it-IT': italian,
  'ja-JP': japanese,
  'pt-PT': portuguese,
  'zh-CN': chinese,
  'ru-RU': russian,
};

export const supportedLanguages = [
  {
    value: 'de-DE',
    label: 'Deutsch',
    dataTestId: 'lang-de-de',
  },
  {
    value: 'en-US',
    label: 'English',
    dataTestId: 'lang-en-us',
  },

  {
    value: 'es-ES',
    label: 'Español',
    dataTestId: 'lang-es-es',
  },
  {
    value: 'fr-FR',
    label: 'Français',
    dataTestId: 'lang-fr-fr',
  },
  {
    value: 'it-IT',
    label: 'Italiano',
    dataTestId: 'lang-it-it',
  },
  {
    value: 'pt-PT',
    label: 'Português',
    dataTestId: 'lang-pt-pt',
  },
  {
    value: 'zh-CN',
    label: '中文',
    dataTestId: 'lang-zh-cn',
  },
  {
    value: 'ja-JP',
    label: '日本語',
    dataTestId: 'lang-ja-jp',
  },
  {
    value: 'ru-RU',
    label: 'русский',
    dataTestId: 'lang-ru-ru',
  },
];

// TODO:
// https://formatjs.io/docs/react-intl/#typing-message-ids-and-locale
// declare global {
//   // eslint-disable-next-line @typescript-eslint/no-namespace
//   namespace FormatjsIntl {
//     interface Message {
//       ids: keyof typeof enMessages;
//     }
//     interface IntlConfig {
//       locale: keyof typeof messages;
//     }
//   }
// }
