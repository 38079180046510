import twoGisru from './2gis.ru.png';
import fourTravelJP from './4travel.jp.png';
import agoda from './agoda.com.png';
import airbnb from './airbnb.com.png';
import trustyou from './analytics.trustyou.com.png';
import booking from './booking.com.png';
import holidayinsider from './de.holidayinsider.com.png';
import defaultPng from './default.png';
import dianping from './dianping.png';
import expedia from './expedia.com.png';
import feratel from './feratel.com.png';
import fliggy from './fliggy.png';
import foodyVn from './foody.vn.png';
import freeDreams from './freedreams.ch.png';
import gnaviCoJp from './gnavi.co.jp.png';
import goibibo from './goibibo.com.png';
import google from './google.com.png';
import holidaycheck from './holidaycheck.de.png';
import hostelsclub from './hostelsclub.com.png';
import hostelworld from './hostelworld.com.png';
import hotelDe from './hotel.de.png';
import elong from './hotel.elong.com.png';
import eztravel from './hotel.eztravel.com.tw.png';
import interpark from './hotel.interpark.com.png';
import qunar from './hotel.qunar.com.png';
import hotelnjoy from './hotelnjoy.com.png';
import hotels from './hotels.com.png';
import ctrip from './hotels.ctrip.com.png';
import hotelsNl from './hotels.nl.png';
import hotelsRu from './hotels.ru.png';
import hotelspecials from './hotelspecials.nl.png';
import hrs from './hrs.de.png';
import ihg from './ihg.com.png';
import ikyu from './ikyu.com.png';
import jalan from './jalan.net.png';
import klook from './klook.png';
import kurzurlaub from './kurzurlaub.de.png';
import lvmama from './lvmama.com.png';
import mafengwo from './mafengwo.cn.png';
import makemytrip from './makemytrip.com.png';
import meituan from './meituan.com.png';
import obnovlenie from './obnovlenie.ru.png';
import openrice from './openrice.com.png';
import opentable from './opentable.com.png';
import orbitz from './orbitz.com.png';
import otelpuan from './otelpuan.com.png';
import ozmall from './ozmall.co.jp.png';
import pegipegi from './pegipegi.com.png';
import rettyMe from './retty.me.png';
import rurubuTravel from './rurubu.travel.png';
import tiket from './tiket.com.png';
import topHotels from './tophotels.ru.png';
import travelRakuten from './travel.rakuten.co.jp.png';
import travelYahoo from './travel.yahoo.co.jp.png';
import travelocity from './travelocity.com.png';
import traveloka from './traveloka.com.png';
import trip from './trip.com.png';
import tripadvisor from './tripadvisor.com.png';
import tui from './tui.com.png';
import tuniu from './tuniu.png';
import votpusk from './votpusk.ru.png';
import wotif from './wotif.com.png';
import yandex from './yandex.ru.png';
import yukoyuko from './yukoyuko.net.png';
import zomato from './zomato.com.png';
import zoover from './zoover.de.png';

import staycation from './staycation.co.png';

type ReviewProvidersLogo = {
  [key: string]: string;
};

export const reviewProvidersLogo: ReviewProvidersLogo = {
  '2gis.ru': twoGisru,
  '4travel.jp': fourTravelJP,
  'agoda.com': agoda,
  'airbnb.com': airbnb,
  'analytics.trustyou.com': trustyou,
  'biz.ikyu.com': ikyu,
  'booking.com': booking,
  'ctrip.com': ctrip,
  'de.holidayinsider.com': holidayinsider,
  'de.hotels.com': hotels,
  'expedia.com': expedia,
  'feratel.com': feratel,
  'foody.vn': foodyVn,
  'freedreams.ch': freeDreams,
  'gnavi.co.jp': gnaviCoJp,
  'goibibo.com': goibibo,
  'google.com': google,
  'holidaycheck.de': holidaycheck,
  'hostelsclub.com': hostelsclub,
  'hostelworld.com': hostelworld,
  'hotel.de': hotelDe,
  'hotel.elong.com': elong,
  'hotel.eztravel.com.tw': eztravel,
  'hotel.interpark.com': interpark,
  'hotelnjoy.com': hotelnjoy,
  'hotel.qunar.com': qunar,
  'hotels.ctrip.com': ctrip,
  'hotels.nl': hotelsNl,
  'hotelspecials.nl': hotelspecials,
  'hotels.ru': hotelsRu,
  'hrs.de': hrs,
  'ihg.com': ihg,
  'ikyu.com': ikyu,
  'jalan.net': jalan,
  'kurzurlaub.de': kurzurlaub,
  'lvmama.com': lvmama,
  'mafengwo.cn': mafengwo,
  'makemytrip.com': makemytrip,
  'meituan.com': meituan,
  'obnovlenie.ru': obnovlenie,
  'openrice.com': openrice,
  'opentable.com': opentable,
  'orbitz.com': orbitz,
  'otelpuan.com': otelpuan,
  'ozmall.co.jp': ozmall,
  'pegipegi.com': pegipegi,
  'retty.me': rettyMe,
  'rurubu.travel': rurubuTravel,
  'staycation.co': staycation,
  'tiket.com': tiket,
  'tophotels.ru': topHotels,
  'travelocity.com': travelocity,
  'traveloka.com': traveloka,
  'travel.rakuten.co.jp': travelRakuten,
  'travel.yahoo.co.jp': travelYahoo,
  'tripadvisor.com': tripadvisor,
  'trip.com': trip,
  'trustyou.com': trustyou,
  'tui.com': tui,
  'votpusk.ru': votpusk,
  'wotif.com': wotif,
  'yandex.ru': yandex,
  'yukoyuko.net': yukoyuko,
  'zomato.com': zomato,
  'zoover.de': zoover,
  tuniu: tuniu,
  klook: klook,
  dianping: dianping,
  fliggy: fliggy,
  'com.hotels': hotels,
  default: defaultPng,
};
