import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  container: {
    border: 2,
    borderRadius: 1,
    borderColor: 'primary.main',
    borderStyle: 'dashed',
    paddingY: 7,
    width: { xs: '100%', md: '800px' },
  },
  formController: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  uploadButton: {
    marginTop: 1,
  },
};

export default styles;
