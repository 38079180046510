import { useIntl } from 'react-intl';

import {
  faArrowRightToArc,
  faBrush,
  faEnvelope,
  faGear,
  faHandWave,
  faShuffle,
  faSquareQuestion,
  faTextSize,
  faUserGear,
} from '@trustyou/fortawesome/pro-regular-svg-icons';

import { EditorSurveyContent } from './content/content';
import { EditorEmailInviteContent } from './content/email-invite';
import { EditorRulesContent } from './content/rules';
import { EditorSettingsContent } from './content/settings';
import { EditorSubmitSectionContent } from './content/submit';
import { EditorThankYouContent } from './content/thank-you';
import { EditorThemeContent } from './content/theme';
import { EditorWelcomeMessageContent } from './content/welcome';

import { EditorScopeContent } from '../../shared/editor-scope-content';
import type { Step } from '../../shared/editor-side-nav';

export function useSurveyEditorSteps() {
  const intl = useIntl();

  const steps: Step[] = [
    {
      icon: faGear,
      label: intl.formatMessage({ id: 'survey.common.settings', defaultMessage: 'Settings' }),
      content: <EditorSettingsContent />,
    },
    {
      icon: faHandWave,
      label: intl.formatMessage({ id: 'survey.common.welcome', defaultMessage: 'Welcome' }),
      content: <EditorWelcomeMessageContent />,
    },
    {
      icon: faSquareQuestion,
      label: intl.formatMessage({ id: 'survey.common.content', defaultMessage: 'Content' }),
      content: <EditorSurveyContent />,
    },
    {
      icon: faShuffle,
      label: intl.formatMessage({ id: 'survey.common.rules', defaultMessage: 'Rules' }),
      content: <EditorRulesContent />,
    },
    {
      icon: faArrowRightToArc,
      label: intl.formatMessage({ id: 'survey.common.submit', defaultMessage: 'Submit' }),
      content: <EditorSubmitSectionContent />,
    },
    {
      icon: faTextSize,
      label: intl.formatMessage({
        id: 'survey.common.thankyou-page',
        defaultMessage: 'Thank you page',
      }),
      content: <EditorThankYouContent />,
    },
    {
      icon: faEnvelope,
      label: intl.formatMessage({
        id: 'survey.common.email-invitiation',
        defaultMessage: 'Email invitation',
      }),
      content: <EditorEmailInviteContent />,
    },
    {
      icon: faBrush,
      label: intl.formatMessage({ id: 'survey.common.theme', defaultMessage: 'Theme' }),
      content: <EditorThemeContent />,
    },
    {
      icon: faUserGear,
      label: intl.formatMessage({ id: 'survey.common.scope', defaultMessage: 'Scope' }),
      content: <EditorScopeContent variant="survey" />,
    },
  ];

  return steps;
}
