import type { ReactNode } from 'react';

import { CircularProgress, Stack, Typography } from '@trustyou/ui';

import styles from './styles';

interface DashboardLoaderProps {
  message: ReactNode;
}

export const DashboardLoader = ({ message }: DashboardLoaderProps) => (
  <Stack sx={styles.container}>
    <CircularProgress />
    <Typography sx={styles.title} variant="subtitle1">
      {message}
    </Typography>
  </Stack>
);
