import { grey } from '@mui/material/colors';
import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    width: '100%',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    gap: 2,
    width: '100%',
  },
  header: {
    height: '56px',
    display: 'flex',
    alignItems: 'flex-end',
  },
  list: {
    border: `1px solid ${grey[300]}`,
    borderRadius: '4px',
    padding: 0,
    flex: '1',
    height: 'fit-content',
    overflowY: 'auto',
  },
  listItem: {
    borderBottom: `1px solid ${grey[300]}`,
  },
  listItemText: {
    marginY: 0.5,
  },
  removeButton: {
    marginRight: 0,
    height: '48px',
    width: '48px',
  },
};

export default styles;
