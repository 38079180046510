import { Box, CircularProgress, Typography } from '@trustyou/ui';

import styles from './styles';

type ListStateProps = {
  isLoading?: boolean;
  emptyMessage: string;
};

export const ListState = ({ isLoading, emptyMessage }: ListStateProps) => (
  <Box sx={styles.container}>
    {isLoading ? (
      <CircularProgress color="primary" size={32} />
    ) : (
      <Typography variant="body1" color="text.primary">
        {emptyMessage}
      </Typography>
    )}
  </Box>
);
