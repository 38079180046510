import type { IStyles } from '../../types/common';

const styles: IStyles = {
  dataGrid: {
    border: 'none',
    '& .MuiDataGrid-pinnedColumns,.MuiDataGrid-pinnedColumnHeaders': {
      backgroundColor: 'white',
    },
    '& .inactive-cell': {
      opacity: '0.5',
    },
  },
  basePopper: {
    '&.MuiDataGrid-menu .MuiPaper-root': {
      '& .MuiTypography-root': {
        fontSize: '14px',
      },
    },
  },
  paginationSelect: {
    '& .MuiTablePagination-select': {
      fontSize: '14px',
    },
  },
  paginationSelectMenu: {
    '& .MuiMenuItem-root': {
      fontSize: '14px',
    },
  },
  noRowsWrapper: {
    pt: 2,
    height: '100%',
    display: 'flex',
    gap: 5,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
  noRowsTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 0.5,
    maxWidth: '600px',
    textAlign: 'center',
  },
  columnsPanel: {
    '.MuiDataGrid-columnsPanel': {
      '.MuiFormControlLabel-root.Mui-disabled': {
        display: 'none',
      },
    },
  },
};

export default styles;
