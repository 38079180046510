import { FormattedMessage } from 'react-intl';

import { Box, Button, Typography } from '@trustyou/ui';

import notFoundLostInSpace from '../../assets/illustrations/404-not-found-lost-in-space.svg';
import styles from './styles';

export interface NotFoundRouteProps {
  onGoBack: () => void;
}

export const NotFoundRoute = ({ onGoBack }: NotFoundRouteProps) => (
  <Box sx={styles.wrapper}>
    <img src={notFoundLostInSpace} alt="Dog lost in space with status code 404" />
    <Box sx={styles.textWrapper}>
      <Typography variant="h6">
        <FormattedMessage id="tyDesign.pageNotFound" defaultMessage="Page not found" />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage
          id="tyDesign.notFoundDescription"
          defaultMessage="We can't find the page you're looking for. Let's get you back on track."
        />
      </Typography>
    </Box>
    <Button variant="contained" data-testid="go-back-button" onClick={onGoBack}>
      <FormattedMessage id="tyDesign.backToHome" defaultMessage="Back to home" />
    </Button>
  </Box>
);
