import { FormattedMessage } from 'react-intl';
import { Link, useLocation, useParams } from 'react-router-dom';

import { commonFields } from '@trustyou/shared';
import { Box, Tab, Tabs } from '@trustyou/ui';

import { SubscriptionEntities, SubscriptionMembers } from '../../../Subscriptions/Common';
import { styles } from './styles';

const SubscriptionUsage = () => {
  const location = useLocation();
  const { chosenSubscriptionId, usage } = useParams();
  console.info('chosenSubscriptionId', chosenSubscriptionId);
  const tabs = [
    { id: 'entities', path: '/entities', label: commonFields.entity },
    { id: 'members', path: '/members', label: commonFields.members },
  ];
  return (
    <Box>
      <Tabs value={usage || 'entities'} sx={styles.tabs}>
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            value={tab.id}
            label={<FormattedMessage {...tab.label} />}
            component={Link}
            to={location.pathname.replace(/\/[^/]*$/, tab.path)}
          />
        ))}
      </Tabs>
      {usage === 'entities' && <SubscriptionEntities />}
      {usage === 'members' && <SubscriptionMembers />}
    </Box>
  );
};

export default SubscriptionUsage;
