import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  type CreateSegmentResponse,
  type Subscription,
  useCurrentSubscription,
} from '@trustyou/shared';
import { Box, ContentPane } from '@trustyou/ui';

import AddAdditionalEntities from './AddAdditionalEntities';
import AllEntitiesTable from './AllEntities/AllEntitiesTable';
import AddSegments from './Segments/AddSegments';
import EditDashboardSegments from './Segments/EditDashboardSegments';
import { UpdateGroups } from './Segments/Groups/UpdateGroups';
import { Segments } from './Segments/Segments';
import TabsBar from './TabsBar';
import { VisitData } from './VisitData/VisitData';

import { AddEntityDialog } from '../../../components';
import { useSegmentStore } from '../../../store';
import styles from './styles';

export const Entities = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean);

  const isNavigationPathSegments = pathSegments.includes('segments');
  const isNavigationPathVisitData = pathSegments.includes('visit-data');
  const defaultValue = isNavigationPathVisitData ? 2 : isNavigationPathSegments ? 1 : 0;
  const [selectedTab, setSelectedTab] = useState(defaultValue);

  const {
    selectedSegment,
    setSelectedSegment,
    editDashboardSegmentsModelOpen,
    setEditDashboardSegmentsModelOpen,
    segmentModelOpen,
    setSegmentModelOpen,
    groupModelOpen,
    setGroupModelOpen,
  } = useSegmentStore();

  const [importEntitiesModelOpen, setImportEntitiesModelOpen] = useState(false);
  const [chosenSubscription, setChosenSubscription] = useState<Subscription | undefined>(undefined);

  const { data: currentSubscription } = useCurrentSubscription();

  const handleCreatedSegment = (data: CreateSegmentResponse) => {
    setSelectedSegment({
      id: data.id,
      name: data.name,
      groups_count: data.groups.length,
      is_editable: true,
      is_global: false,
      selected_for_dashboard: false,
    });
  };

  const toggleSegmentModel = () => {
    setSegmentModelOpen(!segmentModelOpen);
  };

  const toggleGroupModel = () => {
    setGroupModelOpen(!groupModelOpen);
  };

  const toggleImportEntitiesModel = () => {
    setImportEntitiesModelOpen(!importEntitiesModelOpen);
  };

  const toggleDashboardSegmentsModel = () => {
    setEditDashboardSegmentsModelOpen(!editDashboardSegmentsModelOpen);
  };

  const onAddEntity = () => {
    setChosenSubscription(currentSubscription);
  };

  const closeAddEntityDialog = () => {
    setChosenSubscription(undefined);
  };

  return (
    <ContentPane>
      <TabsBar
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        onImportEntities={toggleImportEntitiesModel}
        onAddEntity={onAddEntity}
        currentSubscription={currentSubscription}
      />
      <Box sx={styles.body}>
        {selectedTab === 0 && <AllEntitiesTable />}
        {selectedTab === 1 && <Segments />}
        {selectedTab === 2 && <VisitData />}
      </Box>
      {segmentModelOpen && (
        <AddSegments onSegmentCreated={handleCreatedSegment} onClose={toggleSegmentModel} />
      )}
      {groupModelOpen && selectedSegment && (
        <UpdateGroups segment={selectedSegment} onClose={toggleGroupModel} />
      )}
      {importEntitiesModelOpen && <AddAdditionalEntities onClose={toggleImportEntitiesModel} />}
      {editDashboardSegmentsModelOpen && (
        <EditDashboardSegments onClose={toggleDashboardSegmentsModel} />
      )}
      {chosenSubscription && (
        <AddEntityDialog subscription={chosenSubscription} onClose={closeAddEntityDialog} />
      )}
    </ContentPane>
  );
};
