import { FormattedMessage } from 'react-intl';

import {
  faBallot,
  faCalendarDays,
  faCheck,
  faEnvelope,
  faGaugeHigh,
  faListDropdown,
  faPenNib,
  faPhone,
  faSquare1,
  faThumbsUp,
  faTypewriter,
} from '@trustyou/fortawesome/pro-regular-svg-icons';

import type { ListItem, QuestionTypeOption } from '../../../types/question';

export const QUESTIONS: ListItem[] = [
  {
    typology: 'Choice',
    text: 'Single  select - radio button',
    icon: faBallot,
    value: 'single_select',
    variation: 'radio_group',
  },
  {
    typology: 'Choice',
    text: 'Multiple select - checkbox',
    icon: faBallot,
    value: 'multiple_select',
    variation: 'checkbox_group',
  },
  {
    typology: 'Choice',
    text: 'Dropdown - single select',
    icon: faListDropdown,
    value: 'single_select',
    variation: 'dropdown_single',
  },
  {
    typology: 'Choice',
    text: 'Dropdown - multiple select',
    icon: faListDropdown,
    value: 'multiple_select',
    variation: 'dropdown_multiple',
  },
  {
    typology: 'Choice',
    text: 'Yes / No',
    icon: faThumbsUp,
    value: 'binary',
    variation: 'button_group',
  },
  { typology: 'Choice', text: 'Checkbox', icon: faCheck, value: 'binary', variation: 'checkbox' },

  {
    typology: 'Rating',
    text: 'Net promoter score',
    icon: faGaugeHigh,
    value: 'nps',
    variation: 'numbers-zero-to-ten',
  },
  {
    typology: 'Rating',
    text: 'Numeric Scale',
    icon: faSquare1,
    value: 'rating',
    variation: 'numbers-one-to-five',
  },

  { typology: 'Text', text: 'Short text', icon: faPenNib, value: 'text', variation: 'short_text' },
  {
    typology: 'Text',
    text: 'Long text',
    icon: faTypewriter,
    value: 'text',
    variation: 'long_text',
  },
  {
    typology: 'Text',
    text: 'Number',
    icon: faSquare1,
    value: 'number',
    variation: 'number',
  },

  {
    typology: 'Contact information',
    text: 'Email',
    icon: faEnvelope,
    value: 'text',
    variation: 'email',
  },
  {
    typology: 'Contact information',
    text: 'Phone number',
    icon: faPhone,
    value: 'text',
    variation: 'phone',
  },

  {
    typology: 'Date',
    text: 'Date',
    icon: faCalendarDays,
    value: 'date',
    variation: 'day-month-year',
  },
];

export const QUESTION_LIBRARY_EDITOR_TYPE_OPTIONS: QuestionTypeOption[] = [
  {
    value: 'binary',
    label: <FormattedMessage id="survey.question-editor.question.binary" defaultMessage="Yes/No" />,
    subOptions: [
      {
        label: (
          <FormattedMessage
            id="survey.question-editor.question.binary.button-group"
            defaultMessage="Button group"
          />
        ),
        value: 'button_group',
      },
      {
        label: (
          <FormattedMessage
            id="survey.question-editor.question.binary.checkbox"
            defaultMessage="Checkbox"
          />
        ),
        value: 'checkbox',
      },
    ],
    helperText: (
      <FormattedMessage
        id="survey.question-editor.question.binary.helper-text"
        defaultMessage="Choice between 2 options"
      />
    ),
  },
  {
    value: 'date',
    label: <FormattedMessage id="survey.question-editor.question.date" defaultMessage="Date" />,
    subOptions: [
      {
        label: (
          <FormattedMessage
            id="survey.question-editor.question.date.day-month-year"
            defaultMessage="Day/month/year"
          />
        ),
        value: 'day-month-year',
      },
      {
        label: (
          <FormattedMessage
            id="survey.question-editor.question.date.month-year"
            defaultMessage="Month/year"
          />
        ),
        value: 'month-year',
      },
    ],
  },
  {
    value: 'nps',
    label: (
      <FormattedMessage
        id="survey.question-editor.question.nps"
        defaultMessage="Net promoter score"
      />
    ),
    subOptions: [],
  },
  {
    value: 'number',
    label: <FormattedMessage id="survey.question-editor.question.number" defaultMessage="Number" />,
    subOptions: [],
  },
  {
    value: 'rating',
    label: (
      <FormattedMessage id="survey.question-editor.question.rating" defaultMessage="Rating scale" />
    ),
    subOptions: [
      {
        label: '5',
        value: 'numbers-one-to-five',
      },
      {
        label: '10',
        value: 'numbers-one-to-ten',
      },
    ],
  },
  {
    value: 'single_select',
    label: (
      <FormattedMessage
        id="survey.question-editor.question.single-select"
        defaultMessage="Single select"
      />
    ),
    helperText: (
      <FormattedMessage
        id="survey.question-editor.question.single-select.helper-text"
        defaultMessage="Respondent can pick 1 option"
      />
    ),
    subOptions: [
      {
        label: (
          <FormattedMessage
            id="survey.question-editor.question.select.dropdown"
            defaultMessage="Dropdown"
          />
        ),
        value: 'dropdown_single',
        helperText: (
          <FormattedMessage
            id="survey.question-editor.question.select.dropdown.helper-text"
            defaultMessage="Shows options in a dropdown"
          />
        ),
      },
      {
        label: (
          <FormattedMessage
            id="survey.question-editor.question.single-select.radio-button"
            defaultMessage="Radio button"
          />
        ),
        value: 'radio_group',
        helperText: (
          <FormattedMessage
            id="survey.question-editor.question.single-select.radio-button.helper-text"
            defaultMessage="Shows options in a list"
          />
        ),
      },
    ],
  },
  {
    value: 'multiple_select',
    label: (
      <FormattedMessage
        id="survey.question-editor.question.multiple-select"
        defaultMessage="Multiple select"
      />
    ),
    helperText: (
      <FormattedMessage
        id="survey.question-editor.question.multiple-select.helper-text"
        defaultMessage="Respondent can pick multiple options"
      />
    ),
    subOptions: [
      {
        label: (
          <FormattedMessage
            id="survey.question-editor.question.select.dropdown"
            defaultMessage="Dropdown"
          />
        ),
        value: 'dropdown_multiple',
        helperText: (
          <FormattedMessage
            id="survey.question-editor.question.select.dropdown.helper-text"
            defaultMessage="Shows options in a dropdown"
          />
        ),
      },
      {
        label: (
          <FormattedMessage
            id="survey.question-editor.question.multiple-select.checkbox-group"
            defaultMessage="Checkbox group"
          />
        ),
        value: 'checkbox_group',
        helperText: (
          <FormattedMessage
            id="survey.question-editor.question.multiple-select.checkbox-group.helper-text"
            defaultMessage="Shows options in a list with checkboxes"
          />
        ),
      },
    ],
  },
  {
    value: 'text',
    label: <FormattedMessage id="survey.common.text" defaultMessage="Text" />,
    subOptions: [
      {
        label: <FormattedMessage id="survey.common.short" defaultMessage="Short" />,
        value: 'short_text',
        helperText: '',
      },
      {
        label: <FormattedMessage id="survey.common.long" defaultMessage="Long" />,
        value: 'long_text',
        helperText: '',
      },
      {
        label: <FormattedMessage id="survey.common.email" defaultMessage="Email" />,
        value: 'email',
        helperText: '',
      },
      {
        label: <FormattedMessage id="survey.common.phone-number" defaultMessage="Phone number" />,
        value: 'phone',
        helperText: '',
      },
    ],
  },
];

export const QUESTION_LIBRARY_EDITOR_CHECKBOX_INSTRUCTIONS = [
  {
    title: (
      <FormattedMessage
        id="survey.question-editor.question.checkbox.link.title"
        defaultMessage="Insert a link"
      />
    ),
    description: (
      <FormattedMessage
        id="survey.question-editor.question.checkbox.link.description"
        defaultMessage={
          "Use this format: <link>link text</link>{lineBreak}Replace 'url' with the web address and 'link text' with the desired text"
        }
        values={{
          link: (chunks) => `<a href="url">${chunks}</a>`,
          lineBreak: <br />,
        }}
      />
    ),
  },
  {
    title: (
      <FormattedMessage
        id="survey.question-editor.question.checkbox.italic.title"
        defaultMessage="Formatting text for italics"
      />
    ),
    description: (
      <FormattedMessage
        id="survey.question-editor.question.checkbox.italic.description"
        defaultMessage={'Use this format: <i>italic text</i>'}
      />
    ),
  },
  {
    title: (
      <FormattedMessage
        id="survey.question-editor.question.checkbox.bold.title"
        defaultMessage="Formatting text for bold"
      />
    ),
    description: (
      <FormattedMessage
        id="survey.question-editor.question.checkbox.bold.description"
        defaultMessage={'Use this format: <b>bold text</b>'}
      />
    ),
  },
];
