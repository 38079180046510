import type { ReactNode } from 'react';

import { List, ListItem, Stack, Tooltip, Typography } from '@trustyou/ui';

export type StatusListItem = {
  concept: string;
  content: ReactNode;
  tooltip?: string;
};

function StatusListItem({ concept, content, tooltip }: StatusListItem) {
  return (
    <Tooltip title={tooltip} placement="top" arrow>
      <Stack>
        <Typography variant="body2">{concept}</Typography>
        <Typography variant="body2" color="text.secondary">
          {content}
        </Typography>
      </Stack>
    </Tooltip>
  );
}

type StatusCardProps = {
  items: StatusListItem[];
};

export const StatusList = ({ items }: StatusCardProps) => {
  return (
    <List sx={{ maxWidth: 200 }}>
      {items.map((item, index) => (
        <ListItem key={index}>
          <StatusListItem {...item} />
        </ListItem>
      ))}
    </List>
  );
};
