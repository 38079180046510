import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  table: {
    '& .MuiDataGrid-cell': {
      whiteSpace: 'normal !important',
    },
  },
};

export default styles;
