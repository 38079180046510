import { DELETE_USER_PERMISSION, EDIT_USER_PERMISSION, usePermissionStore } from '@trustyou/shared';

const useManageUsers = () => {
  const { hasPermissions } = usePermissionStore();

  const isEditUserAllowed = hasPermissions(EDIT_USER_PERMISSION);
  const isDeleteUserAllowed = hasPermissions(DELETE_USER_PERMISSION);

  return isEditUserAllowed && isDeleteUserAllowed;
};

export default useManageUsers;
