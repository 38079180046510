import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { downloadFile, introductionInstructions, useRouteUtils } from '@trustyou/shared';
import { Box, Typography, WhatIsCustomAttribute } from '@trustyou/ui';

import CsvDownload from './CsvDownload';
import FieldsExampleTable from './FieldsExampleTable';
import InfoList from './InfoList';
import ProTips from './ProTips';

// eslint-disable-next-line import/no-unresolved
import setupSheet from '../../../assets/setup_sheet.csv?url';
import { Footer } from '../Footer';
import styles from './styles';

const CustomAttributes = () => {
  const [viewInfo, setViewInfo] = useState(false);
  return (
    <>
      <Typography
        variant="body2"
        sx={styles.hyperlink}
        onClick={() => {
          setViewInfo(true);
        }}
      >
        <FormattedMessage
          id="entitiesImporter.customAttributes"
          defaultMessage="custom attributes"
        />
      </Typography>
      <WhatIsCustomAttribute open={viewInfo} onClose={() => setViewInfo(false)} />
    </>
  );
};

const infoList = [
  {
    id: 1,
    messageId: introductionInstructions.download.id,
    defaultMessage: introductionInstructions.download.defaultMessage,
  },
  {
    id: 2,
    messageId: introductionInstructions.fillMandatory.id,
    defaultMessage: introductionInstructions.fillMandatory.defaultMessage,
  },
  {
    id: 3,
    messageId: introductionInstructions.addCustom.id,
    defaultMessage: introductionInstructions.addCustom.defaultMessage,
    values: {
      custom_attributes: <CustomAttributes />,
    },
  },
  {
    id: 4,
    messageId: introductionInstructions.next.id,
    defaultMessage: introductionInstructions.next.defaultMessage,
  },
];

const SETUP_SHEET_DOWNLOAD_NAME = 'Setup Sheet.csv';

type IntroductionViewProps = {
  onClickNext: () => void;
};

const IntroductionView = ({ onClickNext }: IntroductionViewProps) => {
  const { navigateWithBase } = useRouteUtils();

  const onDownloadSetupSheet = () => {
    downloadFile(setupSheet, SETUP_SHEET_DOWNLOAD_NAME);
  };

  const onClickBack = () => {
    navigateWithBase('welcome');
  };

  return (
    <>
      <Box sx={styles.container}>
        <Box>
          <Typography variant="h6">
            <FormattedMessage
              id="introductionView.downloadAndFillInSpreadsheet"
              defaultMessage="Download and fill in spreadsheet"
            />
          </Typography>
          <InfoList list={infoList} />
        </Box>
        <ProTips />
      </Box>
      <FieldsExampleTable />
      <CsvDownload onDownload={onDownloadSetupSheet} />
      <Footer onClickBack={onClickBack} onClickNext={onClickNext} />
    </>
  );
};

export default IntroductionView;
