import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  button: {
    marginTop: 5,
    width: 'fit-content',
  },
};

export default styles;
