import { FormattedMessage } from 'react-intl';

import { faInfoCircle } from '@trustyou/fortawesome/pro-solid-svg-icons';
import { Alert, Stack, StyledFontAwesomeIcon, Typography } from '@trustyou/ui';

import { ALERT_INFO_TITLE_COLOR } from '../../constants';
import { getHumanDate } from '../../utils/date';

type DeletedReviewBannerProps = {
  deletionRequestDate: string;
};

export function DeletedReviewAlert({ deletionRequestDate }: DeletedReviewBannerProps) {
  return (
    <Alert
      severity="info"
      icon={<StyledFontAwesomeIcon icon={faInfoCircle} size="xs" color={ALERT_INFO_TITLE_COLOR} />}
      sx={{
        borderRadius: 0,
        padding: 2,
        '& .MuiAlert-message': { padding: 0 },
        '& .MuiAlert-icon': { paddingBlockStart: 0.5 },
      }}
    >
      <Stack spacing={2}>
        <Typography variant="body2">
          <FormattedMessage
            id="inbox.review.delete.info-banner.confirmed.paragraph-1"
            defaultMessage="This review was marked as deleted on {deletionRequestDate} and is now deleted."
            values={{ deletionRequestDate: getHumanDate(deletionRequestDate) }}
          />
        </Typography>
        <Typography variant="body2">
          <FormattedMessage
            id="inbox.review.delete.info-banner.confirmed.paragraph-2"
            defaultMessage="It will no longer impact KPIs in the Dashboard and will show as “deleted” in the Inbox."
          />
        </Typography>
        <Typography variant="body2">
          <FormattedMessage
            id="inbox.review.delete.info-banner.confirmed.paragraph-3"
            defaultMessage="If the review was not deleted at the source, it will reappear in the Inbox as “unread” within the next 36 hours."
          />
        </Typography>
      </Stack>
    </Alert>
  );
}
