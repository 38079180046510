import type { SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import { type Subscription, isSegmentManageEnabled } from '@trustyou/shared';
import { Box, Tab, Tabs } from '@trustyou/ui';

import AddGroupButton from './AddGroupButton';
import useEntityPageTabs from './useEntityPageTabs';

import { ManageEntitiesButtonBlock } from '../../../../components';
import { useSegmentStore } from '../../../../store';
import SegmentsActionButtons from '../Segments/SegmentsActionButtons';
import styles from './styles';

type TabsBarProps = {
  selectedTab: number;
  onTabChange: (value: number) => void;
  onImportEntities: () => void;
  onAddEntity: () => void;
  currentSubscription?: Subscription;
};

const tabPaths: Record<number, string> = {
  0: '',
  1: 'segments',
  2: 'visit-data',
};

const TabsBar = ({
  selectedTab,
  onTabChange,
  onImportEntities,
  onAddEntity,
  currentSubscription,
}: TabsBarProps) => {
  const navigate = useNavigate();
  const handleChange = (_: SyntheticEvent, value: number) => {
    onTabChange(value);
    navigate(tabPaths[value]);
  };
  const {
    selectedSegment,
    selectedGroup,
    editDashboardSegmentsModelOpen,
    setEditDashboardSegmentsModelOpen,
    segmentModelOpen,
    setSegmentModelOpen,
    groupModelOpen,
    setGroupModelOpen,
  } = useSegmentStore();

  const tabs = useEntityPageTabs();

  const isManageEntitesShown = currentSubscription
    ? currentSubscription.number_of_allowed_entities > 0
    : false;
  const isManageEntitiesDisabled = currentSubscription
    ? currentSubscription.number_of_consumed_entities >=
      currentSubscription.number_of_allowed_entities
    : true;

  return (
    <Box sx={styles.container}>
      <Tabs value={selectedTab} onChange={handleChange}>
        {Object.entries(tabs).map(([id, message]) => (
          <Tab key={id} label={message} />
        ))}
      </Tabs>
      {selectedTab === 0 && isManageEntitesShown && (
        <ManageEntitiesButtonBlock
          color="white"
          onImportEntities={onImportEntities}
          onAddEntity={onAddEntity}
          disabled={isManageEntitiesDisabled}
        />
      )}
      {selectedTab === 1 && !selectedSegment && (
        <SegmentsActionButtons
          onAddSegment={() => setSegmentModelOpen(!segmentModelOpen)}
          onEditDashboardSegments={() =>
            setEditDashboardSegmentsModelOpen(!editDashboardSegmentsModelOpen)
          }
        />
      )}
      {selectedTab === 1 &&
        selectedSegment &&
        !selectedGroup &&
        isSegmentManageEnabled(selectedSegment) && (
          <AddGroupButton onAddGroup={() => setGroupModelOpen(!groupModelOpen)} />
        )}
    </Box>
  );
};

export default TabsBar;
