import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import type { GridPaginationModel } from '@mui/x-data-grid-pro';
import {
  DEFAULT_PAGE_SIZE,
  useDataGridIntl,
  useLanguageStore,
  useRouteUtils,
} from '@trustyou/shared';
import { type Datapoint, useFetchVisitDatapoints } from '@trustyou/survey-manager';
import {
  Box,
  Button,
  DataGridPro,
  Divider,
  Stack,
  Typography,
  magnifyingGlassSearchingFile,
  personReadingData,
} from '@trustyou/ui';

import { CustomDataPointDrawer } from './CustomDataPointDrawer/CustomDataPointDrawer';
import { CustomToolbar } from './customToolbar';
import { visitDataColumns } from './useColumns';

export const VisitData = () => {
  const intl = useIntl();
  const dataGridIntl = useDataGridIntl();
  const { navigateWithBase } = useRouteUtils();
  const { locale } = useLanguageStore();
  const { data, refetch } = useFetchVisitDatapoints();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const [openDrawer, setOpenDrawer] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedDataPoint, setSelectedDataPoint] = useState<Datapoint | undefined>(undefined);

  return (
    <>
      <Stack sx={{ flexDirection: 'row', marginTop: 5, gap: 3 }}>
        <img src={magnifyingGlassSearchingFile} alt="No rows" />
        <Stack spacing={2}>
          <Box>
            <Typography variant="body1">
              <FormattedMessage
                id="app.settings.visit-data.description"
                defaultMessage="Configure your visit data points and upload visit data for multiple entities at once. The data will be used for defining survey distribution rules, conditional questions, and filtering. "
              />
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1">
              <FormattedMessage
                id="app.settings.visit-data-status"
                defaultMessage="Visit data status"
              />
            </Typography>
            <Typography variant="body1">
              <FormattedMessage
                id="app.settings.visit-data.not-uploaded-yet"
                defaultMessage="You haven’t uploaded any visit data yet"
              />
            </Typography>
          </Box>
          <Stack sx={{ flexDirection: 'row', gap: 2 }}>
            <Button variant="contained" onClick={() => navigateWithBase('visit-data/import')}>
              <FormattedMessage
                id="app.settings.visit-data.upload-csv"
                defaultMessage="Upload CSV"
              />
            </Button>
            <Button variant="text">
              <FormattedMessage
                id="app.settings.visit-data.dowload-template"
                defaultMessage="Download template"
              />
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Divider sx={{ marginBlock: 4 }} />
      <Box>
        <Typography variant="subtitle1">
          <FormattedMessage
            id="app.settings.visit-data-points"
            defaultMessage="Visit data points"
          />
        </Typography>
      </Box>
      <DataGridPro
        columns={[
          ...visitDataColumns({
            editButtonClick: () => {
              setOpenDrawer(true);
              setIsEdit(true);
            },
            intl,
          }),
        ]}
        rows={Array.isArray(data) ? data : []}
        getRowId={(row) => row._id}
        pageSizeOptions={[15, 50, 100]}
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        ignoreDiacritics
        disableRowSelectionOnClick
        slots={{
          toolbar: () =>
            CustomToolbar({
              newDataPointClick: () => {
                setOpenDrawer(true);
                setIsEdit(false);
                setSelectedDataPoint(undefined);
              },
            }),
        }}
        onRowClick={({ row }) => {
          setSelectedDataPoint(row);
        }}
        sx={{
          borderStyle: 'none',
          '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within':
            {
              outline: 'none',
            },
        }}
        density="standard"
        localeText={dataGridIntl[locale].components.MuiDataGrid.defaultProps.localeText}
      />
      <CustomDataPointDrawer
        isOpen={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
          refetch();
        }}
        isEdit={isEdit}
        selectedDataPoint={selectedDataPoint}
      />
    </>
  );
};
