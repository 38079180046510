import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Stack } from '@mui/system';
import { commonFields, useAlertStore } from '@trustyou/shared';
import { Box, Button, ContentPane, TextField, Typography } from '@trustyou/ui';

import { useGetSignature, useLeaveDialog, useSetSignature } from '../../../../hooks';
import type { UserFormData } from '../../../../types';

export const SignatureSettingsForm = () => {
  const intl = useIntl();
  const { alert } = useAlertStore();

  const { data: signature, isPending: isLoading, refetch } = useGetSignature();

  const { mutate: setSignature, isPending: isMutating } = useSetSignature(
    () => {
      alert.success(intl.formatMessage(commonFields.changesSaved));
      refetch();
    },
    () => {
      alert.genericError();
    }
  );

  const {
    reset,
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<UserFormData>({
    mode: 'onChange',
    defaultValues: {
      signature: '',
    },
  });

  const { renderLeaveDialog, canContinue } = useLeaveDialog({ showDialog: isDirty });

  const onSubmit = handleSubmit((data) => {
    const trimmedSignature = data.signature.trim();
    setSignature(trimmedSignature);
    reset({
      signature: trimmedSignature,
    });
  });

  useEffect(() => {
    if (signature) {
      reset({
        signature: JSON.parse(signature) ?? '',
      });
    }
  }, [reset, signature]);

  const onCancel = async () => {
    if (await canContinue()) {
      reset();
    }
  };

  return (
    <ContentPane bodyStyles={{ justifyContent: 'space-between' }}>
      <Box>
        <Typography variant="h6">
          <FormattedMessage id="inbox.settings.signature.title" defaultMessage="Signature" />
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <FormattedMessage
            id="inbox.settings.signature.description"
            defaultMessage="Your signature will be appended at the end of your responses to reviews from inbox"
          />
        </Typography>
      </Box>
      <Stack gap={5} mt={3}>
        <Controller
          name="signature"
          control={control}
          disabled={isLoading || isMutating}
          render={({ field }) => (
            <TextField
              label={intl.formatMessage({
                id: 'inbox.settings.signature.title',
                defaultMessage: 'Signature',
              })}
              multiline
              rows={4}
              sx={{ width: '100%' }}
              {...field}
            />
          )}
        />
        <Stack direction="row" gap={2} justifyContent="flex-end">
          <Button
            disabled={!isDirty}
            onClick={onCancel}
            data-testid="inbox_settings_cancel"
            sx={{ alignSelf: 'end' }}
          >
            <FormattedMessage id="inbox.action.cancel" defaultMessage="Cancel" />
          </Button>
          <Button
            disabled={isLoading || isMutating}
            onClick={onSubmit}
            variant="contained"
            data-testid="inbox_settings_save"
            sx={{ alignSelf: 'end' }}
          >
            <FormattedMessage id="inbox.action.save-changes" defaultMessage="Save changes" />
          </Button>
        </Stack>
      </Stack>

      {renderLeaveDialog({
        title: intl.formatMessage({
          id: 'inbox.settings.dialog.header',
          defaultMessage: 'Discard changes?',
        }),
        content: intl.formatMessage({
          id: 'inbox.settings.dialog.message',
          defaultMessage: 'You have unsaved changes on this page. If you leave, they will be lost.',
        }),
      })}
    </ContentPane>
  );
};
