import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  container: { backgroundColor: 'background.paper' },
  main: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginTop: 2,
  },
};

export default styles;
