import { FormattedMessage } from 'react-intl';

import type { SelectChangeEvent } from '@mui/material';
import { supportedLanguages } from '@trustyou/shared';
import { FormControl, FormHelperText, MenuItem, Select } from '@trustyou/ui';

type LanguageSwitcherProps = {
  value: string;
  onChange: (event: SelectChangeEvent) => void;
};

export const LanguageSwitcher = ({ value, onChange }: LanguageSwitcherProps) => (
  <FormControl>
    <Select data-testid="language-select" value={value} onChange={onChange} size="small">
      {value === 'auto' && (
        <MenuItem key="lang-auto" data-testid="lang-auto" value="auto">
          <FormattedMessage
            id="settings.languageSwitcher.autoDetectOption"
            defaultMessage="Auto-detect browser language"
          />
        </MenuItem>
      )}
      {supportedLanguages.map((language) => (
        <MenuItem key={language.value} data-testid={language.dataTestId} value={language.value}>
          {language.label}
        </MenuItem>
      ))}
    </Select>
    <FormHelperText>
      <FormattedMessage
        id="settings.languageSwitcher.info"
        defaultMessage="English will be used, if your browser language is not supported"
      />
    </FormHelperText>
  </FormControl>
);
