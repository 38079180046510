import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  type IconDefinition,
  faArrowRightFromBracket,
  faUserCircle,
} from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  Box,
  BrandedAvatar,
  Button,
  Divider,
  ICON_BUTTON_HEIGHT,
  ICON_HEIGHT,
  ICON_WIDTH,
  IconButton,
  ListItemIcon,
  MenuItem,
  type MenuItemProps,
  MenuList,
  PopperMenu,
  Stack,
  StyledFontAwesomeIcon,
  Typography,
} from '@trustyou/ui';

export interface UserMenuProps {
  name?: string;
  email?: string;
  organization?: string;
  logo?: string;
  children?: (onClick: () => void) => ReactNode;
  onLogout: () => void;
  navToProfile?: () => void;
}

export const UserMenu = ({
  name,
  email,
  logo,
  children,
  onLogout,
  navToProfile,
}: UserMenuProps) => (
  <PopperMenu
    Trigger={({ onClick }) =>
      logo ? (
        <Button
          data-testid="user-profile-button"
          variant="outlined"
          size="small"
          onClick={onClick}
          sx={{
            padding: 0.75,
            background: (theme) => theme.palette.background.paper,
            border: (theme) => `1px solid ${theme.palette.divider}`,
            '&:hover': {
              background: (theme) => theme.palette.background.paper,
              border: (theme) => `1px solid ${theme.palette.divider}`,
            },
          }}
        >
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            <Box
              component="img"
              src={logo}
              alt="Organization logo"
              sx={{
                maxHeight: '38px',
                maxWidth: '300px',
                width: 'fit-content',
              }}
            />
            <BrandedAvatar name={name} />
          </Stack>
        </Button>
      ) : (
        <IconButton data-testid="user-profile-button" onClick={onClick} sx={{ padding: 0 }}>
          <BrandedAvatar name={name} />
        </IconButton>
      )
    }
    Content={({ onClick }) => (
      <>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            padding: 2,
            alignItems: 'center',
            minWidth: '248px',
            maxWidth: '320px',
          }}
        >
          <BrandedAvatar name={name} />
          <Stack
            sx={{
              flex: 1,
              overflow: 'hidden',
              '& > p, & > span': {
                overflowWrap: 'break-word',
              },
            }}
          >
            <Typography variant="body1">{name || email}</Typography>
            {name && email && (
              <Typography variant="caption" color="text.secondary">
                {email}
              </Typography>
            )}
          </Stack>
        </Stack>
        {children && (
          <>
            <Divider />
            {children(onClick)}
          </>
        )}
        <Divider />
        <MenuList>
          <CustomMenuItem
            id="profile-menu-item"
            icon={faUserCircle}
            text={<FormattedMessage id="navigation.settings.profile" defaultMessage="Profile" />}
            onClick={() => {
              onClick();
              navToProfile?.();
            }}
          />
          <CustomMenuItem
            id="logout-menu-item"
            icon={faArrowRightFromBracket}
            text={<FormattedMessage id="tyDesign.userMenu.logout" defaultMessage="Logout" />}
            onClick={() => {
              onClick();
              onLogout();
            }}
          />
        </MenuList>
      </>
    )}
  />
);

function CustomMenuItem({
  id,
  icon,
  text,
  onClick,
}: {
  id: string;
  icon: IconDefinition;
  text: ReactNode;
  onClick: MenuItemProps['onClick'];
}) {
  return (
    <MenuItem
      onClick={onClick}
      data-testid={`${id}-list-item-button`}
      sx={{ height: ICON_BUTTON_HEIGHT }}
    >
      <ListItemIcon>
        <StyledFontAwesomeIcon
          icon={icon}
          sx={{
            height: ICON_HEIGHT,
            width: ICON_WIDTH,
          }}
        />
      </ListItemIcon>
      <Typography variant="body2">{text}</Typography>
    </MenuItem>
  );
}
