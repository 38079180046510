import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  formHeader: { paddingInline: 3 },
  formContent: { paddingX: 3, paddingY: 2 },
  formFooter: { boxShadow: 5 },
  deleteButton: { marginRight: 'auto' },
};

export default styles;
