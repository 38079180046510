import { FormattedMessage } from 'react-intl';

import { Box, Typography } from '@trustyou/ui';

import magnifyingGlassSearchingFile from '../../assets/illustrations/magnifying-glass-searching-file.svg';
import styles from './styles';

type EmptyReviewProviderDetailsProps = {
  reviewProviderName: string;
};

export const EmptyReviewProviderDetails = ({
  reviewProviderName,
}: EmptyReviewProviderDetailsProps) => (
  <Box sx={styles.noRowsWrapper}>
    <img src={magnifyingGlassSearchingFile} alt="No rows" />
    <Box sx={styles.noRowsTextWrapper}>
      <Typography variant="h6">
        <FormattedMessage
          id="reviewProviderDetails.noRows.title"
          defaultMessage="No connected entity yet"
        />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage
          id="reviewProviderDetails.noRows.description"
          defaultMessage="Make sure you’ve verified and published your business profile on {reviewProviderName}"
          values={{ reviewProviderName }}
        />
      </Typography>
    </Box>
  </Box>
);
