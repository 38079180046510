import { useStore } from '../store/store';
import type { ReviewRoot } from '../types';

export function useSurroundingReviewIds(currentId: string) {
  const reviewsList = useStore.use.reviewsList();

  const currentIndex = reviewsList.findIndex(({ id }) => id === currentId);
  const previousIndex = currentIndex - 1;
  const nextIndex = currentIndex + 1;
  const prevReviewId = previousIndex === -1 ? undefined : reviewsList.at(previousIndex)?.id;
  const nextReviewId = reviewsList.at(nextIndex)?.id;

  return { prevReviewId, nextReviewId };
}

export function isDeletionPendingOrRequested(deletion: ReviewRoot['deletion']) {
  return deletion?.status === 'pending' || deletion?.status === 'requested';
}

export function isDeletionConfirmed(deletion: ReviewRoot['deletion']) {
  return deletion?.status === 'confirmed';
}
