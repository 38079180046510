import { useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';

import type { StepperProps } from '@mui/material';
import {
  type CreateSegmentResponse,
  type Segment,
  type SegmentGroupPayload,
  useAlertStore,
  useCreateSegment,
  useMembershipStore,
} from '@trustyou/shared';
import { BackdropSpinner, Box, FullScreenModal, SetupStepper } from '@trustyou/ui';

import AddSegmentsForm from './AddSegmentsForm';

import { DashboardDelay } from '../../../../../components';
import { UpdateGroupsForm } from '../Groups/UpdateGroups';
import styles from './styles';

type AddSegmentsProps = {
  onClose: () => void;
  onSegmentCreated: (data: CreateSegmentResponse) => void;
};

const steps = defineMessages({
  1: {
    id: 'segment.create.add.name',
    defaultMessage: 'Name segment',
  },
  2: {
    id: 'segment.create.add.group',
    defaultMessage: 'Add group',
  },
});

const AddSegments = ({ onClose, onSegmentCreated }: AddSegmentsProps) => {
  const [activeStep, setActiveStep] = useState<StepperProps['activeStep']>(0);
  const [segment, setSegment] = useState<Partial<Segment>>();
  const { membership } = useMembershipStore();
  const { alert } = useAlertStore();
  const { mutate: createSegment, isPending: isCreating } = useCreateSegment(
    (data) => {
      alert.success(
        <FormattedMessage
          id="organization.segments.created"
          defaultMessage="{segment} added. {delay}."
          values={{ segment: data.name, delay: <DashboardDelay /> }}
        />
      );
      onSegmentCreated(data);
      onClose();
    },
    () => {
      alert.genericError();
    }
  );

  const goBack = () => {
    setActiveStep(0);
  };

  const onNameSave = (value: Partial<Segment>) => {
    setSegment(value);
    setActiveStep(1);
  };

  const onSave = (data: SegmentGroupPayload) => {
    createSegment({
      organization_id: membership?.organizationId as string,
      name: segment?.name as string,
      groups: [data],
    });
  };

  return (
    <FullScreenModal open onClose={onClose}>
      <SetupStepper sx={styles.stepper} steps={steps} activeStep={activeStep} />
      <Box sx={styles.container}>
        {activeStep === 0 && <AddSegmentsForm segment={segment} onSave={onNameSave} />}
        {activeStep === 1 && (
          <UpdateGroupsForm
            segment={segment}
            onSave={onSave}
            onGoBack={goBack}
            dataName="add-segment"
          />
        )}
      </Box>
      <BackdropSpinner isLoading={isCreating} />
    </FullScreenModal>
  );
};

export default AddSegments;
