import { type ReactNode, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  type SelectChangeEvent,
  type SxProps,
  type Theme,
  capitalize,
  styled,
} from '@mui/material';
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  type SelectProps,
} from '@trustyou/ui';

import type { Option } from '../../../types';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export type DropdownMultipleOption = {
  label: string;
  value?: string;
  children?: Option[];
};

const getFlatData = (
  data: DropdownMultipleOption[]
): (DropdownMultipleOption | { label: string; value: string })[] => {
  const labelValueObjectArray = data
    .flatMap((item) => [item, ...(item.children ? getFlatData(item.children) : [])])
    .filter((item) => item.value);
  return labelValueObjectArray;
};

const getFlatValues = (arr: DropdownMultipleOption[]): string[] =>
  getFlatData(arr).map((item) => item.value) as string[];

const getLabelValueMapping = (
  data: DropdownMultipleOption[]
): {
  [key: string]: string;
} => {
  const flatDataArray = getFlatData(data) as { label: string; value: string }[];

  const labelValueMapping: { [key: string]: string } = {};
  flatDataArray.forEach(
    (item) =>
      (labelValueMapping[item.value] = item.label.replaceAll(/[\\(\\（].*[\\)\\）]/gi, '').trim())
  );
  return labelValueMapping;
};

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: theme.spacing(0.5),
  marginInlineStart: theme.spacing(-0.5),
  marginInlineEnd: theme.spacing(1),
}));

export type DropdownMultipleProps = {
  fieldName: string;
  label: string;
  allValuesLabel: string;
  options: DropdownMultipleOption[];
  disabled?: boolean;
  initialSelectedValues?: string[];
  dataTestId?: string;
  showLabelAsResult?: boolean;
  capitalizeMenuItems?: boolean;
  size?: SelectProps['size'];
  fullWidth?: SelectProps['fullWidth'];
  sx?: SxProps<Theme>;
};

export const DropdownMultiple = ({
  fieldName,
  label,
  allValuesLabel,
  options,
  disabled,
  initialSelectedValues = [],
  dataTestId,
  showLabelAsResult = false,
  capitalizeMenuItems = false,
  size = 'medium',
  fullWidth = false,
  sx = {},
}: DropdownMultipleProps) => {
  const [selectedValues, setSelectedValues] = useState(initialSelectedValues);
  const flatAvailableValues = getFlatValues(options);
  const labelValueMapping = getLabelValueMapping(options);
  const isAllSelected = selectedValues.length === flatAvailableValues.length;
  const fieldSlug = fieldName.toLowerCase().replaceAll('_', '-');
  const { register, setValue } = useFormContext();

  useEffect(() => {
    // special fields: the backend understands "all options" by sending the special option with the value 'all' (example: the 'source' field)
    if (initialSelectedValues.length === 1 && initialSelectedValues.at(0) === 'all') {
      setSelectedValues(flatAvailableValues);
      return;
    }
    // rest of fields: the backend understands "all options" by sending explicitly all the options (example: the 'status' field)
    setSelectedValues(initialSelectedValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderListItemText = (label: string) => (capitalizeMenuItems ? capitalize(label) : label);

  const renderValue = (value: string[]): ReactNode => {
    if (value.includes('all') || value.length === flatAvailableValues.length) {
      return allValuesLabel;
    }

    return value
      .map((name) => {
        const nameToRender = capitalizeMenuItems ? capitalize(name) : name;
        return showLabelAsResult ? labelValueMapping[name] : nameToRender;
      })
      .join(', ');
  };

  const handleChange = ({ target: { value } }: SelectChangeEvent<string[]>) => {
    // guard clause for undefined value of the "fake" first option to select all options
    if (typeof value !== 'string') return;
    setSelectedValues(value.split(','));
  };

  const toggleAll = () => {
    if (isAllSelected) {
      setSelectedValues([]);
    } else {
      setSelectedValues(flatAvailableValues);
    }
  };

  const everyChildrenIsChecked = (children: DropdownMultipleOption['children'] = []) =>
    children.every((child) => selectedValues.includes(child.value));

  const someChildrenAreChecked = (children: DropdownMultipleOption['children'] = []) =>
    children.some((child) => selectedValues.includes(child.value));

  const handleClickOnFakeParentCheckbox = (children: DropdownMultipleOption['children'] = []) => {
    let values: string[] = [];
    if (children.some(({ value }) => selectedValues.includes(value))) {
      values = selectedValues.filter((item) => !children.find(({ value }) => item === value));
    } else {
      values = [...selectedValues, ...children.map(({ value }) => value)];
    }
    setSelectedValues(values);
    setValue(fieldName, values, { shouldDirty: true });
  };

  const handleClickOnRealOption = (value: string) => {
    let values: string[] = [];
    if (selectedValues.includes(value)) {
      values = selectedValues.filter((item) => item !== value);
    } else {
      values = [...selectedValues, value];
    }
    setSelectedValues(values);
    setValue(fieldName, values, { shouldDirty: true });
  };

  return (
    <Box data-testid={dataTestId} sx={sx}>
      <FormControl fullWidth={fullWidth}>
        <InputLabel id={`dropdown-multiple-label-${fieldSlug}`}>{label}</InputLabel>
        <Select
          id={`dropdown-multiple-${fieldSlug}`}
          labelId={`dropdown-multiple-label-${fieldSlug}`}
          size={size}
          name={fieldName}
          input={<OutlinedInput label={label} inputProps={{ ...register(fieldName) }} />}
          value={selectedValues}
          onChange={handleChange}
          renderValue={renderValue}
          MenuProps={{
            autoFocus: false,
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
                zIndex: 1,
              },
            },
            sx: { maxWidth: 220 },
          }}
          disabled={disabled}
          multiple
        >
          <MenuItem
            onClick={toggleAll}
            sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
            dense
          >
            <StyledCheckbox checked={isAllSelected} />
            <ListItemText primary={allValuesLabel} />
          </MenuItem>
          {options.map(({ value, label, children }) => {
            return children ? (
              <Box key={label}>
                <MenuItem
                  selected={everyChildrenIsChecked(children)}
                  onClick={() => handleClickOnFakeParentCheckbox(children)}
                  dense
                >
                  <StyledCheckbox
                    checked={everyChildrenIsChecked(children)}
                    indeterminate={!everyChildrenIsChecked && someChildrenAreChecked(children)}
                  />
                  <ListItemText primary={renderListItemText(label)} />
                </MenuItem>
                {children.map(({ label, value }) => (
                  <MenuItem
                    value={value}
                    key={label}
                    sx={{ pl: 5 }}
                    selected={selectedValues.includes(value)}
                    onClick={() => handleClickOnRealOption(value)}
                    dense
                  >
                    <StyledCheckbox checked={selectedValues.includes(value)} />
                    <ListItemText primary={renderListItemText(label)} />
                  </MenuItem>
                ))}
              </Box>
            ) : (
              <MenuItem
                value={value}
                key={label}
                onClick={() => handleClickOnRealOption(value as string)}
                dense
              >
                <StyledCheckbox checked={selectedValues.includes(value as string)} />
                <ListItemText primary={renderListItemText(label)} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};
