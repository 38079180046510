import { ReviewsDialog } from './reviews-dialog';
import { MOCK_REVIEWS } from './reviews.mock';

const message = {
  type: 'OPEN_DIALOG',
  // path: 'inbox',
  payload: {
    data: MOCK_REVIEWS,
  },
};

export const FakeSisenseIframe = () => {
  // Convert the `message` object to string and embed it directly in the iframeHtml
  const iframeHtml = `
    <html>
      <body>
        <h1>Fake Sisense iframe</h1>
        <button id="sendMessageButton">Send message to parent HTML element</button>
        <script>
          document.getElementById('sendMessageButton').onclick = function() {
            console.log('POC sending message', ${JSON.stringify(message)});
            window.parent.postMessage(${JSON.stringify(message)}, '*');
          };
        </script>
      </body>
    </html>
  `;

  return (
    <>
      <iframe title="test-iframe" srcDoc={iframeHtml} style={{ width: '100%', height: '300px' }} />
      <ReviewsDialog />
    </>
  );
};
