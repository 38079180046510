import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Chip } from '@trustyou/ui';

import { newReportWizardMessages } from '../../../../constants/messages';
import { useNewReportStore } from '../../../../store';

export const CountChip = () => {
  const { newReport } = useNewReportStore();
  return (
    <Chip
      label={
        <FormattedMessage
          {...newReportWizardMessages.widgetsSelectedCount}
          values={{ count: newReport?.widgets?.length || 0 }}
        />
      }
      size="small"
      variant="pastelInfo"
    />
  );
};
