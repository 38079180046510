import { grey } from '@mui/material/colors';
import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  mandatoryFieldsHeader: {
    marginTop: 5,
    marginBottom: 2,
  },
  table: {
    overflowX: 'auto',
    width: '100%',
    '& .MuiDataGrid-row:hover': {
      backgroundColor: 'inherit',
    },
    borderBottom: '1px solid #E0E0E0',
    borderColor: grey[300],
    borderRadius: 0,
  },
};

export default styles;
