import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useScopeStore } from '@trustyou/shared';
import { Box, Dialog } from '@trustyou/ui';

import { EditorContent } from '../../../components/shared/editor-content';
import { EditorFooter } from '../../../components/shared/editor-footer';
import { EditorHeader } from '../../../components/shared/editor-header';
import { EditorSideNav } from '../../../components/shared/editor-side-nav';
import styles from '../../../components/shared/styles';
import { useThemeEditorSteps } from '../../../components/theme/editor/use-steps';
import { useStore } from '../../../store/store';

export function ThemeEditor() {
  const navigate = useNavigate();
  const editorStartingPoint = useStore.use.themeEditorStartingPoint();

  // dialog
  const [open, setOpen] = useState(true);
  const closeEditor = () => {
    setOpen(false);
    navigate('theme/overview');
  };

  // stepper
  const steps = useThemeEditorSteps();
  const [activeStep, setActiveStep] = useState(0);
  const goToNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const goToPreviousStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const hasPreviousStep = steps[activeStep - 1] !== undefined;
  const hasNextStep = steps[activeStep + 1] !== undefined;

  // global state
  const { selectedEntities, selectedGroups, isValid } = useScopeStore();

  // editor
  const saveDraft = () => {
    // TODO:
    alert('to be implemented: save draft');
  };
  const publish = () => {
    navigate(`${'MOCK_QUESTIONNAIRE_ID'}/detail`);
    // TODO:
    alert(
      `to be implemented: publish \n\n ${JSON.stringify({
        selectedEntities,
        selectedGroups,
      })}`
    );
  };

  return (
    <Dialog fullScreen open={open} onClose={closeEditor}>
      <Box sx={styles.editorMain}>
        <EditorHeader
          title={
            editorStartingPoint === 'new-theme' ? (
              <FormattedMessage id="survey.theme-editor.new-theme" defaultMessage="New Theme" />
            ) : editorStartingPoint === 'new-theme-version' ? (
              <FormattedMessage
                id="survey.editor.new-version-for"
                defaultMessage="New version for {editorTitle}"
                values={{ editorTitle: '[Name of theme]' }}
              />
            ) : editorStartingPoint === 'edit-theme' ? (
              <FormattedMessage
                id="survey.theme-editor.edit.title"
                defaultMessage="Editing {themeName}"
                values={{ themeName: '[Name of theme]' }}
              />
            ) : (
              ''
            )
          }
          close={closeEditor}
          save={saveDraft}
        />
        <EditorContent
          sideNavigator={
            <EditorSideNav activeStep={activeStep} setActiveStep={setActiveStep} steps={steps} />
          }
          content={steps[activeStep].content}
          contentExtraStyles={{ ...(activeStep === 3 && styles.editorContentExtraStyles) }}
          previewExtraStyles={{ ...(activeStep === 3 && styles.editorPreviewExtraStyles) }}
        />
        <EditorFooter
          hasPreviousStep={hasPreviousStep}
          hasNextStep={hasNextStep}
          nextStepLabel={steps[activeStep + 1]?.label}
          previousStepLabel={steps[activeStep - 1]?.label}
          goToPreviousStep={goToPreviousStep}
          goToNextStep={goToNextStep}
          publish={publish}
          disablePublishButton={!isValid()}
          extraMainStyles={{ ...(activeStep === 3 && styles.editorFooterExtraStyles) }}
        />
      </Box>
    </Dialog>
  );
}
