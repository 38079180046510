import type { GridSortModel } from '@mui/x-data-grid-pro';
import {
  EXPORT_OWNED_ENTITIES,
  downloadCSV,
  getSortRequest,
  toShortDate,
  useAlertStore,
  useExportEntities,
  usePermissionStore,
} from '@trustyou/shared';

const useHandleExportEntities = (
  sortModel: GridSortModel,
  searchKey: string | undefined,
  groupId: string | undefined
) => {
  const { mutate: exportCSV, isPending: isLoading } = useExportEntities();
  const { hasPermissions } = usePermissionStore();
  const { alert } = useAlertStore();
  const isExportAllowed = hasPermissions(EXPORT_OWNED_ENTITIES);

  if (!isExportAllowed) return undefined;

  const onExport = () => {
    exportCSV(
      { sortKey: getSortRequest(sortModel), searchKey, groupId },
      {
        onSuccess: (content: string) => {
          downloadCSV(`Entities_${toShortDate(new Date())}.csv`, content);
        },
        onError: () => {
          alert.genericError();
        },
      }
    );
  };

  return { onExport, isLoading };
};

export default useHandleExportEntities;
