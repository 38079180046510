import { FormattedMessage } from 'react-intl';

import { Backdrop, Button, Card, CardActions, CardContent, Stack, Typography } from '@trustyou/ui';

type ToneComparisonProps = {
  isModalOpen: boolean;
  setModalOpen: (value: boolean) => void;
};

export const ToneComparison = ({ isModalOpen, setModalOpen }: ToneComparisonProps) => {
  const closeHandler = () => {
    setModalOpen(false);
  };
  return (
    <Backdrop
      data-testid="tone-comparison"
      sx={{
        color: (theme) => theme.palette.background.paper,
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isModalOpen}
      onClick={closeHandler}
    >
      <Card sx={{ maxWidth: 832, borderRadius: 1 }}>
        <Stack
          direction="row"
          spacing={3}
          sx={{ p: 3, color: (theme) => theme.palette.text.primary }}
        >
          <CardContent
            sx={{
              p: 2,
              maxWidth: 380,
            }}
          >
            <Typography variant="h6" sx={{ lineHeight: '32px', letterSpacing: 0.15 }}>
              <FormattedMessage
                id="inbox.settings.toneComparison.formalToneHeader"
                defaultMessage="Formal tone"
              />
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              <FormattedMessage
                id="inbox.settings.toneComparison.formalToneAppeal"
                defaultMessage="Dear Customer,"
              />
            </Typography>
            <Typography variant="body1">
              <FormattedMessage
                id="inbox.settings.toneComparison.formalToneContent"
                defaultMessage="Wonderful to know that you enjoyed the view from your room. However, we were sorry to hear that the room did not meet your expectations. Your feedback is invaluable to us and we hope to serve you even better in the future."
              />
            </Typography>
            <Typography sx={{ mt: 2 }}>
              <FormattedMessage
                id="inbox.settings.toneComparison.formalToneRegards"
                defaultMessage="Best regards,"
              />
            </Typography>
            <Typography>
              <FormattedMessage
                id="inbox.settings.toneComparison.formalToneName"
                defaultMessage="[Your Name]"
              />
            </Typography>
          </CardContent>

          <CardContent sx={{ p: 2, maxWidth: 380 }}>
            <Typography variant="h6" sx={{ lineHeight: '32px', letterSpacing: 0.15 }}>
              <FormattedMessage
                id="inbox.settings.toneComparison.casualToneHeader"
                defaultMessage="Casual tone"
              />
            </Typography>
            <Typography sx={{ mt: 2 }}>
              <FormattedMessage
                id="inbox.settings.toneComparison.casualToneContent"
                defaultMessage="Welcome! We are sorry to hear that the room did not meet your expectations. We are glad you enjoyed the view. We are working to provide you with a unique and unforgettable experience and hope to see you again soon!"
              />
            </Typography>
          </CardContent>
        </Stack>

        <CardActions sx={{ height: 52, p: 1, float: 'right' }}>
          <Button
            variant="text"
            size="medium"
            onClick={closeHandler}
            sx={{ fontWeight: 500, lineHeight: '24px' }}
          >
            <FormattedMessage id="inbox.action.close" defaultMessage="Close" />
          </Button>
        </CardActions>
      </Card>
    </Backdrop>
  );
};
