import { type ReactNode, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { SxProps, Theme } from '@mui/material';
import { faCheck, faCopy } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Box, Button, Typography, useTheme } from '@trustyou/ui';

import styles from './styles';

// RegEx to remove empty lines due to params not passed on code string (see widgetConfigurator component)
export const removeEmptySpace = (code: string) => code.replace(/^\s*\n/gm, '');

type CodeBlockProps = {
  sx?: SxProps<Theme>;
  isFullWidth?: boolean;
  onCopy?: () => void;
  children: ReactNode;
};

export const CodeBlock = ({
  children,
  sx,
  isFullWidth = false,
  onCopy,
  ...props
}: CodeBlockProps) => {
  const [hover, setHover] = useState(false);
  const [codeCopied, setCodeCopied] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (codeCopied) {
      timer = setTimeout(() => {
        setCodeCopied(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [codeCopied]);

  const theme = useTheme();

  let style: SxProps<Theme> = styles(theme).codeBlock;
  if (isFullWidth) {
    style = {
      ...style,
      width: '100%',
    };
  }

  style = { ...style, ...sx };

  const onCopyHandler = () => {
    onCopy && onCopy();
    setCodeCopied(true);
  };

  const onMouseEnterHandler = () => {
    setHover(true);
  };

  const onMouseLeaveHandler = () => {
    setHover(false);
  };

  return (
    <CopyToClipboard text={removeEmptySpace(children as string)} onCopy={onCopyHandler}>
      <Button
        data-testid="codeBlock"
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
        sx={style}
        {...props}
      >
        <Box component="pre" sx={styles(theme).preBox}>
          <code data-testid="code">{removeEmptySpace(children as string)}</code>
        </Box>
        {hover && (
          <Box data-testid="hoverBox" sx={styles(theme).hoverBox}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                aria-label="delete"
                sx={{
                  ...styles(theme).icon,
                  color: !codeCopied ? theme.palette.primary.contrastText : '#81c784',
                }}
              >
                <FontAwesomeIcon
                  data-testid="icon"
                  icon={!codeCopied ? faCopy : faCheck}
                  size="xs"
                />
              </Box>
              <Typography data-testid="text" color="text.white">
                {!codeCopied
                  ? intl.formatMessage({
                      id: 'common.copy.click',
                      defaultMessage: 'Click to copy',
                    })
                  : intl.formatMessage({
                      id: 'common.copy.done',
                      defaultMessage: 'Copied to clipboard',
                    })}
              </Typography>
            </Box>
          </Box>
        )}
      </Button>
    </CopyToClipboard>
  );
};
