import { useState } from 'react';
import { useController } from 'react-hook-form';

import { Checkbox, FormControlLabel, FormGroup } from '@trustyou/ui';

import type { SelectQuestionProps } from '../question-types/select/select-question';
import type { Option } from '../types';

export type CheckboxGroupProps = SelectQuestionProps;

export function CheckboxGroup({ name, control, options }: CheckboxGroupProps) {
  const { field } = useController({ name, control });
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(field.value ?? []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const clickedOption = options.find((option) => option.value === event.target.name)!;
    const isChecked = event.target.checked;
    let newSelectedOptions: Option[];
    if (isChecked) {
      newSelectedOptions = [...selectedOptions, clickedOption];
    } else {
      const indexToRemove = selectedOptions.findIndex(
        (option) => option.value === event.target.name
      );
      newSelectedOptions = selectedOptions.toSpliced(indexToRemove, 1);
    }
    field.onChange(newSelectedOptions.map((option) => option.value));
    setSelectedOptions(newSelectedOptions);
  };

  return (
    <FormGroup sx={{ alignItems: 'start' }}>
      {options.map((option) => (
        <FormControlLabel
          key={option.value.toString()}
          control={
            <Checkbox
              checked={selectedOptions.map((o) => o.value).includes(option.value)}
              onChange={handleChange}
              name={option.value as string}
            />
          }
          label={option.label}
        />
      ))}
    </FormGroup>
  );
}
