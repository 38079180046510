import { FormattedMessage } from 'react-intl';

import { Button } from '@trustyou/ui';

import styles from './styles';

type CsvDownloadProps = {
  onDownload: () => void;
};

const CsvDownload = ({ onDownload }: CsvDownloadProps) => (
  <Button
    data-testid="download-setup-sheet"
    variant="contained"
    onClick={onDownload}
    color="secondary"
    size="large"
    sx={styles.button}
  >
    <FormattedMessage
      id="entitiesImporter.action.downloadSpreadsheet"
      defaultMessage="Download spreadsheet"
    />
  </Button>
);

export default CsvDownload;
