import { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { faGaugeSimpleHigh } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { faCaretDown } from '@trustyou/fortawesome/pro-solid-svg-icons';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  StyledFontAwesomeIcon as Icon,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@trustyou/ui';

import { useManageSegments } from '../../../../../hooks';
import styles from './styles';

type SegmentsActionButtonsProps = {
  onAddSegment: () => void;
  onEditDashboardSegments: () => void;
};

const SegmentsActionButtons = ({
  onAddSegment,
  onEditDashboardSegments,
}: SegmentsActionButtonsProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const editDashboardSegmentsHandle = () => {
    onEditDashboardSegments();
    setMenuOpen(false);
  };

  const isManageSegmentAllowed = useManageSegments();

  if (!isManageSegmentAllowed) return null;

  return (
    <>
      <ButtonGroup variant="contained" sx={styles.buttonGroup} ref={anchorRef}>
        <Button
          data-testid="add-segment"
          data-gtm-class="action-button"
          variant="outlined"
          sx={styles.firstButton}
          size="medium"
          onClick={onAddSegment}
        >
          <FormattedMessage id="organization.action.addSegment" defaultMessage="Add segment" />
        </Button>
        <Button
          data-testid="edit-dashboard-segment"
          variant="outlined"
          onClick={() => setMenuOpen(true)}
        >
          <Icon icon={faCaretDown} />
        </Button>
      </ButtonGroup>
      <Popper
        sx={styles.popper}
        open={menuOpen}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper variant="elevation" elevation={8} square={false}>
              <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                <MenuList autoFocusItem>
                  <MenuItem
                    data-testid="edit-dashboard-segment-menu"
                    onClick={editDashboardSegmentsHandle}
                  >
                    <ListItemIcon>
                      <Icon icon={faGaugeSimpleHigh} />
                    </ListItemIcon>
                    <ListItemText>
                      <FormattedMessage
                        id="organization.segments.editDashboardSegments"
                        defaultMessage="Edit dashboard segments"
                      />
                    </ListItemText>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default SegmentsActionButtons;
