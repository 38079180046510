import { FormattedMessage } from 'react-intl';

import type { StyleUnit } from '@trustyou/shared';
import { EmptyStatePlaceholder } from '@trustyou/ui';

import personSelectingData from '../../assets/illustrations/person-selecting-data.svg';
import styles from './styles';

export type EmptyEntitySubscriptionsProps = {
  sx?: StyleUnit;
};

export const EmptyEntitySubscriptions = ({ sx }: EmptyEntitySubscriptionsProps) => (
  <EmptyStatePlaceholder
    img={personSelectingData}
    title={
      <FormattedMessage
        id="tyDesign.emptyState.noEntitySubscriptionsYet"
        defaultMessage="No entity subscriptions yet"
      />
    }
    description={
      <FormattedMessage
        id="tyDesign.emptyState.noEntitySubscriptionsDescription"
        defaultMessage="It seems that no entity subscriptions were added. When you purchase entity subscriptions, they will show up here."
      />
    }
    emptyStateBody={{ textAlign: 'center' }}
    emptyStateHeader={styles.emptyStateHeader}
    emptyStateContainer={{ ...styles.emptyStateContainer, ...styles.noDataContainer, ...sx }}
  />
);
