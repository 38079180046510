import { styled } from '@mui/system';

import { CustomTooltip } from './custom-tooltip';

const StyledLink = styled('a')(({ theme }) => ({
  fontFamily: 'sans-serif',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '13px',
  lineHeight: '18px',
  letterSpacing: '0.16px',
  color: theme.palette.primary.main,
  cursor: 'pointer',
  marginRight: theme.spacing(0.25),
  textDecoration: 'none',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  maxWidth: '100px',
  display: 'block',
}));

export type CustomTooltipLinkProps = {
  label: string;
  href: string;
};

export const CustomTooltipLink = ({ label, href }: CustomTooltipLinkProps) => (
  <StyledLink href={href} target="_blank" rel="noreferrer" onClick={(e) => e.stopPropagation()}>
    <CustomTooltip title={label}>{label}</CustomTooltip>
  </StyledLink>
);
