import React from 'react';
import { FormattedMessage } from 'react-intl';

import { faCheck } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Box, Button, StyledFontAwesomeIcon, Typography } from '@trustyou/ui';

import styles from './styles';

export interface FinalStepProps {
  backToReviewProviders: () => void;
}

const FinalStep = ({ backToReviewProviders }: FinalStepProps) => {
  return (
    <Box sx={styles.contentWrapper}>
      <Box sx={styles.successIcon}>
        <StyledFontAwesomeIcon size="2x" icon={faCheck} />
      </Box>
      <Typography variant="h6">
        <FormattedMessage
          id="review-providers.booking.connectForm.step3.header"
          defaultMessage="All good!"
        />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage
          id="review-providers.booking.connectForm.step3.description"
          defaultMessage="We’re setting up your API connection, and this process could take up to 48 hours."
        />
      </Typography>
      <Button
        data-testid="booking-back-to-review-providers"
        onClick={backToReviewProviders}
        variant="contained"
      >
        <FormattedMessage
          id="review-providers.booking.connectForm.step3.buttonText"
          defaultMessage="Back to review providers"
        />
      </Button>
    </Box>
  );
};

export default FinalStep;
