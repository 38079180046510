import { useOidc } from '@axa-fr/react-oidc';
import {
  AuthPendingState,
  useAuthorizationStore,
  useChangelingStore,
  useLogout,
  useMembershipStore,
} from '@trustyou/shared';

const useCustomersLogout = () => {
  const { logout } = useOidc();
  const { setPendingState, setTokenStore, tokenStore } = useAuthorizationStore();
  const { clearStore, getMembershipsCount } = useMembershipStore();
  const { clearChangelingState, isChangeling } = useChangelingStore();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSuccessHandler = () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onErrorHandler = (error: Error) => {};

  const onSettled = async () => {
    clearChangelingState();
    clearStore();
    setTokenStore(null);
    // Keycloak logout
    logout(`${process.env.PUBLIC_URL}`);
  };

  const { mutate: logoutSisense } = useLogout({
    onSuccess: onSuccessHandler,
    onError: onErrorHandler,
    onSettled,
  });

  const onLogoutClickHandler = async () => {
    setPendingState(AuthPendingState.LOGGING_OUT);
    if (
      /* Disabling logout call in case of localhost with REACT_APP_AUTH = false in .env
         (to make all routes public actually disabling authentication in development) */
      ['localhost', '127.0.0.1'].includes(window.location.hostname) &&
      process.env.REACT_APP_AUTH === 'false'
    ) {
      console.warn('Auth disabled on localhost via REACT_APP_AUTH env');
      return;
    }
    const isStopChangelingMode = isChangeling && !tokenStore;
    const isNoOrganizationUser = getMembershipsCount() === 0 && !tokenStore;
    if (isStopChangelingMode || isNoOrganizationUser) {
      onSettled();
    } else {
      // Sisense SSO logout
      logoutSisense();
    }
  };

  return onLogoutClickHandler;
};

export default useCustomersLogout;
