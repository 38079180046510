export const formatTotal = (total?: number): string => {
  const billion = 1000000000;
  const million = 1000000;
  const thousand = 1000;

  if (!total) return '0';
  if (total > billion) return `${(total / billion).toFixed(1)}b`;
  if (total > million) return `${(total / million).toFixed(1)}m`;
  if (total > thousand) return `${(total / thousand).toFixed(1)}k`;

  return total.toString();
};
