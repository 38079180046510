import { FormattedMessage } from 'react-intl';

import type { GridColDef } from '@mui/x-data-grid-pro';
import { DataGrid, Typography } from '@trustyou/ui';

import { ExplanationModal } from '../ExplanationModal/ExplanationModal';

const tableColumns: GridColDef[] = [
  {
    field: 'stars',
    headerName: 'Stars',
    flex: 1,
    sortable: false,
  },
  {
    field: 'propertyId',
    headerName: 'Property ID',
    flex: 1,
    sortable: false,
  },
  {
    field: 'region',
    headerName: 'Region',
    flex: 1,
    sortable: false,
  },
];

const tableRows = [
  {
    id: 1,
    stars: '1 stars',
    propertyId: 'P001',
    region: 'Central Europe',
  },
  {
    id: 2,
    stars: '2 stars',
    propertyId: 'P002',
    region: 'East Europe',
  },
];

export interface WhatIsCustomAttributeProps {
  open: boolean;
  onClose: () => void;
}

export const WhatIsCustomAttribute = ({ open, onClose }: WhatIsCustomAttributeProps) => (
  <ExplanationModal
    open={open}
    onClose={onClose}
    dataName="what-is-custom-attribute"
    title={
      <FormattedMessage
        id="tyDesign.whatIsCustomAttributeTitle"
        defaultMessage="What is custom attribute?"
      />
    }
    buttonText={<FormattedMessage id="tyDesign.whatIsSectorButton" defaultMessage="Got it" />}
    points={[]}
  >
    <Typography variant="body2">
      <FormattedMessage
        id="tyDesign.whatIsCustomAttributeContent"
        defaultMessage="To better suit your organization's needs, create a custom attribute, such as stars or property ID. Custom attributes allow you to segment your data and manage user access. When defining the custom attribute, consider which values can be assigned to it."
      />
    </Typography>
    <Typography variant="body2" marginTop={4}>
      <FormattedMessage id="tyDesign.forExample" defaultMessage="For example:" />
    </Typography>
    <DataGrid columns={tableColumns} rows={tableRows} disableColumnReorder />
  </ExplanationModal>
);
