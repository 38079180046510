import type {
  BookingProviderAccount,
  GoogleProviderAccount,
  ProviderAccount,
  RedirectOptions,
  Subscription,
  SubscriptionShort,
} from '@trustyou/shared';

import { CONNECT_PROVIDER_NAMES } from '../constants/sources';

export const getRouterPath = () => {
  return process.env.REACT_APP_ROUTER_BASENAME ? `/${process.env.REACT_APP_ROUTER_BASENAME}` : '';
};

export const getMembershipIdFromUrl = () => {
  const arrayFromUrl = window.location.pathname.split('/org/');
  if (arrayFromUrl.length < 2) return undefined;
  return arrayFromUrl[1].split('/')[0];
};

export const redirectToOriginalMembership = (
  originalMembershipId: string,
  options?: RedirectOptions
) => {
  const routerPath = getRouterPath();

  if (!options?.silent) {
    // eslint-disable-next-line no-alert
    alert('Your membership id is incorrect. Redirecting to original membership');
  }

  window.location.pathname = `${routerPath}/org/${originalMembershipId}/${window.location.pathname
    .split('/org/')[1]
    .substring(window.location.pathname.split('/org/')[1].indexOf('/') + 1)}`;
};

export const redirectToBase = (options?: RedirectOptions) => {
  const routerPath = getRouterPath();
  if (!options?.silent) {
    // eslint-disable-next-line no-alert
    alert('Your organization id is not included in your memberships. Redirecting to basic view');
  }
  window.location.pathname = `${routerPath}/`;
};

export const getDisplayedProviderName = (name?: string) => {
  if (!name) return '';
  if (name === CONNECT_PROVIDER_NAMES.BOOKING) return 'Booking';
  if (name === CONNECT_PROVIDER_NAMES.GOOGLE) return 'Google';
  return name;
};

export const isGoogleProviderAccount = (account: ProviderAccount): boolean =>
  Object.prototype.hasOwnProperty.call((account as GoogleProviderAccount).credential, 'name');

export const isBookingProviderAccount = (account: ProviderAccount): boolean =>
  Object.prototype.hasOwnProperty.call(
    (account as BookingProviderAccount).credential,
    'legal_entity_id'
  ) ||
  Object.prototype.hasOwnProperty.call(
    (account as BookingProviderAccount).credential,
    'contact_email'
  );

export const defineProvider = (account: ProviderAccount): CONNECT_PROVIDER_NAMES => {
  if (isGoogleProviderAccount(account)) {
    return CONNECT_PROVIDER_NAMES.GOOGLE;
  }
  if (isBookingProviderAccount(account)) {
    return CONNECT_PROVIDER_NAMES.BOOKING;
  }
  // Placeholder for future provider checks
  // else if (isNewProviderAccount(account)) {
  //   return CONNECT_PROVIDER_NAMES.NEW_PROVIDER;
  // }
  throw new Error('Provider cannot be determined or is not supported.');
};

export const checkOrganizationSubscription = (subscription?: Subscription | SubscriptionShort) => {
  return subscription?.is_org_subscription === true;
};
