import type { FormState, UseFormRegister } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Box, TextField, Typography } from '@trustyou/ui';

import styles from './styles';

export type AccessGroupNameForm = {
  name: string;
};

type UserEmailProps = {
  register: UseFormRegister<AccessGroupNameForm>;
  formState: FormState<AccessGroupNameForm>;
  disabled?: boolean;
};

const AccessGroupName = ({ register, formState, disabled }: UserEmailProps) => (
  <Box sx={styles.nameContainer}>
    <Typography variant="body1">
      <FormattedMessage
        id="access-group.enter-access-group-name"
        defaultMessage="Enter a name for this access group"
      />
    </Typography>
    <FormattedMessage id="access-group.name-of-access-group" defaultMessage="Name of access group">
      {(message) => (
        <TextField
          data-testid="access-group-name"
          label={message}
          variant="standard"
          {...register('name')}
          error={!!formState.errors.name}
          helperText={formState.errors.name?.message}
          disabled={disabled}
        />
      )}
    </FormattedMessage>
  </Box>
);

export default AccessGroupName;
