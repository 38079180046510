import React from 'react';
import { FormattedMessage } from 'react-intl';

import { faArrowUpRightFromSquare } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Box, Button, StyledFontAwesomeIcon, Typography } from '@trustyou/ui';

import styles from './styles';

export interface AuthorizeStepProps {
  handleLogInto: () => void;
}

const AuthorizeStep = ({ handleLogInto }: AuthorizeStepProps) => (
  <Box sx={styles.contentWrapper}>
    <Typography variant="h6">
      <FormattedMessage
        id="review-providers.booking.connectForm.step1.header"
        defaultMessage="Authorize via Booking.com"
      />
    </Typography>
    <Typography sx={styles.authoriseDescription} variant="body2">
      <FormattedMessage
        id="review-providers.booking.connectForm.step1.description"
        defaultMessage="Click the button below to log into Booking.com in a new tab. You’ll need to grant TrustYou permission to fetch your review data and publish responses on your behalf."
      />
    </Typography>
    <Button
      data-testid="booking-log-into"
      onClick={handleLogInto}
      variant="contained"
      size="large"
      endIcon={<StyledFontAwesomeIcon icon={faArrowUpRightFromSquare} />}
    >
      <FormattedMessage
        id="review-providers.booking.connectForm.step1.buttonText"
        defaultMessage="Log into Booking.com"
      />
    </Button>
  </Box>
);

export default AuthorizeStep;
