import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  table: {
    '& .MuiDataGrid-cell': {
      whiteSpace: 'normal !important',
    },
  },
  tableContainer: {
    height: 'calc(100% - 64px)',
  },
};

export default styles;
