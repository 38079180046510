import type { IStyles } from '../../types/common';

const styles: IStyles = {
  isoLink: {
    display: 'inline-block',
    a: {
      color: 'primary.main',
      fontSize: '14px',
      textDecoration: 'none',
    },
  },
};

export default styles;
