import { FullScreenModal } from '@trustyou/ui';

import { EntitiesImporter } from '../../../EntitiesImporter';

type AddAdditionalEntitiesProps = {
  onClose: () => void;
};

const AddAdditionalEntities = ({ onClose }: AddAdditionalEntitiesProps) => (
  <FullScreenModal open onClose={onClose}>
    <EntitiesImporter additionalEntities onClose={onClose} />
  </FullScreenModal>
);

export default AddAdditionalEntities;
