import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Checkbox, FormControlLabel, FormGroup, MenuItem, Stack, Typography } from '@trustyou/ui';

import { CustomTextFieldWithSeparatedLabel } from '../../../shared';

export type SurveyEditorEmailInviteMessageProps = {
  id: string;
  type: 'email_invite_message';
  title: {
    [key: string]: string;
  };
  description: {
    [key: string]: string;
  };
};

const MOCK_EMAIL_INVITE_MESSAGE_LIST = {
  'TrustYou Default': {
    id: '2',
    type: 'email_invite_message',
    title: { en: '' },
    description: { en: '' },
  },
};

export function EditorEmailInviteContent() {
  const { register, setValue } = useFormContext();

  return (
    <Stack spacing={4}>
      <Stack>
        <Typography variant="h6">
          <FormattedMessage
            id="survey.survey-editor.email-invite.title"
            defaultMessage="Email invitation"
          />
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <FormattedMessage
            id="survey.survey-editor.email-invite.description"
            defaultMessage="Select an email template to use for the Survey invitations. These can be created and edited in the Content editor."
          />
        </Typography>
      </Stack>
      <CustomTextFieldWithSeparatedLabel
        select
        size="small"
        defaultValue={Object.keys(MOCK_EMAIL_INVITE_MESSAGE_LIST)[0]}
        {...register('surveyEditorEmailInvite')}
        onChange={(e) => {
          setValue(
            'surveyEditorEmailInvite',
            MOCK_EMAIL_INVITE_MESSAGE_LIST[
              e.target.value as keyof typeof MOCK_EMAIL_INVITE_MESSAGE_LIST
            ]
          );
        }}
      >
        {Object.entries(MOCK_EMAIL_INVITE_MESSAGE_LIST).map(([key, value]) => (
          <MenuItem key={value?.id} value={key}>
            {key}
          </MenuItem>
        ))}
      </CustomTextFieldWithSeparatedLabel>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox />}
          label={
            <FormattedMessage
              id="survey.survey-editor.email-invite.checkbox.label"
              defaultMessage="Embed the Overall Score question into the email"
            />
          }
        />
      </FormGroup>
    </Stack>
  );
}
