import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useQueryClient } from '@tanstack/react-query';
import { faPlus } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  type Entity,
  FETCH_ENTITY_DETAILS,
  useAlertStore,
  useMaxCompetitors,
  useMembershipStore,
  useSetEntityCompetitors,
} from '@trustyou/shared';
import { Box, Button, StyledFontAwesomeIcon as Icon, Stack } from '@trustyou/ui';

import { CompetitorCard } from './CompetitorCard/CompetitorCard';
import { DeleteCompetitor } from './DeleteCompetitor/DeleteCompetitor';
import { EmptyState } from './EmptyState/EmptyState';
import { MaxCompetitorsInfo } from './MaxCompetitorsInfo/MaxCompetitorsInfo';

import styles from './styles';

type Props = {
  entity: Entity;
  onAddCompetitors?: () => void;
};

export const EntityCompetitorsView = ({ entity, onAddCompetitors }: Props) => {
  const queryClient = useQueryClient();
  const { membership } = useMembershipStore();
  const MAX_COMPETITORS_ALLOWED = useMaxCompetitors();
  const { alert } = useAlertStore();
  const [deletableCompetitor, setDeletableCompetitor] = useState<Entity>();

  const competitors = entity.competitors || [];

  const { mutate: setEntityCompetitors } = useSetEntityCompetitors(
    () => {
      queryClient.refetchQueries({ queryKey: [FETCH_ENTITY_DETAILS] });
      alert.success(
        <FormattedMessage id="tyDesign.competitorDeleted" defaultMessage="Competitor deleted" />
      );
    },
    () => {
      alert.genericError();
    }
  );

  const onDelete = () => {
    const savingCompetitors = competitors
      .filter((row) => row.id !== deletableCompetitor?.id)
      .map((row) => row.id);
    setEntityCompetitors({
      organization_id: membership?.organizationId as string,
      entity_id: entity.id,
      competitors: savingCompetitors,
    });
    setDeletableCompetitor(undefined);
  };

  const getFooterContent = () => {
    if (competitors.length >= MAX_COMPETITORS_ALLOWED) {
      return <MaxCompetitorsInfo />;
    } else if (onAddCompetitors) {
      return (
        <Button
          data-testid="entity-details-add-competitors-button"
          color="primary"
          variant="outlined"
          onClick={onAddCompetitors}
        >
          <Icon icon={faPlus} sx={styles.addCompetitorIcon} />
          <FormattedMessage id="tyDesign.addCompetitors" defaultMessage="Add competitors" />
        </Button>
      );
    }
    return undefined;
  };

  if (!competitors.length) return <EmptyState onAddCompetitors={onAddCompetitors} />;

  return (
    <>
      <Stack
        data-testid="entity-competitors-list"
        sx={styles.container}
        direction="column"
        spacing={2}
      >
        {competitors.map((competitor) => (
          <CompetitorCard
            key={competitor.id}
            competitor={competitor}
            onDelete={setDeletableCompetitor}
          />
        ))}
      </Stack>
      <Box sx={styles.footer}>{getFooterContent()}</Box>
      <DeleteCompetitor
        onClose={() => {
          setDeletableCompetitor(undefined);
        }}
        onDelete={onDelete}
        competitor={deletableCompetitor}
      />
    </>
  );
};
