import { FormattedMessage } from 'react-intl';

import { DeleteModal } from '@trustyou/ui';

export type DisconnectAPIModalProps = {
  providerName?: string;
  displayedProviderName: string;
  onClose: () => void;
  onDelete: () => void;
  isDisconnectInProgress: boolean;
};

const DisconnectAPIModal = ({
  providerName,
  displayedProviderName,
  onClose,
  onDelete,
  isDisconnectInProgress,
}: DisconnectAPIModalProps) => (
  <DeleteModal
    cancelText={<FormattedMessage id="common.cancel" defaultMessage="Cancel" />}
    content={
      <FormattedMessage
        id="review-providers.disconnect-provider.modal.description"
        defaultMessage="All your connected IDs will be removed. You’ll not be able to use direct responses, and the reviews of these entities will be collected via web crawling."
      />
    }
    dataName={`disconnect-provider-${displayedProviderName}`}
    deleteText={
      <FormattedMessage
        id="review-providers.disconnect-provider.modal.accept"
        defaultMessage="Disconnect"
      />
    }
    onClose={onClose}
    onDelete={onDelete}
    title={
      <FormattedMessage
        id="review-providers.disconnect-provider.modal.title"
        defaultMessage="Disconnect all {providerName} APIs?"
        values={{ providerName: displayedProviderName }}
      />
    }
    open={!!providerName}
    isDeleteInProgress={isDisconnectInProgress}
  />
);

export default DisconnectAPIModal;
