import {
  $assApi,
  ANALYTICS_BASE_PATH,
  type PaginationRequest,
  type UsersResponse,
} from '@trustyou/shared';
import type { AxiosRequestConfig } from 'axios';

import type { FetchReportsSchemaResponse, ReportRequest } from './FetchReportsSchema';

import type {
  NewReportSchema,
  NewReportSchemaResponse,
  ReportStatusHistorySchema,
} from '../NewReportSchema';

export const scheduleReport = async (
  dashboardId: string,
  reportForm: Omit<NewReportSchema, 'dashboard_id' | 'emails'>
): Promise<NewReportSchemaResponse> => {
  const { data } = await $assApi.post(`${ANALYTICS_BASE_PATH}/report/register`, {
    ...reportForm,
    dashboard_id: dashboardId,
  });
  return data;
};

export async function fetchReports(
  request: ReportRequest,
  axiosConfig?: AxiosRequestConfig<unknown>
): Promise<FetchReportsSchemaResponse> {
  const { data } = await $assApi.get(`${ANALYTICS_BASE_PATH}/report/list`, {
    params: {
      type: 'pdf',
      ...request,
    },
    ...axiosConfig,
  });
  return data;
}

export async function fetchReportById(
  reportId: string,
  axiosConfig?: AxiosRequestConfig<unknown>
): Promise<NewReportSchemaResponse> {
  const { data } = await $assApi.get(`${ANALYTICS_BASE_PATH}/report/get`, {
    params: {
      report_id: reportId,
    },
    ...axiosConfig,
  });
  return data;
}

export async function fetchStatusHistoryByReportId(
  reportId: string,
  axiosConfig?: AxiosRequestConfig<unknown>
): Promise<{ data: ReportStatusHistorySchema }> {
  const { data } = await $assApi.get(`${ANALYTICS_BASE_PATH}/status/history`, {
    params: {
      report_id: reportId,
    },
    ...axiosConfig,
  });
  return data;
}

export async function fetchUsersByOrganization(
  { limit, offset }: PaginationRequest,
  sortKey: string,
  searchKey?: string
): Promise<UsersResponse> {
  const { data } = await $assApi.get(`${ANALYTICS_BASE_PATH}/report/get_users`, {
    params: {
      limit,
      offset,
      order: sortKey,
      ...(searchKey && { q: searchKey }),
    },
  });
  return data;
}

export async function deleteReportById(
  reportId: string,
  axiosConfig?: AxiosRequestConfig<unknown>
): Promise<void> {
  const { data } = await $assApi.delete(`${ANALYTICS_BASE_PATH}/report/delete`, {
    params: {
      report_id: reportId,
    },
    ...axiosConfig,
  });
  return data;
}

export const modifyReportById = async (
  id: string,
  reportForm: Omit<NewReportSchema, 'emails'>
): Promise<void> => {
  const { data } = await $assApi.put(`${ANALYTICS_BASE_PATH}/report/modify`, {
    ...reportForm,
    id,
  });
  return data;
};
