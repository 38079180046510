import type {
  FilterSort,
  MetaDetailSchema,
  ResponseIn,
  ReviewEnvelopeDetailSchema,
  ReviewFilters,
  ReviewView,
  SurveyAnswer,
} from '../client';

export type ReviewRoot = ReviewEnvelopeDetailSchema;

export type SurveyType =
  | 'hotel_pre_stay'
  | 'hotel_post_stay'
  | 'hotel_on_site'
  | 'restaurant'
  | 'ess'
  | 'attraction'
  | 'wellness_and_spa'
  | 'all';

export type Meta = MetaDetailSchema & {
  cluster_id?: string;
  total?: number;
  survey_type?: SurveyType;
};

export type Range = {
  start: number;
  end: number;
};

export type SemaTopics = ReviewFilters['sema_topics'];

export type PrePayload = {
  page: number;
  pageSize: number;
  filters: ReviewView;
  sort: FilterSort;
};

export type SetReviewStatusPayload = {
  status: string;
  reviews: string[];
  isList?: boolean;
};

export type MarkReviewAsDeletedPayload = {
  username: string;
  review_id: string;
};

export type SetReviewStatusSuccess = SetReviewStatusPayload;

export type ResponseFormSchema = {
  response: string;
  translatedResponse?: string;
  alsoSendViaEmail?: boolean;
  emailDetails?: {
    subject: string;
    body: string;
    from: string;
    cc?: string[];
    bcc?: string[];
    attachReview?: boolean;
    sendDifferentResponse?: boolean;
    differentResponse?: string;
  };
};

export type ResponseEndpointProps = {
  review_id: string;
  payload: ResponseIn;
};

export type GetResponseUrlSuccess = {
  url: string;
};

export type Survey = {
  id: string;
  contact_info: SurveyContactInfo;
  answers: SurveyAnswer[];
  guest_information: { id: string; questions: SurveyQuestion[] };
};

export type SurveyContactInfo = {
  name: string;
  email: string;
  room: string;
  phone: string;
};

export type SurveyQuestion = {
  name: string;
  type_id: string;
  id: string;
  value: SurveyValue;
  options?: SurveyQuestionOptions;
};

export type SurveyValue = {
  happiness_scale?: number;
  long_text?: string;
  short_text?: string;
  date?: string;
  numeric_scale?: string | number;
  radio?: string;
  checkbox?: string[];
  dropdown?: string[];
  boolean?: boolean;
  phone?: string;
  nps?: number;
  number?: number;
};

export enum NumericScaleOptions {
  ONE_TO_FIVE = 'oneToFive',
  ONE_TO_TEN = 'oneToTen',
}

export enum ResponseTextField {
  ORIGINAL = 'original',
  TRANSLATED = 'translated',
}

export type SurveyQuestionOptions = {
  scale?: NumericScaleOptions;
  description?: string;
};

export enum ResponseTabsEnum {
  RESPONSE_AI = 'responseAI',
  TEMPLATE = 'template',
  BLANK = 'blank',
}

export type GetSourcesReturnType = Record<string, string>;

export type SpecificStampProps = {
  date: string;
  author: string;
};
