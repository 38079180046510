import { useIntl } from 'react-intl';

import { getValueFromMsgs, statusTooltipMessages } from '../constants/messages';

export function useTranslatedText() {
  const intl = useIntl();

  function getTranslatedTooltip(status: string) {
    const msg = getValueFromMsgs(statusTooltipMessages, status);
    const translatedStatus = intl.formatMessage(msg);
    return msg ? translatedStatus : status;
  }

  return { getTranslatedText: getTranslatedTooltip };
}
