import type { Segment, SegmentGroup } from '@trustyou/shared';
import { create } from 'zustand';

export interface SegmentStore {
  selectedSegment: Segment | undefined;
  setSelectedSegment: (selectedSegment: Segment | undefined) => void;

  selectedGroup: SegmentGroup | undefined;
  setSelectedGroup: (selectedGroup: SegmentGroup | undefined) => void;

  segmentModelOpen: boolean;
  setSegmentModelOpen: (segmentModelOpen: boolean) => void;

  groupModelOpen: boolean;
  setGroupModelOpen: (groupModelOpen: boolean) => void;

  editDashboardSegmentsModelOpen: boolean;
  setEditDashboardSegmentsModelOpen: (editDashboardSegmentsModelOpen: boolean) => void;
}

const useSegmentStore = create<SegmentStore>((set) => ({
  selectedSegment: undefined,
  setSelectedSegment: (selectedSegment) => set(() => ({ selectedSegment })),

  selectedGroup: undefined,
  setSelectedGroup: (selectedGroup) => set(() => ({ selectedGroup })),

  segmentModelOpen: false,
  setSegmentModelOpen: (segmentModelOpen) => set(() => ({ segmentModelOpen })),

  groupModelOpen: false,
  setGroupModelOpen: (groupModelOpen) => set(() => ({ groupModelOpen })),

  editDashboardSegmentsModelOpen: false,
  setEditDashboardSegmentsModelOpen: (editDashboardSegmentsModelOpen) =>
    set(() => ({ editDashboardSegmentsModelOpen })),
}));

export default useSegmentStore;
