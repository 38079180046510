import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { faXmark } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Button, IconButton, Stack, StyledFontAwesomeIcon, TyLogo, Typography } from '@trustyou/ui';

type EditorHeaderProps = {
  title: ReactNode;
  close: () => void;
  save: () => void;
};

export const EditorHeader = ({ title, close, save }: EditorHeaderProps) => {
  return (
    <Stack
      direction="row"
      sx={{
        gridColumn: '1 / 4',
        gridRow: '1',
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        paddingInline: 3,
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Stack direction="row" spacing={1.5} alignItems="center">
        <TyLogo />
        <Typography variant="body1" color="text.secondary">
          {title}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={3} alignItems="center">
        <Button onClick={close} color="inherit">
          <FormattedMessage id="survey.editor.discard-changes" defaultMessage="Discard changes" />
        </Button>
        <Button onClick={save} variant="outlined">
          <FormattedMessage id="survey.editor.save-changes" defaultMessage="Save changes" />
        </Button>
        <IconButton onClick={close} size="small">
          <StyledFontAwesomeIcon icon={faXmark} />
        </IconButton>
      </Stack>
    </Stack>
  );
};
