import {
  ADD_OWNED_ENTITIES_PERMISSION,
  SEARCH_COMPETITORS_PERMISSION,
  isTemporaryHidden,
  usePermissionStore,
} from '@trustyou/shared';

const useShowAddEntities = () => {
  const { hasPermissions } = usePermissionStore();

  const isSearchCompetitorsAllowed = hasPermissions(SEARCH_COMPETITORS_PERMISSION);
  const isAddOwnedEntitesAllowed = hasPermissions(ADD_OWNED_ENTITIES_PERMISSION);
  const isAddEntityEnabled = !isTemporaryHidden('CXP-customers-add-entity');

  return isSearchCompetitorsAllowed && isAddOwnedEntitesAllowed && isAddEntityEnabled;
};

export default useShowAddEntities;
