import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import type { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import {
  type SubscriptionStatus,
  absentValues,
  commonFields,
  useCurrentSubscription,
} from '@trustyou/shared';
import { Box, CircularProgress, DataGrid, DataGridCell } from '@trustyou/ui';

import { SUBSCRIPTION_STATUS_CHIP_MAP } from '../../components';
import { styles } from './styles';

const OrganizationSubscription = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { data, isPending: isLoading } = useCurrentSubscription();

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: intl.formatMessage(commonFields.name),
      minWidth: 350,
      flex: 1,
      sortable: false,
      renderCell: ({ row }: GridCellParams) => (
        <DataGridCell primaryValue={row.name} secondaryValue={row.plan_name} />
      ),
    },
    {
      field: 'owner',
      headerName: intl.formatMessage(commonFields.owner),
      minWidth: 350,
      flex: 1,
      sortable: false,
      renderCell: ({ row }: GridCellParams) => (
        <DataGridCell
          primaryValue={row.owner ? row.owner.name : intl.formatMessage(absentValues.notAvailable)}
          secondaryValue={row.owner?.email}
        />
      ),
    },
    {
      field: 'number_of_users',
      headerName: intl.formatMessage(commonFields.numberOfUsers),
      minWidth: 200,
      flex: 1,
      sortable: false,
      renderCell: ({ row }: GridCellParams) => (
        <DataGridCell
          primaryValue={`${row.number_of_consumed_members}/${row.number_of_allowed_members}`}
        />
      ),
    },
    {
      field: 'status',
      headerName: intl.formatMessage(commonFields.status),
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: ({ row }: GridCellParams) =>
        row.status && SUBSCRIPTION_STATUS_CHIP_MAP[row.status as SubscriptionStatus],
    },
  ];

  if (isLoading)
    return (
      <Box sx={styles.loader}>
        <CircularProgress />
      </Box>
    );

  if (!data) return null;

  const rows = [{ ...data }];

  return (
    <DataGrid
      rowHeight={64}
      columns={columns}
      rows={rows}
      hideFooter
      onRowClick={(params) => navigate(`${params.row.id}/details`)}
      loading={isLoading}
    />
  );
};

export default OrganizationSubscription;
