import { type ReactNode, useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';

import {
  Box,
  FormControl,
  InputLabel,
  Popover,
  TextField,
  Typography,
  useTheme,
} from '@trustyou/ui';

type ColorPickerProps = {
  id: string;
  open: boolean;
  colorPickerHandler: (id?: string) => void;
  label?: ReactNode;
  color: {
    color: string;
    label: string;
  };
  onChange: (id?: string, color?: string) => void;
};

export const ColorPicker = ({
  id,
  open,
  colorPickerHandler,
  label,
  color,
  onChange,
}: ColorPickerProps) => {
  const theme = useTheme();
  const [value, setValue] = useState(color);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [backgroundColor, setBackgroundColor] = useState('');

  useEffect(() => {
    setBackgroundColor(value.color);
  }, [value]);

  const onClose = (event: MouseEvent) => {
    const chromePicker = document.querySelector('.chrome-picker');
    if (chromePicker && chromePicker.contains(event.target as Node)) return;
    setAnchorEl(null);
    colorPickerHandler();
  };

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    colorPickerHandler(id);
  };

  const onChangeColor = (colorSelected: { hex: string }) => {
    setBackgroundColor(colorSelected.hex);
    setValue({ color: colorSelected.hex, label: colorSelected.hex });
    onChange(id, colorSelected.hex);
  };

  return (
    <FormControl
      fullWidth
      sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
    >
      {label && (
        <InputLabel sx={{ position: 'static', transform: 'none' }}>
          <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
            {label}
          </Typography>
        </InputLabel>
      )}
      <Box
        component="button"
        sx={{ display: 'flex', gap: 1, backgroundColor: 'transparent', border: 'none' }}
        onClick={onClick}
      >
        <Box
          sx={{
            width: 32,
            height: 32,
            borderRadius: 1,
            border: `1px solid ${theme.palette.divider}`,
            backgroundColor: `${backgroundColor}`,
          }}
        />
        <TextField
          variant="standard"
          value={value.label}
          sx={{ width: 220 }}
          InputProps={{
            readOnly: true,
            sx: {
              color: theme.palette.text.primary,
              '&:hover:not(.Mui-disabled)::before': {
                borderBottom: `2px solid ${theme.palette.primary.main}`,
              },
            },
          }}
        />
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ChromePicker color={backgroundColor} onChangeComplete={onChangeColor} disableAlpha />
      </Popover>
    </FormControl>
  );
};

export default ColorPicker;
