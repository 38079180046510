import {
  INBOX_SET_EMAIL_NOTIFICATION_SETTINGS_PERMISSION,
  usePermissionStore,
} from '@trustyou/shared';

const useSetSettingsPermission = () => {
  const { hasPermissions } = usePermissionStore();
  const isAllowed = hasPermissions(INBOX_SET_EMAIL_NOTIFICATION_SETTINGS_PERMISSION);
  return isAllowed;
};

export default useSetSettingsPermission;
