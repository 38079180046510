import { FormattedMessage } from 'react-intl';

import { commonFields, useCurrentSubscription } from '@trustyou/shared';
import { Box, CircularProgress, ContentPane, Typography } from '@trustyou/ui';

import { SubscriptionInfoPanel } from '../../Subscriptions/Common';
import { styles } from './styles';

const SubscriptionsSettings = () => {
  const { data: currentSubscription, isPending: isCurrentSubscriptionLoading } =
    useCurrentSubscription();

  const isLoading = isCurrentSubscriptionLoading;

  const getSubscriptionSettingsContent = () => {
    if (isLoading)
      return (
        <Box sx={styles.loader}>
          <CircularProgress />
        </Box>
      );

    if (!currentSubscription) return null;

    return <SubscriptionInfoPanel subscription={currentSubscription} />;
  };

  return (
    <ContentPane sx={styles.pageContainer}>
      <Box>
        <Typography variant="h6" color="text.primary">
          <FormattedMessage {...commonFields.subscription} />
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <FormattedMessage
            id="app.settings.subscription.description"
            defaultMessage="View and manage your subscription"
          />
        </Typography>
      </Box>
      <Box sx={styles.body}>{getSubscriptionSettingsContent()}</Box>
    </ContentPane>
  );
};

export default SubscriptionsSettings;
