import { type ChangeEvent, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { DialogContentText } from '@mui/material';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from '@trustyou/ui';

export type ReplaceDialogProps = {
  isOpen: boolean;
  onClose?: () => void;
  onSubmit: (isChecked: boolean) => void;
};

export const ReplaceDialog = ({ isOpen, onClose, onSubmit }: ReplaceDialogProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleChange = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) =>
    setIsChecked(checked);
  const handleSubmit = () => onSubmit(isChecked);

  return (
    <Dialog onClose={onClose} open={isOpen}>
      <DialogTitle>
        <FormattedMessage
          id="inbox.response.generate.dialog.title"
          defaultMessage="Replace response with a new one?"
        />
      </DialogTitle>
      <DialogContent>
        <Box>
          <DialogContentText>
            <FormattedMessage
              id="inbox.response.generate.dialog.content"
              defaultMessage="The previous text will be lost."
            />
          </DialogContentText>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', pl: 3 }}>
        <FormControlLabel
          control={<Checkbox checked={isChecked} onChange={handleChange} size="small" />}
          label={
            <FormattedMessage
              id="inbox.response.generate.dialog.checkBoxLabel"
              defaultMessage="Don't show this again"
            />
          }
        />
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button onClick={onClose}>
            <FormattedMessage id="inbox.action.cancel" defaultMessage="Cancel" />
          </Button>
          <Button variant="contained" onClick={handleSubmit}>
            <FormattedMessage id="inbox.action.confirm" defaultMessage="Confirm" />
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
