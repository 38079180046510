import { FormattedMessage } from 'react-intl';

import type { StyleUnit } from '@trustyou/shared';
import { EmptyStatePlaceholder } from '@trustyou/ui';

import personSelectingData from '../../assets/illustrations/person-selecting-data.svg';
import styles from './styles';

export type EmptyBenchmarkEntityProps = {
  sx?: StyleUnit;
};

export const EmptyBenchmarkEntityState = ({ sx }: EmptyBenchmarkEntityProps) => (
  <EmptyStatePlaceholder
    img={personSelectingData}
    title={
      <FormattedMessage
        id="tyDesign.emptyState.noBenchmarkOwnedEntitiesYet"
        defaultMessage="No entities yet"
      />
    }
    description={
      <FormattedMessage
        id="tyDesign.emptyState.noBenchmarkOwnedEntitiesDescription"
        defaultMessage="When you configure the entities of the benchmark, they will show up here."
      />
    }
    emptyStateBody={{ textAlign: 'center' }}
    emptyStateHeader={styles.emptyStateHeader}
    emptyStateContainer={{ ...styles.emptyStateContainer, ...styles.noDataContainer, ...sx }}
  />
);
