import { useQuery } from '@tanstack/react-query';
import { $assApi, SURVEY_BASE_PATH } from '@trustyou/shared';

import type { ContentEnum, Question_Output } from '../../client';
import type { Content } from '../../types/content';
import { CONTENT_LIST, QUESTION_LIST } from '../constants/query-keys';

async function fetchQuestionList(): Promise<Question_Output[]> {
  const { data } = await $assApi.get(`${SURVEY_BASE_PATH}/question/list`);
  return data;
}

async function fetchContentList({ type }: { type: ContentEnum }): Promise<Content[]> {
  const { data } = await $assApi.get(`${SURVEY_BASE_PATH}/content/list`, {
    params: {
      type,
    },
  });
  return data;
}

async function fetchMultipleContentLists({ types }: { types: ContentEnum[] }): Promise<Content[]> {
  // Approach A: Multiple requests
  const requests = types.map((type) => fetchContentList({ type }));
  const multipleData = await Promise.all(requests);
  return multipleData.flat();

  // TODO: Waiting for backend.
  // Approach B: Multiple values for the same query parameter
  // const params = new URLSearchParams();
  // types.forEach((type) => params.append('type', type));
  // const { data } = await $assApi.get(`${SURVEY_BASE_PATH}/content/list`, { params });
  // return data;
}

export function useQuestions() {
  return useQuery({
    queryKey: [QUESTION_LIST],
    queryFn: fetchQuestionList,
  });
}

export function useContents({ types }: { types: ContentEnum[] }) {
  return useQuery({
    queryKey: [CONTENT_LIST, { types }],
    queryFn: () => fetchMultipleContentLists({ types }),
  });
}
