import trustYouLogo from '../../assets/logos/trustyou-blue-logo.svg';
import trustYouWhiteLogo from '../../assets/logos/trustyou-white-logo.svg';
import tyBlueIsotypeLogo from '../../assets/logos/ty-blue-isotype.svg';
import tyWhiteIsotypeLogo from '../../assets/logos/ty-white-isotype.svg';

export interface TyLogoProps {
  variant?: 'blue' | 'white';
  full?: boolean;
  fullWhite?: boolean;
}

export const TyLogo = ({ variant = 'blue', full, fullWhite }: TyLogoProps) => {
  if (full) {
    return <img src={trustYouLogo} alt="TrustYou logo" />;
  }
  if (fullWhite) {
    return <img src={trustYouWhiteLogo} alt="TrustYou logo - white" />;
  }
  if (variant === 'white') {
    return (
      <img
        src={tyWhiteIsotypeLogo}
        alt="TrustYou Logo - white isotype (three little blue comic text boxes over a white background)"
      />
    );
  }
  return (
    <img
      src={tyBlueIsotypeLogo}
      alt="TrustYou logo - blue isotype (three little white comic text boxes over a lightblue background)"
    />
  );
};
