import { lightBlue, lime, orange, purple, teal } from '@mui/material/colors';
import {
  faBallot,
  faCalendarDays,
  faCheck,
  faEnvelope,
  faGaugeHigh,
  faListDropdown,
  faPenNib,
  faPhone,
  faSquare1,
  faThumbsUp,
  faTypewriter,
} from '@trustyou/fortawesome/pro-regular-svg-icons';

import type { SurveyEditorContentSettingsProps } from '../../../types/survey';

export const EDITOR_QUESTION_CONTENT_TYPES: SurveyEditorContentSettingsProps = {
  button_group: {
    icon: faBallot,
    label: 'Select question',
    color: lightBlue[300],
  },
  dropdown: {
    icon: faListDropdown,
    label: 'Select question',
    color: lightBlue[300],
  },
  radio_group: {
    icon: faThumbsUp,
    label: 'Binary question',
    color: lightBlue[300],
  },
  checkbox: {
    icon: faCheck,
    label: 'Checkbox question',
    color: lightBlue[300],
  },
  nps: {
    icon: faGaugeHigh,
    label: 'NPS question',
    color: purple[300],
  },
  scale: {
    icon: faSquare1,
    label: 'Scale question',
    color: purple[300],
  },
  short_text: {
    icon: faPenNib,
    label: 'Short text question',
    color: purple[300],
  },
  long_text: {
    icon: faTypewriter,
    label: 'Text question',
    color: purple[300],
  },
  number: {
    icon: faSquare1,
    label: 'Number question',
    color: purple[300],
  },
  email: {
    icon: faEnvelope,
    label: 'Email question',
    color: teal[300],
  },
  phone: {
    icon: faPhone,
    label: 'Phone question',
    color: teal[300],
  },
  datepicker: {
    icon: faCalendarDays,
    label: 'Date question',
    color: lime[300],
  },
  section: {
    icon: faBallot,
    label: 'Section text',
    color: lightBlue[300],
  },
  'page-break': {
    icon: faBallot,
    label: 'Page break',
    color: orange[200],
  },
  default: {
    icon: faBallot,
    label: 'Select question',
    color: lightBlue[300],
  },
};
