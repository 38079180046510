import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  inviteButtonContainer: {
    textAlign: 'right',
    marginBottom: 2,
  },
  searchBar: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiFormControl-root': {
      width: '220px',
    },
  },
  contentPane: {
    minHeight: 0,
    flex: 1,
    boxShadow: 'none',
  },
  tableContainer: { height: 'calc(100% - 36px)' },
};

export default styles;
