import { FormattedMessage } from 'react-intl';

import { Box, Button, Typography } from '@trustyou/ui';

import personNexToABrokenLightBulb from '../../assets/illustrations/person-next-to-a-broken-light-bulb.svg';
import styles from './styles';

interface HallwayRouteProps {
  onLogout: () => void;
  email?: string;
}

const StyledEmail = ({ email }: { email?: string }) => {
  return (
    <Typography variant="body2" component="span" sx={{ color: 'primary.main' }}>
      {email || <FormattedMessage id="tyDesign.yourEmail" defaultMessage="your email" />}
    </Typography>
  );
};

export const HallwayRoute = ({ onLogout, email }: HallwayRouteProps) => (
  <Box sx={styles.wrapper}>
    <img src={personNexToABrokenLightBulb} alt="Person next to a broken light bulb" />
    <Box sx={styles.textWrapper}>
      <Typography variant="h6">
        <FormattedMessage
          id="tyDesign.accountWithoutOrganization"
          defaultMessage="Your account is not linked to an organization"
        />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage
          id="tyDesign.hallwayDescription"
          defaultMessage="Use your invited email address to create an account, or ask your admin to invite {email} to your organization, so that you can access your review data."
          values={{ email: <StyledEmail email={email} /> }}
        />
      </Typography>
    </Box>
    <Button variant="contained" data-testid="go-back-button" onClick={onLogout}>
      <FormattedMessage id="tyDesign.logout" defaultMessage="Log out" />
    </Button>
  </Box>
);
