import { forwardRef } from 'react';

import MuiIconButton, {
  type IconButtonProps as MuiIconButtonProps,
} from '@mui/material/IconButton';
import { useGTMProps } from '@trustyou/shared';

import type { E2ECouldTest } from '../types/e2e';
import type { GTMTracked } from '../types/gtm';

export interface IconButtonProps extends MuiIconButtonProps, E2ECouldTest, GTMTracked {}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ children, 'data-testid': testId, ...restProps }: IconButtonProps, ref) => {
    const propsWithGtm = useGTMProps(restProps, testId);
    return (
      <MuiIconButton {...propsWithGtm} ref={ref}>
        {children}
      </MuiIconButton>
    );
  }
);
