import type { LanguageOption } from './static-elements';

export const MAX_CARD_WIDTH_PX = 640;
export const MAX_QUESTION_EDITOR_FIELD_WIDTH_PX = 318;
export const MAX_HEADER_IMAGE_HEIGHT_PX = 186;

export const LANGUAGE_OPTIONS: LanguageOption[] = [
  { value: 'ar', label: 'العربية' },
  { value: 'bg', label: 'Български' },
  { value: 'cs', label: 'Čeština' },
  { value: 'da', label: 'Dansk' },
  { value: 'de', label: 'Deutsch' },
  { value: 'el', label: 'Ελληνικά' },
  { value: 'en', label: 'English' },
  { value: 'es', label: 'Español' },
  { value: 'fi', label: 'Suomi' },
  { value: 'fr', label: 'Français' },
  { value: 'he', label: 'עברית' },
  { value: 'hr', label: 'Hrvatski' },
  { value: 'hu', label: 'Magyar' },
  { value: 'id', label: 'Bahasa Indonesia' },
  { value: 'it', label: 'Italiano' },
  { value: 'ja', label: '日本語' },
  { value: 'ko', label: '한국어' },
  { value: 'ms', label: 'Melayu' },
  { value: 'nl', label: 'Nederlands' },
  { value: 'pl', label: 'Polski' },
  { value: 'pt-BR', label: 'Português do Brasil' },
  { value: 'pt', label: 'Português' },
  { value: 'ro', label: 'Română' },
  { value: 'ru', label: 'Русский' },
  { value: 'sk', label: 'Slovenčina' },
  { value: 'sl', label: 'Slovenski' },
  { value: 'sr', label: 'Srpski' },
  { value: 'sv', label: 'Svenska' },
  { value: 'th', label: 'ไทย' },
  { value: 'tr', label: 'Turkish' },
  { value: 'vi', label: 'Tiếng Việt' },
  { value: 'yue', label: '粵語' },
  { value: 'zh', label: '简体中文' },
  { value: 'zht', label: '繁體中文' },
];

export const QUESTION_TYPES_BY_CATEGORY = {
  select: ['single_select', 'multiple_select'],
  scale: ['rating', 'nps'],
  binary: ['binary'],
  number: ['number'],
  text: ['text'],
  withoutVariation: ['nps', 'number'],
};
