import type { IStyles } from '../../types/common';

const styles: IStyles = {
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 1,
    width: '100%',
    height: '32px',
    '& > img': {
      height: '100%',
      width: 'auto',
    },
    '&  > svg': {
      color: 'text.disabled',
    },
  },
};

export default styles;
