import type { StateCreator } from 'zustand';

import type {
  QuestionLibraryEditorStartingPoint,
  SurveyEditorStartingPoint,
  ThemeEditorStartingPoint,
} from '../../types/editor';

export interface EditorSliceState {
  isSurveyEditorOpen: boolean;
  isThemeEditorOpen: boolean;
  isQuestionLibraryEditorOpen: boolean;
  setIsSurveyEditorOpen: (isSurveyEditorOpen: boolean) => void;
  setIsThemeEditorOpen: (isThemeEditorOpen: boolean) => void;
  setIsQuestionLibraryEditorOpen: (isQuestionLibraryEditorOpen: boolean) => void;

  surveyEditorStartingPoint: SurveyEditorStartingPoint;
  themeEditorStartingPoint: ThemeEditorStartingPoint;
  questionLibraryEditorStartingPoint: QuestionLibraryEditorStartingPoint;
  setSurveyEditorStartingPoint: (surveyEditorStartingPoint: SurveyEditorStartingPoint) => void;
  setThemeEditorStartingPoint: (themeEditorStartingPoint: ThemeEditorStartingPoint) => void;
  setQuestionLibraryEditorStartingPoint: (
    questionLibraryEditorStartingPoint: QuestionLibraryEditorStartingPoint
  ) => void;
}

export const createEditorSlice: StateCreator<EditorSliceState, [], []> = (set) => ({
  isSurveyEditorOpen: false,
  isThemeEditorOpen: false,
  isQuestionLibraryEditorOpen: false,
  setIsSurveyEditorOpen: (isSurveyEditorOpen) => set(() => ({ isSurveyEditorOpen })),
  setIsThemeEditorOpen: (isThemeEditorOpen) => set(() => ({ isThemeEditorOpen })),
  setIsQuestionLibraryEditorOpen: (isQuestionLibraryEditorOpen) =>
    set(() => ({ isQuestionLibraryEditorOpen })),

  surveyEditorStartingPoint: 'new-survey',
  themeEditorStartingPoint: 'new-theme',
  questionLibraryEditorStartingPoint: 'new-question',
  setSurveyEditorStartingPoint: (surveyEditorStartingPoint) =>
    set(() => ({ surveyEditorStartingPoint })),
  setThemeEditorStartingPoint: (themeEditorStartingPoint) =>
    set(() => ({ themeEditorStartingPoint })),
  setQuestionLibraryEditorStartingPoint: (questionLibraryEditorStartingPoint) =>
    set(() => ({ questionLibraryEditorStartingPoint })),
});
