import type { IStyles } from '../../types/common';

const styles: IStyles = {
  itemWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  checkedAll: {
    borderBottom: '1px solid #e1e1e1',
  },
};

export default styles;
