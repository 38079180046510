import { FormattedMessage } from 'react-intl';

import type { SxProps, Theme } from '@mui/material';
import { faBell } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { openNotifications, useMembershipStore } from '@trustyou/shared';
import { AppBar, Box, NavIcon, TyLogo, Typography } from '@trustyou/ui';

import { HelpMenu } from '../HelpMenu/HelpMenu';
import { UserMenuWrapper } from '../UserMenu/UserMenu';
import styles from './styles';

export type TopBarProps = {
  displayLogo?: boolean;
  header?: {
    id: string;
    message: string;
  };
  sx?: SxProps<Theme>;
};

export const TopBar = ({ displayLogo = true, header, sx }: TopBarProps) => {
  const { getMembershipDetails } = useMembershipStore();
  const membershipDetails = getMembershipDetails();

  return (
    <AppBar
      sx={{
        ...styles.appBar,
        ...sx,
      }}
    >
      {header && (
        <Typography variant="subtitle1" color="text.primary">
          <FormattedMessage id={header.id} defaultMessage={header.message} />
        </Typography>
      )}
      {displayLogo && <TyLogo />}
      <Box sx={styles.toolsWrapper}>
        <HelpMenu />
        {membershipDetails && (
          <NavIcon
            id="notifications"
            icon={faBell}
            shape="circular"
            tooltip={<FormattedMessage id="topbar.notifications" defaultMessage="Notifications" />}
            tooltipPlacement="bottom"
            onClick={openNotifications}
          />
        )}
        <UserMenuWrapper />
      </Box>
    </AppBar>
  );
};
