import { FormattedMessage } from 'react-intl';

import { Box, Stack, Typography } from '@trustyou/ui';

import { SurveyCard } from '../../../components/survey/survey-card';
import { SurveyEmptyState } from '../../../components/survey/survey-empty-state';
import { useSurveys } from '../../../service/hooks/use-surveys';

function SurveyListHeader() {
  return (
    <Box sx={{ marginBottom: 2.5 }}>
      <Typography variant="h6" component="h2">
        <FormattedMessage id="survey.common.overview" defaultMessage="Overview" />
      </Typography>
      <Typography variant="body2" color="text.secondary">
        <FormattedMessage
          id="survey.survey-editor.subtitle"
          defaultMessage="Create and manage all your surveys here"
        />
      </Typography>
    </Box>
  );
}

export function SurveyList() {
  const { data: surveys = [] } = useSurveys();

  if (surveys.length === 0)
    return (
      <Stack spacing={3}>
        <SurveyListHeader />
        <Box padding={10}>
          <SurveyEmptyState />
        </Box>
      </Stack>
    );

  return (
    <Stack spacing={3}>
      <SurveyListHeader />
      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 3 }}>
        {surveys.map((survey) => (
          <SurveyCard key={survey.id} survey={survey} />
        ))}
      </Box>
    </Stack>
  );
}
