import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Box,
  Button,
  ConnectToExternalAPI,
  ContentPane,
  InstructionCardList,
  type InstructionCardProps,
  Typography,
  reviewProvidersLogo,
} from '@trustyou/ui';

import styles from './styles';

export interface BookingConnectInstructionsProps {
  onNextClick: () => void;
}

const BookingConnectInstructions = ({ onNextClick }: BookingConnectInstructionsProps) => {
  const bookingInstructionItems: InstructionCardProps[] = [
    {
      stepNumber: 1,
      title: (
        <FormattedMessage
          id="review-providers.booking.connectTo.step1.title"
          defaultMessage="Authorize via Booking.com"
        />
      ),
      description: (
        <FormattedMessage
          id="review-providers.booking.connectTo.step1.description"
          defaultMessage="You’ll need to grant Trustyou permission to fetch your review data and publish responses on your behalf."
        />
      ),
    },
    {
      stepNumber: 2,
      title: (
        <FormattedMessage
          id="review-providers.booking.connectTo.step2.title"
          defaultMessage="Copy the Legal Entity ID from Booking.com"
        />
      ),
      description: (
        <FormattedMessage
          id="review-providers.booking.connectTo.step2.description"
          defaultMessage="Let us know your legal entity ID so that we can match your entities."
        />
      ),
    },
    {
      stepNumber: 3,
      title: (
        <FormattedMessage
          id="review-providers.booking.connectTo.step3.title"
          defaultMessage="Wait for 24-48h"
        />
      ),
      description: (
        <FormattedMessage
          id="review-providers.booking.connectTo.step3.description"
          defaultMessage="We’ll match your Booking Business listings, and this process could take up to 48 hours."
        />
      ),
    },
  ];
  return (
    <ContentPane sx={styles.pageContainer}>
      <Box sx={styles.contentWrapper}>
        <ConnectToExternalAPI srcExt={reviewProvidersLogo['booking.com']} altExt="booking.com" />
        <Typography sx={styles.connectInstructionsTitle} variant="h6">
          <FormattedMessage
            id="review-providers.booking.connectTitle"
            defaultMessage="Connect to Booking.com APIs"
          />
        </Typography>
        <Typography sx={styles.connectInstructionsDescription} variant="body2">
          <FormattedMessage
            id="review-providers.booking.connectDescription"
            defaultMessage="Connect to Booking.com APIs to get accurate reviews faster and use direct responses in your inbox."
          />
        </Typography>
        <InstructionCardList items={bookingInstructionItems} />
        <Button
          size="large"
          data-testid="booking-goto-step-1"
          onClick={onNextClick}
          variant="contained"
        >
          <FormattedMessage
            id="review-providers.booking.connectTo.buttonText"
            defaultMessage="Let’s go"
          />
        </Button>
      </Box>
    </ContentPane>
  );
};

export default BookingConnectInstructions;
