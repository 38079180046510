import {
  $assApi,
  ANALYTICS_BASE_PATH,
  SISENSE_SERVER_PATH,
  getDashboardExcludedFilters,
} from '@trustyou/shared';

import type {
  DashboardIdResponse,
  SSOIsAuthenticatedResponse,
  SSOTokenResponse,
} from '../../../../types/model/analytics';

export async function setRowLevelSecurity({ language }: { language: string }): Promise<void> {
  await $assApi.post(`${ANALYTICS_BASE_PATH}/sisense/set-data-security-rule`, { language });
}

export async function logout(): Promise<void> {
  await $assApi.post(`${ANALYTICS_BASE_PATH}/sisense/logout`);
}

export async function fetchSisenseJWTToken(): Promise<SSOTokenResponse> {
  const { data } = await $assApi.get(`${ANALYTICS_BASE_PATH}/sisense/get_sso_token`);
  return data;
}

export async function fetchSisenseIsAuthenticated(): Promise<SSOIsAuthenticatedResponse> {
  const response = await fetch(`${SISENSE_SERVER_PATH}/api/auth/isauth`, {
    credentials: 'include',
  }).then((res) => res.json());
  return response;
}

export async function fetchDashboardId(): Promise<DashboardIdResponse> {
  const { data } = await $assApi.get(`${ANALYTICS_BASE_PATH}/sisense/get_dashboard_id`);
  return data;
}

export async function fetchDashboard(id: string) {
  const response = await fetch(`${SISENSE_SERVER_PATH}/api/dashboards/${id}`, {
    credentials: 'include',
  }).then((res) => res.json());
  return response;
}

export async function exportDashboardExcel({ id, language }: { id: string; language: string }) {
  const dashboard = await fetchDashboard(id);
  const filters = dashboard.filters || [];
  const excludedFilters = getDashboardExcludedFilters(filters);
  const { data: content } = await $assApi.post(
    `${ANALYTICS_BASE_PATH}/report/excel_download`,
    {
      dashboard_id: id,
      language,
      dash_filters: JSON.stringify(filters),
      excluded_filters: excludedFilters,
    },
    {
      responseType: 'blob',
    }
  );
  return {
    name: dashboard.title,
    content,
  };
}
