import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useLanguageStore } from '@trustyou/shared';
import { Box, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@trustyou/ui';

import { RenderQuestions } from './render-questions';

import { Question } from '../../../components/feedback/components/question';
import {
  SurveyMessageContent,
  SurveySectionTitle,
} from '../../../components/feedback/static-elements';
import type {
  PaginatedSurveyProps,
  SurveyEditorContentOutput,
  SurveyEditorContentProps,
} from '../../../types/survey';
import { paginateSurvey } from '../../../utils/survey-editor';

export const SurveyEditorPreview = ({ activeStep }: { activeStep: number }) => {
  const { watch } = useFormContext();
  const intl = useIntl();

  const { locale } = useLanguageStore();
  const userSettingLang = locale.split('-')[0];

  const surveyContent = watch('surveyContent') as SurveyEditorContentProps[];
  const editorWelcomeMessage =
    watch('surveyEditorWelcomeMessage')?.id === 'no-welcome-message'
      ? null
      : watch('surveyEditorWelcomeMessage');
  const editorThankYouMessage = watch('surveyEditorThankYouMessage');

  const [paginatedSurvey, setPaginatedSurvey] = useState<PaginatedSurveyProps>([]);
  const [selectedPreviewPage, setSelectedPreviewPage] = useState(0);

  const isContentExist = editorWelcomeMessage || surveyContent?.length > 0 || editorThankYouMessage;
  const isThankyouPage = activeStep === 5;

  useEffect(() => {
    setPaginatedSurvey(paginateSurvey(surveyContent));
    setSelectedPreviewPage(
      paginateSurvey(surveyContent) && Object.keys(paginateSurvey(surveyContent)).length - 1
    );
  }, [surveyContent]);

  return (
    <Stack spacing={2} sx={{ marginInline: 5 }}>
      <FormControl sx={{ width: 100, marginTop: 2 }}>
        <InputLabel>
          <FormattedMessage id="survey.survey-editor.preview" defaultMessage="Preview" />
        </InputLabel>
        {isThankyouPage ? (
          <TextField
            inputProps={{
              readOnly: true,
            }}
            label={<FormattedMessage id="survey.survey-editor.preview" defaultMessage="Preview" />}
            size="small"
            value={'Thank you page'}
            sx={{ width: 150 }}
          />
        ) : (
          <Select
            label={<FormattedMessage id="survey.survey-editor.preview" defaultMessage="Preview" />}
            size="small"
            value={selectedPreviewPage === -1 ? 0 : selectedPreviewPage}
            onChange={(event) => setSelectedPreviewPage(Number(event.target.value))}
          >
            {surveyContent?.length > 0 ? (
              Object.keys(paginatedSurvey).map((pageNumber, index) => (
                <MenuItem key={index} value={pageNumber}>
                  Page {pageNumber}
                </MenuItem>
              ))
            ) : (
              <MenuItem value={0}>Page 0</MenuItem>
            )}
          </Select>
        )}
      </FormControl>
      {!isContentExist ? (
        <Question
          name="initial-preview"
          title={intl.formatMessage({
            id: 'survey.survey-editor.initial-preview-card.title',
            defaultMessage: 'This is a preview of your Survey',
          })}
          description={intl.formatMessage({
            id: 'survey.survey-editor.initial-preview-card.description',
            defaultMessage: 'Start adding content',
          })}
        />
      ) : (
        <Box sx={{ maxHeight: '600px', overflow: 'auto' }}>
          {isThankyouPage ? (
            <Box>
              {editorThankYouMessage && (
                <SurveyMessageContent
                  title={
                    editorThankYouMessage.title[userSettingLang] ??
                    editorThankYouMessage.title[editorThankYouMessage.default_language ?? 'en']
                  }
                  description={
                    editorThankYouMessage.description[userSettingLang] ??
                    editorThankYouMessage.description[
                      editorThankYouMessage.default_language ?? 'en'
                    ]
                  }
                />
              )}
            </Box>
          ) : (
            <Box>
              {editorWelcomeMessage && (!selectedPreviewPage || selectedPreviewPage < 1) && (
                <SurveyMessageContent
                  title={
                    editorWelcomeMessage.title[userSettingLang] ??
                    editorWelcomeMessage.title[editorWelcomeMessage.default_language ?? 'en']
                  }
                  description={
                    editorWelcomeMessage.description[userSettingLang] ??
                    editorWelcomeMessage.description[editorWelcomeMessage.default_language ?? 'en']
                  }
                />
              )}
              {surveyContent && (
                <Stack spacing={2}>
                  {paginatedSurvey[selectedPreviewPage]?.map(
                    (content: SurveyEditorContentProps, index: number) => {
                      const contentLang = content.default_language ?? 'en';
                      if (content.type === 'section') {
                        return (
                          <Stack key={content.id}>
                            <SurveySectionTitle
                              title={content.title[userSettingLang] ?? content.title[contentLang]}
                            />
                            {content.questions?.map(
                              (question: SurveyEditorContentOutput, index: number) => (
                                <RenderQuestions key={index} {...question} />
                              )
                            )}
                          </Stack>
                        );
                      }
                      return <RenderQuestions key={index} {...content} />;
                    }
                  )}
                </Stack>
              )}
            </Box>
          )}
        </Box>
      )}
    </Stack>
  );
};
