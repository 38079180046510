import { FormattedMessage } from 'react-intl';

import { commonFields } from '@trustyou/shared';
import { Typography } from '@trustyou/ui';

type Props = {
  values?: string | string[];
};

export const AttributeValue = ({ values }: Props) => {
  if (!values || (Array.isArray(values) && !values.length)) {
    return (
      <Typography variant="body2" color="text.secondary">
        <FormattedMessage {...commonFields.notSet} />
      </Typography>
    );
  }
  if (Array.isArray(values)) {
    return values.map((value) => (
      <Typography key={value} variant="body2">
        {value}
      </Typography>
    ));
  }
  return <Typography variant="body2">{values}</Typography>;
};
