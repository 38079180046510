import { useIntl } from 'react-intl';

import { useChangelingStore } from '@trustyou/shared';
import { ComposableDrawerWithStickyFooter } from '@trustyou/ui';

import { EditReportForm } from './edit-report-form';

import { commonMessages } from '../../constants/messages';
import { useReport } from '../../service/hooks';
import styles from './styles';

const SLIDE_ANIMATION_DURATION = 250;
const DRAWER_WIDTH_PX = 600;

type Props = {
  reportId: string;
  onClose: () => void;
};

export function EditReportDrawer({ reportId, onClose }: Props) {
  const { isChangeling } = useChangelingStore();
  const intl = useIntl();
  const { data: report } = useReport(reportId);

  return (
    <ComposableDrawerWithStickyFooter
      open
      anchor="right"
      onClose={onClose}
      PaperProps={{
        sx: {
          width: DRAWER_WIDTH_PX,
          paddingTop: isChangeling ? 8 : 0,
        },
      }}
      SlideProps={{ appear: true, timeout: SLIDE_ANIMATION_DURATION }}
    >
      <ComposableDrawerWithStickyFooter.Header
        title={intl.formatMessage(commonMessages.editReport)}
        onClose={onClose}
        sx={styles.formHeader}
      />
      {report && <EditReportForm report={report} onClose={onClose} />}
    </ComposableDrawerWithStickyFooter>
  );
}
