import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { faCircleCheck } from '@trustyou/fortawesome/pro-solid-svg-icons';
import { StyledFontAwesomeIcon as Icon, LoadingPlaceholder } from '@trustyou/ui';

import personUploadingFile from '../../assets/illustrations/person-uploading-file.svg';

const MatchedEntities = () => (
  <LoadingPlaceholder
    img={personUploadingFile}
    title={
      <FormattedMessage
        id="matchedEntities.inOurDatabase"
        defaultMessage="Matching your entities in our database..."
      />
    }
    description={
      <FormattedMessage
        id="matchedEntities.loaderMessage"
        defaultMessage="We're uploading and processing your data. This may take a few minutes."
      />
    }
    loaderContainer={{ marginTop: 6 }}
    loaderHeader={{ marginTop: 3 }}
    loaderIcon={{ display: 'none' }}
  >
    <Icon size="2x" icon={faCircleCheck} sx={{ color: 'success.main', marginTop: 5 }} />
  </LoadingPlaceholder>
);

export default MatchedEntities;
