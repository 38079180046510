import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  columnContainer: {
    gap: 3,
    maxWidth: '302px',
  },
  rowContainer: {
    flexDirection: 'row',
    gap: 2,
  },
  formControl: {
    width: '100%',
  },
  timePicker: {
    '& .MuiInputBase-root': {
      height: '41px',
    },
  },
  dayOfMonthPaper: { maxHeight: 210 },
};

export default styles;
