import { FormattedMessage } from 'react-intl';

import type { Segment } from '@trustyou/shared';
import { DeleteModal } from '@trustyou/ui';

type SegmentDeleteModalProps = {
  segment?: Segment;
  onClose: () => void;
  onDelete: () => void;
};

const DeleteSegments = ({ segment, onClose, onDelete }: SegmentDeleteModalProps) => (
  <DeleteModal
    cancelText={<FormattedMessage id="organization.segments.cancel" defaultMessage="Cancel" />}
    content={
      <FormattedMessage
        id="organization.segments.deleteSegmentContent"
        defaultMessage="You are deleting the following segment: {segment}. If you confirm all of the groups belonging to this segment will also be removed from the organization and user scopes"
        values={{ segment: segment?.name }}
      />
    }
    dataName="segment-delete"
    deleteText={<FormattedMessage id="organization.segments.delete" defaultMessage="Delete" />}
    onClose={onClose}
    onDelete={onDelete}
    title={
      <FormattedMessage id="organization.segments.deleteSegment" defaultMessage="Delete segment?" />
    }
    open={!!segment}
  />
);

export default DeleteSegments;
