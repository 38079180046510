import { FormattedMessage } from 'react-intl';

import { Tab, Tabs } from '@trustyou/ui';

import { useLeaveDialog } from '../../hooks';
import { useStore } from '../../store/store';
import { ResponseTabsEnum } from '../../types';

export type ResponseFormTabsProps = {
  currentTab: ResponseTabsEnum;
  shouldShowResponseAI: boolean;
  onChange: (tab: ResponseTabsEnum) => void;
};

export const ResponseTabs = ({
  currentTab,
  shouldShowResponseAI,
  onChange,
}: ResponseFormTabsProps) => {
  const isResponseFormDirty = useStore.use.isResponseFormDirty();
  const { renderLeaveDialog, canContinue } = useLeaveDialog({
    showDialog: isResponseFormDirty,
  });

  const onChangeHandle = async (tab: ResponseTabsEnum) => {
    if (await canContinue()) {
      onChange(tab);
    }
  };

  return (
    <>
      <Tabs
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
        value={currentTab}
        onChange={(_, value) => {
          onChangeHandle(value);
        }}
      >
        {shouldShowResponseAI && (
          <Tab
            label={<FormattedMessage id="inbox.responseAI" defaultMessage="ResponseAI" />}
            value={ResponseTabsEnum.RESPONSE_AI}
          />
        )}
        <Tab
          label={<FormattedMessage id="inbox.template" defaultMessage="Template" />}
          value={ResponseTabsEnum.TEMPLATE}
        />
        <Tab
          label={<FormattedMessage id="inbox.blank" defaultMessage="Blank" />}
          value={ResponseTabsEnum.BLANK}
        />
      </Tabs>
      {renderLeaveDialog()}
    </>
  );
};
