import type { PaginationRequest } from '../../../../types/model/shared';
import type {
  CombinedUserResponse,
  InviteOwner,
  InviteServiceUser,
  InviteUser,
  UpdateInvitation,
  UpdateUser,
  User,
  UserSettings,
  UsersResponse,
} from '../../../../types/model/user';
import { $assApi } from '../../../api';
import { ADMIN_ORGANIZATION_BASE_PATH, ADMIN_USERS_BASE_PATH, USERS_BASE_PATH } from '../../path';

export async function inviteUser(user: InviteUser): Promise<InviteUser> {
  const { data } = await $assApi.post(`${USERS_BASE_PATH}/invite_user`, user);
  return data;
}

export async function updateInvitation(user: UpdateInvitation): Promise<UpdateInvitation> {
  const { data } = await $assApi.post(`${USERS_BASE_PATH}/update_invitation`, user);
  return data;
}

export async function resendInvitation(invitationId: string): Promise<string> {
  const { data } = await $assApi.post(`${USERS_BASE_PATH}/resend_invitation`, {
    invitation_id: invitationId,
  });
  return data;
}

export async function deleteInvitation(invitationId: string): Promise<string> {
  const { data } = await $assApi.delete(`${USERS_BASE_PATH}/delete_invitation`, {
    params: {
      invitation_id: invitationId,
    },
  });
  return data;
}

export async function inviteOwner(user: InviteOwner): Promise<InviteOwner> {
  const { data } = await $assApi.post(`${ADMIN_ORGANIZATION_BASE_PATH}/invite_user`, user);
  return data;
}

export async function inviteServiceUser(user: InviteServiceUser): Promise<InviteServiceUser> {
  const { data } = await $assApi.post(`${ADMIN_USERS_BASE_PATH}/invite_service_user`, user);
  return data;
}

export async function updateUser(user: UpdateUser): Promise<UpdateUser> {
  const { data } = await $assApi.post(`${USERS_BASE_PATH}/edit_user`, user);
  return data;
}

export async function deleteUser(membershipId: string): Promise<string> {
  const { data } = await $assApi.delete(`${USERS_BASE_PATH}/delete_user`, {
    params: {
      membership_id: membershipId,
    },
  });
  return data;
}

export async function deleteServiceUser(userId: string): Promise<string> {
  const { data } = await $assApi.delete(`${ADMIN_USERS_BASE_PATH}/delete_service_user`, {
    params: {
      user_id: userId,
    },
  });
  return data;
}

export async function fetchGlobalUsers(): Promise<User[]> {
  const { data } = await $assApi.get(`${ADMIN_USERS_BASE_PATH}/get_users`);
  return data.data;
}

export async function fetchUsersByOrganization(
  { limit, offset }: PaginationRequest,
  sortKey: string,
  searchKey?: string,
  organizationId?: string
): Promise<UsersResponse> {
  let url = `${USERS_BASE_PATH}/get_users`;
  if (organizationId) {
    url = url.replace('{organization_id}', organizationId);
  }
  const { data } = await $assApi.get(url, {
    params: {
      limit,
      offset,
      order: sortKey,
      ...(searchKey && { q: searchKey }),
    },
  });
  return data;
}

export async function updateUsersSettings(userSettings: UserSettings): Promise<UserSettings> {
  const { data } = await $assApi.post(
    `${USERS_BASE_PATH}/update_user_settings`,
    userSettings.settings
  );
  return data;
}

export async function fetchUserSettings(): Promise<UserSettings> {
  const { data } = await $assApi.get(`${USERS_BASE_PATH}/get_user_settings`);
  return data;
}

export async function fetchGlobalOrganizationUsers(
  { limit, offset }: PaginationRequest,
  sortKey: string,
  searchKey?: string,
  organizationId?: string
): Promise<UsersResponse> {
  const { data } = await $assApi.get(`${ADMIN_USERS_BASE_PATH}/get_organization_users`, {
    params: {
      limit,
      offset,
      order: sortKey,
      ...(searchKey && { q: searchKey }),
      ...(organizationId && { organization_id: organizationId }),
    },
  });
  return data;
}

export async function fetchGlobalServiceUsers(
  { limit, offset }: PaginationRequest,
  sortKey: string,
  searchKey?: string
): Promise<UsersResponse> {
  const { data } = await $assApi.get(`${ADMIN_USERS_BASE_PATH}/get_service_users`, {
    params: {
      limit,
      offset,
      order: sortKey,
      ...(searchKey && { q: searchKey }),
    },
  });
  return data;
}

export async function fetchCombinedUsersByOrganization(
  organizationId: string,
  { limit, offset }: PaginationRequest,
  sortKey: string,
  searchFields?: Record<string, string | undefined>
): Promise<CombinedUserResponse> {
  let url = `${USERS_BASE_PATH}/get_combined_users_and_invitations`;
  url = url.replace('{organization_id}', organizationId);
  const { data } = await $assApi.get(url, {
    params: {
      limit,
      offset,
      order: sortKey,
      ...(searchFields?.searchKey && { q: searchFields.searchKey }),
      ...(searchFields?.scopeId && { scope_id: searchFields.scopeId }),
    },
  });
  return data;
}

export async function fetchCombinedUsersByOrganizationAdmin(
  organizationId: string,
  { limit, offset }: PaginationRequest,
  sortKey: string,
  searchKey?: string
): Promise<CombinedUserResponse> {
  const { data } = await $assApi.get(
    `${ADMIN_ORGANIZATION_BASE_PATH}/get_combined_users_and_invitations`,
    {
      params: {
        organization_id: organizationId,
        limit,
        offset,
        order: sortKey,
        ...(searchKey && { q: searchKey }),
      },
    }
  );
  return data;
}
