import {
  faFaceAngry,
  faFaceFrown,
  faFaceGrinHearts,
  faMeh,
  faSmile,
} from '@trustyou/fortawesome/pro-regular-svg-icons';

import type { Option } from '../types';

export const OneToFiveOptions: Option[] = [
  { value: 1, label: 'Inadequate', color: 'error', icon: faFaceAngry },
  { value: 2, label: 'Adequate', color: 'error', icon: faFaceFrown },
  { value: 3, label: 'Satisfactory', color: 'warning', icon: faMeh },
  { value: 4, label: 'Good', color: 'success', icon: faSmile },
  { value: 5, label: 'Very good', color: 'success', icon: faFaceGrinHearts },
];

export const OneToTenOptions: Option[] = [
  { value: 1, label: 'Very inadequate', color: 'error', icon: faFaceAngry },
  { value: 2, label: 'Inadequate', color: 'error', icon: faFaceAngry },
  { value: 3, label: 'Less adequate', color: 'error', icon: faFaceFrown },
  { value: 4, label: 'Adequate', color: 'error', icon: faFaceFrown },
  { value: 5, label: 'Average', color: 'warning', icon: faMeh },
  { value: 6, label: 'Satisfactory', color: 'warning', icon: faMeh },
  { value: 7, label: 'Very satisfactory', color: 'success', icon: faSmile },
  { value: 8, label: 'Good', color: 'success', icon: faSmile },
  { value: 9, label: 'Very good', color: 'success', icon: faFaceGrinHearts },
  { value: 10, label: 'Excellent', color: 'success', icon: faFaceGrinHearts },
];

export const ZeroToTenOptions: Option[] = [
  { value: 0, label: 'Never', color: 'error', icon: faFaceAngry },
  { value: 1, label: 'Almost never', color: 'error', icon: faFaceAngry },
  { value: 2, label: 'Very unlikely', color: 'error', icon: faFaceAngry },
  { value: 3, label: 'Unlikely', color: 'error', icon: faFaceFrown },
  { value: 4, label: 'Possibly', color: 'error', icon: faFaceFrown },
  { value: 5, label: 'Maybe', color: 'warning', icon: faMeh },
  { value: 6, label: 'Probably', color: 'warning', icon: faMeh },
  { value: 7, label: 'Highly probably', color: 'success', icon: faSmile },
  { value: 8, label: 'Likely', color: 'success', icon: faSmile },
  { value: 9, label: 'Very likely', color: 'success', icon: faFaceGrinHearts },
  { value: 10, label: 'Sure', color: 'success', icon: faFaceGrinHearts },
];
