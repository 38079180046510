import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, Stack, Typography } from '@trustyou/ui';

type FilterDialogButtonProps = {
  title: ReactNode;
  description: ReactNode;
  onClick: () => void;
};

export function FilterDialogButton({ title, description, onClick }: FilterDialogButtonProps) {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
      <Stack alignContent="center">
        <Typography variant="body1">{title}</Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </Stack>
      <Button
        sx={{ minWidth: 90 }}
        size="medium"
        color="secondary"
        variant="outlined"
        onClick={onClick}
      >
        <FormattedMessage id="inbox.action.change" defaultMessage="Change" />
      </Button>
    </Stack>
  );
}
