import type { SxProps, Theme } from '@mui/material';
import { Box } from '@trustyou/ui';

type AsteriskProps = {
  sx?: SxProps<Theme>;
};

export function Asterisk({ sx }: AsteriskProps) {
  return (
    <Box component="span" sx={{ marginLeft: 0.5, color: 'error.main', ...sx }}>
      *
    </Box>
  );
}
