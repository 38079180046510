import { FormattedMessage } from 'react-intl';

import {
  USER_ROLES,
  roleDefinitions,
  useChangelingStore,
  useMembershipStore,
} from '@trustyou/shared';
import { Alert, Box, Button, Typography } from '@trustyou/ui';

import useCustomersLogout from '../useCustomersLogout';

const useAlertChangeling = () => {
  const { isChangeling, userInfo } = useChangelingStore();
  const { getMembershipDetails } = useMembershipStore();
  const logoutHandler = useCustomersLogout();

  const displayOwner = (
    <Typography component="span" variant="body2" sx={{ textTransform: 'lowercase' }}>
      <FormattedMessage
        id={roleDefinitions[USER_ROLES.OWNER].id}
        defaultMessage={roleDefinitions[USER_ROLES.OWNER].defaultMessage}
      />
    </Typography>
  );

  const displayUser = userInfo?.isOwner ? displayOwner : userInfo?.name || userInfo?.email;

  const organizationName = getMembershipDetails()?.organization?.name;

  return {
    JSX: isChangeling ? (
      <Alert
        severity="info"
        sx={{
          position: 'fixed',
          top: 0,
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          maxHeight: '65px',
          zIndex: 10000,
          '& > .MuiAlert-message': {
            display: 'flex',
            flex: 1,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pl: 2,
            pr: 2,
            flex: 1,
          }}
        >
          <Typography variant="body2">
            <FormattedMessage
              id="changeling.msgChangelingDetails"
              defaultMessage="Impersonating {displayUser} of {organizationName}"
              values={{ displayUser, organizationName }}
            />
          </Typography>
          <Button variant="outlined" data-testid="changeling-test" onClick={logoutHandler}>
            <FormattedMessage
              id="changeling.msgStopImpersonation"
              defaultMessage="Stop Impersonation"
            />
          </Button>
        </Box>
      </Alert>
    ) : null,
  };
};

export default useAlertChangeling;
