import { faChevronRight } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Box, StyledFontAwesomeIcon as Icon } from '@trustyou/ui';

import styles from './styles';

export const ListSeparator = () => (
  <Box sx={styles.container}>
    <Box sx={styles.arrow}>
      <Icon size="lg" icon={faChevronRight} />
    </Box>
  </Box>
);
