import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useLanguageStore } from '@trustyou/shared';
import { MenuItem, Stack, Typography } from '@trustyou/ui';

import { useContents } from '../../../../service/hooks/use-questions';
import { CustomTextFieldWithSeparatedLabel } from '../../../shared';

export function EditorWelcomeMessageContent() {
  const intl = useIntl();
  const { register, setValue, watch } = useFormContext();
  const { locale } = useLanguageStore();
  const userSettingLang = locale.split('-')[0];
  const { data: welcomeMessages = [] } = useContents({ types: ['welcome_message'] });
  const [allWelcomeMessages, setAllWelcomeMessages] = useState(welcomeMessages);

  const welcomeMessageId = watch('surveyEditorWelcomeMessage')?.id || 'no-welcome-message';

  useEffect(() => {
    register('surveyEditorWelcomeMessage');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAllWelcomeMessages([
      {
        id: 'no-welcome-message',
        title: {
          en: intl.formatMessage({
            id: 'survey.survey-editor.welcome.no-message-title',
            defaultMessage: 'No welcome message',
          }),
        },
        metadata: { organization_id: '' },
        internal_name: {},
        description: {},
        primitive: {},
      },
      ...welcomeMessages,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [welcomeMessages]);

  return (
    <Stack spacing={4}>
      <Stack>
        <Typography variant="h6">
          <FormattedMessage
            id="survey.survey-editor.welcome-message.title"
            defaultMessage="Welcome message"
          />
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <FormattedMessage
            id="survey.survey-editor.welcome-message.description"
            defaultMessage="Optional message that is displayed at the very beginning of the Survey. You can create and edit Welcome messages in the Content library."
          />
        </Typography>
      </Stack>
      <CustomTextFieldWithSeparatedLabel
        select
        size="small"
        value={welcomeMessageId}
        onChange={(e) => {
          setValue(
            'surveyEditorWelcomeMessage',
            allWelcomeMessages.find((item) => item.id === e.target.value)
          );
        }}
      >
        {allWelcomeMessages.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.id === 'no-welcome-message'
              ? item.title?.['en']
              : (item.title?.[userSettingLang] ?? item.title?.[item.default_language ?? 'en'])}
          </MenuItem>
        ))}
      </CustomTextFieldWithSeparatedLabel>
    </Stack>
  );
}
