import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import type {
  CreateGroup,
  CreateGroupResponse,
  SegmentGroupCreated,
  SegmentGroupPayload,
} from '../../../../types/model/segment';
import type { PaginationRequest } from '../../../../types/model/shared';
import {
  CREATE_GROUP,
  DELETE_GROUP,
  FETCH_GROUP_ENTITIES,
  FETCH_INFINITE_SEGMENT_GROUPS,
  FETCH_SEGMENTS,
  FETCH_SEGMENT_GROUPS,
  IS_VALID_GROUP,
  UPDATE_GROUP,
} from '../../../constants/queryKeys';
import {
  createGroup,
  deleteGroup,
  fetchSegmentGroups,
  isValidGroupName,
  searchSegmentGroups,
  updateGroup,
} from '../../../queries';

export function useSegmentGroups(
  segmentId: string,
  pagination: PaginationRequest,
  sortKey: string
) {
  return useQuery({
    queryKey: [FETCH_SEGMENT_GROUPS, { segmentId, pagination, sortKey }],
    queryFn: () => fetchSegmentGroups(segmentId, pagination, sortKey),
    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useInfiniteSegmentGroups(
  segmentId: string,
  pageSize: number,
  sortKey: string,
  searchKey?: string
) {
  return useInfiniteQuery({
    queryKey: [FETCH_INFINITE_SEGMENT_GROUPS, { limit: pageSize, sortKey, searchKey }],
    queryFn: ({ pageParam = 0 }) =>
      searchSegmentGroups(segmentId, { offset: pageParam, limit: pageSize }, sortKey, searchKey),
    initialPageParam: 0,
    refetchOnWindowFocus: false,
    enabled: true,
    getNextPageParam: (lastPage) => {
      const newOffset = lastPage.pagination.offset + pageSize;
      return newOffset < lastPage.pagination.total ? newOffset : undefined;
    },
  });
}

export function useDeleteGroup(
  onSuccess: (data: string) => void,
  onError?: (error: Error) => void,
  onSettled?: () => void
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (groupId: string) => deleteGroup(groupId),
    mutationKey: [DELETE_GROUP],
    onSuccess: (data: string) => {
      queryClient.refetchQueries({ queryKey: [FETCH_SEGMENTS] });
      queryClient.refetchQueries({ queryKey: [FETCH_SEGMENT_GROUPS] });
      onSuccess(data);
    },
    onError,
    onSettled,
  });
}

export function useCreateGroup(
  onSuccess: (data: CreateGroupResponse) => void,
  onError?: (error: AxiosError) => void,
  onSettled?: () => void
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (group: CreateGroup) => createGroup(group),
    mutationKey: [CREATE_GROUP],
    onSuccess: (data: CreateGroupResponse) => {
      queryClient.refetchQueries({ queryKey: [FETCH_SEGMENTS] });
      queryClient.refetchQueries({ queryKey: [FETCH_SEGMENT_GROUPS] });
      onSuccess(data);
    },
    onError,
    onSettled,
  });
}

export function useUpdateGroup(
  onSuccess: (data: SegmentGroupCreated) => void,
  onError?: (error: AxiosError) => void,
  onSettled?: () => void
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (group: SegmentGroupPayload) => updateGroup(group),
    mutationKey: [UPDATE_GROUP],
    onSuccess: (data: SegmentGroupCreated) => {
      queryClient.refetchQueries({ queryKey: [FETCH_GROUP_ENTITIES] });
      queryClient.refetchQueries({ queryKey: [FETCH_SEGMENT_GROUPS] });
      onSuccess(data);
    },
    onError,
    onSettled,
  });
}

export function useValidGroupName() {
  return useMutation({
    mutationFn: ({ name, segmentId }: { name: string; segmentId: string }) =>
      isValidGroupName(name, segmentId),
    mutationKey: [IS_VALID_GROUP],
  });
}
