import { useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useDebounceEffect(fn: (...args: any[]) => void, waitTime: number, deps: any[]) {
  useEffect(() => {
    const timeout = setTimeout(() => {
      fn(...deps);
    }, waitTime);

    return () => {
      clearTimeout(timeout);
    };
  }, [deps, fn, waitTime]);
}
