export const trackDashboardLoading = (id: string, title: string, startTime: Date) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { dataLayer } = window as any;
  if (!dataLayer) return;
  dataLayer.push({
    event: 'dashboardLoad',
    dashboardId: id,
    dashboardName: title,
    loadingStartTime: startTime,
    loadingEndTime: new Date(),
  });
};
