import { type UIEventHandler, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useQueryClient } from '@tanstack/react-query';
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_SORT_KEY,
  FETCH_INFINITE_SEGMENT_GROUPS,
  type Segment,
  type SegmentGroup,
  isScrollEnd,
  nothingFound,
  searchPlaceholders,
  useInfiniteSegmentGroups,
} from '@trustyou/shared';
import {
  Checkbox,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListState,
  SearchBar,
  Stack,
  Typography,
} from '@trustyou/ui';

import styles from './styles';

type GroupListProps = {
  segment: Segment;
  selectedGroups: string[];
  onGroupSelect: (group: SegmentGroup[]) => void;
};

const GROUP_SEARCH_DEBOUNCE_TIME = 500;

const GroupList = ({ segment, selectedGroups, onGroupSelect }: GroupListProps) => {
  const queryClient = useQueryClient();
  const [searchKey, setSearchKey] = useState<string>();
  const intl = useIntl();

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isPending: isLoading,
  } = useInfiniteSegmentGroups(segment.id, DEFAULT_PAGE_SIZE, DEFAULT_SORT_KEY, searchKey);

  const onScrollReact: UIEventHandler<HTMLUListElement> = (event) => {
    if (hasNextPage && isScrollEnd(event.target as HTMLElement)) {
      fetchNextPage();
    }
  };

  const groups = data?.pages.map((page) => page.data).flat() || [];

  const onSearch = (value: string) => {
    setSearchKey(value);
    queryClient.removeQueries({ queryKey: [FETCH_INFINITE_SEGMENT_GROUPS] });
  };

  return (
    <Stack sx={styles.groupListContainer}>
      <SearchBar
        placeholder={intl.formatMessage(searchPlaceholders.searchGroups)}
        onSearch={onSearch}
        debounceTime={GROUP_SEARCH_DEBOUNCE_TIME}
        id="groups"
      />
      {!groups.length ? (
        <ListState isLoading={isLoading} emptyMessage={intl.formatMessage(nothingFound.noGroups)} />
      ) : (
        <List sx={{ ...styles.list, ...styles.groupList }} onScroll={onScrollReact}>
          {searchKey && (
            <ListItemButton onClick={() => onGroupSelect(groups)} sx={styles.listItem}>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" fontWeight="500">
                    <FormattedMessage
                      id="organization.users.selectGroupSearchResults"
                      defaultMessage={'Select all results ({count})'}
                      values={{ count: groups.length }}
                    />
                  </Typography>
                }
              />
              <ListItemIcon sx={{ minWidth: 'unset' }}>
                <Checkbox
                  edge="start"
                  checked={
                    selectedGroups.length > 0 &&
                    groups.map((row) => row.id).every((id) => selectedGroups.includes(id))
                  }
                  disableRipple
                />
              </ListItemIcon>
            </ListItemButton>
          )}
          {groups.map((group) => (
            <ListItemButton
              key={group.id}
              onClick={() => onGroupSelect([group])}
              data-testid="group-item"
              sx={styles.listItem}
            >
              <ListItemText primary={group.name} />
              <ListItemIcon sx={{ minWidth: 'unset' }}>
                <Checkbox edge="start" checked={selectedGroups.includes(group.id)} disableRipple />
              </ListItemIcon>
            </ListItemButton>
          ))}
        </List>
      )}
    </Stack>
  );
};

export default GroupList;
