import { useLocation } from 'react-router-dom';

import type { NodeType } from '../types';

export const useActiveDeepNode = (nodes: NodeType[]) => {
  const { pathname } = useLocation();
  let activeNode: NodeType | undefined;
  nodes.forEach((node) => {
    if (node.path && pathname.includes(node.path)) {
      activeNode = node;
    }
    if (node.childNodes) {
      const childActiveNode = node.childNodes.find(
        (childNode) => childNode.path && pathname.includes(childNode.path)
      );
      if (childActiveNode) {
        activeNode = childActiveNode;
      }
    }
  });
  return activeNode;
};
