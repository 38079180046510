import type { StateCreator } from 'zustand';

import type { ListItem } from '../../types/question';

export interface QuestionEditorSliceState {
  isQuestionTypeSelectorOpen: boolean;
  setIsQuestionTypeSelectorOpen: (isQuestionTypeSelectorOpen: boolean) => void;

  initialQuestionType: string;
  setInitialQuestionType: (questionType: string) => void;

  initialQuestionValueItem: ListItem;
  setInitialQuestionValueItem: (initialQuestionValueItem: ListItem) => void;
}

export const createQuestionTypeSlice: StateCreator<QuestionEditorSliceState, [], []> = (set) => ({
  isQuestionTypeSelectorOpen: false,
  setIsQuestionTypeSelectorOpen: (isQuestionTypeSelectorOpen) =>
    set(() => ({ isQuestionTypeSelectorOpen })),

  initialQuestionType: '',
  setInitialQuestionType: (initialQuestionType) => set(() => ({ initialQuestionType })),

  initialQuestionValueItem: {} as ListItem,
  setInitialQuestionValueItem: (initialQuestionValueItem) =>
    set(() => ({ initialQuestionValueItem })),
});
