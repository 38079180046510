import { useState } from 'react';

import type { GridPaginationModel } from '@mui/x-data-grid-pro';
import { DEFAULT_PAGE_SIZE, useDataGridIntl, useLanguageStore } from '@trustyou/shared';
import { DataGridPro } from '@trustyou/ui';

import { useColumns } from './use-columns.hook';

import themeDetailByEntity from '../../../../mocks/theme-detail.mock.json';
import { CustomToolbar } from '../../../shared/custom-toolbar';
import styles from '../../../shared/styles';

// import { useEntities } from '../../../../service/hooks/use-entities';

export function DataGrid() {
  const dataGridIntl = useDataGridIntl();
  const { locale } = useLanguageStore();
  // const { surveyId = '', questionnaireId = MOCK_QUESTIONNAIRE_ID } = useParams();
  const columns = useColumns();
  // const { data: rows = [] } = useEntities({ surveyId, questionnaireId });
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  return (
    <DataGridPro
      columns={columns}
      // TODO: Replace the mock with `rows` from `useEntities`.
      rows={themeDetailByEntity}
      pageSizeOptions={[15, 50, 100]}
      pagination
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      ignoreDiacritics
      disableRowSelectionOnClick
      slots={{
        toolbar: CustomToolbar,
      }}
      sx={styles.disableCellOutline}
      density="compact"
      localeText={dataGridIntl[locale].components.MuiDataGrid.defaultProps.localeText}
      initialState={{
        columns: {
          columnVisibilityModel: {
            id: false,
          },
        },
      }}
    />
  );
}
