import { FormattedMessage } from 'react-intl';

import { useQueryClient } from '@tanstack/react-query';
import { useAlertStore } from '@trustyou/shared';
import { DateTime } from 'luxon';

import { ReportForm } from './ReportForm';
import { useCreateScheduledReport } from './hooks/reports';
import { reportQueryKeys } from './hooks/reportsQueryKeys';

export const NewReport = ({
  dashboardId,
  onSuccess,
  onCancel,
  enabledFormats,
}: {
  dashboardId: string;
  onSuccess: () => void;
  onCancel: () => void;
  enabledFormats?: string[];
}) => {
  const { alert } = useAlertStore();
  const queryClient = useQueryClient();
  const { mutate: createScheduledReport, isPending: isLoading } = useCreateScheduledReport(
    dashboardId,
    () => {
      alert.success(
        <FormattedMessage
          id="analytics.reports.new.schedule-success"
          defaultMessage="Report scheduled successfully"
        />
      );
      queryClient.invalidateQueries({
        queryKey: reportQueryKeys.lists(),
      });
      onSuccess?.();
    },
    () => {
      alert.genericError();
    }
  );

  return (
    <ReportForm
      dashboardId={dashboardId}
      enabledFormats={enabledFormats}
      isLoading={isLoading}
      onCancel={onCancel}
      onSubmit={(form) => {
        const timeOfDay = DateTime.fromISO(form.timeOfDay);
        const dayOfMonth = form.dayOfMonth ? form.dayOfMonth : null;
        createScheduledReport({
          disable: false,
          user_ids: form.recipients.map((recipient) => recipient.value).join(','),
          report_name: form.name,
          hour: timeOfDay.hour,
          period: form.frequency,
          day_of_week: form.dayOfWeek ? form.dayOfWeek - 1 : null,
          day: dayOfMonth,
          timezone: timeOfDay.zone.name,
          file_format: form.fileFormat,
          dashboard_filters: form.dashboardFilters,
          excluded_filters: form.excludedFilters,
          language: form.language,
          widget_id: '',
        });
      }}
    />
  );
};
