import { forwardRef } from 'react';

import MuiTextField, { type TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';

import type { E2ECouldTest } from '../types/e2e';

export type TextFieldProps = MuiTextFieldProps & E2ECouldTest;

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(({ ...props }, ref) => {
  return <MuiTextField {...props} ref={ref} />;
});

TextField.displayName = 'TextField';
