import { useIntl } from 'react-intl';

import { ALL_REVIEWS_VIEW, LAST_SEVEN_DAYS_VIEW } from '@trustyou/shared';

export function usePredefinedViews() {
  const intl = useIntl();

  return {
    allReviewsView: {
      ...ALL_REVIEWS_VIEW,
      label: intl.formatMessage({ id: 'inbox.view.all-reviews', defaultMessage: 'All reviews' }),
    },
    lastSevenDaysView: {
      ...LAST_SEVEN_DAYS_VIEW,
      label: intl.formatMessage({
        id: 'inbox.view.last-seven-days',
        defaultMessage: 'Last 7 days',
      }),
    },
  };
}
