import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { useQueryClient } from '@tanstack/react-query';
import { faArrowRotateRight } from '@trustyou/fortawesome/pro-regular-svg-icons';

import { PAGINATED_REVIEWS } from '../../constants/query-keys';

export const RefreshButton = () => {
  const queryClient = useQueryClient();
  const [loading, setRefreshing] = useState(false);

  const handleRefresh = async () => {
    setRefreshing(true);
    await queryClient.invalidateQueries({ queryKey: [PAGINATED_REVIEWS] });
    setRefreshing(false);
  };

  return (
    <LoadingButton
      size="small"
      startIcon={<FontAwesomeIcon icon={faArrowRotateRight} />}
      loading={loading}
      loadingPosition="start"
      onClick={handleRefresh}
    >
      <FormattedMessage id="inbox.refresh" defaultMessage="Refresh" />
    </LoadingButton>
  );
};
