import { type SyntheticEvent, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { type Entity, commonFields, useEntity } from '@trustyou/shared';
import {
  Box,
  EntityCustomAttributesView,
  EntityInfoBanner,
  EntitySourcesView,
  Stack,
  Tab,
  Tabs,
} from '@trustyou/ui';

import { useUpdateEntityCustomAttributesPermission } from '../../hooks';
import { EntityCompetitorsView } from '../EntityCompetitorsView/EntityCompetitorsView';
import { EntityCustomAttributesUpdateDialog } from '../EntityCustomAttributesUpdateDialog/EntityCustomAttributesUpdateDialog';
import styles from './styles';

type EntityDetailViewProps = {
  entityId: string;
  presetEntity?: Entity;
  showCompetitors?: boolean;
  showEditCustomAttributes?: boolean;
  onAddCompetitors?: () => void;
};

const TABS = {
  details: <FormattedMessage {...commonFields.details} />,
  sources: <FormattedMessage {...commonFields.sources} />,
  competitors: <FormattedMessage {...commonFields.competitors} />,
};

export const EntityDetailView = ({
  entityId,
  presetEntity,
  showCompetitors = false,
  showEditCustomAttributes = true,
  onAddCompetitors,
}: EntityDetailViewProps) => {
  const [value, setValue] = useState(0);
  const [isAttributesUpdateDialogOpen, setIsAttributesUpdateDialogOpen] = useState(false);
  const { data: actualEntity } = useEntity(entityId);
  const canUpdateCustomAttributes = useUpdateEntityCustomAttributesPermission();

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const entity = actualEntity || presetEntity;

  if (!entity) {
    // TODO: Display loader.
    return null;
  }

  return (
    <>
      <Box sx={styles.container}>
        <Tabs sx={styles.barContainer} value={value} onChange={handleChange}>
          {Object.entries(TABS)
            .filter(([id]) => id !== 'competitors' || showCompetitors)
            .map(([id, message]) => (
              <Tab data-testid={`entity-detail-tab-${message}`} key={id} label={message} />
            ))}
        </Tabs>
        <Box sx={styles.body}>
          {value === 0 && (
            <Stack gap={3}>
              <EntityInfoBanner entity={entity} />
              <EntityCustomAttributesView
                attributes={entity.custom_attributes}
                onEdit={
                  showEditCustomAttributes && canUpdateCustomAttributes
                    ? () => setIsAttributesUpdateDialogOpen(true)
                    : undefined
                }
              />
            </Stack>
          )}
          {value === 1 && <EntitySourcesView sourcesUrls={entity.sources_urls || []} />}
          {value === 2 && showCompetitors && (
            <EntityCompetitorsView entity={entity} onAddCompetitors={onAddCompetitors} />
          )}
        </Box>
      </Box>
      {isAttributesUpdateDialogOpen && (
        <EntityCustomAttributesUpdateDialog
          entityId={entity.id}
          onClose={() => setIsAttributesUpdateDialogOpen(false)}
        />
      )}
    </>
  );
};
