import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  body: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  emailContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    maxWidth: '480px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  dialog: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        maxWidth: '1000px',
      },
    },
  },
  changelingMode: {
    pt: 8,
  },
};

export default styles;
