import { create } from 'zustand';

import { type Entity, GlobalEntitiesViewTypes } from '../../types/model/entity';

export type GlobalEntityView = {
  entity?: Entity;
  type: GlobalEntitiesViewTypes;
  isDeleting?: boolean;
  isDiscarding?: boolean;
};

type GlobalEntityNavState = {
  view: GlobalEntityView;
  setView: (view: GlobalEntityView) => void;
  navToList: () => void;
};

export const useGlobalEntitiesNavStore = create<GlobalEntityNavState>((set) => ({
  view: {
    type: GlobalEntitiesViewTypes.LIST,
    entity: undefined,
  },
  setView: (view) => set({ view }),
  navToList: () => set({ view: { type: GlobalEntitiesViewTypes.LIST } }),
}));
