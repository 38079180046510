import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation, useParams } from 'react-router-dom';

import { faPencil, faPlus } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  type CombinedUserAndInvitation,
  type Subscription,
  commonFields,
  useRouteUtils,
  useSubscription,
} from '@trustyou/shared';
import {
  Box,
  Button,
  CircularProgress,
  ContentPane,
  IconButton,
  StyledFontAwesomeIcon,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@trustyou/ui';

import { SubscriptionEntities, SubscriptionInfoPanel, SubscriptionMembers } from './Common';
import EditSubscription from './Common/EditSubscription';

import { AddEntityDialog, ManageEntitiesButtonBlock } from '../../components';
import { InviteUpdateUser } from '../Organization/Users/components/InviteUpdateUser';
import { styles } from './styles';

type ToggledUser = undefined | null | CombinedUserAndInvitation;

export const GlobalSubscriptionUsage = () => {
  const [user, setUser] = useState<ToggledUser>(null);
  const [editedSubscription, setEditedSubscription] = useState<Subscription | undefined>(undefined);
  const [subscriptionForAdding, setSubscriptionForAdding] = useState<Subscription | undefined>(
    undefined
  );

  const location = useLocation();
  const { chosenSubscriptionId, usage } = useParams();
  const { navigateWithBase } = useRouteUtils();

  const { data: subscription, isPending: isLoading } = useSubscription(
    chosenSubscriptionId as string
  );

  const tabs = [
    { id: 'details', path: '/details', label: commonFields.details },
    { id: 'entities', path: '/entities', label: commonFields.entity },
    { id: 'members', path: '/members', label: commonFields.members },
  ];

  const navigationHandle = () => navigateWithBase('subscriptions');

  const toggleInviteUpdateDialog = (toggledUser: ToggledUser) => () => setUser(toggledUser);

  const handleEditUser = (editedUser: CombinedUserAndInvitation) => {
    toggleInviteUpdateDialog(editedUser)();
  };

  const onEditSubscription = () => setEditedSubscription(subscription);

  const onCloseEditDialog = () => setEditedSubscription(undefined);

  const onAddEntity = () => {
    setSubscriptionForAdding(subscription);
  };

  const closeAddEntityDialog = () => {
    setSubscriptionForAdding(undefined);
  };

  const onImportEntities = () =>
    navigateWithBase(`upload-subscription-entities/${chosenSubscriptionId}`);

  if (isLoading)
    return (
      <ContentPane sx={styles.usagePaneWrapper} navigationHandle={navigationHandle}>
        <Box sx={styles.loader}>
          <CircularProgress />
        </Box>
      </ContentPane>
    );

  if (!subscription) return null;

  const getAdditionalTabContent = (tabId: string) => {
    switch (tabId) {
      case 'entities':
        return ` (${subscription.number_of_consumed_entities}/${subscription.number_of_allowed_entities})`;
      case 'members':
        return ` (${subscription.number_of_consumed_members}/${subscription.number_of_allowed_members})`;
      case 'details':
      default:
        return '';
    }
  };

  const isInviteDisabled =
    subscription.number_of_consumed_members >= subscription.number_of_allowed_members;

  const isManageEntitesShown = subscription.number_of_allowed_entities > 0;

  const isManageEntitiesDisabled =
    subscription.number_of_consumed_entities >= subscription.number_of_allowed_entities;

  return (
    <ContentPane
      sx={styles.usagePaneWrapper}
      navigationHandle={navigationHandle}
      headers={[
        <Box sx={styles.headerOuterWrapper}>
          <Box sx={styles.header}>
            <Typography variant="h6">{subscription.name}</Typography>
            <Typography variant="body2">{subscription.plan_name}</Typography>
          </Box>
          <IconButton
            data-testid="subscription-edit-button"
            size="small"
            onClick={onEditSubscription}
          >
            <StyledFontAwesomeIcon sx={styles.editIcon} icon={faPencil} />
          </IconButton>
        </Box>,
      ]}
    >
      <Box sx={styles.tabsContainer}>
        <Tabs value={usage || 'details'} sx={styles.tabs}>
          {tabs.map((tab) => (
            <Tab
              key={tab.id}
              value={tab.id}
              label={
                <>
                  <FormattedMessage {...tab.label} />
                  {getAdditionalTabContent(tab.id)}
                </>
              }
              component={Link}
              to={location.pathname.replace(/\/[^/]*$/, tab.path)}
            />
          ))}
        </Tabs>
        {usage === 'members' && (
          <Tooltip
            arrow
            placement="left"
            title={
              isInviteDisabled ? (
                <FormattedMessage
                  id="subscriptions.invite-button.tooltip"
                  defaultMessage="To invite more members, upgrade your plan or remove existing members."
                />
              ) : (
                ''
              )
            }
          >
            <Box component="span" sx={{ display: 'inline-block' }}>
              <Button
                data-testid="invite-user"
                data-gtm-id="invite-member-to-subscription"
                startIcon={<StyledFontAwesomeIcon icon={faPlus} />}
                disabled={isInviteDisabled}
                variant="contained"
                onClick={toggleInviteUpdateDialog(undefined)}
              >
                <FormattedMessage
                  id="organization.users.inviteMember"
                  defaultMessage="Invite member"
                />
              </Button>
            </Box>
          </Tooltip>
        )}
        {usage === 'entities' && isManageEntitesShown && (
          <ManageEntitiesButtonBlock
            color="white"
            onImportEntities={onImportEntities}
            onAddEntity={onAddEntity}
            disabled={isManageEntitiesDisabled}
          />
        )}
      </Box>
      {usage === 'details' && (
        <Box sx={styles.detailsWrapper}>
          <SubscriptionInfoPanel subscription={subscription} />
        </Box>
      )}
      {usage === 'entities' && <SubscriptionEntities />}
      {usage === 'members' && <SubscriptionMembers onEdit={handleEditUser} />}
      <InviteUpdateUser
        isDialog
        refetchSubscriptionMembers
        user={user}
        subscription={subscription}
        onCancel={toggleInviteUpdateDialog(null)}
      />
      <EditSubscription subscription={editedSubscription} onClose={onCloseEditDialog} />
      {subscriptionForAdding && (
        <AddEntityDialog subscription={subscriptionForAdding} onClose={closeAddEntityDialog} />
      )}
    </ContentPane>
  );
};
