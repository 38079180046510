import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { blueGrey, lightBlue } from '@mui/material/colors';
import type {
  GridCellParams,
  GridPaginationModel,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import { DEFAULT_PAGE_SIZE, capitalizeFirstLetter, useLanguageStore } from '@trustyou/shared';
import {
  Button,
  Chip,
  DataGrid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@trustyou/ui';

import type { Content_Output } from '../../../../../types/survey';
import { CustomToolbar } from '../../../../shared/custom-toolbar';
import { EDITOR_QUESTION_CONTENT_TYPES } from '../../../constants/survey-editor-content';

type SurveyContentListProps = {
  data: Content_Output[];
  open: boolean;
  rowSelectionModel: GridRowSelectionModel;
  onRowSelectionModelChange: (newSelection: GridRowSelectionModel) => void;
  onCancel: () => void;
  onSubmit: () => void;
};

const columns = (lang: string) => [
  {
    field: 'id',
    headerName: 'ID',
    flex: 1,
    hideable: false,
    disableColumnMenu: true,
  },
  {
    field: 'name',
    headerName: 'Name',
    renderCell: ({ row }: GridCellParams) => (
      <Stack>
        <Typography variant="body2">
          {capitalizeFirstLetter(
            row.internal_name[lang] ?? row.internal_name[row.default_language]
          )}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {row.title[lang] ?? row.title[row.default_language]}
        </Typography>
      </Stack>
    ),
    flex: 1,
  },
  {
    field: 'type',
    headerName: 'Type',
    flex: 1,
    renderCell: ({ row }: GridCellParams) => (
      <Stack sx={{ gap: 1, flexDirection: 'row', alignItems: 'center' }}>
        <Typography variant="body2">
          {
            EDITOR_QUESTION_CONTENT_TYPES[
              row.primitive.repr as keyof typeof EDITOR_QUESTION_CONTENT_TYPES
            ]?.label
          }
        </Typography>
      </Stack>
    ),
  },
  {
    field: 'handling_type',
    headerName: 'Category',
    renderCell: ({ row }: GridCellParams) => (
      <Chip
        label={row.handling_type === 'custom' ? 'CUSTOM' : 'STANDARD'}
        sx={{
          color: row.handling_type === 'custom' ? 'info.main' : blueGrey[600],
          backgroundColor: row.handling_type === 'custom' ? lightBlue[50] : blueGrey[50],
        }}
      />
    ),
    flex: 1,
  },
];

export const SurveyContentList = ({
  data,
  open,
  rowSelectionModel,
  onRowSelectionModelChange,
  onCancel,
  onSubmit,
}: SurveyContentListProps) => {
  const { locale } = useLanguageStore();
  const userSettingLang = locale.split('-')[0];
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  return (
    <Dialog onClose={onCancel} open={open} maxWidth="lg">
      <DialogTitle>
        <FormattedMessage
          id="survey.survey-editor.content-list.dialog.title"
          defaultMessage="Select content to add to the Survey"
        />
        <Typography variant="body2" color="text.secondary">
          <FormattedMessage
            id="survey.survey-editor.content-list.dialog.description"
            defaultMessage="Selected questions will be added to the end of the Survey. You can reorder them after closing this window. Questions can be created and edited in the Question library. Messages are located in the Content library."
          />
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DataGrid
          disableColumnMenu={false}
          rowHeight={64}
          scrollbarSize={5}
          rows={data}
          columns={columns(userSettingLang)}
          columnVisibilityModel={{
            id: false,
          }}
          pagination
          pageSizeOptions={[5, 10, 25]}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          checkboxSelection
          keepNonExistentRowsSelected
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={onRowSelectionModelChange}
          slots={{
            toolbar: () => CustomToolbar({ hideColumns: true }),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="inherit">
          <FormattedMessage id="survey.common.cancel" defaultMessage="Cancel" />
        </Button>
        <Button onClick={onSubmit} autoFocus variant="contained">
          <FormattedMessage id="survey.common.add-selected" defaultMessage="Add selected" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
