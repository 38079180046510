import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import type { TooltipProps } from '@mui/material';
import { StyledFontAwesomeIcon, type StyledFontAwesomeIconProps, Tooltip } from '@trustyou/ui';

export type IconWithTooltipProps = {
  icon: FontAwesomeIconProps['icon'];
  tooltip: TooltipProps['title'];
  size?: FontAwesomeIconProps['size'];
  IconProps?: Partial<StyledFontAwesomeIconProps>;
};

export const IconWithTooltip = ({ tooltip, icon, size, IconProps }: IconWithTooltipProps) => (
  <Tooltip arrow placement="top" title={tooltip}>
    <StyledFontAwesomeIcon icon={icon} size={size} {...IconProps} />
  </Tooltip>
);
