import { useMutation, useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import {
  getEmailNotification,
  getResponseTemplates,
  getSignature,
  setEmailNotification,
  setSignature,
} from './fetchers';

import type { EmailNotificationSettingsIn_Input } from '../../../client';
import { EMAIL_NOTIFICATION, RESPONSE_TEMPLATES, SIGNATURE } from '../../../constants/query-keys';

export function useGetEmailNotification() {
  return useQuery({
    queryKey: [EMAIL_NOTIFICATION],
    queryFn: getEmailNotification,
    refetchOnWindowFocus: false,
  });
}

export function useSetEmailNotification() {
  return useMutation<
    EmailNotificationSettingsIn_Input,
    AxiosError,
    EmailNotificationSettingsIn_Input
  >({
    mutationFn: (payload) => setEmailNotification(payload),
  });
}

export function useGetResponseTemplates() {
  return useQuery({
    queryKey: [RESPONSE_TEMPLATES],
    queryFn: getResponseTemplates,
    refetchOnWindowFocus: false,
  });
}

export function useGetSignature() {
  return useQuery({
    queryKey: [SIGNATURE],
    queryFn: getSignature,
    refetchOnWindowFocus: false,
  });
}

export function useSetSignature(onSuccess: () => void, onError: () => void) {
  return useMutation({
    mutationFn: (signature?: string) => setSignature(signature),
    onSuccess,
    onError,
  });
}
