import { DOMAIN } from '../../../../types/misc/common';
import type {
  AccessTokenResponse,
  DomainsTokenInfo,
  GetMembershipsAndInvitationsResponse,
} from '../../../../types/model/auth';
import { $assApi } from '../../../api';
import {
  ANALYTICS_BASE_PATH,
  AUTH_TOKEN_SERVICE_PATH,
  INBOX_BASE_PATH,
  ORG_BASE_PATH,
  SURVEY_BASE_PATH,
  TOKEN_INFO_PATH,
} from '../../path';

export async function fetchAccessToken({
  keycloakAccessToken,
  membershipId,
}: {
  keycloakAccessToken: string;
  membershipId: string;
}): Promise<AccessTokenResponse> {
  const { data } = await $assApi.post(
    `${AUTH_TOKEN_SERVICE_PATH}/get_access_token`,
    {
      desired_claims: {
        membership_id: membershipId,
      },
    },
    {
      headers: { Authorization: `Bearer ${keycloakAccessToken}` },
    }
  );
  return data;
}

export async function fetchChangelingAccessToken({
  keycloakAccessToken,
  membershipId,
}: {
  keycloakAccessToken: string;
  membershipId: string;
}): Promise<AccessTokenResponse> {
  const { data } = await $assApi.post(
    `${AUTH_TOKEN_SERVICE_PATH}/get_changeling_access_token`,
    {
      membership_id: membershipId,
    },
    {
      headers: { Authorization: `Bearer ${keycloakAccessToken}` },
    }
  );
  return data;
}

export async function fetchTokenInfo(apis: string[]): Promise<DomainsTokenInfo> {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const promisesMapList: [string, Promise<any>][] = [];
  apis.forEach((api) => {
    switch (api) {
      case DOMAIN.ORG_CENTER:
        promisesMapList.push([api, $assApi.get(`${ORG_BASE_PATH}${TOKEN_INFO_PATH}`)]);
        break;
      case DOMAIN.ANALYTICS:
        promisesMapList.push([api, $assApi.get(`${ANALYTICS_BASE_PATH}${TOKEN_INFO_PATH}`)]);
        break;
      case DOMAIN.INBOX:
        promisesMapList.push([api, $assApi.get(`${INBOX_BASE_PATH}${TOKEN_INFO_PATH}`)]);
        break;
      case DOMAIN.SURVEY:
        promisesMapList.push([api, $assApi.get(`${SURVEY_BASE_PATH}${TOKEN_INFO_PATH}`)]);
        break;
    }
  });

  const promises = promisesMapList.map(([domain, promise]) => ({
    domain,
    promise: promise.catch((error) => {
      console.error(`${domain} token_info error: `, error);
      return error;
    }),
  }));

  const data = await Promise.all(promises.map((row) => row.promise)).then((responses) =>
    promises.reduce(
      (prev, curr, index) => ({
        ...prev,
        [curr.domain]: responses[index]?.data,
      }),
      {} as DomainsTokenInfo
    )
  );

  return data;
}

export async function fetchMembershipsAndInvitations({
  keycloakAccessToken,
}: {
  keycloakAccessToken: string;
}): Promise<GetMembershipsAndInvitationsResponse> {
  const { data } = await $assApi.get(`${AUTH_TOKEN_SERVICE_PATH}/get_memberships_and_invitations`, {
    headers: { Authorization: `Bearer ${keycloakAccessToken}` },
  });
  return data.data;
}

export async function acceptInvitation({
  keycloakAccessToken,
  invitationId,
}: {
  keycloakAccessToken: string;
  invitationId: string;
}): Promise<string> {
  const { data } = await $assApi.post(
    `${AUTH_TOKEN_SERVICE_PATH}/accept_invitation`,
    {
      invitation_id: invitationId,
    },
    {
      headers: { Authorization: `Bearer ${keycloakAccessToken}` },
    }
  );
  return data;
}
