import { FormattedMessage } from 'react-intl';

import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
import { faPlus } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Box, Button, StyledFontAwesomeIcon } from '@trustyou/ui';

export const CustomToolbar = ({ newDataPointClick }: { newDataPointClick: () => void }) => {
  return (
    <GridToolbarContainer sx={{ alignItems: 'start', padding: 1 }}>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <Box component="span" flexGrow={1} />
      <Button
        variant="outlined"
        startIcon={<StyledFontAwesomeIcon icon={faPlus} />}
        onClick={newDataPointClick}
      >
        <FormattedMessage id="visit-data.new-data-point" defaultMessage="New data point" />
      </Button>
    </GridToolbarContainer>
  );
};
