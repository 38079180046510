import { FormattedMessage } from 'react-intl';

import type { Entity } from '@trustyou/shared';
import { DeleteModal } from '@trustyou/ui';

type EntityDeleteModalProps = {
  entity?: Entity;
  onClose: () => void;
  onDelete: () => void;
};

const EntityDeleteModal = ({ entity, onClose, onDelete }: EntityDeleteModalProps) => (
  <DeleteModal
    cancelText={<FormattedMessage id="organization.action.cancel" defaultMessage="Cancel" />}
    content={
      <FormattedMessage
        id="organization.deleteEntityContent"
        defaultMessage="You are deleting the following entity: {entity}. If you confirm, the entity will be removed from the organization"
        values={{ entity: entity?.name }}
      />
    }
    dataName="entity-delete"
    deleteText={<FormattedMessage id="organization.action.delete" defaultMessage="Delete" />}
    onClose={onClose}
    onDelete={onDelete}
    title={<FormattedMessage id="organization.deleteEntity" defaultMessage="Delete entity?" />}
    open={!!entity}
  />
);

export default EntityDeleteModal;
