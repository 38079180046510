import { useIntl } from 'react-intl';

import { faArrowUpRightFromSquare } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { useAlertStore } from '@trustyou/shared';
import { StyledFontAwesomeIcon } from '@trustyou/ui';

import { useFetchResponseUrl } from '../../../../hooks';
import { useStore } from '../../../../store/store';
import { SubmitButton } from './submit-button';

export type GoToSourceButtonProps = {
  response: string;
  isDisabled?: boolean;
};

export function GoToSourceButton({ response, isDisabled }: GoToSourceButtonProps) {
  const intl = useIntl();
  const { alert } = useAlertStore();
  const reviewRoot = useStore.use.reviewRoot();
  const { data: responseUrlData } = useFetchResponseUrl(
    reviewRoot?.id,
    reviewRoot?.review.respondable
  );

  const handleClick = () => {
    navigator.clipboard.writeText(response);
    alert.success(
      intl.formatMessage({
        id: 'inbox.response.copy.successful',
        defaultMessage: 'Copied to clipboard',
      })
    );
    setTimeout(() => {
      window.open(String(responseUrlData), '_blank');
    }, 500);
  };

  return (
    <SubmitButton
      data-gtm-id="go_to_source"
      startIcon={
        <StyledFontAwesomeIcon icon={faArrowUpRightFromSquare} style={{ fontSize: 'inherit' }} />
      }
      disabled={isDisabled}
      onClick={handleClick}
    >
      {intl.formatMessage({
        id: 'inbox.response.action.goToSource',
        defaultMessage: 'Go to source',
      })}
    </SubmitButton>
  );
}
