import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  container: {
    height: '100%',
  },
  barContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingX: 3,
  },
  body: {
    padding: 3,
    paddingBottom: 1.5,
    height: 'calc(100% - 48px)',
    overflow: 'auto',
  },
};

export default styles;
