import type { IntlShape } from 'react-intl';

import { debounce } from '@mui/material';
import * as yup from 'yup';

import type {
  EntitiesPayload,
  Entity,
  MatchedEntity,
  PreviewEntitiesSummary,
  PreviewEntityData,
  PreviewEntityRow,
} from '../types';

const ENTITY_NAME_LENGTH = 50;
const VALIDATE_DEBOUNCE_TIME = 500;

type PreviewEntityTableData = {
  rows: PreviewEntityRow[];
  summary: PreviewEntitiesSummary;
};

export const getPreviewEntitiesSummary = (
  previewEntityData: PreviewEntityData
): PreviewEntityTableData => {
  const rows = [
    ...previewEntityData.errors.map((row, index) => ({
      ...row.input,
      status: 'error',
      rowId: `ERROR${index}`,
      errorColumns: row.errors.map((error) => error.field),
    })),
    ...previewEntityData.unmatched.map((row, index) => ({
      ...row,
      status: 'not_found',
      rowId: `NOTFOUND${index}`,
    })),
    ...previewEntityData.matched.map((row, index) => ({
      ...row.input,
      status: 'matched',
      rowId: `MATCHED${index}`,
      entityId: row.entity_id,
    })),
  ];

  const summary = {
    matched: previewEntityData.matched.length,
    not_found: previewEntityData.unmatched.length,
    error: previewEntityData.errors.length,
  };

  return { rows, summary };
};

export const getEntitiesPayload = (
  matchedEntities: MatchedEntity[],
  subscriptionId?: string
): EntitiesPayload => {
  const entities = matchedEntities.map((row) => ({
    entity_id: row.entity_id,
    attributes: row.input.custom_attributes,
  }));
  const payload: EntitiesPayload = { entities };
  if (subscriptionId) {
    payload.subscription_id = subscriptionId;
  }
  return payload;
};

export const getAddEntitiesPayload = (
  chosenEntities: Entity[],
  subscriptionId?: string
): EntitiesPayload => {
  const entities = chosenEntities.map((row) => ({
    entity_id: row.id,
    attributes: row.custom_attributes || {},
  }));
  const payload: EntitiesPayload = { entities };
  if (subscriptionId) {
    payload.subscription_id = subscriptionId;
  }
  return payload;
};

export const getEntityValidationSchema = (
  intl: IntlShape,
  checkValidation: (name: string) => Promise<boolean>,
  defaultValue?: string
) => {
  const duplicateError = intl.formatMessage({
    id: 'entity.name.exists',
    defaultMessage: 'The entity name already exists',
  });

  const debounceDuplicateCheck = debounce((resolve: (val: boolean) => void, value) => {
    if (defaultValue && defaultValue === value) {
      return resolve(true);
    }
    return checkValidation(value).then(resolve);
  }, VALIDATE_DEBOUNCE_TIME);

  return yup.object().shape({
    name: yup
      .string()
      .max(ENTITY_NAME_LENGTH)
      .required()
      .test(
        'duplicateCheck',
        duplicateError,
        (value) =>
          new Promise((resolve) => {
            debounceDuplicateCheck(resolve, value);
          })
      ),
    sector: yup.string().required(),
    brand: yup.string().required(),
    continent: yup.string().required(),
    country: yup.string().required(),
    city: yup.string().required(),
    address: yup.string().required(),
    state: yup.string(),
    zip: yup.string(),
  });
};
