import { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import { faChevronDown, faChevronUp } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Alert, AlertTitle, Box, Button, Chip } from '@trustyou/ui';
import { motion } from 'framer-motion';

import { ALERT_INFO_TITLE_COLOR } from '../../../constants';
import { useComponentHeight } from '../../../hooks';

const FlexContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row-reverse',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: theme.spacing(0.5),
}));

const ChipContainer = styled(Box)({
  height: 30,
  display: 'flex',
  alignItems: 'center',
  maxWidth: 'calc(100% - 100px)',
});

const StyledIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.action.active,
}));

export type UsedAIGuidesProps = {
  guides: string[];
};

export const UsedAIGuides = ({ guides }: UsedAIGuidesProps) => {
  const ROW_HEIGHT = 30;
  const flexContainerRef = useRef<HTMLDivElement>(null);
  const overallChipsHeight = useComponentHeight(flexContainerRef);
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleIsExpanded = () => setIsExpanded(!isExpanded);

  return (
    <Alert
      severity="info"
      // eslint-disable-next-line react/jsx-no-useless-fragment
      icon={<></>}
      sx={{ borderRadius: 0 }}
    >
      <AlertTitle>
        <FormattedMessage
          id="inbox.response.ai.info.guides"
          defaultMessage="AI guides used in response"
        />
      </AlertTitle>
      <motion.div
        initial={{ height: `${ROW_HEIGHT}px` }}
        animate={{ height: isExpanded ? 'auto' : `${ROW_HEIGHT}px` }}
        transition={{ type: 'just' }}
        style={{ overflow: 'hidden' }}
      >
        <FlexContainer ref={flexContainerRef}>
          <Button
            sx={{
              height: `${ROW_HEIGHT}px`,
              color: ALERT_INFO_TITLE_COLOR,
              visibility: overallChipsHeight === ROW_HEIGHT ? 'hidden' : 'visible',
            }}
            onClick={toggleIsExpanded}
          >
            <Box display="flex" gap={1} alignItems="center">
              {isExpanded ? (
                <>
                  <FormattedMessage id="inbox.less" defaultMessage="Less" />
                  <StyledIcon size="lg" icon={faChevronUp} />
                </>
              ) : (
                <>
                  <FormattedMessage id="inbox.more" defaultMessage="More" />
                  <StyledIcon size="lg" icon={faChevronDown} />
                </>
              )}
            </Box>
          </Button>
          {guides.map((guide, index) => (
            <ChipContainer key={guide + index}>
              <Chip color="info" sx={{ textOverflow: 'ellipsis' }} size="small" label={guide} />
            </ChipContainer>
          ))}
        </FlexContainer>
      </motion.div>
    </Alert>
  );
};
