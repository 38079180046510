import { create } from 'zustand';

import type { Recipient } from '../components';
import { reportValidationSchema } from '../constants/report-validation-schema';

type NewReport = {
  contentId: string;
  name?: string;
  recipients?: Recipient[];
  widgets?: string[];
  isOneTime?: boolean;
  frequency?: string;
  timeOfDay?: string | null;
  dayOfMonth?: number;
  dayOfWeek?: number;
};

type NewReportState = {
  showDiscardModal: boolean;
  activeStep: number;
  setActiveStep: (activeStep: number) => void;
  isDashboardLoaded: boolean;
  newReport?: NewReport;
  init: () => void;
  toggleDiscardModal: () => void;
  nextStep: () => void;
  prevStep: () => void;
  updateNewReport: (newReport: Partial<NewReport>) => void;
  setContentId: (id: string) => void;
  setIsDashboardLoaded: (isDashboardLoaded: boolean) => void;
  setWidgets: (widgets: string[]) => void;
  setIsOneTime: (isOneTime: boolean) => void;
  isInboxReviews: () => boolean;
};

export const useNewReportStore = create<NewReportState>()((set, get) => ({
  showDiscardModal: false,
  activeStep: 0,
  setActiveStep: (activeStep: number) => set({ activeStep }),
  isDashboardLoaded: false,
  isInboxReviews: () => {
    return get().newReport?.contentId === 'reviews_inbox';
  },
  init: () => set({ activeStep: 0, newReport: undefined }),
  toggleDiscardModal: () => set({ showDiscardModal: !get().showDiscardModal }),
  nextStep: () => set({ activeStep: get().activeStep + 1 }),
  prevStep: () => set({ activeStep: get().activeStep - 1 }),
  updateNewReport: (newReport: Partial<NewReport>) =>
    set({ newReport: { ...(get().newReport as NewReport), ...newReport } }),
  setContentId: (contentId: string) =>
    set({
      newReport: {
        contentId,
        isOneTime: true,
        ...reportValidationSchema.getDefault(),
      },
      isDashboardLoaded: false,
    }),
  setIsDashboardLoaded: (isDashboardLoaded: boolean) => set({ isDashboardLoaded }),
  setWidgets: (widgets: string[]) => get().updateNewReport({ widgets }),
  setIsOneTime: (isOneTime: boolean) => get().updateNewReport({ isOneTime }),
}));
