const openAssistant = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Userlane('openAssistant');
};

export const initUserlane = (userId: string, lang: string, breakpoint: string) => {
  passUserlaneBreakpoint(userId, breakpoint);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Userlane('init', process.env.REACT_APP_USERLANE_ID);
  changeUserlaneLanguage(lang);
};

export const openFeatureTour = () => {
  openAssistant();
  setTimeout(() => {
    document.getElementById('usln-p-assistant-nav-tab__chapters')?.click();
  }, 10);
};

export const openNotifications = () => {
  openAssistant();
  setTimeout(() => {
    document.getElementById('usln-p-assistant-nav-tab__announcement-list')?.click();
  }, 10);
};

export const changeUserlaneLanguage = (lang: string) => {
  if (!lang) return;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (window.Userlane) Userlane('lang', lang.toLowerCase());
};

export const passUserlaneBreakpoint = (userId: string, breakpoint: string) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (window.Userlane) Userlane('identify', userId, { breakpoint });
};
