import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { LoadingPlaceholder, laptopWithLoadingScreen } from '@trustyou/ui';

import { newReportWizardMessages } from '../../../../constants/messages';
import styles from './styles';

const FiltersLoader = () => (
  <LoadingPlaceholder
    img={laptopWithLoadingScreen}
    title={<FormattedMessage {...newReportWizardMessages.filtersLoaderHeader} />}
    description={<FormattedMessage {...newReportWizardMessages.filtersLoaderContent} />}
    loaderContainer={styles.loaderContainer}
    loaderHeader={styles.loaderHeader}
    loaderIcon={styles.loaderIcon}
  />
);

export default FiltersLoader;
