import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  type Segment,
  type UpdateSegment,
  useAlertStore,
  useSegmentValidationSchema,
  useUpdateSegment,
  useValidSegmentName,
} from '@trustyou/shared';
import {
  BackdropSpinner,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@trustyou/ui';

import { DashboardDelay } from '../../../../../components';
import styles from './styles';

type EditSegmentsProps = {
  onClose: () => void;
  onSegmentEdit: (segment: Segment) => void;
  segment: Segment;
};

const EditSegments = ({ segment, onSegmentEdit, onClose }: EditSegmentsProps) => {
  const { mutateAsync: checkValidation } = useValidSegmentName();
  const { alert } = useAlertStore();
  const { mutate: updateSegment, isPending: isUpdating } = useUpdateSegment(
    (data: UpdateSegment) => {
      alert.success(
        <FormattedMessage
          id="organization.segments.changesSaved"
          defaultMessage="Changes saved. {delay}"
          values={{ delay: <DashboardDelay /> }}
        />
      );
      onSegmentEdit({ ...segment, ...data });
      onClose();
    },
    () => {
      alert.genericError();
    }
  );

  const schema = useSegmentValidationSchema(checkValidation, segment.name);

  const { register, handleSubmit, formState } = useForm<Partial<Segment>>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: segment,
  });

  const onSubmit = (data: Partial<Segment>) => {
    updateSegment({
      id: data.id as string,
      name: data.name as string,
    });
  };

  return (
    <Dialog open onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle variant="h6">
          <FormattedMessage id="organization.editSegment" defaultMessage="Edit segment" />
        </DialogTitle>
        <DialogContent>
          <FormattedMessage id="organization.nameOfSegment" defaultMessage="Name of segment">
            {(message) => (
              <TextField
                sx={styles.nameField}
                data-testid="segment-name"
                label={message}
                variant="standard"
                {...register('name')}
                error={!!formState.errors.name}
                helperText={formState.errors.name?.message}
              />
            )}
          </FormattedMessage>
          <BackdropSpinner isLoading={isUpdating} />
        </DialogContent>
        <DialogActions>
          <Button data-testid="cancel-edit-segment" onClick={onClose} color="inherit">
            <FormattedMessage id="organization.editSegment.cancel" defaultMessage="Cancel" />
          </Button>
          <Button
            data-testid="accept-edit-segment"
            disabled={!formState.isValid}
            type="submit"
            variant="contained"
          >
            <FormattedMessage id="organization.editSegment.save" defaultMessage="Save" />
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditSegments;
