import { Box, TyLogo, type TyLogoProps, Typography } from '@trustyou/ui';

import type { StyleUnit } from '../../types/common';

export interface DrawerHeaderProps {
  logoVariant?: TyLogoProps['variant'];
  sx?: StyleUnit;
}

export const DrawerHeader = ({ logoVariant = 'blue', sx }: DrawerHeaderProps) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 2,
      padding: 2,
      ...sx,
    }}
  >
    <TyLogo variant={logoVariant} />
    <Typography variant="body1">TrustYou</Typography>
  </Box>
);
