import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  type FormErrorResponseData,
  useCreateGoogleConnection,
  useRouteUtils,
} from '@trustyou/shared';
import { BackdropSpinner, FullScreenModal } from '@trustyou/ui';
import type { AxiosError } from 'axios';

import FailedConnect from './FailedConnect';
import SuccessConnect from './SuccessConnect';

import { GOOGLE_REDIRECT_URL } from '../../../constants/reviewProviders';

const GoogleStaticConnect = () => {
  const { navigateWithBase } = useRouteUtils();

  const [searchParams] = useSearchParams();

  const [providerId, setProviderId] = useState<string | undefined>(undefined);
  const [isUniquenessError, setIsUniquenessError] = useState<boolean>(false);

  const onClose = () =>
    navigateWithBase(`settings/application/review-providers${providerId ? `/${providerId}` : ''}`);

  const code = searchParams.get('code');

  const handleGoogleConnectionError = (error: AxiosError) => {
    const responseData = error.response?.data as FormErrorResponseData;
    const bookingValidationErrors = responseData.error.validation_errors;
    if (!bookingValidationErrors) return;
    if (!bookingValidationErrors.some((item) => item.code === 'uniqueness_error')) return;
    setIsUniquenessError(true);
  };

  const {
    mutate: createGoogleConnection,
    isPending: isCreatingConnection,
    isError,
    isSuccess,
  } = useCreateGoogleConnection(
    (data) => {
      setProviderId(data.review_provider_id);
    },
    (err) => handleGoogleConnectionError(err as AxiosError)
  );

  useEffect(() => {
    if (!code) return;
    // prevent cycle of requests cause mutation is fired automatically
    if (isCreatingConnection || isError || isSuccess) return;
    createGoogleConnection({ redirect_url: GOOGLE_REDIRECT_URL, code });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderContent = () => {
    if (!code || isError)
      return <FailedConnect isUniquenessError={isUniquenessError} onClose={onClose} />;
    if (isCreatingConnection) return <BackdropSpinner isLoading />;
    if (isSuccess) return <SuccessConnect onClose={onClose} />;
    return null;
  };

  const getCloseButtonGTMID = () => {
    if (!code || isError) return 'google-static-error';
    if (isSuccess) return 'google-static-success';
    return undefined;
  };

  return (
    <FullScreenModal closeButtonGTMID={getCloseButtonGTMID()} open onClose={onClose}>
      {renderContent()}
    </FullScreenModal>
  );
};

export default GoogleStaticConnect;
