import { defineMessages } from 'react-intl';

export function getValueFromMsgs<T>(msgs: T, value: string) {
  return msgs[value as keyof T];
}

export const guestInfoMessages = defineMessages({
  name: {
    id: 'inbox.name',
    defaultMessage: 'Name',
  },
  email: {
    id: 'inbox.email',
    defaultMessage: 'Email',
  },
  'phone number': {
    id: 'inbox.phone-number',
    defaultMessage: 'Phone number',
  },
  'room number': {
    id: 'inbox.room-number',
    defaultMessage: 'Room number',
  },
});

export const statusTooltipMessages = defineMessages({
  confirmed: {
    id: 'inbox.status.tooltip.confirmed',
    defaultMessage: 'Response confirmed',
  },
  responded: {
    id: 'inbox.status.tooltip.responded',
    defaultMessage: 'Marked as responded',
  },
  unresponded: {
    id: 'inbox.status.tooltip.unresponded',
    defaultMessage: 'Unresponded',
  },
  unread: {
    id: 'inbox.status.tooltip.unread',
    defaultMessage: 'Unread',
  },
  read: {
    id: 'inbox.status.tooltip.read',
    defaultMessage: 'Read',
  },
  marked_as_deleted: {
    id: 'inbox.status.tooltip.marked-as-deleted',
    defaultMessage: 'Marked as deleted',
  },
  deleted: {
    id: 'inbox.status.tooltip.deleted',
    defaultMessage: 'Deleted',
  },
  marked_as_inappropriate: {
    id: 'inbox.status.tooltip.marked-as-inappropriate',
    defaultMessage: 'This review is marked  as inappropriate and is waiting for moderation',
  },
  inappropriate: {
    id: 'inbox.status.tooltip.inappropriate',
    defaultMessage: 'This review is inappropriate',
  },
});

export const commonMessages = defineMessages({
  by: {
    id: 'inbox.common.by',
    defaultMessage: 'By',
  },
  byUsername: {
    id: 'inbox.common.by-username',
    defaultMessage: 'By: {username}',
  },
});
