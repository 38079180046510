import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { browserLanguages } from '../constants/languages';
import { supportedLanguages } from '../i18n';
import { changeUserlaneLanguage } from '../utils';

type LanguageState = {
  locale: string;
  updateLocale: (locale: string) => void;
};

const getInitialLocale = (): string => {
  const browserLocale = navigator.language.split('_')[0];
  return browserLanguages[browserLocale] || 'en-US';
};

export const useLanguageStore = create<LanguageState>()(
  persist(
    (set, get) => ({
      locale: getInitialLocale(),
      updateLocale: (locale) => {
        changeUserlaneLanguage(locale);
        return set({ locale });
      },
    }),
    {
      name: 'language-storage',
      partialize: (state) => {
        const isSupported = supportedLanguages.some((lang) => lang.value === state.locale);
        const replacedLocale = getInitialLocale();
        if (!isSupported) {
          state.updateLocale(replacedLocale);
        }
        return {
          locale: isSupported ? state.locale : replacedLocale,
          updateLocale: state.updateLocale,
        };
      },
    }
  )
);
