import { UPDATE_SEGMENT_PERMISSION, usePermissionStore, useResponsive } from '@trustyou/shared';

const useManageSegments = () => {
  const { hasPermissions } = usePermissionStore();
  const isManageSegmentsAllowed = hasPermissions(UPDATE_SEGMENT_PERMISSION);
  const { isMobileRange } = useResponsive();
  return !isMobileRange && isManageSegmentsAllowed;
};

export default useManageSegments;
