import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { Stack } from '@trustyou/ui';

import type { FlagFormValues } from './form-drawer';
import { reasonAndNotesMessages } from './messages';
import { useReasonOptions } from './use-reason-options';

import { DropdownFormField } from '../dropdowns/dropdown-form-field';
import { TextFieldController } from '../text-field-controller';

export function ReasonAndNotesStep() {
  const intl = useIntl();

  const {
    formState: { errors },
  } = useFormContext<FlagFormValues>();

  const reasonOptions = useReasonOptions();

  return (
    <Stack spacing={3}>
      <DropdownFormField
        name="reason"
        label={intl.formatMessage(reasonAndNotesMessages.reasonFieldLabel)}
        required
        options={reasonOptions}
        error={!!errors.reason}
        helperText={errors.reason?.message ?? ' '}
      />
      <TextFieldController
        name="notes"
        label={intl.formatMessage(reasonAndNotesMessages.notesFieldLabel)}
        required
        multiline
        rows={3}
        error={!!errors.notes}
        helperText={errors.notes?.message ?? ' '}
      />
    </Stack>
  );
}
