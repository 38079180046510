import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  container: { display: 'flex', alignItems: 'center', gap: 0.5 },
  infoIcon: {
    color: 'rgba(0, 0, 0, 0.56)',
    height: '14px',
    width: '14px',
  },
  headerText: {
    cursor: 'pointer',
    borderBottom: '1px dotted',
    borderColor: 'primary.main',
  },
};

export default styles;
