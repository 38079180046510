import { useState } from 'react';
import { useController } from 'react-hook-form';

import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Box, Rating, Stack, StyledFontAwesomeIcon, Typography } from '@trustyou/ui';

import type { ScaleQuestionProps } from '../question-types';
import type { Color } from '../types';
import { getLabels } from '../utils';

type FaceRatingProps = ScaleQuestionProps;

export function FaceRating({ name, control, options }: FaceRatingProps) {
  const { field } = useController({ name, control });
  const [value, setValue] = useState<number | null>(field.value ?? null);
  const [hover, setHover] = useState(-1);

  const labels = getLabels(options);
  const firstLabel = Object.values(labels).at(0);
  const lastLabel = Object.values(labels).at(-1);

  const getHoverOrSelectedLabel = () => {
    if (hover !== -1) return labels[hover];
    if (value !== null) return labels[value];
    return null;
  };

  return (
    <Stack spacing={1} sx={{ alignItems: 'center' }}>
      <Rating
        highlightSelectedOnly
        max={options.length}
        value={value}
        onChange={(event, newValue) => {
          field.onChange(newValue);
          setValue(newValue);
        }}
        onChangeActive={(event, newHover) => setHover(newHover)}
        getLabelText={(value) => `Face number ${value}, ${labels[value]}`}
        size="large"
        IconContainerComponent={({ value, ...props }) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const option = options.find((option) => option.value === value)!;
          return (
            <Box component="span" sx={{ marginInline: 0.5 }} {...props}>
              <StyledFontAwesomeIcon
                icon={option.icon as IconDefinition}
                sx={{
                  color: (theme) =>
                    hover === value ? theme.palette[option.color ?? 'success'].main : 'inherit',
                }}
              />
            </Box>
          );
        }}
        sx={{
          '& .MuiRating-iconEmpty': {
            color: (theme) => theme.palette.action.active,
          },
          '& .MuiRating-iconFilled': {
            color: (theme) => {
              const option = options.find((option) => option.value === value) ?? options[0];
              return theme.palette[(option.color as Color) ?? 'success'].main;
            },
          },
        }}
      />
      {hover !== -1 || value !== null ? (
        <Typography variant="body2" color="text.primary" fontWeight={500} textAlign="center">
          {getHoverOrSelectedLabel()}
        </Typography>
      ) : (
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Typography variant="body2" color="text.secondary">
            {firstLabel}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {lastLabel}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}
