import { useState } from 'react';

import type { Entity } from '@trustyou/shared';
import { EntityCustomAttributesView, EntityInfoBanner, Stack } from '@trustyou/ui';

import { EntityCustomAttributesUpdateDialog } from '../../../../components';
import { useUpdateEntityCustomAttributesPermission } from '../../../../hooks';

type Props = {
  entity: Entity;
};

export const OverviewTab = ({ entity }: Props) => {
  const canUpdateCustomAttributes = useUpdateEntityCustomAttributesPermission();
  const [isAttributesUpdateDialogOpen, setIsAttributesUpdateDialogOpen] = useState(false);
  return (
    <>
      <Stack gap={3}>
        <EntityInfoBanner entity={entity} />
        <EntityCustomAttributesView
          attributes={entity.custom_attributes}
          onEdit={
            canUpdateCustomAttributes ? () => setIsAttributesUpdateDialogOpen(true) : undefined
          }
        />
      </Stack>
      {isAttributesUpdateDialogOpen && (
        <EntityCustomAttributesUpdateDialog
          entityId={entity.id}
          onClose={() => setIsAttributesUpdateDialogOpen(false)}
        />
      )}
    </>
  );
};
