import { DateTime } from 'luxon';

export const stringToShortDate = (str: string) => {
  return DateTime.fromISO(str).toFormat('dd.MM.yyyy');
};

export const stringToDateAndTime = (str: string) => {
  return DateTime.fromISO(str).toFormat('dd.MM.yyyy, HH:mm');
};

export const toShortDate = (date: Date) => {
  return DateTime.fromJSDate(date).toFormat('dd.MM.yyyy');
};

export const isExpired = (unix: number | undefined): boolean => {
  if (!unix) return true;
  const nowUtc = DateTime.utc().valueOf();
  const expiryTimeUtc = DateTime.fromSeconds(unix, { zone: 'utc' }).valueOf();
  return nowUtc > expiryTimeUtc;
};

const suffixes = new Map([
  ['one', 'st'],
  ['two', 'nd'],
  ['few', 'rd'],
  ['other', 'th'],
]);

export const ordinalDayLocale = (locale: string) => (day: number) => {
  const rules = new Intl.PluralRules(locale, { type: 'ordinal' });
  const rule = rules.select(day);
  const suffix = suffixes.get(rule);
  return `${day}${suffix}`;
};
