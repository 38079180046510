import MuiMenuItem, { type MenuItemProps as MuiMenuItemProps } from '@mui/material/MenuItem';
import { useGTMProps } from '@trustyou/shared';

import type { E2ECouldTest } from '../types/e2e';
import type { GTMTracked } from '../types/gtm';

export interface MenuItemProps extends MuiMenuItemProps, E2ECouldTest, GTMTracked {}

export const MenuItem = ({ children, 'data-testid': testId, ...restProps }: MenuItemProps) => {
  const propsWithGtm = useGTMProps(restProps, testId);
  return <MuiMenuItem {...propsWithGtm}>{children}</MuiMenuItem>;
};
