import { useMutation, useQueryClient } from '@tanstack/react-query';
import { $assApi, ANALYTICS_BASE_PATH } from '@trustyou/shared';
import type { AxiosError } from 'axios';

import { DELETE_REPORT, HAS_REPORTS_CREATED, REPORT_LIST } from '../../constants/query-keys';

async function deleteReport(reportId: string): Promise<void> {
  const { data } = await $assApi.delete(`${ANALYTICS_BASE_PATH}/report/delete`, {
    params: {
      report_id: reportId,
    },
  });
  return data;
}

export const useDeleteReport = (
  onSuccess: () => void,
  onError?: (error: AxiosError<unknown>) => void
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteReport,
    mutationKey: [DELETE_REPORT],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [REPORT_LIST] });
      queryClient.invalidateQueries({ queryKey: [HAS_REPORTS_CREATED] });
      onSuccess();
    },
    onError,
  });
};
