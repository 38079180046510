import { useEffect } from 'react';

import Hotjar from '@hotjar/browser';
import { useMembershipStore } from '@trustyou/shared';

import useCurrentUser from '../useCurrentUser';

const useHotjar = () => {
  const siteId = Number(process.env.REACT_APP_HOTJAR_ID);
  const hotjarVersion = 6;

  const currentUser = useCurrentUser();
  const { membership } = useMembershipStore();

  const userId = currentUser?.id;
  const role = currentUser?.role;
  const membershipId = currentUser?.membershipId;
  const organizationId = membership?.organizationId;

  useEffect(() => {
    if (siteId && userId) {
      // https://github.com/hotjar/hotjar-js
      Hotjar.init(siteId, hotjarVersion);
      Hotjar.identify(userId, {
        role: role ?? '',
        membership_id: membershipId ?? '',
        organization_id: organizationId ?? '',
      });
    }
  }, []);
};

export default useHotjar;
