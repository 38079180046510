import { FormattedMessage } from 'react-intl';

import { CustomAttributeType } from '@trustyou/shared';

import { common } from '../../constants/messages/customAttributes';

type Props = {
  type: CustomAttributeType;
};

export const CustomAttributeTypeLabel = ({ type }: Props) => {
  if (type === CustomAttributeType.SELECT) return <FormattedMessage {...common.singleSelectType} />;
  if (type === CustomAttributeType.MULTI_SELECT)
    return <FormattedMessage {...common.multiSelectType} />;
  return <FormattedMessage {...common.freeTextType} />;
};
