import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  link: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    cursor: 'pointer',
  },
  linkIcon: {
    color: 'action.active',
  },
};

export default styles;
