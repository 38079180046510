import { useIntl } from 'react-intl';

import { debounce } from '@mui/material';
import * as yup from 'yup';

const SEGMENT_NAME_LENGTH = 50;
const VALIDATE_DEBOUNCE_TIME = 500;

export const useSegmentValidationSchema = (
  checkValidation: (name: string) => Promise<boolean>,
  defaultValue?: string
) => {
  const intl = useIntl();

  const duplicateError = intl.formatMessage({
    id: 'segment.exists',
    defaultMessage: 'Entered segment already exists',
  });

  const debounceDuplicateCheck = debounce((resolve: (val: boolean) => void, value) => {
    if (defaultValue && defaultValue === value) {
      return resolve(true);
    }
    return checkValidation(value).then(resolve);
  }, VALIDATE_DEBOUNCE_TIME);

  return yup.object().shape({
    name: yup
      .string()
      .max(SEGMENT_NAME_LENGTH)
      .required()
      .test(
        'duplicateCheck',
        duplicateError,
        (value) =>
          new Promise((resolve) => {
            debounceDuplicateCheck(resolve, value);
          })
      ),
  });
};
