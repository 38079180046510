import type { BasicEntity } from '@trustyou/shared';
import type { StateCreator } from 'zustand';

import type { ReviewSchema } from '../../client';
import type { ReviewRoot } from '../../types/reviews';

export interface ReviewSliceState {
  reviewsList: ReviewSchema[];
  updateReviewsList: (reviewsList: ReviewSchema[]) => void;

  reviewRoot: ReviewRoot | null;
  updateReviewRoot: (review: ReviewRoot | null) => void;

  entityData: BasicEntity | null;
  updateEntityData: (entityData: BasicEntity | null) => void;

  restrictedScope: string[];
  updateRestrictedScope: (entityIds: string[]) => void;
}

export const createReviewSlice: StateCreator<ReviewSliceState, [], []> = (set) => ({
  reviewsList: [],
  updateReviewsList: (reviewsList) => set(() => ({ reviewsList })),

  reviewRoot: null,
  updateReviewRoot: (reviewRoot) => set(() => ({ reviewRoot })),

  entityData: null,
  updateEntityData: (entityData) => set(() => ({ entityData })),

  restrictedScope: [],
  updateRestrictedScope: (entityIds) => set(() => ({ restrictedScope: entityIds })),
});
