import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { faArrowDownToLine } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { useInboxStore } from '@trustyou/shared';
import { Button, StyledFontAwesomeIcon } from '@trustyou/ui';

import { exportReviews } from '../../hooks';

export function CustomExportButton() {
  const [isExporting, setIsExporting] = useState(false);
  const currentView = useInboxStore((state) => state.currentView);

  const handleExportClick = async () => {
    setIsExporting(true);
    await exportReviews({
      // @ts-expect-error Type 'ReviewView' is not assignable to type 'ReviewFilters'.
      filters: currentView.filters ?? {},
      offset: 0,
    }).finally(() => {
      setIsExporting(false);
    });
  };

  return (
    <Button
      size="small"
      startIcon={<StyledFontAwesomeIcon icon={faArrowDownToLine} />}
      onClick={handleExportClick}
      data-gtm-id="inbox_export_all"
      disabled={isExporting}
    >
      <FormattedMessage id="inbox.table.action.export-all" defaultMessage="Export all" />
    </Button>
  );
}
