import type { IStyles } from '@trustyou/shared';

export const styles: IStyles = {
  title: {
    display: 'flex',
    alignItems: 'center',
    paddingY: 2,
    paddingX: 3,
    justifyContent: 'space-between',
  },
  content: { padding: 0 },
  button: { marginLeft: 'auto' },
};
