import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  type CombinedUserAndInvitation,
  type Subscription,
  USER_SCOPES,
  USER_STATUSES,
  checkUserScopeIsAllowed,
  detectUserScope,
  getDefaultRole,
  getDefaultScope,
  getSimpleUserValidationSchema,
  useAlertStore,
  useChangelingStore,
  useInviteUser,
  useScopeStore,
  useUpdateInvitation,
  useUpdateUser,
} from '@trustyou/shared';
import {
  BackdropSpinner,
  Box,
  ContentPane,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DiscardChangesModal,
  Typography,
} from '@trustyou/ui';

import { CancelButton, SubmitButton } from './Buttons';
import { NavHeader } from './NavHeader';
import UserEmail, { type UserEmailForm } from './UserEmail';

import { useCheckEntityManager } from '../../../../../hooks';
import { RoleSelection } from '../RoleSelection';
import { ScopeSelection } from '../ScopeSelection';
import styles from './styles';

type InviteUpdateUserProps = {
  user?: CombinedUserAndInvitation | null;
  subscription?: Subscription;
  onCancel: () => void;
  isDialog?: boolean;
  refetchSubscriptionMembers?: boolean;
};

export const InviteUpdateUser = ({
  user,
  subscription,
  onCancel,
  isDialog,
  refetchSubscriptionMembers,
}: InviteUpdateUserProps) => {
  const [selectedRole, setSelectedRole] = useState<string>();
  const {
    selectedScope,
    selectedEntities,
    selectedGroups,
    selectedAccessGroup,
    setSelectedScope,
    setSelectedEntities,
    setSelectedGroups,
    setSelectedAccessGroup,
    isValid: isValidScope,
  } = useScopeStore();
  const [validated, setValidated] = useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const isEntityManager = useCheckEntityManager();

  const intl = useIntl();
  const { alert } = useAlertStore();
  const { isChangeling } = useChangelingStore();

  const bodyRef = useRef<HTMLElement>(null);

  const { mutate: inviteUser, isPending: isCreating } = useInviteUser(
    () => {
      onCancel();
      alert.success(
        <FormattedMessage
          id="organization.users.invitationSentToEmail"
          defaultMessage="Invitation sent to {email}"
          values={{ email: getValues('email') }}
        />
      );
    },
    () => {
      alert.genericError();
    },
    refetchSubscriptionMembers
  );

  const { mutate: updateUser, isPending: isUpdatingUser } = useUpdateUser(
    () => {
      onCancel();
      alert.success(
        <FormattedMessage id="organization.users.changesSaved" defaultMessage="Changes saved" />
      );
    },
    () => {
      alert.genericError();
    },
    refetchSubscriptionMembers
  );

  const { mutate: updateInvitation, isPending: isUpdatingInvitation } = useUpdateInvitation(
    () => {
      onCancel();
      alert.success(
        <FormattedMessage id="organization.users.changesSaved" defaultMessage="Changes saved" />
      );
    },
    () => {
      alert.genericError();
    },
    refetchSubscriptionMembers
  );

  const schema = getSimpleUserValidationSchema(intl);
  const { register, trigger, formState, getValues, setValue, reset } = useForm<UserEmailForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      email: user?.email,
    },
  });

  useEffect(() => {
    if (!user) {
      setSelectedEntities([]);
      setSelectedGroups([]);
      setSelectedAccessGroup(undefined);
      setSelectedRole(getDefaultRole(isEntityManager));
      reset();
    }
    if (user) {
      setValue('email', user.email);
      setSelectedRole(user.role);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (!subscription) return;
    const defaultScope = getDefaultScope(subscription, isEntityManager);
    if (!user) {
      setSelectedScope(defaultScope);
      return;
    }
    const isScopeAllowed = checkUserScopeIsAllowed(user.scope, subscription);
    if (!isScopeAllowed) {
      console.info(
        `user scope is not allowed for given subscription, drop scope to ${defaultScope}`
      );
      setSelectedScope(defaultScope);
      return;
    }
    setSelectedScope(detectUserScope(user.scope));
    if ('reusable' in user.scope && user.scope.reusable) {
      setSelectedAccessGroup(user.scope);
    } else if (user.scope.entities.length) {
      setSelectedEntities(user.scope.entities);
    } else if (user.scope.groups.length) {
      setSelectedGroups(user.scope.groups);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, subscription]);

  const getScopeSubscriptionId = (scope: USER_SCOPES, id: string | null) => {
    if (!id) return null;
    if (scope !== USER_SCOPES.ALL_ENTITIES) return null;
    return id;
  };

  const getSubscriptionIdAssigned = () => {
    if (user?.subscription.is_org_subscription) {
      // edit user within org scope, assing all entities in org
      return null;
    }
    if (user) {
      // edit entity subscription user
      return user.subscription.id;
    }
    if (subscription?.is_org_subscription) {
      // invite user to org scope
      return null;
    }
    if (!subscription) {
      // default invite to org if needed
      return null;
    }
    return subscription?.id;
  };

  const cancelSave = () => {
    if (
      !user &&
      (getValues('email') ||
        selectedRole ||
        selectedScope ||
        selectedGroups.length ||
        selectedEntities.length ||
        selectedAccessGroup)
    ) {
      setShowCancelConfirmation(true);
    } else {
      onCancel();
    }
  };

  const validateForm = async () => {
    const isFormValid = !!user || (await trigger());
    if (!isFormValid) {
      bodyRef?.current?.firstElementChild?.scrollIntoView({ behavior: 'smooth' });
      return false;
    }
    if (!selectedRole || !isValidScope()) {
      return false;
    }
    return true;
  };

  const onSave = async () => {
    const isFormValid = await validateForm();
    setValidated(true);
    if (!isFormValid) {
      return;
    }

    const isReusableScope = selectedScope === USER_SCOPES.ACCESS_GROUP && !!selectedAccessGroup;

    const subscriptionIdAssigned = getSubscriptionIdAssigned();

    const savingUser = {
      email: user?.email || getValues('email'),
      role: selectedRole as string,
      subscription_id: subscriptionIdAssigned,
      user_scope: isReusableScope
        ? undefined
        : {
            reusable: false,
            all: subscriptionIdAssigned ? false : selectedScope === USER_SCOPES.ALL_ENTITIES,
            entity_ids:
              selectedScope === USER_SCOPES.BY_ENTITIES
                ? selectedEntities.map((entity) => entity.id)
                : [],
            group_ids:
              selectedScope === USER_SCOPES.BY_GROUPS
                ? selectedGroups.map((group) => group.id)
                : [],
            subscription_id: getScopeSubscriptionId(selectedScope, subscriptionIdAssigned),
          },
      scope_id: isReusableScope ? selectedAccessGroup.id : undefined,
    };
    if (!user) {
      inviteUser(savingUser);
      return;
    }
    if (user.id && user.status === USER_STATUSES.ACTIVE) {
      updateUser({
        ...savingUser,
        membership_id: user.membership_id,
      });
      return;
    }
    if (user.status === USER_STATUSES.INVITED) {
      updateInvitation({
        ...savingUser,
        invitation_id: user.membership_id,
      });
      return;
    }
    // if goes there it is possible error to be handled
    console.error('a user possible has errors in its data: ', user);
  };

  if (!subscription) return <BackdropSpinner isLoading />;

  if (isDialog) {
    return (
      <Dialog
        open={user !== null}
        onClose={onCancel}
        sx={{ ...styles.dialog, ...(isChangeling ? styles.changelingMode : {}) }}
      >
        <DialogTitle variant="h6">
          <NavHeader user={user} />
        </DialogTitle>
        <DialogContent>
          <Box ref={bodyRef} sx={styles.body}>
            <UserEmail register={register} formState={formState} disabled={!!user} />
            <RoleSelection role={selectedRole} setRole={setSelectedRole} validated={validated} />
            <ScopeSelection subscription={subscription} validated={validated} />
          </Box>
        </DialogContent>
        <DialogActions>
          <CancelButton cancelSave={onCancel} />
          <SubmitButton user={user} onSave={onSave} />
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <ContentPane
      headers={[
        <Typography variant="h6">
          <NavHeader user={user} />
        </Typography>,
      ]}
      navigationHandle={cancelSave}
    >
      <Box ref={bodyRef} sx={styles.body}>
        <UserEmail register={register} formState={formState} disabled={!!user} />
        <RoleSelection role={selectedRole} setRole={setSelectedRole} validated={validated} />
        <ScopeSelection subscription={subscription} validated={validated} />
        <Box sx={styles.footer}>
          <CancelButton cancelSave={cancelSave} />
          <SubmitButton user={user} onSave={onSave} />
        </Box>
      </Box>
      <BackdropSpinner isLoading={isCreating || isUpdatingUser || isUpdatingInvitation} />
      <DiscardChangesModal
        onClose={() => {
          setShowCancelConfirmation(false);
        }}
        onDiscard={onCancel}
        open={showCancelConfirmation}
      />
    </ContentPane>
  );
};
