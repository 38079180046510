import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useQueryClient } from '@tanstack/react-query';
import {
  DEFAULT_SORT_KEY,
  type Entity,
  FETCH_INFINITE_ENTITIES,
  useBasicEntities,
  useEntityCount,
  useInfiniteEntities,
} from '@trustyou/shared';
import { Box, Button, ListItemText, Typography } from '@trustyou/ui';

import { MAX_ENTITIES } from '../../../constants';
import { SelectorModal } from '../../selector-modal';

export const EntitySelectorForEmailNotifications = () => {
  const { setValue, getValues } = useFormContext();
  const intl = useIntl();
  const queryClient = useQueryClient();
  const [entities, setEntities] = useState<Entity[]>([]);
  const [selectedEntities, setSelectedEntities] = useState<Entity[]>([]);
  const { data: totalEntityCount = 0 } = useEntityCount();
  const [searchKey, setSearchKey] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const { data: initialEntities = [], isPending: isLoadingInitialEntities } = useBasicEntities(
    getValues('filters.entity_ids')
  );
  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteEntities(
    MAX_ENTITIES,
    DEFAULT_SORT_KEY,
    searchKey
  );
  const filtersEntityIdsLength = getValues('filters.entity_ids').length;

  const getInitialSelection = () =>
    filtersEntityIdsLength > 0 ? (initialEntities.slice(0, MAX_ENTITIES) as Entity[]) : [];

  useEffect(() => {
    if (!isLoadingInitialEntities && initialEntities.length > 0) {
      setSelectedEntities(initialEntities.slice(0, MAX_ENTITIES) as Entity[]);
    }
  }, [isLoadingInitialEntities, initialEntities]);

  useEffect(() => {
    // If there is only one entity, select it by default
    if (totalEntityCount === 1 && entities.length === 1) {
      setValue('filters.entity_ids', [entities[0].id], { shouldDirty: false });
    }
  }, [entities, setValue, totalEntityCount]);

  useEffect(() => {
    setEntities(data?.pages.flatMap((page) => page.data) ?? []);
  }, [data]);

  const saveEntities = () => {
    const ids = selectedEntities.map(({ id }) => id);
    setValue('filters.entity_ids', ids, { shouldDirty: true });
    setIsOpen(false);
  };

  const cancel = () => {
    setSelectedEntities(getInitialSelection());
    setIsOpen(false);
  };

  const renderRowContent = (entity: Entity) => (
    <ListItemText primary={entity.name} secondary={`${entity.city}, ${entity.country_name}`} />
  );

  const handleFetchNextPage = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  const search = (value: string) => {
    setSearchKey(value);
    queryClient.removeQueries({ queryKey: [FETCH_INFINITE_ENTITIES] });
  };

  return totalEntityCount <= 1 ? null : (
    <Box>
      <Typography variant="subtitle1">
        <FormattedMessage id="inbox.settings.notifications.entities" defaultMessage="Entities" />
      </Typography>
      <Typography variant="body1">
        <FormattedMessage
          id="inbox.settings.notifications.entities.description"
          defaultMessage="You can select up to {maxEntities} entities to receive notifications for."
          values={{ maxEntities: MAX_ENTITIES }}
        />
      </Typography>
      <Typography variant="body1">
        <FormattedMessage
          id="inbox.settings.notifications.entities.selected"
          defaultMessage="{count} / {total} selected"
          values={{
            count:
              selectedEntities.length > 0
                ? selectedEntities.length
                : totalEntityCount > MAX_ENTITIES
                  ? MAX_ENTITIES
                  : totalEntityCount, //getValues('filters.entity_ids').length,
            total: totalEntityCount,
          }}
        />
      </Typography>
      <Button variant="text" onClick={() => setIsOpen(true)}>
        <FormattedMessage id="inbox.action.change" defaultMessage="Change" />
      </Button>
      <SelectorModal
        isOpen={isOpen}
        items={entities}
        selectedItems={selectedEntities}
        setSelectedItems={setSelectedEntities}
        onClose={cancel}
        onSave={saveEntities}
        renderRowContent={renderRowContent}
        searchPlaceholder={intl.formatMessage({
          id: 'inbox.search.entities',
          defaultMessage: 'Search entities',
        })}
        title={<FormattedMessage id="inbox.filter.entity.modal.title" defaultMessage="Entities" />}
        allTab={
          <FormattedMessage
            id="inbox.filter.entity.modal.button.all"
            defaultMessage="All entities"
          />
        }
        selectTab={
          <FormattedMessage
            id="inbox.filter.entity.modal.button.select"
            defaultMessage="Select entities"
          />
        }
        selectedHeaderTitle={
          <FormattedMessage
            id="inbox.filter.entity.modal.selected-count"
            defaultMessage="Selected entities ({count})"
            values={{ count: selectedEntities.length }}
          />
        }
        alertTitle={
          selectedEntities.length === 0 ? (
            <FormattedMessage
              id="inbox.filter.entity.modal.alert-title.min-error"
              defaultMessage="Select at least one entity"
            />
          ) : (
            <FormattedMessage
              id="inbox.filter.entity.modal.alert-title.max-error"
              defaultMessage="More than {maxEntities} entities selected"
              values={{ maxEntities: MAX_ENTITIES }}
            />
          )
        }
        alertDescription={
          selectedEntities.length > MAX_ENTITIES && (
            <FormattedMessage
              id="inbox.filter.entity.modal.alert-description.min-error"
              defaultMessage="Remove a few entities from the list and try again"
            />
          )
        }
        isLoading={isLoading}
        onFetchNextPage={handleFetchNextPage}
        onSearch={search}
        hideButtonGroup
        maxItems={MAX_ENTITIES}
      />
    </Box>
  );
};
