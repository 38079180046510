import { grey } from '@mui/material/colors';
import { faArrowRightFromBracket } from '@trustyou/fortawesome/pro-regular-svg-icons';
import type { NodeType } from '@trustyou/shared';
import {
  Box,
  DRAWER_WIDTH_MOBILE,
  DrawerHeader,
  DrawerNodeList,
  Stack,
  SwipeableDrawer,
} from '@trustyou/ui';

import useCustomersLogout from '../../../service/hooks/useCustomersLogout';
import { MobileUserMenu } from '../UserMenu/UserMenu';

type MobileDrawerProps = {
  primaryNodes: NodeType[];
  secondaryNodes: NodeType[];
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export const MobileDrawer = ({
  primaryNodes,
  secondaryNodes,
  isOpen,
  setIsOpen,
}: MobileDrawerProps) => {
  const logoutHandler = useCustomersLogout();

  const logoutNode = {
    id: 'tyDesign.userMenu.logout',
    name: 'Logout',
    icon: faArrowRightFromBracket,
    onClick: logoutHandler,
  };

  return (
    <SwipeableDrawer
      open={isOpen}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      sx={{
        '& .MuiDrawer-paper': {
          width: DRAWER_WIDTH_MOBILE,
        },
      }}
    >
      <Stack
        sx={{
          paddingBlockStart: 5,
          paddingBottom: 2,
          background: grey[50],
        }}
      >
        <DrawerHeader
          sx={{
            '& img': {
              height: '40px',
              width: '40px',
            },
            '& p': {
              fontSize: '20px',
              fontWeight: '500',
              lineHeight: '160%',
              letterSpacing: '0.15px',
            },
          }}
        />
        <MobileUserMenu />
      </Stack>
      <Stack sx={{ height: '100%', paddingBlock: 3, gap: 4 }}>
        <DrawerNodeList nodes={primaryNodes} isPermanentlyExpanded />
        <Box sx={{ flexGrow: 1 }} />
        <DrawerNodeList nodes={secondaryNodes} isPermanentlyExpanded />
        <DrawerNodeList nodes={[logoutNode]} isPermanentlyExpanded />
      </Stack>
    </SwipeableDrawer>
  );
};
