import type { ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import type { FormControlProps } from '@mui/material';
import {
  Asterisk,
  FormHelperText,
  InputLabel,
  MenuItem,
  StyledFormControl,
  TextField,
} from '@trustyou/ui';

type Option = {
  value: string;
  label: string;
};

export type DropdownFormProps = {
  name: string;
  options: Option[];
  label?: ReactNode;
  required?: boolean;
  placeholder?: string;
  size?: FormControlProps['size'];
  error?: FormControlProps['error'];
  helperText?: ReactNode;
};

export const DropdownFormField = ({
  name,
  options,
  label,
  required = false,
  placeholder,
  error,
  size,
  helperText,
}: DropdownFormProps) => {
  const { control } = useFormContext();

  return (
    <p>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <StyledFormControl fullWidth>
            {label && (
              <InputLabel shrink htmlFor={name}>
                {label}
                {required && <Asterisk />}
              </InputLabel>
            )}
            <TextField
              select
              aria-labelledby={name}
              placeholder={placeholder}
              id={name}
              size={size}
              value={value}
              onChange={onChange}
              error={error}
            >
              {options.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  data-gtm-class={`inbox_settings_${name}`}
                  data-gtm-id={option.label.toLowerCase()}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
          </StyledFormControl>
        )}
      />
    </p>
  );
};
