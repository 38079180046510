import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { type SelectChangeEvent, capitalize } from '@mui/material';
import { faTrash } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { type GroupRule, useAttributeValues, useAttributesForRules } from '@trustyou/shared';
import {
  Box,
  FormControl,
  StyledFontAwesomeIcon as Icon,
  IconButton,
  MenuItem,
  MultipleSelectWithCheckboxes,
  Select,
  Stack,
} from '@trustyou/ui';

import styles from './styles';

type GroupRuleSelectionProps = {
  id: number;
  rule: GroupRule;
  onDelete: (id: number) => void;
  onRuleUpdate: (id: number, rule: GroupRule) => void;
};

interface AttributeValues {
  attributeValues: string[];
}

const GroupRuleSelection = ({ rule, id, onDelete, onRuleUpdate }: GroupRuleSelectionProps) => {
  const { data: attributes } = useAttributesForRules();
  const { data: attributeValues } = useAttributeValues(rule.attribute);
  const methods = useForm<AttributeValues>();

  const handleAttributeChange = (event: SelectChangeEvent) => {
    onRuleUpdate(id, { ...rule, attribute: event.target.value, values: [] });
  };

  const handleAttributeValuesChange = (values: string[]) => {
    onRuleUpdate(id, { ...rule, values });
  };

  return (
    <Stack sx={styles.rulesStack} direction="row" spacing={4}>
      <Box sx={styles.conditionBox}>
        {id ? (
          <FormattedMessage id="organization.segments.and" defaultMessage="and" />
        ) : (
          <FormattedMessage id="organization.segments.where" defaultMessage="Where" />
        )}
      </Box>
      <FormControl variant="standard" sx={styles.attributeBox}>
        <Select
          data-testid="group-rule-select"
          value={rule.attribute || ''}
          onChange={handleAttributeChange}
        >
          {(attributes || []).map((attribute) => (
            <MenuItem
              data-testid="group-rule-item-attribute"
              data-gtm-id={attribute}
              key={attribute}
              value={attribute}
            >
              {capitalize(attribute)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box>
        <FormattedMessage id="organization.segments.is" defaultMessage="is" />
      </Box>
      {attributeValues && (
        <FormProvider {...methods}>
          <MultipleSelectWithCheckboxes
            key={`${rule.attribute}_${id}_value_selector`}
            all={{ key: 'All', label: 'All' }}
            names={attributeValues || []}
            fieldName="attributeValues"
            defaultValue={rule.values}
            variant="standard"
            sx={{ width: 300, margin: 1 }}
            onChange={handleAttributeValuesChange}
          />
        </FormProvider>
      )}
      {id > 0 && (
        <IconButton size="small" sx={styles.deleteButton} onClick={() => onDelete(id)}>
          <Icon icon={faTrash} />
        </IconButton>
      )}
    </Stack>
  );
};

export default GroupRuleSelection;
