import dayjs from 'dayjs';

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const getDateFromReview = (date: string): string => {
  const d = new Date(date);

  return `${d.getDate().toString().length === 1 ? `0${d.getDate()}` : d.getDate()} ${
    monthNames[d.getMonth()]
  }, ${d.getFullYear()}`;
};

export const getReviewListDate = (date: string) => {
  const d = new Date(date);

  return `${('0' + d.getDate()).slice(-2)}.${('0' + (d.getMonth() + 1)).slice(
    -2
  )}.${d.getFullYear()}`;
};

export const getHumanDate = (date: string) => {
  const d = new Date(date);

  return `${d.getDate().toString().length === 1 ? `0${d.getDate()}` : d.getDate()} ${
    monthNames[d.getMonth()]
  }, ${d.getFullYear()}`;
};

export const getValidDate = (timestamp: number) => {
  return dayjs(timestamp * 1000).format('DD.MM.YYYY');
};
