import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from '@mui/material';
import { faQuestion } from '@trustyou/fortawesome/pro-solid-svg-icons';

import { REVIEW_SCORE_DENOMINATOR } from '../../constants';

function getLabel(value: number) {
  if (!value) return <FontAwesomeIcon icon={faQuestion} />;
  if (value === 10) return value;
  return Number.parseFloat(value.toString()).toFixed(1);
}

function getColor(value: number) {
  if (!value) return 'default';
  if (value < 6) return 'error';
  if (value < 8) return 'warning';
  return 'success';
}

export type ScoreChipProps = {
  id?: string;
  value: number;
  isRound?: boolean;
  dataTestId?: string;
};

export function ScoreChip({ id, value, isRound = false, dataTestId }: ScoreChipProps) {
  const normalizedValue = value / REVIEW_SCORE_DENOMINATOR;

  return (
    <Chip
      id={id}
      data-test-id={dataTestId}
      size="small"
      sx={[
        {
          width: 40,
          '& > span': {
            padding: (theme) => theme.spacing(0.375, 0.5),
            fontWeight: 700,
          },
        },
        isRound && {
          height: 40,
          borderRadius: '50%',
          '& > span': {
            fontSize: 20,
          },
        },
      ]}
      label={getLabel(normalizedValue)}
      color={getColor(normalizedValue)}
      data-color={getColor(normalizedValue)}
    />
  );
}
