import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { yupResolver } from '@hookform/resolvers/yup';
import { faChevronRight } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { type Segment, useSegmentValidationSchema, useValidSegmentName } from '@trustyou/shared';
import { Box, Button, StyledFontAwesomeIcon as Icon, TextField, Typography } from '@trustyou/ui';

import styles from './styles';

type AddSegmentsFormProps = {
  segment?: Partial<Segment>;
  onSave: (segment: Partial<Segment>) => void;
};

const AddSegmentsForm = ({ segment, onSave }: AddSegmentsFormProps) => {
  const { mutateAsync: checkValidation } = useValidSegmentName();
  const schema = useSegmentValidationSchema(checkValidation);
  const { register, handleSubmit, formState } = useForm<Partial<Segment>>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: segment,
  });

  const onSubmit = (data: Partial<Segment>) => {
    onSave({
      name: data.name,
    });
  };

  return (
    <Box sx={styles.segmentNameContainer}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={styles.body}>
          <Typography variant="h6">
            <FormattedMessage id="organization.nameSegment" defaultMessage="Name segment" />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage
              id="organization.nameSegmentInfo"
              defaultMessage="A segment helps you organize your entities, manage your user permission and competitor sets"
            />
          </Typography>
          <Box sx={styles.segmentNameForm}>
            <FormattedMessage id="organization.nameOfSegment" defaultMessage="Name of segment">
              {(message) => (
                <TextField
                  data-testid="segment-name"
                  label={message}
                  variant="standard"
                  {...register('name')}
                  error={!!formState.errors.name}
                  helperText={
                    formState.errors.name?.message || (
                      <FormattedMessage id="organization.egRegion" defaultMessage="e.g. Region" />
                    )
                  }
                />
              )}
            </FormattedMessage>
          </Box>
        </Box>

        <Box sx={styles.footer}>
          <Button
            data-testid="submit-form"
            sx={styles.footerNextBtn}
            variant="contained"
            endIcon={<Icon icon={faChevronRight} />}
            disabled={!formState.isValid}
            type="submit"
          >
            <FormattedMessage id="organization.action.next" defaultMessage="Next" />
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default AddSegmentsForm;
