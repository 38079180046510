import { Grid, Typography } from '@trustyou/ui';

type GridRowProps = {
  label: string;
  value: string;
  isHeader?: boolean;
};

export const GridRow = ({ label, value, isHeader }: GridRowProps) => (
  <>
    <Grid item xs={6}>
      <Typography variant={isHeader ? 'subtitle2' : 'body2'}>{label}</Typography>
    </Grid>
    <Grid item xs={6}>
      <Typography variant={isHeader ? 'subtitle2' : 'body2'}>{value}</Typography>
    </Grid>
  </>
);
