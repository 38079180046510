import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { yupResolver } from '@hookform/resolvers/yup';
import { faChevronLeft, faPlus } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  type GroupRule,
  type Segment,
  type SegmentGroup,
  type SegmentGroupPayload,
  getGroupValidationSchema,
  parseGroupRules,
  stringifyGroupRules,
  useValidGroupName,
} from '@trustyou/shared';
import { Box, Button, StyledFontAwesomeIcon as Icon, TextField, Typography } from '@trustyou/ui';

import GroupRuleSelection from './GroupRuleSelection';

import styles from './styles';

type UpdateGroupsFormProps = {
  onSave: (data: SegmentGroupPayload) => void;
  onGoBack?: () => void;
  segment?: Partial<Segment>;
  group?: SegmentGroup;
  dataName: string;
};

type FormData = {
  name: string;
};

const defaultRule = {
  values: [],
};

const UpdateGroupsForm = ({
  segment,
  group,
  onSave,
  onGoBack,
  dataName,
}: UpdateGroupsFormProps) => {
  const intl = useIntl();
  const { mutateAsync: checkValidation } = useValidGroupName();

  const schema = getGroupValidationSchema(
    intl,
    (name) =>
      segment?.id ? checkValidation({ name, segmentId: segment.id }) : Promise.resolve(true),
    group?.name
  );

  const { register, handleSubmit, formState } = useForm<FormData>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: group,
  });

  const defaultRules = useMemo(() => parseGroupRules(group?.rule), [group]);

  const [rules, setRules] = useState<GroupRule[]>(defaultRules);

  const addRule = () => {
    setRules((state) => [...state, defaultRule]);
  };

  const deleteRule = (id: number) => {
    setRules((state) => state.filter((_, index) => index !== id));
  };

  const updateRule = (id: number, newRule: GroupRule) => {
    setRules((state) => state.map((rule, index) => (index === id ? newRule : rule)));
  };

  const isRulesValid = useMemo(
    () => !rules.find((rule) => !rule.attribute || !rule.values.length),
    [rules]
  );

  const onSubmit = (data: FormData) => {
    const payload = {
      ...data,
      rule: stringifyGroupRules(rules),
    };
    onSave(payload);
  };

  return (
    <Box sx={styles.groupNameContainer}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={styles.body}>
          <Typography variant="h6">
            {dataName === 'update-group' ? (
              <FormattedMessage
                id="organization.segments.editGroupForRegionSegment"
                defaultMessage="Edit a group for the {segment} segment"
                values={{ segment: segment?.name }}
              />
            ) : (
              <FormattedMessage
                id="organization.segments.addGroupForRegionSegment"
                defaultMessage="Add a group for the {segment} segment"
                values={{ segment: segment?.name }}
              />
            )}
          </Typography>
          <Typography variant="body2">
            <FormattedMessage
              id="organization.segments.segmentGroupInfo"
              defaultMessage="A group is a collection of entities, filtered by attributes"
            />
          </Typography>
          <Box sx={styles.groupNameForm}>
            <FormattedMessage id="organization.segments.nameOfGroup" defaultMessage="Name of group">
              {(message) => (
                <TextField
                  data-testid="group-name"
                  label={message}
                  variant="standard"
                  {...register('name')}
                  error={!!formState.errors.name}
                  helperText={
                    formState.errors.name?.message || (
                      <FormattedMessage
                        id="organization.segments.egEmea"
                        defaultMessage="e.g. EMEA"
                      />
                    )
                  }
                />
              )}
            </FormattedMessage>
          </Box>

          <Typography sx={styles.groupFilterHeader} variant="subtitle1">
            <FormattedMessage
              id="organization.segments.whichEntitiesBelongToThisGroup"
              defaultMessage="Which entities belong to this group?"
            />
          </Typography>

          {rules.map((rule, index) => (
            <GroupRuleSelection
              rule={rule}
              id={index}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              onDelete={deleteRule}
              onRuleUpdate={updateRule}
            />
          ))}
          <Button
            data-testid="add-rule"
            sx={styles.addFilterButton}
            color="primary"
            onClick={addRule}
          >
            <Icon sx={styles.addFilterButtonIcon} icon={faPlus} />
            <FormattedMessage id="organization.action.addFilter" defaultMessage="Add filter" />
          </Button>
        </Box>

        <Box sx={styles.footer}>
          {onGoBack && (
            <Button
              data-testid="go-back"
              variant="outlined"
              onClick={onGoBack}
              startIcon={<Icon icon={faChevronLeft} />}
            >
              <FormattedMessage id="organization.action.back" defaultMessage="Back" />
            </Button>
          )}
          <Button
            data-testid="submit-form"
            data-gtm-id={`${dataName}-submit-form`}
            sx={styles.footerNextBtn}
            disabled={!formState.isValid || !isRulesValid}
            type="submit"
            variant="contained"
          >
            <FormattedMessage id="organization.action.save" defaultMessage="Save" />
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default UpdateGroupsForm;
