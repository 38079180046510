import React from 'react';
import { FormattedMessage } from 'react-intl';

import { faXmark } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Box, Button, StyledFontAwesomeIcon, Typography } from '@trustyou/ui';

import styles from './styles';

export interface FailedConnectProps {
  isUniquenessError: boolean;
  onClose: () => void;
}

const FailedConnect = ({ onClose, isUniquenessError }: FailedConnectProps) => (
  <Box sx={styles.googleFinalWrapper}>
    <Box sx={{ ...styles.icon, ...styles.failedIcon }}>
      <StyledFontAwesomeIcon size="2x" icon={faXmark} />
    </Box>
    <Typography variant="h6">
      <FormattedMessage
        id="review-providers.google.failed.title"
        defaultMessage="An error occurred"
      />
    </Typography>
    <Typography variant="body2">
      {isUniquenessError ? (
        <FormattedMessage
          id="review-providers.google.uniqueness-error.description"
          defaultMessage="This account already exists"
        />
      ) : (
        <FormattedMessage
          id="review-providers.google.failed.description"
          defaultMessage="We’re not able to set up your API connection at the moment. Please try again later."
        />
      )}
    </Typography>
    <Button
      size="large"
      data-testid="back-to-review-providers-when-google-connect-failed"
      onClick={onClose}
      variant="contained"
    >
      <FormattedMessage
        id="review-providers.google.static.button-text"
        defaultMessage="Back to review providers"
      />
    </Button>
  </Box>
);

export default FailedConnect;
