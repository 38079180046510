import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useLanguageStore } from '@trustyou/shared';
import { ListItemText } from '@trustyou/ui';

import type { Category } from '.';
import type { SemaLanguage } from '../../client';
import { useFetchTopLevelCategories } from '../../hooks';
import { FiltersFormFields } from '../../types';
import { FilterDialogButton } from '../filter-dialog-button';
import { SelectorModal } from '../selector-modal';

interface TopLevelCategoriesProps {
  initialSelection?: string[] | null;
  isLoading?: boolean;
  saveTopicIds: () => void;
}

export const Categories = ({
  initialSelection: initialSelectedIds = [],
  isLoading: isParentLoading,
  saveTopicIds,
}: TopLevelCategoriesProps) => {
  const intl = useIntl();
  const { setValue, getValues } = useFormContext();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
  const [displayedCategories, setDisplayedCategories] = useState<Category[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);

  const { locale } = useLanguageStore();
  const { data, isPending } = useFetchTopLevelCategories(locale as SemaLanguage);
  useEffect(() => {
    if (!isPending && data) {
      const reshapedData = Object.entries(data).reduce(
        (res: { id: string; title: string }[], [key, value]) => {
          res.push({ id: key, title: value });
          return res;
        },
        []
      );
      const initialSelection = reshapedData.filter(({ id }) => initialSelectedIds?.includes(id));
      setValue(
        FiltersFormFields.Categories,
        initialSelection.map(({ id }) => id),
        { shouldDirty: false }
      );
      setSelectedCategories(initialSelection);
      setDisplayedCategories(reshapedData);
      setCategories(reshapedData);
    }
  }, [data, initialSelectedIds, isPending, setValue]);

  const openDialog = () => {
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
    setDisplayedCategories(categories);
  };

  const saveCategories = () => {
    const ids = selectedCategories.map(({ id }) => id);
    setValue(FiltersFormFields.Categories, ids, { shouldDirty: true });
    saveTopicIds();
    closeDialog();
  };

  const cancel = () => {
    setSelectedCategories(
      categories.filter(({ id }) => getValues(FiltersFormFields.Categories)?.includes(id)) || []
    );
    closeDialog();
  };

  const renderRowContent = (category: Category) => <ListItemText primary={category.title} />;

  const onSearch = (value: string) => {
    if (value === '') {
      setDisplayedCategories(categories);
    } else
      setDisplayedCategories(
        categories.filter(({ title }) => title.toLowerCase().includes(value.toLowerCase()))
      );
  };

  return (
    <>
      <FilterDialogButton
        title={<FormattedMessage id="inbox.filter.categories.name" defaultMessage="Categories" />}
        description={
          <FormattedMessage
            id="inbox.filter.selected-ratio"
            defaultMessage="{count} / {total} selected"
            values={{
              count: selectedCategories.length > 0 ? selectedCategories.length : categories.length,
              total: categories.length,
            }}
          />
        }
        onClick={openDialog}
      />
      <SelectorModal
        isOpen={isOpen}
        items={displayedCategories}
        selectedItems={selectedCategories}
        setSelectedItems={setSelectedCategories}
        isLoading={isPending || isParentLoading}
        onClose={cancel}
        onSave={saveCategories}
        renderRowContent={renderRowContent}
        searchPlaceholder={intl.formatMessage({
          id: 'inbox.search.categories',
          defaultMessage: 'Search categories',
        })}
        title={
          <FormattedMessage id="inbox.filter.categories.modal.title" defaultMessage="Categories" />
        }
        allTab={
          <FormattedMessage
            id="inbox.filter.categories.modal.button.all"
            defaultMessage="All categories"
          />
        }
        selectTab={
          <FormattedMessage
            id="inbox.filter.categories.modal.button.select"
            defaultMessage="Select categories"
          />
        }
        selectedHeaderTitle={
          <FormattedMessage
            id="inbox.filter.categories.modal.selected-count"
            defaultMessage="Selected categories ({count})"
            values={{ count: selectedCategories.length }}
          />
        }
        alertDescription={
          <FormattedMessage
            id="inbox.filter.categories.modal.alert"
            defaultMessage="Please select at least one category"
          />
        }
        onSearch={onSearch}
      />
    </>
  );
};
