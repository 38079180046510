import { useQuery } from '@tanstack/react-query';
import {
  $assApi,
  ANALYTICS_BASE_PATH,
  type PaginationRequest,
  type UsersResponse,
} from '@trustyou/shared';

import { USERS_BY_ORGANIZATION } from '../../constants/query-keys';

export async function fetchUsersByOrganization(
  { limit, offset }: PaginationRequest,
  sortKey: string,
  searchKey?: string
): Promise<UsersResponse> {
  const { data } = await $assApi.get(`${ANALYTICS_BASE_PATH}/report/get_users`, {
    params: {
      limit,
      offset,
      order: sortKey,
      ...(searchKey && { q: searchKey }),
    },
  });
  return data;
}

export function useUsersByOrganization(
  pagination: PaginationRequest,
  sortKey: string,
  searchKey?: string
) {
  return useQuery({
    queryKey: [USERS_BY_ORGANIZATION, pagination, sortKey, searchKey],
    queryFn: () => fetchUsersByOrganization(pagination, sortKey, searchKey),
    refetchOnWindowFocus: false,
    enabled: true,
  });
}
