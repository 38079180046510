import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { faClose, faRotateRight } from '@trustyou/fortawesome/pro-regular-svg-icons';
import type { SelectionMode } from '@trustyou/shared';
import {
  Button,
  StyledFontAwesomeIcon as Icon,
  IconButton,
  List,
  ListItem,
  type SelectorItem,
  Stack,
  Typography,
} from '@trustyou/ui';

import styles from './SelectedList.styles';

export type SelectedListProps<T> = {
  items: (T & SelectorItem)[];
  header: ReactNode;
  mode?: SelectionMode;
  onRemove: (item: (T & SelectorItem)[]) => void;
  onReset?: () => void;
  renderRowContent: (item: T & SelectorItem) => ReactNode;
};

export const SelectedList = <T,>({
  items,
  header,
  mode,
  onRemove,
  onReset,
  renderRowContent,
}: SelectedListProps<T>) => (
  <Stack gap={2.5} width="100%">
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-end"
      gap={2}
      width="100%"
    >
      <Typography sx={styles.header} variant="subtitle1" gutterBottom>
        {header}
      </Typography>
      {onReset && (
        <Button
          startIcon={<Icon size="lg" icon={faRotateRight} />}
          variant="text"
          onClick={onReset}
        >
          <FormattedMessage id="common.action.reset" defaultMessage="Reset" />
        </Button>
      )}
    </Stack>
    <List
      dense
      disablePadding
      sx={{
        ...styles.list,
        ...(mode === 'single' && styles.singleList),
      }}
    >
      {items.map((item) => (
        <ListItem
          key={item.id}
          sx={{
            ...styles.listItem,
            ...(mode === 'single' && styles.singleListItem),
          }}
          secondaryAction={
            <IconButton
              data-testid="selected-entity-remove-button"
              onClick={() => onRemove([item])}
            >
              <Icon size="xs" icon={faClose} />
            </IconButton>
          }
        >
          {renderRowContent(item)}
        </ListItem>
      ))}
    </List>
  </Stack>
);
