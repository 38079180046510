import { FormattedMessage } from 'react-intl';

import type { AccessGroup } from '@trustyou/shared';
import { DeleteModal } from '@trustyou/ui';

export type DeleteAccessGroupModalProps = {
  accessGroup?: AccessGroup;
  onClose: () => void;
  onDelete: () => void;
};

const DeleteAccessGroupModal = ({
  accessGroup,
  onClose,
  onDelete,
}: DeleteAccessGroupModalProps) => (
  <DeleteModal
    cancelText={
      <FormattedMessage id="access-group.delete-confirmation.cancel" defaultMessage="Cancel" />
    }
    content={
      <FormattedMessage
        id="access-group.delete-confirmation.content"
        defaultMessage="You’ll not be able to assign this access group to members."
      />
    }
    dataName="access-group-delete"
    deleteText={
      <FormattedMessage id="access-group.delete-confirmation.delete" defaultMessage="Delete" />
    }
    onClose={onClose}
    onDelete={onDelete}
    title={
      <FormattedMessage
        id="access-group.delete-confirmation.title"
        defaultMessage="Delete {accessGroup}?"
        values={{ accessGroup: accessGroup?.name }}
      />
    }
    open={!!accessGroup}
  />
);

export default DeleteAccessGroupModal;
