import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    py: 2,
    px: 3,
    gap: 1,
    backgroundColor: 'rgba(3, 155, 229, 0.04)',
    maxWidth: '568px',
  },
  chip: {
    color: 'background.paper',
    backgroundColor: 'warning.main',
  },
};

export default styles;
