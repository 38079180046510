import { FormattedMessage } from 'react-intl';

import type { CardProps } from '@mui/material';
import { faArrowRight } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { stringToDateAndTime } from '@trustyou/shared';
import { Box, ClampedTypography, Stack, StyledFontAwesomeIcon, Typography } from '@trustyou/ui';

import { BaseCard } from './base-card';

const Separator = () => ': ';

type SmallCardProps = {
  title: string;
  lastUpdated?: string;
  questionnaireCount: number;
  entityCount: number;
  onClick: CardProps['onClick'];
};

export function SmallCard({
  title,
  lastUpdated,
  questionnaireCount,
  entityCount,
  onClick,
}: SmallCardProps) {
  return (
    <BaseCard onClick={onClick} sx={{ height: 220 }}>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <ClampedTypography
          maxLines={2}
          title={title}
          variant="h6"
          gutterBottom
          sx={{ fontSize: 'initial' }}
        >
          {title}
        </ClampedTypography>
        <StyledFontAwesomeIcon icon={faArrowRight} size="lg" sx={{ color: 'primary.main' }} />
      </Stack>
      <ClampedTypography maxLines={1} variant="body2" color="text.secondary">
        <FormattedMessage id="survey.common.last-updated" defaultMessage="Last updated" />
        <Separator />
        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        <Box component="span">{stringToDateAndTime(lastUpdated!)}</Box>
      </ClampedTypography>
      <Box flexGrow={1} />
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle2" gutterBottom>
          <FormattedMessage id="survey.common.questionnaires" defaultMessage="Questionnaires" />
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {questionnaireCount}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle2">
          <FormattedMessage id="survey.common.entities" defaultMessage="Entities" />
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {entityCount}
        </Typography>
      </Stack>
    </BaseCard>
  );
}
