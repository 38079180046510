import type { ReactNode } from 'react';

import { Box, Typography } from '@trustyou/ui';

import styles from './styles';

export interface WhereCanIFindProps {
  title: ReactNode;
  description: ReactNode;
  src: string;
}

const WhereCanIFind = ({ title, description, src }: WhereCanIFindProps) => (
  <Box sx={styles.whereCanIFindWrapper}>
    <Typography variant="subtitle1">{title}</Typography>
    <Typography variant="body2">{description}</Typography>
    <img src={src} alt="Booking com instructions screenshot" />
  </Box>
);

export default WhereCanIFind;
