import { FormattedMessage } from 'react-intl';

import { USER_SCOPES, useScopeStore } from '@trustyou/shared';
import { Box, EntitySelector, GroupSelector, ScopeSelector, Stack, Typography } from '@trustyou/ui';

type EditorScopeContentProps = {
  variant: 'survey' | 'theme';
};

export function EditorScopeContent({ variant }: EditorScopeContentProps) {
  const selectedScope = useScopeStore((state) => state.selectedScope);

  return (
    <Stack spacing={2}>
      <Box>
        <Typography variant="h6">
          <FormattedMessage
            id="survey.editor.step-scope.title"
            defaultMessage="Assign to entities"
          />
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {variant === 'survey' ? (
            <FormattedMessage
              id="survey.survey-editor.step-scope.description"
              defaultMessage="Decide which entities will have this Survey version assigned."
            />
          ) : (
            <FormattedMessage
              id="survey.theme-editor.step-scope.description"
              defaultMessage="Decide which entities will have this Theme or Theme version assigned."
            />
          )}
        </Typography>
      </Box>
      <Box>
        <Typography variant="subtitle1" fontWeight={500}>
          <FormattedMessage id="survey.editor.step-scope.subtitle" defaultMessage="Scope" />
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {variant === 'survey' ? (
            <FormattedMessage
              id="survey.survey-editor.step-scope.subdescription"
              defaultMessage="Which entities will have access to this Survey?"
            />
          ) : (
            <FormattedMessage
              id="survey.theme-editor.step-scope.subdescription"
              defaultMessage="Which entities will have access to this Theme?"
            />
          )}
        </Typography>
      </Box>
      <ScopeSelector scopes={Object.values(USER_SCOPES)} />
      {selectedScope === USER_SCOPES.BY_ENTITIES && <EntitySelector />}
      {selectedScope === USER_SCOPES.BY_GROUPS && <GroupSelector />}
    </Stack>
  );
}
