import { FormattedMessage } from 'react-intl';

import { Chip, Tooltip } from '@trustyou/ui';

import styles from './styles';

interface DashboardCellProps {
  isOnDashboard: boolean;
  onClick: () => void;
}

const DashboardCell = ({ isOnDashboard, onClick }: DashboardCellProps) => {
  const onClickHandle = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onClick();
  };
  if (!isOnDashboard) return null;
  return (
    <Tooltip
      placement="top"
      arrow
      title={
        <FormattedMessage
          id="organization.clickToChangeSegmentsToShowInDashboard"
          defaultMessage="Click to change segments to show in dashboard"
        />
      }
    >
      <Chip
        label={<FormattedMessage id="organization.onDashboard" defaultMessage="On dashboard" />}
        size="small"
        data-testid="on-dashboard-chip"
        variant="pastelDefault"
        sx={styles.chip}
        onClick={onClickHandle}
      />
    </Tooltip>
  );
};

export default DashboardCell;
