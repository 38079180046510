import { faChevronDown, faChevronUp } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  Checkbox,
  StyledFontAwesomeIcon as Icon,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@trustyou/ui';

import styles from './styles';

export interface Item {
  id: string;
  text: string;
  order: number;
  group?: string;
}

export interface GroupedItem extends Item {
  groupItems?: Item[];
}

type ListItemProps = {
  item: GroupedItem;
  isSelected: boolean;
  groupExpanded?: boolean;
  isSubItem?: boolean;
  onToggle: () => void;
  onToggleExpand?: () => void;
};

export const ListItem = ({
  item,
  isSelected,
  groupExpanded,
  isSubItem,
  onToggle,
  onToggleExpand,
}: ListItemProps) => {
  return (
    <ListItemButton
      sx={isSubItem ? styles.listSubItemButton : styles.listItemButton}
      selected={isSelected}
      onClick={onToggle}
    >
      <ListItemIcon>
        <Checkbox checked={isSelected} />
      </ListItemIcon>
      <ListItemText primary={item.groupItems ? item.group : item.text} />
      {item.groupItems && onToggleExpand ? (
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            onToggleExpand();
          }}
          size="small"
        >
          <Icon size="sm" icon={groupExpanded ? faChevronUp : faChevronDown} />
        </IconButton>
      ) : null}
    </ListItemButton>
  );
};
