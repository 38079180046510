import { FormattedMessage } from 'react-intl';

import { faPlus } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { type StyleUnit, useRouteUtils } from '@trustyou/shared';
import { Button, StyledFontAwesomeIcon as Icon } from '@trustyou/ui';

import { commonMessages } from '../../constants/messages';

type Props = {
  sx?: StyleUnit;
  variant?: 'outlined' | 'contained';
};

export const NewReportButton = ({ sx, variant = 'outlined' }: Props) => {
  const { navigateWithBase } = useRouteUtils();
  return (
    <Button
      variant={variant}
      sx={{ width: 'fit-content', ...sx }}
      startIcon={<Icon icon={faPlus} />}
      onClick={() => navigateWithBase('reports/new-report')}
    >
      <FormattedMessage {...commonMessages.newReport} />
    </Button>
  );
};
