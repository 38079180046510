/* eslint-disable @nx/enforce-module-boundaries */
import { faUser } from '@trustyou/fortawesome/pro-regular-svg-icons';

import * as avatars from '../../design-tokens/avatars';
import { Avatar } from '../../mui';
import { directColouredAvatar, stringBrandedAvatar } from '../../utils/stringBrandedAvatar';
import { StyledFontAwesomeIcon as Icon } from '../index';

export interface BrandedAvatarProps {
  name?: string;
  brandColor?: string;
}

const NoNameAvatar = () => (
  <Avatar sx={{ backgroundColor: avatars.avatarsDefault }}>
    <Icon icon={faUser} />
  </Avatar>
);

export const BrandedAvatar = ({ name, brandColor }: BrandedAvatarProps) => {
  if (brandColor) return <Avatar {...directColouredAvatar(brandColor)} />;
  if (!name) return <NoNameAvatar />;
  return <Avatar {...stringBrandedAvatar(name)} />;
};
