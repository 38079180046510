import { green, red } from '@mui/material/colors';
import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  pageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    '& > div': {
      maxWidth: '500px',
      paddingX: 0,
      justifyContent: 'center',
    },
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 2,
  },
  instructionDescription: {
    marginY: 1,
  },
  googleStaticConnectWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    gap: 2,
  },
  googleFinalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    gap: 3,
    '& h6+p': {
      mt: -3,
    },
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '64px',
    height: '64px',
    borderRadius: '50%',
  },
  successIcon: {
    color: 'success.main',
    backgroundColor: green[50],
  },
  failedIcon: {
    color: 'error.main',
    backgroundColor: red[50],
  },
};

export default styles;
