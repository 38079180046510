import { useEffect, useState } from 'react';

export function useDebounce<T>(param: T, delay: number) {
  const [debounced, setDebounced] = useState(param);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounced(param);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [param, delay]);

  return debounced;
}
