import { FormattedMessage } from 'react-intl';

import { faPlus, faTrash } from '@trustyou/fortawesome/pro-regular-svg-icons';
import type { Entity } from '@trustyou/shared';
import { ActionCell, type SingleActionProps } from '@trustyou/ui';

interface EntityActionCellProps {
  entity: Entity;
  handleDelete?: (entity: Entity) => void;
  handleAddCompetitors?: (entity: Entity) => void;
}

const EntityActionCell = ({
  entity,
  handleDelete,
  handleAddCompetitors,
}: EntityActionCellProps) => {
  const actions: SingleActionProps[] = [];

  if (handleAddCompetitors) {
    actions.push({
      testId: 'entity-action-add-competitors',
      icon: faPlus,
      handler: () => handleAddCompetitors(entity),
      message: (
        <FormattedMessage id="organization.competitors.add" defaultMessage="Add competitors" />
      ),
    });
  }

  if (handleDelete) {
    actions.push({
      testId: 'entity-action-delete',
      icon: faTrash,
      handler: () => handleDelete(entity),
      message: <FormattedMessage id="organization.action.delete" defaultMessage="Delete" />,
    });
  }

  return <ActionCell testId="entity-action-menu" actions={actions} />;
};

export default EntityActionCell;
