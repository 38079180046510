import { useIntl } from 'react-intl';

import { reportFrequencyOptions } from '../constants/messages';

export function useFrequencyOptions() {
  const intl = useIntl();
  return Object.entries(reportFrequencyOptions).map(([value, label]) => ({
    value,
    label: intl.formatMessage(label),
  }));
}
