import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { actionMessages, commonFields } from '@trustyou/shared';
import { Divider, InfoDialog, Stack, Typography } from '@trustyou/ui';

import { common, manageDrawer } from '../../constants/messages/customAttributes';

type Props = {
  segmentsByAttribute?: Record<string, string[]>;
  onClose: () => void;
};

export const UpdateProhibitionDialog = ({ segmentsByAttribute, onClose }: Props) => (
  <InfoDialog
    title={<FormattedMessage {...manageDrawer.updateProhibitionDialogTitle} />}
    open={!!segmentsByAttribute}
    primaryActionText={<FormattedMessage {...actionMessages.okay} />}
    onPrimaryAction={onClose}
    dataName="custom-attribute-update"
    maxWidth="sm"
  >
    <Stack gap={2} paddingX={3}>
      <Typography variant="body1">
        <FormattedMessage {...manageDrawer.updateProhibitionDialogContent} />
      </Typography>
      <Stack>
        <Stack gap={3} direction="row" padding={2}>
          <Typography width="50%" variant="subtitle2">
            <FormattedMessage {...common.attributeOption} />
          </Typography>
          <Typography variant="subtitle2">
            <FormattedMessage {...commonFields.segments} />
          </Typography>
        </Stack>
        {segmentsByAttribute &&
          Object.entries(segmentsByAttribute).map(([key, value]) => (
            <Fragment key={key}>
              <Divider />
              <Stack gap={3} direction="row" padding={2}>
                <Typography width="50%" variant="body2">
                  {key}
                </Typography>
                <Typography variant="body2">{value.join(',')}</Typography>
              </Stack>
            </Fragment>
          ))}
      </Stack>
    </Stack>
  </InfoDialog>
);
