import { $assApi, INBOX_BASE_PATH } from '@trustyou/shared';

import type {
  EmailNotificationSettingsIn_Input,
  EmailNotificationSettingsIn_Output,
} from '../../../client';
import type { GetResponseTemplatesResponse } from '../../../types';

export async function getEmailNotification(): Promise<EmailNotificationSettingsIn_Input[]> {
  const { data } = await $assApi.get(`${INBOX_BASE_PATH}/user-settings/get-email-notification`);
  return data;
}

export async function setEmailNotification(
  payload: EmailNotificationSettingsIn_Input
): Promise<EmailNotificationSettingsIn_Output> {
  const { data } = await $assApi.put(
    `${INBOX_BASE_PATH}/user-settings/set-email-notification`,
    payload
  );
  return data;
}

export async function getResponseTemplates(): Promise<GetResponseTemplatesResponse> {
  const { data } = await $assApi.get(`${INBOX_BASE_PATH}/user-settings/get-response-templates`);
  return data;
}

export async function setSignature(signature?: string): Promise<string | null> {
  const { data } = await $assApi.put(`${INBOX_BASE_PATH}/user-settings/set-signature`, {
    params: {
      signature,
    },
  });
  return data;
}

export async function getSignature(): Promise<string | null> {
  const { data } = await $assApi.get(`${INBOX_BASE_PATH}/user-settings/get-signature`);
  return data;
}
