import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { type Subscription, useCurrentSubscription, useSubscription } from '@trustyou/shared';
import { Box } from '@trustyou/ui';

import { NoEntitiesAdmin } from './NoEntitiesAdmin';

import { AddEntityDialog, ManageEntitiesButtonBlock } from '../../components';
import AddAdditionalEntities from '../Organization/Entities/AddAdditionalEntities';
import styles from './styles';

export interface ManageNoEntitiesProps {
  subscriptionId?: string;
}

export const ManageNoEntities = ({ subscriptionId }: ManageNoEntitiesProps) => {
  const [chosenSubscription, setChosenSubscription] = useState<Subscription | undefined>(undefined);
  const [isImportEntitiesModalOpen, setIsImportEntitiesModalOpen] = useState(false);

  const navigate = useNavigate();
  const { membershipId } = useParams();

  const { data: currentSubscription } = useCurrentSubscription();
  const { data: specificSubscription } = useSubscription(subscriptionId);

  const onImportEntities = () => {
    if (subscriptionId) {
      navigate(`/org/${membershipId}/upload-subscription-entities/${specificSubscription?.id}`);
    } else {
      toggleImportEntitiesModal();
    }
  };

  const onAddEntity = () => {
    setChosenSubscription(subscriptionId ? specificSubscription : currentSubscription);
  };

  const toggleImportEntitiesModal = () => {
    setIsImportEntitiesModalOpen(!isImportEntitiesModalOpen);
  };

  const closeAddEntityDialog = () => {
    setChosenSubscription(undefined);
  };

  const showManageEntities = () => {
    if (!currentSubscription) return false;
    if (subscriptionId) {
      if (!specificSubscription) return false;
      if (specificSubscription.number_of_allowed_entities === 0) return false;
      return true;
    }
    if (currentSubscription.number_of_allowed_entities === 0) return false;
    return true;
  };

  return (
    <>
      <NoEntitiesAdmin>
        {showManageEntities() ? (
          <Box sx={styles.manageNoEntitiesWrapper}>
            <ManageEntitiesButtonBlock
              onImportEntities={onImportEntities}
              onAddEntity={onAddEntity}
            />
          </Box>
        ) : null}
      </NoEntitiesAdmin>
      {chosenSubscription && (
        <AddEntityDialog subscription={chosenSubscription} onClose={closeAddEntityDialog} />
      )}
      {isImportEntitiesModalOpen && <AddAdditionalEntities onClose={toggleImportEntitiesModal} />}
    </>
  );
};
