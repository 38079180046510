import { ButtonGroup } from '../../components/button-group';
import { CheckboxSingle } from '../../components/checkbox-single';
import { Question, type QuestionProps } from '../../components/question';
import { RadioGroup } from '../../components/radio-group';
import type { Option } from '../../types';

export type BooleanQuestionVariant = 'toggle' | 'checkbox' | 'radio_group' | 'button_group';
export type BooleanQuestionProps = QuestionProps & {
  options: Option[];
  variant?: BooleanQuestionVariant;
};

export function BooleanQuestion({ variant = 'checkbox', ...props }: BooleanQuestionProps) {
  return (
    <Question {...props}>
      <>
        {variant === 'checkbox' && <CheckboxSingle {...props} />}
        {variant === 'radio_group' && <RadioGroup {...props} />}
        {variant === 'button_group' && <ButtonGroup {...props} />}
      </>
    </Question>
  );
}
