import type { IntlShape } from 'react-intl';

import { debounce } from '@mui/material';
import * as yup from 'yup';

import type { GroupRule } from '../types';

const GROUP_NAME_LENGTH = 50;
const VALIDATE_DEBOUNCE_TIME = 500;
const DEFAULT_GROUP_RULE = {
  values: [],
};

export const getGroupValidationSchema = (
  intl: IntlShape,
  checkValidation: (name: string) => Promise<boolean>,
  defaultValue?: string
) => {
  const duplicateError = intl.formatMessage({
    id: 'group.exists',
    defaultMessage: 'Entered group already exists',
  });

  const debounceDuplicateCheck = debounce((resolve: (val: boolean) => void, value) => {
    if (defaultValue && defaultValue === value) {
      return resolve(true);
    }
    return checkValidation(value).then(resolve);
  }, VALIDATE_DEBOUNCE_TIME);

  return yup.object().shape({
    name: yup
      .string()
      .max(GROUP_NAME_LENGTH)
      .required()
      .test(
        'duplicateCheck',
        duplicateError,
        (value) =>
          new Promise((resolve) => {
            debounceDuplicateCheck(resolve, value);
          })
      ),
  });
};

export const parseGroupRules = (rule?: string) => {
  if (rule) {
    try {
      return Object.entries(JSON.parse(rule)).map(([attribute, values]) => ({
        attribute: attribute as string,
        values: values as string[],
      }));
    } catch (error) {
      return [DEFAULT_GROUP_RULE];
    }
  }
  return [DEFAULT_GROUP_RULE];
};

const mergeGroupRules = (curValues: string[], prevValues?: string[]): string[] => {
  if (!prevValues) return curValues;
  return [...new Set([...prevValues, ...curValues])];
};

export const stringifyGroupRules = (rules: GroupRule[]) =>
  JSON.stringify(
    rules.reduce(
      (prev: Record<string, string[]>, cur) => ({
        ...prev,
        [cur.attribute as string]: mergeGroupRules(cur.values, prev[cur.attribute as string]),
      }),
      {}
    )
  );
