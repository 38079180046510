import type {
  CreateGroup,
  CreateGroupResponse,
  SegmentGroupCreated,
  SegmentGroupPayload,
  SegmentGroupsResponse,
} from '../../../../types/model/segment';
import type { PaginationRequest } from '../../../../types/model/shared';
import { $assApi } from '../../../api';
import { SEGMENTS_BASE_PATH } from '../../path';

export async function fetchSegmentGroups(
  segmentId: string,
  { limit, offset }: PaginationRequest,
  sortKey: string
): Promise<SegmentGroupsResponse> {
  const { data } = await $assApi.get(`${SEGMENTS_BASE_PATH}/get_groups`, {
    params: {
      segment_id: segmentId,
      limit: limit,
      offset: offset,
      order: sortKey,
    },
  });
  return data;
}

export async function searchSegmentGroups(
  segmentId: string,
  { limit, offset }: PaginationRequest,
  sortKey: string,
  searchKey = ''
): Promise<SegmentGroupsResponse> {
  const { data } = await $assApi.get(`${SEGMENTS_BASE_PATH}/search_groups`, {
    params: {
      segment_id: segmentId,
      limit: limit,
      offset: offset,
      order: sortKey,
      q: searchKey,
    },
  });
  return data;
}

export async function isValidGroupName(name: string, segmentId: string): Promise<boolean> {
  const { data } = await $assApi
    .get(`${SEGMENTS_BASE_PATH}/is_valid_group_name`, {
      params: {
        name,
        segment_id: segmentId,
      },
    })
    .then((response) => response.data)
    .catch(() => ({ data: { valid: false } }));

  return data.valid;
}

export async function createGroup(group: CreateGroup): Promise<CreateGroupResponse> {
  const { data } = await $assApi.post(`${SEGMENTS_BASE_PATH}/create_group`, group);
  return data.data;
}

export async function updateGroup(group: SegmentGroupPayload): Promise<SegmentGroupCreated> {
  const { data } = await $assApi.post(`${SEGMENTS_BASE_PATH}/update_group`, group);
  return data.data;
}

export async function deleteGroup(groupId: string): Promise<string> {
  const { data } = await $assApi.delete(`${SEGMENTS_BASE_PATH}/delete_group`, {
    params: {
      group_id: groupId,
    },
  });
  return data;
}
