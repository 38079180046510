import { FormattedMessage } from 'react-intl';

import { Box, Button } from '@trustyou/ui';

import styles from './styles';

type FooterProps = {
  actionMode: 'update' | 'create';
  onCancel?: () => void;
  onSave?: () => void;
};

export const Footer = ({ actionMode, onCancel, onSave }: FooterProps) => (
  <Box sx={styles.footer}>
    <Button data-testid={`cancel-${actionMode}-access-group`} variant="outlined" onClick={onCancel}>
      <FormattedMessage id="access-group.cancel" defaultMessage="Cancel" />
    </Button>
    <Button
      data-testid={`${actionMode}-access-group`}
      data-gtm-id={`${actionMode}_access_group`}
      variant="contained"
      onClick={onSave}
    >
      {actionMode === 'update' ? (
        <FormattedMessage id="access-group.save-changes" defaultMessage="Save changes" />
      ) : (
        <FormattedMessage id="access-group.add-access-group" defaultMessage="Add access group" />
      )}
    </Button>
  </Box>
);
