import { grey } from '@mui/material/colors';
import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  stepperContainer: {
    px: 1,
    py: 2,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: grey[100],
  },
  stepper: {
    '& .MuiStepIcon-root.Mui-active, .MuiStepIcon-root.Mui-completed': {
      color: 'secondary.main',
    },
    width: '80%',
  },
};

export default styles;
