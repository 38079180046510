import { type ReactElement, type ReactNode, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import { OidcProvider } from '@axa-fr/react-oidc';
import { AuthPendingState, useAuthorizationStore, useChangelingStore } from '@trustyou/shared';
import { Box, CircularProgress, Typography } from '@trustyou/ui';

// import IsLoggedOut from '../../../components/LoadingScreen/IsLoggedOut';
import { keycloakOidcConfig, keycloakOidcOperationsConfig } from '../../../config';
import useInitChangeling from '../../../service/hooks/changeling/useInitChangeling';
import styles from './styles';

type ProtectedRouteProps = {
  children: ReactElement;
};

type LoadingProps = {
  children: ReactNode;
};

const Loading = ({ children }: LoadingProps) => (
  <Box sx={styles.loadingContainer}>
    <CircularProgress />
    <Typography sx={styles.loadingTitle} variant="subtitle1">
      {children}
    </Typography>
  </Box>
);

const Authenticating = () => (
  <Loading>
    <FormattedMessage id="auth.loading" defaultMessage="Authenticating..." />
  </Loading>
);

const SessionLost = () => {
  useEffect(() => {
    // I need to redirect to root to execute all protected route component logic.
    // Login() only will be not enough and make the app to block in loading state.
    window.location.replace(`${process.env.PUBLIC_URL}`);
  }, []);

  return (
    <Loading>
      <FormattedMessage id="auth.redirecting" defaultMessage="Session lost... Redirecting" />
    </Loading>
  );
};

const AuthenticationProvider = ({ children }: ProtectedRouteProps) => {
  const { isCompleted } = useInitChangeling();
  const { isChangeling, isReadyToAuthToAdmin } = useChangelingStore();
  const { pendingState } = useAuthorizationStore();

  const [searchParams] = useSearchParams();
  const theme = searchParams.get('theme');

  if (!isCompleted && pendingState !== AuthPendingState.LOGGING_OUT) return null;

  return (
    <OidcProvider
      configuration={
        !(isChangeling && isReadyToAuthToAdmin)
          ? keycloakOidcConfig({ theme })
          : keycloakOidcOperationsConfig({ theme })
      }
      loadingComponent={Authenticating}
      authenticatingComponent={Authenticating}
      callbackSuccessComponent={Authenticating}
      sessionLostComponent={SessionLost}
      authenticatingErrorComponent={SessionLost}
    >
      {children}
    </OidcProvider>
  );
};

export default AuthenticationProvider;
