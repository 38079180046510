import { FormattedMessage } from 'react-intl';

import { EmptyStatePlaceholder } from '@trustyou/ui';

import personSelectingData from '../../assets/illustrations/person-selecting-data.svg';
import styles from './styles';

export const EmptyAccessGroupState = () => (
  <EmptyStatePlaceholder
    img={personSelectingData}
    title={
      <FormattedMessage
        id="organization.access-groups.no-access-groups"
        defaultMessage="No access groups yet"
      />
    }
    description={
      <FormattedMessage
        id="organization.access-groups.no-access-groups-description"
        defaultMessage="When you add access groups, they will show up here."
      />
    }
    emptyStateHeader={styles.emptyStateHeader}
    emptyStateContainer={{ ...styles.emptyStateContainer, ...styles.noDataContainer }}
  />
);
