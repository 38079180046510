import { useQuery } from '@tanstack/react-query';
import { $assApi, ANALYTICS_BASE_PATH } from '@trustyou/shared';

import { SINGLE_REPORT } from '../../constants/query-keys';
import type { ScheduledReport } from '../../types/reports';

async function fetchReport(reportId: string): Promise<ScheduledReport> {
  const { data } = await $assApi.get(`${ANALYTICS_BASE_PATH}/report/get`, {
    params: {
      report_id: reportId,
    },
  });
  return data;
}

export function useReport(id: string, enabled = true) {
  return useQuery({
    queryKey: [SINGLE_REPORT, id],
    queryFn: () => fetchReport(id),
    refetchOnWindowFocus: false,
    enabled,
  });
}
