import { useIntl } from 'react-intl';

import { openInNewTab, useMembershipStore } from '@trustyou/shared';

const openLink = (url: string, locale: string) =>
  openInNewTab(url.replace('{lang_code}', locale.toLowerCase()) as string);

const useZendeskLinks = () => {
  const { membership } = useMembershipStore();
  const intl = useIntl();
  const supportFeedbackURL = process.env.REACT_APP_URLS__ZENDESK_SUPPORT?.concat(
    `?org=${encodeURIComponent(membership?.organizationId as string)}`
  );

  const openHelpManual = () => {
    openLink(process.env.REACT_APP_URLS__ZENDESK_HELP_MANUAL as string, intl.locale);
  };
  const openSupportAndFeedback = () => {
    openLink(supportFeedbackURL as string, intl.locale);
  };
  return { openHelpManual, openSupportAndFeedback };
};

export default useZendeskLinks;
