import { Box, Typography } from '@trustyou/ui';

import styles from './styles';

export type DetailsCellProps = {
  primaryValue: string;
  secondaryValue: string;
};

export const DetailsCell = ({ primaryValue, secondaryValue }: DetailsCellProps) => (
  <Box sx={styles.detailsCell}>
    <Typography sx={styles.detailsCellText} variant="body2">
      {primaryValue}
    </Typography>
    <Typography sx={styles.detailsCellText} variant="caption" color="text.secondary">
      {secondaryValue}
    </Typography>
  </Box>
);
