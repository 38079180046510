import { FormattedMessage } from 'react-intl';

import { Box, Stack, Typography } from '@trustyou/ui';

import { ThemeCard } from '../../../components/theme/theme-card';
import { ThemeEmptyState } from '../../../components/theme/theme-empty-state';

function ThemeListHeader() {
  return (
    <Box>
      <Typography variant="h6" component="h2">
        <FormattedMessage id="survey.common.overview" defaultMessage="Overview" />
      </Typography>
      <Typography variant="body2" color="text.secondary">
        <FormattedMessage
          id="survey.theme.subtitle"
          defaultMessage="Themes define the look and feel of your Surveys and email invitations. You can share them with groups of entities or just a selected few."
        />
      </Typography>
    </Box>
  );
}

export function ThemeList() {
  // const { data: themes = [] } = useThemes();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const themes: any[] = [];

  if (themes.length === 0)
    return (
      <Stack spacing={3}>
        <ThemeListHeader />
        <Box padding={10}>
          <ThemeEmptyState />
        </Box>
      </Stack>
    );

  return (
    <Stack spacing={3}>
      <ThemeListHeader />
      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', columnGap: 5, rowGap: 3 }}>
        {themes.map((theme) => (
          <ThemeCard key={theme.id} theme={theme} />
        ))}
      </Box>
    </Stack>
  );
}
