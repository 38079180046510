import { grey } from '@mui/material/colors';
import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    width: '100%',
  },
  list: {
    border: `1px solid ${grey[300]}`,
    borderRadius: '4px',
    padding: 0,
    flex: '1',
    height: 'fit-content',
    overflowY: 'auto',
  },
  listItem: {
    borderBottom: `1px solid ${grey[300]}`,
  },
  listItemIcon: {
    minWidth: 'unset',
  },
};

export default styles;
