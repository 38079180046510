import { Entities } from './entities';

export type EntityFilterProps = {
  initial: {
    entityIds: string[];
  };
};

export function EntityFilter({ initial }: EntityFilterProps) {
  return <Entities initialSelection={initial.entityIds} />;
}
