import { FormattedMessage } from 'react-intl';

import { EmptyStatePlaceholder, Stack, personFillingAForm } from '@trustyou/ui';

import { NewReportButton } from './new-report-button';

import {
  onetimeReportsEmptyStateMessages,
  scheduledReportsEmptyStateMessages,
} from '../../constants/messages';
import { useHasSchedulePermission } from '../../hooks';
import styles from './styles';

export const EmptyState = () => {
  const hasSchedulePermission = useHasSchedulePermission();
  const messages = hasSchedulePermission
    ? scheduledReportsEmptyStateMessages
    : onetimeReportsEmptyStateMessages;
  return (
    <Stack sx={styles.emptyStateStack}>
      <EmptyStatePlaceholder
        emptyStateContainer={styles.emptyStateContainer}
        img={personFillingAForm}
        dataName="reports"
        title={<FormattedMessage {...messages.title} />}
        description={<FormattedMessage {...messages.description} />}
      />
      <NewReportButton variant="contained" />
    </Stack>
  );
};
