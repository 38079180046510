import {
  DELETE_INVITATION_PERMISSION,
  INVITE_USER_PERMISSION,
  RESEND_INVITATION_PERMISSION,
  UPDATE_INVITATION_PERMISSION,
  usePermissionStore,
} from '@trustyou/shared';

const useManageInvitations = () => {
  const { hasPermissions } = usePermissionStore();

  const isInviteUserAllowed = hasPermissions(INVITE_USER_PERMISSION);
  const isResendInvitationAllowed = hasPermissions(RESEND_INVITATION_PERMISSION);
  const isDeleteInvitationAllowed = hasPermissions(DELETE_INVITATION_PERMISSION);
  const isUpdateInvitationAllowed = hasPermissions(UPDATE_INVITATION_PERMISSION);

  return (
    isInviteUserAllowed &&
    isResendInvitationAllowed &&
    isDeleteInvitationAllowed &&
    isUpdateInvitationAllowed
  );
};

export default useManageInvitations;
