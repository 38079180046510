import { FormattedMessage } from 'react-intl';

import { actionMessages, commonFields } from '@trustyou/shared';
import { InfoDialog, Stack, Typography } from '@trustyou/ui';

import { manageDrawer } from '../../constants/messages/customAttributes';

type Props = {
  segments: string[];
  onClose: () => void;
};

export const DeleteProhibitionDialog = ({ segments, onClose }: Props) => (
  <InfoDialog
    title={<FormattedMessage {...manageDrawer.deleteProhibitionDialogTitle} />}
    open={!!segments.length}
    primaryActionText={<FormattedMessage {...actionMessages.okay} />}
    onPrimaryAction={onClose}
    dataName="custom-attribute-delete"
    maxWidth="sm"
  >
    <Stack gap={2} paddingX={3}>
      <Typography variant="body1">
        <FormattedMessage {...manageDrawer.deleteProhibitionDialogContent} />
      </Typography>
      <Stack gap={1}>
        <Typography variant="subtitle1">
          <FormattedMessage {...commonFields.segments} />
        </Typography>
        {segments.map((row) => (
          <Typography key={row} variant="body1">
            {row}
          </Typography>
        ))}
      </Stack>
    </Stack>
  </InfoDialog>
);
