import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { faXmark } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { commonFields, fetchEntity, useEntity, useRouteUtils } from '@trustyou/shared';
import {
  Box,
  ContentPane,
  EntitySourcesView,
  StyledFontAwesomeIcon as Icon,
  IconButton,
  SectorAvatarIcon,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@trustyou/ui';

import { MiniEntityTable } from './MiniEntityTable';
import { OverviewTab } from './OverviewTab';

import { EntityCompetitorsView } from '../../../../components';
import { useShowCompetitors } from '../../../../hooks';
import styles from './styles';

const TABS = {
  details: <FormattedMessage {...commonFields.details} />,
  sources: <FormattedMessage {...commonFields.sources} />,
  competitors: <FormattedMessage {...commonFields.competitors} />,
};

export const EntityDetails = () => {
  const { goUp } = useRouteUtils();
  const navigate = useNavigate();
  const { entityId } = useParams();
  const { data: entity } = useEntity(entityId);
  const showCompetitors = useShowCompetitors();

  const [selectedTab, setSelectedTab] = useState(0);

  const onAddCompetitors = () => {
    navigate('add-competitors');
  };

  useEffect(() => {
    // Logic to bo back to entities table if a invalid entity id used.
    // Cannot refer useEntity error because it takes time to return the error response due to retries
    fetchEntity(entityId as string).catch((error) => {
      if (error.code === 'ERR_BAD_REQUEST') {
        goUp();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentPane sx={styles.contentPane} bodyStyles={styles.bodyStyles}>
      <MiniEntityTable />
      {entity && (
        <Stack sx={styles.detailsContainer}>
          <Stack sx={styles.detailsHeader}>
            <SectorAvatarIcon sector={entity.sector} />
            <Typography variant="h6" flex="1">
              {entity.name}
            </Typography>
            <IconButton onClick={goUp}>
              <Icon icon={faXmark} />
            </IconButton>
          </Stack>
          <Stack sx={styles.detailsBody}>
            <Tabs
              sx={styles.detailsTabs}
              value={selectedTab}
              onChange={(_, value) => {
                setSelectedTab(value);
              }}
            >
              {Object.entries(TABS)
                .filter(([id]) => id !== 'competitors' || showCompetitors)
                .map(([id, message]) => (
                  <Tab key={id} label={message} />
                ))}
            </Tabs>
            <Box sx={styles.tabsBody}>
              {selectedTab === 0 && <OverviewTab entity={entity} />}
              {selectedTab === 1 && <EntitySourcesView sourcesUrls={entity.sources_urls || []} />}
              {selectedTab === 2 && showCompetitors && (
                <EntityCompetitorsView entity={entity} onAddCompetitors={onAddCompetitors} />
              )}
            </Box>
          </Stack>
        </Stack>
      )}
    </ContentPane>
  );
};
