type SourceIconsMap = { [key: string]: string };

const sourceIconsMap: SourceIconsMap = {
  booking: '5-icon.png',
  google: '3-icon.png',
  tripAdvisor: '16-icon.png',
};

export enum CONNECT_PROVIDER_NAMES {
  BOOKING = 'booking.com',
  GOOGLE = 'google.com',
}

export default sourceIconsMap;
