import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { FormControl, InputLabel, Stack, Typography } from '@trustyou/ui';

import { useUnregisterUnmountedQuestion } from '../../../../../service/hooks/use-unregister-unmounted-question';
import { QUESTION_TYPES_BY_CATEGORY } from '../../../../feedback/constants';
import { CustomTextFieldWithSeparatedLabel } from '../../../../shared';

export function QuestionEditorNumberType() {
  const { register } = useFormContext();
  useUnregisterUnmountedQuestion({
    questionTypes: QUESTION_TYPES_BY_CATEGORY.number,
    fieldsToUnregister: ['minNumber', 'maxNumber', 'number'],
  });

  return (
    <Stack spacing={1}>
      <Typography variant="body2">
        <FormattedMessage id="survey.common.range" defaultMessage="Range" />
      </Typography>
      <Stack direction="row" spacing={2}>
        <FormControl variant="standard">
          <InputLabel shrink>
            <FormattedMessage id="survey.common.minimum" defaultMessage="Minimum" />
          </InputLabel>
          <CustomTextFieldWithSeparatedLabel
            size="small"
            sx={{ width: 151 }}
            {...register('minNumber')}
          />
        </FormControl>
        <FormControl variant="standard">
          <InputLabel shrink>
            <FormattedMessage id="survey.common.maximum" defaultMessage="Maximum" />
          </InputLabel>
          <CustomTextFieldWithSeparatedLabel
            size="small"
            sx={{ width: 151 }}
            {...register('maxNumber')}
          />
        </FormControl>
      </Stack>
    </Stack>
  );
}
