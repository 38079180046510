import { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';

import { faGripDotsVertical, faTrashCan } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  StyledFontAwesomeIcon,
  Tooltip,
  Typography,
} from '@trustyou/ui';

import { DeleteViewDialog } from './delete-view-dialog';

import type { View } from '../../types';
import { IconButtonWithTooltip } from '../icon-button-with-tooltip';

export type ViewItemProps = {
  view: View;
  index: number;
  onDelete: (viewToDelete: View) => void;
};

export const SortableViewItem = ({ view, index, onDelete }: ViewItemProps) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDelete = () => {
    onDelete(view);
    setIsModalOpen(false);
  };

  return (
    <Draggable key={view.label} draggableId={view.label} index={index}>
      {(provided, snapshot) => (
        <ListItem
          disablePadding
          style={provided.draggableProps.style}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <ListItemButton
            disableRipple
            sx={{
              cursor: 'grab',
              height: (theme) => theme.spacing(6),
              ...(snapshot.isDragging && {
                backgroundColor: 'rgba(0, 0, 0, 0.08)',
              }),
            }}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            <ListItemIcon sx={{ minWidth: (theme) => theme.spacing(4) }}>
              <StyledFontAwesomeIcon icon={faGripDotsVertical} size="lg" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Tooltip
                  placement="top"
                  arrow
                  title={view.label}
                  disableHoverListener={view.label.length <= 25}
                >
                  <Typography variant="body2" noWrap>
                    {view.label}
                  </Typography>
                </Tooltip>
              }
            />
            {isHovering && (
              <IconButtonWithTooltip
                icon={faTrashCan}
                tooltip={<FormattedMessage id="inbox.action.delete" defaultMessage="Delete" />}
                onClick={() => setIsModalOpen(true)}
              />
            )}
            <DeleteViewDialog
              open={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              onDelete={handleDelete}
            />
          </ListItemButton>
        </ListItem>
      )}
    </Draggable>
  );
};
