import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { faArrowsRotate } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  type Entity,
  type Subscription,
  getAddEntitiesPayload,
  useAddEntities,
  useAlertStore,
} from '@trustyou/shared';
import { Box, Button, FullScreenModal, StyledFontAwesomeIcon, Typography } from '@trustyou/ui';

import { AddEntitySelector } from './AddEntitySelector';

import { DashboardDelay } from '../DashboardDelay/DashboardDelay';
import styles from './styles';

export interface AddEntityDialogProps {
  subscription?: Subscription;
  onClose: () => void;
}

export const AddEntityDialog = ({ subscription, onClose }: AddEntityDialogProps) => {
  const { alert } = useAlertStore();

  const [selectedEntities, setSelectedEntities] = useState<Entity[]>([]);

  const { mutate: addEntities, isPending: isEntitiesAdding } = useAddEntities(
    (data, variables) => {
      if (variables.entities.length === 1) {
        alert.success(
          <FormattedMessage
            id="add-entity.success.single-add"
            defaultMessage="{entity} added. {delay}"
            values={{ entity: selectedEntities[0].name, delay: <DashboardDelay /> }}
          />
        );
      } else {
        alert.success(
          <FormattedMessage
            id="add-entity.success.multi-add"
            defaultMessage="{entities} entities added. {delay}"
            values={{ entities: selectedEntities.length, delay: <DashboardDelay /> }}
          />
        );
      }
      setSelectedEntities([]);
      onClose();
    },
    () => {
      alert.genericError();
    },
    !subscription?.is_org_subscription
  );

  const handleAddEntities = () => {
    const payload = getAddEntitiesPayload(selectedEntities, subscription?.id);
    addEntities(payload);
  };

  const renderActionButton = () => (
    <Button
      data-testid="add-entity-submit"
      variant="contained"
      size="medium"
      onClick={handleAddEntities}
      disabled={selectedEntities.length === 0 || isEntitiesAdding}
      startIcon={isEntitiesAdding && <StyledFontAwesomeIcon icon={faArrowsRotate} />}
    >
      <FormattedMessage id="add-entity.submit-button" defaultMessage="Add entity" />
    </Button>
  );

  const getEntitiesRemaining = () => {
    if (!subscription) return 0;
    return (
      subscription.number_of_allowed_entities -
      subscription.number_of_consumed_entities -
      selectedEntities.length
    );
  };

  const isSingleEntitySubscription = subscription?.number_of_allowed_entities === 1;

  return (
    <FullScreenModal open={!!subscription} onClose={onClose} footer={renderActionButton()}>
      <Box sx={styles.wrapper}>
        <Typography variant="h6">
          <FormattedMessage id="add-entity.title" defaultMessage="Add entity by search" />
        </Typography>
        <Typography variant="body2">
          {isSingleEntitySubscription ? (
            <FormattedMessage
              id="add-entity.description-single"
              defaultMessage="{count} remaining"
              values={{ count: getEntitiesRemaining() }}
            />
          ) : (
            <FormattedMessage
              id="add-entity.description"
              defaultMessage="{count} remaining. Existing entities in your organization are not included below."
              values={{ count: getEntitiesRemaining() }}
            />
          )}
        </Typography>
        <AddEntitySelector
          selectedEntities={selectedEntities}
          setSelectedEntities={setSelectedEntities}
          maxEntitiesAllowed={subscription?.number_of_allowed_entities || 0}
          areItemsDisabled={getEntitiesRemaining() <= 0}
        />
      </Box>
    </FullScreenModal>
  );
};
