import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import {
  isRouteEndsWith,
  isTemporaryHidden,
  useMembershipStore,
  usePermissionStore,
  useResponsive,
} from '@trustyou/shared';
import { Box, DrawerNode, List } from '@trustyou/ui';

import { appSettingsNodes } from '../../constants/sideBarNodes';
import { styles } from './styles';

export default function ApplicationSettings() {
  const { hasRoutePermissions } = usePermissionStore();
  const { isMobileRange } = useResponsive();
  const { membership } = useMembershipStore();
  const membershipIdPath = membership?.id ? `/org/${membership.id}` : '';
  const appSettingsInnerNodes = [...appSettingsNodes(membershipIdPath)];

  const location = useLocation();
  const navigate = useNavigate();

  const isParentPage = isRouteEndsWith(location.pathname, 'settings/application');

  useEffect(() => {
    if (!isMobileRange && isParentPage) {
      navigate(
        isTemporaryHidden('CXP-customers-organization-profile') ? 'subscriptions' : 'general',
        { replace: true }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isParentPage, isMobileRange]);

  return (
    <Box sx={styles.nodesContainer}>
      {(isParentPage || !isMobileRange) && (
        <List sx={styles.topNodes}>
          {appSettingsInnerNodes
            .filter((node) => !node.isHidden && hasRoutePermissions(node.path as string))
            .map((node) => (
              <DrawerNode key={node.id} node={node} isPermanentlyExpanded />
            ))}
        </List>
      )}
      <Outlet />
    </Box>
  );
}
