import { useState } from 'react';
import type { FieldValues, FormState, UseControllerProps } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { grey } from '@mui/material/colors';
import {
  faArrowTurnDownRight,
  faExclamationCircle,
} from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  Asterisk,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  StyledFontAwesomeIcon,
  Typography,
} from '@trustyou/ui';

import { CustomCard } from './custom-card';

type NotApplicableCheckbox = {
  checked: boolean;
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export type QuestionProps = UseControllerProps & {
  title?: string;
  description?: string;
  notApplicableCheckbox?: NotApplicableCheckbox;
  showMandatoryAsterisk?: boolean;
  isLegalCheckbox?: boolean;
  withTriggerIcon?: boolean;
  withoutCardWrapper?: boolean;
  errors?: FormState<FieldValues>['errors'];
  children?: React.ReactElement;
};

const styles = {
  disabledStyle: {
    pointerEvents: 'none',
    opacity: 0.5,
  },
};

export function Question({
  title,
  description,
  notApplicableCheckbox,
  showMandatoryAsterisk,
  withTriggerIcon,
  isLegalCheckbox,
  withoutCardWrapper,
  name,
  errors,
  children,
}: QuestionProps) {
  const errorType = errors?.[name]?.type;

  //TODO: This needs to be checked in sometime to find better way
  const shouldShowMandatoryError =
    (errorType === 'optionality' || errorType === 'nullable') && !isLegalCheckbox;

  const [notApplicable, setNotApplicable] = useState(notApplicableCheckbox?.checked);

  return (
    <CustomCard withoutCardWrapper={withoutCardWrapper}>
      <Stack spacing={1}>
        {title && (
          <Box>
            {withTriggerIcon && (
              <StyledFontAwesomeIcon
                icon={faArrowTurnDownRight}
                size="lg"
                sx={{ color: grey[600], marginBlock: 1 }}
              />
            )}
            {title && (
              <Typography component="h3" variant="subtitle1">
                {title}
                {showMandatoryAsterisk && <Asterisk />}
              </Typography>
            )}
            {description && (
              <Typography variant="body2" color="text.secondary">
                {description}
              </Typography>
            )}
            {notApplicableCheckbox && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={notApplicable}
                      onChange={(event) => {
                        notApplicableCheckbox.onChange(event);
                        setNotApplicable(event.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography variant="body2" color="text.secondary">
                      {notApplicableCheckbox.label}
                    </Typography>
                  }
                />
              </FormGroup>
            )}
          </Box>
        )}
        <Box sx={notApplicable ? styles.disabledStyle : null}>{children}</Box>
        {shouldShowMandatoryError && (
          <Stack
            direction="row"
            spacing={0.75}
            sx={{ alignItems: 'center', paddingBlockStart: 2, color: 'error.main' }}
          >
            <StyledFontAwesomeIcon icon={faExclamationCircle} sx={{ height: 20, width: 20 }} />
            <Typography variant="caption" color="error.main" sx={{ mt: 2 }}>
              <FormattedMessage
                id="survey.common.mandatory-question"
                defaultMessage="Mandatory question"
              />
            </Typography>
          </Stack>
        )}
      </Stack>
    </CustomCard>
  );
}
