import { useIntl } from 'react-intl';

import { Box, Typography } from '@trustyou/ui';

import { InfoDialog } from './info-dialog';
import { detailsDialogMessages, reasonAndNotesMessages } from './messages';

type SurveyModerationDetailsDialogProps = {
  isOpen: boolean;
  onClose?: () => void;
};

export function SurveyModerationDetailsDialog({
  isOpen,
  onClose,
}: SurveyModerationDetailsDialogProps) {
  const intl = useIntl();

  return (
    <InfoDialog
      open={isOpen}
      onClose={onClose}
      // @ts-expect-error Type 'Element' is not assignable to type 'ReactNode'.
      title={
        <>
          {intl.formatMessage(detailsDialogMessages.title)}
          <Typography variant="body2" color="text.secondary">
            {intl.formatMessage(detailsDialogMessages.subtitle, { user: '[user]', date: '[date]' })}
          </Typography>
        </>
      }
      // @ts-expect-error Type 'Element' is not assignable to type 'ReactNode'.
      content={
        <Box color="text.primary">
          <Typography variant="subtitle2" sx={{ marginBlockStart: 2 }}>
            {intl.formatMessage(reasonAndNotesMessages.reasonFieldLabel)}
          </Typography>
          <Typography variant="body2">[reason]</Typography>
          <Typography variant="subtitle2" sx={{ marginBlockStart: 3 }}>
            {intl.formatMessage(reasonAndNotesMessages.notesFieldLabel)}
          </Typography>
          <Typography variant="body2">[notes]</Typography>
        </Box>
      }
      fullWidth
      maxWidth="sm"
      sx={{ '& .MuiDialog-paper': { maxHeight: 600 } }}
    />
  );
}
