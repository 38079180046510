import { DateTime } from 'luxon';
import * as yup from 'yup';

export const reportValidationSchema = yup.object().shape({
  name: yup.string().default('').required(),
  recipients: yup.array().test({ test: (array = []) => array.length > 0 }),
  frequency: yup.string().default('weekly'),
  dayOfWeek: yup
    .number()
    .min(0)
    .max(6)
    .when('frequency', {
      is: 'weekly',
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional(),
    })
    .default(0),
  dayOfMonth: yup
    .number()
    .min(1)
    .max(31)
    .when('frequency', {
      is: 'monthly',
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional(),
    })
    .default(1),
  timeOfDay: yup
    .string()
    .default(DateTime.local().set({ hour: 11, minute: 0, second: 0, millisecond: 0 }).toISO()),
});
