import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useAlertStore, useScopeStore } from '@trustyou/shared';
import { Box, Dialog, DiscardDialog } from '@trustyou/ui';

import { EditorContent } from '../../../components/shared/editor-content';
import { EditorFooter } from '../../../components/shared/editor-footer';
import { EditorHeader } from '../../../components/shared/editor-header';
import { EditorSideNav } from '../../../components/shared/editor-side-nav';
import styles from '../../../components/shared/styles';
import { useSurveyEditorSteps } from '../../../components/survey/editor/use-steps';
import { MOCK_QUESTIONNAIRE_ID } from '../../../constants/mocks';
import { useStore } from '../../../store/store';
import type { SurveyEditorDataProps } from '../../../types/survey';
import { SurveyEditorPreview } from './survey-editor-preview';

export function SurveyEditor() {
  const navigate = useNavigate();
  const { alert } = useAlertStore();
  const editorStartingPoint = useStore.use.surveyEditorStartingPoint();
  const surveyEditorFormHook = useForm<SurveyEditorDataProps>();
  const { handleSubmit, trigger } = surveyEditorFormHook;

  //Discard dialog
  const [openDiscardDialog, setOpenDiscardDialog] = useState(false);

  // dialog
  const [open, setOpen] = useState(true);
  const closeEditor = () => {
    setOpenDiscardDialog(false);
    setOpen(false);
    navigate('');
  };

  const openDiscardDialogHandler = () => {
    setOpenDiscardDialog(true);
  };

  // stepper
  const steps = useSurveyEditorSteps();
  const [activeStep, setActiveStep] = useState(0);

  const goToNextStep = (data: SurveyEditorDataProps) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const goToPreviousStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const goToSideBarChosenStep = (index: number) => {
    const onfulfilled = (value: boolean) => {
      if (value) setActiveStep(index);
      else {
        alert.error('Check the fields with warnings.');
      }
    };
    const onrejected = (reason: unknown) => console.error('onrejected', reason);
    trigger?.().then(onfulfilled, onrejected);
  };
  const hasPreviousStep = steps[activeStep - 1] !== undefined;
  const hasNextStep = steps[activeStep + 1] !== undefined;

  // global state
  const { selectedEntities, selectedGroups, isValid } = useScopeStore();

  // editor
  const saveDraft = () => {
    // TODO:
    alert.warning('to be implemented: save draft');
  };
  const publish = () => {
    navigate(`${MOCK_QUESTIONNAIRE_ID}/detail`);
    // TODO:
    alert.warning(
      `to be implemented: publish \n\n ${JSON.stringify({
        selectedEntities,
        selectedGroups,
      })}`
    );
  };

  return (
    <Dialog fullScreen open={open} onClose={openDiscardDialogHandler}>
      <FormProvider {...surveyEditorFormHook}>
        <Box sx={styles.editorMain}>
          <EditorHeader
            title={
              editorStartingPoint === 'new-survey' ? (
                <FormattedMessage id="survey.common.new-survey" defaultMessage="New Survey" />
              ) : editorStartingPoint === 'new-survey-version' ? (
                <FormattedMessage
                  id="survey.editor.new-version-for"
                  defaultMessage="New version for {editorTitle}"
                  values={{ editorTitle: '[Name of survey]' }}
                />
              ) : editorStartingPoint === 'edit-survey' ? (
                <FormattedMessage
                  id="survey.survey-editor.edit.title"
                  defaultMessage="Editing {surveyName}"
                  values={{ surveyName: '[Name of survey]' }}
                />
              ) : (
                ''
              )
            }
            close={openDiscardDialogHandler}
            save={saveDraft}
          />
          <EditorContent
            sideNavigator={
              <EditorSideNav
                activeStep={activeStep}
                setActiveStep={goToSideBarChosenStep}
                steps={steps}
              />
            }
            content={steps[activeStep].content}
            contentExtraStyles={{ ...(activeStep === 8 && styles.editorContentExtraStyles) }}
            preview={<SurveyEditorPreview activeStep={activeStep} />}
            previewExtraStyles={{ ...(activeStep === 8 && styles.editorPreviewExtraStyles) }}
          />
          <EditorFooter
            hasNextStep={hasNextStep}
            hasPreviousStep={hasPreviousStep}
            nextStepLabel={steps[activeStep + 1]?.label}
            previousStepLabel={steps[activeStep - 1]?.label}
            goToNextStep={handleSubmit(goToNextStep)}
            goToPreviousStep={goToPreviousStep}
            publish={publish}
            disablePublishButton={!isValid()}
            extraMainStyles={{ ...(activeStep === 8 && styles.editorFooterExtraStyles) }}
          />
        </Box>
        <DiscardDialog
          // @ts-expect-error Type 'Element' is not assignable to type 'ReactNode'.
          title={
            <FormattedMessage
              id="survey.survey-editor.discard-editor.title"
              defaultMessage="Leave without saving changes?"
            />
          }
          // @ts-expect-error Type 'Element' is not assignable to type 'ReactNode'.
          content={
            <FormattedMessage
              id="survey.survey-editor.discard-editor.description"
              defaultMessage="All changes will be lost."
            />
          }
          open={openDiscardDialog}
          onCancel={() => setOpenDiscardDialog(false)}
          onDiscard={closeEditor}
        />
      </FormProvider>
    </Dialog>
  );
}
