import { FormattedMessage, useIntl } from 'react-intl';

import { faFlag } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { faUserCheck, faUserTie } from '@trustyou/fortawesome/pro-solid-svg-icons';

import { commonMessages } from '../../constants/messages';
import type { SpecificStampProps } from '../../types';
import { Stamp } from './stamp';
import { StampPair } from './stamp-pair';

export function InappropriateProcessingStampPair({
  date,
  requestor,
  approver,
}: Omit<SpecificStampProps, 'author'> & {
  requestor: string;
  approver: string;
}) {
  const intl = useIntl();

  return (
    <StampPair>
      <Stamp
        status={
          <FormattedMessage
            id="inbox.response.status.date.inappropriate-at"
            defaultMessage="Inappropriate: {moderationRequestDate}"
            values={{ moderationRequestDate: date }}
          />
        }
        icon={faFlag}
      />
      <Stamp
        status={intl.formatMessage(commonMessages.byUsername, { username: requestor })}
        icon={faUserTie}
      />
      <Stamp
        status={
          <FormattedMessage
            id="inbox.response.status.moderation.approved-by"
            defaultMessage="Approved by: {approver}"
            values={{ approver: approver }}
          />
        }
        icon={faUserCheck}
      />
    </StampPair>
  );
}
