import { type SyntheticEvent, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Chip, Popover } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useQueryClient } from '@tanstack/react-query';
import { useAlertStore } from '@trustyou/shared';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Rating,
  Stack,
  TextField,
  Typography,
} from '@trustyou/ui';

import { PersonNextToACheckmark } from './person-next-to-a-checkmark';

import { RESPONSE_AI_RESULT } from '../../../constants/query-keys';
import { useResponseAIIssueOptions, useSendFeedback } from '../../../hooks';
import type { Issue, ResponseResult, Score } from '../../../types';

export type ResponseRatingProps = {
  isGenerating?: boolean;
};

export const ResponseRating = ({ isGenerating = false }: ResponseRatingProps) => {
  const intl = useIntl();
  const { alert } = useAlertStore();
  const ratingRef = useRef<HTMLDivElement>(null);
  const queryClient = useQueryClient();
  const issueOptions = useResponseAIIssueOptions();
  const { mutate: sendFeedback, isError: feedbackError } = useSendFeedback();

  const [score, setScore] = useState<Score | null>(null);
  const [showFeedbackCard, setShowFeedbackCard] = useState(false);
  const [showThanks, setShowThanks] = useState(false);
  const [selectedIssues, setSelectedIssues] = useState<Issue[]>([]);
  const [feedbackText, setFeedbackText] = useState('');

  const isOtherSelected = selectedIssues.find((issue) => issue === 'other');
  const payload = {
    consumption_log_id: (
      queryClient.getMutationCache().find({ mutationKey: [RESPONSE_AI_RESULT] })?.state
        ?.data as ResponseResult
    )?.consumption_log_id,
    score: score as Score,
    issues: selectedIssues,
    description: isOtherSelected ? feedbackText : '',
  };

  useEffect(() => {
    // reset values to default when the response changes
    if (isGenerating) {
      setScore(null);
      setSelectedIssues([]);
      setFeedbackText('');
    }
  }, [isGenerating]);

  useEffect(() => {
    setShowThanks(false);
    if (!score) return;

    if (score > 3) {
      sendFeedback({
        ...payload,
        issues: [],
        description: '',
      });
      if (feedbackError) {
        handleRatingError();
      } else {
        alert.success(
          intl.formatMessage({
            id: 'inbox.response.ai.rating.snackbar.success',
            defaultMessage: 'Thank you for your feedback!',
          })
        );
      }
    } else {
      sendFeedback(payload);
      setShowFeedbackCard(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [score]);

  useEffect(() => {
    !showFeedbackCard && setShowFeedbackCard(false);
  }, [showFeedbackCard]);

  const handleRatingError = () => {
    alert.error(
      intl.formatMessage({
        id: 'inbox.response.ai.rating.snackbar.error',
        defaultMessage: "Couldn't send rating, please try again.",
      })
    );
  };

  const handleCloseFeedbackCard = (event: Event | SyntheticEvent) => {
    if (ratingRef.current && ratingRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setShowFeedbackCard(false);
  };

  const toggleSelectedIssue = (clickedIssue: Issue) => {
    if (selectedIssues.includes(clickedIssue)) {
      setSelectedIssues(selectedIssues.filter((issue) => issue !== clickedIssue));
    } else {
      setSelectedIssues([...selectedIssues, clickedIssue]);
    }
  };

  const submit = () => {
    if (!score) return;
    sendFeedback(payload);
    if (feedbackError) {
      handleRatingError();
      return;
    }
    setShowThanks(true);
  };

  const renderFeedbackFormContentAndActions = () => (
    <>
      <CardContent>
        <Typography variant="h6">
          <FormattedMessage
            id="inbox.response.ai.rating.dialog.header"
            defaultMessage="What went wrong?"
          />
        </Typography>
      </CardContent>
      <CardContent>
        <Stack component="ul" margin={0} padding={0} gap={1.5} direction="row" flexWrap="wrap">
          {issueOptions.map((option) => (
            <Chip
              component="li"
              key={option.value}
              label={option.label}
              color="secondary"
              variant={selectedIssues.includes(option.value) ? 'filled' : 'outlined'}
              sx={{ '&.MuiChip-filled': { border: `1px solid transparent` } }}
              onClick={() => toggleSelectedIssue(option.value)}
            />
          ))}
        </Stack>
        {isOtherSelected && (
          <TextField
            placeholder={intl.formatMessage({
              id: 'inbox.response.ai.rating.dialog.message.placeholder',
              defaultMessage: 'Tell us more (optional)',
            })}
            value={feedbackText}
            onChange={(event) => setFeedbackText(event.target.value)}
            fullWidth
            multiline
            minRows="3"
            sx={{ marginTop: 2 }}
          />
        )}
      </CardContent>
      <CardActions sx={{ justifyContent: 'end' }}>
        <Button onClick={() => setShowThanks(true)}>
          <FormattedMessage id="inbox.action.skip" defaultMessage="Skip" />
        </Button>
        <Button variant="contained" onClick={submit}>
          <FormattedMessage id="inbox.action.send" defaultMessage="Send" />
        </Button>
      </CardActions>
    </>
  );

  const renderThanksContentAndActions = () => (
    <>
      <CardContent>
        <Typography variant="h6">
          <FormattedMessage
            id="inbox.response.ai.rating.dialog.successHeader"
            defaultMessage="Thank you for your feedback"
          />
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <FormattedMessage
            id="inbox.response.ai.rating.dialog.successSubHeader"
            defaultMessage="It helps us greatly with improving our AI."
          />
        </Typography>
      </CardContent>
      <Box
        display="flex"
        justifyContent="center"
        bgcolor={(theme) => alpha(theme.palette.secondary.main, 0.2)}
      >
        <PersonNextToACheckmark />
      </Box>
      <CardActions sx={{ justifyContent: 'end' }}>
        <Button variant="contained" onClick={() => setShowFeedbackCard(false)}>
          <FormattedMessage id="inbox.action.close" defaultMessage="Close" />
        </Button>
      </CardActions>
    </>
  );

  return (
    <>
      <Stack
        gap={1}
        direction="row"
        flexWrap="wrap"
        alignItems="center"
        paddingY={1}
        justifyContent="flex-end"
        ref={ratingRef}
      >
        <Typography variant="caption" color="text.secondary">
          <FormattedMessage
            id="inbox.response.ai.rating.label"
            defaultMessage="Rate this response:"
          />
        </Typography>
        <Rating
          disabled={isGenerating}
          size="small"
          value={score}
          onChange={(event, score) => setScore(score as Score)}
        />
      </Stack>
      <Popover
        role="menu"
        elevation={3}
        open={showFeedbackCard}
        anchorEl={ratingRef.current}
        sx={{ '.MuiMenu-list': { padding: 0 } }}
        onClose={handleCloseFeedbackCard}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Card sx={{ width: (theme) => theme.spacing(60) }}>
          {showThanks ? renderThanksContentAndActions() : renderFeedbackFormContentAndActions()}
        </Card>
      </Popover>
    </>
  );
};
