import { useEffect, useState } from 'react';

import MatchingEntities from '../../../../components/LoadingScreen/MatchingEntities';
import EntityUploadLoader from '../../../../components/LoadingScreen/SpreadsheetUploading';

const UploadingState = () => {
  const [isMatching, setIsMatching] = useState<boolean>(false);

  useEffect(() => {
    const uploadingTimeout = setTimeout(() => {
      setIsMatching(true);
    }, 3000);

    return () => clearTimeout(uploadingTimeout);
  }, []);

  return isMatching ? <MatchingEntities /> : <EntityUploadLoader />;
};

export default UploadingState;
