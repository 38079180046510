export const keycloakOidcConfig = ({ theme }: { theme: string | null }) => ({
  ...{
    client_id: `${process.env.REACT_APP_AUTH_CLIENT}`,
    redirect_uri: `${process.env.PUBLIC_URL}/callback-keycloak-oidc`,
    scope: 'openid profile email',
    authority: `${process.env.REACT_APP_IDP_ORIGIN}/realms/${process.env.REACT_APP_AUTH_REALM}`,
    service_worker_only: false,
    token_renew_mode: 'access_token_or_id_token_invalid',
    storage: localStorage,
  },
  ...(theme && {
    extras: {
      theme,
    },
  }),
});

export const keycloakOidcOperationsConfig = ({ theme }: { theme: string | null }) => ({
  ...{
    client_id: `${process.env.REACT_APP_AUTH_ADMIN_CLIENT}`,
    redirect_uri: `${process.env.PUBLIC_URL}/callback-keycloak-oidc`,
    scope: 'openid profile email',
    authority: `${process.env.REACT_APP_IDP_ORIGIN}/realms/${process.env.REACT_APP_AUTH_ADMIN_REALM}`,
    service_worker_only: false,
    token_renew_mode: 'access_token_or_id_token_invalid',
    storage: localStorage,
  },
  ...(theme && {
    extras: {
      theme,
    },
  }),
});
