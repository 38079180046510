import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { faChevronLeft, faChevronRight } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Button, Stack, StyledFontAwesomeIcon, Typography } from '@trustyou/ui';

type EditorFooterProps = {
  hasPreviousStep: boolean;
  hasNextStep: boolean;
  nextStepLabel: ReactNode;
  previousStepLabel: ReactNode;
  goToPreviousStep: () => void;
  goToNextStep: () => void;
  publish: () => void;
  disablePublishButton?: boolean;
  extraMainStyles?: Record<string, unknown>;
};

export const EditorFooter = ({
  hasPreviousStep,
  hasNextStep,
  nextStepLabel,
  previousStepLabel,
  goToPreviousStep,
  goToNextStep,
  publish,
  disablePublishButton,
  extraMainStyles,
}: EditorFooterProps) => {
  return (
    <Stack
      direction="row"
      sx={{
        gridColumn: '2',
        gridRow: '3',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingInline: 3,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        ...extraMainStyles,
      }}
    >
      <Stack sx={{ visibility: hasPreviousStep ? 'block' : 'hidden' }}>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          <FormattedMessage id="survey.common.previous" defaultMessage="Previous" />
        </Typography>
        <Button
          onClick={goToPreviousStep}
          variant="outlined"
          startIcon={<StyledFontAwesomeIcon icon={faChevronLeft} />}
          sx={{
            '& .MuiButton-startIcon>*:nth-of-type(1)': {
              fontSize: 'inherit',
            },
          }}
        >
          {previousStepLabel}
        </Button>
      </Stack>
      <Stack sx={{ alignItems: 'end', height: 60, justifyContent: 'flex-end' }}>
        {hasNextStep && (
          <Typography variant="body2" color="text.secondary" gutterBottom>
            <FormattedMessage id="survey.common.next" defaultMessage="Next" />
          </Typography>
        )}
        <Button
          onClick={hasNextStep ? goToNextStep : publish}
          variant="contained"
          endIcon={hasNextStep && <StyledFontAwesomeIcon icon={faChevronRight} />}
          sx={{
            '& .MuiButton-endIcon>*:nth-of-type(1)': {
              fontSize: 'inherit',
            },
          }}
          disabled={!hasNextStep && disablePublishButton}
        >
          {hasNextStep ? (
            nextStepLabel
          ) : (
            <FormattedMessage id="survey.common.save-close" defaultMessage="Save and close" />
          )}
        </Button>
      </Stack>
    </Stack>
  );
};
