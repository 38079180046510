import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  actionCell: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 1,
    marginX: 'auto',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    minWidth: '40px',
  },
  actionCellMenuItem: {
    minWidth: '220px',
  },
  actionCellMenuItemBody: {
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    alignItems: 'center',
  },
  actionCellMenuItemIcon: {
    color: 'action.active',
  },
};

export default styles;
