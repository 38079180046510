import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  nameCell: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  nameText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    gap: 2,
    pb: 2,
    px: 4,
  },
  userInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    height: '80px',
    padding: 2,
    backgroundColor: 'background.default',
    borderRadius: '4px',
  },
  userCredentialsCell: {
    display: 'flex',
    gap: 2.5,
  },
  invitedUserCredentialsCell: {
    '& .MuiAvatar-circular': {
      border: '1px dashed',
      borderColor: 'text.primary',
      background: 'rgba(255, 255, 255, 1)',
      color: 'transparent',
    },
  },
  role: {
    display: 'flex',
    alignItems: 'center',
    marginY: 3,
    gap: 1,
    '& > svg': {
      fontSize: '20px',
      color: 'secondary.main',
    },
  },
  scopeDetailsHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    '& > svg': {
      fontSize: '20px',
      color: 'secondary.main',
    },
  },
  scopeDetailsBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  userScopeCell: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
};

export default styles;
