import { useOidc, useOidcAccessToken } from '@axa-fr/react-oidc';
import {
  AuthPendingState,
  isExpired,
  useAuthorizationStore,
  useChangelingStore,
  useMembershipStore,
} from '@trustyou/shared';

import MembershipLayer from './MembershipLayer';
import NavigationLayer from './NavigationLayer';

import useAuthChangeling from '../../service/hooks/changeling/useAuthChangeling';
import useEmailInvite from '../../service/hooks/useEmailInvite';
import IsLoggedOut from '../LoadingScreen/IsLoggedOut';

const ChangelingLayer = () => {
  const { membership, organizations } = useMembershipStore();
  const { pendingState } = useAuthorizationStore();
  const { isChangeling } = useChangelingStore();
  const { isCompleted } = useAuthChangeling();
  const { hasInvitedParam } = useEmailInvite();
  const { accessTokenPayload } = useOidcAccessToken();
  const { renewTokens } = useOidc();

  if (!isCompleted && pendingState === AuthPendingState.LOGGING_OUT) {
    return <IsLoggedOut />;
  }

  if (!isCompleted) return null;

  // wait for invited to be defined, block render if handle invited param
  if (hasInvitedParam) return null;

  const isKeycloakTokenExpired = isExpired(accessTokenPayload?.exp);

  if (accessTokenPayload && isKeycloakTokenExpired) {
    renewTokens();
    return null;
  }

  if (!isChangeling) return <MembershipLayer />;

  if (!membership || !organizations) {
    console.error('organization and memberships must be defined in changeling layer');
    return <NavigationLayer />;
  }

  return <MembershipLayer />;
};

export default ChangelingLayer;
