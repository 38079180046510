import { Backdrop, CircularProgress } from '@trustyou/ui';

type BackdropSpinnerProps = {
  isLoading: boolean;
};

export const BackdropSpinner = ({ isLoading }: BackdropSpinnerProps) => (
  <Backdrop sx={{ zIndex: (theme) => theme.zIndex.modal + 1, color: 'white' }} open={isLoading}>
    <CircularProgress color="inherit" />
  </Backdrop>
);
