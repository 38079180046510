import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Box,
  Button,
  ConnectToExternalAPI,
  ContentPane,
  InstructionCardList,
  type InstructionCardProps,
  Typography,
  reviewProvidersLogo,
} from '@trustyou/ui';

import styles from './styles';

export interface GoogleConnectInstructionsProps {
  onAuthorize: () => void;
}

const GoogleConnectInstructions = ({ onAuthorize }: GoogleConnectInstructionsProps) => {
  const googleInstructionItems: InstructionCardProps[] = [
    {
      stepNumber: 1,
      title: (
        <FormattedMessage
          id="review-providers.google.connect.step1.title"
          defaultMessage="Get ready your Google Business Profile account"
        />
      ),
      description: (
        <FormattedMessage
          id="review-providers.google.connect.step1.description"
          defaultMessage="Make sure you’ve verified and published your business profile. If you’re able to respond to reviews from Google Business Profile today, you have it ready."
        />
      ),
    },
    {
      stepNumber: 2,
      title: (
        <FormattedMessage
          id="review-providers.google.connect.step2.title"
          defaultMessage="Authorize via Google "
        />
      ),
      description: (
        <FormattedMessage
          id="review-providers.google.connect.step2.description"
          defaultMessage="You’ll need to grant TrustYou permission to fetch your review data and publish responses on your behalf."
        />
      ),
    },
    {
      stepNumber: 3,
      title: (
        <FormattedMessage
          id="review-providers.google.connect.step3.title"
          defaultMessage="Wait up to 24 hours"
        />
      ),
      description: (
        <FormattedMessage
          id="review-providers.google.connect.step3.description"
          defaultMessage="We’ll match your Google Business listings, and this process could take up to 24 hours."
        />
      ),
    },
  ];
  return (
    <ContentPane sx={styles.pageContainer}>
      <Box sx={styles.contentWrapper}>
        <ConnectToExternalAPI srcExt={reviewProvidersLogo['google.com']} altExt="google.com" />
        <Typography sx={styles.instructionDescription} variant="body2">
          <FormattedMessage
            id="review-providers.google.connect.description"
            defaultMessage="Connect to Google Business Profile APIs to get accurate reviews faster and use direct responses in your inbox."
          />
        </Typography>
        <InstructionCardList items={googleInstructionItems} />
        <Button
          size="large"
          data-testid="google-get-auth-url"
          onClick={onAuthorize}
          variant="contained"
        >
          <FormattedMessage
            id="review-providers.google.connect.button-text"
            defaultMessage="Authorize via Google"
          />
        </Button>
      </Box>
    </ContentPane>
  );
};

export default GoogleConnectInstructions;
