import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  container: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    width: '367px',
    paddingBottom: 8,
  },
  header: {
    position: 'sticky',
    top: '64px',
    zIndex: '1',
    background: 'white',
  },
};

export default styles;
