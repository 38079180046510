import { SISENSE_SERVER_PATH } from '@trustyou/shared';

import { useHasImpactScorePermission } from './use-has-impact-score-permission';

import { useImpactScore } from '../services';
import type { ImpactScoreResponse } from '../types';

const REQUEST_KEY = 'impact-score-calculate';
const RESPOND_KEY = 'impact-score-receive';

export const useSendImpactScore = (iFrameRef: React.RefObject<HTMLIFrameElement>) => {
  const hasImpactScorePermission = useHasImpactScorePermission();

  const postImpactScore = (response?: ImpactScoreResponse) => {
    iFrameRef.current?.contentWindow?.postMessage(
      {
        action: RESPOND_KEY,
        response: response || null,
      },
      SISENSE_SERVER_PATH as string
    );
  };

  const { mutate: getImpactScore } = useImpactScore(postImpactScore, () => postImpactScore());

  // NOTE: Use this if multiple requests fired
  // const getImpactScoreThrottled = useCallback(throttle(getImpactScore, 1000), []);

  const sendImpactScore = (event: MessageEvent) => {
    if (typeof event.data === 'string') return;
    if (!hasImpactScorePermission) return;
    if (event.data.action && event.data.action === REQUEST_KEY) {
      getImpactScore(event.data.request);
    }
  };

  return { sendImpactScore };
};
