import { deDE, enUS, esES, frFR, itIT, jaJP, ptBR, ruRU, zhCN } from '@mui/x-date-pickers-pro';

// TODO: check the Localization type
export const useDatePickersIntl = (): Record<string, typeof deDE> => ({
  'de-DE': deDE,
  'en-US': enUS,
  'es-ES': esES,
  'fr-FR': frFR,
  'it-IT': itIT,
  'ja-JP': jaJP,
  'pt-PT': ptBR,
  'zh-CN': zhCN,
  'ru-RU': ruRU,
});
